var Maps = require('lib/maps')

module.exports = Backbone.View.extend({
	template: require('./region-draw-view-template'),
	className: 'region-draw storm-modal stretch modal fade',

	events: {
		'click .cancel-button': 'close',
		'click .save-button': 'save'
	},

	initialize: function(options) {
		this.modalTitle = options.modalTitle
		this.appId = options.appId || 0
		this.polygons = []
	},

	getRenderData: function() {
		var renderData = (this.model) ? this.model.toJSON() : {}

		renderData.modalTitle = this.modalTitle || $.t('hazards.alerts.custom.drawRegion')

		return renderData
	},

	afterRender: function() {
		// Initialise Google Maps API.
		Maps.init(this.initGoogleMap.bind(this))
	},

	initGoogleMap: function() {
		var overlayOptions = {
			strokeColor: '#F93A2F',
			fillColor: '#F93A2F',
			fillOpacity: 0.28,
			strokeWeight: 2,
			clickable: false,
			editable: true
		}

		// Initialize Google map
		var mapOpts = Maps.getOptions()

		// Centre on US for ARC.
		if (App.system.id === 9) {
			mapOpts.center = new google.maps.LatLng(40, -93)
		} else if (App.system.id === 10) {
			mapOpts.center = new google.maps.LatLng(64, -31)
		}

		switch (this.appId) {
			case 1: // UK (RSPB)
				mapOpts.center = new google.maps.LatLng(53, -1)
				break
			case 83: // Vietnam
				mapOpts.center = new google.maps.LatLng(13, 108)
				break
			case 85: // philippines
				mapOpts.center = new google.maps.LatLng(11, 123)
				break
			case 86: // Indonesia
				mapOpts.center = new google.maps.LatLng(-1, 117)
				break
			case 89: // Belize
				mapOpts.center = new google.maps.LatLng(17, -88)
				break
			case 90: // Jamaica
				mapOpts.center = new google.maps.LatLng(18, -77)
				break
			case 91: // St kitts
				mapOpts.center = new google.maps.LatLng(17, -62)
				break
			case 92: // Trinidad
				mapOpts.center = new google.maps.LatLng(10, -61)
				break
			case 93: // Grenada
				mapOpts.center = new google.maps.LatLng(12, -61)
				break
			case 94: // Guyana
				mapOpts.center = new google.maps.LatLng(6, -58)
				break
			case 95: // Antigua
				mapOpts.center = new google.maps.LatLng(17, -61)
				break
			case 96: // Barbados
				mapOpts.center = new google.maps.LatLng(13, -59)
				break
			case 97: // St Lucia
				mapOpts.center = new google.maps.LatLng(13, -60)
				break
			case 98: // Bahamas
				mapOpts.center = new google.maps.LatLng(25, -76)
				break
			case 99: // St Vincents
				mapOpts.center = new google.maps.LatLng(13, -61)
				break
			case 100: // Dominica
				mapOpts.center = new google.maps.LatLng(15, -61)
				break
			case 103: // Suriname
				mapOpts.center = new google.maps.LatLng(4, -55)
				break
			case 159: // New Zealand
				mapOpts.center = new google.maps.LatLng(-42, 172)
				break
			case 163: // Canada
				mapOpts.center = new google.maps.LatLng(56, -101)
				break
			case 171: // Mexico
				mapOpts.center = new google.maps.LatLng(24, -101)
				break
			case 188: // Iraq
				mapOpts.center = new google.maps.LatLng(32, 43)
				break
			default:
				break
		}

		this.map = new google.maps.Map(this.$('.region-map')[0], mapOpts)

		this.drawingManager = new google.maps.drawing.DrawingManager({
			drawingMode: google.maps.drawing.OverlayType.POLYGON,
			drawingControlOptions: {
				position: google.maps.ControlPosition.TOP_CENTER,
				drawingModes: [
					google.maps.drawing.OverlayType.POLYGON
				]
			},
			polygonOptions: overlayOptions,
			map: this.map
		})
		google.maps.event.addListener(this.drawingManager, 'overlaycomplete', this.overlayComplete.bind(this))
	},

	show: function() {
		this.$el.modal({backdrop: 'static'})

		setTimeout(function() {
			// Container has resized – update map and re-center.
			google.maps.event.trigger(this.map, 'resize')
			this.map.setCenter(this.map.center)
		}.bind(this), 500)
	},

	close: function() {
		this.$el.modal('hide')
	},

	overlayComplete: function(e) {
		this.polygons.push(e.overlay)
	},

	save: function() {
		var paths = []

		if (this.polygons.length > 1) {
			this.close()

			swal({
				title: $.t('error.oops'),
				text: $.t('hazards.alerts.specifySinglePolygon'),
				type: 'error'
			})

			return
		}

		// Map array of polygons to a geoJSON multipolygon array.
		this.polygons.forEach(function(polygon) {
			var polyPaths = []

			polygon.getPaths().forEach(function(path) {
				var polyPath = []

				path.forEach(function(latLng) {
					polyPath.push([latLng.lng(), latLng.lat()])
				})

				// Add first point in at the end - closing point.
				var start = path.getAt(0)

				if (start) {
					polyPath.push([start.lng(), start.lat()])
				}

				polyPaths.push(polyPath)
			})

			paths.push(polyPaths)
		})

		var region = {
			polygon: {
				type: 'Polygon', // Set to Polygon as multipolygon breaks RCN Api
				coordinates: paths // Change the paths to the first one in the array just before we save the model in general-notifications-edit-view
			},
			description: 'Unset'
		}

		this.model.set('area', region)
		this.model.trigger('change:area')
		this.close()
	}
})
