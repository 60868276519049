var StormQL = require('models/stormql'),
	EditorSectionView = require('editor/editor-section-view'),
	PlanItemListItemView = require('./plan-item-list-item-view')

module.exports = EditorSectionView.extend({
	className: 'planItems',
	template: require('./plan-item-view-template'),
	// TODO this may change once plugin navigation is done.
	activeTabIndex: 4,

	initialize: function(options) {
		App.startLoad()
		var requests = []
		this.app = App.appList.get(options.appId)
		this.appId = options.appId
		// Fetch PlanItems assigned to this app.
		this.appPlanItems = new StormQL(null, {app: this.app})
		var appPlanItemsFetch = this.appPlanItems.fetch({data: {class: 'Gnah_PlanItem'}})
		requests.push(appPlanItemsFetch)

		// Render page once all data loaded.
		Promise.all(requests).then(this.ready.bind(this))
	},

	ready: function() {
		App.stopLoad()
		this.render()
		if (this.appPlanItems.length === 0) {
			$('#noItems').show()
			$('table').hide()
		} else {
			$('#noItems').hide()
			$('table').show()
		}
	},

	getPageTitle: function() {
		return $.t('rspb.planItem.planItems')
	},

	getRenderData: function() {
		return {
			appId: this.app.id
		}
	},

	afterRender: function() {
		this.appPlanItems.forEach(this.addPlanItem, this)
	},

	addPlanItem: function(planItem) {
		var view = new PlanItemListItemView({
			planItem: planItem,
			appId: this.appId
		})

		this.$('.list-items').append(view.render().el)
	}
})
