<td class="center">{{search.id}}</td>
<!-- <td class="center">{{search.burningRate}}</td> -->
<td class="center">{{search.compressionStrength25}}</td>
<td class="center">{{search.compressionStrength50}}</td>
<td class="center">{{search.compressionStrength75}}</td>
<td class="center">{{search.equivalentYoungsModulus}}</td>
<td class="center">{{search.mouldedDensity}}</td>
<td class="center">{{search.resilience75DynamicCompression}}</td>
<!-- <td class="center">{{search.tensileElongation}}</td> -->
<!-- <td class="center">{{search.tensileStrength}}</td> -->

<td class="tools">
	<a href="/apps/{{appId}}/product-search/{{search.id}}" title="{{t "common.edit"}}"><i class="icon-pencil"></i></a>
	<!-- <i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}"></i> -->
</td>
