var StormQL  = require('models/stormql'),
	FeedList   = require('../feeds/feed-list'),
	EventList  = require('./event-list'),
	AppStrings = require('../../app-setup/app-strings'),
	StandaloneStormObject = require('editor/standalone-storm-object')

/**
 * Wrapper for retrieving and updating the events data within hazards
 * @param  {Object} options options object (contains app)
 */
module.exports = function EventsConfig(options) {
	this.app = options.app
	this.retrieveCategories = options.retrieveCategories // Used to signal if we want events and categories only (i.e. in disaster-view.js)

	this.fetch = function() {
		var promises = []

		if (this.retrieveCategories) {
			// this.metaHazCat = new StormQL(null, {app: this.app})
			// var metaHazCatFetch = this.metaHazCat.fetch({data: {class: 'MetaHazardsCategory'}})
			// promises.push(metaHazCatFetch)

			this.appCategories = new StormQL(null, {app: this.app})
			this.appCategories.comparator = 'order'
			var appCategoriesFetch = this.appCategories.fetch({data: {class: 'Hazards_Category'}})
			promises.push(appCategoriesFetch)
		}

		// Fetch Feeds assigned to this app.
		this.appFeedList = new StormQL(null, {app: this.app})
		this.appFeedList.comparator = 'order'
		var feedsFetch = this.appFeedList.fetch({data: {class: 'Feed'}})
		promises.push(feedsFetch)

		this.appStrings = new AppStrings({id: this.app.id})
		var appStringFetch = this.appStrings.fetch()
		promises.push(appStringFetch)

		// Fetch MetaHazards assigned to this app.
		// this.metaHaz = new StormQL(null, {app: this.app})
		// var metaHazFetch = this.metaHaz.fetch({data: {class: 'MetaHazardsEvent'}})
		// promises.push(metaHazFetch)

		// Fetch MetaData assigned to this app.
		this.meta = new StormQL(null, {app: this.app})
		var metaFetch = this.meta.fetch({data: {class: 'MetaData'}})
		promises.push(metaFetch)

		// Fetch the full list of feeds
		this.feedList = new FeedList()
		var feedListFetch = this.feedList.fetch()
		promises.push(feedListFetch)

		// Fetch the full list of Events
		this.eventList = new EventList()
		var eventListFetch = this.eventList.fetch()
		promises.push(eventListFetch)

		// Return a promise for all promises
		return Promise.all(promises)
	}

	this.getEventsWithFeeds = function() {
		var self = this
		// For each event
		this.eventList.forEach(function(theEvent) {
			var expandedFeeds = []
			var eventEnabled = false
			theEvent.get('feeds').forEach(function(feed) {
				var enabled = false
				// Replace ID with the actual feed...
				var actualFeed = self.getFeedById(feed)
				// Is active?
				var appFeed = self.appFeedList.findWhere({code: actualFeed.get('code')})
				if (appFeed) {
					if (_.contains(appFeed.get('events'), theEvent.get('id'))) {
						enabled = true
						eventEnabled = true
					}
				}

				var jsonFeed = actualFeed.toJSON()
				jsonFeed.enabled = enabled
				expandedFeeds.push(jsonFeed)
			})
			var name = theEvent.get('name')
			var friendlyName =  self.appStrings.get(name)
			var eventMeta = self.metaHaz.findWhere({identifier: theEvent.get('id')})

			if (eventMeta) {
				theEvent.set('meta', eventMeta.toJSON())
			}
			theEvent.set('friendlyName', friendlyName)
			theEvent.set('enabled', eventEnabled)
			theEvent.set('expandedFeeds', expandedFeeds)
		})

		return this.eventList
	}

	this.getCategories = function() {
		// Get MetaData for each category
		this.appCategories.forEach(function(category) {
			var catMeta = this.metaHazCat.findWhere({identifier: category.get('id')})
			if (catMeta) {
				category.set('meta', catMeta)
			}
		}.bind(this))
		return this.appCategories
	}

	this.getFeedById = function(id) {
		return this.feedList.get(id)
	}

	this.parseMeta = function() {
		if (this.meta.length === 0) {
			// We need to create an object and put it in there!
			this.meta.add(StandaloneStormObject.fromClassName('MetaData'))
		}
		var meta = this.meta.at(0)
		this.metaHaz = meta.get('events')
		this.metaHazCat = meta.get('categories')
	}
}
