var DevPool = require('./dev-pool'),
	GDPCSectionView = require('./gdpc-section-view')

module.exports = GDPCSectionView.extend({
	template: require('./dev-pool-country-view-template'),

	initialize: function(options) {
		this.appId = options.appId
		this.societyId = parseInt(options.societyId, 10)

		this.model = new DevPool({id: options.id})
		this.model.once('sync', this.ready, this)
		this.model.fetch()

		this.model.details.once('sync', this.ready, this)
		this.model.details.fetch()
	},

	getRenderData: function() {
		var data = this.model.toJSON()
		data.country = this.model.details.findWhere({societyId: this.societyId})

		if (data.country) {
			data.country = data.country.toJSON()

			// Pad each app language stage list to full length of stages, copy in stage names
			_.each(data.country.languages, function(lang) {
				for (var i in data.stages) {
					if (lang.stages[i] === undefined) {
						lang.stages[i] = {
							stageDue: data.stages[i].timestamp,
							stageId: data.stages[i].stageId,
							stageName: data.stages[i].name
						}
					}

					lang.stages[i].responsibility = data.stages[i].responsibility
					lang.stages[i].notes = data.stages[i].notes
				}

				// Set estimated completion date as 1 week after iOS submission
				var submission = lang.stages.filter(function(stage) {
					return stage.stageId === 5
				})[0]

				if (submission === undefined) {
					return
				}

				var dueDate = new Date(submission.stageDue * 1000)
				dueDate.setDate(dueDate.getDate() + 7)

				lang.expected = parseInt(dueDate.getTime() / 1000, 10)
			})
		}

		data.appId = this.appId

		return data
	},

	getPageTitle: function() {
		return $.t('gdpcConsole.nationalSocieties.details')
	},

	ready: function() {
		this.render()
		App.stopLoad()
	}
})
