var ListViewItem = require('list-view/list-view-item'),
	Whatnow = require('./whatnow')

module.exports = ListViewItem.extend({
	template: require('./whatnow-item-view-template'),

	events: {
		'click .delete': 'delete'
	},

	initialize: function(options) {
		this.model = new Whatnow()
		this.data = options.data
		this.appId = options.appId
		this.countryCode = options.countryCode
		this.model.set('id', this.data.id)
		this.model.once('sync', this.ready, this)
		this.model.fetch()
	},

	delete: function() {
		this.requestDelete()
	},

	afterInitialize: function() {
		this.events['click .reject'] = 'reject'
		this.delegateEvents()
	},

	getRenderData: function() {
		return {
			data: this.data,
			appId: this.appId,
			countryCode: this.countryCode
		}
	}
})
