var StormView = require('lib/storm-view'),
	/** @type {StormGlobals} */
	globals = require('globals')

/**
 * Exports {@link DashboardSectionView}.
 * @module
 */
module.exports = StormView.extend(/** @lends DashboardSectionView.prototype */{
	/**
	 * @constructs DashboardSectionView
	 * @extends StormView
	 * @override
	 */
	initialize: function() {
	},

	/** @override */
	getSectionTitle: function() {
		return $.t('dashboard.title')
	},

	/** @override */
	getPages: function() {
		var acl = globals.getAcl()

		return [
			{
				name: $.t('dashboard.overview'),
				url: 'dashboard',
				isVisible: function() {
					// TODO page not yet implemented
					return false
				},
				views: []
			},
			{
				name: $.t('nav.items.push.title'),
				url: 'push',
				isVisible: function() {
					return acl.hasAccess('Push')
				},
				views: [
					require('push/push-view'),
					require('push/push-edit-view'),
					require('push/push-details-view')
				]
			}
		]
	}
})
