var PasswordReset = require('./password-reset')

module.exports = Backbone.View.extend({
	template: require('./password-reset-view-template'),

	el: '#login',
	className: 'password-reset',

	events: {
		'click button': 'submit',
		'keypress input': 'keypress',
		'change input': 'keypress',
		'keyup input': 'keypress'
	},

	initialize: function(options) {
		this.model = new PasswordReset({flag: options.flag})
		App.startLoad()

		// Check flag is valid.
		this.model.verify().then(this.ready, this.tokenExpired)
	},

	tokenExpired: function() {
		swal($.t('error.oops'), $.t('login.error.linkExpired'), 'error')
		document.location = '/'
	},

	ready: function() {
		App.stopLoad()
	},

	afterRender: function() {
		$('#login').show()
		this.$('.confirm').focus()
	},

	keypress: function(e) {
		var $button = this.$('button')

		// Submit when return pressed
		if (e.keyCode === 13 && !$button.prop('disabled')) {
			return this.submit(e)
		}

		var password = $('.password').val(),
			confirm  = $('.confirm').val(),
			isValid  = password === confirm && password
		$button.prop('disabled', !isValid)
	},

	submit: function(e) {
		e.preventDefault()
		var password = this.$('.password').val(),
			confirm  = this.$('.confirm').val()

		var $button = this.$('button'),
			$icon   = $button.find('i')

		if (confirm !== password) {
			return
		}

		$button.attr('disabled', true)
		$icon.removeClass('icon-arrow-right')
			.addClass('icon-spin icon-refresh')

		this.model.setPassword(password).then(this.passwordChanged, this.passwordChangeError)
	},

	passwordChanged: function() {
		this.$('.login-inner').addClass('success')
		this.$('.forgot-submit-button').removeAttr('disabled')
	},

	passwordChangeError: function() {
		swal($.t('error.oops'), $.t('error.generic'), 'error')

		var $button = this.$('button')

		$button.removeAttr('disabled')
		$button.find('i').addClass('icon-arrow-right')
			.removeClass('icon-spin icon-refresh')
	}
})
