var StandaloneStormObject = require('editor/standalone-storm-object'),
	MediaLibrary = require('media-library/media-library-view'),
	MediaSelectorView = require('media-library/media-selector-view'),
	EditorSectionView = require('editor/editor-section-view'),
	utils = require('lib/utils')

/**
 * Exports {@link BadgeEditView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends BadgeEditView.prototype */{
	/** @override */
	className: 'badges',
	/** @override */
	template: require('./badge-edit-view-template'),

	/** @override */
	events: {
		'click .save': 'save',
		'click .choose-image-button': 'chooseImage'
	},

	/**
	 * @constructs BadgeEditView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)

		if (!options.app) {
			throw new Error('No app specified')
		}

		this.views = {}

		/** @private {App} */
		this.app_ = options.app

		// Fetch enabled languages for this app.
		var languagePromise = this.app_.languageList.fetchOnce()

		this.model = StandaloneStormObject.fromClassName('Badge')
		this.listenTo(this.model, 'change:badge', this.updateBadge, this)

		var modelPromise = Promise.resolve()

		if (options.id !== 'new') {
			this.model.set('id', options.id)
			this.model.once('sync', this.ready, this)
			modelPromise = this.model.fetch().then(function() {
				// Add in any missing text objects to the model.
				['title', 'shareMessage', 'completion', 'how'].forEach(function(key) {
					if (!this.model.has(key)) {
						var text = App.getClassStructure('Text', 0)
						this.model.set(key, text)
					}
				}, this)

				this.model.requestLock()
			}.bind(this))
		}

		Promise.all([modelPromise, languagePromise])
			.then(App.stopLoad)
			.then(this.ready.bind(this))
	},

	ready: function() {
		this.render()
	},

	/** @override */
	getRenderData: function() {
		var data = this.model.toJSON()

		data.appId = this.app_.id
		data.languages = this.app_.languageList.toJSON()

		return data
	},

	/** @override */
	afterRender: function() {
		this.delegateEvents()

		// Show current input values
		_.each(this.model.get('title..content'), function(val, key) {
			this.$('.title-input[data-code=' + key + ']').val(val)
		})

		_.each(this.model.get('completion..content'), function(val, key) {
			this.$('.completion-input[data-code=' + key + ']').val(val)
		})

		_.each(this.model.get('shareMessage..content'), function(val, key) {
			this.$('.share-input[data-code=' + key + ']').val(val)
		})

		_.each(this.model.get('how..content'), function(val, key) {
			this.$('.how-input[data-code=' + key + ']').val(val)
		})
	},

	save: function() {
		App.startLoad()

		var self = this

		this.model.once('sync', function() {
			this.model.requestUnlock()
			App.router.navigate('/apps/' + self.app_.id + '/badges', {trigger: true})
		}.bind(this))

		// Get all new input values
		var titles = {},
			completions = {},
			messages = {},
			hows = {}

		this.$('.title-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				titles[code] = value
			}
		})

		this.$('.completion-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				completions[code] = value
			}
		})

		this.$('.share-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				messages[code] = value
			}
		})

		this.$('.how-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				hows[code] = value
			}
		})

		this.model.set('title..content', titles)
		this.model.set('completion..content', completions)
		this.model.set('shareMessage..content', messages)
		this.model.set('how..content', hows)
		this.model.save(null, {appId: this.app_.id})
		return false
	},

	chooseImage: function() {
		this.views.mediaLibrary = new MediaSelectorView({
			app: this.app_,
			model: this.model.get('icon'),
			mediaType: MediaLibrary.types.IMAGE
		})

		this.views.mediaLibrary.on('change', function() {
			this.model.trigger('change:badge', this.model)
		}, this)

		$('body').append(this.views.mediaLibrary.el)
		this.views.mediaLibrary.render().show()
	},

	updateBadge: function() {
		// Support legacy image format.
		var icon = this.model.get('icon')

		if (!icon.src) {
			icon = icon.toJSON()
		}

		this.$('img').attr('src', utils.getImagePreviewUrl(icon))
	}

})
