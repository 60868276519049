var PaginatedListView = require('list-view/paginated-list-view'),
	UserList = require('users/user-list')

module.exports = PaginatedListView.extend({
	template: require('./bugs-paginated-list-view-template'),
	list: require('./bug-list'),
	listItem: require('./bug-list-view-item'),

	afterInitialize: function() {
		this.on('ready', this.afterReady, this)
		this.collection.on('sync', this.fetchUsers, this)
	},

	afterReady: function() {
		if (this.total > 0) {
			this.$('table').show()
			this.$('.empty-table-text').hide()
		}
	},

	// Fetch user objects for each loaded bug.
	fetchUsers: function() {
		var users = this.collection.map(function(bug) {
			return bug.get('userId')
		})

		users = _.uniq(users)

		var userList = new UserList({ids: users})
		userList.once('sync', this.matchUsers, this)
		userList.fetch()
	},

	// Match user objects to bugs.
	matchUsers: function(users) {
		this.collection.forEach(function(bug) {
			var user = users.get(bug.get('userId'))
			bug.set('user', user.get('firstName') + ' ' + user.get('lastName'))
		})
	}
})
