module.exports = Backbone.Collection.extend({

	initialize: function(options) {
		this.countryCode = options.countryCode
	},

	url: function() {
		return App.whatnowRoot + 'org/' + this.countryCode + '/whatnow'
	}
})
