var MediaLibrary = require('./media-library-view'),
	CropView     = require('./media-crop-view'),
	UploadList   = require('./upload-list'),
	Upload       = require('./upload'),
	TagList      = require('./tag-list'),
	tagBlacklist = require('./tag-blacklist')

var ANIMATION_INTERVAL = 500

/**
 * Exports {@link MediaUploadView}.
 * @module
 */
module.exports = Backbone.View.extend(/** @lends MediaUploadView.prototype */{
	/** @override */
	template: require('./media-upload-view-template'),
	/** @override */
	className: 'media-upload row no-transparency',

	/** @override */
	events: {
		'click .upload-back-button': 'cancel',
		'click .crop-type': 'cropTypeClick_',
		'mouseover .crop-type': 'cropTypeMouseOver_',
		'change #upload-transparent': 'uploadTransparentChange_',
		'click .finish-upload-button': 'performUpload_',
		'click .cancel-button': 'cancel',
		'click .choose-button': 'chooseButtonClick'
	},

	/**
	 * @constructs MediaUploadView
	 * @extends Backbone.View
	 * @override
	 * @classdesc Triggers an 'upload' event on successful media upload, with a
	 *     payload of the file data response from the API.
	 */
	initialize: function(options) {
		if (!options.app) {
			throw new Error('No app specified')
		}

		this.views = {}
		/** @private {CropView} */
		this.cropView_ = null
		/** @private {number} */
		this.cropRatio_ = 0
		/** @private {Array.<Object>} */
		this.data_ = []
		/** @private {App} */
		this.app_ = options.app
		/** @private {TagList} */
		this.tagList_ = options.tagList
		/** @private {boolean} */
		this.restrictType_ = options.restrictType
		/** @private {string} */
		this.pageName_ = options.pageName
		/** @private {?number} */
		this.animationInterval_ = null
		/** @private {number} */
		this.animationFrame_ = 0

		this.tagList_ = new TagList()
		this.tagList_.fetch().then(this.render.bind(this))
	},

	/** @override */
	getRenderData: function() {
		var typeIds    = MediaLibrary.types,
			mediaTypes = [
				typeIds.IMAGE,
				typeIds.ICON,
				typeIds.ANIMATION,
				typeIds.VIDEO
			],
			typeData   = []

		mediaTypes.forEach(function(id) {
			var typeName          = typeIds.get(id),
				localisedTypeName = $.t('mediaLibrary.' + typeName)

			typeData.push({
				id: id,
				name: localisedTypeName
			})
		})

		return {
			url: UploadList.prototype.url(),
			mediaTypes: typeData
		}
	},

	/** @override */
	afterRender: function() {
		this.data_ = []
		this.doneCount = 0
		var self = this

		// Initialise file upload plugin
		this.$('.file-upload').fileupload({
			dataType: 'json',
			traditional: true,

			error: function(jqXHR) {
				var oops  = $.t('error.oops'),
					error = 'error'

				switch (jqXHR.status) {
					case 413:
						swal(oops, $.t('mediaLibrary.upload.filesizeError') + ' ' + App.MAX_FILESIZE + 'MB', error)
						break

					case 415:
						swal(oops, $.t('mediaLibrary.upload.formatError'), error)
						break

					default:
						swal(oops, $.t('mediaLibrary.upload.genericError') + ' ' + jqXHR.status, error)
				}

				var $uploadControl = self.$('.upload-control')

				$uploadControl.find('progress').addClass('hidden')
				$uploadControl.find('.finish-button').removeClass('hidden')
				self.$('.upload-back-button').removeAttr('disabled')
			},

			done: function(e, data) {
				if (++self.doneCount === self.data_.length) {
					// Return to library once all files uploaded
					self.cancel()

					if (self.collection) {
						self.collection.trigger('upload', data.result)
					}

					var upload = new Upload(data.result)

					self.trigger('upload', upload)
					self.render()
				}
			},

			progressall: function(e, data) {
				var progress = parseInt(data.loaded / data.total * 100, 10)

				self.$('.upload-control progress').attr('value', progress)
			},

			add: this.fileAddHandler_.bind(this)
		})

		this.setLegalMediaTypes_()
		this.setLegalCropTypes_()
		this.uploadTransparentChange_()

		// Set current crop selection.
		this.$('.crop-type[data-ratio="' + this.cropRatio_ + '"]')
			.addClass('active')

		// Populate additional tags field auto complete
		var tags = this.tagList_.map(function(tag) {
			return {value: tag.get('name')}
		})

		// Set up tag input autocomplete
		// AutoSuggest requires at least one tag to be initialised.
		if (!tags.length) {
			tags.push({value: 'Test'})
		}

		this.$('#upload-tags').autoSuggest(tags, {
			startText: $.t('mediaLibrary.upload.tags')
		})

		// Hide user agreement off-GDPC.
		if (App.system.id !== 3) {
			this.$('.user-agreement-disclaimer').remove()
		}

		// Show correct user agreement based on app type.
		if (this.app_.isHazardsApp()) {
			this.$('.first-aid-agreement, .hazards-agreement')
				.toggleClass('hidden')
		}
	},

	/**
	 * Handles 'add' events from the jQuery file upload plugin. Updates the
	 * file preview and stores the data for uploading at a later time. Triggers
	 * a 'select' event on the view to notify any listeners that one or more
	 * files have been selected.
	 * @param {jQuery.Event} e jQuery Event object.
	 * @param {Object} data Plugin file object.
	 * @private
	 */
	fileAddHandler_: function(e, data) {
		this.data_.push(data)
		this.updatePreviewContent_()
		this.trigger('select')
	},

	/**
	 * Adds a list of files to the queue to be uploaded. This method should be
	 * called with data from an HTML5 drop event or similar, which has bypassed
	 * the jQuery file upload plugin.
	 * @param {FileList} files Files to add.
	 */
	addFiles: function(files) {
		this.$('.file-upload').fileupload('add', {files: files})
	},

	/**
	 * Clears any existing media previews and (asynchronously) updates the view
	 * with previews of the currently selected media.
	 * @private
	 */
	updatePreviewContent_: function() {
		var $previewArea = this.$('.preview-area')

		$previewArea.empty()

		// Stop any running animation previews.
		clearInterval(this.animationInterval_)
		this.animationFrame_ = 0

		// Show preview if we can
		if (!window.FileReader) {
			return
		}

		var fileCount = 0,
			isImage   = /^image\//,
			isVideo   = /^video\//

		this.data_.forEach(function(fileuploadData) {
			var filesArray = Array.prototype.slice.call(fileuploadData.files, 0)

			filesArray.forEach(function() {
				var file   = fileuploadData.files[0],
					index  = fileCount++,
					reader = new FileReader(),
					tagName

				if (isImage.test(file.type)) {
					tagName = 'img'
				} else if (isVideo.test(file.type)) {
					tagName = 'video'
				} else {
					throw new Error('Unsupported file type')
				}

				reader.onload = function(e) {
					// Remove any other previews with the same index.
					$previewArea.find('[data-index=' + index + ']').remove()

					var el = document.createElement(tagName)

					if (tagName === 'video') {
						el.controls = true
					}

					el.src = e.target.result
					el.setAttribute('data-index', index.toString())
					$previewArea.append(el)
				}

				reader.readAsDataURL(file)
			})
		})

		// Start animation preview if multiple files.
		if (fileCount > 1) {
			var func = this.nextAnimationFrame_.bind(this)

			this.animationInterval_ = setInterval(func, ANIMATION_INTERVAL)
			this.nextAnimationFrame_()
		}

		if (this.cropView_) {
			this.cropView_.destroy()
			this.cropView_ = null
		}

		// Create crop view if only one image.
		var files = this.getFiles_(),
			file  = files[0]

		if (files.length === 1 && isImage.test(file.type)) {
			this.cropView_ = new CropView({
				file: this.data_[0].files[0],
				ratio: this.cropRatio_
			})

			this.$('.preview-area').addClass('hidden')
			this.$('.crop-area').append(this.cropView_.render().el)
				.removeClass('hidden')
		} else {
			this.$('.preview-area').removeClass('hidden')
		}

		// Hide "Requires transparency" option for videos.
		if (files.length > 0 && isVideo.test(files[0].type)) {
			this.$('.upload-transparent-group').addClass('hidden')
		}

		this.setLegalMediaTypes_()
		this.setLegalCropTypes_()
	},

	/**
	 * Shows the next animation frame in the preview.
	 * @private
	 */
	nextAnimationFrame_: function() {
		var files        = this.getFiles_(),
			$previewArea = this.$('.preview-area')

		$previewArea.find('img').addClass('hidden')
		$previewArea.find('[data-index=' + this.animationFrame_ + ']')
			.removeClass('hidden')

		if (++this.animationFrame_ >= files.length) {
			this.animationFrame_ = 0
		}
	},

	/**
	 * Handles click events on crop type buttons, updates the image cropper.
	 * @param {Event} e Event object.
	 * @private
	 */
	cropTypeClick_: function(e) {
		var $el = $(e.currentTarget)

		if ($el.attr('disabled')) {
			return
		}

		this.$('.crop-type').removeClass('active')
		$el.addClass('active')

		this.cropRatio_ = Number($el.attr('data-ratio'))
		this.updatePreviewContent_()
	},

	/**
	 * Hides any options from the media type dropdown which cannot be
	 * selected for this media. If the media type is restricted, the dropdown
	 * will be hidden.
	 * @private
	 */
	setLegalMediaTypes_: function() {
		// Set current media type passed from media library
		if (!isNaN(this.mediaType)) {
			this.$('#upload-media-type').val(this.mediaType.toString())
		}

		// Hide media type toggles if media type restricted.
		if (this.restrictType_) {
			this.$('.upload-media-type-group').addClass('hidden')
		}

		var files       = this.getFiles_(),
			file1       = files[0],
			types       = MediaLibrary.types,
			isVideo     = /^video\//,
			$typeSelect = this.$('#upload-media-type')

		$typeSelect.find('option').removeClass('hidden')

		if (files.length === 0) {
			return
		}

		var ANIMATION = types.ANIMATION,
			VIDEO = types.VIDEO

		if (files.length > 1) {
			// Animation
			$typeSelect.find('[value!=' + ANIMATION + ']')
				.addClass('hidden')
			$typeSelect.val(ANIMATION)
		} else if (isVideo.test(file1.type)) {
			// Video
			$typeSelect.find('[value!=' + VIDEO + ']').addClass('hidden')
			$typeSelect.val(types.VIDEO)
		} else {
			// Image/icon
			$typeSelect.find('[value=' + ANIMATION + '],[value=' + VIDEO + ']')
				.addClass('hidden')
		}
	},

	/**
	 * Hides any crop types which cannot be selected for this media type.
	 * @private
	 */
	setLegalCropTypes_: function() {
		// Only show one of the generic/GDPC crop description sets.
		if (App.system.id === 3) {
			this.$('.crop-description [data-generic]').remove()
		} else {
			this.$('.crop-description [data-gdpc]').remove()
		}

		var isVideo = /^video\//,
			files   = this.getFiles_(),
			file    = files[0]

		this.$('.upload-crop-type-group, .crop-type').removeClass('hidden')

		if (files.length === 0) {
			return
		}

		if (files.length > 1 || isVideo.test(file.type)) {
			this.$('.upload-crop-type-group').addClass('hidden')
		}
	},

	/**
	 * @returns {Array.<File>} Array of {@link File} objects, all files from
	 *     all file upload data entries.
	 * @private
	 */
	getFiles_: function() {
		return this.data_.reduce(function(prev, current) {
			var files = Array.prototype.slice.call(current.files, 0)

			return prev.concat(files)
		}, [])
	},

	/**
	 * Triggers file selection using the native file input.
	 */
	chooseButtonClick: function() {
		this.data_ = []

		// Trigger file input
		this.$('.file-upload').trigger('click')
	},

	/**
	 * Performs the image crop (if selected), creates any new tags in the API
	 * and uploads the media.
	 * @private
	 */
	performUpload_: function() {
		var URL = window.URL || window.webkitURL

		// Perform crop, if required.
		if (this.cropRatio_ !== 0) {
			var blob = this.cropView_.crop()

			this.handleCroppedBlob_(blob)

			// Update preview.
			var preview = this.$('.preview-area img')[0]

			preview.onload = function() {
				var scale = Math.min(400 / preview.naturalWidth, 1)

				preview.width = Math.floor(preview.naturalWidth * scale)
				preview.height = Math.floor(preview.naturalHeight * scale)

				this.$('.preview-area').removeClass('hidden')
				this.$('.crop-area').addClass('hidden')
			}.bind(this)

			preview.src = URL.createObjectURL(blob)
		}

		var tagsVal = $('.as-values').last().val() || '' // Get the last Tag DOM value.
		// Trigger tab keypress to add any unfinished tags
		if (tagsVal !== '') {
			var e = new jQuery.Event('keydown')
			e.keyCode = 9
			this.$('.as-input').trigger(e)
		}

		// Check for invalid tags (containing wildcard).

		if (tagsVal.indexOf('*') > -1) {
			swal($.t('error.oops'), $.t('mediaLibrary.upload.invalidTag'), 'error')
			return
		}

		// Get tags to save with media.
		var tagNames = tagsVal.split(','),
			tags     = []

		// Don't allow the user to tag images with blacklisted tags.
		for (var i = 0; i < tagNames.length; i++) {
			var tag = tagNames[i].toLowerCase()

			if (tagBlacklist.indexOf(tag) > -1 || tag.match(/^animation-/) !== null) {
				tagNames.splice(i, 1)
				i--
			}
		}

		// Tag the media with its media type.
		var mediaType       = Number(this.$('#upload-media-type').val()),
			mediaTypeString = MediaLibrary.types.get(mediaType)

		tagNames.push(mediaTypeString)

		// If this is an animation, assign a group animation tag and frame IDs.
		if (mediaType === MediaLibrary.types.ANIMATION) {
			var uid = Math.random().toString(36).substr(2, 6)
			tagNames.push('animation-' + uid)

			for (var j = 0; j < this.data_.length; j++) {
				tagNames.push(uid + '-frame-' + j)
			}
		}

		// Get current page name
		if (this.pageName_) {
			tagNames.push(this.pageName_)
		}

		// Tag with society name
		// var app     = this.app_
		// society = App.societiesList.get(app.get('societyId'))

		// tagNames.push(society.get('name'))

		// Tag with app type.
		// var appTypes = [
		// 	'First aid',
		// 	'Hazards'
		// ]
		//
		// var type = appTypes[app.get('type')]
		//
		// if (type) {
		// 	tagNames.push(type)
		// }

		// Get a tag-creation promise for each tag name specified.
		var tagPromises = tagNames.map(function(tagName) {
			var promise = new jQuery.Deferred()

			if (tagName) {
				tagName = tagName.toLowerCase()
				// Check if tag already exists. If not, create it.
				var tag = this.tagList_.findWhere({name: tagName})

				if (tag) {
					tags.push(tag.id)
					promise.resolve()
				} else {
					tag = this.tagList_.create({type: 'files', name: tagName})
					tag.once('sync', function() {
						tags.push(tag.id)
						promise.resolve()
					})
				}
			} else {
				// Empty tag - resolve immediately.
				promise.resolve()
			}

			return promise
		}, this)

		// Upload each cached data object once all new tags have saved.
		$.when.apply($, tagPromises).then(function() {
			this.data_.forEach(function(data, index) {
				// Concat all tag IDs into a single string value.
				var tagsString = ''

				tags.forEach(function(tagId) {
					// Only append frame index tags if it matches the image
					// index.
					var tag        = this.tagList_.get(tagId),
						frameMatch = tag.get('name').match(/frame-(\d+)$/)

					if (frameMatch === null || frameMatch[1] === index.toString()) {
						tagsString += tagId + ','
					}
				}, this)

				var requiresTransparency = this.$('#upload-transparent')[0].checked

				data.formData = [
					{name: 'Authorization', value: App.session.get('token')},
					{name: 'appId', value: this.app_.id},
					{name: 'tags', value: tagsString.slice(0, -1)},
					{name: 'transparent', value: requiresTransparency}
				]
				this.$('.file-upload').fileupload('send', data)
			}, this)
		}.bind(this))

		this.$('.upload-back-button').attr('disabled', true)

		var $uploadControl = this.$('.upload-control')

		$uploadControl.find('progress').removeClass('hidden')
		$uploadControl.find('.finish-button').addClass('hidden')
	},

	/**
	 * Hides, resets the view and triggers a 'close' event.
	 */
	cancel: function() {
		this.render()
		this.$el.hide()
		this.trigger('close')
	},

	/** @override */
	beforeDestroy: function() {
		if (this.cropView_) {
			this.cropView_.destroy()
			this.cropView_ = null
		}

		try {
			this.$('.file-upload').fileupload('destroy')
		} catch (e) {
		}
	},

	/**
	 * Replaces the currently cached file with the cropped blob before
	 * uploading.
	 * @param {Blob} blob The cropped image as a blob.
	 * @private
	 */
	handleCroppedBlob_: function(blob) {
		var filename           = this.data_[0].files[0].name,
			filenameComponents = filename.match(/(.+)(\.\w+)/)

		// Set (modified) filename on blob.
		blob.name = filenameComponents[1] + '_crop_' + this.cropRatio_ + filenameComponents[2]

		// Replace original file object with cropped image blob.
		this.data_[0].files[0] = blob
	},

	/**
	 * Handles mouseover events on crop type buttons. Displays the correct crop
	 * description.
	 * @param {Event} e Event object.
	 * @private
	 */
	cropTypeMouseOver_: function(e) {
		var $el              = $(e.currentTarget),
			type             = $el.data('type'),
			$cropDescription = this.$('.crop-description')

		$cropDescription.find('[data-type]').removeClass('visible')
		$cropDescription.find('[data-type=' + type + ']').addClass('visible')
	},

	/**
	 * Handles change events on the "Requires transparency" checkbox. Toggles a
	 * solid white background on the preview area/crop canvas.
	 * @private
	 */
	uploadTransparentChange_: function() {
		var isChecked = this.$('#upload-transparent')[0].checked

		this.$el.toggleClass('no-transparency', !isChecked)
	}
})
