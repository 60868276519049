<option value="AFG">Afghanistan</option>
<option value="ALB">Albania, People's Socialist Republic of</option>
<option value="DZA">Algeria, People's Democratic Republic of</option>
<option value="ASM">American Samoa</option>
<option value="AND">Andorra, Principality of</option>
<option value="AGO">Angola, Republic of</option>
<option value="AIA">Anguilla</option>
<option value="ATA">Antarctica (the territory South of 60 deg S)</option>
<option value="ATG">Antigua and Barbuda</option>
<option value="ARG">Argentina, Argentine Republic</option>
<option value="ARM">Armenia</option>
<option value="ABW">Aruba</option>
<option value="AUS">Australia, Commonwealth of</option>
<option value="AUT">Austria, Republic of</option>
<option value="AZE">Azerbaijan, Republic of</option>
<option value="BHS">Bahamas, Commonwealth of the</option>
<option value="BHR">Bahrain, Kingdom of</option>
<option value="BGD">Bangladesh, People's Republic of</option>
<option value="BRB">Barbados</option>
<option value="BLR">Belarus</option>
<option value="BEL">Belgium, Kingdom of</option>
<option value="BLZ">Belize</option>
<option value="BEN">Benin (was Dahomey), People's Republic of</option>
<option value="BMU">Bermuda</option>
<option value="BTN">Bhutan, Kingdom of</option>
<option value="BOL">Bolivia, Republic of</option>
<option value="BIH">Bosnia and Herzegovina</option>
<option value="BWA">Botswana, Republic of</option>
<option value="BVT">Bouvet Island (Bouvetoya)</option>
<option value="BRA">Brazil, Federative Republic of</option>
<option value="IOT">British Indian Ocean Territory (Chagos Archipelago)</option>
<option value="VGB">British Virgin Islands</option>
<option value="BRN">Brunei Darussalam</option>
<option value="BGR">Bulgaria, People's Republic of</option>
<option value="BFA">Burkina Faso (was Upper Volta)</option>
<option value="BDI">Burundi, Republic of</option>
<option value="KHM">Cambodia, Kingdom of (was Khmer Republic/Kampuchea, Democratic)</option>
<option value="CMR">Cameroon, United Republic of</option>
<option value="CAN">Canada</option>
<option value="CPV">Cape Verde, Republic of</option>
<option value="CYM">Cayman Islands</option>
<option value="CAF">Central African Republic</option>
<option value="TCD">Chad, Republic of</option>
<option value="CHL">Chile, Republic of</option>
<option value="CHN">China, People's Republic of</option>
<option value="CXR">Christmas Island</option>
<option value="CCK">Cocos (Keeling) Islands</option>
<option value="COL">Colombia, Republic of</option>
<option value="COM">Comoros, Union of the</option>
<option value="COD">Congo, Democratic Republic of (was Zaire)</option>
<option value="COG">Congo, People's Republic of</option>
<option value="COK">Cook Islands</option>
<option value="CRI">Costa Rica, Republic of</option>
<option value="CIV">Cote D'Ivoire, Ivory Coast, Republic of the</option>
<option value="CUB">Cuba, Republic of</option>
<option value="CYP">Cyprus, Republic of</option>
<option value="CZE">Czech Republic</option>
<option value="DNK">Denmark, Kingdom of</option>
<option value="DJI">Djibouti, Republic of (was French Afars and Issas)</option>
<option value="DMA">Dominica, Commonwealth of</option>
<option value="DOM">Dominican Republic</option>
<option value="ECU">Ecuador, Republic of</option>
<option value="EGY">Egypt, Arab Republic of</option>
<option value="SLV">El Salvador, Republic of</option>
<option value="GNQ">Equatorial Guinea, Republic of</option>
<option value="ERI">Eritrea</option>
<option value="EST">Estonia</option>
<option value="ETH">Ethiopia</option>
<option value="FRO">Faeroe Islands</option>
<option value="FLK">Falkland Islands (Malvinas)</option>
<option value="FJI">Fiji, Republic of the Fiji Islands</option>
<option value="FIN">Finland, Republic of</option>
<option value="FRA">France, French Republic</option>
<option value="GUF">French Guiana</option>
<option value="PYF">French Polynesia</option>
<option value="ATF">French Southern Territories</option>
<option value="GAB">Gabon, Gabonese Republic</option>
<option value="GMB">Gambia, Republic of the</option>
<option value="GEO">Georgia</option>
<option value="DEU">Germany</option>
<option value="GHA">Ghana, Republic of</option>
<option value="GIB">Gibraltar</option>
<option value="GRC">Greece, Hellenic Republic</option>
<option value="GRL">Greenland</option>
<option value="GRD">Grenada</option>
<option value="GLP">Guadaloupe</option>
<option value="GUM">Guam</option>
<option value="GTM">Guatemala, Republic of</option>
<option value="GIN">Guinea, Revolutionary People's Rep'c of</option>
<option value="GNB">Guinea-Bissau, Republic of (was Portuguese Guinea)</option>
<option value="GUY">Guyana, Republic of</option>
<option value="HTI">Haiti, Republic of</option>
<option value="HMD">Heard and McDonald Islands</option>
<option value="VAT">Holy See (Vatican City State)</option>
<option value="HND">Honduras, Republic of</option>
<option value="HKG">Hong Kong, Special Administrative Region of China</option>
<option value="HRV">Hrvatska (Croatia)</option>
<option value="HUN">Hungary, Hungarian People's Republic</option>
<option value="ISL">Iceland, Republic of</option>
<option value="IND">India, Republic of</option>
<option value="IDN">Indonesia, Republic of</option>
<option value="IRN">Iran, Islamic Republic of</option>
<option value="IRQ">Iraq, Republic of</option>
<option value="IRL">Ireland</option>
<option value="ISR">Israel, State of</option>
<option value="ITA">Italy, Italian Republic</option>
<option value="JAM">Jamaica</option>
<option value="JPN">Japan</option>
<option value="JOR">Jordan, Hashemite Kingdom of</option>
<option value="KAZ">Kazakhstan, Republic of</option>
<option value="KEN">Kenya, Republic of</option>
<option value="KIR">Kiribati, Republic of (was Gilbert Islands)</option>
<option value="PRK">Korea, Democratic People's Republic of</option>
<option value="KOR">Korea, Republic of</option>
<option value="KWT">Kuwait, State of</option>
<option value="KGZ">Kyrgyz Republic</option>
<option value="LAO">Lao People's Democratic Republic</option>
<option value="LVA">Latvia</option>
<option value="LBN">Lebanon, Lebanese Republic</option>
<option value="LSO">Lesotho, Kingdom of</option>
<option value="LBR">Liberia, Republic of</option>
<option value="LBY">Libyan Arab Jamahiriya</option>
<option value="LIE">Liechtenstein, Principality of</option>
<option value="LTU">Lithuania</option>
<option value="LUX">Luxembourg, Grand Duchy of</option>
<option value="MAC">Macao, Special Administrative Region of China</option>
<option value="MKD">Macedonia, the former Yugoslav Republic of</option>
<option value="MDG">Madagascar, Republic of</option>
<option value="MWI">Malawi, Republic of</option>
<option value="MYS">Malaysia</option>
<option value="MDV">Maldives, Republic of</option>
<option value="MLI">Mali, Republic of</option>
<option value="MLT">Malta, Republic of</option>
<option value="MHL">Marshall Islands</option>
<option value="MTQ">Martinique</option>
<option value="MRT">Mauritania, Islamic Republic of</option>
<option value="MUS">Mauritius</option>
<option value="MYT">Mayotte</option>
<option value="MEX">Mexico, United Mexican States</option>
<option value="FSM">Micronesia, Federated States of</option>
<option value="MDA">Moldova, Republic of</option>
<option value="MCO">Monaco, Principality of</option>
<option value="MNG">Mongolia, Mongolian People's Republic</option>
<option value="MSR">Montserrat</option>
<option value="MAR">Morocco, Kingdom of</option>
<option value="MOZ">Mozambique, People's Republic of</option>
<option value="MMR">Myanmar (was Burma)</option>
<option value="NAM">Namibia</option>
<option value="NRU">Nauru, Republic of</option>
<option value="NPL">Nepal, Kingdom of</option>
<option value="ANT">Netherlands Antilles</option>
<option value="NLD">Netherlands, Kingdom of the</option>
<option value="NCL">New Caledonia</option>
<option value="NZL">New Zealand</option>
<option value="NIC">Nicaragua, Republic of</option>
<option value="NER">Niger, Republic of the</option>
<option value="NGA">Nigeria, Federal Republic of</option>
<option value="NIU">Niue, Republic of</option>
<option value="NFK">Norfolk Island</option>
<option value="MNP">Northern Mariana Islands</option>
<option value="NOR">Norway, Kingdom of</option>
<option value="OMN">Oman, Sultanate of (was Muscat and Oman)</option>
<option value="PAK">Pakistan, Islamic Republic of</option>
<option value="PLW">Palau</option>
<option value="PSE">Palestinian Territory, Occupied</option>
<option value="PAN">Panama, Republic of</option>
<option value="PNG">Papua New Guinea</option>
<option value="PRY">Paraguay, Republic of</option>
<option value="PER">Peru, Republic of</option>
<option value="PHL">Philippines, Republic of the</option>
<option value="PCN">Pitcairn Island</option>
<option value="POL">Poland, Polish People's Republic</option>
<option value="PRT">Portugal, Portuguese Republic</option>
<option value="PRI">Puerto Rico</option>
<option value="QAT">Qatar, State of</option>
<option value="REU">Reunion</option>
<option value="ROU">Romania, Socialist Republic of</option>
<option value="RUS">Russian Federation</option>
<option value="RWA">Rwanda, Rwandese Republic</option>
<option value="SHN">St. Helena</option>
<option value="KNA">St. Kitts and Nevis</option>
<option value="LCA">St. Lucia</option>
<option value="SPM">St. Pierre and Miquelon</option>
<option value="VCT">St. Vincent and the Grenadines</option>
<option value="WSM">Samoa, Independent State of (was Western Samoa)</option>
<option value="SMR">San Marino, Republic of</option>
<option value="STP">Sao Tome and Principe, Democratic Republic of</option>
<option value="SAU">Saudi Arabia, Kingdom of</option>
<option value="SEN">Senegal, Republic of</option>
<option value="SCG">Serbia and Montenegro</option>
<option value="SYC">Seychelles, Republic of</option>
<option value="SLE">Sierra Leone, Republic of</option>
<option value="SGP">Singapore, Republic of</option>
<option value="SVK">Slovakia (Slovak Republic)</option>
<option value="SVN">Slovenia</option>
<option value="SLB">Solomon Islands (was British Solomon Islands)</option>
<option value="SOM">Somalia, Somali Republic</option>
<option value="ZAF">South Africa, Republic of</option>
<option value="SGS">South Georgia and the South Sandwich Islands</option>
<option value="ESP">Spain, Spanish State</option>
<option value="LKA">Sri Lanka, Democratic Socialist Republic of (was Ceylon)</option>
<option value="SDN">Sudan, Democratic Republic of the</option>
<option value="SUR">Suriname, Republic of</option>
<option value="SJM">Svalbard &amp; Jan Mayen Islands</option>
<option value="SWZ">Swaziland, Kingdom of</option>
<option value="SWE">Sweden, Kingdom of</option>
<option value="CHE">Switzerland, Swiss Confederation</option>
<option value="SYR">Syrian Arab Republic</option>
<option value="TWN">Taiwan, Province of China</option>
<option value="TJK">Tajikistan</option>
<option value="TZA">Tanzania, United Republic of</option>
<option value="THA">Thailand, Kingdom of</option>
<option value="TLS">Timor-Leste, Democratic Republic of</option>
<option value="TGO">Togo, Togolese Republic</option>
<option value="TKL">Tokelau (Tokelau Islands)</option>
<option value="TON">Tonga, Kingdom of</option>
<option value="TTO">Trinidad and Tobago, Republic of</option>
<option value="TUN">Tunisia, Republic of</option>
<option value="TUR">Turkey, Republic of</option>
<option value="TKM">Turkmenistan</option>
<option value="TCA">Turks and Caicos Islands</option>
<option value="TUV">Tuvalu (was part of Gilbert &amp; Ellice Islands)</option>
<option value="VIR">US Virgin Islands</option>
<option value="UGA">Uganda, Republic of</option>
<option value="UKR">Ukraine</option>
<option value="ARE">United Arab Emirates (was Trucial States)</option>
<option value="GBR">United Kingdom of Great Britain &amp; N. Ireland</option>
<option value="UMI">United States Minor Outlying Islands</option>
<option value="USA">United States of America</option>
<option value="URY">Uruguay, Eastern Republic of</option>
<option value="UZB">Uzbekistan</option>
<option value="VUT">Vanuatu (was New Hebrides)</option>
<option value="VEN">Venezuela, Bolivarian Republic of</option>
<option value="VNM">Viet Nam, Socialist Republic of (was Democratic Republic of &amp; Republic of)</option>
<option value="WLF">Wallis and Futuna Islands</option>
<option value="ESH">Western Sahara (was Spanish Sahara)</option>
<option value="YEM">Yemen</option>
<option value="ZMB">Zambia, Republic of</option>
<option value="ZWE">Zimbabwe (was Southern Rhodesia)</option>
