<div class="media-preview"></div>
<div class="row image-delay-container">
	<h5 class="span2">{{t "editor.inspector.properties.animation.delay"}}</h5>
	<input type="number" class="span1 image-delay" min="0" value="1">
</div>
<div class="row">
	<div class="col-xs-6">
		<button class="button block image-select-button">{{t "editor.inspector.properties.image.chooseButton"}}</button>
	</div>
	<div class="col-xs-6">
		<button class="button block animation-select-button">{{t "editor.inspector.properties.animation.chooseButton"}}</button>
	</div>
</div>
