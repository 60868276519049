var VideoInspector = require('./video-inspector-view')

module.exports = Backbone.View.extend({
	template: require('./multi-video-selector-child-view-template'),

	events: {
		'click .remove-video-item': 'removeVideo',
		'change .video-url': 'updateVideoUrl',
		'change .video-language': 'updateVideoLanguage'
	},

	initialize: function() {
		this.views = {}
	},

	afterRender: function() {
		var view = this.views.videoInspector = new VideoInspector({model: this.model})
		this.$('.child-video-selector').append(view.render().el)

		// Remove automatic inspector property setting.
		this.$('.video-url').removeAttr('data-property')

		// Populate language list
		var app = Storm.app,
			society = App.societiesList.get(app.get('societyId'))

		app.languageList.forEach(function(lang) {
			this.$('.video-language').append('<option value="' + society.get('locale').toLowerCase() + '_' + lang.get('code').toLowerCase() + '">' + lang.get('name') + '</option>')
		}, this)

		// Select current locale
		this.$('.video-language').val(this.model.get('locale'))
	},

	removeVideo: function() {
		// Disable remove video until the last one has been deleted
		this.model.collection.remove(this.model)
		this.destroy()
	},

	updateVideoUrl: function() {
		var url = this.$('.video-url').val().trim()

		// Reformat short youtu.be URLs.
		var shortMatch = url.match(/\/\/youtu.be\/(.*)$/)

		if (shortMatch !== null) {
			url = 'https://youtube.com/watch?v=' + shortMatch[1]
		}

		this.model.get('src').destination = url

		this.model.collection.parent.needsSaving = true
	},

	updateVideoLanguage: function() {
		var lang = this.$('.video-language').val()
		this.model.set('locale', lang)

		this.model.collection.parent.needsSaving = true
	}
})
