{{#ifExists image}}
	{{! Support legacy image format }}
	{{#ifExists image.src}}
		<img src="{{getPreviewUrl image.src}}">
	{{else}}
		<img src="{{getPreviewUrl image}}">
	{{/ifExists}}
{{else}}
	<i class="icon-picture" style="font-size:12em"></i>
{{/ifExists}}
<div class="List">
	<ul class="list-unstyled">
		<li class="ListItem TitleListItem" style="text-align:center">{{initialPosition}} {{{getLocaleTextHTML unit}}}</li>
		<li class="ListItem slider"><input type="range" min="{{range.start}}" max="{{add range.start range.length}}" {{#ifExists range.increment}} step="{{range.increment}}" {{/ifExists}} value="{{initialPosition}}"></li>
	</ul>
</div>
