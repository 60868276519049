module.exports = Backbone.Collection.extend({
	url: function() {
		return App.apiRoot + 'alerts'
	},

	parse: function(response) {
		// Filter out all disaster ID < 0 (invalid).
		for (var i = 0; i < response.length; i++) {
			if (response[i].category < 0) {
				response.splice(i, 1)
				i--
			}
		}

		return response
	}
})
