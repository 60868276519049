var AppModel = require('./app')

module.exports = Backbone.Collection.extend({
	model: AppModel,

	initialize: function(models, options) {
		options = options || {}
		this.id = options.id
	},

	url: function() {
		if (this.id === 'all') {
			return App.apiRoot + 'apps'
		}

		return App.apiRoot + 'users/' + ((this.id) ? this.id : 'me') + '/apps'
	}
})
