/**
 * How often to check for session expiry, in seconds.
 * @const {number}
 * @default
 */
var EXPIRY_CHECK_INTERVAL = 5

/**
 * The number of seconds within which to treat a token as expired (to allow for
 * variances in clocks between client/server).
 * @const {number}
 * @default
 */
var EXPIRY_THRESHOLD = 30

/**
 * Exports {@link Session}.
 * @module
 */
module.exports = Backbone.Model.extend(/** @lends Session.prototype */{
	noAuth: true,

	/** @override */
	url: function() {
		return App.authRoot + 'authentication'
	},

	/** @override */
	defaults: {
		isAuthenticated: false,
		expires: 0,
		token: null
	},

	/**
	 * @constructs Session
	 * @extends Backbone.Model
	 * @override
	 */
	initialize: function() {
		this.checkExpiryInterval = null
	},

	/**
	 * Fetches a new session token for a username/password pair.
	 * @param {string} username The username to authenticate with.
	 * @param {string} password The password to authenticate with.
	 * @returns {Promise} Promise which will resolve on authentication
	 *     success, and reject on failure.
	 */
	authenticate: function(username, password) {
		var data = JSON.stringify({
			username: username,
			password: password
		})

		var jqXHR = this.save(null, {
			data: data,
			global: false
		})

		jqXHR.fail(this.setLoginThrottle.bind(this))

		return Promise.resolve(jqXHR)
	},

	/**
	 * Refreshes an existing session token to extend its validity. Clears the
	 * session if renewal fails.
	 * @returns {Promise} Promise which resolves on renew success and rejects
	 *     on failure.
	 */
	renew: function() {
		if (!this.isAuthenticated()) {
			return Promise.reject()
		}

		var jqXHR = this.save(null, {
			url: this.url() + '/renew',
			headers: this.getHeadersObject(),
			global: false
		})

		jqXHR.fail(this.clearSession.bind(this))

		return Promise.resolve(jqXHR)
	},

	/**
	 * Verifies the validity of an existing token.
	 * @returns {Promise} Promise which resolves on successful validation, and
	 *     rejects on failure.
	 */
	verify: function() {
		if (!this.get('token')) {
			return Promise.reject()
		}

		var jqXHR = this.fetch({
			headers: this.getHeadersObject(),
			global: false
		})

		jqXHR.fail(this.clearSession.bind(this))

		return Promise.resolve(jqXHR)
	},

	/**
	 * @returns {number} The integer number of seconds remaining before the
	 *     current token expires.
	 */
	getTimeRemaining: function() {
		var now       = Math.floor(Date.now() / 1000),
			remaining = this.get('expires') - now - EXPIRY_THRESHOLD

		return Math.max(remaining, 0)
	},

	/**
	 * @returns {boolean} Whether or not the user has a session token.
	 */
	isAuthenticated: function() {
		return this.get('isAuthenticated')
	},

	/**
	 * Clears the session and reset it to an unauthorized state.
	 */
	clearSession: function() {
		this.set(this.defaults)
	},

	/**
	 * Handles auth failure, updates the delay until next attempt is allowed.
	 * @param {Object} jqXHR jQuery XHR object containing the response from the
	 *     authentication request.
	 */
	setLoginThrottle: function(jqXHR) {
		var response = JSON.parse(jqXHR.responseText)

		if (response['client error'] && response['client error'].data) {
			var remaining = response['client error'].data.remaining || 0,
				now       = Math.floor(Date.now() / 1000)

			this.set('loginThrottle', now + remaining)
		}
	},

	/**
	 * Checks token expiry time and triggers relevant events.
	 */
	checkExpiry: function() {
		if (this.getTimeRemaining() <= 0) {
			this.set({
				isAuthenticated: false,
				token: null
			})

			clearInterval(this.checkExpiryInterval)
		}
	},

	/**
	 * Modify server response to fit a flat model.
	 * @override
	 */
	parse: function(response) {
		var func  = this.checkExpiry.bind(this),
			delay = EXPIRY_CHECK_INTERVAL * 1000,
			now   = Math.floor(Date.now() / 1000)

		this.checkExpiryInterval = setInterval(func, delay)

		// Payload from verify is different - reformat.
		if (response.remaining) {
			response = {
				expires: response
			}
		}

		response.isAuthenticated = true
		response.expires = now + response.expires.remaining

		return response
	},

	/**
	 * @returns {Object.<string, string>} Populated hash of request headers
	 *     required for authenticated requests in this session.
	 */
	getHeadersObject: function() {
		var headers = {}

		if (this.get('token') !== null) {
			headers.Authorization = this.get('token')
		}

		return headers
	}
})
