<table>
    <thead>
    <tr>
        <th class="narrow">{{t "achievements.image"}}</th>
        <th>{{t "achievements.name"}}</th>
        <th>{{t "common.tools"}}</th>
    </tr>
    </thead>
    <tbody class="list-items"></tbody>
</table>
