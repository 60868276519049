module.exports = Backbone.View.extend({
	template: require('./raised-trigger-view-template'),
	events: {
		'change :input': 'updateModel',
		'click .remove-trigger': 'removeTrigger'
	},

	initialize: function(options) {
		this.trigger = options.trigger
		this.index = options.index
	},

	getRenderData: function() {
		return _.extend(this.trigger.toJSON(), {
			index: this.index,
			currencies: [
				{code: 'GBP', description: 'GBP British Pounds'},
				{code: 'USD', description: 'USD US Dollars'},
				{code: 'HKD', description: 'HKD Hong Kong Dollars'},
				{code: 'SGD', description: 'SGD Singapore Dollars'},
				{code: 'CAD', description: 'CAD Canadian Dollars'},
				{code: 'AED', description: 'AED UAE Dirham'},
				{code: 'AUD', description: 'AUD Australian Dollars'},
				{code: 'ZAR', description: 'ZAR South African Rand'},
				{code: 'EUR', description: 'EUR Euro'}
			]
		})
	},

	updateModel: function(e) {
		this.trigger.set(e.target.name, e.target.value)
	},

	removeTrigger: function() {
		this.trigger.destroy()
		this.remove()
	}
})
