<div class="row">
	<div class="span1">
		<h6>{{t "gdpcConsole.devPools.language"}}</h6>
	</div>
	<div class="span3">
		<select class="form-control video-language">
			<option value=""></option>
		</select>
	</div>
</div>
<div class="child-video-selector"></div>
<button class="button red remove-video-item"><i class="icon-remove"></i> {{t "mediaLibrary.delete"}}</button>
<hr>
