var ListView         = require('./list-view'),
	GridView         = require('./grid-view'),
	QuizItemView     = require('./quiz-item-view'),
	Inspector        = require('editor/inspector/inspector-view'),
	ViewPicker       = require('editor/view-picker'),
	TabPagePreview   = require('./tab-page-preview'),
	StormObject      = require('editor/storm-object'),
	CanvasItemView   = require('./canvas-item-view'),
	Page             = require('editor/page-list/page'),
	StormObjectUtils = require('lib/storm-object-utils')

module.exports = CanvasItemView.extend({
	className: 'canvas',
	template: require('./canvas-view-template'),

	events: function() {
		var events = CanvasItemView.prototype.events()

		delete events.click
		events['click .preview'] = 'editPage'
		events['click .device-style button'] = 'deviceStyleButtonClick'
		events['mouseover .preview-container'] = 'mouseover'
		events.mouseout = 'mouseout'
		events['click .dot'] = 'quizQuestionDotClick'
		events['click .add-object-button'] = 'addObjectButtonClick'

		return events
	},

	initialize: function(options) {
		options = options || {}

		if (!options.app) {
			throw new Error('No App specified')
		}

		this.views = {}
		this.listViews = []

		this.deviceStyle = 'ios'
		this.app = options.app
		this.listDrake = null
		this.listItemDrake = null
		this.quizItemDrake = null

		/** @private {StormObject} */
		this.selectedQuizItemId_ = null

		// Need to re-render on language change
		this.on('change:language', function(language) {
			this.language = language

			// Remove old inspector
			if (this.views.inspector) {
				this.views.inspector.destroy()
				delete this.views.inspector
			}

			// Re-render preview
			var isEditing = !this.$('.preview').hasClass('preview-mode')

			this.render()

			if (isEditing) {
				this.$('.preview').removeClass('preview-mode')
			}
		})
	},

	deviceStyleButtonClick: function(e) {
		var $button = $(e.currentTarget)

		this.$('.device-style .active').removeClass('active')
		$button.addClass('active')
		this.setDeviceStyle($button.val())
	},

	// Set the preview styling to
	setDeviceStyle: function(device) {
		this.$('.preview')
			.removeClass(this.deviceStyle)
			.addClass(device)

		this.deviceStyle = device
	},

	/**
	 * @param {Page} page The page to render into this canvas.
	 */
	setPage: function(page) {
		if (page !== null && !(page instanceof Page)) {
			throw new Error('Invalid parameter type')
		}

		this.selectedQuizItemId_ = null

		// Leave edit mode, if we're in it.
		this.trigger('enterPreviewMode')

		// Unbind event handlers from previous page
		if (this.model) {
			if (this.model.has('children')) {
				this.model.get('children').off()
			}

			this.model.selected = false

			// If the page was locked, unlock it so others can edit it
			StormObject.then(function() {
				if (!this.model) {
					return
				}

				if (this.model.hasLock()) {
					this.model.unlock()
				}
			}.bind(this))
		}

		// Remove previous list views
		if (this.listViews) {
			_.each(this.listViews, function(view) {
				view.destroy()
			})

			this.listViews = []
		}

		// Set new model and render
		this.model = page
		this.render()

		// Don't bind change handlers if there's no page
		if (page === null) {
			return
		}

		// Update page title when changed
		this.listenTo(this.model, 'change', this.updateTitle, this)
		this.listenTo(this.model, 'change:style', this.updatePageStyle, this)

		// Set inspector initially to page object
		this.setInspector(page)
	},

	// Trash the current model, reload, re-render.
	reloadPage: function() {
		this.model.attributes = {id: this.model.id}
		return this.model.fetch().then(this.render.bind(this))
	},

	getRenderData: function() {
		// Return model JSON with language key
		if (this.model) {
			var data = this.model.toJSON()

			data.language = this.language
			return data
		}

		return {}
	},

	afterRender: function() {
		// Clear previous preview content
		this.listViews.forEach(function(view) {
			view.destroy()
		})

		this.listViews = []
		this.$('.preview-content').empty()

		// Only render anything if a page has been selected
		if (this.model) {
			if (this.model.has('children')) {
				// Re-render the preview whenever the root collection changes
				this.model.get('children').off('add remove reset')
				this.model.get('children').on('add remove reset', this.afterRender, this)
			}

			switch (this.model.get('class')) {
				case 'TabbedPageCollection':
					this.renderTabbedPageCollection()
					break

				case 'ListPage':
					this.renderListPage()
					break

				case 'QuizPage':
					this.renderQuizPage()
					break

				case 'NativePage':
					this.renderNativePage()
					break

				case 'GridPage':
					this.renderGridPage()
					break
			}

			// Hide add buttons if restricted
			var restrictions = this.model.get('restrictions')

			if (restrictions) {
				if (restrictions.indexOf('add') > -1) {
					this.$('.add-tab').hide()
				}
			}

			// Switch text direction to RTL for Arabic/Hebrew
			if (this.language === 'ar' || this.language === 'he' || this.language === 'fa') {
				this.$('.preview').addClass('rtl')
			}

			// Don't allow the user to leave preview mode unless they have
			// write permission
			var permission = App.acl.getPermission('Content')

			if (permission !== 'Write' && permission !== 'Delete') {
				this.$('.preview-button').remove()
			}

			// Set initial device style.
			this.setDeviceStyle('ios')

			// Set classes for custom page styles, if applicable.
			var attributes = this.model.get('attributes') || []

			if (attributes.indexOf('STYLE_PAPER') > -1) {
				this.$('.preview').addClass('style-paper')
			}

			// Icons on Hazards app pages need masking.
			if (this.app.isHazardsApp() && App.system.id === 3) {
				this.$('.preview').addClass('mask-images')
			}

			// Add editing state if the inspector's open
			if (this.editing) {
				this.startEditing()
			}
		}
	},

	renderTabbedPageCollection: function() {
		// Output tab page preview
		var page = new TabPagePreview({pages: this.model.get('pages')})

		this.listViews.push(page)
		this.$('.preview-content').html(page.render().el)
	},

	renderListPage: function() {
		this.model.get('children').each(function(model) {
			var childView = new ListView({model: model})

			this.listViews.push(childView)

			this.$('.preview-content').append(childView.render().el)

			// Render in edit mode if appropriate.
			if (this.editing) {
				childView.startEditing()
			}
		}, this)
	},

	renderQuizPage: function() {
		var $quizQuestionSelector = $('<div>')
			.addClass('quiz-question-selector')

		var $quizQuestionPreview = $('<div>')
			.addClass('quiz-question-preview')

		this.$('.preview-content')
			.append($quizQuestionSelector)
			.append($quizQuestionPreview)

		var items = this.model.get('children')

		items.each(function(model, i) {
			// Render question to DOM
			var questionView = new QuizItemView({model: model})

			$quizQuestionPreview.append(questionView.render().el)
			this.listViews.push(questionView)

			// Create a dot in the question selector
			var $dot = $('<span>')
				.addClass('dot')
				.attr('data-index', i)
				.html('&bull;')

			$dot[0].model = model
			$quizQuestionSelector.append($dot)
		}, this)

		// Display currently selected question.
		if (this.selectedQuizItemId_ === null && items.length > 0) {
			this.selectedQuizItemId_ = items.first().id
		}

		var item = items.get(this.selectedQuizItemId_)

		if (!item) {
			// Model not found — look for something without an ID.
			item = items.find(function(item) {
				return item.id === undefined
			})
		}

		var selectedItemIndex = items.indexOf(item)

		this.$('.QuizItem').eq(selectedItemIndex).show()
		this.$('.dot').eq(selectedItemIndex).addClass('active')
	},

	renderNativePage: function() {
		this.$('.preview-content').html('<div class="page-placeholder">{native content}</div>')
	},

	renderGridPage: function() {
		var gridViewModel = this.model.get('grid')
		var gridView = new GridView({model: gridViewModel})

		this.model.set('grid', gridViewModel)

		this.listViews.push(gridView)
		this.$('.preview-content').html(gridView.render().el)

		// Update grid preview on size change
		this.listenTo(this.model, 'change', function() {
			gridView.render()
		})
	},

	updateTitle: function() {
		// Update page title preview
		this.$('.preview-header .title').text(this.model.get('title..content..' + this.language))
	},

	updatePageStyle: function() {
		var preview = this.$('.preview')[0]

		// Clone class list as an array so we can mutate it
		var classList = Array.prototype.slice.call(preview.classList, 0)

		classList.forEach(function(className) {
			if (className.match(/^style-.*$/)) {
				preview.classList.remove(className)
			}
		})

		// Set new style class, if applicable.
		var attributes = this.model.get('attributes')

		if (attributes.indexOf('STYLE_PAPER') > -1) {
			preview.classList.add('style-paper')
		}
	},

	setInspector: function(model) {
		this.$('.editing').removeClass('editing')

		// Remove previous inspector
		if (this.views.inspector) {
			this.views.inspector.destroy()
			delete this.views.inspector
		}

		if (model && !this.$('.preview-mode').length) {
			// Create new inspector and render
			this.views.inspector = new Inspector({
				model: model,
				app: this.app
			})

			this.$('.inspector-container').html(this.views.inspector.render().el)
		}
	},

	addView: function(options) {
		this.$('.editing').removeClass('editing')

		// Remove previous inspector
		if (this.views.inspector) {
			this.views.inspector.destroy()
		}

		// Create and render view picker
		options.app = this.app

		this.views.inspector = new ViewPicker(options)
		this.$('.inspector-container').html(this.views.inspector.render().el)
	},

	/**
	 * Handles click events to the floating 'Add object' button, shows the view
	 * picker with new objects to drag in.
	 */
	addObjectButtonClick: function() {
		this.addView({model: this.model})
	},

	editPage: function() {
		// Don't edit in preview mode
		if (this.$('.preview').hasClass('preview-mode')) {
			return
		}

		// Set inspector to the entire page model
		this.setInspector(this.model)
		this.$('.preview').addClass('editing')
	},

	// Handler for "Add question" button displayed on QuizPages
	addQuestionButtonClick: function(e) {
		// Add new question
		this.addView({model: this.model})
		e.stopPropagation()
	},

	quizQuestionDotClick: function(e) {
		var index = $(e.currentTarget).data('index')

		this.selectedQuizItemId_ = this.model.get('children').at(index).id

		// Hide current question, show new one
		this.$('.QuizItem').hide()
		this.$('.QuizItem').eq(index).show()

		// Update dots
		this.$('.dot.active').removeClass('active')
		$(e.currentTarget).addClass('active')

		// Hide current inspector
		this.setInspector(null)

		e.stopPropagation()
	},

	mouseover: function(e) {
		// Don't edit in preview mode
		if (this.$('.preview').hasClass('preview-mode')) {
			return
		}

		var target = e.target

		// Set focus outline on first parent with editable class
		while (!$(target).hasClass('editable') && target !== null) {
			target = target.parentNode
		}

		$(target).addClass('focus')
	},

	mouseout: function(e) {
		var target = e.target

		// Remove focus outline on first parent with editable class
		while (!$(target).hasClass('editable') && target !== null) {
			target = target.parentNode
		}

		$(target).removeClass('focus')
	},

	// Set every child model to edit mode.
	startEditing: function() {
		CanvasItemView.prototype.startEditing.apply(this, arguments)

		this.editing = true

		var children = this.model.get('children') || this.model.get('pages') || []

		children.forEach(function(child) {
			child.trigger('startEditing')
		})

		var grid = this.model.get('grid')

		if (grid !== undefined) {
			grid.trigger('startEditing')
		}

		this.$('.add-object-button').removeClass('hidden')
		this.startDragAndDrop()
	},

	/**
	 * Initialises the drag and drop plugin for reordering items on a page.
	 *
	 * Currently implemented for ListPage objects only (dragging both ListItem
	 * and List objects). QuizPage dragging is implemented in {@link
		* QuizItemView}.
	 */
	startDragAndDrop: function() {
		if (this.listDrake) {
			this.listDrake.destroy()
		}

		if (this.listItemDrake) {
			this.listItemDrake.destroy()
		}

		var listPage = this.$('.ListPage').get(),
			lists    = this.$('.ListPage .List .view-children').get(),
			preview  = this.$('.preview')[0]

		this.listDrake = dragula(listPage, {
			mirrorContainer: preview,
			moves: function(el, source, handle) {
				var currentNode = handle

				while (!currentNode.classList.contains('List')) {
					if (currentNode.classList.contains('ListItem')) {
						return false
					}

					currentNode = currentNode.parentNode
				}

				return true
			}
		})

		this.listItemDrake = dragula(lists, {
			mirrorContainer: preview
		})

		var quizDots = this.$('.quiz-question-selector').get()

		this.quizItemDrake = dragula(quizDots, {
			mirrorContainer: quizDots[0]
		})

		this.listItemDrake.on('drop', this.handleListPageDrop)
		this.listDrake.on('drop', this.handleListPageDrop)
		this.quizItemDrake.on('drop', this.handleQuizItemDrop.bind(this))
	},

	/**
	 * Tears down the drag and drop plugin for reordering items on a page.
	 */
	stopDragAndDrop: function() {
		if (this.listDrake) {
			this.listDrake.destroy()
			this.listDrake = null
		}

		if (this.listItemDrake) {
			this.listItemDrake.destroy()
			this.listItemDrake = null
		}
	},

	/**
	 * Handles a drop event from the drag and drop plugin. Reorders models on
	 * the page to match the updated DOM state.
	 * @param {HTMLElement} el The DOM element being dropped.
	 * @param {HTMLElement} target The DOM element in which {@link el} is being
	 *     dropped.
	 * @param {HTMLElement} source The DOM element from which {@link el} has
	 *     been removed.
	 * @param {HTMLElement} sibling The DOM element which {@link el} has been
	 *     dropped before, or {@code null} if {@link el} has been dropped at
	 *     the
	 *     end of the list.
	 */
	handleListPageDrop: function(el, target, source, sibling) {
		if (!target) {
			return
		}

		var itemModel = el.model

		// Iterate up from source/target el to get models.
		var targetView = target,
			sourceView = source

		while (targetView && !targetView.model) {
			targetView = targetView.parentNode
		}

		while (sourceView && !sourceView.model) {
			sourceView = sourceView.parentNode
		}

		if (!targetView) {
			throw new Error('Failed to find target view element')
		}

		if (!sourceView) {
			throw new Error('Failed to find source view element')
		}

		var sourceModel = sourceView.model,
			targetModel = targetView.model

		/* HACK - to be removed when Project Storm #230 has been resolved. */
		/* Performs a copy and paste instead of moving an existing model. */
		var itemData = itemModel.toJSON()

		StormObjectUtils.stripIDs(itemData, itemData.pageId)
		itemModel.destroy()

		itemModel = StormObject.fromProperties(itemData)
		/* END HACK */

		// Remove old reference to this model.
		sourceModel.get('children').remove(itemModel)

		// Get index to add new model into target.
		var targetChildren = targetModel.get('children'),
			targetIndex

		if (sibling === null) {
			targetIndex = targetChildren.length
		} else {
			targetIndex = targetChildren.indexOf(sibling.model)
		}

		targetChildren.add(itemModel, {at: targetIndex})

		// Save both target and source models with new orderings.
		targetModel.save().then(function() {
			// Make sure the items can still be edited.
			$('.inline-editable').attr('contenteditable', true)
		})

		if (sourceModel !== targetModel) {
			sourceModel.save().then(function() {
				// Make sure the items can still be edited.
				$('.inline-editable').attr('contenteditable', true)
			})
		}
	},

	/**
	 * Handles a drop event from the drag and drop plugin. Reorders models on
	 * the page to match the updated DOM state.
	 * @param {HTMLElement} el The DOM element being dropped.
	 * @param {HTMLElement} target The DOM element in which {@link el} is being
	 *     dropped.
	 * @param {HTMLElement} source The DOM element from which {@link el} has
	 *     been removed.
	 * @param {HTMLElement} sibling The DOM element which {@link el} has been
	 *     dropped before, or {@code null} if {@link el} has been dropped at
	 *     the end of the list.
	 */
	handleQuizItemDrop: function(el, target, source, sibling) {
		if (!target) {
			return
		}

		var itemModel = el.model,
			sourceModel = this.model

		/* HACK - to be removed when Project Storm #230 has been resolved. */
		/* Performs a copy and paste instead of moving an existing model. */
		var itemData = itemModel.toJSON()

		StormObjectUtils.stripIDs(itemData, itemData.pageId)
		itemModel.destroy()

		itemModel = StormObject.fromProperties(itemData)
		/* END HACK */

		var quizzes = sourceModel.get('children')

		// Remove old reference to this model.
		quizzes.remove(itemModel)

		// Get index to add new model into target.
		var targetIndex

		if (sibling === null) {
			targetIndex = quizzes.length
		} else {
			targetIndex = quizzes.indexOf(sibling.model)
		}

		quizzes.add(itemModel, {at: targetIndex})

		// Save model with new orderings.
		sourceModel.save().then(function() {
			// Make sure the items can still be edited.
			$('.inline-editable').attr('contenteditable', true)
		})
	},

	// Stop editing all child models.
	stopEditing: function() {
		CanvasItemView.prototype.stopEditing.apply(this, arguments)

		this.editing = false
		this.setInspector(null)

		var children = this.model.get('children') || this.model.get('pages') || []

		children.forEach(function(child) {
			child.trigger('startEditing')
		})

		$('.add-object-button').addClass('hidden')
		this.stopDragAndDrop()
	},

	beforeDestroy: function() {
		// Close current page to save changes and revoke lock token
		this.setPage(null, true)
	}
})
