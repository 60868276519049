var Maps = require('lib/maps'),
	RegionDrawView = require('./region-draw-view'),
	CustomAlert = require('./custom-alert'),
	CanvasView = require('editor/canvas/canvas-view'),
	Page = require('editor/page-list/page'),
	HazardsSectionView = require('./hazards-section-view')

var HIDDEN_CLASS = 'hidden'

module.exports = HazardsSectionView.extend({
	template: require('./alert-edit-view-template'),

	events: {
		'click .map-button': 'mapClick',
		'click .preview-button': 'showPreview',
		'click .save-button': 'save',
		'click .preview-back-button': 'back'
	},

	initialize: function(options) {
		this.views = {}
		this.overlay = null
		this.app = options.app

		// Fetch page list to construct root page dropdowns.
		this.pageList = this.app.pageList
		this.pageList.fetchOnce().then(function() {
			// Ready touches DOM, so can't happen in constructor.
			setTimeout(this.ready.bind(this))
		}.bind(this))

		this.model = new CustomAlert()
		this.listenTo(this.model, 'change:area', this.renderRegionPreview)
	},

	getPageTitle: function() {
		return $.t('hazards.alerts.createNewAlert')
	},

	getRenderData: function() {
		return {
			appId: this.appId,
			pageSelectOptions: this.pageSelectOptions
		}
	},

	ready: function() {
		// Generate page list dropdown.
		var pageListDropdownOptions = '<option value="">-</option>'

		// Get array of all unique tag names
		var tags = _.filter(this.pageList.pluck('tag'), function(elem, pos, self) {
			return self.indexOf(elem) === pos
		}).sort()

		// Output an option group for each tag
		_.each(tags, function(tag) {
			var taggedPages = this.pageList.where({tag: tag})
			var options = ''

			// Output an option for each page with this tag
			_.each(taggedPages, function(page) {
				options += '<option value="cache://pages/' + page.id + '.json">' + App.l(page.get('title')) + '</option>'
			})

			pageListDropdownOptions += '<optgroup label="' + tag + '">' + options + '</optgroup>'
		}, this)

		this.pageSelectOptions = pageListDropdownOptions
		this.render()

		// Initialise Google Maps API.
		Maps.init(this.initGoogleMap.bind(this))

		// Setup datepicker.
		this.datepicker = new Pikaday({
			field: this.$('#alert-expiry')[0],
			bound: true,
			showTime: true
		})

		var initialDate = new Date()
		initialDate.setDate(initialDate.getDate() + 1)
		initialDate.setHours(0)
		initialDate.setMinutes(0)

		this.datepicker.setDate(initialDate)

		App.stopLoad()
	},

	initGoogleMap: function() {
		// Initialize Google map
		this.map = new google.maps.Map(this.$('.alert-preview-map')[0], {
			disableDefaultUI: true,
			center: new google.maps.LatLng(40, -93),
			zoom: 5
		})

		App.stopLoad()
	},

	// Show region draw modal on map click.
	mapClick: function() {
		if (this.views.regionDrawView) {
			this.views.regionDrawView.destroy()
		}

		this.views.regionDrawView = new RegionDrawView({model: this.model})

		$('body').append(this.views.regionDrawView.render().el)
		this.views.regionDrawView.show()
	},

	// Draw polygons on map preview and fit bounds.
	renderRegionPreview: function() {
		// Clear previous polygons.
		if (this.overlay) {
			this.overlay.setMap(null)
		}

		var bounds = new google.maps.LatLngBounds(),
			region = this.model.get('area'),
			paths = []
		region.polygon.coordinates.forEach(function(polygon) {
			var latLngs = polygon.map(function(path) {
				return path.map(function(coord) {
					var latLng = new google.maps.LatLng(coord[1], coord[0])
					bounds.extend(latLng)
					return latLng
				})
			})

			paths = paths.concat(latLngs)
		})

		// Construct the polygon.
		this.overlay = new google.maps.Polygon({
			paths: paths,
			strokeColor: '#F93A2F',
			strokeOpacity: 1,
			strokeWeight: 2,
			fillColor: '#F93A2F',
			fillOpacity: 0.28,
			map: this.map
		})

		this.map.fitBounds(bounds)
	},

	// Hide form fields, show preview before send.
	showPreview: function() {
		var expiryDate = this.datepicker.getDate(),
			description = this.$('#alert-message').val()

		var jobTitle = '',
			user = Storm.user_

		if (user.extra !== null) {
			jobTitle = user.extra.get('jobTitle')
		}

		this.model.set('to', parseInt(expiryDate / 1000, 10))
		this.model.set('description', description)
		this.model.set('page', this.$('#alert-page').val())
		this.model.set('severity', 'Minor')
		this.model.set('notify', this.$('#alert-notify')[0].checked)
		this.model.set('event', 'general notifications')

		if (!this.model.has('area')) {
			App.showToast($.t('hazards.alerts.custom.errors.pleaseSelectRegion'))
			return
		}

		if (this.model.get('description') === '') {
			App.showToast($.t('hazards.alerts.custom.errors.pleaseProvideMessage'))
			return
		}

		// Format description for "Sent by" for GDPC users with the 'partner' role.
		var partnerRole = App.userRoles.findWhere({id: 76})

		if (partnerRole && jobTitle) {
			var formattedDescription = '[' + jobTitle + '] ' + description

			this.model.set('description', formattedDescription)
		}

		this.$('.save-button').removeClass(HIDDEN_CLASS)
		this.$('.preview-back-button').removeClass(HIDDEN_CLASS)
		this.$('.preview-button').addClass(HIDDEN_CLASS)

		this.$('.edit-form').addClass(HIDDEN_CLASS)
		this.$('.alert-preview').removeClass(HIDDEN_CLASS)

		this.$('.alert-preview-text').text(description)

		if (partnerRole && jobTitle) {
			var text = description + ' — sent by ' + jobTitle

			this.$('.alert-preview-text').text(text)
		}

		// Render page preview, if applicable.
		if (this.model.get('page')) {
			App.startLoad()

			if (!this.views.canvas) {
				this.views.canvas = new CanvasView({app: this.app})
			}

			this.$('.canvas-area').html(this.views.canvas.el)

			// Fetch page to be viewed.
			var pageId = App.getIdFromCacheUrl(this.model.get('page')),
				model = new Page({id: pageId})

			model.once('sync', function() {
				this.views.canvas.setPage(model, true)
				App.stopLoad()
			}, this)

			model.fetch()
		} else {
			this.$('.canvas-area').empty()
		}
	},

	save: function() {
		App.startLoad()
		this.$('.save-button').attr('disabled', true)
		var area = this.model.get('area')
		area.polygon.coordinates = area.polygon.coordinates[0]
		this.model.set('area', area, {silent: true})
		this.model.save()
			.then(this.alertSaved.bind(this), this.alertSaveError.bind(this))
	},

	back: function() {
		this.$('.save-button').addClass(HIDDEN_CLASS)
		this.$('.preview-back-button').addClass(HIDDEN_CLASS)
		this.$('.preview-button').removeClass(HIDDEN_CLASS)

		this.$('.edit-form').removeClass(HIDDEN_CLASS)
		this.$('.alert-preview').addClass(HIDDEN_CLASS)
	},

	alertSaved: function() {
		App.router.navigate('/apps/' + this.app.id + '/alerts', {trigger: true})
		App.showToast($.t('hazards.alerts.custom.alertQueued'))
	},

	alertSaveError: function(xhr) {
		var error = $.t('error.generic')

		try {
			var data = JSON.parse(xhr.responseText)

			if (data['client error'] && data['client error'].data) {
				error = data['client error'].data
			}
		} catch (e) {}

		swal($.t('error.oops'), error, 'error')
		this.$('.save-button').removeAttr('disabled')
	}
})
