var StormObject = require('./storm-object'),
	APICompat   = require('lib/api-compat')

/**
 * Exports {@link StandaloneStormObject}.
 * @module
 */
var StandaloneStormObject = StormObject.extend(/** @lends StandaloneStormObject.prototype */{
	/**
	 * @constructs StandaloneStormObject
	 * @extends StormObject
	 * @override
	 * @classdesc Provides a common interface for {@link StormObject} types
	 *     which may be saved as standalone objects (i.e. with no parent page)
	 *     — e.g. {@link Page} objects and {@link StormQL} children.
	 *
	 *     This class overrides the save method to reformat POST data in the
	 *     required format.
	 */
	initialize: function() {
		StormObject.prototype.initialize.apply(this, arguments)
	},

	/** @override */
	save: function(key, val, options) {
		// Rearrange arguments if save was called with the (key, val, options)
		// syntax
		var attrs = key

		if (typeof key === 'string') {
			attrs = {}
			attrs[key] = val
		} else {
			options = val
		}

		// Reformat POST data for new objects.
		if (this.isNew()) {
			var appId = options.appId

			if (appId === undefined) {
				throw new Error('New Page object saves require an app ID')
			}

			if (attrs) {
				this.set(attrs, options)
			}

			options.data = JSON.stringify({
				data: this.toJSON(),
				appId: options.appId
			})

			return StormObject.prototype.save.call(this, null, options)
		}

		return StormObject.prototype.save.apply(this, arguments)
	}
})

/**
 * Creates a new StandaloneStormObject instance with structure defined by the
 * specified class name. Used for creating new StormQL object instances.
 *
 * Note: input class names will automatically be mapped back to versions
 * compatible with the active API.
 * @param {string} className Class name of the object to construct.
 * @returns {StandaloneStormObject} Complete StandaloneStormObject instance for
 *     the specified object.
 */
StandaloneStormObject.fromClassName = function(className) {
	var apiClassName = APICompat.unNormaliseClassName(className),
		structure    = App.getClassStructure(apiClassName)

	return new StandaloneStormObject(structure)
}

module.exports = StandaloneStormObject
