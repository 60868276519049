var PermissionsParser = require('./permissions-parser')

/**
 * Exports {@link ACL}.
 * @module
 */
module.exports = Backbone.Model.extend(/** @lends ACL.prototype */{
	/**
	 * @constructs ACL
	 * @extends Backbone.Model
	 * @override
	 */
	initialize: function() {
	},

	/** @override */
	url: function() {
		return App.apiRoot + 'acl'
	},

	/**
	 * @returns {Object} Parsed permission structure for this ACL, detailing
	 *     which functions of the CMS are accessible to this user.
	 */
	getStructure: function() {
		return PermissionsParser.parse(this.toJSON())
	},

	/**
	 * Gets the permission string for the specified item, or an empty string if
	 * the item does not exist.
	 * @param {string} section The section of the app to check.
	 * @param {string} [item=''] The section item to check.
	 * @returns {string} The value of the specified permission, or empty string.
	 */
	getPermission: function(section, item) {
		item = item || ''

		var structure  = this.getStructure(),
			sectionObj = structure[section]

		if (Object.keys(structure).length === 0) {
			return ''
		}

		if (sectionObj === undefined) {
			console.warn('Permission section does not exist. Typo?', section)
			return ''
		}

		var permission = sectionObj[item]

		if (permission === undefined) {
			console.warn('Permission item does not exist. Typo?', section)
			return ''
		}

		return permission
	},

	/**
	 * Checks whether the user has 'Read' permission for the specified item.
	 * @param {string} section The section of the app to check.
	 * @param {string} [item=''] The section item to check.
	 * @returns {boolean} Whether or not the user has the 'Read' permission.
	 */
	hasReadPermission: function(section, item) {
		return this.getPermission(section, item) === 'Read'
	},

	/**
	 * Checks whether the user has 'Write' permission for the specified item.
	 * @param {string} section The section of the app to check.
	 * @param {string} [item=''] The section item to check.
	 * @returns {boolean} Whether or not the user has the 'Write' permission.
	 */
	hasWritePermission: function(section, item) {
		return this.getPermission(section, item) === 'Write'
	},

	/**
	 * Checks whether the user has any access to the specified item (read,
	 * write or other).
	 * @param {string} section The section of the app to check.
	 * @param {string} [item=''] The section item to check.
	 * @returns {boolean} Whether or not the user has any permission.
	 */
	hasAccess: function(section, item) {
		return this.getPermission(section, item) !== ''
	}
})
