var HazardsSectionView = require('../hazards-section-view'),
	FeedList = require('./feed-list'),
	DisasterList = require('../disaster-list'),
	FeedListItemView = require('./feeds-list-item-view'),
	StormQL = require('models/stormql'),
	StandaloneStormObject = require('editor/standalone-storm-object')

module.exports = HazardsSectionView.extend({
	template: require('./feeds-view-template'),

	tutorial: 'feeds',

	events: {
		'click .enable-feed': 'enableFeed',
		'click .save-button': 'save'
	},

	initialize: function(options) {
		this.app = options.app
	},

	getPageTitle: function() {
		return $.t('hazards.feeds.title')
	},

	getRenderData: function() {
		return {appId: this.app.id}
	},

	afterRender: function() {
		if (!this.app.isHazardsApp() && !App.developerMode) {
			setTimeout(function() {
				App.router.navigate('/', {trigger: true})
			})

			return
		}

		var requests = []

		this.listViews = []
		this.appEvents = []
		this.allEvents = []

		// fetch all feeds
		this.feedList = new FeedList()
		var feedFetch = this.feedList.fetch()
		requests.push(feedFetch)

		// fetch all disasters
		this.disasterList = new DisasterList()
		var disasterFetch = this.disasterList.fetch()
		requests.push(disasterFetch)

		// Fetch disasters assigned to this app.
		this.appDisasterList = new StormQL(null, {app: this.app})
		this.appDisasterList.comparator = 'order'
		var appDisasterFetch = this.appDisasterList.fetch({data: {class: 'StormQLDisaster'}})
		requests.push(appDisasterFetch)

		// Fetch feeds assigned to this app.
		this.appFeedList = new StormQL(null, {app: this.app})
		this.appFeedList.comparator = 'order'
		var appFeedFetch = this.appFeedList.fetch({data: {class: 'Feed'}})
		requests.push(appFeedFetch)

		// Render page again once all data loaded.
		Promise.all(requests).then(this.ready.bind(this))
	},

	ready: function() {
		// Get list of all unique enabled events for all enabled disasters
		this.appDisasterList.forEach(function(disaster) {
			var eventList = _(disaster.get('events').toJSON()).chain().flatten().pluck('eventId').value()
			Array.prototype.push.apply(this.appEvents, eventList)
		}, this)

		this.appEvents = _.uniq(this.appEvents)

		// Recurse through all disasters and grab all event types in the system
		// @todo replace this with data from /events when implemented
		this.disasterList.forEach(function(disaster) {
			Array.prototype.push.apply(this.allEvents, disaster.get('events'))
		}, this)

		this.renderFeeds()
		App.stopLoad()
	},

	renderFeeds: function() {
		this.listViews.forEach(function(view) {
			view.destroy()
		})
		this.listViews = []
		this.feedList.forEach(this.addFeed, this)
	},

	addFeed: function(feed) {
		// Get the app feed assignment model associated with this feed (if exists)
		var model = this.appFeedList.findWhere({code: feed.get('code')})

		// Array of event objects supported by this feed
		var feedEvents = _.filter(this.allEvents, function(event) {
			return feed.get('eventIds').indexOf(event.id) !== -1
		})

		var view = new FeedListItemView({
			feed: feed, // The feed object from /feeds
			feedEvents: feedEvents, // All Event objects supported by this feed
			model: model, // The assignment "Feed" Storm object if this feed has been enabled for this app
			enabledEvents: this.appEvents // Event ids enabled for this app
		})

		// Appends view to this.listViews
		this.addView(view)
		this.$('.list-items').append(view.render().el)
	},

	enableFeed: function(e) {
		// Grab the feed obj with selected id and create a new storm 'Feed' object for this app
		var feedId = $(e.currentTarget).data('id'),
			feed = this.feedList.findWhere({id: feedId})

		if (feed) {
			var model = StandaloneStormObject.fromClassName('Feed')
			// Make sure that there is no page id being set.
			model.unset('pageId')
			model.set('code', feed.get('code'))
			model.set('events', [])
			model.set('languages', feed.get('languages'))
			model.set('name', feed.get('name'))

			this.appFeedList.push(model)

			this.renderFeeds()
		}
	},

	save: function() {
		App.startLoad()
		var requests = [],
			appId = this.app.id

		// Save each changed disaster
		this.appFeedList.forEach(function(feed) {
			if (feed.isNew()) {
				requests.push(feed.save(null, {appId: appId}))
			} else if (feed.needsSaving) {
				requests.push(feed.save())
			}
			delete feed.needsSaving
		})

		Promise.all(requests).then(App.stopLoad()).then(this.renderFeeds())
	}
})
