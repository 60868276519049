module.exports = Backbone.View.extend({
	template: require('./token-renew-view-template'),

	className: 'token-renew',

	events: {
		'click .renew': 'renew',
		'click .cancel': 'cancel'
	},

	renew: function() {
		this.trigger('tokenRenew', true)
		this.$el.hide()
	},

	cancel: function() {
		this.trigger('tokenRenew', false)
		this.$el.hide()
	}
})
