<div class="jg-posts">
	<div class="row">
		<div class="col-xs-12 actions-col">
			<div class="pagination"></div>
			<button class="button add" data-i18n="common.add"></button>
		</div>
	</div>

	<div class="col-xs-12">
		<table>
			<thead class="title-case">
				<tr>
					<th data-i18n="common.tools"></th>
				</tr>
			</thead>
			<tbody class="list-items"></tbody>
		</table>
	</div>

	<div class="row">
		<div class="col-xs-12">
			<div class="pagination"></div>
		</div>
	</div>
</div>
