var PLACEHOLDER_CLASS = 'placeholder'

var APICompat = require('lib/api-compat')

module.exports = Backbone.View.extend({
	events: function() {
		return {
			'click': 'click',
			'blur *[contenteditable]': 'contenteditableInputChange',
			'focus *[contenteditable]': 'contenteditableInputFocus'
		}
	},

	initialize: function() {
		this.listenTo(this.model, 'change', this.render, this)
		this.listenTo(this.model, 'stopEditing', this.stopEditing, this)
	},

	afterRender: function() {
		var className = APICompat.normaliseClassName(this.model.get('class'))
		this.el.className = this.className
		this.$el.addClass(className)

		// Add editing state if the inspector's open
		if (this.model.editing) {
			this.startEditing()
		}
	},

	// Enter edit mode.
	startEditing: function() {
		// Set everything to contenteditable.
		this.$('.inline-editable').attr('contenteditable', true)
		this.setPlaceholders()
		this.model.editing = true
	},

	// Leave edit mode.
	stopEditing: function() {
		this.$('.inline-editable').removeAttr('contenteditable')
		this.model.editing = false
	},

	// Populate any empty text inputs with placeholders.
	setPlaceholders: function() {
		this.$('.inline-editable:empty').each(function() {
			var $this = $(this),
				placeholder = $this.data(PLACEHOLDER_CLASS)

			$this.text(placeholder).addClass(PLACEHOLDER_CLASS)
		})
	},

	// Text edited in the preview - copy back to model.
	contenteditableInputChange: function(e) {
		e.stopPropagation()

		var $el = $(e.currentTarget),
			attr = $el.data('attribute')

		if (this.model.get(attr) === undefined) {
			return
		}

		var text = ''

		// innerText is exactly what we need here, but Firefox doesn't support it.
		if ($el[0].innerText) {
			text = $el[0].innerText
		} else {
			text = $el.html().replace(/<br>/g, '\n')
		}

		this.model.setTextContent(Storm.view.language, attr, text)

		// Restore any placeholders.
		this.setPlaceholders()
		// Make sure the items can still be edited.
		this.$('.inline-editable').attr('contenteditable', true)
	},

	// Input may be focused by tabbing – make sure the inspector gets set and changes saved.
	contenteditableInputFocus: function(e) {
		e.stopPropagation()
		var $target = $(e.currentTarget)

		this.$el.trigger('click')

		// Clear placeholder if necessary.
		if ($target.hasClass(PLACEHOLDER_CLASS)) {
			$target.text('').removeClass(PLACEHOLDER_CLASS)
		}
	}
})
