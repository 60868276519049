{
	"error": {
		"noPermission": "No tienes permiso para realizar esa acción",
		"noAccess": "Usted no tiene acceso a este sistema. Por favor, hable con su administrador.",
		"noAccessJoin": "Usted actualmente no tiene acceso a este sistema. ¿Quiere unirse?",
		"saveFailed": "Error al guardar. Por favor recarga la página",
		"connect": "No se puede conectar al servidor API",
		"noApps": "No tienes acceso a ninguna app",
		"rootPage": "La página raiz no fue encontrada",
		"generic": "Un error ha ocurrido.",
		"exitWarning": "Todavía se están guardando los datos Aplicación. Si sale de la página ahora se pueden perder datos.",
		"allFields": "Por favor rellene todos los campos.",
		"cantPaste": "Este tipo de objeto no se puede pegar aquí.",
		"lockedBy": "Este objeto está actualmente bloqueado por ",
		"oops": "¡Huy!"
	},
	"common": {
		"save": "Guardar",
		"submit": "Entregar",
		"cancel": "Cancelar",
		"next": "Próximo",
		"finish": "Terminar",
		"close": "Cerrar",
		"back": "Retroceder",
		"preview": "Previsualizar",
		"publish": "Publicar",
		"language": "Idioma",
		"upload": "Upload",
		"done": "Listo",
		"tag": "Tag",
		"page": "Página",
		"view": "Ver",
		"email": "Dirección de email",
		"username": "Nombre de usuario",
		"add": "Añadir",
		"edit": "Editar",
		"tools": "Herramientas",
		"description": "Descripción",
		"position": "Posición",
		"warning": "ADVERTENCIA",
		"settings": "Configuración",
		"clickToBrowseMap": "Haga clic para navegar por el mapa",
		"closeThisMessage": "Cerrar este mensaje",
		"pleaseWait": "Por favor espera",
		"pageTags": {
			"learn": "Aprender",
			"prepare": "Prepárate",
			"emergency": "Emergencia",
			"tests": "Pruebas",
			"info": "Información",
			"other": "Otro"
		},
		"linkTypes": {
			"external": "Externo",
			"internal": "Interno",
			"native": "Nativo",
			"uri": "URI",
			"timer": "Cronometro",
			"call": "Llamada",
			"sms": "SMS",
			"share": "Compartir",
			"emergency": "Emergencia",
			"appLink": "Entre aplicaciones",
			"email": "Email"
		},
		"linkDescriptions": {
			"external": "Un enlace a una página web externa. Se abrirá dentro de la aplicación.",
			"internal": "Un enlace a una página diferente dentro de la aplicación.",
			"native": "Un enlace a un pedazo de funcionalidad nativa dentro de la aplicación.",
			"uri": "Un enlace a una página web externa o la aplicación. Hará que el usuario puede salir de la aplicación.",
			"timer": "Un enlace que se inicia un temporizador de cuenta atrás en el dispositivo.",
			"call": "Un enlace a marcar un número de teléfono desde el dispositivo del usuario.",
			"sms": "Un enlace para enviar un mensaje SMS desde el dispositivo del usuario.",
			"share": "Un enlace para compartir la aplicación a través de la ventana de diálogo nativo \"compartir\" en el dispositivo.",
			"emergency": "Un enlace a marcar el número de teléfono de emergencia especificados en la configuración del usuario.",
			"appLink": "Un enlace a una página en otra aplicación.",
			"email": "Un enlace para enviar un correo electrónico a la dirección especificada."
		},
		"filter": "Filtro",
		"reject": "Rechazar",
		"approve": "Aprobar",
		"approved": "Aprobado",
		"sections": "Secciones",
		"longLoad": "Recuperar una gran cantidad de datos, esto puede tomar hasta un minuto."
	},
	"login": {
		"inputs": {
			"password": "Password"
		},
		"error": {
			"title": "Ups!",
			"body": "Has introducido erroneamente el usuario o password.",
			"emailNotFound": "Dirección de email no encontrada.",
			"linkExpired": "El link para reestablecer la contraseña ha expirado"
		},
		"forgot": {
			"title": "Nosotros olvidamos todo algunas veces!",
			"body": "Introduce la dirección de email relacionada con tu cuenta."
		},
		"success": {
			"title": "Listo!",
			"body": "En unos momentos recibirás un email con las instrucciones para reestablecer tu password.",
			"loginBody": "¡Has iniciado sesión con éxito, pero no tienes adónde ir! Estás en auth.3sidedcube.com. Vaya a Storm CMS de su organización."
		},
		"links": {
			"forgot": "Yo olvide...",
			"return": "Regresar al login"
		},
		"reset": {
			"newPassword": "Nuevo password",
			"confirm": "Confirmar",
			"submit": "Enviar",
			"success": "Su contraseña ha sido configurada, ahora puede cerrar esta ventana."
		},
		"title": "Iniciar sesión"
	},
	"nav": {
		"navigate": "Navegar",
		"logout": "Log Out",
		"items": {
			"appSetup": {
				"title": "App Setup",
				"description": "Configura el nombre de la aplicación, icono, texto e información de la App Store"
			},
			"content": {
				"title": "Contenido",
				"description": "Editar el contenido in-app"
			},
			"publish": {
				"title": "Publicar",
				"description": "Publicar cambios en el dispositivo o liberar"
			},
			"stats": {
				"title": "Estadísticas",
				"description": "Muestra como la aplicación está funcionando"
			},
			"userAgreement": {
				"title": "Contrato del usuario",
				"description": "Ver y firmar el contrato del Universal App Program"
			},
			"users": {
				"title": "Usuarios",
				"description": "Manejar todos los usuarios"
			},
			"stories": {
				"title": "Historias",
				"description": "Gestione historias"
			},
			"levels": {
				"title": "Niveles",
				"description": "Administrar los niveles de progreso de la aplicación"
			},
			"badges": {
				"title": "Insignias",
				"description": "Gestione insignias adjudicados para completar las actividades de aplicación"
			},
			"push": {
				"title": "Notificaciones push",
				"description": "Gestionar y enviar notificaciones push a los usuarios"
			},
			"sprint": {
				"title": "Tablero de Estado",
				"description": "Ver el estado de desarrollo actual de sprint"
			},
			"bugs": {
				"title": "Informes de errores",
				"description": "Informe de problemas con el editor o aplicación acumulación"
			},
			"gdpcConsole": {
				"title": "Consola de las Sociedades Nacionales",
				"description": "Ver las Sociedades Nacionales y Consorcio de Desarrollo estados"
			},
			"hazards": {
				"title": "Desastres y Alertas",
				"description": "Prioridades, gestión de desastres, las alertas y la moderación"
			},
			"decisionTree": {
				"title": "Árbol de decisión",
				"description": "Ver y editar el árbol de decisiones para la aplicación"
			},
			"admin": {
				"title": "Administración",
				"description": "Ver las estadísticas, los usuarios editar y detalles de la organización"
			}
		}
	},
	"localisations": {
		"title": "Localizaciones",
		"string": "Cadena",
		"key": "Llave",
		"uploadCsv": "Subir un archivo CSV",
		"addNew": "Añadir nuevas localizaciones",
		"all": "<b>Todas</b> las localizaciones",
		"addMultiple": "Añadir múltiples localizaciones",
		"overwriteExisting": "Sobrescribir localizaciones existentes",
		"copyFromAnotherApp": "Copiar desde otra aplicación",
		"addMultipleInstructions": "Añadir o actualizar localizaciones en bloque. Utilice CSV en el formato",
		"overwriteWarning": "Es posible que se haya reemplazado alguna traducción existente.",
		"errors": {
			"invalidKey": "Clave de localización no válida. Las claves deben comenzar con un guión bajo y solo pueden contener mayúsculas y guiones bajos. Las claves deben contener al menos dos secciones (bits delimitados por guiones).",
			"csvTooManyColumns": "Demasiadas columnas de cabecera.",
			"csvInvalidHeader": "Cabecera no válida.",
			"csvInvalidKeyOnLine": "Clave de localización no válida en línea",
			"csvInvalidColumnCountOnLine": "Número no válido de columnas en línea",
			"expected": "Previsto",
			"got": "tengo"
		},
		"confirmCopy": "¿Seguro que desea copiar localizaciones desde la aplicación seleccionada?"
	},
	"appSetup": {
		"title": "Setup de la App",
		"appName": "Nombre de la App",
		"startPage": "Página de inicio",
		"languages": "Lenguaje de la App",
		"localisation": "Localización de la App",
		"publishable": "Publicable a:",
		"dev": "Desarrollo",
		"live": "Vivo",
		"key": "Clave",
		"value": "Valor",
		"properties": "Característica",
		"publishableLanguages": "Idiomas publicables",
		"addMoreLanguages": "Sólo los idiomas asignados a esta organización están disponibles aquí. Asignar más en la sección Admin.",
		"translationError": "Error al descargar el archivo de traducción. Por favor verifique la aplicación y la organización tiene al menos un idioma habilitado y publicable.",
		"expandAll": "Expandir todo",
		"addCsv": "Añadir CSV",
		"bundleIdentifiers": "SDK Bundle Identificadores",
		"type": "Tipo",
		"translation": "Traducciones"
	},
	"metadata": {
		"title": "Metadatos",
		"noBuildPlatforms": "No existen plataformas de construcción de esta aplicación",
		"appStore": "Detalles de la App Store",
		"name": "Nombre de la aplicación",
		"description": "Descripción",
		"keywords": "Palabras clave",
		"primaryCategory": "Categoría principal",
		"secondaryCategory": "Categoría de secundaria",
		"copyright": "Derechos de autor",
		"website": "URL de Marketing",
		"supportUrl": "URL de soporte",
		"privacyPolicyUrl": "Privacidad URL política",
		"phoneNumber": "Número de teléfono",
		"launchDate": "Fecha de lanzamiento",
		"appIcon": "Icono de la aplicación",
		"iconSize": "Icono original debe 1024x1024 de tamaño.",
		"addScreenshot": "Añadir Captura de pantalla",
		"appIconHint": "Debería ser 1024 x 1024 sin transparencia. Debe estar en el formato JPG o PNG con una resolución mínima de al menos 72 DPI y en el espacio de color RGB. No debe contener capas o esquinas redondeadas.",
		"appPreviewVideo": "App vista previa de vídeo",
		"appPreviewVideoHint": "Las previsualizaciones de la aplicación deben estar en formato M4V, MP4 o MOV y no pueden superar los 500 MB",
		"appStoreTitle": "Título de App store",
		"delete": "Borrar",
		"emailHint": "Indique una dirección de correo electrónico si la App Store o los usuarios necesitan estar en contacto",
		"featureGraphic": "Tipo de gráficos",
		"featureGraphicHint": "La tienda de Google Play exige que las aplicaciones tengan un gráfico característico o 'banner' con unas dimensiones de 1024 ancho x 500 alto. Puede ser una imagen independiente o una imagen de fondo para un video promocional (si la hubiere)",
		"languages": "Idiomas",
		"nameOnDevice": "Nombre de la aplicación en el dispositivo",
		"phoneNumberHint": "Debe incluir el prefijo telefónico del país",
		"platformSpecific": "Metadatos específicos de la plataforma",
		"price": "Precio",
		"rating": "Clasificación",
		"screenshotsHint": "Incluir capturas de àntalla para cada tamaño de la pantalla. Las capturas de pantalla deben estar en formato JPG o PNG, y en el espacio de color RGB",
		"shared": "Metadatos entre plataformas",
		"shortAppDescription": "Breve descripción de la aplicación",
		"supportUrlHint": "Una dirección URL con información de soporte para su aplicación. Esta URL será visible en la App Store",
		"websiteHint": "Una dirección URL con la información de comercialización acerca de su aplicación. Esta URL será visible en la App Store",
		"features": "Caracteristicas"
	},
	"plugins": {
		"title": "Plugins",
		"availablePlugins": "Plugins disponibles"
	},
	"editor": {
		"title": "Editor",
		"pageName": "Nombre de la página",
		"pageType": "Tipo de página",
		"pageStyle": "Estilo de página",
		"pageTag": "Etiqueta de la página",
		"addPage": "Añadir página",
		"saving": "Salvando...",
		"lastSaved": "Último guardado",
		"never": "nunca",
		"newFromTemplate": "Nuevo de plantilla",
		"pageList": {
			"title": "Páginas",
			"filter": "Filtrar páginas",
			"allPages": "Todas las páginas"
		},
		"addObject": "Añadir un objeto aqui",
		"addQuiz": "Añadir concurso",
		"addApp": "Agregar aplicación",
		"addLink": "Añadir enlace",
		"confirmRelock": "Este objeto ya está bloqueado por usted. ¿Le gustaría que encaje de nuevo? Su bloqueo anterior será revocada.",
		"confirmRequestNewLock": "Su bloqueo de esta página ha sido revocado. ¿Desea solicitar una nueva cerradura?",
		"savesQueued": "guarda en cola",
		"inspector": {
			"title": "Página de propiedades",
			"addAnother": "Añadir otro",
			"addBulletItem": "Añadir elemento de viñeta",
			"rootPageDelete": "Esta es la página raíz de la aplicación. No puede ser borrada",
			"areYouSure": "Estás seguro?",
			"confirmDelete": "¿Estas seguro de que quieres borrar este elemento?",
			"confirmPageDelete": "Confirmar la eliminación",
			"confirmPageDeleteWarning": "ADVERTENCIA:Vas a borrar un objeto de la página. Todos los contenidos van a ser borrados. ¿Estás seguro que quieres continuar?",
			"moveUp": "Hacer sitio",
			"moveDown": "Bajar",
			"copy": "Copia",
			"paste": "Pegar",
			"copySuccess": "Objeto copiado al portapapeles",
			"changeType": "Cambie el tipo",
			"newType": "Nuevo tipo",
			"properties": {
				"annotation": {
					"title": "Comentario",
					"description": "Paso número"
				},
				"title": {
					"title": "Titulo",
					"description": "Titulo de la lista de elementos"
				},
				"name": {
					"title": "Nombre",
					"description": "Nombre interno del sistema"
				},
				"hint": {
					"title": "Pista",
					"description": "Instrucciones para el usuario"
				},
				"image": {
					"title": "Imagen",
					"chooseButton": "Escoge una imagen",
					"removeButton": "Quitar imagen"
				},
				"video": {
					"title": "Video",
					"chooseButton": "Escoger video",
					"attributes": "Atributos",
					"loop": "Lazo",
					"addLanguage": "Agregar idioma"
				},
				"link": {
					"title": "Link",
					"type": "Escribe",
					"destination": "Destino",
					"body": "Cuerpo del texto",
					"duration": "Duración del cronómetro (minutos)",
					"recipients": "Destinatarios (Uno por línea)",
					"testLink": "Enlace de prueba",
					"linkDestination": "Enlace Destino",
					"linkTitle": "Enlace Título"
				},
				"images": {
					"add": "Añadir imagen"
				},
				"animation": {
					"chooseButton": "Escoge la animación",
					"delay": "Delay del frame (segundos)"
				},
				"embeddedLinks": {
					"title": "Links relacionados",
					"add": "Añadir link"
				},
				"embeddedMedia": {
					"title": "Medios incorporados"
				},
				"header": {
					"title": "Cabecera",
					"description": "Aparece debajo del grupo de elementos"
				},
				"footer": {
					"title": "Pie de página",
					"description": "Aparece debajo del grupo de elementos"
				},
				"badge": {
					"title": "Medalla",
					"icon": "Icono",
					"completion": {
						"title": "Texto complementario",
						"description": "Mostrar al usuario cuando finalice el cuestionario"
					},
					"how": {
						"title": "Cómo ganar",
						"description": "Texto que describe la forma de lograr esta placa"
					}
				},
				"units": {
					"title": "Unidades",
					"description": "segundos/metros etc."
				},
				"range": {
					"title": "Rango",
					"start": "Inicio",
					"length": "Longitud",
					"increment": "Incremento"
				},
				"completionText": {
					"title": "Texto complementario",
					"description": "Mostrar al usuario cuando conteste una pregunta correctamente"
				},
				"failureText": {
					"title": "Texto erroneo",
					"description": "Mostrar las respuestas incorrectas al usuario al contestar"
				},
				"winMessage": {
					"title": "Mensaje de ganador",
					"description": "Mostrar al usuario cuando apruebe el examen"
				},
				"loseMessage": {
					"title": "Mensaje perdido",
					"description": "Desplegar al usuario si no es correcto su examen"
				},
				"shareMessage": {
					"title": "Compartir mensaje",
					"description": "Texto que será compartido en las redes sociales"
				},
				"volatile": "Volátil (No conserva su estado)",
				"textContent": "Contenido de texto",
				"quizzes": "Cuestionarios",
				"answer": "Respuesta",
				"selectionLimit": "Seleccionar límite",
				"correctAnswer": "Respuesta correcta",
				"initialPosition": "Posición inicial",
				"relatedLinks": "Links relacionados",
				"winRelatedLinks": "Gana links relacionados",
				"loseRelatedLinks": "Perder links relacionados",
				"caption": "Subtítulo",
				"delay": "Retraso (s)",
				"placeholderImage": "marcador de posición imagen",
				"button": "Botón",
				"standard": "Estándar",
				"columns": "Columnas",
				"quiz": "Examen",
				"overlay": "Superposición de texto",
				"progressMessage": "Mensaje de progreso",
				"gridType": "Tipo de rejilla",
				"confirmGridTypeChange": "¿Está seguro de que desea cambiar el tipo de cuadrícula? Esto puede afectar todos los elementos de la cuadrícula.",
				"winThreshold": {
					"title": "Umbral de ganancia (%)",
					"description": "Porcentaje de respuestas correctas requeridas para aprobar el cuestionario"
				}
			},
			"swapItem": "Elemento de Swap",
			"copiedItem": "elemento copiado",
			"confirmApproval": "¿Estás seguro de que quieres aprobar esto?"
		},
		"objectPreviews": {
			"DescriptionListItem": {
				"title": "Este elemento tiene un título y descripción",
				"description": "Utilice este elemento cuando tenga mucho que decir"
			},
			"StandardListItem": {
				"title": "Utilice este elemento en la mayoría de ocasiones",
				"description": "Este elemento tiene un título, una descripción y un icono. Puede dejar trozos en blanco, como el icono"
			},
			"ToggleableListItem": {
				"title": "Utilícelo cuando tenga algo que ocultar",
				"description": "El usuario puede mostrar y ocultar este texto. Mejor si ofrece mucha información, quizás una sección de preguntas más frecuentes"
			},
			"OrderedListItem": {
				"title": "Lista ordenada",
				"description": "Utilice esta opción cuando desee cosas de una lista numerada"
			},
			"UnorderedListItem": {
				"title": "Lista desordenada",
				"description": "Utilice esta opción cuando desee cosas en una lista, pero sólo con apartados por puntos"
			},
			"HeaderListItem": {
				"title": "Utilice esta en la parte superior de una página",
				"description": "Puede tener un título y una descripción más larga"
			},
			"SpotlightListItem": {
				"first": "Rotatorio",
				"second": "Destacar"
			},
			"TextListItem": "Utilice esta opción para texto de introducción, por lo general en la parte superior de una página",
			"LogoListItem": "Utilícelo cuando necesite un logotipo, una imagen, subtítulo y un enlace",
			"CheckableListItem": "Utilice esto para listas de verificación",
			"TitleListItem": "Utilice este elemento para una sola línea de texto",
			"ButtonListItem": "Utilice esta opción si desea un botón",
			"ChunkyListItemView": {
				"title": "Es como el elemento de la lista estándar, pero con más contenido",
				"description": "Tiene un título, una descripción y un icono. Puede dejar trozos en blanco, al igual que el icono"
			},
			"ListItemView": "Este elemento solo tiene un título"
		}
	},
	"mediaLibrary": {
		"title": "Libreria de media",
		"refine": "Refinar libreria",
		"filterByTags": "Filtrar por etiquetas",
		"type": "Tipo de media",
		"image": "Imagen",
		"video": "Video",
		"icon": "Icono",
		"animation": "Animación",
		"section": "Sección",
		"society": "Sociedad",
		"general": "General",
		"additionalTags": "Tags adicionales",
		"commas": "Separar por comas",
		"clearFilters": "Limpiar filtros",
		"editMedia": "Editar media",
		"delete": "Borrar",
		"dragToReorder": "Arrastre para reordenar",
		"confirmTagRemoval": "¿Está seguro de que desea eliminar esta etiqueta?",
		"upload": {
			"title": "Cargar media",
			"tags": "Tags",
			"newTag": "Nuevo tag",
			"agreementText": "Todos los contenidos subidos al sistema están sujetos a los términos de",
			"filesizeError": "Falló la carga. El archivo es muy grande.",
			"formatError": "Falló la carga. El formto es invalido. Los formatos válidos son: .jpeg, .png, .gif Los formatos de videos: .mp4, .m4v",
			"genericError": "La carga de archivos ha fallado. Código de error:",
			"selectType": "Por favor seleccione el tipo de imagen que se va a cargar",
			"imageDesc": "Se recomienda imágenes de 640 por 320 píxeles de borde a las imágenes de bordes, imágenes de diapositivas y las imágenes de vídeo. Imágenes Concurso deben ser de 640 por 640 píxeles.",
			"invalidTag": "Etiquetas no válidas. Etiquetas no pueden contener el carácter comodín asterisco (*).",
			"instructions": "Haga clic aquí o arrastre un archivo para empezar",
			"errors": {
				"tooManyFiles": "Por favor, seleccione un archivo único"
			},
			"noCrop": "Sin recorte",
			"cropType": "Tipo de recorte",
			"square": "Cuadrado",
			"squareDesc": "Utilice este recorte para iconos e imágenes de test Las imágenes de test deben ser <b>640 (w)</b> por <b>640 (h)</b> píxeles.",
			"headerDesc": "Utilice este cultivo para las grandes imágenes de cabecera que aparecen en la parte superior de una página. Las imágenes deben ser <b>640 (w)</b> por <b>568 (h)</b> píxeles de tamaño.",
			"spotlightDesc": "Utilice este cultivo para focos, de borde a borde, diapositivas e imágenes de vídeo. Las imágenes deben ser <b>640 (w)</b> <b>por</b> 320 <b>(h)</b> píxeles de tamaño.",
			"requiresTransparency": "Requiere fondo transparente",
			"gdpcHeaderDesc": "Utilice este cultivo para las grandes imágenes de cabecera en Peligros aplicaciones. Las imágenes deben ser <b>640 (w)</b> por <b>568 (h)</b> píxeles de tamaño.",
			"gdpcSpotlightDesc": "Utilice este cultivo para los encabezados en aplicaciones de primeros auxilios, focos, de borde a borde, diapositivas e imágenes de vídeo. Las imágenes deben ser <b>640 (w)</b> <b>por</b> 320 <b>(h)</b> píxeles de tamaño."
		},
		"chooseExisting": "Elegir el existente",
		"loadMore": "Carga más"
	},
	"viewPicker": {
		"title": "Objectos",
		"filter": "Filtrar objetos",
		"basicObjects": "Objetos básicos",
		"multiLineObjects": "Objetos multilínea",
		"mediaObjects": "Objetos de media",
		"interactiveObjects": "Objetos interactivos",
		"text": "Texto",
		"subText": "Sub-texto",
		"leadInText": "Texto de explicación previa",
		"hiddenText": "Esconder texto",
		"spotlight": "Spotlight",
		"TextListItem": "Texto entre grupos de borde a borde",
		"TitleListItem": "Una sola línea de texto",
		"StandardListItem": "Un titulo, enlace e icono",
		"DescriptionListItem": "Un título y una descripción de una descripción de varias líneas",
		"ToggleableListItem": "Un titulo seleccionable que muestra un contenido de texto largo",
		"OrderedListItem": "Un título contenuido en varias líneas de texto, con una anotación o numeración",
		"UnorderedListItem": "Un elemento de la lista con un título y una descripción",
		"CheckableListItem": "Un título y un recuadro El estado puede mantenerse entre usuarios de la aplicación",
		"ButtonListItem": "Un título y un botón",
		"ImageListItem": "Una sola imagen, borde a borde",
		"HeaderListItem": "Una gran imagen con un título superpuesto y descripción",
		"VideoListItem": "Un objeto de vídeo que admite varios tipos de letra",
		"AnimatedImageListItem": "Una serie de imágenes mostradas en una presentación crean una animación",
		"SpotlightImageListItem": "Una o mas imágenes principales que contengan una imagen, una descripción y un enlace",
		"LogoListItem": "Usado para mostrar el nombre de la compañia",
		"SliderQuizItem": "Pregunta de selección para cursor de imagen",
		"TextQuizItem": "Pregunta de selección de texto",
		"ImageQuizItem": "Pregunta de selección de imagen",
		"AreaQuizItem": "Pregunta de selección de área",
		"CollectionListItem": "Una vista 'Recoger todos' para todas las aplicaciones, distintivos y enlaces",
		"dragAndDrop": "Arrastrar y soltar objetos para añadirlos a la página"
	},
	"publish": {
		"title": "Publicar",
		"noChanges": "No hay cambios",
		"auditTrail": "Pista de auditoría",
		"lastModified": "Última modificación",
		"publishToDev": "Publicar en el dispositivo",
		"publishToLive": "Publicar en vivo",
		"objectType": "Tipo de objeto",
		"detail": "Detalle",
		"details": "Detalles",
		"time": "Tiempo",
		"startTime": "Hora de inicio",
		"endTime": "Hora de finalización",
		"user": "Usuario",
		"selectAll": "Seleccione todos",
		"error": "Por favor, seleccione una o varias páginas a publicar.",
		"warning1": "Ha seleccionado el siguiente páginas que se publicará en las aplicaciones de iOS y Android a través de una actualización delta.",
		"warning2": "Por favor revise cuidadosamente todos los cambios antes de continuar y confirme su selección en la parte inferior de la página.",
		"comment": "Comentario",
		"publishConfirm": "Confirmo que he revisado todos los cambios seleccionados y tienen permiso para empujar a vivir",
		"toApps": "Publicar en aplicaciones",
		"pleaseConfirm": "Por favor, marque la casilla para confirmar que ha revisado todos los cambios.",
		"publishing": "Publicaciones...",
		"progress1": "Los cambios seleccionados están siendo publicados.",
		"progress2": "Esto puede tomar un tiempo. No salir de esta página.",
		"success": "Publicar un éxito.",
		"fail": "Publicar fallado.",
		"history": "Historia",
		"publishType": "Publicar tipo",
		"test": "De prueba",
		"live": "Vivo",
		"publishLandmark": "Landmark Publish?",
		"progress3": "Parece que esto está tomando un tiempo. Puedes volver más tarde."
	},
	"publishHistory": {
		"title": "Publicar historia",
		"previousPublishes": "Anterior publica"
	},
	"agreement": {
		"title": "Acuerdo del \"Universal App Program\"",
		"hazardsTitle": "Acuerdo del Programa de Aplicación universal de Peligros",
		"details": "Detalles del acuerdo",
		"status": "Status",
		"sign": "Firma",
		"signed": "Firmado",
		"notSigned": "Sin firma",
		"daysRemaining": "__count__ día restante",
		"daysRemaining_plural": "__count__ días restantes",
		"signedBy": "Firmado por",
		"date": "Fecha",
		"nationalSociety": "Sociedad Nacional",
		"viewThe": "Ver el",
		"signAgreement": "Firma del acuerdo",
		"fullName": "Nombre completo",
		"email": "Dirección de email",
		"iAgree": "Estoy de acuerdo con los términos del",
		"checkToAgree": "Por favor da click para confirmar que has leido y estás de acuerdo con los términos"
	},
	"users": {
		"title": "Usuarios",
		"list": "Lista de usuarios",
		"name": "Nombre",
		"firstName": "Nombre",
		"lastName": "Apellido",
		"organisation": "Organización",
		"role": "Rol",
		"roles": "Roles",
		"apps": "Apps",
		"lastActive": "Última vez activo",
		"success": "Listo! El nuevo usuario recibirá un email con las instrucciones para activar su cuenta",
		"complete": "Usuario creado con éxito",
		"error": "No se puede crear al usuario. Verifica el usuario y contraseña porque han sido registrados previamente",
		"createUser": "Crear usuario",
		"usernameHelp": "Los nombres de usuario sólo pueden contener letras, números, guiones bajos, guiones y puntos.",
		"removeUserConfirm": "¿Seguro que desea eliminar a este usuario?",
		"failedToRemove": "Error al eliminar el usuario"
	},
	"roles": {
		"title": "Roles de usuarios",
		"list": "Lista de roles",
		"permissions": "Permisos"
	},
	"permissions": {
		"title": "Permisos de roles",
		"edit": "Editar permisos",
		"none": "Ninguno",
		"read": "Leer",
		"write": "Escribir",
		"delete": "Escribir/Borrar"
	},
	"apps": {
		"title": "Aplicaciones de usuario",
		"subtitle": "Asignar apps al usuario",
		"app": "App",
		"assignNew": "Asignar nueva app",
		"assign": "Asignar"
	},
	"stories": {
		"title": "Historias",
		"list": "lista de Historias",
		"created": "Creado",
		"subtitle": "Subtítulo",
		"content": "Contenido",
		"editStory": "Editar historia"
	},
	"badges": {
		"title": "Medallas",
		"list": "Lista de medallas",
		"editBadge": "Editar medalla"
	},
	"levels": {
		"title": "Niveles",
		"list": "Lista de niveles",
		"editLevel": "Editar Nivel",
		"details": "Detalles Nivel",
		"children": "Los niños de nivel"
	},
	"push": {
		"showAllApps": "Mostrar todas las aplicaciones",
		"existing": "Notificaciones push existentes",
		"category": "Categoría",
		"notes": "Observación",
		"type": "Tipo",
		"numberSent": "Total",
		"numberCompleted": "Terminado",
		"createdBy": "Creado por",
		"priority": "Prioridad",
		"editPush": "Notificación Editar empuje",
		"pushDetails": "Detalles notificación",
		"assignments": "Asignaciones",
		"noPageSpecified": "No página especificada",
		"approve": "Aprobar",
		"reject": "Rechazar",
		"message": "Aviso",
		"payload": "Carga útil",
		"saveAndSend": "Guardar y enviar",
		"noNotifications": "Ninguna notificación",
		"sending": "Envío",
		"sent": "Notificación de inserción envió",
		"categories": {
			"standard": "Estándar",
			"future": "Futuro",
			"region": "Región",
			"custom": "Personalizado"
		},
		"types": {
			"default": "Defecto"
		},
		"priorities": {
			"low": "Bajo",
			"high": "Elevado"
		},
		"custom": {
			"area": "Área de destino",
			"areaHelp": "Haga clic en el mapa para definir un área objetivo para esta notificación"
		},
		"error": {
			"length": "Mensaje de notificación de inserción demasiado largo",
			"messageEmpty": "Por favor, introduzca un mensaje",
			"missingRegion": "Por favor seleccione una región"
		},
		"messageDescription": "Mensaje de notificación push",
		"what": "¿Qué desea enviar?",
		"when": "¿Cuándo desea enviarlo?",
		"addToQueue": "Añadir a la cola"
	},
	"bugs": {
		"title": "Errores",
		"report": "Informar",
		"listOpen": "Lista de errores abiertos",
		"listPending": "Lista de errores pendientes",
		"listClosed": "Lista de errores cerrados",
		"version": "Versión",
		"build": "Compilación",
		"device": "Versión del dispositivo / OS",
		"screenshots": "Imágenes",
		"reportBug": "Informar de un fallo",
		"platformDetails": "Detalles de la plataforma",
		"issueDetails": "Detalles del problema",
		"steps": "Pasos para reproducir",
		"expected": "Comportamiento esperado",
		"actually": "El comportamiento real",
		"noReports": "No hay informes",
		"bugReport": "Informe de error",
		"comments": "Comentarios",
		"noComments": "No hay comentarios",
		"addComment": "Añadir un comentario",
		"loadingDetails": "Cargando los detalles de errores ...",
		"closeIssue": "Cerrar cuestión",
		"reopenIssue": "Reabrir tema",
		"loadingFiles": "Carga de archivos ...",
		"noFiles": "No hay archivos",
		"status": {
			"title": "Estatus",
			"deleted": "Suprimido",
			"open": "Abierto",
			"fixed": "Solucionado",
			"wontFix": "No reparará",
			"postponed": "Aplazado",
			"duplicate": "Duplicado",
			"notReproducible": "No reproducible",
			"byDesign": "Por Diseño",
			"closed": "Terminado"
		}
	},
	"gdpcConsole": {
		"nationalSocieties": {
			"title": "Visión de conjunto",
			"countries": "Países",
			"stage": "Etapa",
			"averageRating": "Nota media",
			"downloads": "Descargas",
			"totalDownloads": "Número total de descargas",
			"contact": "Contacto",
			"pool": "Consorcio",
			"goLive": "Ir directo",
			"locale": "Lugar",
			"details": "Sociedad Detalles",
			"edit": "Editar Sociedad"
		},
		"devPools": {
			"title": "Estado Pools Desarrollo",
			"completed": "Realizado",
			"noApps": "No hay aplicaciones en esta consorcio de desarrollo",
			"editPool": "Editar consorcio de desarrollo",
			"stages": "Etapas",
			"language": "Idioma",
			"expected": "Esperado",
			"requiredBy": "Requerido por",
			"responsibility": "Responsabilidad",
			"estimatedDate": "Estimado actual fecha de aplicación"
		}
	},
	"hazards": {
		"disasters": {
			"title": "Desastres",
			"rootPage": "Página raíz",
			"alertSettings": "Configuración de alertas",
			"configure": "Configurar",
			"enable": "Permitir",
			"disable": "Inhabilitar",
			"saveSuccess": "Configuración aplicación salvó.",
			"saveError": "Error al guardar la configuración de la aplicación.",
			"pleaseSpecifyPages": "Por favor, especifique una página para cada tipo de desastre seleccionado.",
			"whatNow": "'Lo que ahora' la página",
			"categories": {
				"tsunami": "Tsunami",
				"hurricane": "Huracán",
				"tornado": "Tornado",
				"flood": "Inundación",
				"wildfire": "Incendios forestales",
				"wind": "Viento",
				"storm": "Tormenta",
				"earthquake": "Terremoto",
				"heatwave": "Ola de calor",
				"winter": "Invierno",
				"volcano": "Volcán",
				"nuclear": "Nuclear",
				"coastal": "Costero"
			},
			"tutorial": {
				"heading": "¿Qué significan los distintos estados?",
				"body": "- Activado significa que la página aparecerá en el editor de contenidos para que usted comience a trabajar en él.<br>- Dev. significa que la página aparecerá en el desarrollo se basa para esta aplicación.<br>- Vivo significa que la página aparecerá en la tienda de aplicaciones se basa para esta aplicación."
			}
		},
		"alerts": {
			"title": "Alarmas",
			"alert": "Alarma",
			"expired": "Caducado",
			"alertActive": "Alerta activa",
			"pin": "Prender con alfileres",
			"pinned": "Fijado",
			"effective": "Eficaz",
			"issuedBy": "expedido por",
			"noAlerts": "No hay alertas",
			"show": "Mostrar",
			"inTheLast": "En la última",
			"allAlerts": "Todas las alertas",
			"createNewAlert": "Crear una nueva alerta",
			"testAlert": "Crear alerta de prueba",
			"testAlertDesc": "Esta alerta de prueba se enviará a todos los usuarios de monitoreo una ubicación en la Antártida.",
			"specifySinglePolygon": "Múltiples polígonos no son compatibles con las alertas. Por favor, dibuje solamente un polígono",
			"times": {
				"allTime": "Todo el tiempo",
				"hour": "Hora",
				"day": "Día",
				"week": "Semana",
				"month": "Mes"
			},
			"custom": {
				"types": {
					"announcement": "Anuncio",
					"pin": "Fijado página",
					"announcementDesc": "Los anuncios se utilizan para crear alertas para los eventos que no se ofrecen a otros proveedores de alerta.",
					"pinDesc": "Páginas puestas se utilizan para ofrecer páginas importantes que son el tiempo y el lugar sensible."
				},
				"errors": {
					"pleaseSelectRegion": "Por favor seleccione una región para esta alerta.",
					"pleaseProvideMessage": "Por favor ingrese su mensaje para este anuncio.",
					"pleaseSelectPage": "Por favor seleccione una página para ser depositado.",
					"pleaseProvideAreaDescription": "Proporcione una descripción del área para este anuncio.",
					"pleaseProvideHeadline": "Por favor, proporcione un titular para este anuncio.",
					"pleaseProvideEvent": "Por favor, proporcione un evento para este anuncio.",
					"effectiveWrong": "La fecha de vigencia no puede ser posterior a la fecha de vencimiento"
				},
				"announcementPage": "La página se muestra en la sección \"¿y ahora qué\".",
				"pinPage": "La página que se ofrecerá.",
				"message": "El mensaje enviado a esta alerta.",
				"area": "Zona de alerta",
				"areaHelp": "La alerta se enviará a todos los usuarios de seguimiento una ubicación dentro de la zona seleccionada.",
				"expiry": "Fecha de caducidad",
				"expiryHelp": "La fecha se eliminará esta alerta.",
				"severity": "Severidad",
				"severityHelp": "¿Qué tan importante es esta alerta?",
				"alertQueued": "Alerta cola para ser enviado.",
				"drawRegion": "Dibuja alrededor de las áreas de esta alerta se debe enviar",
				"notify": "Notificar",
				"sendNotification": "Enviar una notificación de inserción a estos usuarios.",
				"pushes": "empuja",
				"cap_send": "Generar una alerta CAP",
				"polygon": "Añadir datos de polígono costumbre aquí",
				"showPolyData": "Mostrar datos de polígono",
				"updateMap": "Actualización de mapas",
				"effective": "Fecha efectiva",
				"cap_create": "GORRA",
				"onset": "Fecha de comienzo",
				"languageHelp": "Idioma en el que está escrita la alerta",
				"effectiveHelp": "La fecha de vigencia de esta alerta.",
				"onsetHelp": "La fecha en que ocurrirá el peligro."
			}
		},
		"title": "Peligros",
		"generalNotifications": {
			"title": "Notificaciones generales",
			"createNotification": "crear Notificación",
			"event": "Evento",
			"headline": "Titular",
			"area_description": "Descripción de la zona",
			"type": "Tipo",
			"status": "Estado",
			"scope": "Alcance",
			"category": "Categoría",
			"urgency": "Urgencia",
			"severity": "Gravedad",
			"certainty": "Certeza",
			"customMapData": {
				"placeholder": "Haga clic en el mapa de arriba o añadir un mínimo de tres coordenadas aquí para crear un polígono i.e. \n[longitud,latitud],\n[longitud,latitud],\n[longitud,latitud]",
				"moreCoords": "Sírvanse proporcionar más de tres coordenadas",
				"firstAndLast": "Coordenadas primer y el último debe ser el same",
				"notFound": "No hay datos de polígonos que se encuentran , por favor, añadir un mínimo de tres coordenadas. [longitude,latitud],[longitud,latitud],[longitud,latitud]",
				"fisrtFoundNotLast": "Primera coordenada encuentran dentro de las coordenadas. Sólo el primer y el último coordenadas deben ser los mismos"
			},
			"dropdown": {
				"alert": "Alerta",
				"update": "Actualizar",
				"cancel": "Cancelar",
				"error": "Error",
				"actual": "Real",
				"system": "Sistema",
				"test": "Prueba",
				"draft": "Borrador",
				"public": "Público",
				"restricted": "Restringido",
				"private": "Privado",
				"safety": "La seguridad",
				"security": "Seguridad",
				"rescue": "Rescate",
				"fire": "Fuego",
				"health": "Salud",
				"transport": "Transporte",
				"other": "Otro",
				"immediate": "Inmediato",
				"expected": "Esperado",
				"future": "Futuro",
				"past": "Pasado",
				"unknown": "Desconocido",
				"extreme": "Extremo",
				"severe": "Grave",
				"moderate": "Moderar",
				"minor": "Menor",
				"observed": "Observado",
				"likely": "Probable",
				"possible": "Posible",
				"unlikely": "Improbable"
			}
		},
		"alertZones": {
			"events": "Eventos",
			"area": "Zona",
			"title": "zonas de alerta",
			"addAlertZone": "Añadir zona de alerta",
			"name": "Nombre",
			"type": "Tipo",
			"whitelist": "Lista blanca",
			"editAlertZone": "Editar Zona de Alerta",
			"areaSelectModalTitle": "Dibujar alrededor de las áreas de esta zona aplica",
			"blacklist": "Lista negra"
		},
		"feeds": {
			"tutorial": {
				"heading": "Usando Feeds",
				"steps": "<ol><li> Asegúrese de que los tipos y categorías de desastres elegidos estén habilitados para esta aplicación. Esto determinará qué alimentaciones y eventos pueden habilitarse a continuación. </li><li> Seleccione los feeds y los tipos de eventos que desea contribuir a las alertas de esta aplicación. </li></ol>",
				"body": "Algunos tipos de alerta pueden provenir de múltiples fuentes de alerta. Esta sección le permite habilitar o deshabilitar feeds específicos y tipos de eventos de feeds individuales para esta aplicación."
			},
			"delete": {
				"confirm": "¿Estás seguro?",
				"yes": "Sí, eliminarlo",
				"events": "Todos los eventos habilitados se deshabilitarán para este feed."
			},
			"type": "Tipo",
			"scope": "Alcance",
			"disable": "Inhabilitar",
			"configure": "Configurar",
			"enable": "Habilitar",
			"code": "Código",
			"feed": "Alimentar",
			"events": "Eventos",
			"languages": "Idiomas Disponibles",
			"title": "Feeds"
		}
	},
	"appCreate": {
		"title": "Crear nueva aplicación",
		"details": "Detalles de la aplicación",
		"copyFrom": "Copiar el contenido de",
		"emptyApp": "Aplicación vacía",
		"createNewOrganization": "Crear nueva organización"
	},
	"organization": {
		"title": "Organización",
		"details": "Detalles de la organización",
		"noLanguages": "No hay lenguas asignados",
		"confirmLanguageAssignment": "¿Seguro que desea asignar este idioma? Asignaciones de idioma no se pueden quitar."
	},
	"mailingList": {
		"title": "Listas de correo",
		"list": "Listas disponibles",
		"export": "Exportar",
		"dateFrom": "Desde",
		"dateTo": "Hasta"
	},
	"moderation": {
		"title": "Moderación",
		"image": {
			"title": "La moderación de imagen",
			"location": "Ubicación"
		}
	},
	"manageApps": {
		"title": "Administrar aplicaciones",
		"yourNextApp": "Su próxima aplicación",
		"easilySetUpYourNextApp": "Configure fácilmente su próxima aplicación"
	},
	"dashboard": {
		"title": "Panel de control"
	},
	"manage": {
		"title": "Administrar"
	},
	"userApps": {
		"title": "Aplicaciones de usuario"
	},
	"posts": {
		"title": "Mensajes"
	},
	"achievements": {
		"image": "Imagen",
		"edit": "Editar Logro",
		"name": "Nombre",
		"completion": "Terminación",
		"title": "Logros",
		"badge": "Distintivo"
	},
	"triggers": {
		"subclasses": {
			"RaisedTrigger": {
				"amount": "Cantidad",
				"singular": "Monto levantó"
			}
		},
		"plural": "Los disparadores"
	},
	"mind-moderation": {
		"emoodji": {
			"singular": "Emoodji"
		},
		"title": "Emoodji Moderación",
		"empty": "No hay Emoodjis a moderada"
	},
	"crossBorders": {
		"contact": {
			"title": "Título",
			"number": "Número de teléfono",
			"editItem": "Editar contacto"
		},
		"phrase": {
			"phrase": "Frase",
			"translation": "Traducción",
			"editItem": "Editar Frase"
		},
		"country": {
			"code": "Código de país",
			"name": "Nombre del país",
			"description": "El país de destino"
		},
		"contacts": "contactos",
		"title": "cruzar las fronteras",
		"list": "Lista de destinos",
		"editItem": "Editar destino País",
		"translations": "Las traducciones de frases",
		"phrases": "frases",
		"link": {
			"title": "Título",
			"destination": "Destino",
			"placeholder": "Título del destino"
		},
		"links": "Campo de golf"
	},
	"whatnow": {
		"attribution": {
			"attribution": "Atribución",
			"imageHelp": "Carga de imagen / icono.",
			"image": "Imagen de atribución",
			"message": "Mensaje de atribución",
			"messageHelp": "Mensaje que va junto con la atribución.",
			"socName": "Nombre de la Sociedad de Atribución",
			"urlHelp": "URL que enlaza con más información sobre el Distribuidor.",
			"socNameHelp": "Nombre de la Sociedad que se debe atribuir con los whatnows.",
			"url": "URL de atribución"
		},
		"edit": {
			"moreinfoHelp": {
				"placeholder": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
			},
			"translations": "Traducciones",
			"event": "Peligro",
			"languages": "Idiomas",
			"after": "Después",
			"description": "Descripción",
			"recover": "Recuperar",
			"recoverHelp": "Después",
			"immediateHelp": "Responder",
			"immediate": "Inmediato",
			"forecast": "Pronóstico",
			"selectLang": "Selecciona un idioma",
			"before": "antes de",
			"warning": "Advertencia",
			"title": "Título",
			"watch": "Reloj",
			"watchHelp": "Prepárate para responder",
			"warningHelp": "Prepárate para responder",
			"during": "Durante",
			"new": "Agrega un nuevo idioma",
			"titleHelp": "Título para el ahora.",
			"moreinfo": "URL de información",
			"midterm": "Previsión a medio plazo",
			"addALang": "Agrega un idioma para comenzar",
			"editWhatnow": "Edita lo que ahora",
			"descriptionHelp": "Una descripción para el ahora.",
			"midtermHelp": "Reducir los riesgos (acciones a más largo plazo)",
			"beforeHelp": "Cómo prepararse antes de que ocurra el evento.",
			"afterHelp": "Qué hacer después de que el evento haya sucedido",
			"eventhelp": "El tipo de evento para el ahora.",
			"moreinfohelp": "URL para obtener más información sobre el ahora.",
			"duringHelp": "Qué hacer mientras el evento está sucediendo.",
			"forecastHelp": "Evaluar y planificar (acciones a corto plazo)",
			"empty": "Por favor agregue un idioma para crear un What Now",
			"missing": "Falta contenido"
		},
		"title": "Título",
		"whatnow": "¿Ahora que?",
		"during": "Durante",
		"description": "Descripción",
		"eventType": "Peligro",
		"after": "Después",
		"before": "antes de",
		"content-not-set": "Contenido no establecido",
		"selectSoc": "Seleccione una sociedad",
		"empty": "No hay entradas de what now, haga clic en agregar para crear una"
	},
	"arpro": {
		"codeGroup": {
			"name": "Nombre",
			"codes": "Códigos",
			"description": "Descripción",
			"codeGroup": "Grupos de código",
			"addCodes": "Ingresa un código aquí",
			"editItem": "Editar grupo de código"
		},
		"colours": {
			"description": "Descripción",
			"name": "Nombre",
			"colours": "Colores"
		},
		"search": {
			"tensileElongation": "Alargamiento a la tracción",
			"tensileStrength": "Resistencia a la tracción",
			"search": "Búsqueda de Producto",
			"burningRate": "Tasa de quema",
			"mouldedDensity": "Densidad moldeada",
			"compressionStrength50": "Fuerza de Compresión 50",
			"compressionStrength75": "Fuerza de Compresión 75",
			"compressionStrength25": "Resistencia a la compresión 25",
			"resilience75DynamicCompression": "Resistencia 75 Compresión dinámica",
			"equivalentYoungsModulus": "Módulo de Youngs equivalente"
		},
		"product": {
			"properties": {
				"bag": "Bolso",
				"bulkLabel": "Abultar",
				"packaging": "embalaje",
				"food": "Comida aprobada",
				"size": "Tamaño (mm)",
				"weight": "Peso (mg)",
				"bulk": "Densidad a granel (g / l)",
				"moulded": "Densidad moldeada (g / l)"
			},
			"notes": "Notas",
			"colour": "Color",
			"name": "Nombre",
			"product": "Productos",
			"specifications": "Presupuesto",
			"grade": "Grado",
			"physicalProps": "Propiedades físicas",
			"access": "Códigos de acceso",
			"editItem": "Editar producto",
			"linked": "Tiene códigos de acceso",
			"rawData": "Datos JSON sin procesar",
			"moulded": "Rango de densidad moldeada (g / l)",
			"rawDataError": "Datos JSON sin formato: datos JSON no válidos",
			"noneLinked": "Sin códigos de acceso"
		},
		"arpro": "arpro",
		"noitems": "No hay elementos para mostrar, haga clic en agregar arriba."
	},
	"r4b": {
		"level": {
			"red": "rojo",
			"blue": "Azul",
			"title": "Nivel",
			"colour": "Color",
			"levels": "Niveles",
			"green": "Verde",
			"alpha": "Alfa",
			"selectBadge": "Por favor seleccione una insignia"
		},
		"modules": {
			"blockContent": {
				"logic": {
					"title": "Lógica de visibilidad de sección"
				},
				"slider": {
					"interval": "Intervalo",
					"startLabel": "Comience la etiqueta",
					"endLabel": "Etiqueta final"
				},
				"question": {
					"title": "Pregunta y respuesta",
					"textHelp": "Ingrese una pregunta",
					"addResponse": "Añadir una respuesta",
					"selectionHelp": "Seleccione una sección para continuar",
					"responseHelp": "Ingrese una respuesta",
					"addAnotherResponse": "Agregue otra respuesta",
					"answerHelp": "Ingrese una respuesta"
				},
				"quote": {
					"title": "Citar",
					"citation": "Ingresa la cita aquí"
				},
				"checkbox": {
					"title": "Casillas de verificación"
				},
				"textInput": {
					"title": "Entrada de texto",
					"help": "Ingresa la etiqueta de entrada aquí",
					"multi": "Texto de varias líneas?"
				},
				"link": {
					"title": "Campo de golf"
				},
				"help": {
					"title": "Objeto de ayuda"
				},
				"task": {
					"title": "Objeto de tarea",
					"help": "Ingrese un objetivo de la tarea"
				},
				"text": {
					"title": "Objeto de texto"
				},
				"radio": {
					"title": "Botones de radio"
				},
				"clickToEdit": "Haz click para editar",
				"enterText": "Introducir texto aquí",
				"break": "Salto de página",
				"section": "Sección",
				"identifier": "Identificador",
				"videoLink": "Enlace al video externo",
				"addSection": "Arrastra un elemento aquí para crear una nueva sección",
				"enterTitle": "Ingresa un título",
				"inputLabel": "Etiqueta de entrada de texto",
				"addIdentifier": "Agrega un identificador"
			},
			"section": {
				"title": "SECCIÓN"
			},
			"blockTypes": {
				"text": "TEXTO",
				"radio": "RADIO",
				"link": "ENLAZAR",
				"qna": "Q & A",
				"image": "IMAGEN",
				"video": "VÍDEO",
				"quote": "CITAR",
				"check": "COMPROBAR",
				"help": "AYUDA",
				"task": "TAREA",
				"slider": "DESLIZADOR",
				"media": "BLOQUES DE MEDIOS",
				"textInput": "ENTRADA DE TEXTO",
				"userInput": "BLOQUES DE ENTRADA DE USUARIO"
			},
			"levels": "Niveles",
			"version": "Versión",
			"people": "gente",
			"visibility": "Visibilidad",
			"mins": "minutos",
			"level": "Nivel",
			"module": "Módulo",
			"person": "persona",
			"title": "Módulos",
			"unsaved": "Hay cambios no guardados",
			"saved": "Se guardan todos los cambios"
		},
		"assessments": {
			"assessment": "Evaluación",
			"pre": "Pre evaluación"
		},
		"languages": "Idiomas",
		"people": "GENTE",
		"person": "PERSONA",
		"sections": "SECCIONES",
		"title": "ATLAS",
		"questions": "Preguntas",
		"minutes": "MINOS",
		"languageVars": "Variaciones de idioma",
		"contentBlocks": "Bloques de contenido",
		"admin": "Tablero de administración",
		"inputBlocks": "BLOQUES DE ENTRADA DE USUARIO",
		"mediaBlocks": "BLOQUES DE MEDIOS",
		"empty": "No hay módulos o evaluaciones aquí, agregue uno a este nivel para comenzar."
	},
	"rspb": {
		"factFile": {
			"start": "comienzo",
			"factFile": "Archivos del hecho",
			"images": "Imágenes",
			"funFacts": "Hechos graciosos",
			"description": "Descripción",
			"features": "Caracteristicas",
			"scientificName": "Nombre científico",
			"tags": "Etiquetas",
			"activities": "Ocupaciones",
			"show": "Espectáculo",
			"name": "Nombre",
			"commonName": "Nombre común",
			"seen": "Visto",
			"addImages": "Seleccionar imagen",
			"addFact": "Ingrese un hecho aquí",
			"addActivities": "Ingresa una actividad aquí",
			"addFeatures": "Ingrese una característica aquí",
			"addTags": "Ingresa una etiqueta aquí",
			"editItem": "Editar archivo de hechos"
		},
		"planItem": {
			"difficulty": {
				"hard": "Difícil",
				"easy": "Fácil",
				"medium": "Medio",
				"difficulty": "Seleccione la dificultad"
			},
			"garden": {
				"public": "Público",
				"large": "Grande",
				"small": "Pequeña",
				"medium": "Medio",
				"balconyroof": "Balcón / techo",
				"selectType": "Seleccione el tipo / s de jardín"
			},
			"months": "Meses",
			"from": "Disponible de",
			"title": "Título",
			"to": "Fecha final",
			"type": "Tipo",
			"tags": "Etiquetas",
			"leastImportant": "Menos importante",
			"dates": "fechas",
			"description": "Descripción",
			"mostImportant": "Lo más importante",
			"image": "Imagen",
			"solo": "¿Para la familia?",
			"region": "Región",
			"source": "Fuente",
			"indefinite": "¿Indefinido?",
			"identifier": "Identificador",
			"planItems": "Elementos del plan",
			"duration": "Duración (segundos)",
			"weighting": "Planificar la ponderación de elementos",
			"activityCode": "Código de actividad",
			"weightingHelp": "Prioridad de dónde aparecerá el elemento en la aplicación",
			"pageHelp": "Esta es la página a la que su elemento del plan se vinculará",
			"regionHelp": "Haga clic para seleccionar la región para este elemento del plan",
			"visible": "Visible desde"
		},
		"rspb": "RSPB",
		"noitems": "No hay elementos para mostrar, haga clic en agregar arriba."
	}
}