var User = require('users/user'),
	UserExtra = require('users/user-extra'),
	AgreementList = require('./agreement-list'),
	GDPCSectionView = require('gdpc-console/gdpc-section-view')

module.exports = GDPCSectionView.extend({
	template: require('./user-agreement-view-template'),

	events: {
		'click .sign-button': 'sign'
	},

	initialize: function(options) {
		this._readyCount = 0
		this._totalReadyCount = 3
		this.appId = options.appId

		// Get the society for the currently selected app.
		this.app = App.appList.get(this.appId)
		var society = App.societiesList.get(this.app.get('societyId'))

		this.collection = new AgreementList(null, {societyId: society.id})
		this.collection.once('sync', this.agreementsFetched, this)
		this.collection.fetch()

		// Load current user details for agreement signing
		this.currentUser = new User({id: 'me'})
		this.currentUser.once('sync', this.ready, this)
		this.currentUser.fetch()

		this.currentUserExtra = new UserExtra({id: 'me'})
		this.currentUserExtra.once('sync', this.ready, this)
		this.currentUserExtra.fetch()
	},

	getPageTitle: function() {
		return $.t('nav.items.userAgreement.title')
	},

	agreementsFetched: function() {
		// Get the agreement for this app type (if it exists).
		this.signature = this.collection.findWhere({typeId: this.app.get('type')})

		if (this.signature) {
			this._totalReadyCount++

			var signingUserId = this.signature.get('userId')

			this.signingUser = new User({id: signingUserId})
			this.signingUser.once('sync', this.ready, this)
			this.signingUser.fetch()

			this.signingUserExtra = new UserExtra({id: signingUserId})
			this.signingUserExtra.once('sync', this.ready, this)
			this.signingUserExtra.fetch()
		} else {
			this.signingUser = null
			this.ready()
		}
	},

	getRenderData: function() {
		var societyId = this.app.get('societyId'),
			society = App.societiesList.get(societyId)

		var expiryDate = new Date(society.get('created') * 1000)
		expiryDate.setDate(expiryDate.getDate() + 30)

		var data = {
			timeRemaining: (expiryDate.getTime() - Date.now()) / 1000,
			society: society.get('name'),
			signingUser: (this.signingUser) ? this.signingUser.toJSON() : null,
			signingUserExtra: (this.signingUserExtra) ? this.signingUserExtra.toJSON() : null,
			user: (this.currentUser) ? this.currentUser.toJSON() : {},
			userExtra: (this.currentUserExtra) ? this.currentUserExtra.toJSON() : {},
			isGDPC: this.app.isFirstAidApp(),
			isHazards: this.app.isHazardsApp()
		}

		if (this.signature) {
			data.timestamp = this.signature.get('timestamp')
		}

		return data
	},

	ready: function() {
		if (++this._readyCount < this._totalReadyCount) {
			return
		}

		this.render()

		// Only show signing form if not already signed and user has write permission
		if (!this.signature && App.acl.hasWritePermission('User Agreement')) {
			this.$('.agreement-sign').show()
		}

		App.stopLoad()
	},

	sign: function() {
		var agreed = $('#agreement-checkbox').prop('checked')

		if (!agreed) {
			swal($.t('error.oops'), $.t('agreement.checkToAgree'), 'error')
			return
		}

		App.startLoad()

		this.collection.once('sync', this.agreementsFetched, this)
		this.collection.create({
			typeId: this.app.get('type')
		})
	}
})
