/**
 * @url https://github.com/datasets/language-codes/blob/master/data/language-codes-3b2.csv
 */
module.exports = [
	['aar', 'aa', 'Afar'],
	['abk', 'ab', 'Abkhazian'],
	['afr', 'af', 'Afrikaans'],
	['aka', 'ak', 'Akan'],
	['alb', 'sq', 'Albanian'],
	['amh', 'am', 'Amharic'],
	['ara', 'ar', 'Arabic'],
	['arg', 'an', 'Aragonese'],
	['arm', 'hy', 'Armenian'],
	['asm', 'as', 'Assamese'],
	['ava', 'av', 'Avaric'],
	['ave', 'ae', 'Avestan'],
	['aym', 'ay', 'Aymara'],
	['aze', 'az', 'Azerbaijani'],
	['bak', 'ba', 'Bashkir'],
	['bam', 'bm', 'Bambara'],
	['baq', 'eu', 'Basque'],
	['bel', 'be', 'Belarusian'],
	['ben', 'bn', 'Bengali'],
	['bih', 'bh', 'Bihari languages'],
	['bis', 'bi', 'Bislama'],
	['bos', 'bs', 'Bosnian'],
	['bre', 'br', 'Breton'],
	['bul', 'bg', 'Bulgarian'],
	['bur', 'my', 'Burmese'],
	['cat', 'ca', 'Catalan; Valencian'],
	['cha', 'ch', 'Chamorro'],
	['che', 'ce', 'Chechen'],
	['chi', 'zh', 'Chinese'],
	['chu', 'cu', 'Church Slavic'],
	['chv', 'cv', 'Chuvash'],
	['cor', 'kw', 'Cornish'],
	['cos', 'co', 'Corsican'],
	['cre', 'cr', 'Cree'],
	['cze', 'cs', 'Czech'],
	['dan', 'da', 'Danish'],
	['div', 'dv', 'Divehi; Dhivehi; Maldivian'],
	['dut', 'nl', 'Dutch; Flemish'],
	['dzo', 'dz', 'Dzongkha'],
	['eng', 'en', 'English'],
	['epo', 'eo', 'Esperanto'],
	['est', 'et', 'Estonian'],
	['ewe', 'ee', 'Ewe'],
	['fao', 'fo', 'Faroese'],
	['fij', 'fj', 'Fijian'],
	['fin', 'fi', 'Finnish'],
	['fre', 'fr', 'French'],
	['fry', 'fy', 'Western Frisian'],
	['ful', 'ff', 'Fulah'],
	['geo', 'ka', 'Georgian'],
	['ger', 'de', 'German'],
	['gla', 'gd', 'Gaelic; Scottish Gaelic'],
	['gle', 'ga', 'Irish'],
	['glg', 'gl', 'Galician'],
	['glv', 'gv', 'Manx'],
	['gre', 'el', 'Greek, Modern (1453-)'],
	['grn', 'gn', 'Guarani'],
	['guj', 'gu', 'Gujarati'],
	['hat', 'ht', 'Haitian; Haitian Creole'],
	['hau', 'ha', 'Hausa'],
	['heb', 'he', 'Hebrew'],
	['her', 'hz', 'Herero'],
	['hin', 'hi', 'Hindi'],
	['hmo', 'ho', 'Hiri Motu'],
	['hrv', 'hr', 'Croatian'],
	['hun', 'hu', 'Hungarian'],
	['ibo', 'ig', 'Igbo'],
	['ice', 'is', 'Icelandic'],
	['ido', 'io', 'Ido'],
	['iii', 'ii', 'Sichuan Yi; Nuosu'],
	['iku', 'iu', 'Inuktitut'],
	['ile', 'ie', 'Interlingue; Occidental'],
	['ina', 'ia', 'Interlingua'],
	['ind', 'id', 'Indonesian'],
	['ipk', 'ik', 'Inupiaq'],
	['ita', 'it', 'Italian'],
	['jav', 'jv', 'Javanese'],
	['jpn', 'ja', 'Japanese'],
	['kal', 'kl', 'Kalaallisut; Greenlandic'],
	['kan', 'kn', 'Kannada'],
	['kas', 'ks', 'Kashmiri'],
	['kau', 'kr', 'Kanuri'],
	['kaz', 'kk', 'Kazakh'],
	['khm', 'km', 'Central Khmer'],
	['kik', 'ki', 'Kikuyu; Gikuyu'],
	['kin', 'rw', 'Kinyarwanda'],
	['kir', 'ky', 'Kirghiz; Kyrgyz'],
	['kom', 'kv', 'Komi'],
	['kon', 'kg', 'Kongo'],
	['kor', 'ko', 'Korean'],
	['kua', 'kj', 'Kuanyama; Kwanyama'],
	['kur', 'ku', 'Kurdish'],
	['lao', 'lo', 'Lao'],
	['lat', 'la', 'Latin'],
	['lav', 'lv', 'Latvian'],
	['lim', 'li', 'Limburgan; Limburger; Limburgish'],
	['lin', 'ln', 'Lingala'],
	['lit', 'lt', 'Lithuanian'],
	['ltz', 'lb', 'Luxembourgish; Letzeburgesch'],
	['lub', 'lu', 'Luba-Katanga'],
	['lug', 'lg', 'Ganda'],
	['mac', 'mk', 'Macedonian'],
	['mah', 'mh', 'Marshallese'],
	['mal', 'ml', 'Malayalam'],
	['mao', 'mi', 'Maori'],
	['mar', 'mr', 'Marathi'],
	['may', 'ms', 'Malay'],
	['mlg', 'mg', 'Malagasy'],
	['mlt', 'mt', 'Maltese'],
	['mon', 'mn', 'Mongolian'],
	['nau', 'na', 'Nauru'],
	['nav', 'nv', 'Navajo; Navaho'],
	['nbl', 'nr', 'Ndebele, South; South Ndebele'],
	['nde', 'nd', 'Ndebele, North; North Ndebele'],
	['ndo', 'ng', 'Ndonga'],
	['nep', 'ne', 'Nepali'],
	['nno', 'nn', 'Norwegian Nynorsk; Nynorsk, Norwegian'],
	['nob', 'nb', 'BokmÃ¥l, Norwegian; Norwegian BokmÃ¥l'],
	['nor', 'no', 'Norwegian'],
	['nya', 'ny', 'Chichewa; Chewa; Nyanja'],
	['oci', 'oc', 'Occitan (post 1500); ProvenÃ§al'],
	['oji', 'oj', 'Ojibwa'],
	['ori', 'or', 'Oriya'],
	['orm', 'om', 'Oromo'],
	['oss', 'os', 'Ossetian; Ossetic'],
	['pan', 'pa', 'Panjabi; Punjabi'],
	['per', 'fa', 'Persian'],
	['pli', 'pi', 'Pali'],
	['pol', 'pl', 'Polish'],
	['por', 'pt', 'Portuguese'],
	['prs', '', 'Dari'],
	['pus', 'ps', 'Pushto; Pashto'],
	['que', 'qu', 'Quechua'],
	['roh', 'rm', 'Romansh'],
	['rum', 'ro', 'Romanian; Moldavian; Moldovan'],
	['run', 'rn', 'Rundi'],
	['rus', 'ru', 'Russian'],
	['sag', 'sg', 'Sango'],
	['san', 'sa', 'Sanskrit'],
	['sin', 'si', 'Sinhala; Sinhalese'],
	['slo', 'sk', 'Slovak'],
	['slv', 'sl', 'Slovenian'],
	['sme', 'se', 'Northern Sami'],
	['smo', 'sm', 'Samoan'],
	['sna', 'sn', 'Shona'],
	['snd', 'sd', 'Sindhi'],
	['som', 'so', 'Somali'],
	['sot', 'st', 'Sotho, Southern'],
	['spa', 'es', 'Spanish; Castilian'],
	['srd', 'sc', 'Sardinian'],
	['srp', 'sr', 'Serbian'],
	['ssw', 'ss', 'Swati'],
	['sun', 'su', 'Sundanese'],
	['swa', 'sw', 'Swahili'],
	['swe', 'sv', 'Swedish'],
	['tah', 'ty', 'Tahitian'],
	['tam', 'ta', 'Tamil'],
	['tat', 'tt', 'Tatar'],
	['tel', 'te', 'Telugu'],
	['tgk', 'tg', 'Tajik'],
	['tgl', 'tl', 'Tagalog'],
	['tha', 'th', 'Thai'],
	['tib', 'bo', 'Tibetan'],
	['tir', 'ti', 'Tigrinya'],
	['ton', 'to', 'Tonga (Tonga Islands)'],
	['tsn', 'tn', 'Tswana'],
	['tso', 'ts', 'Tsonga'],
	['tuk', 'tk', 'Turkmen'],
	['tur', 'tr', 'Turkish'],
	['twi', 'tw', 'Twi'],
	['uig', 'ug', 'Uighur; Uyghur'],
	['ukr', 'uk', 'Ukrainian'],
	['urd', 'ur', 'Urdu'],
	['uzb', 'uz', 'Uzbek'],
	['ven', 've', 'Venda'],
	['vie', 'vi', 'Vietnamese'],
	['vol', 'vo', 'VolapÃ¼k'],
	['wel', 'cy', 'Welsh'],
	['wln', 'wa', 'Walloon'],
	['wol', 'wo', 'Wolof'],
	['xho', 'xh', 'Xhosa'],
	['yid', 'yi', 'Yiddish'],
	['yor', 'yo', 'Yoruba'],
	['zha', 'za', 'Zhuang; Chuang'],
	['zul', 'zu', 'Zulu']
]
