var AppSetupSectionView  = require('app-setup/app-setup-section-view'),
	EditorSectionView    = require('editor/editor-section-view'),
	HazardsSectionView   = require('hazards/hazards-section-view'),
	ManageSectionView    = require('manage/manage-section-view'),
	PublishSectionView   = require('publish/publish-section-view'),
	DashboardSectionView = require('dashboard/dashboard-section-view'),
	GDPCSectionView      = require('gdpc-console/gdpc-section-view')
/**
 * Exports {@link ApplicationView}.
 * @module
 */
module.exports = Backbone.View.extend(/** @lends ApplicationView.prototype */{
	/** @override */
	className: 'storm',
	/** @override */
	template: require('./application-view-template'),

	/** @type {StormView} */
	view: null,

	/** @type {Array.<StormView>} */
	sections: [
		new EditorSectionView(),
		new AppSetupSectionView(),
		new HazardsSectionView(),
		new ManageSectionView(),
		new PublishSectionView(),
		new DashboardSectionView(),
		new GDPCSectionView()
	],

	/** @override */
	events: {
		'click .hamburger': 'hamburgerClick_',
		'change .app-select': 'appSelectChange_',
		'change .client-language': 'languageChange_'
	},

	/**
	 * @constructs ApplicationView
	 * @extends Backbone.View
	 * @override
	 * @classdesc Container view for the whole app. Renders all child views,
	 *     holds navigation.
	 */
	initialize: function(options) {
		if (!options.appList) {
			throw new Error('No app list specified')
		}

		if (!options.user) {
			throw new Error('No user specified')
		}

		/** @private {AppList} */
		this.appList_ = options.appList
		/** @private {User} */
		this.user_ = options.user

		this.listenTo(this.user_, 'change', this.render)
	},

	/** @override */
	getRenderData: function() {
		if (localStorage.getItem('lang')) {
			this.currentLang = localStorage.getItem('lang')
			this.update_il8next(this.currentLang)
		}

		var view = this.view,
			app

		if (this.app) {
			app = this.app.toJSON()
		}

		var data = {
			sections: this.getSections_(),
			app: app,
			user: this.user_.toJSON(),
			appSelectOptions: App.generateAppSelect(),
			apiRoot: window.App.apiRoot,
			cmsVersion: window.env.version
		}

		if (view) {
			data.sectionTitle = view.getSectionTitle()
			data.pageTitle = view.getPageTitle()
			data.pages = view.getVisiblePages()
		}

		return data
	},

	/**
	 * Renders the app navigation and the current child view.
	 * @override
	 */
	render: function() {
		this.setLanguage_()

		Backbone.View.prototype.render.apply(this, arguments)
		var view = this.view

		if (view) {
			this.$('.storm-view-content').append(this.view.el)
			view.render().delegateEvents()

			if (view.actionsView) {
				this.$('.actions').append(view.actionsView.render().el)
			}

			// Set the active subnavigation tab.
			var $breadcrumbs = this.$('.breadcrumbs a')

			view.getVisiblePages().forEach(function(page, i) {
				if (!(page.views instanceof Array)) {
					throw new Error('Missing child views array')
				}

				if (page.views.indexOf(view.constructor) > -1) {
					$breadcrumbs.eq(i).addClass('active')
				}
			})
		}

		// Set current value on app select.
		if (this.app) {
			this.$('.app-select').val(this.app.id)
		}

		return this
	},

	afterRender: function() {
		// Hide developer-only controls.
		if (!App.developerMode) {
			this.$('.developer-mode').remove()
		}
	},

	/**
	 * Sets the current app by ID.
	 * @param {number} appId The ID of the app to set.
	 * @returns {boolean} Whether the app was set successfully. This call will
	 *     fail if the app is not assigned to the current user, or does not
	 *     exist.
	 */
	setApp: function(appId) {
		// Check app exists before trying to navigate to it.
		var app = this.appList_.get(appId)

		if (!app) {
			App.router.redirectToContent()
			return false
		}

		if (app !== this.app) {
			this.app = app
			this.render()
		}

		// Cache last app ID.
		localStorage.setItem('lastApp', appId)

		return true
	},

	/**
	 * Retrieves all the page tags
	 * @returns {Promise} A promise for the get tags request
	 */
	setPageTags: function() {
		return new Promise(function(resolve) {
			$.ajax({
				url: App.apiRoot + 'apps/' + this.app.id + '/tags',

				type: 'GET',

				headers: App.session.getHeadersObject(),

				success: function(data) {
					this.pageTags = data
					resolve()
				}.bind(this),

				error: function() {
					this.pageTags = []
					resolve() // resolve anyway
				}
			})
		}.bind(this))
	},

	/**
	 * Renders a view into the application.
	 * @param {StormView} view The view to render.
	 */
	setPage: function(view) {
		if (this.view) {
			this.view.destroy()

			if (this.view.actionsView) {
				this.view.actionsView.destroy()
			}
		}

		this.view = view
		this.setPageTitle_(view.getPageTitle())
		this.toggleSidebar_(false)
		view.loadData().then(this.render.bind(this))
	},

	/**
	 * Handles click events on the hamburger button. Toggles the sidebar
	 * visibility.
	 * @private
	 */
	hamburgerClick_: function() {
		this.toggleSidebar_()
	},

	/**
	 * Handles change events on the app select dropdown. Sets the new app and
	 * reloads the current child view.
	 * @private
	 */
	appSelectChange_: function() {
		var appId = this.$('.app-select').val()

		// If the current route contains an app ID manually trigger the route
		// change.
		var routeMatch = document.location.pathname.match(/^\/apps\/(\w+)\/?(.*)$/)

		if (routeMatch !== null) {
			var lastApp = routeMatch[1],
				path    = routeMatch[2]

			// If switching app in the content editor, don't keep the whole
			// path (page ID).
			if (path.substr(0, 5) === 'pages' && lastApp !== appId) {
				path = 'pages'
			}

			App.router.navigate('/apps/' + appId + '/' + path, {trigger: true})

			if (this.view) {
				this.view.trigger('change:app', appId)
			}
		} else {
			// Reload whatever route we were on
			Backbone.history.loadUrl()
		}
	},

	/**
	 * @returns {Array} List of all (visible) section names and child pages.
	 * @private
	 */
	getSections_: function() {
		return this.sections.filter(function(section) {
			return section.isVisible()
		}).map(function(section) {
			return {
				name: section.getSectionTitle(),
				pages: section.getVisiblePages()
			}
		})
	},

	/**
	 * Toggles the sidebar between shown and hidden. Optionally pass a state to
	 * set.
	 * @param {boolean} [isOpen] Optional state to set ({@code true} for open,
	 *     {@code false} for closed).
	 * @private
	 */
	toggleSidebar_: function(isOpen) {
		this.$el.toggleClass('sidebar-open', isOpen)
	},

	/**
	 * Sets the title of the web page to the specified string appended with '
	 * :: Storm'.
	 * @param {string} title The title to set.
	 * @private
	 */
	setPageTitle_: function(title) {
		document.title = title + ' :: Storm'
	},

	setLanguage_: function() {
		if (this.currentLang) {
			App.clientLang = this.currentLang
		}
	},

	/**
	 * Sets the language of the CMS and triggers a re-render.
	 */
	languageChange_: function() {
		App.clientLang = this.$('.client-language').val()
		this.currentLang = App.clientLang
		// Force langage change with il8next
		this.update_il8next(this.currentLang)
		// Set language to localStorage
		localStorage.setItem('lang', App.clientLang)
		location.reload()
	},

	update_il8next: function(lang) {
		// Force langage change with il8next
		App.il8next.changeLanguage(lang, function(err, t) {
			if (err) {
				console.error('Could not load language ', lang, t)
				return
			}
		})
	}
})
