module.exports = Backbone.Model.extend({
	initialize: function(options) {
		this.appId = options.appId
		this.objectId = options.objectId

		this.set('id', this.appId || this.objectId)
	},

	url: function() {
		if (this.objectId) {
			return App.apiRoot + 'objects/' + this.objectId + '/structure'
		}

		return App.apiRoot + 'apps/' + this.appId + '/structure'
	},

	defaults: {
		children: new Backbone.Collection()
	},

	parse: function(response) {
		if (response.children) {
			_.each(response.children, this.parse, this)
			response.children = new Backbone.Collection(response.children)
		}

		return response
	}
})
