{
	"error": {
		"noPermission": "You don't have permission to perform that action.",
		"noAccess": "You don't have access to this system. Please speak to your administrator.",
		"noAccessJoin": "You do not currently have access to this system. Would you like to join?",
		"saveFailed": "Save failed. Please refresh the page.",
		"connect": "Could not connect to API server.",
		"noApps": "You don't have access to any apps",
		"rootPage": "Root page could not be found.",
		"generic": "An error has occurred.",
		"exitWarning": "App data is still being saved. If you leave the page now data may be lost.",
		"allFields": "Please fill in all fields.",
		"cantPaste": "This object type cannot be pasted here.",
		"lockedBy": "This object is currently locked by ",
		"oops": "Oops!"
	},
	"common": {
		"language": "Language",
		"longLoad": "Retrieving a large amount of data, this may take up to a minute.",
		"save": "Save",
		"submit": "Submit",
		"cancel": "Cancel",
		"next": "Next",
		"finish": "Finish",
		"close": "Close",
		"back": "Back",
		"preview": "Preview",
		"publish": "Publish",
		"upload": "Upload",
		"done": "Done",
		"tag": "Tag",
		"page": "Page",
		"view": "View",
		"email": "Email address",
		"username": "Username",
		"add": "Add",
		"edit": "Edit",
		"tools": "Tools",
		"description": "Description",
		"position": "Position",
		"warning": "WARNING",
		"settings": "Settings",
		"filter": "Filter",
		"clickToBrowseMap": "Click to browse map",
		"closeThisMessage": "Close this message",
		"pleaseWait": "Please wait",
		"sections": "Sections",
		"pageTags": {
			"learn": "Learn",
			"prepare": "Prepare",
			"emergency": "Emergency",
			"tests": "Tests",
			"info": "Info",
			"other": "Other"
		},
		"linkTypes": {
			"external": "External",
			"internal": "Internal",
			"native": "Native",
			"uri": "URI",
			"timer": "Timer",
			"call": "Call",
			"sms": "SMS",
			"share": "Share",
			"emergency": "Emergency",
			"appLink": "Inter-app",
			"email": "Email"
		},
		"linkDescriptions": {
			"external": "A link to an external web page. Will open within the app.",
			"internal": "A link to a different page within the app.",
			"native": "A link to a piece of native functionality within the app.",
			"uri": "A link to an external web page or application. Will cause the user to leave the app.",
			"timer": "A link which starts a countdown timer on the device.",
			"call": "A link to dial a phone number from the user's device.",
			"sms": "A link to send an SMS message from the user's device.",
			"share": "A link to share the app via the native 'share' dialog on the device.",
			"emergency": "A link to dial the emergency phone number specified in the user's settings.",
			"appLink": "A link to a page in another app.",
			"email": "A link to send an email to the specified address."
		},
		"approve": "Approve",
		"approved": "Approved",
		"reject": "Reject"
	},
	"login": {
		"title": "Login",
		"inputs": {
			"password": "Password"
		},
		"error": {
			"title": "Oopsie!",
			"body": "You've entered the incorrect username or password.",
			"emailNotFound": "Email address not found.",
			"linkExpired": "This password reset link has expired."
		},
		"forgot": {
			"title": "We all forget sometimes!",
			"body": "Enter the email address linked to your account."
		},
		"success": {
			"title": "Success!",
			"body": "You should shortly receive an email with instructions to reset your password.",
			"loginBody": "You've logged in successfully, but there's nowhere to go! You are at auth.3sidedcube.com. Please go to your organisation's Storm CMS."
		},
		"links": {
			"forgot": "I forgot...",
			"return": "Return to login"
		},
		"reset": {
			"newPassword": "New password",
			"confirm": "Confirm",
			"submit": "Submit",
			"success": "Your password has been set, you can now close this window."
		}
	},
	"nav": {
		"navigate": "Navigate",
		"logout": "Log Out",
		"items": {
			"appSetup": {
				"title": "App Setup",
				"description": "Configure the application name, icon, text localisation and App Store info"
			},
			"content": {
				"title": "Content",
				"description": "Edit in-app content"
			},
			"publish": {
				"title": "Publish",
				"description": "Publish changes to the dev or live build"
			},
			"stats": {
				"title": "Stats",
				"description": "See how the app is doing"
			},
			"userAgreement": {
				"title": "User agreement",
				"description": "View and sign the Universal App Program Agreement"
			},
			"users": {
				"title": "Users",
				"description": "Manage all users"
			},
			"stories": {
				"title": "Stories",
				"description": "Manage stories"
			},
			"levels": {
				"title": "Levels",
				"description": "Manage app progress levels"
			},
			"badges": {
				"title": "Badges",
				"description": "Manage badges awarded for completing activities in app"
			},
			"push": {
				"title": "Push notifications",
				"description": "Manage and send push notifications to users"
			},
			"sprint": {
				"title": "Status board",
				"description": "View current development sprint status"
			},
			"bugs": {
				"title": "Bug Reporting",
				"description": "Report issues with the editor or app build"
			},
			"gdpcConsole": {
				"title": "NS Console",
				"description": "View National Society and Dev Pool statuses"
			},
			"hazards": {
				"title": "Disasters and Alerts",
				"description": "Priorities, disaster management, alerts and moderation"
			},
			"decisionTree": {
				"title": "Decision tree",
				"description": "View and edit the decision tree for the app"
			},
			"admin": {
				"title": "Admin",
				"description": "View stats, edit users and organization details"
			}
		}
	},
	"localisations": {
		"title": "Localizations",
		"string": "String",
		"key": "Key",
		"addNew": "Add new localizations",
		"addMultiple": "Add multiple localizations",
		"addMultipleInstructions": "Add or update localizations in bulk. Use CSV in the format",
		"uploadCsv": "Upload CSV",
		"copyFromAnotherApp": "Copy from another app",
		"confirmCopy": "Are you sure you want to copy localizations from the selected app?",
		"overwriteExisting": "Overwrite existing localizations",
		"overwriteWarning": "Any existing translation you may have done will be overwritten.",
		"all": "<b>All</b> localizations",
		"errors": {
			"invalidKey": "Invalid localization key. Keys must start with an underscore and only contain uppercase letters and underscores. Keys must contain at least two sections (bits delimited by underscores).",
			"csvTooManyColumns": "Too many columns in header.",
			"csvInvalidHeader": "Invalid header.",
			"csvInvalidKeyOnLine": "Invalid localization key on line",
			"csvInvalidColumnCountOnLine": "Invalid number of columns on line",
			"expected": "Expected",
			"got": "got"
		}
	},
	"appSetup": {
		"title": "App Setup",
		"appName": "App name",
		"startPage": "Start page",
		"languages": "App languages",
		"localisation": "App localization",
		"publishable": "Publishable to:",
		"translation": "Translations",
		"type": "Type",
		"dev": "Dev",
		"live": "Live",
		"key": "Key",
		"value": "Value",
		"properties": "Properties",
		"publishableLanguages": "Publishable languages",
		"addMoreLanguages": "Only languages assigned to this organization are available here. Assign more in the Admin section.",
		"translationError": "Failed to download translation file. Please check the app and organization have at least one language enabled and publishable.",
		"expandAll": "Expand all",
		"addCsv": "Add CSV",
		"bundleIdentifiers": "SDK Bundle Identifiers"
	},
	"metadata": {
		"title": "Metadata",
		"noBuildPlatforms": "No build platforms exist for this app",
		"appStore": "App Store details",
		"name": "App name",
		"description": "Description",
		"features": "Features",
		"keywords": "Keywords",
		"primaryCategory": "Primary category",
		"secondaryCategory": "Secondary category",
		"copyright": "Copyright",
		"website": "Marketing URL",
		"supportUrl": "Support URL",
		"privacyPolicyUrl": "Privacy policy URL",
		"phoneNumber": "Phone number",
		"launchDate": "Launch date",
		"appIcon": "App icon",
		"iconSize": "Original icon must be 1024x1024 in size.",
		"addScreenshot": "Add Screenshot",
		"appIconHint": "Should be 1024 x 1024 with no transparency. Must be in the JPG or PNG format, with a minimum resolution of at least 72 DPI, and in the RGB color space. It must not contain layers or rounded corners",
		"appPreviewVideo": "App preview video",
		"appPreviewVideoHint": "App video previews must be in the M4V, MP4, or MOV format and can’t exceed 500 MB",
		"appStoreTitle": "App store title",
		"delete": "Delete",
		"emailHint": "Provide an email address for if the App store or users need to get in touch",
		"featureGraphic": "Feature graphic",
		"featureGraphicHint": "The Google Play store requires apps to have a feature graphic or ‘banner’ - the dimensions should be 1024w x 500h. This can be a standalone image, or will be the background image for a promo video (if provided)",
		"languages": "Languages",
		"nameOnDevice": "App name on device",
		"phoneNumberHint": "Must include country dialling code",
		"platformSpecific": "Platform-specific metadata",
		"price": "Price",
		"rating": "Rating",
		"screenshotsHint": "Include screenshots for each screen size. Screenshots must be in the JPG or PNG format, and in the RGB color space",
		"shared": "Cross-platform metadata",
		"shortAppDescription": "Short app description",
		"supportUrlHint": "A URL with support information for your app. This URL will be visible on the App Store",
		"websiteHint": "A URL with marketing information about your app. This URL will be visible on the App Store"
	},
	"plugins": {
		"title": "Plugins",
		"availablePlugins": "Available Plugins"
	},
	"editor": {
		"title": "Editor",
		"pageName": "Page name",
		"pageType": "Page type",
		"pageStyle": "Page style",
		"pageTag": "Page tag",
		"addPage": "Add page",
		"saving": "Saving...",
		"lastSaved": "Last saved:",
		"never": "never",
		"newFromTemplate": "New from template",
		"pageList": {
			"title": "Pages",
			"filter": "Filter pages",
			"allPages": "All pages"
		},
		"addObject": "Add object here",
		"addQuiz": "Add quiz",
		"addApp": "Add app",
		"addLink": "Add link",
		"confirmRelock": "This object is already locked by you. Would you like to lock it again? Your previous lock will be revoked.",
		"confirmRequestNewLock": "Your lock for this page has been revoked. Would you like to request a new lock?",
		"savesQueued": "saves queued",
		"inspector": {
			"title": "Edit Properties",
			"addAnother": "Add another",
			"addBulletItem": "Add bullet item",
			"rootPageDelete": "This is the root page of the app. It cannot be deleted.",
			"areYouSure": "Are you sure?",
			"confirmDelete": "Are you sure you want to delete this element?",
			"confirmPageDelete": "Confirm Page Deletion",
			"confirmPageDeleteWarning": "WARNING: You are deleting a page object. All page content will be removed. Are you sure you want to continue?",
			"confirmApproval": "Are you sure you want to approve this?",
			"moveUp": "Move up",
			"moveDown": "Move down",
			"copy": "Copy",
			"paste": "Paste",
			"copySuccess": "Object copied to clipboard",
			"changeType": "Change type",
			"swapItem": "Swap item",
			"newType": "New type",
			"copiedItem": "Copied item",
			"properties": {
				"annotation": {
					"title": "Annotation",
					"description": "Step number"
				},
				"title": {
					"title": "Title",
					"description": "List item title"
				},
				"name": {
					"title": "Name",
					"description": "Internal system name"
				},
				"hint": {
					"title": "Hint",
					"description": "Instructions for the user"
				},
				"image": {
					"title": "Image",
					"chooseButton": "Choose image",
					"removeButton": "Remove image"
				},
				"video": {
					"title": "Video",
					"chooseButton": "Choose video",
					"attributes": "Attributes",
					"loop": "Loop",
					"addLanguage": "Add language"
				},
				"link": {
					"title": "Link",
					"type": "Type",
					"destination": "Destination",
					"body": "Body text",
					"duration": "Timer duration (minutes)",
					"recipients": "Recipients (one per line)",
					"testLink": "Test link",
					"linkTitle": "Link Title",
					"linkDestination": "Link Destination"
				},
				"images": {
					"add": "Add image",
					"alt": "Alternative text"
				},
				"animation": {
					"chooseButton": "Choose animation",
					"delay": "Frame delay (seconds)"
				},
				"embeddedLinks": {
					"title": "Embedded links",
					"add": "Add link"
				},
				"embeddedMedia": {
					"title": "Embedded media"
				},
				"linkableImageContainer": {
					"title": "Linkable Images",
					"add": "Add Item"
				},
				"header": {
					"title": "Header",
					"description": "Appears above group of items"
				},
				"footer": {
					"title": "Footer",
					"description": "Appears below group of items"
				},
				"badge": {
					"title": "Badge",
					"icon": "Icon",
					"completion": {
						"title": "Completion text",
						"description": "Displayed to user on quiz completion"
					},
					"how": {
						"title": "How to earn",
						"description": "Text describing how to achieve this badge"
					}
				},
				"units": {
					"title": "Units",
					"description": "seconds/metres etc."
				},
				"range": {
					"title": "Range",
					"start": "Start",
					"length": "Length",
					"increment": "Increment"
				},
				"completionText": {
					"title": "Completion text",
					"description": "Displayed to user on answering correctly"
				},
				"failureText": {
					"title": "Failure text",
					"description": "Displayed to user on answering incorrectly"
				},
				"winThreshold": {
					"title": "Win threshold (%)",
					"description": "Percentage of correct answers required to pass the quiz"
				},
				"winMessage": {
					"title": "Win message",
					"description": "Displayed to user on passing the test"
				},
				"loseMessage": {
					"title": "Lose message",
					"description": "Displayed to user on failing the test"
				},
				"shareMessage": {
					"title": "Share message",
					"description": "Text to be shared with badge on social networks"
				},
				"volatile": "Volatile (don't persist state)",
				"textContent": "Text content",
				"quizzes": "Quizzes",
				"answer": "Answer",
				"selectionLimit": "Selection limit",
				"correctAnswer": "Correct answer",
				"initialPosition": "Initial position",
				"relatedLinks": "Related links",
				"winRelatedLinks": "Win related links",
				"loseRelatedLinks": "Lose related links",
				"caption": "Caption",
				"delay": "Delay (s)",
				"placeholderImage": "Placeholder image",
				"button": "Button",
				"standard": "Standard",
				"columns": "Columns",
				"quiz": "Quiz",
				"overlay": "Overlay text",
				"progressMessage": "Progress message",
				"gridType": "Grid type",
				"confirmGridTypeChange": "Are you sure you want to change the grid type? This will alter all grid items.",
				"analyticsEvent": "Analytics Event"
			}
		},
		"objectPreviews": {
			"TextListItem": "Use this item for lead-in text, usually at the top of a page",
			"TitleListItem": "Use this item for a single line of text",
			"DescriptionListItem": {
				"title": "This item has a title and description",
				"description": "Use this item when you've got lots to say"
			},
			"StandardListItem": {
				"title": "Use this item most of the time",
				"description": "This item has a title, a description and an icon. You can leave bits blank — like the icon"
			},
			"ToggleableListItem": {
				"title": "Use this when you have something to hide",
				"description": "This text can be shown and hidden by the user. Good if you've got lots of detail — maybe an FAQ"
			},
			"OrderedListItem": {
				"title": "Ordered list",
				"description": "Use this when you want things in a numbered list"
			},
			"UnorderedListItem": {
				"title": "Unordered list",
				"description": "Use this when you want things in a list, but just with bullet points"
			},
			"CheckableListItem": "Use this for checklists",
			"ButtonListItem": "Use this if you want a button",
			"HeaderListItem": {
				"title": "Use this at the top of a page",
				"description": "It can have a title and a longer description"
			},
			"LogoListItem": "Use this when for something like a logo, where you want an image, caption and a link",
			"SpotlightListItem": {
				"first": "Rotating",
				"second": "Spotlight"
			},
			"ChunkyListItemView": {
				"title": "This is like the standard list item — with more padding",
				"description": "It has a title, a description and an icon. You can leave bits blank — like the icon"
			},
			"ListItemView": "This item just has a title",
			"LinkableImageItemContainer": {
				"first": "First",
				"second": "Second",
				"third": "Third"
			}
		}
	},
	"mediaLibrary": {
		"title": "Media Library",
		"refine": "Refine library",
		"filterByTags": "Filter by tags",
		"type": "Type of media",
		"image": "Image",
		"icon": "Icon",
		"video": "Video",
		"animation": "Animation",
		"section": "Section",
		"society": "Society",
		"general": "General",
		"additionalTags": "Additional tags",
		"commas": "Separate with commas",
		"clearFilters": "Clear filters",
		"editMedia": "Edit media",
		"delete": "Delete",
		"dragToReorder": "Drag to reorder",
		"confirmTagRemoval": "Are you sure you want to remove this tag?",
		"chooseExisting": "Choose existing",
		"loadMore": "Load more",
		"upload": {
			"title": "Upload media",
			"instructions": "Click here or drag a file to begin",
			"cropType": "Crop type",
			"noCrop": "No crop",
			"square": "Square",
			"tags": "Tags",
			"newTag": "New tag",
			"agreementText": "All content uploaded to this system is subject to the terms of the",
			"filesizeError": "Upload failed. The file is too large.\n\nThe maximum allowed filesize is",
			"formatError": "Upload failed. Invalid file format.\n\nAllowed image formats: .jpeg, .png, .gif\nAllowed video formats: .mp4, .m4v",
			"genericError": "File upload failed. Error code:",
			"selectType": "Please select the type of image you are uploading",
			"spotlightDesc": "Use this crop for spotlights, edge to edge, slideshow and video images. Images should be <b>640(w)</b> by <b>320(h)</b> pixels in size.",
			"gdpcSpotlightDesc": "Use this crop for headers in First Aid apps, spotlights, edge to edge, slideshow and video images. Images should be <b>640(w)</b> by <b>320(h)</b> pixels in size.",
			"imageDesc": "Images of <b>640(w)</b> by <b>320(h)</b> pixels are recommended for edge to edge images, slideshow images and video images. Quiz images should be <b>640(w)</b> by <b>640(h)</b> pixels.",
			"squareDesc": "Use this crop for icons and quiz images. Quiz images should be <b>640(w)</b> by <b>640(h)</b> pixels.",
			"headerDesc": "Use this crop for large header images which appear at the top of a page. Images should be <b>640(w)</b> by <b>568(h)</b> pixels in size.",
			"gdpcHeaderDesc": "Use this crop for large header images in Hazards apps. Images should be <b>640(w)</b> by <b>568(h)</b> pixels in size.",
			"invalidTag": "Invalid tags. Tags may not contain the wildcard (*) symbol.",
			"errors": {
				"tooManyFiles": "Please select a single file"
			},
			"requiresTransparency": "Requires transparent background"
		}
	},
	"viewPicker": {
		"title": "Objects",
		"filter": "Filter objects",
		"basicObjects": "Basic objects",
		"multiLineObjects": "Multi-line objects",
		"mediaObjects": "Media objects",
		"interactiveObjects": "Interactive objects",
		"text": "Text",
		"subText": "Sub-text",
		"leadInText": "Lead-in text",
		"hiddenText": "Hidden text",
		"spotlight": "Spotlight",
		"TextListItem": "Edge to edge text between groups",
		"TitleListItem": "A single line of text",
		"StandardListItem": "A title, link and icon",
		"DescriptionListItem": "A title and multi-line text description",
		"ToggleableListItem": "A clickable title which shows longer text content",
		"OrderedListItem": "A title and multi-line text content, with an annotation or step number",
		"UnorderedListItem": "A bulleted list item with a title and description",
		"CheckableListItem": "A title and a checkbox. State can persist between app users",
		"ButtonListItem": "A title and a button",
		"ImageListItem": "A single image, edge to edge",
		"HeaderListItem": "A large image with an overlaid title and description",
		"VideoListItem": "A video object supporting multiple sources",
		"AnimatedImageListItem": "A series of images played as a slideshow, giving animation",
		"SpotlightImageListItem": "One or more spotlight images, containing an image, a caption and a link",
		"LogoListItem": "Used to display company branding",
		"SliderQuizItem": "Image slider selection question",
		"TextQuizItem": "Text selection question",
		"ImageQuizItem": "Image selection question",
		"AreaQuizItem": "Area selection question",
		"CollectionListItem": "A 'Collect them All' view for apps, badges and links",
		"dragAndDrop": "Drag and drop objects to add them to the page"
	},
	"publish": {
		"title": "Publish",
		"auditTrail": "Audit trail",
		"noChanges": "No changes",
		"lastModified": "Last modified",
		"publishToDev": "Publish to dev",
		"publishToLive": "Publish to live",
		"objectType": "Object type",
		"detail": "Detail",
		"details": "Details",
		"time": "Time",
		"startTime": "Start time",
		"endTime": "End time",
		"user": "User",
		"selectAll": "Select all",
		"error": "Please select one or more pages to publish.",
		"warning1": "You have selected the below pages to be published to the iOS and Android applications through a delta update.",
		"warning2": "Please thoroughly review all changes before continuing and confirm your selection at the bottom of the page.",
		"comment": "Comment",
		"publishConfirm": "I confirm I have reviewed all selected changes and have permission to push them live",
		"publishLandmark": "Landmark Publish?",
		"toApps": "Publish to apps",
		"pleaseConfirm": "Please check the checkbox to confirm you have reviewed all changes.",
		"publishing": "Publishing...",
		"progress1": "Your selected changes are now being published.",
		"progress2": "This may take a while. Do not navigate away from this page.",
		"progress3": "Looks like this is taking a while. You can come back later.",
		"success": "Publish successful.",
		"fail": "Publish failed.",
		"history": "History",
		"publishType": "Publish type",
		"test": "Test",
		"live": "Live"
	},
	"publishHistory": {
		"title": "Publish history",
		"previousPublishes": "Previous publishes"
	},
	"agreement": {
		"title": "Universal App Program Agreement",
		"hazardsTitle": "Universal Hazard Application Program Agreement",
		"details": "Agreement details",
		"status": "Status",
		"sign": "Sign",
		"signed": "Signed",
		"notSigned": "Not signed",
		"daysRemaining": "__count__ day remaining",
		"daysRemaining_plural": "__count__ days remaining",
		"signedBy": "Signed by",
		"date": "Date",
		"nationalSociety": "National society",
		"viewThe": "View the",
		"signAgreement": "Sign agreement",
		"fullName": "Full name",
		"email": "Email address",
		"iAgree": "I agree to the terms given in the",
		"checkToAgree": "Please check the box to confirm you have read and agree to the terms."
	},
	"users": {
		"title": "Users",
		"list": "List of users",
		"name": "Name",
		"firstName": "First name",
		"lastName": "Last name",
		"organisation": "Organization",
		"role": "Role",
		"roles": "Roles",
		"apps": "Apps",
		"lastActive": "Last active",
		"success": "Success! The new user will receive an email with instructions to activate their account.",
		"complete": "User created successfully",
		"error": "Couldn't create user. Check the username and email haven't already been registered.",
		"createUser": "Create User",
		"usernameHelp": "Usernames may only contain letters, numbers, underscores, dashes and dots.",
		"removeUserConfirm": "Are you sure you want to remove this user?",
		"failedToRemove": "Failed to remove user"
	},
	"userApps": {
		"title": "User apps"
	},
	"roles": {
		"title": "User roles",
		"list": "List of roles",
		"permissions": "Permissions"
	},
	"permissions": {
		"title": "Roles permissions",
		"edit": "Edit permissions",
		"none": "None",
		"read": "Read",
		"write": "Write",
		"delete": "Write/Delete"
	},
	"apps": {
		"title": "Assign apps",
		"subtitle": "Assign apps to user",
		"app": "App",
		"assignNew": "Assign new app",
		"assign": "Assign"
	},
	"stories": {
		"title": "Stories",
		"list": "Stories list",
		"created": "Created",
		"subtitle": "Subtitle",
		"content": "Content",
		"editStory": "Edit story"
	},
	"badges": {
		"title": "Badges",
		"list": "List of badges",
		"editBadge": "Edit badge"
	},
	"crossBorders": {
		"title": "Cross Borders",
		"list": "List of destinations",
		"links": "Links",
		"link": {
			"title": "Title",
			"destination": "Destination",
			"placeholder": "Destination title"
		},
		"editItem": "Edit Destination Country",
		"contacts": "Contacts",
		"translations": "Phrase Translations",
		"contact":{
			"editItem": "Edit Contact",
			"title": "Title",
			"number": "Phone Number"
		},
		"phrases": "Phrases",
		"phrase": {
			"editItem": "Edit Phrase",
			"phrase": "Phrase",
			"translation": "Translation"
		},
		"country": {
			"code": "Country Code",
			"name": "Country Name",
			"description": "The destination country"
		}
	},
	"levels": {
		"title": "Levels",
		"list": "List of levels",
		"editLevel": "Edit level",
		"details": "Level details",
		"children": "Level children"
	},
	"push": {
		"showAllApps": "Show all apps",
		"existing": "Existing push notifications",
		"category": "Category",
		"notes": "Notes",
		"type": "Type",
		"numberSent": "Total",
        "numberCompleted": "Completed",
		"createdBy": "Created by",
		"editPush": "Edit push notification",
		"pushDetails": "Notification details",
		"assignments": "Assignments",
		"noPageSpecified": "No page specified",
		"approve": "Approve",
		"reject": "Reject",
		"priority": "Priority",
		"message": "Message",
		"messageDescription": "Push notification message",
		"payload": "Payload",
		"saveAndSend": "Save and send",
		"noNotifications": "No notifications",
		"sending": "Sending",
		"sent": "Push sent",
		"categories": {
			"standard": "Standard",
			"future": "Future",
			"region": "Region",
            "custom": "Custom"
		},
		"types": {
			"default": "Default"
		},
		"priorities": {
			"low": "Low",
			"high": "High"
		},
		"custom": {
			"area": "Target Area",
			"areaHelp": "Click the map to define a target area for this notification"
		},
		"error": {
			"length": "Push notification message too long",
			"messageEmpty": "Please enter a message",
			"missingRegion": "Please select a region"
		},
		"what": "What do you want to send?",
		"when": "When do you want to send it?",
		"addToQueue": "Add to queue"
	},
	"bugs": {
		"title": "Bugs",
		"report": "Report",
		"listOpen": "List of open bug reports",
		"listPending": "List of pending bug reports",
		"listClosed": "List of closed bug reports",
		"version": "Version",
		"build": "Build",
		"device": "Device/OS version",
		"screenshots": "Screenshots",
		"reportBug": "Report a bug",
		"platformDetails": "Platform details",
		"issueDetails": "Issue details",
		"steps": "Steps to reproduce",
		"expected": "Expected behaviour",
		"actually": "Actual behaviour",
		"noReports": "No reports",
		"bugReport": "Bug report",
		"comments": "Comments",
		"noComments": "No comments",
		"addComment": "Add a comment",
		"loadingDetails": "Loading bug details...",
		"closeIssue": "Close issue",
		"reopenIssue": "Reopen issue",
		"loadingFiles": "Loading files...",
		"noFiles": "No files",
		"status": {
			"title": "Status",
			"deleted": "Deleted",
			"open": "Open",
			"fixed": "Fixed",
			"wontFix": "Won't Fix",
			"postponed": "Postponed",
			"duplicate": "Duplicate",
			"notReproducible": "Not Reproducible",
			"byDesign": "By Design",
			"closed": "Closed"
		}
	},
	"gdpcConsole": {
		"nationalSocieties": {
			"title": "Overview",
			"countries": "Countries",
			"stage": "Stage",
			"averageRating": "Average rating",
			"downloads": "Downloads",
			"totalDownloads": "Total Downloads",
			"contact": "Contact",
			"pool": "Pool",
			"goLive": "Go live",
			"locale": "Locale",
			"details": "Society Details",
			"edit": "Edit Society"
		},
		"devPools": {
			"title": "Dev Pool status",
			"completed": "Completed",
			"noApps": "No apps in this dev pool",
			"editPool": "Edit dev pool",
			"stages": "Stages",
			"language": "Language",
			"expected": "Expected",
			"requiredBy": "Required by",
			"responsibility": "Responsibility",
			"estimatedDate": "Current estimated app date"
		}
	},
	"hazards": {
		"title": "Hazards",
		"disasters": {
			"title": "Disasters",
			"rootPage": "Root page",
			"alertSettings": "Alert settings",
			"configure": "Configure",
			"enable": "Enable",
			"disable": "Disable",
			"saveSuccess": "App configuration saved.",
			"saveError": "Failed to save app configuration.",
			"pleaseSpecifyPages": "Please specify a page for each selected disaster type.",
			"whatNow": "'What now' page",
			"categories": {
				"tsunami": "Tsunami",
				"hurricane": "Hurricane",
				"tornado": "Tornado",
				"flood": "Flood",
				"wildfire": "Wildfire",
				"wind": "Wind",
				"storm": "Storm",
				"earthquake": "Earthquake",
				"heatwave": "Heatwave",
				"winter": "Winter",
				"volcano": "Volcano",
				"nuclear": "Nuclear",
				"coastal": "Coastal"
			},
			"tutorial": {
				"heading": "What do the different states mean?",
				"body": "- Enabled means the page will appear in the content editor for you to start working on it.<br>- Dev means the page will appear in development builds for this app.<br>- Live means the page will appear in app store builds for this app."
			}
		},
		"alerts": {
			"title": "Alerts",
			"alert": "Alert",
			"expired": "Expired",
			"alertActive": "Alert active",
			"pin": "Pin",
			"pinned": "Pinned",
			"effective": "Effective",
			"issuedBy": "issued by",
			"noAlerts": "No alerts",
			"show": "Show",
			"inTheLast": "In the last",
			"allAlerts": "All alerts",
			"createNewAlert": "Create new alert",
			"testAlert": "Create test alert",
			"testAlertDesc": "This test alert will be sent to all users monitoring a location in Antarctica.",
			"specifySinglePolygon": "Multiple polygons are not supported for alerts. Please draw a single polygon.",
			"times": {
				"allTime": "All time",
				"hour": "Hour",
				"day": "Day",
				"week": "Week",
				"month": "Month"
			},
			"custom": {
				"types": {
					"announcement": "Announcement",
					"pin": "Pinned page",
					"announcementDesc": "Announcements are used to create alerts for events that aren't provided from other alert providers.",
					"pinDesc": "Pinned pages are used to feature important pages which are time and location sensitive."
				},
				"errors": {
					"pleaseSelectRegion": "Please select a region for this alert.",
					"pleaseProvideMessage": "Please provide a message for this announcement.",
					"pleaseSelectPage": "Please select a page to be pinned.",
					"pleaseProvideEvent": "Please provide an event for this announcement.",
					"pleaseProvideHeadline": "Please provide a headline for this announcement.",
					"pleaseProvideAreaDescription": "Please provide an area description for this announcement.",
					"effectiveWrong": "Effective date cannot be after expiry date"
				},
				"announcementPage": "The page is displayed in the \"what now\" section.",
				"pinPage": "The page to be featured.",
				"message": "The message sent with this alert.",
				"area": "Alert area",
				"areaHelp": "The alert will be sent to all users monitoring a location within the selected area.",
				"expiry": "Expiry date",
				"expiryHelp": "The date this alert will be removed.",
				"effective": "Effective date",
				"effectiveHelp": "The date this alert will be effective from.",
				"onset": "Onset date",
				"onsetHelp": "The date the hazard will happen.",
				"severity": "Severity",
				"severityHelp": "How important is this alert?",
				"alertQueued": "Alert queued to be sent.",
				"drawRegion": "Draw around the areas this alert should be sent",
				"notify": "Notify",
				"sendNotification": "Send a push notification to these users.",
				"pushes": "Pushes",
				"cap_create": "CAP",
				"cap_send": "Generate a CAP alert",
				"languageHelp": "Language the alert is written in.",
				"polygon": "Add custom polygon data here.",
				"showPolyData": "Show polygon data",
				"updateMap":"Update map"
			}
		},
		"generalNotifications": {
			"title": "General Notifications",
			"createNotification": "Create Notification",
			"event": "Event",
			"headline": "Headline",
			"area_description": "Area description",
			"type": "Type",
			"status": "Status",
			"scope": "Scope",
			"category": "Category",
			"urgency": "Urgency",
			"severity": "Severity",
			"certainty": "Certainty",
			"customMapData":{
				"placeholder":"Click on the map above or add a minimum of three coordinates here to create a polygon. i.e. \n[longitude,latitude],\n[longitude,latitude],\n[longitude,latitude]",
				"moreCoords":"Please provide more than three coordinates",
				"firstAndLast":"First and last coordinates should be the same",
				"notFound":"No Polygon data found, please add a minimum of three coordinates. i.e. [longitude,latitude],[longitude,latitude],[longitude,latitude]",
				"fisrtFoundNotLast":"First coordinate found within coordinates, only first and last coordinates should be the same"
			},
			"dropdown": {
				"alert": "Alert",
				"update": "Update",
				"cancel": "Cancel",
				"error": "Error",
				"actual": "Actual",
				"system": "System",
				"test": "Test",
				"draft": "Draft",
				"public": "Public",
				"restricted": "Restricted",
				"private": "Private",
				"safety": "Safety",
				"security": "Security",
				"rescue": "Rescue",
				"fire": "Fire",
				"health": "Health",
				"transport": "Transport",
				"other": "Other",
				"immediate": "Immediate",
				"expected": "Expected",
				"future": "Future",
				"past": "Past",
				"unknown": "Unknown",
				"extreme": "Extreme",
				"severe": "Severe",
				"moderate": "Moderate",
				"minor": "Minor",
				"observed": "Observed",
				"likely": "Likely",
				"possible": "Possible",
				"unlikely": "Unlikely"
			}
		},
		"alertZones": {
			"title": "Alert Zones",
			"addAlertZone": "Add Alert Zone",
			"editAlertZone": "Edit Alert Zone",
			"name": "Name",
			"type": "Type",
			"blacklist": "Blacklist",
			"whitelist": "Whitelist",
			"events": "Events",
			"area": "Area",
			"areaSelectModalTitle": "Draw around the areas this zone applies to"
		},
        "feeds": {
            "title": "Feeds",
            "feed": "Feed",
            "code": "Code",
            "scope": "Scope",
            "events": "Events",
						"languages": "Available languages",
            "type": "Type",
            "configure": "Configure",
            "enable": "Enable",
            "disable": "Disable",
            "tutorial": {
                "heading": "Using Feeds",
                "body": "Some alert types may come from multiple alert sources. This section allows you to enable or disable specific feeds and event types from individual feeds for this app.",
                "steps": "<ol><li>Ensure the chosen Disaster Types and categories are enabled for this app. This will determine which feeds and events may be enabled below.</li><li>Select the feeds and event types you wish to contribute to alerts for this app.</li></ol>"
            },
            "delete": {
                "confirm": "Are you sure?",
                "events": "All enabled events will be disabled for this feed.",
                "yes": "Yes, delete it"
            }
        }
	},
	"appCreate": {
		"title": "Create new app",
		"details": "App details",
		"copyFrom": "Copy content from",
		"emptyApp": "Empty app",
		"createNewOrganization": "Create new organization"
	},
	"organization": {
		"title": "Organization",
		"details": "Organization details",
		"noLanguages": "No languages assigned",
		"confirmLanguageAssignment": "Are you sure you want to assign this language? Language assignments cannot be removed."
	},
	"mailingList": {
		"title": "Mailing lists",
		"list": "Available lists",
		"export": "Export",
		"dateFrom": "From",
		"dateTo": "To"
	},
	"moderation": {
		"title": "Moderation",
		"image": {
			"title": "Image moderation",
			"location": "Location"
		}
	},
	"manageApps": {
		"title": "Manage apps",
		"yourNextApp": "Your next app",
		"easilySetUpYourNextApp": "Easily set up your next app"
	},
	"dashboard": {
		"title": "Dashboard"
	},
	"manage": {
		"title": "Manage"
	},
	"posts": {
		"title": "Posts"
	},
	"achievements": {
		"title": "Achievements",
		"edit": "Edit Achievement",
		"name": "Name",
		"image": "Image",
		"completion": "Completion",
		"badge": "Badge"
	},
	"triggers": {
		"plural": "Triggers",
		"subclasses": {
			"RaisedTrigger": {
				"singular": "Amount raised",
				"amount": "Amount"
			}
		}
	},
	"mind-moderation": {
		"title": "Emoodji Moderation",
		"empty": "No Emoodjis to moderate",
		"emoodji": {
			"singular": "Emoodji"
		}
	},
	"whatnow": {
		"whatnow": "What Now?",
		"eventType": "Hazard",
		"title": "Title",
		"description": "Description",
		"before": "Before",
		"during": "During",
		"after": "After",
		"content-not-set": "Content not set",
		"empty": "There are no what now entries, click add to create one",
		"selectSoc": "Select a society",
		"attribution": {
			"attribution": "Attribution",
			"url": "Attribution URL",
			"urlHelp":"URL that links to more information about the Attributor.",
			"socName": "Attribution Society Name",
			"socNameHelp":"Name of the Society that is to be attributed with the whatnows.",
			"image": "Attribution Image",
			"imageHelp":"Image/Icon upload.",
			"message": "Attribution Message",
			"messageHelp":"Message that goes along with the attribution."
		},
		"edit": {
			"editWhatnow": "Edit What Now",
			"event": "Hazard",
			"eventhelp": "The type of event for the What now.",
			"moreinfo": "Information URL",
			"moreinfohelp": "URL for more information about the What now.",
			"translations": "Translations",
			"new": "Add a new language",
			"selectLang": "Select a language",
			"title": "Title",
			"titleHelp": "Title for the What now.",
			"description": "Description",
			"descriptionHelp": "A description for the What now.",
			"midterm":"Mid-term forecast",
			"midtermHelp":"Reduce risks (Longer term actions)",
			"forecast":"Forecast",
			"forecastHelp":"Assess and plan (Shorter term actions)",
			"warning":"Warning",
			"warningHelp":"Prepare to respond",
			"watch":"Watch",
			"watchHelp":"Prepare to respond",
			"immediate":"Immediate",
			"immediateHelp":"Respond",
			"recover":"Recover",
			"recoverHelp":"After",
			"before": "Before",
			"beforeHelp": "How to prepare before the event happens.",
			"during": "During",
			"duringHelp": "What to do whilst the event is happening.",
			"after": "After",
			"afterHelp": "What to do after the event has happened.",
			"languages": "Languages",
			"missing": "Missing content",
			"empty": "Please add a language to create a What Now",
			"addALang":"Add a language to get started",
			"moreinfoHelp": {
				"placeholder": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "
			}
		}
	},
	"arpro": {
		"arpro": "arpro",
		"noitems": "There are no items to display, click add above.",
		"codeGroup": {
			"codeGroup": "Code Groups",
			"name": "Name",
			"description": "Description",
			"codes": "Codes",
			"editItem": "Edit Code Group",
			"addCodes": "Enter a code here"
		},
		"colours": {
			"colours": "Colours",
			"name": "Name",
			"description": "Description"
		},
		"search": {
			"search": "Product Search",
			"burningRate": "Burning Rate",
			"compressionStrength25": "Compression Strength 25",
			"compressionStrength50": "Compression Strength 50",
			"compressionStrength75": "Compression Strength 75",
			"equivalentYoungsModulus": "Equivalent Youngs Modulus",
			"mouldedDensity": "Moulded Density",
			"resilience75DynamicCompression": "Resilience 75 Dynamic Compression",
			"tensileElongation": "Tensile Elongation",
			"tensileStrength": "Tensile Strength"
		},
		"product": {
			"editItem": "Edit Product",
			"product": "Products",
			"grade": "Grade",
			"name": "Name",
			"colour": "Colour",
			"access": "Access Codes",
			"rawData": "Raw JSON data",
			"rawDataError": "Raw JSON data - Invalid JSON data",
			"noneLinked": "No Access codes",
			"linked": "Has Access codes",
			"specifications": "Specifications",
			"notes": "Notes",
			"moulded": "Moulded Density Range (g/l)",
			"physicalProps": "Physical Properties",
			"properties": {
				"weight": "Weight (mg)",
				"size": "Size (mm)",
				"bulk": "Bulk density (g/l)",
				"moulded": "Moulded density (g/l)",
				"packaging": "Packaging",
				"food": "Food approved",
				"bag": "Bag",
				"bulkLabel": "Bulk"
			}
		}
	},
	"r4b": {
		"title":"ATLAS",
		"admin":"Admin Dashboard",
		"languages": "Languages",
		"languageVars": "Language Variations",
		"contentBlocks": "Content Blocks",
		"sections": "SECTIONS",
		"minutes": "MINS",
		"people": "PEOPLE",
		"person": "PERSON",
		"questions": "Questions",
		"empty": "There are no modules or assessments here, add one to this level to get started.",
		"inputBlocks": "USER INPUT BLOCKS",
		"mediaBlocks": "MEDIA BLOCKS",
		"level": {
			"title": "Level",
			"levels": "Levels",
			"colour": "Colour",
			"selectBadge": "Please select a badge",
			"red": "Red",
			"blue": "Blue",
			"green": "Green",
			"alpha": "Alpha"
		},
		"modules": {
			"module": "Module",
			"version": "Version",
			"saved": "All changes are saved",
			"unsaved": "There are unsaved changes",
			"level": "Level",
			"levels": "Levels",
			"title": "Modules",
			"visibility":"Visibility",
			"mins": "mins",
			"people":  "people",
			"person": "person",
			"blockContent": {
				"section": "Section",
				"logic": {
					"title": "Section Visibility Logic"
				},
				"identifier": "Identifier",
				"addIdentifier": "Add an identifier",
				"enterTitle": "Enter a title",
				"break": "Page break",
				"inputLabel": "Text Input Label",
				"enterText": "Enter text here",
				"clickToEdit": "Click to edit",
				"videoLink": "Link to external video",
				"addSection": "Drag an item here to create a new section",
				"slider": {
					"interval": "Interval",
					"startLabel": "Start Label",
					"endLabel": "End Label"
				},
				"question": {
					"title": "Question and Answer",
					"textHelp": "Enter a question",
					"answerHelp": "Enter an answer",
					"responseHelp": "Enter a response",
					"selectionHelp": "Select a section to proceed to",
					"addAnotherResponse": "Add another response",
					"addResponse": "Add a response"
				},
				"quote": {
					"title":"Quote",
					"citation": "Enter citation here"
				},
				"checkbox": {
					"title": "Checkboxes"
				},
				"textInput": {
					"title": "Text Input",
					"help": "Enter input label here",
          "multi": "Multi-line text?"
				},
				"link": {
					"title": "Links"
				},
				"help": {
					"title": "Help Object"
				},
				"task": {
					"title": "Task Object",
					"help": "Enter a task objective"
				},
				"text": {
					"title": "Text Object"
				},
				"radio": {
					"title": "Radio Buttons"
				}
			},
			"section": {
				"title": "SECTION"
			},
			"blockTypes": {
				"userInput": "USER INPUT BLOCKS",
				"radio": "RADIO",
				"check": "CHECK",
				"slider": "SLIDER",
				"textInput": "TEXT ENTRY",
				"media": "MEDIA BLOCKS",
				"text": "TEXT",
				"image": "IMAGE",
				"video": "VIDEO",
				"link": "LINK",
				"task": "TASK",
				"help": "HELP",
				"quote": "QUOTE",
				"qna": "Q&A"
			}
		},
		"assessments": {
			"pre": "Pre Assessment",
			"assessment": "Assessment"
		}
	},
	"rspb": {
		"rspb": "RSPB",
		"noitems": "There are no items to display, click add above.",
		"factFile": {
			"factFile": "Fact Files",
			"name": "Name",
			"description": "Description",
			"editItem": "Edit Fact File",
			"features": "Features",
			"addFeatures": "Enter a feature here",
			"activities": "Activities",
			"addActivities": "Enter an activity here",
			"images": "Images",
			"addImages": "Select Image",
			"scientificName": "Scientific Name",
			"commonName": "Common Name",
			"seen": "Seen",
			"tags": "Tags",
			"addTags": "Enter a tag here",
			"funFacts": "Fun Facts",
			"addFact": "Enter a fact here",
			"show": "Show",
			"start": "Start"
		},
		"planItem": {
			"planItems": "Plan Items",
			"title": "Title",
			"type": "Type",
			"description": "Description",
			"image": "Image",
			"tags": "Tags",
			"duration": "Duration (seconds)",
			"solo": "For the family?",
			"pageHelp": "This is the page your plan item will link to",
			"dates": "Dates",
			"to": "End date",
			"from": "Available from",
			"visible": "Visible from",
			"weighting": "Plan item weighting",
			"weightingHelp": "Priority of where the item will appear in the app",
			"mostImportant": "Most important",
			"leastImportant": "Least important",
			"months": "Months",
			"region": "Region",
			"identifier": "Identifier",
			"regionHelp": "Click to select the region for this plan item",
			"indefinite": "Indefinite?",
			"activityCode": "Activity code",
			"source": "Source",
			"difficulty": {
				"difficulty": "Select the difficulty",
				"easy": "Easy",
				"medium": "Medium",
				"hard": "Hard"
			},
			"garden": {
				"selectType": "Select the type/s of garden",
				"small": "Small",
				"medium": "Medium",
				"large": "Large",
				"public": "Public",
				"balconyroof": "Balcony / Roof"
			}
		}
	}
}
