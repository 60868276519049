var PaginatedListView       = require('list-view/paginated-list-view'),
	Maps                    = require('lib/maps'),
	alertInfoWindowTemplate = require('./alert-info-window-template'),
	DisasterList            = require('./disaster-list'),
	HazardsSectionView      = require('./hazards-section-view')

var categoryColours = [
	'lime',    // General
	'blue',    // Tsunami
	'red',     // Hurricane
	'orange',  // Tornado
	'green',   // Flood
	'purple',  // Wildfire
	'blue',    // Wind
	'#0087FF', // Storm
	'black',   // Earthquake
	'red',     // Heatwave
	'yellow',  // Winter
	'magenta', // Volcano
	'lime'     // Nuclear
]

var AlertListView = PaginatedListView.extend({
	template: require('./alert-list-view-template'),
	list: require('./alert-list'),
	listItem: require('./alert-list-item-view'),

	initialize: function() {
		PaginatedListView.prototype.initialize.apply(this, arguments)

		// Fetch disaster list to get event IDs.
		this.disasterList = new DisasterList()
		this.disasterList.once('sync', this.generateEventMap, this)
		this.disasterList.fetch()

		// Create empty event collection to pass to each list item.
		// Hacky.
		this.eventList = new Backbone.Collection()
		this.listItem.prototype.disasterList = this.disasterList
		this.listItem.prototype.eventList = this.eventList
	},

	generateEventMap: function() {
		var self = this

		this.disasterList.forEach(function(disaster) {
			disaster.get('events').forEach(function(event) {
				self.eventList.add(event)
			})
		})

		// Trigger reset to indicate list population - re-render list items.
		this.eventList.trigger('reset')

		// Populate filter selects.
		this.disasterList.forEach(function(category) {
			var categoryName = Handlebars.helpers.getBrowserLocaleText(category.get('name'))

			this.$('.event-filter').append('<option value="' + category.id + '">' + categoryName + '</option>')
		}, this)
	},

	afterReady: function() {
		// Set timestamp in fetch data to restrict pagination.
		var first = this.collection.first()
		var firstDate = new Date(first.attributes.timestamps.effective)

		// Compare the first in the collection ot the first non-pinned.
		for (var i = 0; i < this.collection.length; i++) {
			var item = this.collection.at(i)

			if (!item.get('options').pinned) {
				var date = new Date(item.attributes.timestamps.effective)

				if (date > firstDate) {
					first = item
				}

				break
			}
		}

		this.fetchData.timestamp = first.attributes.timestamps.effective
	}
})

module.exports = HazardsSectionView.extend({
	template: require('./alerts-view-template'),

	events: {
		'click .map-overlay': 'unlockMap',
		'change .event-filter': 'eventFilterChange',
		'change .date-filter': 'dateFilterChange',
		'click .info-window-pin-button': 'infoWindowPinButtonClick'
	},

	initialize: function(options) {
		this.app = App.appList.get(options.appId)

		this.views = {
			list: new AlertListView({app: this.app})
		}

		Maps.init(this.mapLoaded.bind(this))
	},

	getPageTitle: function() {
		return $.t('hazards.alerts.title')
	},

	getRenderData: function() {
		return {appId: this.app.id}
	},

	afterRender: function() {
		if (Maps.loaded) {
			setTimeout(this.initGoogleMap.bind(this))
		} else {
			this.once('mapReady', this.initGoogleMap, this)
		}

		if (!App.acl.hasWritePermission('Hazards', 'Alerts')) {
			this.$('.alert-buttons').remove()
		}
		// Test alert function only available on ARC.
		var isARC = App.system.id === 9

		if (!isARC) {
			this.$('.test-alert-button').remove()
		}

		if (App.system.apiCode === 'GDPC') {
			this.$('.create-alert-button').remove()
		}
	},

	infoWindowPinButtonClick: function() {
		App.startLoad()

		var options = _.clone(this.infoWindowAlert.get('options'))
		options.pinned = !options.pinned

		this.infoWindowAlert.unset('region')
		this.infoWindowAlert.once('sync', this.infoWindowPinComplete, this)
		this.infoWindowAlert.save({options: options}, {globalAuth: true})
	},

	infoWindowPinComplete: function() {
		// Re-render InfoWindow.
		this.infoWindow.setContent(alertInfoWindowTemplate(this.infoWindowAlert.toJSON()))
		App.stopLoad()
	},

	mapLoaded: function() {
		this.trigger('mapReady')
	},

	initGoogleMap: function() {
		// Initialize Google map
		this.map = new google.maps.Map(this.$('.map')[0], Maps.getOptions())
		this.bounds = new google.maps.LatLngBounds()
		this.infoWindow = new google.maps.InfoWindow()

		// Store a pointer to the alert being shown in the InfoWindow
		this.infoWindowAlert = null

		// Bind add/remove events to draw on the map.
		this.listenTo(this.views.list.collection, 'add', this.addAlertToMap)
		this.listenTo(this.views.list.collection, 'remove', this.removeAlertFromMap)

		// Render all initial markers, if they loaded before Google Maps (and the add event handler didn't fire).
		if (this.views.list.collection.length) {
			this.views.list.collection.forEach(this.addAlertToMap, this)
		}
	},

	unlockMap: function() {
		this.$('.map-overlay').fadeOut(500)
		this.$('.map').css('height', '400px')

		setTimeout(function() {
			google.maps.event.trigger(this.map, 'resize')
			this.map.fitBounds(this.bounds)
		}.bind(this), 500)
	},

	addAlertToMap: function(alert) {
		var paths = [],
			region = alert.get('region') || alert.get('marker'),
			colour = categoryColours[alert.get('category')]

		if (region.type === 'MultiPolygon') {
			region.coordinates.forEach(function(polygon) {
				var latLngs = polygon.map(function(path) {
					return path.map(function(coord) {
						var latLng = new google.maps.LatLng(coord[1], coord[0])
						this.bounds.extend(latLng)
						return latLng
					}, this)
				}, this)

				paths = paths.concat(latLngs)
			}, this)

			// Construct the polygon.
			alert.marker = new google.maps.Polygon({
				paths: paths,
				strokeColor: colour,
				strokeOpacity: 0.8,
				strokeWeight: 1,
				fillColor: colour,
				fillOpacity: 0.35,
				map: this.map
			})
		} else if (region.type === 'Circle') {
			// Add a circle to the map.
			alert.marker = new google.maps.Circle({
				paths: paths,
				strokeColor: colour,
				strokeOpacity: 0.8,
				strokeWeight: 1,
				fillColor: colour,
				fillOpacity: 0.35,
				center: new google.maps.LatLng(region.coordinates[1], region.coordinates[0]),
				radius: region.radius,
				map: this.map
			})
			var circlelatLng = new google.maps.LatLng(region.coordinates[1], region.coordinates[0])
			this.bounds.extend(circlelatLng)
		} else if (region.type === 'Point') {
			alert.marker = new google.maps.Marker({
				position: new google.maps.LatLng(region.coordinates[1], region.coordinates[0]),
				map: this.map
			})
			var latLng = new google.maps.LatLng(region.coordinates[1], region.coordinates[0])
			this.bounds.extend(latLng)
		} else {
			return
		}

		this.map.fitBounds(this.bounds)

		google.maps.event.addListener(alert.marker, 'click', function(e) {
			this.polygonClick(e, alert)
		}.bind(this))
	},

	removeAlertFromMap: function(alert) {
		if (alert.marker) {
			alert.marker.setMap(null)

			// Clear paths on markers with them (polygons) for hopeful GC.
			if (alert.marker.setPath) {
				alert.marker.setPaths([])
			}
		}
	},

	polygonClick: function(e, alert) {
		var data = alert.toJSON()

		var categoryId = alert.get('category'),
			eventId = alert.get('event')

		var category = this.views.list.disasterList.get(categoryId),
			event = this.views.list.eventList.get(eventId)

		if (category) {
			data.categoryName = category.get('codeName')
		}

		if (event) {
			data.eventName = event.get('name')
		}

		this.infoWindow.setContent(alertInfoWindowTemplate(data))
		this.infoWindow.setPosition(e.latLng)
		this.infoWindow.open(this.map)

		// Store a reference to the current alert for pinning/re-rendering.
		this.infoWindowAlert = alert

		if (!App.acl.hasWritePermission('Hazards', 'Alerts')) {
			this.$('.info-window-pin-button').attr('disabled', true)
		}

		// Hide alert pinning on ARC.
		if (App.system.id === 9) {
			this.$('.info-window-pin-button').remove()
		}
	},

	eventFilterChange: function(e) {
		var list = this.views.list,
			val = $(e.currentTarget).val()

		if (val === '') {
			delete list.fetchData.category
		} else {
			list.fetchData.category = val
		}

		list.getPage(0)
		list.collection.once('sync', list.ready, list)
	},

	dateFilterChange: function(e) {
		var list = this.views.list,
			val = $(e.currentTarget).val()

		if (val === '') {
			delete list.fetchData.from
		} else {
			var date = new Date()

			if (val === 'hour') {
				date.setHours(date.getHours() - 1)
			} else if (val === 'day') {
				date.setDate(date.getDate() - 1)
			} else if (val === 'week') {
				date.setDate(date.getDate() - 7)
			} else if (val === 'month') {
				date.setMonth(date.getMonth() - 1)
			}

			list.fetchData.from = date.toISOString()
		}

		list.getPage(0)
		list.collection.once('sync', list.ready, list)
	}
})
