var ManageSectionView = require('manage/manage-section-view')
var ReportedPostList = require('./reported-post-list')
var ModerationItemView = require('./moderation-item-view')

module.exports = ManageSectionView.extend({
	template: require('./moderation-view-template'),

	initialize: function() {
		this.collection = new ReportedPostList()
		this.collection.on('add', this.addListItem, this)
		this.collection.once('sync', this.ready, this)

		// Changing property on model, not deleting it
		this.collection.on('change', function(item) {
			this.collection.remove(item)
			this.showEmptyMessageOrTable()
		}, this)

		this.collection.fetch()
	},

	addListItem: function(item) {
		var view = new ModerationItemView({model: item})
		this.$('.list-items').append(view.render().el)
	},

	ready: function() {
		this.showEmptyMessageOrTable()
		App.stopLoad()
	},

	showEmptyMessageOrTable: function() {
		this.$('.moderation-table').toggle(this.collection.length > 0)
		this.$('.empty-table-text').toggle(this.collection.length < 1)
	}
})
