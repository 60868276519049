var AuditList          = require('./audit-list'),
	AuditListItemChild = require('./audit-list-item-child-view'),
	UsersList          = require('users/user-list')

/**
 * Exports {@link AuditListItemView}.
 * @module
 */
module.exports = Backbone.View.extend(/** @lends AuditListItemView.prototype */{
	template: require('./audit-list-item-view-template'),
	tagName: 'tbody',
	className: 'audit-list-item',

	events: {
		'click .see-more-link': 'expand'
	},

	/**
	 * @constructs AuditListItemView
	 * @extends Backbone.View
	 * @override
	 */
	initialize: function(options) {
		this.listViews = []

		if (!options.pageList) {
			throw new Error('No page list specified')
		}

		if (options.area === undefined) {
			throw new Error('No publish area specified')
		}

		this.changedObject = options.changedObject
		this.labelProperty = options.labelProperty
		this.pluginClassName = options.pluginClassName

		/** @private PageList */
		this.pageList_ = options.pageList
		this.area = options.area

		// App-level audits are passed as a collection.
		if (this.model instanceof Array) {
			this.isPage = false
			this.collection = new Backbone.Collection(this.model)
			this.collection.fetched = true
			this.model = new Backbone.Model({
				timestamp: this.collection.last().get('timestamp'),
				pageName: this.collection.last().get('details').type,
				tag: '-',
				pageId: 0
			})
			this.expandContent = false
			this.fetchUsers()
		} else {
			this.isPage = true
			this.collection = new AuditList(null, {appId: options.appId})
			this.collection.url = App.apiRoot + 'objects/' + this.model.get('pageId') + '/audit'
			this.collection.on('add', this.addAuditItem, this)
		}
	},

	/** @override */
	getRenderData: function() {
		var data     = this.model.toJSON(),
			page     = this.pageList_.get(data.pageId),
			pageName = this.model.get('pageName'),
			tag
			// If this is related to a changed object then we can get the title from it's label property
		if (this.changedObject) {
			var textObject = this.changedObject.get(this.labelProperty)
			if (!textObject) {
				textObject = this.changedObject.get('class') + ' #' + this.changedObject.get('id')
			}
			if (typeof textObject === 'string' || typeof textObject === 'number') {
				pageName = textObject
			} else if (textObject.content) {
				pageName = App.l(textObject)
			} else {
				pageName = '-'
			}
		}
		if (page) {
			tag = page.get('tag')
		}

		data.pageName = pageName
		data.tag = tag
		data.pluginClassName = this.pluginClassName
		App.stopLoad()

		return data
	},

	/** @override */
	afterRender: function() {
		// Automatically render out app change children.
		if (this.collection.fetched) {
			this.collection.each(this.addAuditItem, this)
		}
	},

	expand: function() {
		if (this.collection.fetched) {
			this.expandContent = false
			Backbone.trigger('pauseAutoRefresh', {isPaused: false})
			this.$('.detail-row').toggle()
			this.$('.see-more-link i').toggleClass('icon-sort-down icon-sort-up')
		} else {
			// Show loading spinner
			App.startLoad()
			// this.collection.on('add', this.addAuditItem, this)
			this.expandContent = true
			Backbone.trigger('pauseAutoRefresh', {isPaused: true})
			this.collection.once('sync', this.fetchUsers, this)

			this.collection.fetch()
			this.collection.fetched = true
		}
	},

	fetchUsers: function() {
		var userIDs = this.collection.map(function(item) {
			return item.get('userId')
		})

		// Fetch list of users to reference
		this.userList = new UsersList({ids: _.uniq(userIDs)})
		this.userList.once('sync', this.matchUserIDs, this)
		this.userList.fetch()
	},

	matchUserIDs: function() {
		this.collection.each(function(item) {
			var userId = item.get('userId')
			var user = this.userList.get(userId)
			var name

			if (user) {
				name = user.get('firstName') + ' ' + user.get('lastName')
			} else {
				name = 'Deleted User: ' + userId
			}

			item.set('user', name)
		}, this)

		if (this.expandContent) {
			this.expand()
		}
		App.stopLoad()
	},

	addAuditItem: function(item) {
		// Only add the items for the correct publish area
		if ((this.area & item.get('in')) === 0) {
			return
		}
		var view = new AuditListItemChild({model: item})
		this.$('.child-list-items').append(view.render().el)
		this.listViews.push(view)
	}
})
