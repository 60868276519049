var CollectionItemView = require('./collection-item-view'),
	StormQL = require('models/stormql'),
	StormObject = require('editor/storm-object'),
	CanvasItemView = require('editor/canvas/canvas-item-view'),
	APICompat = require('lib/api-compat')

module.exports = CanvasItemView.extend({
	template: require('./collection-list-item-view-template'),
	tagName: 'li',
	className: 'CollectionListItem ListItem editable',

	events: {
		'click': 'click',
		'click .add-cell': 'createCell'
	},

	initialize: function() {
		this.listViews = []

		this.collection = this.model.get('cells')
		this.listenTo(this.collection, 'add', this.addCell, this)
		this.listenTo(this.collection, 'remove reset', this.afterRender, this)

		// Fetch the badge list for QuizCollectionItem objects
		this.badgeList = new StormQL(null, {app: Storm.app})
		this.badgeList.once('sync', this.render, this)
		this.badgeList.fetch({data: {class: 'Badge'}})
	},

	afterRender: function() {
		// Add editing state if the inspector's open
		if (this.model.editing) {
			this.$el.addClass('editing')
		}

		this.hideAddButtons()

		// Remove all previous cell views
		this.listViews.forEach(function(view) {
			view.destroy()
		})

		this.listViews = []

		// Render out all cells
		this.collection.each(this.addCell, this)
	},

	click: function(e) {
		Storm.view.views.canvas.setInspector(this.model)
		if (!$('.preview').hasClass('preview-mode')) {
			this.$el.addClass('editing')
		}

		// Don't bubble up to the page
		e.stopPropagation()
	},

	addCell: function(cell) {
		// Set badge image URL if this is a QuizCollectionItem
		var cellClass = cell.get('class')
		if (['QuizCollectionItem', 'QuizCollectionCell'].indexOf(cellClass) > -1) {
			this.listenTo(cell, 'change', this.updateBadgePreview, this)
			this.updateBadgePreview(cell)
		}

		// Add preview before the add button
		var view = this.addView(new CollectionItemView({model: cell}))
		this.$('.add-cell').first().before(view.render().el)
	},

	createCell: function(e) {
		// Get type from button clicked
		var type = $(e.currentTarget).data('type')

		// Create cell object
		var cell = StormObject.fromClassName(type, this.model.get('pageId'))

		// Show inspector for new cell
		Storm.view.views.canvas.setInspector(cell)

		this.collection.add(cell)
		this.model.save()

		// If this is the first cell being added, hide the other add buttons
		if (this.collection.length === 1) {
			this.hideAddButtons()
		}

		e.stopPropagation()
	},

	// Hide other add buttons if this already contains typed cells
	hideAddButtons: function() {
		var cell = this.collection.first()

		if (cell) {
			// Hide add buttons for classes which aren't that of this cell
			var type = APICompat.normaliseClassName(cell.get('class'))

			this.$('.add-cell').addClass('hidden')
			this.$('.add-cell[data-type=' + type + ']').removeClass('hidden')
		} else {
			// Show all buttons to start
			this.$('.add-cell').removeClass('hidden')
		}
	},

	updateBadgePreview: function(cell) {
		// Pass correct badge image URL to a QuizCollectionItem to update its preview
		var badgeId = cell.get('badgeId')
		var badge = this.badgeList.get(badgeId)

		if (badge) {
			var icon = badge.get('icon')
			var url = Handlebars.helpers.getPreviewUrl(icon)

			cell.set('badgeUrl', url)
		}
	}
})
