<div class="row">
	<div class="col-xs-12 actions-col">
		<a href="/apps/{{appId}}/tips/new" class="new-tip-button">
			<button class="button">{{t "common.add"}}</button>
		</a>
	</div>
</div>
<div class="row">
	<div class="col-xs-12">
		<table>
			<thead>
				<tr>
					<th>Type</th>
					<th>Content</th>
					<th>Moods</th>
					<th>Dates</th>
					<th>{{t "common.tools"}}</th>
				</tr>
			</thead>
			<tbody class="list-items tip-list-body">
				{{#each tips}}
					<tr>
						<td>{{objectType}}</td>
						<td>
							<div><b>Title:</b> {{title}}</div>
							<div><b>Subtitle:</b> {{subtitle}}</div>
							<div><b>Button title:</b> {{buttonTitle}}</div>
							{{#if answers}}
								<div><b>Answers:</b> {{answers.data.length}}</div>
							{{/if}}
						</td>
						<td><b>{{minMood}}</b> to <b>{{maxMood}}</b></td>
						<td>
							<div><b>Published:</b> {{formatDateTime publishDate}}</div>
							<div><b>Expires:</b> {{formatDateTime expiryDate}}</div>
						</td>
						<td class="tools">
							<a href="/apps/{{../appId}}/tips/{{id}}" class="details-button" data-id="{{id}}">
								<i class="icon-search" title="{{t "publish.details"}}"></i>
							</a>
							<a href="javascript:void(0)" data-id="{{id}}" class="delete-button">
								<i class="icon-trash" title="{{t "mediaLibrary.delete"}}"></i>
							</a>
						</td>
					</tr>
				{{/each}}
			</tbody>
		</table>
	</div>
</div>
