<div class="row">
	<div class="map-overlay"><span>{{t "common.clickToBrowseMap"}}</span></div>
	<div class="map google-maps"></div>

	<div class="col-xs-6 alerts-filters"></div>
	<div class="col-xs-6 actions-col">
		<a href="/apps/{{appId}}/general-notifications/new" class="create-alert-button"><button class="button green">{{t "hazards.generalNotifications.createNotification"}}</button></a>
		<a href="/apps/{{appId}}/general-notifications/test" class="test-alert-button"><button class="button">{{t "hazards.alerts.testAlert"}}</button></a>
	</div>
</div>
