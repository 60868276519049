var LinkSelector = require('./link-selector')

module.exports = Backbone.View.extend({
	template: require('./link-list-item-view-template'),

	events: {
		'click .remove': 'removeLink',
		'click .up': 'moveUp',
		'click .down': 'moveDown',

		'click .left, h5': 'toggleDetail'
	},

	initialize: function(options) {
		this.currentLang = options.language || Storm.view.language
		this.views = {
			linkSelector: new LinkSelector({link: this.model, language: this.currentLang})
		}
		this.parentLock = options.parentLock

		this.listenTo(this.views.linkSelector, 'change', function() {
			this.model.collection.parent.needsSaving = true
			this.model.collection.parent.needsStructureUpdate = true
		}, this)
	},

	afterRender: function() {
		this.$('.child-link-selector').html(this.views.linkSelector.render().el)
	},

	removeLink: function() {
		if (this.parentLock) {
			var headers = App.session.getHeadersObject()
			// Pull in lock data from the parent page, if there is one.
			headers.Lock = this.parentLock.get('token')

			$.ajax({
				url: App.apiRoot + 'objects/' + this.model.id,
				type: 'DELETE',
				headers: headers
			}).then(this.remove.bind(this))
		} else {
			this.destroyLink()
		}
	},

	destroyLink: function() {
		this.remove()
		this.model.destroy()
	},

	moveUp: function() {
		// Move child down up space in order
		var model = this.model
		var collection = this.model.collection

		var index = collection.indexOf(model)
		if (index > 0) {
			// silence this to stop excess event triggers
			collection.remove(model, {silent: true})
			collection.add(model, {
				at: index - 1,
				silent: true
			})

			collection.trigger('reset')
		}

		// Parent needs to be sent to server
		model.collection.parent.needsSaving = true
	},

	moveDown: function() {
		// Move child down one space in order
		var model = this.model
		var collection = this.model.collection

		var index = collection.indexOf(model)
		if (index < collection.models.length) {
			// silence this to stop excess event triggers
			collection.remove(model, {silent: true})
			collection.add(model, {
				at: index + 1,
				silent: true
			})

			collection.trigger('reset')
		}

		// Parent needs to be sent to server
		model.collection.parent.needsSaving = true
	},

	toggleDetail: function() {
		this.$('.child-link-selector').toggle()
	}
})
