var RATIO_ERROR = 0.01

/**
 * Exports {@link MediaCropView}.
 * @module
 */
module.exports = Backbone.View.extend(/** @lends MediaCropView.prototype */{
	/** @override */
	template: require('./media-crop-view-template'),

	/**
	 * Constructs a new {@link MediaCropView} with a file ({@link File}) and
	 * ratio (decimal, {@link number}).
	 * @constructs MediaCropView
	 * @classdesc Displays an image in an HTML5 canvas and allows it to be
	 *     cropped to a specified ratio.
	 * @override
	 */
	initialize: function(options) {
		if (!options.file) {
			throw new Error('No file specified')
		}

		if (options.ratio === undefined) {
			throw new Error('No ratio specified')
		}

		/** @private {File} */
		this.file_ = options.file
		/** @private {number} */
		this.ratio_ = options.ratio
		/** @private {number} */
		this.width_ = 0
		/** @private {number} */
		this.height_ = 0

		var URL = window.URL || window.webkitURL,
			img = new Image()

		img.onload = function() {
			this.width_ = img.width
			this.height_ = img.height

			this.render()

			cropper.showImage(img.src)

			// Only start cropping if the ratio isn't already correct
			var currentRatio = img.height / img.width

			if (this.ratio_ !== 0 && Math.abs(currentRatio - this.ratio_) >= RATIO_ERROR) {
				cropper.startCropping()
			}
		}.bind(this)

		img.src = URL.createObjectURL(this.file_)
	},

	/** @override */
	getRenderData: function() {
		var scale = Math.min(400 / this.width_, 1)

		return {
			width: Math.floor(this.width_ * scale),
			height: Math.floor(this.height_ * scale)
		}
	},

	/** @override */
	afterRender: function() {
		cropper.start(this.$('.crop-canvas')[0], this.ratio_)
	},

	/**
	 * Crops the image to the currently selected shape.
	 * @returns {Blob} Cropped image blob.
	 */
	crop: function() {
		return cropper.getCroppedImageBlob(this.file_.type)
	}
})
