<div class="row">
	<div class="col-xs-12 actions-col"></div>
	<div class="col-xs-12 col-sm-6 col-lg-4 col-lg-offset-2">
		<form class="form-horizontal">
			<fieldset>
				<legend>{{t "appSetup.appName"}}</legend>

				{{#key_value app.name "key" "val"}}
					<div class="form-group">
						<label class="control-label col-xs-1" for="name-{{key}}">{{key}}</label>
						<div class="col-xs-11">
							<input type="text" value="{{val}}" class="form-control app-name" id="name-{{key}}" data-code="{{key}}">
						</div>
					</div>
				{{/key_value}}

				<div class="row">
					<div class="col-xs-4 col-xs-offset-1">
						<button class="button green save-app form-control" type="submit" data-i18n="common.save"></button>
					</div>
				</div>

				<div class="form-group developer-mode">
					<div class="col-xs-11 col-xs-offset-1">
						<p class="form-control-static">{{appStormId}}</p>
					</div>
				</div>
			</fieldset>
		</form>
	</div>
	<div class="col-xs-12 col-sm-6 col-lg-4">
		<form class="form-horizontal">
			<fieldset>
				<legend>{{t "common.settings"}}</legend>

				<div class="form-group">
					<label class="control-label col-xs-6" for="start-page">{{t "appSetup.startPage"}}</label>
					<div class="col-xs-6">
						<select id="start-page" name="start-page" class="form-control"></select>
					</div>
				</div>

				<div class="form-group translation-download">
					<label class="control-label col-xs-6">Download translations</label>
					<div class="col-xs-6">
						<button type="button" class="download-json-button button"><i class="icon-download-alt"></i> JSON</button>
						<button type="button" class="download-csv-button button"><i class="icon-download-alt"></i> CSV</button>
					</div>
				</div>

				<div class="form-group translation-upload">
					<label class="control-label col-xs-6">Upload translations</label>
					<div class="col-xs-6">
						<button type="button" class="translation-upload-button button"><i class="icon-upload-alt"></i> CSV or JSON</button>
					</div>

					<input type="file" class="translation-upload-input" accept=".json,.csv">
				</div>

				<div class="row">
					<div class="col-xs-4 col-xs-offset-6">
						<button class="button green save-app form-control" type="submit" data-i18n="common.save"></button>
					</div>
				</div>
			</fieldset>
		</form>
	</div>
</div>
<div class="row">
	<div class="language-list-container"></div>
	<div class="col-sm-6 col-lg-4">
		<form class="form-horizontal">
			<fieldset>
				<legend data-i18n="appSetup.bundleIdentifiers"></legend>
				<div class="form-group">
					<label class="control-label col-xs-2">iOS</label>
					<div class="col-xs-10">
						<p class="form-control-static">{{bundleIdentifiers.ios}}</p>
					</div>
				</div>
				<div class="form-group">
					<label class="control-label col-xs-2">Android</label>
					<div class="col-xs-10">
						<p class="form-control-static">{{bundleIdentifiers.android}}</p>
					</div>
				</div>
			</fieldset>
		</form>
	</div>
</div>

