module.exports = Backbone.View.extend({
	template: require('./bug-file-view-template'),

	className: 'bug-file span3',

	initialize: function() {
		this.url = App.bugsRoot + 'files/' + this.model.get('filename')
	},

	getRenderData: function() {
		return {
			url: this.url,
			originalFilename: this.model.get('originalFilename')
		}
	},

	afterRender: function() {
		if (this.model.get('fileType').match(/image\//)) {
			this.$('a').append('<img src="' + this.url + '">')
		} else {
			this.$('a').append('<i class="icon-file"></i>')
		}
	}
})
