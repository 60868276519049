<div class="row">
	<div class="col-sm-6">
		<h3>{{t "push.pushDetails"}}</h3>
	</div>
	<div class="col-sm-6 actions-col">
		<a href="/apps/{{appId}}/push"><button class="button white">{{t "common.back"}}</button></a>
		<a href="/apps/{{appId}}/push/{{id}}"><button class="button" data-i18n="common.edit"></button></a>
	</div>
</div>
<div class="row">
	<div class="col-sm-6">
		<legend>{{t "push.payload"}}</legend>

		<dl class="dl-horizontal">
			<dt>{{t "agreement.nationalSociety"}}:</dt>
			<dd>{{getSocietyDescriptionById app.societyId}}</dd>

			<dt>{{t "apps.app"}}:</dt>
			<dd>{{getLocaleText app.name}}</dd>

			<dt>{{t "push.type"}}:</dt>
			<dd>{{payload.type}}</dd>

			<dt>{{t "push.message"}}:</dt>
			<dd>{{payload.message}}</dd>

			<dt>{{t "common.page"}}:</dt>
			<dd>
				{{#if page}}
					[{{page.tag}}] {{getLocaleText page.title}}
				{{else}}
					{{t "push.noPageSpecified"}}
				{{/if}}
			</dd>
			<dt>{{t "push.assignments"}}:</dt>
			<dd>{{#if counts.total}}{{counts.total}}{{else}}-{{/if}}</dd>
		</dl>
	</div>
	<div class="col-sm-6">
		<legend>{{t "common.settings"}}</legend>

		<dl class="dl-horizontal">
			<!-- <dt>{{t "push.priority"}}:</dt>
			<dd>{{getPushPriority priority}}</dd> -->

			<dt>{{t "push.category"}}:</dt>
			<dd>{{localisePushCategory category}}</dd>

			<dt>{{t "agreement.date"}}:</dt>
			<dd>{{formatDateUTC timestamp}}</dd>

			<dt>{{t "push.notes"}}:</dt>
			<dd>{{notes}}</dd>

			<dt>{{t "publish.user"}}:</dt>
			<dd>{{user.firstName}} {{user.lastName}}</dd>
		</dl>
	</div>
</div>
<div class="row map-preview-group">
	<div class="col-sm-6">
		<legend>{{t "push.custom.area"}}</legend>

		<div class="map-preview">
			<div class="push-preview-map google-maps"></div>
		</div>
	</div>
</div>
<div class="row">
	<div class="col-xs-12 push-tools">
		<div class="well">
			<button class="button green approve-button"><i class="icon-thumbs-up"></i> {{t "push.approve"}}</button>
			<button class="button red reject-button"><i class="icon-thumbs-down"></i> {{t "push.reject"}}</button>
			<a href="/apps/{{appId}}/push/{{id}}"><button class="button right"><i class="icon-pencil"></i> {{common.edit}}</button></a>
		</div>
	</div>
</div>
