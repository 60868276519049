var MultiVideoSelectorChild = require('./multi-video-selector-child-view')

module.exports = Backbone.View.extend({
	template: require('./multi-video-selector-template'),

	events: {
		'click .add-video-item': 'addNewVideo'
	},

	initialize: function(options) {
		this.pageId = options.pageId

		this.listViews = []

		this.listenTo(this.model, 'add', this.addVideo, this)
		this.listenTo(this.model, 'remove', this.removeVideo, this)
	},

	afterRender: function() {
		this.model.each(this.addVideo, this)
	},

	removeVideo: function(model) {
		// Child video removed from collection - delete it and save the collection back to the server
		model.destroy()
		$('.remove-video-item').prop('disabled', true)
		App.startLoad()
		this.model.parent.save().then(function() {
			App.stopLoad()
			$('.remove-video-item').prop('disabled', false)
		})
	},

	addVideo: function(video) {
		var view = this.addView(new MultiVideoSelectorChild({model: video}))
		this.$('.multi-video-list').append(view.render().el)

		// Trigger change on video update to set needsSaving.
		this.listenTo(video, 'change', function() {
			this.model.parent.needsSaving = true
		}, this)
	},

	addNewVideo: function() {
		var structure = App.getClassStructure('Video', this.pageId)
		this.model.add(structure)
	}
})
