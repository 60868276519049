var StandaloneStormObject = require('editor/standalone-storm-object'),
	EditorSectionView = require('editor/editor-section-view'),
	StormObject = require('editor/storm-object'),
	MediaLibrary = require('media-library/media-library-view'),
	AppStrings   = require('app-setup/app-strings'),
	MediaSelectorView = require('media-library/media-selector-view'),
	StormQL = require('models/stormql')

/**
 * Exports {@link FactFileEditView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends FactFileEditView.prototype */{
	/** @override */
	className: 'FactFileEditView',
	/** @override */
	template: require('./fact-file-edit-view-template'),
	activeTabIndex: 4,

	/** @override */
	events: {
		'click .save': 'save',
		'click #addCode': 'addCode',
		'click .array-add': 'addArrayItem',
		'change .text-input': 'updateText',
		'change .scientific-input': 'updateScientific',
		'click .delete': 'deleteArrayItem',
		'click .fact-file-image': 'changeImage',
		'click .map-button': 'mapClick'
	},

	/**
	 * @constructs CrossBordersEditView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)

		/** @private {App} */
		this.app_ = options.app

		this.model = new StandaloneStormObject()

		// Fetch enabled languages for this app.
		var languagePromise = this.app_.languageList.fetchOnce()

		// Fetch native app localisation strings
		this.localisations = new AppStrings({id: this.app_.id})
		var stringsFetch = this.localisations.fetch()

		// Fetch Plan items
		this.appPlanItems = new StormQL(null, {app: this.app_})
		var appPlanItemsFetch = this.appPlanItems.fetch({data: {class: 'Gnah_PlanItem'}})

		var modelPromise = Promise.resolve()

		if (options.id !== 'new') {
			this.model.set('id', options.id)
			this.model.once('sync', this.ready, this)

			modelPromise = this.model.fetch()
		} else {
			this.model = StandaloneStormObject.fromClassName('Gnah_FactFile')
		}

		Promise.all([modelPromise, languagePromise, stringsFetch, appPlanItemsFetch])
			.then(App.stopLoad)
			.then(this.render.bind(this))
	},

	/** @override */
	afterRender: function() {
		this.delegateEvents()

		// Show current input values
		// Common name
		_.each(this.model.get('commonName..content'), function(val, key) {
			this.$('.common-input[data-code=' + key + ']').val(val)
		})

		// Common name
		_.each(this.model.get('seen..content'), function(val, key) {
			this.$('.seen-input[data-code=' + key + ']').val(val)
		})

		// Scientific name
		this.$('.scientific-input').val(this.model.get('scientificName'))

		// Dates
		this.setupDatePickers()

		App.stopLoad()
	},

	setupDatePickers: function() {
		this.datepickers = {};
		// Initialise datepicker
		['show', 'start'].forEach(function(value) {
			var datepicker = new Pikaday({
				field: this.$('#date-' + value)[0],
				bound: true,
				showTime: true
			})

			var date      = new Date(),
				timestamp = this.model.get(value)
			if (timestamp !== undefined && timestamp !== '') {
				date = new Date(timestamp)
			}

			datepicker.setDate(date)
			this.datepickers[value] = datepicker
		}, this)
	},

	mapLocalisations: function() {
		var langs = this.app_.languageList,
			strings = []

		_.each(this.localisations.attributes, function(val, key) {
			if (key === 'id') {
				return
			}
			if (!key.includes('_TAGS')) {
				return
			}
			var isDisabled = false
			var shortendedKey = key.replace('_TAGS_', '').toLowerCase()

			if (this.model.get('tags')) {
				isDisabled = (this.model.get('tags').indexOf(shortendedKey) > -1) ? true : false
			}

			strings.push({
				key: shortendedKey,
				isDisabled: isDisabled,
				languages: langs.map(function(lang) {
					var code = lang.get('code')

					return {
						name: lang.get('name'),
						code: code,
						value: val[code]
					}
				})
			})
		}.bind(this))

		return strings
	},

	/** @override */
	getRenderData: function() {
		// Map Localisations
		App.startLoad()
		var data = this.model.toJSON()
		data.allTags = this.mapLocalisations()
		data.appId = this.app_.id
		data.languages = this.app_.languageList.toJSON()
		data.countryCodes = this.countryCodes
		data.planItemsDropdown = this.getPlanItemList()
		data.planItems = this.appPlanItems
		return data
	},

	getPlanItemList: function() {
		if (!this.model.get('activities')) {
			this.model.set('activities', [])
		}
		// Generate page list dropdown.
		var planItemDropdownOptions = '<option value="-">-</option>'

		// Get array of all unique tag names
		var tags = _.filter(this.appPlanItems.pluck('type'), function(elem, pos, self) {
			return self.indexOf(elem) === pos
		}).sort()
		// Output an option group for each tag
		_.each(tags, function(tag) {
			var taggedItem = this.appPlanItems.where({type: tag})
			var options = ''
			// Output an option for each page with this tag
			_.each(taggedItem, function(planItem) {
				// var selected = this.model.get('src') === 'cache://pages/' + page.id + '.json' ? 'selected' : ''
				// Disabled
				var disabled = this.model.get('activities').indexOf(planItem.id) === -1 ? '' : 'disabled'
				options += '<option value="' + planItem.id + '" ' + disabled + ' >' + App.l(planItem.get('title')) + '</option>'
			}, this)

			planItemDropdownOptions += '<optgroup label="' + tag.charAt(0).toUpperCase() + tag.slice(1) + '">' + options + '</optgroup>'
		}, this)

		return planItemDropdownOptions
	},

	updateText: function(e) {
		var textInput = $(e.currentTarget)
		// Get Object
		var object = textInput.data('object')
		// Get Lang Code
		var code = textInput.data('code')
		// Get value
		var value = textInput.val()

		var arrayItem = textInput.data('arrayitem')
		if (object && code) {
			if (!arrayItem) {
				this.model.set(object + '..content..' + code, value)
			} else {
				this.model.get(object).at(textInput.data('index')).set('content..' + code, value)
			}
		}
	},

	updateScientific: function() {
		this.model.set('scientificName', this.$('.scientific-input').val())
	},

	addArrayItem: function(e) {
		// What are we adding?
		var type = $(e.currentTarget).data('object')
		// Different cases for each object type
		if (type) {
			switch (type) {
				case 'features':
					// Text object
					// Create one and fill in langs
					var text = StormObject.fromClassName('Text', this.model.get('pageId'))
					this.$('.feature-input').each(function() {
						var code = $(this).data('code')
						var value = this.value
						if (value !== '') {
							text.set('content..' + code, value)
						}
					})
					var features = this.model.get('features')
					if (!features) {
						features = []
						features.push(text)
						this.model.set('features', features)
					} else {
						features.add(text)
					}

					break
				case 'facts':
					// Text object
					// Create one and fill in langs
					var factText = StormObject.fromClassName('Text', this.model.get('pageId'))
					this.$('.fact-input').each(function() {
						var code = $(this).data('code')
						var value = this.value
						if (value !== '') {
							factText.set('content..' + code, value)
						}
					})
					var facts = this.model.get('facts')
					if (!facts) {
						facts = []
						facts.push(factText)
						this.model.set('facts', facts)
					} else {
						facts.add(factText)
					}

					break
				case 'tags':
					// String object
					var value = $('.tag-input').val()
					if (value !== '') {
						var tags = this.model.get('tags')
						if (!tags) {
							tags = []
							this.model.set('tags', tags)
						}
						tags.push(value)
					}
					break
				case 'activities':
					// Number
					// Just create a number.
					var number = parseInt($('.activity-input').val(), 10)
					if (number) {
						var activities = this.model.get('activities')
						if (!activities) {
							activities = []
							this.model.set('activities', activities)
						}
						activities.push(number)
					}
					break
				case 'images':
					// Image object
					var image = StormObject.fromClassName('Image', this.model.get('pageId'))
					if (!this.model.get('images')) {
						this.model.set('images', [])
					}

					this.chooseImage(image, this.model.get('images'))
					break
			}
		}
		this.render()

		// Populate it
	},

	deleteArrayItem: function(e) {
		var button = $(e.currentTarget)
		var object = button.data('object')
		var index = button.data('index')
		var model
		if (object !== 'tags' && object !== 'activities') {
			model = this.model.get(object).at(index)
		}

		swal({
			title: $.t('editor.inspector.areYouSure'),
			text: $.t('editor.inspector.confirmDelete'),
			showCancelButton: true
		}, function(didConfirm) {
			if (didConfirm) {
				if (object !== 'tags' && object !== 'activities') {
					model.set('toDelete', true)
				} else {
					// Splice out tags item as it's not a backbone collection
					this.model.get(object).splice(index, 1)
				}
				this.render()
			}
		}.bind(this))
	},

	changeImage: function(e) {
		var index = $(e.currentTarget).data('index')
		var model = this.model.get('images').at(index)
		this.chooseImage(model)
	},

	chooseImage: function(imageModel, parent) {
		this.mediaLibrary = new MediaSelectorView({
			app: this.app_,
			model: imageModel,
			mediaType: MediaLibrary.types.IMAGE
		})

		this.mediaLibrary.on('change', function() {
			// Do something
			if (parent) {
				parent.add(imageModel)
			}
			this.render()
		}, this)

		$('body').append(this.mediaLibrary.el)
		this.mediaLibrary.render().show()
	},

	save: function() {
		App.startLoad()

		var self = this

		for (var propertyName in this.datepickers) {
			if (this.datepickers[propertyName]) {
				this.model.set(propertyName, moment(this.datepickers[propertyName].getDate()).format())
			}
		}

		self.model.once('sync', function() {
			App.router.navigate('/apps/' + self.app_.id + '/fact-files', {trigger: true})
		})

		if (!self.model.isNew()) {
			self.model.requestLock(function() {
				var featuresToDelete = self.model.get('features').where({toDelete: true})
				var imagesToDelete = self.model.get('images').where({toDelete: true})
				var promises = []

				featuresToDelete.concat(imagesToDelete).forEach(function(model) {
					promises.push(model.destroy())
				})

				Promise.all(promises).then(function() {
					self.model.save(null, {appId: self.app_.id}).then(function() {
						self.model.requestUnlock()
					})
				})
			})
		} else {
			self.model.save(null, {appId: self.app_.id})
		}
		return false
	}
})
