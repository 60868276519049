var ListView = require('list-view/list-view'),
	EditorSectionView = require('editor/editor-section-view')

var AchievementListView = ListView.extend({
	template: require('./achievement-list-view-template'),
	list: require('models/stormql'),
	listItem: require('./achievement-list-item-view')
})

/**
 * Exports {@link AchievementsView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends AchievementsView.prototype */{
	/** @override */
	className: 'achievements',
	/** @override */
	template: require('./achievements-view-template'),

	/**
	 * @constructs AchievementsView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)
		App.startLoad()

		this.app = App.appList.get(options.appId)

		this.views = {
			list: new AchievementListView({
				fetchData: {data: {class: 'Achievement'}},
				app: this.app
			})
		}
	},

	/** @override */
	getPageTitle: function() {
		return $.t('achievements.title')
	},

	/** @override */
	getRenderData: function() {
		return {
			appId: this.app.id
		}
	},

	/** @override */
	afterRender: function() {
		var permission = App.acl.getPermission('Content')

		this.$('.add').hide()

		switch (permission) {
			case 'Delete':
			case 'Write':
				this.$('.add').show()
		}
	}
})
