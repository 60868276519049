module.exports = [
	"Active Shooter",
	"Animal (Zoonotic) Borne Disease",
	"Air Quality Alert",
	"Air",
	"Airborne Disease",
	"Avalanche",
	"Blizzard",
	"Bloodborne Disease",
	"Body-Fluid Borne Disease",
	"Biological Hazard",
	"Chemical Hazard",
	"Child Safety",
	"Coastal Flood",
	"Coastal",
	"Dense Fog",
	"Drought",
	"Dust Storm",
	"Earthquake",
	"Epidemic",
	"Excessive",
	"Extreme Cold",
	"Extreme Fire",
	"Extreme Heat",
	"Flash Flood",
	"Flood",
	"Foodborne Disease",
	"General",
	"Hailstorm",
	"Heatwave",
	"Hurricane Force Wind",
	"Hurricane",
	"Hydrologic Advisory",
	"Lakeshore",
	"Landslide",
	"Marine Weather",
	"Nuclear",
	"Nuclear Power Plant",
	"Other",
	"Pandemic",
	"Pest Infestation",
	"Public Health Statement",
	"Radiological Hazard",
	"Road Works",
	"Severe Thunderstorm Warning",
	"Storm",
	"Terror Alert",
	"Tornado",
	"Tropical Cyclone",
	"Tropical Storm",
	"Tsunami Advisory",
	"Tsunami",
	"Typhoon",
	"Vector-Borne Disease",
	"Volcano",
	"Wildfire",
	"Wind",
	"Winter Storm",
	"Winter"
]
