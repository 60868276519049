<div class="container-fluid">
<div class="row">
	<div class="atlas-header col-xs-12">
		<img width="100px" src="/images/atlas/bear-icon.png"></img>
		<h2>{{t "r4b.title"}} {{t "r4b.admin"}}</h2>
	</div>
</div>
<div class="row">
	<div class="level-header">
		<legend>{{t "r4b.level.levels"}}</legend>
		<a class="close-edit-levels" style="display:none; margin-left:10px;" href=""><i class="icon icon-remove"></i></a>
		<a class="edit-levels" href=""><i class="icon icon-pencil"></i></a>
		<a class="add-level" style="display:none" href=""><i class="icon icon-plus"></i> {{t "common.add"}} {{t "r4b.level.title"}}</a>
	</div>
	<div class="levels" style="display:none">
		{{#each levelList}}
			<div class="level">
				<h4 class="level-name">{{#ifEqual @key 0}}{{t "r4b.assessments.pre"}}{{else}}{{t "r4b.level.title"}} {{identifier}}{{/ifEqual}}:</h4>
				<label class="control-label">{{t "r4b.level.colour"}}:</label>
				<span class="colour-visual" data-id={{id}} data-rgba="{{getRGBAString colour}}" style="{{getColourStyle colour}}"></span>
				{{t "r4b.level.red"}} <input data-id={{id}} data-colour="r" class="colour-pick" type="number" min="0" max="255" value={{colour.r}}></input>
				{{t "r4b.level.green"}} <input data-id={{id}} data-colour="g" class="colour-pick" type="number" min="0" max="255" value={{colour.g}}></input>
				{{t "r4b.level.blue"}} <input data-id={{id}} data-colour="b" class="colour-pick" type="number" min="0" max="255" value={{colour.b}}></input>
				{{t "r4b.level.alpha"}} <input data-id={{id}} data-colour="a" class="colour-pick" type="number" min="0" max="1" value={{colour.a}}></input>
				<label for="badges" class="control-label">{{t "editor.inspector.properties.badge.title"}}:</label>
				<select name="badges" class="badges" data-id={{id}} id="badges-{{id}}" id="badges">
							<option value="-">{{t "r4b.level.selectBadge"}}</option>
					{{#each ../badges}}
							<option value="{{id}}" {{findSelected id ../badgeId}}>{{getLocaleText title.content}}</option>
					{{/each}}
				</select>
				<div class="red-delete developer-mode level-delete" data-selector="{{pageId}}" data-id="{{pageId}}"><i class="icon-trash"></i></div>
				<b><code><i class="icon-lightbulb"></i> {{id}}</code></b>
			</div>
		{{/each}}
		<hr>
	</div>
</div>

{{#each levelList}}
	{{#ifEqual @key 0}}
	<!-- If level === 0 then its a pre assessment-->
		<div class="row">
			<div class="col-xs-12 level-header">
				<legend><span class="colour-visual" data-id={{id}} data-rgba="{{getRGBAString colour}}" style="{{getColourStyle colour}}"><div class="white"></div></span> {{t "r4b.assessments.pre"}}</legend>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-12 actions-col">

			</div>
		</div>
		<div class="row">
			<div class="col-xs-12">
				<div class="modules-container">
					{{#each units}}
						<a class="assessment" href="apps/{{../../appId}}/atlas_admin/{{id}}" style="{{getBackgroundImageStyle image.src 'cover'}}">
							<div class="assessment-content">
								<div class="red-delete module-delete developer-mode" data-selector="{{pageId}}" data-id="{{pageId}}"><i class="icon-trash"></i></div>
								<h4>{{t "r4b.assessments.pre"}}</h3>
							</div>
							<div class="assessment-footer">
								<span><b>{{languageLength}}</b> {{t "r4b.languages"}}</span>
								<span><b>{{contentBlockLength}}</b> {{t "r4b.sections"}}</span>
							</div>
						</a>
					{{/each}}
				</div>
			</div>
		</div>
	{{else}}
		<!-- else it's just a normal module section -->
		<div class="row">
			<div class="col-xs-12 level-header">
				<!-- <div style="{{getColourStyle colour}}" class="level-logo"></div> -->
				<legend><span class="colour-visual span-colour" data-id={{id}} data-rgba="{{getRGBAString colour}}" style="{{getColourStyle colour}}" data-level="{{@key}}"><div class="white"></div></span> {{t "r4b.modules.level"}} {{@key}}</legend>
				{{#if hasAssessment}}{{else}}
				<a class="add" href="" data-class="R4b_Assessment" data-level="{{@key}}" style="margin-left:10px;"><i class="icon icon-plus"></i> {{t "common.add"}} {{t "r4b.assessments.assessment"}}</a>
				{{/if}}
				<a class="add" href="" data-class="R4b_Module" data-level="{{@key}}"><i class="icon icon-plus"></i> {{t "common.add"}} {{t "r4b.modules.level"}} {{@key}} {{t "r4b.modules.module"}} </a>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-12 actions-col">

			</div>
		</div>
		<div class="row">
			<div class="col-xs-12">
				<div class="modules-container">
					{{#each units}}
					{{log this}}
						<!-- <a class="module" data-selector="{{pageId}}" href="apps/{{../../appId}}/atlas_admin/{{id}}" style="{{getBackgroundImageStyle image.src 'cover'}}">
							{{#ifEqual class 'R4b_Module'}}
							<div class="module-header">
								<i class="icon-eye-open visibility-i"></i>{{t "r4b.modules.visibility"}}: {{#each logic}} {{#each value}} {{capitaliseFirstLetter this}} {{/each}} {{/each}}
								<div class="red-delete module-delete developer-mode" data-selector="{{pageId}}" data-id="{{pageId}}"><i class="icon-trash"></i></div>
							</div>
							{{else}}
							<div class="empty-header">
								<div class="red-delete module-delete developer-mode" data-selector="{{pageId}}" data-id="{{pageId}}"><i class="icon-trash"></i></div>
							</div>
							{{/ifEqual}}
							<div class="module-content">
								<h4>{{#ifEqual class 'R4b_Module'}}{{getLocaleText name.content}}{{else}}{{t "r4b.assessments.assessment"}}{{/ifEqual}}</h4>
							</div>
							<div class="module-footer">
								<span><b>{{languageLength}}</b> {{t "r4b.languages"}}</span>
								<span><b>{{contentBlockLength}}</b> {{t "r4b.sections"}}</span>
							</div>
						</a> -->

						<a class="module-card" style="{{getColourStyle ../colour}}" data-level="{{level}}" data-selector="{{pageId}}" href="apps/{{../../appId}}/atlas_admin/{{id}}">
							<div class="module-card-content">
								<div class="red-delete module-delete developer-mode" data-selector="{{pageId}}" data-id="{{pageId}}"><i class="icon-trash"></i></div>
								<h4 class="title">
								{{#ifEqual class 'R4b_Module'}}
									{{getLocaleText name.content}}
								{{else}}
									{{t "r4b.assessments.assessment"}}
								{{/ifEqual}}
								</h4>
								<div class="bottom-row" style="border-bottom: {{getRGBAString ../colour}} 5px solid;">
									<h5 class="bottom-text">
										<b><i style="color:{{getRGBAString ../colour}};" class="icon-time"></i> {{secondsToMinutes1dp time}}</b> {{t 'r4b.minutes'}}
									</h5>
									<h5 class="bottom-text">
										<b><i style="color:{{getRGBAString ../colour}};" class="icon-list-ol"></i> {{contentBlockLength}}</b> {{t "r4b.sections"}}
									</h5>
									<h5 class="bottom-text">
										<b><i style="color:{{getRGBAString ../colour}};" class="icon-group"></i> {{people}}</b> {{#ifEqual people 1}} {{t 'r4b.person'}} {{else}} {{t 'r4b.people'}} {{/ifEqual}}
									</h5>
								</div>
							</div>
						</a>
					{{else}}
					<p class="help-block">
						{{t 'r4b.empty'}}
					</p>
					{{/each}}
				</div>
			</div>
		</div>
	{{/ifEqual}}
{{/each}}

</div>
