<td class="center">
	<!-- Event type -->
	{{capitaliseFirstLetter data.eventType}}
</td>
<td class="center">
	<!-- Title -->
	<dl>
		{{#each data.translations}}
			<dt>{{lang}}</dt>
			{{#if title}}
			<dd>{{title}}</dd>
			{{else}}
			<dd>{{t "whatnow.content-not-set"}}</dd>
			{{/if}}
		{{else}}
			<dd>{{t "whatnow.content-not-set"}}</dd>
			{{/each}}
	</dl>
</td>
<td class="center">
	<!-- Description -->
	<dl>
		{{#each data.translations}}
			<dt>{{lang}}</dt>
			{{#if description}}
			<dd>{{description}}</dd>
			{{else}}
			<dd>{{t "whatnow.content-not-set"}}</dd>
			{{/if}}
		{{else}}
			<dd>{{t "whatnow.content-not-set"}}</dd>
		{{/each}}
	</dl>
</td>
<td class="tools">
		<a href="/apps/{{countryCode}}/whatnow/{{data.id}}"><i class="icon-pencil" data-id="{{data.id}}"></i></a>
			<i class="icon-trash delete" data-id="{{data.id}}"></i></br>
			{{#if data.approvedBy}}
			<button type="button" class="button green approve" disabled>{{t 'common.approved'}}</button>
			{{else}}
			<button type="button" id="approve-{{data.id}}" class="button blue approve" data-id="{{data.id}}">{{t 'common.approve'}}</button>
			{{/if}}
	</td>
