<div class="row">
	<div class="col-sm-6">
		<h3>{{t "gdpcConsole.nationalSocieties.edit"}}</h3>
	</div>
	<div class="col-sm-6 actions-col">
		<a href="javascript:history.go(-1)"><button class="button white">{{t "common.back"}}</button></a>
	</div>
</div>
<div class="row">
	<form class="col-xs-5">
		<fieldset class="form-horizontal">
			<legend>{{t "gdpcConsole.nationalSocieties.details"}}</legend>

			<div class="form-group">
				<label class="control-label col-sm-3" for="society-name">{{t "users.name"}}</label>
				<div class="col-sm-9">
					<input type="text" id="society-name" class="form-control">
				</div>
			</div>

			<div class="form-group">
				<label class="control-label col-sm-3" for="society-description">{{t "common.description"}}</label>
				<div class="col-sm-9">
					<input type="text" id="society-description" class="form-control">
				</div>
			</div>

			<div class="form-group">
				<label class="control-label col-sm-3" for="society-locale">{{t "gdpcConsole.nationalSocieties.locale"}}</label>
				<div class="col-sm-9">
					<select id="society-locale" class="form-control">{{> localeOptions}}</select>
				</div>
			</div>

			<div class="well">
				<button class="button green save-button">{{t "common.save"}}</button>
			</div>
		</fieldset>
	</form>
</div>
