{
	"error": {
		"noAccess": "သင္သည္ ဤစနစ္သံုးရန္ ဆက္သြယ္မွုမရွိ။ စီမံခန္ ့ခြဲသူႏွင့္ ဆက္သြယ္ပါ။",
		"noAccessJoin": "သင္သည္ ေလာေလာဆယ္ဤစနစ္သံုးရန္ ဆက္သြယ္မွုမရွိ။ စီမံခန္ ့ခြဲသူႏွင့္ ဆက္သြယ္ပါ။",
		"oops": "သည်းခံပါ!",
		"lockedBy": "ဤသည်အရာဝတ္ထုလက်ရှိအားဖြင့်သော့ခတ်နေသည်",
		"saveFailed": "ကြော်ငြာကို Save လုပ်ရန်ပျက်ကွက်ခဲ့သည်။ စာမကျြနှာ refresh ပေးပါ။",
		"generic": "အမှားတစ်ခုဖြစ်ပေါ်နေပါသည်။",
		"allFields": "အားလုံးနယ်ပယ်များတွင်ဖြည့်ပေးပါ။",
		"connect": "API ဆာဗာကိုချိတ်ဆက်မပေးနိုင်ခဲ့ပါ။",
		"rootPage": "အမြစ်စာမျက်နှာကိုတွေ့ရှိခဲ့လို့မရပါ။",
		"cantPaste": "ဤသည်အရာဝတ္ထုအမျိုးအစားကဒီမှာ paste လုပ်ထားတဲ့မရနိုင်ပါ။",
		"exitWarning": "App ကိုဒေတာနေဆဲကယ်တင်ခြင်းသို့ရောက်ရ၏လျက်ရှိသည်။ သငျသညျစာမျက်နှာစွန့်ခွာခဲ့လျှင်ယခုဒေတာကိုဆုံးရှုံးနိုင်ပါသည်။",
		"noPermission": "သင် action ကိုဖျော်ဖြေဖို့ခွင့်ပြုချက်မရှိကြပါဘူး။",
		"noApps": "သင်သည်မည်သည့် app များကိုဝင်ရောက်ခွင့်ရှိသည်မဟုတ်ကြဘူး"
	},
	"common": {
		"filter": "ဖီလ္တာ(အစစ္)",
		"linkTypes": {
			"email": "အီးေမးလ္",
			"timer": "timer",
			"emergency": "အရေးပေါ်",
			"call": "ခေါ်ရန်",
			"share": "ဝေစု",
			"appLink": "Inter-app ကို",
			"sms": "စာတို",
			"uri": "URI",
			"native": "ဇာတိ",
			"internal": "internal",
			"external": "ပြင်ပ"
		},
		"linkDescriptions": {
			"email": "သီးသန့္လိပ္စာသုိ ့အီးေမးလ္ပုိ ့ရန္အခ်ိတ္္အဆက္ ",
			"native": "app ကိုအတွင်းဇာတိလုပ်ဆောင်နိုင်စွမ်းကိုတစ်ဖဲ့ကိုရခြင်းမှတစ်ဦးက link ကို။",
			"external": "ပြင်ပက web page ကိုတစ်ဦးက link ကို။ app ကိုအတွင်းဖွင့်လှစ်ပါလိမ့်မယ်။",
			"appLink": "အခြား app ထဲမှာစာမျက်နှာမှတစ်ဦးက link ကို။",
			"sms": "အသုံးပြုသူရဲ့ device မှတစ်ဦးသို့ SMS Message ပေးပို့ဖို့တစ်ဦးက link ကို။",
			"call": "အသုံးပြုသူရဲ့ device မှဖုန်းနံပါတ်တစ်ခု dial မှတစ်ဦးက link ကို။",
			"emergency": "အသုံးပြုသူရဲ့ setting တွင်သတ်မှတ်ထားသောအရေးပေါ်ဖုန်းနံပါတ် dial မှတစ်ဦးက link ကို။",
			"timer": "device ကိုအပေါ်တစ်ဦးနှစ်သစ်ကြိုဆိုပွဲ timer နေရာမှာစတင်သည်ရာတစ်ဦးက link ကို။",
			"share": "အဆိုပါကိရိယာပေါ်တွင်ဇာတိ '' ဝေစု '' ပေးတဲ့ dialog ကနေတစ်ဆင့် App ကိုဝေမျှဖို့တစ်ဦးက link ကို။",
			"internal": "app ကိုအတွင်းတစ်ဦးကွဲပြားခြားနားစာမျက်နှာတစ်ဦးက link ကို။",
			"uri": "ပြင်ပဝဘ်စာမျက်နှာသို့မဟုတ် application ကိုမှတစ်ဦးက link ကို။ app ကိုစွန့်ခွာရန်အအသုံးပြုသူဖြစ်ပေါ်စေပါလိမ့်မယ်။"
		},
		"pageTags": {
			"emergency": "အရေးပေါ်",
			"tests": "စမ်းသပ်မှုများ",
			"other": "အခြား",
			"info": "info",
			"learn": "သင်ကြား",
			"prepare": "ပြင်ဆင်ထား"
		},
		"pleaseWait": "ကျေးဇူးပြုပြီးခဏစောင့်ပါ",
		"closeThisMessage": "ဤသတင်းစကားကိုပိတ်ပြီး",
		"settings": "Settings များ",
		"sections": "ကဏ္ဍများ",
		"cancel": "Cancel",
		"language": "ဘာသာစကား",
		"back": "ပြန်.",
		"close": "ပိတ်",
		"description": "ဖေါ်ပြချက်",
		"upload": "လွှတ်တင်ခြင်း",
		"finish": "အပြီးသတ်",
		"longLoad": "အချက်အလက်များ၏ပမာဏကြီးမားတဲ့ရယူခြင်း, ဒီတစ်မိနစ်လောက်အထိကြာလိမ့်မည်။",
		"done": "ပြီးပြီ",
		"next": "နောက်တစ်ခု",
		"email": "အီးမေးလိပ်စာ",
		"save": "ကြော်ငြာကို Save လုပ်ရန်",
		"edit": "Edit ကို",
		"submit": "Submit",
		"approved": "approved",
		"reject": "ပယ်ချ",
		"tools": "Tools များ",
		"warning": "သတိပေးချက်",
		"add": "ပေါင်း",
		"approve": "အတည်ပြု",
		"position": "ရာထူး",
		"preview": "preview",
		"clickToBrowseMap": "မြေပုံ browse ဖို့ကိုနှိပ်ပါ",
		"publish": "ပုံနှိပ်ထုတ်ဝေ",
		"username": "username",
		"tag": "tag ကို",
		"page": "စာမျက်နှာ",
		"view": "ကြည့်ရှုခြင်း"
	},
	"login": {
		"title": "ေလာ့ဂ္အင္း",
		"inputs": {
			"password": "Password ကို"
		},
		"error": {
			"title": "Oopsie!",
			"emailNotFound": "အီးမေးလ်ပို့ရန်လိပ်စာရှာမတွေ့ပါ။",
			"body": "သင်ကမမှန်ကန်ကြောင်းအသုံးပြုသူအမည်သို့မဟုတ်စကားဝှက်ကိုဝင်ကြ၏ပါတယ်။",
			"linkExpired": "ဒါဟာစကားဝှက်ပြန်ညှိ link ကိုသက်တမ်းကုန်သွားပြီ။"
		},
		"forgot": {
			"title": "ငါတို့ရှိသမျှသည်တစ်ခါတစ်ရံတွင်မေ့လျော့!",
			"body": "သင့်အကောင့်ဆက်စပ်အီးမေးလ်လိပ်စာရိုက်ထည့်ပါ။"
		},
		"success": {
			"title": "အောင်မြင်ပါသည်!",
			"loginBody": "သငျသညျကိုအောင်မြင်စွာ logged in ဝင်သော, ဒါပေမယ့်သွားကြဖို့ဘယ်နေရာမှာရှိတယ်င့်! သငျသညျ auth.3sidedcube.com မှာရှိပါတယ်။ သင်၏အဖွဲ့အစည်းရဲ့မုန်တိုင်း CMS သို့သွားပါသွားပါ။",
			"body": "သငျသညျမကြာမီသင့်စကားဝှက်ကို reset ညွှန်ကြားချက်နှင့်အတူအီးမေးလ်တစ်စောင်လက်ခံရရှိသင့်ပါတယ်။"
		},
		"links": {
			"forgot": "ကျွန်တော်မေ့သွားတယ်...",
			"return": "ရဲ့ login ပြန်သွား"
		},
		"reset": {
			"newPassword": "စကားဝှကိအသစ်",
			"confirm": "အတည်ပြု",
			"success": "သင့်ရဲ့စကားဝှက်ကိုသင်အခုဒီဝင်းဒိုးကိုပိတ်လိုက်နိုင်, မသတ်မှတ်ထားပြီးဖြစ်ပါသည်။",
			"submit": "Submit"
		}
	},
	"localisations": {
		"title": "လုိကယ္လုိက္ေဇးရွင္း",
		"string": "ကြင္းဆက္မ်ား",
		"key": "ေသာ့၊ အဓိက",
		"addNew": "လုိကယ္လုိက္ေဇးရွင္း အသစ္မ်ား ထပ္ျဖည့္",
		"addMultiple": "လုိကယ္လုိက္ေဇးရွင္း အသစ္ ေျမာက္မ်ားစြာ ထပ္ျဖည့္",
		"addMultipleInstructions": "လုိကယ္လုိက္ေဇးရွင္း အသစ္မ်ားအစုလုိက္အျပံုလုိက္ အပ္ေဒ့တ္လုပ္ သုိ ့မဟုတ္ ထပ္ျဖည့္",
		"uploadCsv": "CSV အပ္ပလုိ ့ဒ္လုပ္",
		"copyFromAnotherApp": "အျခား အက္ပ္တစ္ခုမွ ကူးယူ",
		"confirmCopy": "ေရြးထားေသာ အက္ပ္မွ လုိကယ္လုိင္ေဇးရွင္းမ်ား ကူယူလုိတာေသခ်ာလား။",
		"overwriteExisting": "လက္ရွိလုိကယ္လုိင္ေဇးရွင္းမ်ား အေပၚတြင္ ထပ္ေရး",
		"overwriteWarning": "လက္ရွိ သင္ဘာသာျပန္ျပီးသည္မ်ားကုိ ဖ်က္ျပီးအေပၚမွထပ္ေရးလိမ့္မည္ ",
		"all": "<b>All</b> localizations",
		"errors": {
			"invalidKey": "တရားမ၀င္ လုိကယ္လုိင္ေဇးရွင္း ေသာ့။  ေသာ့မ်ားတြင္ အံဒါစေကာျဖင့္စရမည္၊ ေနာက္ျပီး စာလံုးျကီး(ကက္ပီတာလ္)မ်ားႏွင့္ အံဒါစေကာမ်ားသာပါ၀င္ရမည္။ေသာ့မ်ားတြင္ အပိုင္း ႏွစ္ပုိင္းပါ၀င္ရမည္ ( အံဒါစေကာမ်ားျဖင့္ ဒီမစ္မစ္လုပ္ထားေသာ ဘစ္တ္စ္မ်ား)။",
			"csvTooManyColumns": "ဟက္ဒါတြင္ ေကာ္လံမ်ား မ်ားလြန္းသည္",
			"csvInvalidHeader": "ေဘာင္မ၀င္ ဟက္ဒါ",
			"csvInvalidKeyOnLine": "လုိင္းေပၚတြင္ ေဘာင္မ၀င္ လုိကယ္လုိင္ေဇးရွင္း ေသာ့။ ",
			"csvInvalidColumnCountOnLine": "လုိင္းေပၚတြင္ ေဘာင္မ၀င္ ေကာ္လံအေရအတြက္ ",
			"expected": "ေမွ်ာ္မွန္းထားေသာ",
			"got": "ရရွိခဲ့"
		}
	},
	"appSetup": {
		"expandAll": "အားလံုး ခ်ဲ ့",
		"addCsv": "CSV ျဖည့္ထည့္",
		"translation": "ဘာသာပြန်ချက်များ",
		"localisation": "App ကိုမူပြောင်းခြင်း",
		"appName": "App အမည်",
		"publishableLanguages": "Publish ဘာသာစကားများ",
		"type": "ပုံစံ",
		"title": "App ကို Setup ကို",
		"languages": "App ကိုဘာသာစကားများ",
		"value": "အဘိုး",
		"dev": "dev",
		"bundleIdentifiers": "SDK ကို Bundle ကိုသတ်မှတ်သူများ",
		"properties": "properties ကို",
		"publishable": ": ရန် Publish",
		"key": "သော့",
		"startPage": "စာမျက်နှာ Start",
		"translationError": "ဘာသာပြန်ချက်ဖိုင်ကို download လုပ်ပါရန်မအောင်မြင်ခဲ့ပါ။ app ကိုများနှင့်အဖွဲ့အစည်းကအနည်းဆုံးဘာသာစကား enabled နှင့်ထုတ်ဝေသူရှိသည်ဟုစစ်ဆေးပါ။",
		"addMoreLanguages": "ဒီအဖွဲ့အစည်းကမှတာဝန်ပေးအပ်သာလျှင်ဘာသာစကားများကိုဤနေရာတွင်ရရှိနိုင်ပါသည်။ အဆိုပါအဒ်မင်အပိုင်း၌ သာ. assign ။",
		"live": "အသက်ရှင်သော"
	},
	"metadata": {
		"addScreenshot": "စခရီးန္ေရွာ့တ္(screenshot) ထည့္ပါ။",
		"appIconHint": "(၁၀၂၄) x (၁၀၂၄) ျဖစ္ျပီး ထရန္စပယ္ရစီ (ေဖါက္ထြင္းျမင္ရျခင္း) မရွိရ။ ေဂ်ပီဂ်ီ(JPG) သုိ ့မဟုတ္ ပီအင္ဂ်ီ (PNG) ေဖၚမက္ျဖစ္ျပီး ရယ္ဇလူးရွင္းအနည္းဆံုး (၇၂)ဒီပီအုိင္ ႏွင့္ အာရ္ဂ်ီဘီ(RGB)ေရာင္စဥ္တြင္းရွိရမည္။ အထပ္လႊာ(Layer)ႏွင့္ ေဒါင့္မ်ားလံုးမ်ား မပါရွိရ။",
		"appPreviewVideo": "အက္ပ္ အျကုိျကည့္ ဗြီဒီယုိ ",
		"appPreviewVideoHint": "အက္ပ္ အျကုိျကည့္ ဗြီဒီယုိမ်ားသည္ M4V, MP4, သုိ ့မဟုတ္ MOV ေဖၚမက္ျဖစ္ျပီး၊ (၅၀၀)MB မေက်ာ္ရ",
		"appStoreTitle": "အက္ပ္စတုိးေခါင္းစီး",
		"delete": "(ပယ္) ဖ်က္ (ဒီလီတ္ လုပ္)",
		"emailHint": "အက္ပ္စတုိး သုိ ့မဟုတ္ အသံုးျပဳသူမွ ဆက္သြယ္ရန္လုိအပ္ေသာ္ သံုးရန္ အီးေမးလ္ေပးပါ",
		"featureGraphic": "ဖီးခ်ားဂရက္ဖစ္ (အေၾကာင္းအရာ ဂရပ္ပံု)",
		"featureGraphicHint": "ဂူဂဲလ္ပေလးစတုိးအတြက္ ဖီးခ်ားဂရက္ဖစ္ သုိ ့မဟုတ္ (အလံ)ဘဲန္နာပါရမည္၊ အတုိင္းအတာသည္ အနံ (၁၀၂၄)x အျမင့္(၅၀၀) ျဖစ္ရမည္။ ယင္းသည္ သီးသန္ ့တစ္ပံုတည္းလည္းျဖစ္နုိင္သည္။ (အကယ္၍ေပးထားလွ်င္) ေၾကာ္ျငာ ဗြီဒီယုိအတြက္ ေနာက္ခံလည္း ျဖစ္ႏုိင္သည္။ ",
		"languages": "ဘာသာစကားမ်ား",
		"nameOnDevice": "ပစၥည္းကရိယာပါ အက္ပ္၏ အမည္။",
		"phoneNumberHint": "ဖုန္းေခၚရန္ ႏုိင္ငံ သေကၤတနံပါတ္ မပါမေနရ။ ",
		"platformSpecific": "ပလက္ေဖါင္းသီးသန္ ့ မီတာေဒတာ",
		"price": "ေစ်းႏွုန္း",
		"rating": "အဆင့္(အမွတ္) သတ္မွတ္",
		"screenshotsHint": "ပိတ္ကား အရြယ္တုိင္းအတြက္ စခရီးန္ေရွာ့တ္မ်ား ပါ၀င္သည္။ စခရီးန္းေရွာတ့္မ်ားသည္ JPG သုိ ့မဟုတ္ PNG ေဖၚမက္ျဖစ္ျပီး RGB ေရာင္စဥ္ျဖစ္ရမည္။",
		"shared": "ပလက္ေဖါင္းမ်ားအၾကား မီတာေဒတာ",
		"shortAppDescription": "အက္ပ္ ေဖၚျပခ်က္အက်ဥ္း",
		"supportUrlHint": "သင့္ အက္ပ္အတြက္ အေထာက္အကူအခ်က္အလက္ပါ ယူအာရ္အဲလ္။ ဤ ယူအာရ္အဲလ္ကု္ သင့္အက္ပ္တြင္ ျမင္ရမည္။",
		"websiteHint": "သင့္ အက္ပ္၏ေစ်းကြက္ဆုိင္ရာအခ်က္မ်ားပါ ယူအာရ္အဲလ္။ ဤ ယူအာရ္အဲလ္ကု္ သင့္အက္ပ္တြင္ ျမင္ရမည္။",
		"description": "ဖေါ်ပြချက်",
		"name": "App အမည်",
		"features": "အင်္ဂါရပ်များ",
		"phoneNumber": "ဖုန်းနံပါတ်",
		"keywords": "keywords",
		"title": "metadata",
		"copyright": "မူပိုင်",
		"secondaryCategory": "secondary အမျိုးအစား",
		"supportUrl": "ပံ့ပိုးမှု URL ကို",
		"appIcon": "App ကိုအိုင်ကွန်",
		"primaryCategory": "မူလတန်းအမျိုးအစား",
		"appStore": "App Store မှာအသေးစိတ်အချက်အလက်",
		"website": "marketing ကို URL ကို",
		"launchDate": "ပစ်လွှတ်သည့်ရက်စွဲ",
		"privacyPolicyUrl": "ကိုယ်ပိုင်ရေးရာမူဝါဒ URL ကို",
		"noBuildPlatforms": "အဘယ်သူမျှမတည်ဆောက်ပလက်ဖောင်းဒီ app များအတွက်တည်ရှိ",
		"iconSize": "မူရင်းအိုင်ကွန်အရွယ်အစား 1024x1024 ရမည်ဖြစ်သည်။"
	},
	"plugins": {
		"title": "ပလပ္ဂ္အင္းမ်ား",
		"availablePlugins": "လက္လွမ္းမီေသာ ပလပ္ဂ္အင္းမ်ား"
	},
	"editor": {
		"inspector": {
			"swapItem": "အေၾကာင္းအခ်က္(အုိက္တဲမ္) လဲလွယ္",
			"properties": {
				"progressMessage": "မက္ဆစ္ဂ်္ ေရာက္ရွိမွုအဆင့္",
				"annotation": {
					"title": "မှတ်စာ",
					"description": "အဆင့်အရေအတွက်ကို"
				},
				"title": {
					"title": "ခေါင်းစဥ်",
					"description": "စာရင်းကို item ခေါင်းစဉ်"
				},
				"name": {
					"title": "အမည်",
					"description": "internal စနစ်ကအမည်အား"
				},
				"hint": {
					"title": "သဲလွန်စ",
					"description": "အသုံးပြုသူများအတွက်ညွှန်ကြားချက်များ"
				},
				"image": {
					"title": "image ကို",
					"removeButton": "image ကို Remove",
					"chooseButton": "image ကိုရှေးခယျြပါ"
				},
				"video": {
					"title": "ဗီဒီယိုကို",
					"addLanguage": "ဘာသာစကား Add",
					"loop": "loop",
					"attributes": "attribute တွေ",
					"chooseButton": "ဗီဒီယိုကိုရွေးချယ်ပါ"
				},
				"link": {
					"type": "ပုံစံ",
					"destination": "ခရီးလမ်းဆုံး",
					"linkTitle": "link ခေါင်းစဉ်",
					"title": "link ကို",
					"linkDestination": "link ကိုကံကြမ္မာ",
					"body": "ခန္ဓာကိုယ်ကစာသား",
					"duration": "timer ကြာချိန် (မိနစ်)",
					"testLink": "စမ်းသပ်ခြင်း link ကို",
					"recipients": "လက်ခံသူများ (လိုင်းနှုန်းတဦးတည်း)"
				},
				"images": {
					"add": "image ကို Add"
				},
				"animation": {
					"delay": "Frame ကနှောင့်နှေး (စက္ကန့်)",
					"chooseButton": "ကာတွန်း Choose"
				},
				"embeddedLinks": {
					"add": "link ကို Add",
					"title": "embedded လင့်များ"
				},
				"embeddedMedia": {
					"title": "embedded မီဒီယာ"
				},
				"header": {
					"title": "header",
					"description": "ပစ္စည်းအုပ်စုတွင်အထက်ပုံပေါ်"
				},
				"footer": {
					"title": "footer",
					"description": "ပစ္စည်းအုပ်စုအောက်တွင်ဖော်ပြထားသောပုံပေါ်"
				},
				"badge": {
					"completion": {
						"title": "ပြီးစီးစာသားကို",
						"description": "ပဟေဠိပြီးစီးအပေါ်အသုံးပြုသူမှပြသ"
					},
					"how": {
						"title": "ဘယ်လိုဝင်ငွေ",
						"description": "ဒီတံဆိပ်အောင်မြင်ရန်ဖို့ဘယ်လိုဖော်ပြသည့်စာသား"
					},
					"icon": "အိုင်ကွန်",
					"title": "တံဆိပ်"
				},
				"units": {
					"title": "ယူနစ်",
					"description": "စက္ကန့် / မီတာစသည်တို့ကို"
				},
				"range": {
					"increment": "increment",
					"length": "အရှည်",
					"title": "အကွာအဝေး",
					"start": "စတင်"
				},
				"completionText": {
					"title": "ပြီးစီးစာသားကို",
					"description": "မှန်ကန်စွာဖြေဆိုအပေါ်အသုံးပြုသူမှပြသ"
				},
				"failureText": {
					"title": "ပျက်ကွက်စာသားကို",
					"description": "မမှန်မကန်ဖြေဆိုအပေါ်အသုံးပြုသူမှပြသ"
				},
				"winThreshold": {
					"title": "ဝင်းတံခါးခုံကို (%)",
					"description": "အဆိုပါပဟေဠိရှောက်သွားရန်လိုအပ်သည်မှန်ကန်သောအဖြေကိုရာခိုင်နှုန်း"
				},
				"winMessage": {
					"title": "ဦးဝင်းသတင်းစကား",
					"description": "စမ်းသပ်ဖြတ်သန်းအပေါ်အသုံးပြုသူမှပြသ"
				},
				"loseMessage": {
					"title": "မက်ဆေ့ခ်ျကိုဆုံးရှုံး",
					"description": "စမ်းသပ်မှုပျက်ကွက်အပေါ်အသုံးပြုသူမှပြသ"
				},
				"shareMessage": {
					"title": "ဝေမျှမယ်သတင်းစကား",
					"description": "လူမှုရေးကွန်ရက်များပေါ်တွင်တံဆိပ်နှင့်အတူ shared ခံရဖို့ကိုစာသား"
				},
				"correctAnswer": "အဖြေမှန်",
				"standard": "စံ",
				"quiz": "စစ်ဆေးမေးမြန်းခြင်း",
				"quizzes": "ဉာဏ်စမ်းပဟေဠိ",
				"answer": "အဖွေ",
				"caption": "caption",
				"relatedLinks": "Related လင့်များ",
				"gridType": "grid အမျိုးအစား",
				"overlay": "overlay ကိုစာသား",
				"selectionLimit": "ရွေးချယ်ရေးန့်သတ်ချက်",
				"loseRelatedLinks": "ဆက်စပ်လင့်များဆုံးရှုံး",
				"button": "ကြယ်သီး",
				"placeholderImage": "Placeholder ပုံရိပ်",
				"textContent": "စာသားမအကြောင်းအရာများ",
				"columns": "ကော်လံ",
				"delay": "နှောင့်နှေး (s) ကို",
				"volatile": "မတည်ငြိမ်သော (ပြည်နယ်ဆက်လက်ရှိပါဘူး)",
				"initialPosition": "ကနဦးအနေအထား",
				"winRelatedLinks": "ဦးဝင်းဆက်စပ်လင့်များ",
				"confirmGridTypeChange": "သင်ဇယားကွက်ပုံစံကိုပြောင်းလဲချင်သင်သေချာပါသလား? ဒါကအားလုံးဇယားကွက်ပစ္စည်းများကိုပြောင်းလဲပစ်ပါလိမ့်မယ်။"
			},
			"areYouSure": "သေချာလား?",
			"addAnother": "အခြား Add",
			"confirmPageDeleteWarning": "သတိပေးချက်: သင်စာမျက်နှာအရာဝတ္ထုကိုမဖျက်ပစ်နေကြသည်။ အားလုံးစာမျက်နှာအကြောင်းအရာများကိုဖယ်ရှားပါလိမ့်မည်။ သငျသညျကိုဆက်လက်ချင်သင်သေချာပါသလား?",
			"confirmApproval": "သင်ဤအတည်ပြုချင်သင်သေချာပါသလား?",
			"paste": "ငါးပိ",
			"moveUp": "ထရွှေ့",
			"moveDown": "နှိမ့်ချ Move",
			"newType": "နယူးအမျိုးအစား",
			"copiedItem": "ကူးယူကို item",
			"changeType": "ပြောင်းလဲမှုအမျိုးအစား",
			"title": "Edit ကို Properties ကို",
			"copy": "မိတ္တူ",
			"confirmPageDelete": "စာမျက်နှာပယ်ဖျက်ခြင်းကိုအတည်ပြု",
			"addBulletItem": "ကျည်ဆံကို item Add",
			"copySuccess": "clipboard ထံကူးယူ object",
			"rootPageDelete": "ဤအပလီကေးရှင်းများအမြစ်စာမျက်နှာဖြစ်ပါသည်။ ဒါဟာဖျက်ပစ်လို့မရပါ။",
			"confirmDelete": "သင်ဤဒြပ်စင်သင်ဖျက်ချင်တာသေချာလား?"
		},
		"objectPreviews": {
			"TextListItem": "ႊဤ အုိက္တဲမ္ကုိ ေခါင္းျကီးဓါတ္ပံုရွင္းျပဦးဆံုးစာလံုးမ်ားအတြက္သံုးပါ၊ အမ်ားအားျဖင့္ စာမ်က္ႏွာထိပ္တြင္",
			"TitleListItem": "ႊဤ  အုိက္တဲမ္ကုိ တစ္ေၾကာင္းတည္းေသာ  တက္စ္စာ အတြက္သံုးပါ၊ ",
			"DescriptionListItem": {
				"title": "ႊဤ  အုိက္တဲမ္တြင္ ေခါင္းစဥ္ႏွင့္ ရွင္းလင္းေဖၚျပခ်က္ရွိသည္",
				"description": "ုိ ေျပာစရာ အမ်ားျကီးရွိေသာအခါ ဤ  အုိက္တဲမ္ကုိသံုးပါ"
			},
			"StandardListItem": {
				"title": "သာမန္အားျဖင့္   ဤအုိက္တဲမ္ကုိ သံုးပါ",
				"description": "ႊဤ  အုိက္တဲမ္တြင္ ေခါင္းစဥ္္၊ ရွင္းလင္းေဖၚျပခ်က္ ႏွင့့္ အုိက္ကြန္ရွိသည္။ အိုက္ကြန္ကဲ့သုိ ့ေသာ ဘစ္တ္စ္(bits)မ်ားကုိ ကြက္လပ္ထားခဲ့လည္းရသည္။ "
			},
			"ToggleableListItem": {
				"title": "အမ်ားမျမင္ေစလုိေသာအရာရွိလွ်င္  ယခုအုိက္တဲမ္ကုိ သံုးပါ။",
				"description": "ဤတက္စ္စာသားကုိ အသံုးျပဳသူမွ ေပၚေစ၊ ေပ်ာက္ေစ၊ (၀ွက္လုိက္္၊ ျပလုိက္)လုပ္ႏုိင္သည္။ အေသးစိတ္မ်ားလွ်င္ အသံုးတည့္သည္။ ဥပမာ- အေမးမ်ားေမးခြန္းမ်ား၊ (FAQ)။"
			},
			"OrderedListItem": {
				"title": "မွာျပီးစာရင္း",
				"description": "အေၾကာင္းအရာမ်ားကုိ နံပတ္စဥ္တပ္စါရင္း သံုးလုိလွ်င္ ဤဟာကုိသံုးပါ"
			},
			"UnorderedListItem": {
				"title": "မမွာေသာ စာရင္း",
				"description": "အေၾကာင္းအရာမ်ားကုိ စာရင္းလုပ္ထားလုိလွ်င္ ဤဟာကုိသံုးပါ"
			},
			"CheckableListItem": "ျပန္စစ္ စာရင္းအျဖစ္ ဤဟာကုိသံုးပါ ",
			"ButtonListItem": " ခလုတ္ (ဘတ္္တင္)လိုအပ္လွ်င္ ဤဟာကုိသံုးပါ",
			"HeaderListItem": {
				"title": "စာမ်က္နွာထိပ္ဆံုးတြင္ ဤဟာကုိသံုးပါ",
				"description": "ေခါင္းစီးႏွင့္ ရွင္းလင္းခ်က္ရွည္ ရွိႏုိင္သည္"
			},
			"LogoListItem": "အမွတ၊္တံဆိတ္လုိ ဟာမ်ိဳးတြင္ သံုးပါ၊ ပံု၊ ပံုရွင္းစာ(ကက္ပရွင္)၊ အျခားကြင္းဆက္ လုိခ်င္သည့္ေနရာ",
			"SpotlightListItem": {
				"first": "ပံု၊ စါကုိ လွည့္",
				"second": "စေပါ့တ္လုိက္( အာရံုျပဳမီးေမာင္းထုိး)"
			},
			"ChunkyListItemView": {
				"title": "ဤသည္ ပံုမွန္စာရင္းျဖစ္သည္၊ ပက္ဒင္ပုိမ်ားသည္။ ",
				"description": "ႊဤ  အုိက္တဲမ္တြင္ ေခါင္းစဥ္္ ရွင္းလင္းေဖၚျပခ်က္ ႏွင့့္ အုိက္ကြန္ရွိသည္။ အိုက္ကြန္ကဲ့သုိ ့ေသ ဘစ္တ္စ္(bits)မ်ားကုိ ကြက္လပ္ထားခဲ့လည္းရသည္။ "
			},
			"ListItemView": "ႊဤ  အုိက္တဲမ္တြင္ ေခါင္းစီးသာရွိသည္။"
		},
		"pageList": {
			"title": "စာမျက်နှာများ",
			"filter": "filter စာမကျြနှာ",
			"allPages": "အားလုံးစာမကျြနှာ"
		},
		"never": "ဘယ်တော့မှ",
		"pageName": "စာမျက်နှာအမည်ဖြင့်",
		"pageStyle": "စာမျက်နှာစတိုင်",
		"addLink": "link ကို Add",
		"addPage": "စာမျက်နှာပေါင်းထည့်ပါ",
		"pageType": "စာမျက်နှာအမျိုးအစား",
		"newFromTemplate": "template ကိုကနေနယူး",
		"lastSaved": "နောက်ဆုံးသိမ်းဆည်း:",
		"savesQueued": "queued ကယ်တင်",
		"title": "အယ်ဒီတာ",
		"saving": "သိမ်းဆည်းခြင်း ...",
		"addQuiz": "ပဟေဠိ Add",
		"addObject": "ဤနေရာတွင်အရာဝတ္ထု Add",
		"pageTag": "စာမျက်နှာ tag ကို",
		"addApp": "app ကို Add",
		"confirmRelock": "ဤသည်အရာဝတ္ထုပြီးသားသင်သော့ခတ်ထားပါသည်။ သငျသညျတဖနျသော့ခတ်လိုပါသလား သင်၏ယခင်သော့ခတ်ရုတ်သိမ်းပါလိမ့်မည်။",
		"confirmRequestNewLock": "ဒီစာမျက်နှာအဘို့သင့်သော့ခတ်ရုတ်သိမ်းခဲ့တာဖြစ်ပါတယ်။ သငျသညျသစ်တစ်ခုသော့ခတ်တောင်းဆိုရန်လိုပါသလား"
	},
	"mediaLibrary": {
		"chooseExisting": "လက္ရွိကုိ ေရြးခ်ယ္",
		"upload": {
			"instructions": "စတင္ရန္ ဤေနရာတြင္ ကလစ္လုပ္ပါ၊  သုိ ့မဟုတ္ဖုိင္ကုိ ဆြဲယူပါ။ ",
			"cropType": "ပံုကုိ ျဖတ္ယူျပင္ဆင္ရန္ အမ်ိဳးအစါး",
			"noCrop": "ပံုကုိ မ ျဖတ္ယူ မျပင္ဆင္",
			"square": "ံေလးေထာင့္ပံု",
			"squareDesc": "အုိက္ကြန္မ်ားႏွင့္ ပေဟဠိမ်ားအတြက္  ဤ ျဖတ္ယူပံုကုိ သံုးပါ။ ပေဟဠိပံုမ်ားသည္ <b>640(w)</b> by <b>640(h)</b> ပစ္ဇဲလ္ျဖစ္ရမည္။ ",
			"errors": {
				"tooManyFiles": "တစ္ခုတည္းေသာဖုိင္ကုိသာ ေရြးခ်ယ္ပါ"
			},
			"requiresTransparency": "ျဖတ္ထုိးျမင္ႏုိင္ေသာေနာက္ခံကားလုိအပ္သည္။",
			"tags": "Tags:",
			"newTag": "new tag",
			"title": "မီဒီယာ Upload လုပ်ပါ",
			"genericError": "file ကိုအပ်လုဒ်ကိုမအောင်မြင်ခဲ့ပေ။ အမှားကုဒ်:",
			"agreementText": "ဒီစနစ်အပ်လုဒ်တင်ပြီးအားလုံးအကြောင်းအရာ၏စည်းကမ်းချက်များမှဘာသာရပ်ဖြစ်ပါသည်",
			"formatError": "လွှတ်တင်ခြင်းပျက်ကွက်ခဲ့သည်။ မှားနေသောဖိုင် format နဲ့။ Allowed image ကိုကို formats: တွေဖြစ်တဲ့ JPEG, .png, Allowed ကဗီဒီယိုကို formats .gif: .mp4, .m4v",
			"gdpcHeaderDesc": "ဘေးအန္တရာယ် apps များအတွက်ကြီးမားသော header ကိုရုပ်တုဆင်းတုအဘို့ဤသီးနှံကိုသုံးပါ။ ပုံရိပ်တွေအရွယ်အစား <b>568 (ဇ)</b> pixels ကိုအားဖြင့် <b>640 (w)</b> ဖြစ်သင့်ပါတယ်။",
			"spotlightDesc": "မီးထိုး, အစွန်း, ဆလိုက်ရှိုးနှင့်ဗီဒီယိုရုပ်ပုံများကိုမှအစွန်းအဘို့ဤသီးနှံကိုသုံးပါ။ ပုံရိပ်တွေအရွယ်အစား <b>320 (ဇ)</b> pixels ကိုအားဖြင့် <b>640 (w)</b> ဖြစ်သင့်ပါတယ်။",
			"gdpcSpotlightDesc": ", ရှေးဦးသူနာပြု apps များအတွက်ခေါင်းစီးများအဘို့ဤသီးနှံသုံးပါလိုက်မီးထိုး, အစွန်း, ဆလိုက်ရှိုးနှင့်ဗီဒီယိုရုပ်ပုံများကိုမှအစွန်း။ ပုံရိပ်တွေအရွယ်အစား <b>320 (ဇ)</b> pixels ကိုအားဖြင့် <b>640 (w)</b> ဖြစ်သင့်ပါတယ်။",
			"headerDesc": "စာမျက်နှာရဲ့ထိပ်မှာပေါ်လာသည့်ကြီးမားသော header ကိုရုပ်တုဆင်းတုအဘို့ဤသီးနှံကိုသုံးပါ။ ပုံရိပ်တွေအရွယ်အစား <b>568 (ဇ)</b> pixels ကိုအားဖြင့် <b>640 (w)</b> ဖြစ်သင့်ပါတယ်။",
			"imageDesc": "<b>320 (ဇ)</b> pixels ကိုအားဖြင့် <b>640 (w)</b> ၏ပုံများပုံများ, ဆလိုက်ရှိုးပုံရိပ်တွေနဲ့ဗီဒီယိုရုပ်ပုံများကို EDGE မှအစွန်းအဘို့အကြံပြုထားတာဖြစ်ပါတယ်။ ပဟေဠိပုံရိပ်တွေ <b>640 (ဇ)</b> pixels ကိုအားဖြင့် <b>640 (w)</b> ဖြစ်သင့်ပါတယ်။",
			"selectType": "သင်တင်နေသောပုံရိပ်အမျိုးအစားကိုရွေးပါ ကျေးဇူးပြု.",
			"invalidTag": "မှားနေသော tags များ။ Tags: အဆိုပါသံခိတ် (*) သင်္ကေတကိုမဆံ့မခံနိုငျသညျ။",
			"filesizeError": "လွှတ်တင်ခြင်းပျက်ကွက်ခဲ့သည်။ ဖိုင်ကြီးလွန်းနေသည်။ အများဆုံးခွင့်ပြု FILESIZE ဖြစ်ပါသည်"
		},
		"image": "image ကို",
		"section": "အပိုင်း",
		"delete": "ဖျက်ပစ်ပါ",
		"society": "လူ့အဖွဲ့အစည်း",
		"title": "မီဒီယာစာကြည့်တိုက်",
		"video": "ဗီဒီယိုကို",
		"icon": "အိုင်ကွန်",
		"animation": "animation ရဲ့",
		"refine": "စာကြည့်တိုက်သနျ့",
		"clearFilters": "Clear ကို filter များ",
		"additionalTags": "အပိုဆောင်း tags များ",
		"editMedia": "Edit ကိုမီဒီယာ",
		"confirmTagRemoval": "သင်ဤ tag ကိုဖယ်ရှားလိုတာသေချာလား?",
		"loadMore": "ဝန်ကပို",
		"dragToReorder": "ပြန်စီရန် drag",
		"commas": "ကော်မာနှင့်အတူသီးခြား",
		"type": "မီဒီယာအမျိုးအစား",
		"general": "ယေဘုယျ",
		"filterByTags": "tags များအလိုက်စစ်ထုတ်ရန်"
	},
	"viewPicker": {
		"TextListItem": "စာစုမ်ားအၾကား တစ္ဖက္မွ တစ္ဖက္စာမ်ား",
		"TitleListItem": "တက္စ္စာ တစ္ေၾကာင္းတည္းသာ",
		"StandardListItem": "ေခါင္းစဥ္္၊ လင့္ခ္ ႏွင့့္ အုိက္ကြန္ ",
		"DescriptionListItem": "ေခါင္းစဥ္၊ စာေၾကာင္းမ်ားစြာပါ ရွင္းလင္းခ်က္တက္စ္",
		"ToggleableListItem": "ကလစ္လုပ္ရႏုိင္ေသာ ပုိမုိရွည္လ်ားေသာ ေခါင္းစဥ္",
		"OrderedListItem": "အမွတ္သေကၤတပါရွင္းလင္းခ်က္ သုိ ့မဟုတ္ အဆင့္နံပါတ္ပါေသာ ေခါင္းစဥ္္၊ စာေၾကာင္းမ်ားစြာပါတက္စ္စာသား၊  ",
		"UnorderedListItem": "ေခါင္းစီးႏွင့္ ရွင္းလင္းခ်က္ပါ က်ည္မွတ္စာရင္း",
		"CheckableListItem": "ေခါင္းစဥ္ႏွင့္ ျပန္စစ္စာရင္းတုိ။ အသံုးျပဳသူမ်ားအၾကား အေျခအေနၾကာရွည္နို္င္သည္။",
		"ButtonListItem": "ေခါင္းစဥ္ႏွင့္ ခလုတ္(ဘတ္္တင္)။",
		"ImageListItem": "အစြန္းႏွစ္ဖက္အၾကား ရုပ္ပံုတစ္ပံုတည္း",
		"HeaderListItem": "ေခါင္းစဥ္ႏွင္ ့ရွင္းလင္းခ်က္မ်ား ပံုေပၚပါရွိျပိး ပံုကားျကီး။",
		"VideoListItem": "ဇစ္ျမစ္မ်ားစြာကုိ ပံ့ပုိးေသာ ဗြီဒီုယုိ ေအာ့ဗဂ်က္",
		"AnimatedImageListItem": "အသက္၀င္လွုပ္ရွားေစေသာ ဆလုိက္ရွိဳးပံုမ်ားအျဖစ္ဆက္တုိက္ျပသသည့္ ပံုမ်ား",
		"SpotlightImageListItem": "ပုံကား၊ ကက္ပရွင္ႏွင့္ ဆက္သြယ္မွတ္ပါေသာ ဆက္တုိက္ စေပါ့တ္လုိက္ပံုမ်ား",
		"LogoListItem": "ကုမၻဏီတံဆိပ္ ျပသရန္ သံုးေလ့ရွိသည္။",
		"SliderQuizItem": "ရုပ္ပံုဆလုိက္ဒါကား ေရြးခ်ယ္ရန္ ေမးခြန္း ",
		"TextQuizItem": "တက္စ္ေရြးခ်ယ္ရန္ေမးခြန္း",
		"ImageQuizItem": "ပံုေရြးခ်ယ္ရန္ေမးခြန္း",
		"AreaQuizItem": "ေဒသေရြး ေမးခြန္း",
		"CollectionListItem": "အက္ပ္မ်ား၊ တံဆိပ္မ်ားႏွင့္ ကြင္းဆက္မ်ားအတြက္ - အားလံုးစုယူ- ျမင္ကြင္း၊ ",
		"spotlight": "Spotlight ကို",
		"leadInText": "ဦးဆောင်လမ်းပြ-in ကိုစာသားမ",
		"dragAndDrop": "စာမကျြနှာမှသူတို့ကိုထည့်သွင်းဖို့အရာဝတ္ထု drag and drop",
		"mediaObjects": "မီဒီယာတ္ထု",
		"interactiveObjects": "interactive တ္ထု",
		"filter": "filter တ္ထု",
		"subText": "sub-စာသားကို",
		"title": "အရာဝတ္ထု",
		"text": "စာသားမ",
		"basicObjects": "အခြေခံပညာတ္ထု",
		"multiLineObjects": "multi-line ကိုအရာဝတ္ထု",
		"hiddenText": "hidden စာသားကို"
	},
	"users": {
		"removeUserConfirm": "ႊဤ အသံုးျပဳသူကုိ ပယ္ဖ်က္ရန္ေသခ်ာလား",
		"failedToRemove": "အသံုးျပဳသူကုိ ပယ္ဖ်က္ရန္ မေအာင္ျမင္",
		"lastName": "မျိုးနွယ်အမည်",
		"name": "အမည်",
		"firstName": "နာမည်",
		"title": "အသုံးပြုသူများသည်",
		"role": "အခန်းက္ပ",
		"apps": "Apps ကပ",
		"roles": "အခန်းကဏ္ဍ",
		"organisation": "အဖှဲ့အစညျး",
		"list": "အသုံးပြုသူများအများစာရင်း",
		"complete": "အသုံးပြုသူကိုအောင်မြင်စွာဖန်တီး",
		"usernameHelp": "usernames သာအက္ခရာများ, နံပါတ်များ, underscores, ဒက်ရှ်နှင့်အစက်င်နိုင်ပါသည်။",
		"success": "အောင်မြင်ပါသည်! အဆိုပါအသုံးပြုသူအသစ်၎င်းတို့၏အကောင့်ကိုသက်ဝင်စေဖို့ညွှန်ကြားချက်နှင့်အတူအီးမေးလ်တစ်စောင်လက်ခံရရှိလိမ့်မည်။",
		"error": "အသုံးပြုသူဖန်တီးမပေးနိုင်ခဲ့ပါ။ အဆိုပါ username နှင့်အီးမေးလ်ကို Check ပြီးသားမှတ်ပုံတင်ပြီးကြပြီမဟုတ်။",
		"createUser": "အသုံးပြုသူကိုဖန်တီး",
		"lastActive": "နောက်ဆုံးတက်ကြွစွာ"
	},
	"userApps": {
		"title": "အသံုးျပဳသူ၏ အက္ပ္မ်ား"
	},
	"push": {
		"messageDescription": "ပြတ္ရွ္ ႏုိတီဖီေကးရွင္း မက္ဆစ္ဂ်္",
		"categories": {
			"region": "တုိင္း၊ ေဒသ",
			"custom": "ထုံးစံဓလေ့",
			"standard": "စံ",
			"future": "အနာဂတ်"
		},
		"custom": {
			"area": "ပစ္မွတ္ေဒသ",
			"areaHelp": "ဤအသိေပးအတြက္ ပစ္မွတ္ေဒသ သတ္မွတ္ရန္ ေျမပံုကုိ ကလစ္လုပ္ပါ။"
		},
		"error": {
			"length": "ပြတ္ရွ္ ႏုိတီဖီေကးရွင္း မက္ဆစ္ဂ်္ ရွည္လြန္း",
			"messageEmpty": "မက္ဆစ္ဂ်္ ေရးသြင္းပါ",
			"missingRegion": "ေဒသ ေရြးခ်ယ္ပါ"
		},
		"what": "ဘာကုိ ပုိ ့ခ်င္တာလဲ",
		"when": "ဘယ္ေတာ့ ပုိ ့ခ်င္တာလဲ",
		"addToQueue": "တန္းစီစရင္းတြင္ထည့္ပါ",
		"types": {
			"default": "ပျက်ကွက်"
		},
		"priorities": {
			"high": "မြင့်သော",
			"low": "အနိမ့်"
		},
		"type": "ပုံစံ",
		"approve": "အတည်ပြု",
		"message": "မက်ဆေ့ခ်ျကို",
		"createdBy": "မှဖန်တီးသည်",
		"reject": "ပယ်ချ",
		"numberCompleted": "ပြီးစီး",
		"sending": "ပေးပို့ခြင်း",
		"assignments": "တာဝန်ကျ",
		"payload": "payload",
		"noNotifications": "အဘယ်သူမျှမဆိုင်ရာအသိပေးချက်များ",
		"notes": "မှတ်စုများ",
		"category": "အမျိုးအစား",
		"numberSent": "စုစုပေါငျး",
		"priority": "ဦးစားပေး",
		"pushDetails": "သတိပေးချက်အသေးစိတ်အချက်အလက်",
		"sent": "push ကိုစလှေတျတျော",
		"editPush": "Edit ကိုတွန်းအားပေးသတိပေးချက်",
		"showAllApps": "အားလုံး apps များပြရန်",
		"existing": "တည်ဆဲတွန်းအားပေးဆိုင်ရာအသိပေးချက်များ",
		"noPageSpecified": "စာမျက်နှာဖော်ပြထားခြင်းမရှိပါ",
		"saveAndSend": "Save နှင့်ပေးပို့"
	},
	"hazards": {
		"title": "ေဘးရန္မ်ား",
		"disasters": {
			"categories": {
				"coastal": "ကမ်းရိုးတန်း",
				"volcano": "မီးတောင်",
				"heatwave": "Heatwave",
				"winter": "ဆောင်းရာသီ",
				"wildfire": "Wildfire",
				"flood": "ရေကြီးခြင်း",
				"earthquake": "ငလျင်",
				"storm": "မုန်တိုင်း",
				"nuclear": "နြူကလီးယား",
				"hurricane": "လေပြင်းမုန်တိုင်း",
				"tsunami": "ဆူနာမီ",
				"wind": "လေတိုက်",
				"tornado": "လပှေ"
			},
			"tutorial": {
				"heading": "မတူညီသောပြည်နယ်များအဘယ်သို့ဆိုလိုသနည်း",
				"body": "- Enabled သင်ကအလုပ်လုပ်စတင်ရန်များအတွက်စာမျက်နှာအကြောင်းအရာအယ်ဒီတာထဲမှာပေါ်လာလိမ့်မယ်ဆိုလိုသည်။ <br> - Dev မှစာမကျြနှာကဒီ app ကိုအဘို့တည်ဆောက်ဖွံ့ဖြိုးတိုးတက်မှုအတွက်ပေါ်လာပါလိမ့်မည်ကိုဆိုလိုသည်။ <br> - တိုက်ရိုက်စာမျက်နှာဒီ app များအတွက်တည်ဆောက် app ကို store မှာပေါ်လာပါလိမ့်မယ်ဆိုလိုသည်။"
			},
			"disable": "disable",
			"title": "သဘာဝဘေးအန္တရာယ်",
			"rootPage": "အမြစ်စာမျက်နှာ",
			"enable": "Enable",
			"configure": "configure",
			"alertSettings": "အချက်ပေး settings ကို",
			"whatNow": "'' ယခုအဘယ်အရာကို '' စာမျက်နှာတွင်",
			"pleaseSpecifyPages": "တစ်ဦးချင်းစီကိုရှေးခယျြဘေးအန္တရာယ်အမျိုးအစားများအတွက်စာမျက်နှာကိုသတ်မှတ်ပေးပါ။",
			"saveError": "app ကို configuration ကိုကယ်ဖို့ရန်မအောင်မြင်ပါ။",
			"saveSuccess": "App ကို configuration ကိုကယ်လွှတ်တော်မူ၏။"
		},
		"alerts": {
			"times": {
				"month": "လ",
				"day": "နေ့",
				"week": "ရက်သတ္တပတ်",
				"hour": "နာရီ",
				"allTime": "အားလုံးအချိန်"
			},
			"custom": {
				"types": {
					"announcement": "ကြေညာချက်",
					"announcementDesc": "ကြေညာချက်များကအခြားတပ်လှန့်ပေးသူထံမှထောက်ပံ့ပေးကြသည်မဟုတ်ကြောင်းဖြစ်ရပ်များအတွက်သတိပေးချက်များကိုဖန်တီးရန်အသုံးပြုကြသည်။",
					"pinDesc": "Pinned စာမျက်နှာများကိုအချိန်နှင့်တည်နေရာအထိခိုက်မခံသောအရေးကြီးသောစာမကျြနှာကိုထူးခြားစေမည့်အသုံးပြုကြသည်။",
					"pin": "Pinned စာမျက်နှာ"
				},
				"errors": {
					"effectiveWrong": "ထိရောက်သောနေ့စွဲသက်တမ်းကုန်ဆုံးသည့်ရက်စွဲပြီးနောက်မဖွစျနိုငျ",
					"pleaseProvideEvent": "ဒီကြေညာချက်အဘို့ဖြစ်ရပ်တစ်ခုသည်ပေးပါ။",
					"pleaseProvideHeadline": "ဒီကြေငြာချက်တစ်ခုခေါင်းစဉ်များကိုပေးပါ။",
					"pleaseSelectRegion": "ဒီသတိပေးချက်များအတွက်ဒေသရွေးချယ်ပါ။",
					"pleaseProvideAreaDescription": "ဒီကြေငြာချက်တစ်ခုဧရိယာဖော်ပြချက်သည်ပေးပါ။",
					"pleaseProvideMessage": "ဒီကြေငြာချက်တစ်ခုသတင်းစကားပေးပေးပါ။",
					"pleaseSelectPage": "Taskbar မှာအရင်ကမရှိသေးတဲ့ခံရဖို့စာမျက်နှာရွေးချယ်ပါ။"
				},
				"expiry": "သက်တမ်းကုန်ဆုံးရက်",
				"updateMap": "Update ကိုမြေပုံ",
				"area": "အချက်ပေးဧရိယာ",
				"showPolyData": "အနားဒေတာပြရန်",
				"pinPage": "featured ခံရဖို့စာမျက်နှာ။",
				"polygon": "ဤနေရာတွင်ထုံးစံအနား data တွေကိုထည့်ပါ။",
				"onsetHelp": "အဆိုပါရက်စွဲသည်အန္တရာယ်ဖြစ်ပျက်ပါလိမ့်မယ်။",
				"languageHelp": "ဘာသာစကားဟာတပ်လှန့်၌ရေးထားလျက်ရှိ၏ဖြစ်ပါတယ်။",
				"expiryHelp": "ရက်စွဲကဒီတပ်လှန့်များကိုဖယ်ရှားပါလိမ့်မည်။",
				"onset": "စတင်ခြင်းနေ့စွဲ",
				"severity": "ပြင်းထန်မှု",
				"cap_create": "CAP",
				"pushes": "push",
				"cap_send": "တစ်ဦး CAP သတိပေးချက် Generate",
				"severityHelp": "ဒီသတိပေးချက်ကဘယ်လိုအရေးကြီးသလဲ",
				"notify": "ကြားသိစေ",
				"message": "ဒီသတိပေးချက်နဲ့အတူသဝဏ်လွှာကို။",
				"alertQueued": "alert ကိုစလှေတျခံရဖို့တန်းစီ။",
				"sendNotification": "ဤအသုံးပြုသူတစ်ဦးတွန်းအားပေးအကြောင်းကြားစာပေးပို့ပါ။",
				"effectiveHelp": "ရက်စွဲကဒီတပ်လှန့်ရာမှထိရောက်မှုရှိလိမ့်မည်။",
				"drawRegion": "ဒီသတိပေးချက်ဟာဒေသများန်းကျင်ကိုခပ်စလှေတျတျောရပါမည်",
				"announcementPage": "စာမကျြနှာမှာ \"ယခုအဘယ်အရာကို\" အပိုင်းထဲမှာဖော်ပြပါတယ်။",
				"areaHelp": "အဆိုပါသတိပေးချက်ရွေးချယ်ထားသောဧရိယာအတွင်းမှာတည်နေရာတစ်ခုစောင့်ကြည့်အားလုံးအသုံးပြုသူများကိုစလှေတျပါလိမ့်မည်။",
				"effective": "ထိရောက်သောနေ့စွဲ"
			},
			"issuedBy": "မှထုတ်ပြန်သည်",
			"pinned": "Pinned",
			"effective": "ထိရောက်သော",
			"show": "ပြသ",
			"title": "သတိပေးချက်များ",
			"expired": "Expired",
			"noAlerts": "အဘယ်သူမျှမသတိပေးချက်",
			"createNewAlert": "အသစ်ကတပ်လှန့် Create",
			"specifySinglePolygon": "အကွိမျမြားစှာအနားသတိပေးချက်အဘို့ထောက်ခံကြသည်မဟုတ်။ တစ်ခုတည်းအနားဆွဲပေးပါ။",
			"allAlerts": "အားလုံးသတိပေးချက်",
			"inTheLast": "ပြီးခဲ့သည့်အတွက်",
			"alertActive": "တက်ကြွသတိပေး",
			"alert": "သတိပေး",
			"pin": "တွယ်အပ်",
			"testAlertDesc": "ဤစစ်ဆေးမှုကတပ်လှန့်အန္တာတိကတစ်ဦးတည်နေရာစောင့်ကြည့်အားလုံးအသုံးပြုသူများကိုစလှေတျပါလိမ့်မည်။",
			"testAlert": "စမ်းသပ်မှုသတိပေးချက် Create"
		},
		"generalNotifications": {
			"customMapData": {
				"moreCoords": "ထက် ပို. သုံးကိုသြဒီနိတ်များကို ကျေးဇူးပြု.",
				"firstAndLast": "ပထမနှင့်နောက်ဆုံးသြဒီနိတ်အတူတူပင်ဖြစ်သင့်သည်",
				"notFound": "အနားဒေတာတစ်ခုမှမတွေ့သုံးခုကိုသြဒီနိတ်၏အနိမ့်ဆုံးကိုထည့်သွင်းပါ။ ဆိုလိုသည်မှာ [လောင်ဂျီတွဒ်, လတ္တီ], [လောင်ဂျီတွဒ်, လတ္တီ], [လောင်ဂျီတွဒ်, လတ္တီ]",
				"fisrtFoundNotLast": "ပထမဦးစွာညှိနှိုင်းမှသာပထမဦးဆုံးသြဒီနိတ်အတွင်းတွေ့ရှိခဲ့ခြင်းနှင့်နောက်ဆုံးသြဒီနိတ်အတူတူပင်ဖြစ်သင့်သည်",
				"placeholder": "တစ်ဦးအနားကိုဖန်တီးရန်အထက်မြေပုံပေါ်တွင် Click သို့မဟုတ်ကဒီမှာသုံးကိုသြဒီနိတ်၏အနိမ့်ဆုံးထည့်ပါ။ ဆိုလိုသည်မှာ [လောင်ဂျီတွဒ်, လတ္တီ], [လောင်ဂျီတွဒ်, လတ္တီ], [လောင်ဂျီတွဒ်, လတ္တီ]"
			},
			"dropdown": {
				"health": "ကျန်းမာရေး",
				"cancel": "Cancel",
				"other": "အခြား",
				"expected": "မျှော်လင့်ထားသည့်",
				"immediate": "လက်ငင်း",
				"test": "စမ်းသပ်",
				"future": "အနာဂတ်",
				"safety": "လုံခွုံမှု",
				"alert": "သတိပေး",
				"moderate": "မနည်းမများသော",
				"error": "အမှား",
				"possible": "ဖြစ်နိုင်သော",
				"likely": "ဖွယ်ရှိ",
				"unlikely": "မဖြစ်တန်ရာ",
				"public": "ပြည်သူ့",
				"fire": "မီး",
				"actual": "အမှန်တကယ်",
				"transport": "တင်ဆောင်",
				"update": "Update ကို",
				"unknown": "အမည်မသိ",
				"minor": "မိုင်းနား",
				"draft": "မူကြမ်း",
				"rescue": "ကယ်ဆယ်",
				"severe": "ပြင်းထန်",
				"security": "လုံခွုံရေး",
				"restricted": "ကန့်သတ်",
				"observed": "လေ့လာသူများ",
				"extreme": "ပြင်းထန်သော",
				"private": "ကိုယ်ပိုင်",
				"past": "အတိတ်",
				"system": "စံနစ်"
			},
			"urgency": "အရေးတကြီး",
			"status": "status",
			"type": "ပုံစံ",
			"certainty": "မုချဖြစ်မှု",
			"event": "အဖြစ်အပျက်",
			"title": "အထွေထွေအသိပေးချက်များ",
			"area_description": "ဧရိယာဖော်ပြချက်",
			"createNotification": "သတိပေးချက် Create",
			"severity": "ပြင်းထန်မှု",
			"headline": "ခေါင်းစဉ်",
			"category": "အမျိုးအစား",
			"scope": "scope"
		},
		"alertZones": {
			"name": "အမည်",
			"type": "ပုံစံ",
			"area": "ဧရိယာ",
			"events": "ဖြစ်ရပ်များ",
			"whitelist": "whitelist",
			"title": "အချက်ပေးဇုန်",
			"blacklist": "အမည်ပျက်စာရင်း",
			"addAlertZone": "အချက်ပေးဇုန် Add",
			"areaSelectModalTitle": "အဆိုပါဒေသများန်းကျင်ကိုခပ်ဒီဇုန်သက်ဆိုင်",
			"editAlertZone": "Edit ကိုအချက်ပေးဇုန်"
		},
		"feeds": {
			"tutorial": {
				"body": "တချို့ကသတိပေးချက်အမျိုးအစားများမျိုးစုံတပ်လှန့်ရင်းမြစ်များမှလာနိုငျသညျ။ ဤအပိုင်းကိုသငျသညျဒီ app များအတွက်တစ်ဦးချင်းစီ feeds တွေကိုထံမှတိကျသော Feeds နဲ့ဖြစ်ရပ်အမျိုးအစားတွေကို enable သို့မဟုတ် disable လုပ်ဖို့ခွင့်ပြုပါတယ်။",
				"steps": "<ol><li> ရွေးကောက်တော်မူသောဘေးအန္တရာယ်အမျိုးအစားများနှင့်အမျိုးအစားကိုသေချာဒီ app များအတွက် enabled နေကြသည်။ ဤသည် feeds နှင့်ဖြစ်ရပ်များကိုအောက်တွင် enabled စေခြင်းငှါအရာဆုံးဖြတ်ရန်ပါလိမ့်မယ်။ </li><li> သငျသညျဒီ app များအတွက်သတိပေးချက်များအထောက်အကူပြုရန်ဆန္ဒရှိသည့် Feeds နဲ့ဖြစ်ရပ်အမျိုးအစားများကိုရွေးချယ်ပါ။ </li></ol>",
				"heading": "ထည့်သွင်းမှုများအသုံးပြုခြင်း"
			},
			"delete": {
				"confirm": "သေချာလား?",
				"events": "အားလုံး enabled ဖြစ်ရပ်များဤ feed အတွက်ပိတ်ထားပါလိမ့်မည်။",
				"yes": "ဟုတ်ကဲ့, delete"
			},
			"code": "ကုဒ်",
			"type": "ပုံစံ",
			"events": "ဖြစ်ရပ်များ",
			"disable": "disable",
			"enable": "Enable",
			"feed": "အစာ",
			"languages": "ရရှိနိုင်ဘာသာစကားများ",
			"scope": "scope",
			"configure": "configure",
			"title": "feeds"
		}
	},
	"appCreate": {
		"createNewOrganization": "အဖြဲ ့အစည္းသစ္ဖြဲ ့",
		"emptyApp": "အချည်းနှီးသော app ကို",
		"copyFrom": "ကနေ Copy ကူးအကြောင်းအရာ",
		"details": "App ကိုအသေးစိတျ",
		"title": "app သစ်တစ်ခုကိုဖန်တီး"
	},
	"manageApps": {
		"title": "အက္ပ္မ်ားကုိင္တြယ္",
		"yourNextApp": "သင္၏ေနာက္တဆင့္ အက္ပ္",
		"easilySetUpYourNextApp": "သင္၏ေနာက္တဆင့္ အက္ပ္ကုိ လြယ္ကူစြာ တပ္ဆင္ပါ"
	},
	"dashboard": {
		"title": "ဒက္ရွဘုတ္"
	},
	"manage": {
		"title": "ကုိင္တြယ္"
	},
	"nav": {
		"items": {
			"appSetup": {
				"description": "လျှောက်လွှာအမည်, အိုင်ကွန်, စာသားပြည်တွင်းနှင့် App Store ကိုအင်ဖို configure",
				"title": "App ကို Setup ကို"
			},
			"content": {
				"title": "အကြောင်းအရာ",
				"description": "In-app ကိုအကြောင်းအရာ Edit ကို"
			},
			"publish": {
				"title": "ပုံနှိပ်ထုတ်ဝေ",
				"description": "အဆိုပါ dev သို့မဟုတ်တိုက်ရိုက်ထုတ်လွှတည်ဆောက်ပြောင်းလဲမှုများ Publish"
			},
			"stats": {
				"title": "stats",
				"description": "app ကိုလုပ်နေတယ်ဘယ်လိုကြည့်ရှုပါ"
			},
			"userAgreement": {
				"title": "အသုံးပြုသူသဘောတူညီချက်",
				"description": "က Universal App ကိုအစီအစဉ်သဘောတူညီချက်ကြည့်ရန်နှင့်လက်မှတ်ထိုး"
			},
			"users": {
				"title": "အသုံးပြုသူများသည်",
				"description": "အားလုံးအသုံးပြုသူများကိုစီမံခန့်ခွဲရန်"
			},
			"stories": {
				"title": "ပုံပြင်",
				"description": "ပုံပြင်များကိုစီမံခန့်ခွဲ"
			},
			"levels": {
				"title": "အဆင့်ဆင့်",
				"description": "app ကိုတိုးတက်မှုအဆင့်ဆင့်ကိုစီမံခန့်ခွဲ"
			},
			"badges": {
				"title": "တံဆိပ်များ",
				"description": "app ထဲမှာလှုပ်ရှားမှုများပြီးပါကများအတွက်ချီးမြှင့်တံဆိပ်များကိုစီမံခန့်ခွဲ"
			},
			"push": {
				"title": "အသိပေးချက်များ",
				"description": "စီမံခန့်ခွဲရန်နှင့်သုံးစွဲသူများမှတွန်းအားပေးအကြောင်းကြားစာများပေးပို့"
			},
			"sprint": {
				"title": "status ဘုတ်အဖွဲ့",
				"description": "လက်ရှိဖွံ့ဖြိုးတိုးတက်မှုဆိုင်ရာ Sprint အခြေအနေကိုကြည့်"
			},
			"bugs": {
				"title": "bug အစီရင်ခံ",
				"description": "အယ်ဒီတာသို့မဟုတ် app ကိုတည်ဆောက်အတူအစီရင်ခံစာဆိုင်ရာကိစ္စရပ်များ"
			},
			"gdpcConsole": {
				"title": "NS Console",
				"description": "ကြည့်ရန်အမျိုးသားအသင်းနှင့် Dev မှရေကူးကန် status အ"
			},
			"hazards": {
				"description": "ဦးစားပေး, ဘေးအန္တရာယ်စီမံခန့်ခွဲမှု, သတိပေးချက်များနှင့်ဖြည့်ညှင်း",
				"title": "သဘာဝဘေးအန္တရာယ်များနှင့်သတိပေးချက်များ"
			},
			"decisionTree": {
				"title": "ဆုံးဖြတ်ချက်သစ်ပင်",
				"description": "app ကိုများအတွက်ဆုံးဖြတ်ချက်သစ်ပင်ကြည့်ရန်နှင့်တည်းဖြတ်"
			},
			"admin": {
				"description": "ကြည့်ရန်စာရင်းအင်း, ပြင်ဆင်ရန်အသုံးပြုသူများနှင့်အဖွဲ့အစည်းအသေးစိတျ",
				"title": "အဒ်မင်"
			}
		},
		"navigate": "လမ်းကြောင်း",
		"logout": "အထဲက Log"
	},
	"publish": {
		"title": "ပုံနှိပ်ထုတ်ဝေ",
		"time": "အချိန်",
		"details": "အသေးစိတ်",
		"user": "အသုံးပြုသူကို",
		"live": "အသက်ရှင်သော",
		"noChanges": "အပြောင်းအလဲမရှိပါ",
		"history": "သမိုင်း",
		"comment": "မှတ်ချက်",
		"detail": "အသေးစိတ်",
		"publishType": "အမျိုးအစား Publish",
		"publishToDev": "dev မှ Publish",
		"test": "စမ်းသပ်",
		"objectType": "object type ကို",
		"selectAll": "အားလုံးကို Select လုပ်ပါ",
		"publishLandmark": "မှတ်တိုင် Publish?",
		"endTime": "အဆုံးအချိန်",
		"success": "အောင်မြင်သော Publish ။",
		"publishing": "ထုတ်ဝေခြင်း ...",
		"startTime": "အချိန် Start",
		"lastModified": "နောက်ဆုံးပြင်ဆင်ခဲ့သည်",
		"fail": "မအောင်မြင် Publish ။",
		"progress3": "ဒီခဏယူနဲ့တူလှပါတယ်။ သငျသညျနောက်မှပြန်လာနိုင်ပါတယ်။",
		"error": "ထုတ်ဝေရန်တဦးတည်းသို့မဟုတ်ထိုထက်ပိုသောစာမျက်နှာများကို select ပေးပါ။",
		"pleaseConfirm": "သင်တို့ရှိသမျှသည်အပြောင်းအလဲများကိုပြန်လည်သုံးသပ်ကြပါပြီအတည်ပြုပေးရန် checkbox ကိုစစ်ဆေးပါ။",
		"publishConfirm": "ငါအားလုံးမရွေးအပြောင်းအလဲများကိုပြန်လည်သုံးသပ်ကြပါပြီအတည်ပြုခြင်းနှင့်တိုက်ရိုက်သူတို့ကိုတွန်းအားပေးရန်ခွင့်ပြုချက်ရှိ",
		"warning2": "နှိုက်နှိုက်ချွတ်ချွတ်ဆက်လက်အလုံးစုံတို့ကိုရှေ့တော်၌အပြောင်းအလဲများပြန်လည်သုံးသပ်ရန်နှင့်စာမျက်နှာ၏အောက်ခြေတွင်သင့်ရဲ့ရွေးချယ်မှုကိုအတည်ပြုပေးပါ။",
		"auditTrail": "စာရင်းစစ်လမ်းကြောင်း",
		"warning1": "သင်တစ်ဦးမြစ်ဝကျွန်းပေါ် update ကိုမှတဆင့် iOS နဲ့ Android applications များမှထုတ်ဝေခံရဖို့အောက်ကစာမကျြနှာကိုရွေးချယ်ခဲ့ကြသည်။",
		"toApps": "apps များရန် Publish",
		"publishToLive": "အသက်ရှင်ရန် Publish",
		"progress2": "ဒါဟာခဏတစ်ယူနိုငျသညျ။ ဒီစာမျက်နှာကနေထွက်သွား navigate မထားပါနဲ့။",
		"progress1": "သင်ရွေးချယ်ထားသောပြောင်းလဲမှုများကိုယခုထုတ်ဝေလျက်ရှိသည်။"
	},
	"publishHistory": {
		"previousPublishes": "ယခင်ထုတ်ဝေ",
		"title": "သမိုင်း Publish"
	},
	"agreement": {
		"email": "အီးမေးလိပ်စာ",
		"fullName": "နာမည်အပြည့်အစုံ",
		"signedBy": "ကလက်မှတ်ရေးထိုး",
		"date": "နေ့စှဲ",
		"status": "status",
		"sign": "အမှတ်လက္ခဏာ",
		"nationalSociety": "အမျိုးသားလူ့အဖွဲ့အစည်း",
		"notSigned": "လက်မှတ်မထိုးရ",
		"signed": "လက်မှတ်ရေးထိုးခဲ့",
		"daysRemaining": "__count__ နေ့ကကျန်ရှိသော",
		"signAgreement": "သင်္ကေတပြသဘောတူညီချက်",
		"daysRemaining_plural": "__count__ ရက်ပေါင်းကျန်ရှိနေသေးသော",
		"checkToAgree": "သင်ဖတ်ကြပါပြီအတည်ပြုခြင်းနှင့်စည်းကမ်းချက်များကိုသဘောတူရန်အကွက်ကိုစစ်ဆေးပါ။",
		"viewThe": "အဆိုပါ View",
		"details": "သဘောတူညီချက်အသေးစိတ်အချက်အလက်",
		"iAgree": "ငါ၌ပေးသောစည်းကမ်းချက်များရန်သဘောတူ",
		"hazardsTitle": "universal ဇက်လျှောက်လွှာအစီအစဉ်သဘောတူညီချက်",
		"title": "universal App ကိုအစီအစဉ်သဘောတူညီချက်"
	},
	"roles": {
		"permissions": "permissions",
		"title": "အသုံးပြုသူအခန်းကဏ္ဍ",
		"list": "အခန်းကဏ္ဍများစာရင်း"
	},
	"permissions": {
		"none": "အဘယ်သူမျှမ",
		"read": "ဖတ်",
		"write": "ရေးသား",
		"delete": "ရေးထား / ဖျက်ပစ်ပါ",
		"title": "အခန်းကဏ္ဍခွင့်ပြုချက်",
		"edit": "Edit ကိုခွင့်ပြုချက်"
	},
	"apps": {
		"app": "App ကို",
		"assign": "သတ်မှတ်",
		"title": "app များကို assign",
		"subtitle": "အသုံးပြုသူမှ apps များ assign",
		"assignNew": "app သစ် assign"
	},
	"stories": {
		"title": "ပုံပြင်",
		"content": "အကြောင်းအရာ",
		"subtitle": "ခေါင်းစဉ်ကလေး",
		"created": "Created",
		"editStory": "Edit ကိုပုံပြင်",
		"list": "ပုံပြင်စာရင်း"
	},
	"badges": {
		"title": "တံဆိပ်များ",
		"list": "တံဆိပ်များများစာရင်း",
		"editBadge": "Edit ကိုတံဆိပ်"
	},
	"crossBorders": {
		"link": {
			"title": "ခေါင်းစဥ်",
			"destination": "ခရီးလမ်းဆုံး",
			"placeholder": "destination ခေါင်းစဉ်"
		},
		"contact": {
			"title": "ခေါင်းစဥ်",
			"number": "ဖုန်းနံပါတ်",
			"editItem": "Edit ကိုဆက်သွယ်ပါ"
		},
		"phrase": {
			"translation": "ဘာသာပြန်ချက်",
			"phrase": "ထားသောစာပိုဒ်တိုများ",
			"editItem": "Edit ကို Phrase"
		},
		"country": {
			"code": "နိုင်ငံကုဒ်",
			"description": "ဦးတည်ရာတိုင်းပြည်",
			"name": "တိုင်းပြည်အမည်"
		},
		"phrases": "စာပိုဒ်တိုများ",
		"contacts": "ဆက်သွယ်ရန်",
		"title": "လက်ဝါးကပ်တိုင်နယ်ခြားမဲ့",
		"links": "links",
		"editItem": "Edit ကိုကံကြမ္မာနိုင်ငံ",
		"list": "ခရီးစဉ်များစာရင်း",
		"translations": "ထားသောစာပိုဒ်တိုများဘာသာပြန်ချက်များ"
	},
	"levels": {
		"title": "အဆင့်ဆင့်",
		"details": "level အသေးစိတ်ကို",
		"editLevel": "Edit ကိုအဆငျ့",
		"list": "အဆင့်ဆင့်များစာရင်း",
		"children": "level ကလေးများ"
	},
	"bugs": {
		"status": {
			"closed": "ပိတ်သိမ်း",
			"fixed": "သတ်မှတ်ထားတဲ့",
			"open": "ဖွင့်လှစ်",
			"postponed": "ရွှေ့ဆိုင်း",
			"duplicate": "မူရငျးခှဲ",
			"deleted": "Deleted",
			"wontFix": "Fix မည်မဟုတ်",
			"notReproducible": "Reproducible မဟုတ်",
			"title": "status",
			"byDesign": "ဒီဇိုင်းအားဖြင့်"
		},
		"noComments": "အထူးပြောစရာမရှိ",
		"title": "bugs",
		"bugReport": "bug အစီရင်ခံစာ",
		"version": "ဗားရှင်း",
		"issueDetails": "ပြဿနာအသေးစိတျ",
		"platformDetails": "ပလက်ဖောင်းအသေးစိတ်ကို",
		"closeIssue": "အနီးကပ်ပြဿနာ",
		"report": "အစီရင်ခံစာ",
		"comments": "မှတ်ချက်များ",
		"build": "တည်ဆောက်",
		"screenshots": "screenshots",
		"noFiles": "အဘယ်သူမျှမဖိုင်တွေ",
		"addComment": "မှတ်ချက် Add",
		"loadingDetails": "bug ကိုအသေးစိတျ Loading ...",
		"reopenIssue": "ပြဿနာပြန်ဖွင့်",
		"listPending": "ဆိုင်းငံ့ bug ကိုအစီရင်ခံစာများများစာရင်း",
		"steps": "မျိုးပွားဖို့ခြေလှမ်းများ",
		"listOpen": "ပွင့်လင်း bug ကိုအစီရင်ခံစာများများစာရင်း",
		"device": "စက်ပစ္စည်း / OS ဗားရှင်း",
		"loadingFiles": "loading files တွေကို ...",
		"expected": "မျှော်လင့်ထားသည့်အမူအကျင့်",
		"actually": "အမှန်တကယ်အပြုအမူ",
		"noReports": "အဘယ်သူမျှမအစီရင်ခံစာများ",
		"reportBug": "တစ်ဦး bug ကိုသတင်းပို့",
		"listClosed": "တံခါးပိတ် bug ကိုအစီရင်ခံစာများများစာရင်း"
	},
	"gdpcConsole": {
		"nationalSocieties": {
			"countries": "နိုင်ငံများ",
			"downloads": "downloads",
			"pool": "ရေကူးကန်",
			"goLive": "တိုက်ရိုက်လွှ Go",
			"stage": "စင်",
			"totalDownloads": "စုစုပေါင်း Downloads",
			"title": "ယေဘုယျအမြင်",
			"averageRating": "ပျမ်းမျှအဆင့်",
			"locale": "ဒေသ",
			"edit": "Edit ကို Society က",
			"details": "လူ့အဖွဲ့အစည်းအသေးစိတ်",
			"contact": "ထိတှေ့"
		},
		"devPools": {
			"completed": "ပြီးစီး",
			"language": "ဘာသာစကား",
			"expected": "မျှော်လင့်ထားသည့်",
			"stages": "စင်",
			"requiredBy": "တို့ကတောင်းဆိုနေတဲ့",
			"estimatedDate": "လက်ရှိခန့်မှန်း app ကိုနေ့စွဲ",
			"title": "dev ရေကူးကန် status ကို",
			"noApps": "ဒီ dev ရေကူးကန်ထဲမှာဘယ်သူမျှမက apps များ",
			"responsibility": "တာဝန်",
			"editPool": "Edit ကို dev ရေကူးကန်"
		}
	},
	"organization": {
		"noLanguages": "အဘယ်သူမျှမဘာသာစကားများတာဝန်ပေး",
		"confirmLanguageAssignment": "သငျသညျဤဘာသာစကားကို assign ချင်သင်သေချာပါသလား? ဘာသာစကားတာဝန်တွေဖယ်ရှားမရပါ။",
		"title": "အဖှဲ့အစညျး",
		"details": "အဖွဲ့အစည်းကအသေးစိတျ"
	},
	"mailingList": {
		"dateFrom": "မှ",
		"dateTo": "သို့",
		"title": "စာပို့စာရင်းများ",
		"export": "ထုတ်ကုန်",
		"list": "ရရှိနိုင်ပါစာရင်းများ"
	},
	"moderation": {
		"image": {
			"title": "image ကိုဖြည့်ညှင်း",
			"location": "တည်နေရာ"
		},
		"title": "moderation"
	},
	"posts": {
		"title": "စာတိုက်"
	},
	"achievements": {
		"image": "image ကို",
		"name": "အမည်",
		"completion": "ပြည့်စုံခြင်း",
		"title": "အောင်မြင်မှုများ",
		"badge": "တံဆိပ်",
		"edit": "Edit ကိုအောင်မြင်မှု"
	},
	"triggers": {
		"subclasses": {
			"RaisedTrigger": {
				"amount": "ငွေပမာဏ",
				"singular": "ကြီးပြင်းငွေပမာဏ"
			}
		},
		"plural": "သေနတ်မောင်းခလုတ်"
	},
	"mind-moderation": {
		"emoodji": {
			"singular": "Emoodji"
		},
		"title": "Emoodji ထိန်းချုပ်နိုင်ခြင်း",
		"empty": "အဘယ်သူမျှမ Emoodjis အလယ်အလတ်မှ"
	},
	"whatnow": {
		"attribution": {
			"attribution": "အရည်အချင်း",
			"socName": "အရည်အချင်း Society ကအမည်",
			"messageHelp": "အဆိုပါထည့်သွင်းတွက်ချက်မှုနှင့်အတူသွားသတင်းစကား။",
			"urlHelp": "အဆိုပါ Attributor အကြောင်းကိုပိုမိုသိရှိလိုပါကရန်ဆက်သွယ်ကြောင်း URL ကို။",
			"socNameHelp": "အဆိုပါ whatnows နှင့်အတူစွပ်စွဲခံရဖို့သော Society ရဲ့အမည်။",
			"url": "အရည်အချင်း URL ကို",
			"image": "အရည်အချင်းပုံရိပ်",
			"message": "အရည်အချင်းကို Message",
			"imageHelp": "image / အိုင်ကွန်အပ်လုဒ်ကို။"
		},
		"edit": {
			"moreinfoHelp": {
				"placeholder": "ငါ့ရဲ့ဇာတ်မြင့်ဒေါ်လိုရေစ် sed, amet consectetur adipiscing Elite ထိုင် eiusmod အလုပ်သမားရေးရာ et ဒေါ်လိုရေစ် Magna aliqua incididunt ut ယာယီပါဘူး။ ut enim ကြော်ငြာအနည်းငယ်မျှသာ veniam, quis nostrud exercitation ullamco လောကီ nisi ut aliquip ဟောင်း ea commodo consequat ။"
			},
			"title": "ခေါင်းစဥ်",
			"translations": "ဘာသာပြန်ချက်များ",
			"immediate": "လက်ငင်း",
			"after": "ပြီးနောက်",
			"recoverHelp": "ပြီးနောက်",
			"forecast": "ခန့်မှန်းချက်",
			"event": "စွန့်စားခြင်း",
			"languages": "ဘာသာစကားများ",
			"missing": "ပျောက်ဆုံးနေအကြောင်းအရာ",
			"warningHelp": "တုံ့ပြန်ရန်ပြင်ဆင်ပါ",
			"selectLang": "ဘာသာစကားတခုကို Select လုပ်ပါ",
			"midterm": "နှစ်လယ်ပိုင်းတွင်သက်တမ်းခန့်မှန်းချက်",
			"midtermHelp": "အန္တရာယ်များ (ရှည်လျားတဲ့သက်တမ်းလုပ်ရပ်တွေကို) ကိုလျှော့ချ",
			"beforeHelp": "ဘယ်လိုအဖြစ်အပျက်ဖြစ်ပျက်မတိုင်မီကြိုတင်ပြင်ဆင်ရန်။",
			"forecastHelp": "အကဲဖြတ်ရန်နှင့်အစီအစဉ်ကို (အရပ်ပုသည့်ဝေါဟာရကိုလုပ်ရပ်တွေကို)",
			"descriptionHelp": "ယခုအဘယ်အရာကိုတစ်ဖော်ပြချက်။",
			"watchHelp": "တုံ့ပြန်ရန်ပြင်ဆင်ပါ",
			"addALang": "စတင်ရန်ဘာသာစကားတစ်ခုကို Add",
			"empty": "အခုတော့တစ်ဘယျလိုဖနျတီးဖို့ဘာသာစကားတစ်ခုကိုပေါင်းထည့်ပေးပါ",
			"eventhelp": "အဆိုပါအဘယျသို့ယခုဖြစ်ရပ်အမျိုးအစား။",
			"moreinfohelp": "ယခုအဘယ်အရာကိုအကြောင်းပိုမိုသတင်းအချက်အလက်များအတွက် URL ကို။",
			"afterHelp": "ထိုအဖြစ်အပျက်ဖြစ်ပျက်ပြီးအဘယ်သို့ပြုရပါဖို့။",
			"before": "ရှေ့မှာ",
			"recover": "Recover",
			"warning": "သတိပေးခြင်း",
			"immediateHelp": "တုံ့ပြန်",
			"duringHelp": "အဘယ်အရာကိုဖြစ်ရပ်နေတုန်းလုပ်ဖို့ဖြစ်ပျက်နေသည်။",
			"description": "ဖေါ်ပြချက်",
			"watch": "စောင့်ကြည့်",
			"titleHelp": "ယခုအဘယ်အရာကိုများအတွက်ခေါင်းစဉ်။",
			"editWhatnow": "အခုတော့ဘယ်အရာကိုပြင်ရန်",
			"new": "အသစ်တခုဘာသာစကား Add",
			"moreinfo": "ပြန်ကြားရေး URL ကို",
			"during": "စဉ်အတွင်း"
		},
		"title": "ခေါင်းစဥ်",
		"eventType": "စွန့်စားခြင်း",
		"after": "ပြီးနောက်",
		"before": "ရှေ့မှာ",
		"content-not-set": "အကြောင်းအရာမသတ်မှတ်",
		"whatnow": "အခုဆိုရင်အဘယျနညျး",
		"selectSoc": "လူ့အဖွဲ့အစည်းကို Select လုပ်ပါ",
		"empty": "ယခုအဘယ်အရာကို entries တွေကိုမျှရှိပါသည်, တဦးတည်းကိုဖန်တီးရန် add ကိုကလစ်နှိပ်ပါ",
		"description": "ဖေါ်ပြချက်",
		"during": "စဉ်အတွင်း"
	},
	"arpro": {
		"codeGroup": {
			"description": "ဖေါ်ပြချက်",
			"editItem": "Edit ကို Code ကိုအုပ်စု",
			"addCodes": "ဒီမှာကုဒ်ရိုက်ထည့်ပါ",
			"codes": "codes",
			"name": "အမည်",
			"codeGroup": "Code ကိုအဖွဲ့များ"
		},
		"colours": {
			"description": "ဖေါ်ပြချက်",
			"colours": "အရောင်များ",
			"name": "အမည်"
		},
		"search": {
			"tensileElongation": "ဆန့ elongated",
			"compressionStrength75": "compression ခွန်အား 75",
			"compressionStrength50": "compression ခွန်အား 50",
			"search": "ကုန်ပစ္စည်းရှာရန်",
			"resilience75DynamicCompression": "ခံနိုင်ရည် 75 Dynamic Compression ကို",
			"tensileStrength": "ဆန့်နိုင်အား",
			"burningRate": "မီးလောင်သောနှုန်း",
			"mouldedDensity": "ပုံသွင်း Density",
			"equivalentYoungsModulus": "ညီမျှငယ်ရွယ်ကိန်းပကတိတန်ဖိုး",
			"compressionStrength25": "compression ခွန်အား 25"
		},
		"product": {
			"properties": {
				"bag": "အိတ်",
				"food": "စားနပ်ရိက္ခာအတည်ပြု",
				"size": "အရွယ်အစား (မီလီမီတာ)",
				"bulk": "bulk သိပ်သည်းဆ (ဆ / ဌ)",
				"moulded": "ပုံသွင်းသိပ်သည်းဆ (ဆ / ဌ)",
				"bulkLabel": "ထုထည်",
				"weight": "အလေးချိန် (မီလီဂရမ်)",
				"packaging": "ထုပ်ပိုး"
			},
			"colour": "အရောင်",
			"grade": "grade",
			"product": "ထုတ်ကုန်များ",
			"access": "access ကိုကုဒ်များ",
			"rawData": "ကုန်ကြမ်း JSON ဒေတာ",
			"rawDataError": "ကုန်ကြမ်း JSON ဒေတာ - မှားနေသော JSON ဒေတာ",
			"notes": "မှတ်စုများ",
			"name": "အမည်",
			"specifications": "အသေးစိတ်ဖော်ပြချက်",
			"noneLinked": "အဘယ်သူမျှမ Access ကို codes တွေကို",
			"physicalProps": "ရုပ်ပိုင်းဆိုင်ရာ Properties ကို",
			"editItem": "Edit ကိုကုန်ပစ္စည်း",
			"linked": "Access ကို codes တွေကိုရှိပါတယ်",
			"moulded": "ပုံသွင်း Density Range (ဆ / ဌ)"
		},
		"arpro": "arpro",
		"noitems": "အထက်ပေါင်းထည့်, ဖော်ပြရန်နှိပ်ပါပစ္စည်းများကိုမျှရှိနေပါသည်။"
	},
	"r4b": {
		"level": {
			"levels": "အဆင့်ဆင့်",
			"green": "စိမ်းလန်းသော",
			"blue": "ပြာသော",
			"title": "level",
			"selectBadge": "တစ်တံဆိပ်ကို select ပေးပါ",
			"red": "အနီေရာင်",
			"alpha": "alpha",
			"colour": "အရောင်"
		},
		"modules": {
			"blockContent": {
				"logic": {
					"title": "ပုဒ်မမြင်နိုင်စွမ်း Logic"
				},
				"slider": {
					"interval": "အကြား",
					"startLabel": "တံဆိပ်တပ်ရန် Start",
					"endLabel": "အဆုံးတံဆိပ်တပ်ရန်"
				},
				"question": {
					"selectionHelp": "မှဆက်လက်ဆောင်ရွက်ရန်အပိုင်းကိုရွေးချယ်ပါ",
					"textHelp": "မေးခွန်းတစ်ခုကိုရိုက်ထည့်",
					"responseHelp": "တစ်တုံ့ပြန်မှု Enter",
					"addResponse": "တစ်တုံ့ပြန်မှု Add",
					"addAnotherResponse": "အခြားတုံ့ပြန်မှု Add",
					"answerHelp": "အဖြေကိုရိုက်ထည့်",
					"title": "မေးခွန်းနှင့်အဖြေ"
				},
				"quote": {
					"title": "စြေးထား",
					"citation": "ဒီနေရာမှာကိုးကား Enter"
				},
				"checkbox": {
					"title": "checkboxes"
				},
				"textInput": {
					"title": "စာသားမ Input",
					"multi": "multi-line ကိုစာသားမ?",
					"help": "ဒီနေရာမှာ input ကိုတံဆိပ်ရိုက်ထည့်ပါ"
				},
				"link": {
					"title": "links"
				},
				"help": {
					"title": "အကူအညီအရာဝတ္ထု"
				},
				"task": {
					"title": "task ကို Object ကို",
					"help": "တာဝန်ရည်ရွယ်ချက် Enter"
				},
				"text": {
					"title": "စာသားမအရာဝတ္ထု"
				},
				"radio": {
					"title": "ရေဒီယိုခလုတ်"
				},
				"identifier": "identifier",
				"section": "အပိုင်း",
				"inputLabel": "စာသားမ Input ကိုတံဆိပ်တပ်ရန်",
				"addIdentifier": "တစ်ဦးအမှတ်အသား Add",
				"clickToEdit": "တည်းဖြတ်ဖို့ကိုနှိပ်ပါ",
				"videoLink": "ပြင်ပဗီဒီယို Link ကို",
				"enterText": "ဤနေရာတွင်စာသားကိုရိုက်ထည့်ပါ",
				"break": "စာမျက်နှာချိုး",
				"enterTitle": "ခေါင်းစဉ်ကိုရိုက်ထည့်",
				"addSection": "သစ်တစ်ခုအပိုင်းကိုဖန်တီးရန်ဒီနေရာကိုတစ်ဦးကို item ဆွဲပါ"
			},
			"section": {
				"title": "အပိုင်း"
			},
			"blockTypes": {
				"help": "ကူညီပါ",
				"quote": "quote",
				"media": "မီဒီယာ Block",
				"userInput": "USER မှထည့်သွင်းမှု Block",
				"image": "IMAGE",
				"task": "လုပ်ငန်း",
				"link": "LINK",
				"text": "TEXT",
				"textInput": "TEXT Entry",
				"video": "ဗွီဒီယို",
				"radio": "ရေဒီယို",
				"slider": "slider",
				"check": "နေစစ်ဆေးခြင်း",
				"qna": "Q & A"
			},
			"module": "module",
			"levels": "အဆင့်ဆင့်",
			"version": "ဗားရှင်း",
			"level": "level",
			"person": "လူ",
			"visibility": "မြင်နိုင်စွမ်း",
			"unsaved": "မသိမ်းရသေးသောအပြောင်းအလဲများရှိပါသည်",
			"saved": "အားလုံးအပြောင်းအလဲများကိုကယ်တင်ခြင်းသို့ရောက်",
			"title": "modules",
			"people": "လူထု",
			"mins": "မိနစ်"
		},
		"assessments": {
			"assessment": "အကဲဖြတ်",
			"pre": "အကြိုအကဲဖြတ်"
		},
		"sections": "အပိုင်း",
		"admin": "အဒ်မင် Dashboard ကို",
		"mediaBlocks": "မီဒီယာ Block",
		"questions": "မေးခွန်းလွှာ",
		"contentBlocks": "အကြောင်းအရာလုပ်ကွက်",
		"minutes": "မိနစ်",
		"inputBlocks": "USER မှထည့်သွင်းမှု Block",
		"people": "လူတွေ",
		"person": "PERSON သည်",
		"languageVars": "ဘာသာစကားအပြောင်းအလဲတွေဟာ",
		"languages": "ဘာသာစကားများ",
		"title": "ပေုံ",
		"empty": "အဘယ်သူမျှမ module တွေသို့မဟုတ်အကဲဖြတ်စတင်ရန်ဤအဆင့်အထိတဦးတည်းကိုထည့်သွင်း, ဒီမှာရှိပါတယ်။"
	},
	"rspb": {
		"factFile": {
			"tags": "Tags:",
			"start": "စတင်",
			"commonName": "လူအသုံးအများဆုံးအမည်",
			"show": "ပြသ",
			"features": "အင်္ဂါရပ်များ",
			"scientificName": "သိပ္ပံနည်းကျအမည်",
			"funFacts": "ပျော်စရာအချက်အလက်",
			"name": "အမည်",
			"seen": "တှေ့မွငျရ",
			"description": "ဖေါ်ပြချက်",
			"addImages": "ကို Select လုပ်ပါ Image ကို",
			"editItem": "Edit ကိုအဖြစ်မှန်ဖိုင်မှတ်တမ်း",
			"addTags": "ဒီနေရာမှာတစ်ဦး tag ကိုရိုက်ထည့်ပါ",
			"images": "ပုံရိပ်တွေ",
			"addFeatures": "ဒီနေရာမှာတစ်ဦးအင်္ဂါရပ် Enter",
			"factFile": "တကယ်တော့ဖိုင်များ",
			"addActivities": "ဒီနေရာမှာတစ်ခုလှုပ်ရှားမှု Enter",
			"addFact": "ဒီနေရာမှာတစ်ဦးအချက်ကိုထည့်သွင်းပါ",
			"activities": "လှုပ်ရှားမှုများ"
		},
		"planItem": {
			"difficulty": {
				"easy": "လွယ်ကူသော",
				"hard": "ခိုင်မာသော",
				"medium": "အလယ်အလတ်",
				"difficulty": "အခက်အခဲတစ်ခုကိုရွေးချယ်ပါ"
			},
			"garden": {
				"medium": "အလယ်အလတ်",
				"public": "ပြည်သူ့",
				"large": "အကြီးစား",
				"small": "သေးငယ်သော",
				"selectType": "ဥယျာဉ်တော်များ၏အမျိုးအစား / s ကို Select လုပ်ပါ",
				"balconyroof": "လသာဆောင် / ခေါင်မိုး"
			},
			"title": "ခေါင်းစဥ်",
			"type": "ပုံစံ",
			"description": "ဖေါ်ပြချက်",
			"tags": "Tags:",
			"source": "အရင်းအမြစ်",
			"image": "image ကို",
			"months": "လများ",
			"indefinite": "အစဉျအမွဲ?",
			"dates": "ရက်စွဲများ",
			"identifier": "identifier",
			"to": "အဆုံးသည့်ရက်စွဲ",
			"planItems": "plan ကိုပစ္စည်းများ",
			"region": "ဒေသ",
			"weightingHelp": "ပစ္စည်းကတော့ app ထဲမှာပေါ်လာလိမ့်မယ်ဘယ်မှာ၏ဦးစားပေး",
			"mostImportant": "အရေးအပါဆုံး",
			"visible": "ကနေမြင်နိုင်",
			"leastImportant": "အနည်းဆုံးအရေးကြီးသော",
			"weighting": "အစီအစဉ်ကို item တွက်ဆ",
			"duration": "Duration: (စက္ကန့်)",
			"pageHelp": "ဒါဟာသင့်ရဲ့အစီအစဉ်ကို item လင့်ထားသည်ပါလိမ့်မယ်စာမျက်နှာဖြစ်ပါသည်",
			"activityCode": "activity ကိုကုဒ်",
			"from": "ကနေရရှိနိုင်",
			"solo": "မိသားစုသနည်း",
			"regionHelp": "ဤအစီအစဉ်ကို item များအတွက်ဒေသရွေးဖို့ကိုနှိပ်ပါ"
		},
		"rspb": "RSPB",
		"noitems": "အထက်ပေါင်းထည့်, ဖော်ပြရန်နှိပ်ပါပစ္စည်းများကိုမျှရှိနေပါသည်။"
	}
}