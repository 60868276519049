module.exports = Backbone.View.extend({
	template: require('./signup-view-template'),

	events: {
		'click .signup-button': 'signupButtonClick'
	},

	signupButtonClick: function() {
		return false
	}
})
