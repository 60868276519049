var PaginatedListView = require('list-view/paginated-list-view'),
	HazardsSectionView = require('hazards/hazards-section-view')

var ImageModerationListView = PaginatedListView.extend({
	template: require('./image-moderation-list-view-template'),
	list: require('./crowd-image-list'),
	listItem: require('./image-moderation-item-view'),

	afterInitialize: function(options) {
		this.appId = Number(options.appId)
	}
})

module.exports = HazardsSectionView.extend({
	template: require('./image-moderation-view-template'),

	initialize: function(options) {
		this.appId = options.appId

		this.views = {
			list: new ImageModerationListView({appId: this.appId})
		}
	},

	getRenderData: function() {
		return {appId: this.appId}
	}
})
