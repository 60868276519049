var StandaloneStormObject = require('editor/standalone-storm-object'),
	EditorSectionView     = require('editor/editor-section-view'),
	LanguageList          = require('editor/language-list'),
	LanguageCodes         = require('../language-codes')

module.exports = EditorSectionView.extend({
	className: 'crossBorders',
	template: require('./phrase-edit-view-template'),
	// TODO need to update this to match badges view, if it changes.
	activeTabIndex: 4,

	events: {
		'click .save': 'save',
		'click .add-row': 'addTransRow',
		'click .remove-row': 'removeTransRow'
	},

	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)

		if (!options.app) {
			throw new Error('No app specified')
		}

		/** @private {App} */
		this.app_ = options.app

		this.parentId = options.parentId

		// Fetch enabled languages for this app.
		var languagePromise = this.app_.languageList.fetchOnce()

		this.parentModel = null

		var modelPromise = Promise.resolve()
		var parentPromise

		this.languageList = new LanguageList()
		this.languageList.once('sync', this.ready, this)
		this.languageList.fetch()

		if (options.id !== 'new') {
			this.model = new StandaloneStormObject()
			this.model.set('id', options.id)
			this.model.once('sync', this.ready, this)

			modelPromise = this.model.fetch().then(function() {
				// Add in any missing text objects to the model.
				['phrase'].forEach(function(key) {
					if (!this.model.has(key)) {
						var text = App.getClassStructure('Text', 0)
						this.model.set(key, text)
					}
				}, this)
			}.bind(this))
		} else {
			this.parentModel = new StandaloneStormObject()
			this.parentModel.set('id', this.parentId)

			// Load parent model in which to store new child obj
			parentPromise = this.parentModel.fetch()
			this.model = StandaloneStormObject.fromClassName('CrossBorderPhrase')
		}

		Promise.all([modelPromise, languagePromise, parentPromise])
			.then(App.stopLoad)
			.then(this.render.bind(this))
	},

	afterRender: function() {
		this.delegateEvents()
		this.$('#country-code').val(this.model.get('code'))

		// Show current input values
		_.each(this.model.get('phrase..content'), function(val, key) {
			this.$('.phrase-input[data-code=' + key + ']').val(val)
		})
	},

	getRenderData: function() {
		var data = this.model.toJSON()
		data.appId = this.app_.id

		// Sort language list by code for dropdown
		var languages = this.app_.languageList.toJSON().sort(function(a, b) {
			if (a.code < b.code) {
				return -1
			}

			if (a.code > b.code) {
				return 1
			}

			return 0
		})

		data.languages = languages
		data.languageCodes = LanguageCodes

		return data
	},

	addTransRow: function() {
		var langCode  = this.$('.new-trans-lang').val(),
			transText = this.$('.new-trans-text').val()

		if (langCode.length && transText.length) {
			var transModel = StandaloneStormObject.fromClassName('CrossBorderTranslationItem')
			transModel.set('code', langCode)
			transModel.set('text', transText)

			this.model.get('translations').push(transModel)

			this.render()
		}
	},
	removeTransRow: function(e) {
		var transId  = $(e.currentTarget).data('id'),
			transArr = this.model.get('translations')

		var arr = _.filter(transArr.models, function(obj) {
			return obj.id !== transId
		})

		this.model.set('translations', arr)

		this.render()
	},

	save: function() {
		App.startLoad()

		var self = this

		this.model.once('sync', function() {
			App.router.navigate('/apps/' + self.app_.id + '/cross-borders/' + self.parentId, {trigger: true})
		})

		// Get all new input values
		var countries = {}

		this.$('.phrase-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				countries[code] = value
			}
		})

		this.model.set('phrase..content', countries)
		if (this.model.isNew()) {
			this.parentModel.once('sync', function() {
				App.router.navigate('/apps/' + self.app_.id + '/cross-borders/' + self.parentId, {trigger: true})
			})

			// save new model onto parent
			this.parentModel.get('phrases').push(this.model)
			this.parentModel.requestLock(function() {
				App.stopLoad()
				this.parentModel.save().then(this.parentModel.requestUnlock.bind(this))
			}.bind(this))
		} else {
			this.model.requestLock(function() {
				App.stopLoad()
				this.model.save().then(this.model.requestUnlock.bind(this))
			}.bind(this))
		}

		return false
	}
})
