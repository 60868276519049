<div class="row">
	<div class="col-xs-12">
		<div class="row">
			<div class="col-sm-6">
				<h3 data-i18n="rspb.factFile.editItem"></h3>
			</div>
			<div class="col-sm-6 actions-col">
				<a href="javascript:history.back()"><button class="button red wide" type="button">{{t "common.cancel"}}</button></a>
				<button class="button green wide save" type="submit">{{t "common.save"}}</button>
			</div>
		</div>
	</div>
</div>

<div class="row">
	<!-- Name -->
	<div class="col-sm-6">
		<div class="form-group">
			<label class="control-label">{{t "rspb.factFile.commonName"}}</label>
		</div>
		{{#each languages}}
			<div class="form-group">
				<div class="input-group">
					<span class="input-group-addon">{{name}}</span>
					<input type="text" class="common-input form-control text-input" data-code="{{code}}" data-object="commonName">
				</div>
			</div>
		{{/each}}
	</div>

	<!-- Scientific -->
	<div class="col-sm-6">
		<div class="form-group">
			<label class="control-label">{{t "rspb.factFile.scientificName"}}</label>
		</div>
		<div class="form-group">
			<input type="text" class="scientific-input form-control">
		</div>
	</div>
</div>

<div class="row">
	<!-- Seen -->
	<div class="col-sm-6">
		<div class="form-group">
			<label class="control-label">{{t "rspb.factFile.seen"}}</label>
		</div>
		{{#each languages}}
			<div class="form-group">
				<div class="input-group">
					<span class="input-group-addon">{{name}}</span>
					<input type="text" class="seen-input form-control text-input" data-code="{{code}}" data-object="seen">
				</div>
			</div>
		{{/each}}
	</div>
</div>
<div class="row">

	<!-- Activities -->
	<!-- <div class="col-sm-4">
		<div class="form-group">
			<label class="control-label" for="code-input">{{t "rspb.factFile.activities"}}</label>
		</div>
		<div class="form-group">
			<div class="col-sm-12 input-group">
				<input type="text" class="form-control array-input" data-object="activities" placeholder="{{t "rspb.factFile.addActivities"}}">
				<span class="input-group-btn">
					<button class="btn btn-default array-add" type="button" data-object="activities">{{t "common.add"}}</button>
				</span>
			</div>
		</div>

		<ul id="code-list">
			{{#each activities}}
				<li>{{this}} <a href="" class="array-remove-button" data-id="{{@key}}" data-object="activities">×</a></li>
			{{/each}}
		</ul>
	</div> -->

	<!-- Tags -->
	<div class="col-sm-4">
		<div class="form-group">
			<label class="control-label">{{t "rspb.factFile.tags"}}</label>
		</div>

		<table>
			<tbody class="list-items">
				{{#each tags}}
					{{#unless toDelete}}
					<tr>
						<td>
							<!-- <input type="text" class="form-control string-input" data-object="tags" data-arrayitem="true" value="{{this}}" data-index="{{@key}}"> -->
							{{this}}
						</td>
						<td class="tools">
							<i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}" class="array-remove-button" data-object="tags" data-index="{{@key}}"></i>
						</td>
					</tr>
					{{/unless}}
				{{/each}}
				<tr>
					<td>
						<div class="form-group">
							<select class="form-control tag-input" data-object="tags" >
								{{#each allTags}}
									<option value="{{key}}" {{#if isDisabled}} disabled {{/if}}>{{languages.[0].value}}</option>
								{{/each}}
							</select>
							<!-- <input type="text" class="tag-input form-control" data-object="tags" placeholder="{{t "rspb.factFile.addTags"}}"> -->
						</div>
					</td>
					<td class="tools">
						<button class="btn btn-default array-add" type="button" data-object="tags">{{t "common.add"}}</button>
					</td>
				</tr>

			</tbody>
		</table>
	</div>

	<!-- Features -->
	<div class="col-sm-4">
		<div class="form-group">
			<label class="control-label">{{t "rspb.factFile.features"}}</label>
		</div>

		<table>
			<tbody class="list-items">
				{{#each features}}
					{{#unless toDelete}}
					<tr>
						<td>
							{{#key_value content "lang" "text"}}
								<dt>{{lang}}</dt>
								<input type="text" class="form-control text-input" data-code="{{lang}}" data-object="features" data-arrayitem="true" value="{{text}}" data-index="{{@key}}">
							{{/key_value}}
						</td>
						<td class="tools">
							<i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}" class="array-remove-button" data-object="features" data-index="{{@key}}"></i>
						</td>
					</tr>
					{{/unless}}
				{{/each}}
				<tr>
					<td>
						{{#each languages}}
							<div class="form-group">
								<div class="col-sm-12 input-group">
									<span class="input-group-addon">{{name}}</span>
									<input type="text" class="feature-input form-control" data-code="{{code}}" data-object="features" placeholder="{{t "rspb.factFile.addFeatures"}}">
								</div>
							</div>
						{{/each}}
					</td>
					<td class="tools">
						<button class="btn btn-default array-add" type="button" data-object="features">{{t "common.add"}}</button>
					</td>
				</tr>

			</tbody>
		</table>
	</div>

	<!-- Images -->
	<div class="col-sm-4">
		<div class="form-group">
			<label class="control-label">{{t "rspb.factFile.images"}}</label>
		</div>
		<table>
			<tbody class="list-items">
				{{#each images}}
					{{#unless toDelete}}
					<tr>
						<td>
							<img src="{{getPreviewUrl src}}" class="fact-file-image" data-index="{{@key}}" />
						</td>
						<td class="tools">
							<i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}" class="array-remove-button" data-object="images" data-index="{{@key}}"></i>
						</td>
					</tr>
					{{/unless}}
				{{/each}}
					<tr>
						<td>
						</td>
						<td class="tools">
							<button class="btn btn-default array-add" type="button" data-object="images">{{t "common.add"}}</button>
						</td>
					</tr>
			</tbody>
		</table>
	</div>
</div>

<div class="row">
	<!-- Fun Facts -->
	<div class="col-sm-4">
		<div class="form-group">
			<label class="control-label">{{t "rspb.factFile.funFacts"}}</label>
		</div>

		<table>
			<tbody class="list-items">
				{{#each facts}}
					{{#unless toDelete}}
					<tr>
						<td>
							{{#key_value content "lang" "text"}}
								<dt>{{lang}}</dt>
								<input type="text" class="form-control text-input" data-code="{{lang}}" data-object="facts" data-arrayitem="true" value="{{text}}" data-index="{{@key}}">
							{{/key_value}}
						</td>
						<td class="tools">
							<i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}" class="array-remove-button" data-object="facts" data-index="{{@key}}"></i>
						</td>
					</tr>
					{{/unless}}
				{{/each}}
				<tr>
					<td>
						{{#each languages}}
							<div class="form-group">
								<div class="col-sm-12 input-group">
									<span class="input-group-addon">{{name}}</span>
									<input type="text" class="fact-input form-control" data-code="{{code}}" data-object="facts" placeholder="{{t "rspb.factFile.addFact"}}">
								</div>
							</div>
						{{/each}}
					</td>
					<td class="tools">
						<button class="btn btn-default array-add" type="button" data-object="facts">{{t "common.add"}}</button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<!-- Dates -->
	<div class="col-sm-4">
		<div class="form-group">
			<label class="control-label">{{t "rspb.planItem.dates"}}</label>
		</div>
		<div class="input-group">
			<span class="input-group-addon">{{t "rspb.factFile.show"}}</span>
			<input type="text" id="date-show" class="form-control">
		</div>
		<br />
		<div class="input-group">
			<span class="input-group-addon">{{t "rspb.factFile.start"}}</span>
			<input type="text" id="date-start" class="form-control">
		</div>
		<br />
	</div>

	<!-- activities -->
	<div class="col-sm-4">
		<div class="form-group">
			<label class="control-label">{{t "rspb.factFile.activities"}}</label>
		</div>

		<table>
			<tbody class="list-items">
				{{#each activities}}
					{{#unless toDelete}}
					<tr>
						<td>
							{{getObjectTitleById this ../planItems}}
						</td>
						<td class="tools">
							<i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}" class="array-remove-button" data-object="activities" data-index="{{@key}}"></i>
						</td>
					</tr>
					{{/unless}}
				{{/each}}
				<tr>
					<td>
						<div class="form-group">
							<select class="form-control activity-input" data-object="activities" >
								{{{planItemsDropdown}}}
							</select>
							<!-- <input type="text" class="tag-input form-control" data-object="tags" placeholder="{{t "rspb.factFile.addTags"}}"> -->
						</div>
					</td>
					<td class="tools">
						<button class="btn btn-default array-add" type="button" data-object="activities">{{t "common.add"}}</button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
<div style="height:100px;">

</div>
