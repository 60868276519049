var ListView = require('list-view/list-view'),
	UsersExtraList = require('./user-extra-list'),
	RoleList = require('permissions/role-list'),
	Extra = require('./user-extra'),
	UserList = require('./user-list')

function getUserList(ids) {
	return UserList.extend({
		ids: ids,
		filterDeleted: true,
		// Set to no-op so it doesn't overwrite the IDs.
		initialize: function() {}
	})
}

module.exports = ListView.extend({
	template: require('./user-list-view-template'),
	listItem: require('./user-list-item-view'),

	initialize: function(options) {
		// Programmatically generate list with correct set of user IDs.
		this.list = getUserList(options.ids)

		ListView.prototype.initialize.apply(this, arguments)

		this.roleList = new RoleList()
		var rolesFetch = this.roleList.fetch()

		this.usersExtraList = new UsersExtraList()
		var extraFetch = this.usersExtraList.fetch()

		Promise.all([rolesFetch, extraFetch]).then(this.ready.bind(this))
	},

	ready: function() {
		// Update role helper with current lists
		Handlebars.helpers.getRoleById.roleList = this.roleList

		// Set job titles on each user model
		this.collection.each(function(user) {
			var extra = this.usersExtraList.get(user.id)
			if (extra) {
				user.extra = extra
			} else {
				user.extra = new Extra({id: user.id, jobTitle: ''})
			}

			// Cause the row to re-render
			user.trigger('change', user)
		}, this)

		this.trigger('ready')
	},

	afterRender: function() {
		// Hide 'job title' column on non-GDPC systems.
		if (App.system.id !== 3) {
			this.$('.job-title').remove()
		}
	}
})
