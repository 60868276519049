module.exports = Backbone.DeepModel.extend({
	urlRoot: function() {
		return App.apiRoot + 'push'
	},

	defaults: function() {
		return {
			status: 1,
			priority: 3,
			notes: '',
			category: 'standard',
			payload: {
				message: '',
				type: 'default'
			},
			region: false
		}
	},

	approve: function() {
		this.admin('approve')
	},

	reject: function() {
		this.admin('reject')
	},

	admin: function(method) {
		var model = this

		Backbone.sync('update', model, {
			url: this.url() + '/' + method,
			complete: function() {
				model.trigger('sync')
			}
		})
	}
})
