module.exports = Backbone.Model.extend({
	urlRoot: function() {
		if (!this.isNew()) {
			return App.bugsRoot + 'comments'
		}
	},

	initialize: function() {
		var CommentList = require('./comment-list')
		this.attributes.replies = new CommentList(this.attributes.replies)
	}
})
