var ListViewItem = require('list-view/list-view-item'),
	UserRoleList = require('permissions/user-role-list')

/**
 * Exports {@link UserListItemView}.
 * @module
 */
module.exports = ListViewItem.extend(/** @lends UserListItemView.prototype */{
	/** @override */
	template: require('./user-list-item-view-template'),

	/**
	 * @constructs UserListItemView
	 * @extends ListViewItem
	 * @override
	 */
	initialize: function(options) {
		ListViewItem.prototype.initialize.apply(this, arguments)

		if (!options.groupList) {
			throw new Error('No groupList specified')
		}

		if (!options.roleList) {
			throw new Error('No roleList specified')
		}

		this.roleList = new UserRoleList(null, {userId: this.model.id})
		/** {@private GroupList} */
		this.groupList_ = options.groupList
		/** {@private RoleList} */
		this.systemRoleList_ = options.roleList
	},

	/** @override */
	afterRender: function() {
		var groupId = this.getCurrentRoleGroup_()

		this.$('#user-group-select').val(groupId)

		if (!App.acl.hasWritePermission('Users', 'Users list')) {
			this.$('.tools').text('-')
		}

		if (!App.acl.hasAccess('Users', 'App assignment')) {
			this.$('.apps-link').text('-')
		}

		// Hide 'job title' column on non-GDPC systems.
		if (App.system.id !== 3) {
			this.$('.job-title').remove()
		}
	},

	/** @override */
	getRenderData: function() {
		var data = this.model.toJSON()

		// Append any extra user attributes
		if (this.model.extra) {
			var extra = this.model.extra.toJSON()
			_.each(extra, function(value, key) {
				if (key !== 'id') {
					data[key] = value
				}
			})
		}

		data.roleList = this.roleList.toJSON()
		data.groups = this.groupList_.toJSON()

		// Strip out all roles not for this system
		for (var i = 0; i < data.roleList.length; i++) {
			var role = data.roleList[i]

			if (role.systemId !== App.system.id) {
				data.roleList.splice(i, 1)
				i--
			}
		}

		var groupId = this.getCurrentRoleGroup_(),
			group = this.groupList_.get(groupId)

		if (group) {
			data.roleGroupName = group.get('name')
		}

		return data
	},

	edit: function() {
		if (this.roleList.length === 0) {
			this.roleList.once('sync', function() {
				this.render({edit: true})
			}, this)

			this.roleList.fetch()
		}

		this.render({edit: true})
	},

	save: function() {
		App.stopLoad()

		var jobTitle = this.$('.job-title-input').val()

		// Update user details, if changed
		this.model.set({
			firstName: this.$('.first-name').val(),
			lastName: this.$('.last-name').val(),
			username: this.$('.username').val(),
			email: this.$('.email').val()
		})

		if (this.model.hasChanged()) {
			App.startLoad()
			this.model.save()
		}

		// Update job title, if changed
		if (jobTitle && this.model.extra.get('jobTitle') !== jobTitle) {
			App.startLoad()

			this.model.extra.save({
				jobTitle: jobTitle
			})

			this.model.extra.once('sync', function() {
				// Trigger sync event on actual model to stop loading indicator
				this.model.trigger('sync')
			}, this)
		}

		// Update roles, if group changed.
		var newGroupId = parseInt(this.$('#user-group-select').val(), 10),
			groupId    = this.getCurrentRoleGroup_()

		if (!isNaN(newGroupId) && newGroupId !== groupId) {
			var roleList = this.roleList,
				newGroup = this.groupList_.get(newGroupId),
				newRoles = newGroup.get('roles'),
				oldRoles = roleList.where({systemId: App.system.id}),
				model    = this.model

			oldRoles = oldRoles.map(function(role) {
				return role.id
			})

			// Assign master role(s) as well.
			// TODO remove this once API has updated to do this automatically.
			var masterRoleIds = newRoles.map(function(id) {
				var role = this.systemRoleList_.get(id)

				return role.get('masterId')
			}, this)

			masterRoleIds = _.uniq(masterRoleIds)
			newRoles = newRoles.concat(masterRoleIds)
			model.removeRoles(oldRoles).then(function() {
				model.addRoles(newRoles).then(function() {
					roleList.fetch().then(function() {
						var roles = []
						roleList.forEach(function(role) {
							roles.push(role.get('id'))
						})
						model.set('roles', roles)
					})
				})
			})
		}
	},

	delete: function() {
		swal({
			title: $.t('editor.inspector.areYouSure'),
			text: $.t('users.removeUserConfirm'),
			type: 'warning',
			showCancelButton: true
		}, function(doRemove) {
			if (!doRemove) {
				return
			}

			App.startLoad()

			this.roleList.fetch().then(function() {
				var roles = this.roleList.where({systemId: App.system.id}),
					ids = roles.map(function(role) {
						return role.id
					})

				this.model.removeRoles(ids).then(function() {
					$.ajax({
						url: App.apiRoot + 'users/' + this.model.id,
						type: 'DELETE',
						headers: App.session.getHeadersObject(),
						success: function() {
							App.stopLoad()
							this.destroy()
						}.bind(this)
					})
				}.bind(this), error)
			}.bind(this), error)
		}.bind(this))

		function error() {
			App.stopLoad()
			swal($.t('error.oops'), $.t('users.failedToRemove'), 'error')
		}
	},

	/**
	 * @returns {?number} The ID of the first role group for which the user has
	 *     every required role, or {@code null} if no group is matched.
	 * @private
	 */
	getCurrentRoleGroup_: function() {
		var roles = this.model.get('roles'),
			groupId = null

		var previousLength = 0
		this.groupList_.forEach(function(group) {
			var hasGroup = group.get('roles').every(function(id) {
				return roles.indexOf(id) > -1
			})

			if (hasGroup) {
				var length = group.get('roles').length
				if (previousLength < length) {
					previousLength = length
					groupId = group.id
				}
			}
		})

		return groupId
	}
})
