module.exports = Backbone.View.extend({
	template: require('./general-notification-list-item-view-template'),
	tagName: 'tr',
	className: 'alert-item',

	events: {
		'click .pin-button': 'pin'
	},

	initialize: function() {
		// Re-render on event list reset (i.e. populate).
		this.listenTo(this.eventList, 'reset', this.render, this)
	},

	getRenderData: function() {
		var data = this.model.toJSON()

		var categoryId = this.model.get('type').category
		var category = this.disasterList.get(categoryId)
		if (category) {
			data.categoryName = category.get('codeName')
		}

		var eventId = this.model.get('type').event
		var event = this.eventList.get(eventId)
		if (event) {
			data.eventName = event.get('name')
		}

		var feedRegex = new RegExp(/\[(.*?)\]/)
		var regexResult = feedRegex.exec(this.model.get('description'))
		data.feed = regexResult ? regexResult[1] : App.system.apiCode

		return data
	},

	afterRender: function() {
		if (!App.acl.hasWritePermission('Hazards', 'Alerts')) {
			this.$('.pin-button').attr('disabled', true)
		}

		// Hide alert pinning on ARC.
		if (App.system.id === 9) {
			this.$('.pin-button').remove()
		}
	},

	pin: function() {
		App.startLoad()

		var options = _.clone(this.model.get('options'))
		options.pinned = !options.pinned

		this.model.unset('region')
		this.model.once('sync', this.pinComplete, this)
		this.model.save({options: options}, {globalAuth: true})
	},

	pinComplete: function() {
		this.render()
		App.stopLoad()
	}
})
