<div class="row">

    <div class="col-xs-12 actions-col">
        <button class="button green wide save-button">{{t "common.save"}}</button>
    </div>

    <div class="col-xs-12 tutorial-box">
        <h4>{{t "hazards.feeds.tutorial.heading"}}</h4>

        <p>{{t "hazards.feeds.tutorial.body"}}</p>
		<div>{{t "hazards.feeds.tutorial.steps"}}</div>
        <button class="button white close-tutorial-button">{{t "common.closeThisMessage"}}</button>
    </div>

    <table class="feeds-table col-xs-12">
        <thead>
        <tr>
            <th>{{t "hazards.feeds.feed"}}</th>
            <th>{{t "hazards.feeds.code"}}</th>
            <th>{{t "hazards.feeds.scope"}}</th>
            <th>{{t "hazards.feeds.languages"}}</th>
            <th>{{t "hazards.feeds.events"}}</th>
            <th>{{t "common.tools"}}</th>
        </tr>
        </thead>
        <tbody class="list-items"></tbody>
    </table>
</div>
