var ROOT_ID = 4281

var StormObject       = require('editor/storm-object'),
	MediaLibrary      = require('media-library/media-library-view'),
	MediaSelectorView = require('media-library/media-selector-view'),
	EditorSectionView = require('editor/editor-section-view')

/**
 * Exports {@link DecisionTreeView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends DecisionTreeView.prototype */{
	/** @override */
	template: require('./decision-tree-view-template'),

	/** @override */
	events: {
		'click .choose-image-button': 'chooseImage',
		'click .add-question-link': 'addQuestion',
		'click .add-answer-button': 'addAnswer',
		'click .save-button': 'save'
	},

	/**
	 * @constructs DecisionTreeView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		this._readyCount = 0
		this._totalReadyCount = 3

		this.views = {}

		this.app = options.app
		this.id = options.id || ROOT_ID

		this.model = StormObject.fromProperties({id: this.id})
		this.model.once('sync', this.ready, this)
		this.model.fetch()

		this.app.pageList.fetchOnce()
			.then(this.ready.bind(this))

		// Fetch enabled languages for this app.
		this.app.languageList.fetchOnce()
			.then(this.ready.bind(this))
	},

	/** @override */
	getRenderData: function() {
		var data = this.model.toJSON()
		data.appId = this.app.id

		var pageSelect = '<option value="">-</option>'

		// Get array of all unique tag names
		var tags = _.filter(this.app.pageList.pluck('tag'), function(elem, pos, self) {
			return self.indexOf(elem) === pos
		}).sort()

		// Output an option group for each tag
		_.each(tags, function(tag) {
			var taggedPages = this.app.pageList.where({tag: tag}),
				options     = ''

			// Output an option for each page with this tag
			_.each(taggedPages, function(page) {
				options += '<option value="cache://pages/' + page.id + '.json">' + App.l(page.get('title')) + '</option>'
			})

			pageSelect += '<optgroup label="' + tag + '">' + options + '</optgroup>'
		}, this)

		data.pageSelect = pageSelect
		return data
	},

	/** @override */
	render: function() {
		return Backbone.View.prototype.render.call(this, {languages: this.app.languageList.toJSON()})
	},

	/** @override */
	afterRender: function() {
		if (!this.model.attributes.text) {
			return
		}

		// Set value of all 'Page' selects.
		var answers = this.model.get('answers')

		this.$('.answer-page').each(function() {
			var index  = Number($(this).parent().parent().parent().data('answer')),
				answer = answers.at(index)

			var link = answer.get('src')

			if (link) {
				$(this).val(link.destination)
			}
		})

		// Populate all text inputs.
		_.each(this.model.attributes.text.content, function(text, lang) {
			this.$('.question-text[data-lang=' + lang + ']').val(text)
		}, this)

		this.model.attributes.answers.forEach(function(answer, i) {
			_.each(answer.attributes.text.content, function(text, lang) {
				this.$('[data-answer=' + i + '] .answer-text[data-lang=' + lang + ']').val(text)
			}, this)
		}, this)
	},

	ready: function() {
		if (++this._readyCount === this._totalReadyCount) {
			this.render()
			App.stopLoad()
		}
	},

	// Pull any changes out of the view onto the model and save.
	updateModel: function() {
		var model = this.model

		// Update question text for all languages.
		this.$('.question-text').each(function() {
			var lang = $(this).data('lang')

			model.attributes.text.content[lang] = $(this).val()
		})

		// Update all answers.
		model.attributes.answers.forEach(function(answer, i) {
			// Update text for all languages.
			this.$('[data-answer=' + i + '] .answer-text').each(function() {
				var lang = $(this).data('lang')

				answer.attributes.text.content[lang] = $(this).val()
			})

			// Update page if set, or remove model.
			var page = this.$('[data-answer=' + i + '] .answer-page').val()

			if (page !== '') {
				if (!answer.attributes.src) {
					answer.attributes.src = App.getClassStructure('InternalLink', 0)
				}

				answer.attributes.src.destination = page
			} else if (answer.attributes.src) {
				// Delete link if it exists.
				if (answer.attributes.src.id) {
					$.ajax({
						url: App.apiRoot + 'objects/' + answer.attributes.src.id,
						type: 'DELETE',
						headers: App.session.getHeadersObject()
					})
				}

				answer.attributes.src = null
			}
		}, this)

		return Promise.resolve(model.save())
	},

	chooseImage: function(e) {
		var answerIndex = $(e.currentTarget).parent().parent().parent().data('answer'),
			image       = this.model.get('answers').at(answerIndex).get('image'),
			self        = this

		this.views.mediaLibrary = new MediaSelectorView({
			app: this.app,
			model: image,
			mediaType: MediaLibrary.types.IMAGE
		})

		this.views.mediaLibrary.on('change', function() {
			var preview = self.$('[data-answer=' + answerIndex + '] img')[0]

			preview.src = Handlebars.helpers.getImagePreviewUrl(image)
		}, this)

		$('body').append(this.views.mediaLibrary.el)
		this.views.mediaLibrary.render().show()
	},

	addQuestion: function(e) {
		var appId = this.app.id

		// Add new question, redirect to it.
		var answerIndex = $(e.currentTarget).parent().parent().parent().data('answer'),
			answer      = this.model.attributes.answers.at(answerIndex)

		answer.set('question', App.getClassStructure('StormQLQuestion', 0))

		this.updateModel().then(function() {
			return Promise.resolve(answer.save())
		}).then(function() {
			App.router.navigate('/apps/' + appId + '/decision-tree/' + answer.get('question').id, {trigger: true})
		})

		App.startLoad()
	},

	addAnswer: function() {
		// Add new answer, update preview.
		var answer = App.getClassStructure('StormQLOption', 0)
		answer.src = null
		answer.question = null

		this.model.attributes.answers.add(answer)
		this.updateModel()
		this.render()
	},

	save: function() {
		App.startLoad()

		this.model.once('sync', function() {
			App.stopLoad()
			App.showToast($.t('hazards.disasters.saveSuccess'))
		})

		this.updateModel()
	}
})
