var StormView = require('lib/storm-view'),
	/** @type {StormGlobals} */
	globals = require('globals')

/**
 * Exports {@link PublishSectionView}.
 * @module
 */
module.exports = StormView.extend(/** @lends PublishSectionView.prototype */{
	/**
	 * @constructs PublishSectionView
	 * @extends StormView
	 * @override
	 */
	initialize: function() {
	},

	/** @override */
	getSectionTitle: function() {
		return $.t('publish.title')
	},

	/** @override */
	getPages: function() {
		var acl = globals.getAcl()

		return [
			{
				name: $.t('publish.title'),
				url: 'publish',
				isVisible: function() {
					return acl.hasAccess('Publish')
				},
				views: [
					require('publish/publish-view')
				]
			}
		]
	}
})
