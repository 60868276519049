module.exports = Backbone.Model.extend({
	initialize: function(options) {
		options = options || {}
		this.appId = options.appId
	},

	urlRoot: function() {
		var url = App.apiRoot + 'classes'

		if (this.appId) {
			url = App.apiRoot + 'apps/' + this.appId + '/classes'
		}

		return url
	},

	parse: function(response) {
		var classes = {}

		_.each(response, function(value) {
			classes[value.class] = value
		})

		// Create fake EmailLink class to assist editing. EmailLink already
		// exists, but is not supported by apps - use in frontend and transform
		// during save/load.
		classes.EmailLink = {
			class: 'EmailLink',
			title: '{Text}',
			segue: '<String>',
			destination: '<String>'
		}

		// Create fake CallLink class to assist editing. Doesn't exist in API,
		// transform when saving/loading.
		classes.CallLink = {
			class: 'CallLink',
			title: '{Text}',
			segue: '<String>',
			destination: '<String>'
		}

		// Create fake Text/ImageQuizItemOption class to assist editing.
		// Doesn't exist in API, transform when saving/loading.
		classes.TextQuizItemOption = {
			class: 'TextQuizItemOption',
			title: '{Text}',
			isCorrect: '<Boolean>'
		}

		classes.ImageQuizItemOption = {
			class: 'ImageQuizItemOption',
			title: '{Text}',
			image: '{Image}',
			isCorrect: '<Boolean>'
		}

		// Drop transformed keys from QuizItem classes.
		var TextQuizItem = classes.TextQuizItem || classes.TextSelectionQuestion

		delete TextQuizItem.answer
		TextQuizItem.options = '[TextQuizItemOption]'

		var ImageQuizItem = classes.ImageQuizItem ||
			classes.ImageSelectionQuestion

		delete ImageQuizItem.answer
		delete ImageQuizItem.images
		ImageQuizItem.options = '[ImageQuizItemOption]'

		// Drop transformed keys from RSPB GNAH
		if (classes.Gnah_FactFile) {
			var FactFileItem = classes.Gnah_FactFile
			delete FactFileItem.features
			delete FactFileItem.activities
			delete FactFileItem.images
		}

		return classes
	}
})
