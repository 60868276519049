<div class="row">
    <div class="col-sm-6">
        <h4 data-i18n="crossBorders.phrases"></h4>
    </div>
    <div class="col-sm-6 actions-col">
        <a href="/apps/{{appId}}/cross-borders/phrase/{{parentId}}/new">
            <button class="button wide add-phrase">{{t "common.add"}}</button>
        </a>
    </div>
</div>
<table>
	<thead>
		<tr>
			<th>{{t "crossBorders.phrase.phrase"}}</th>
			<th>{{t "crossBorders.phrase.translation"}}
			</th>
			<th>{{t "common.tools"}}</th>
		</tr>
	</thead>
	<tbody class="list-items"></tbody>
</table>
