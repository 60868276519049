var UserExtra = require('./user-extra')

/**
 * Exports {@link User}.
 * @module
 */
module.exports = Backbone.Model.extend(/** @lends User.prototype */{
	/** @override */
	urlRoot: App.authRoot + 'users',

	/**
	 * @constructs User
	 * @extends Backbone.Model
	 * @override
	 */
	initialize: function() {
		this.extra = null
	},

	/** @override */
	fetch: function() {
		var userFetch = Backbone.Model.prototype.fetch.apply(this, arguments)

		if (this.extra === null) {
			this.extra = new UserExtra({id: this.id})
		}

		var self = this

		// Wrap extra fetch to swallow error if the endpoint doesn't exist.
		var extraFetch = new Promise(function(resolve) {
			self.extra.fetch().then(resolve, function() {
				self.extra = null
				resolve()
			})
		})

		return Promise.all([userFetch, extraFetch])
	},

	/** @override */
	save: function(attributes, options) {
		if (this.isNew()) {
			// New user object needs randomly generated password set
			this.attributes.password = Math.random().toString(36).substr(2)

			this.once('sync', function(model, response, options) {
				if (options.xhr.status === 200) {
					swal("Success", $.t('users.success'), 'success')
				} else if (options.xhr.status === 400) {
					swal($.t('error.oops'), $.t('users.error'), 'error')
				}
			})
		} else {
			// Don't send password/system role ID on save
			this.set(attributes)
			attributes = null

			options = options || {}
			options.attrs = this.toJSON()

			delete options.attrs.password
			delete options.attrs.roleId
		}

		return Backbone.Model.prototype.save.call(this, attributes, options)
	},

	addRoles: function(ids) {
		ids = ids.map(function(id) {
			return {id: id}
		})

		var model = this
		var data = {roles: ids}

		return Backbone.sync('update', model, {
			url: model.url() + '/roles',
			data: JSON.stringify(data),
			complete: function() {
				model.trigger('sync')
			}
		})
	},

	removeRoles: function(ids) {
		ids = ids.map(function(id) {
			return {id: id}
		})

		var model = this
		var data = {roles: ids}

		return Backbone.sync('delete', model, {
			url: model.url() + '/roles',
			data: JSON.stringify(data),
			complete: function() {
				model.trigger('sync')
			}
		})
	}
})
