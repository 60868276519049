{{#if @edit}}
	<td>
		<div>
			<h6>{{t "users.firstName"}}</h6>
			<input type="text" class="form-control first-name" value="{{firstName}}">
			<h6>{{t "users.lastName"}}</h6>
			<input type="text" class="form-control last-name" value="{{lastName}}">
			<h6>{{t "common.username"}}</h6>
			<input type="text" class="form-control username" value="{{username}}">
		</div>
	</td>
	<td class="job-title"><input type="text" class="form-control job-title-input" value="{{jobTitle}}"></td>
	<td><input type="email" class="form-control email" value="{{email}}"></td>
	<td>
		<select class="form-control" id="user-group-select">
			{{#each groups}}
				<option value="{{id}}">{{name}}</option>
			{{/each}}
		</select>
	</td>
	<td class="center">-</td>
	<td class="center">-</td>
	<td class="tools">
		<i class="icon-save save"></i>
		<i class="icon-remove cancel"></i>
	</td>
{{else}}
	<td class="center">
		<img class="avatar" src="{{gravatar email}}">
		<div>
			<div>{{firstName}} {{lastName}}</div>
			<div class="sub-text">{{username}}</div>
		</div>
	</td>
	<td class="center job-title">{{jobTitle}}</td>
	<td class="center"><a href="mailto:{{email}}">{{email}}</a></td>
	<td class="center">{{roleGroupName}}</td>
	<td class="center apps-link"><a href="{{link "users"}}/{{id}}/apps">{{t "common.view"}}</a></td>
	<td class="center">{{formatTime lastSeen}}</td>
	<td class="tools">
		<i class="icon-pencil edit"></i>
		<i class="icon-trash delete"></i>
	</td>
{{/if}}
