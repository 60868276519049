var TagBlacklist = require('./tag-blacklist')

module.exports = Backbone.View.extend({
	template: require('./media-library-item-view-template'),

	events: {
		'click .add-tag-button': 'addTag',
		'click .add-tag-save-button': 'saveTag',
		'click .tag-remove-button': 'removeTag'
	},

	attributes: function() {
		return {'data-id': this.model.id}
	},

	initialize: function(options) {
		this.tagList = options.tagList
		this.listenTo(this.model, 'change', this.render, this)

		if (this.afterInitialize) {
			this.afterInitialize(options)
		}
	},

	getRenderData: function() {
		var data = {
			id: this.model.id,
			tags: []
		}

		this.model.get('tags').forEach(function(id) {
			var tag = this.tagList.get(id),
				tagName = tag.get('name')

			if (TagBlacklist.indexOf(tagName) > -1 || tagName.match(/(^animation-|frame-)/) !== null) {
				return
			}

			data.tags.push(tag.toJSON())
		}, this)

		return data
	},

	addTag: function(e) {
		this.$el.css('z-index', 1)
		this.$('.add-tag-button').hide()
		this.$('.add-tag-form').show()
		this.$('.add-tag-input').focus()

		e.stopPropagation()
	},

	saveTag: function(e) {
		e.preventDefault()

		var tagName = this.$('.add-tag-input').val().toLowerCase()

		if (!tagName || TagBlacklist.indexOf(tagName) > -1 || tagName.match(/^animation-/) !== null) {
			return false
		}

		// Check for invalid tags (containing wildcard).
		if (tagName.indexOf('*') > -1) {
			swal($.t('error.oops'), $.t('mediaLibrary.upload.invalidTag'), 'error')
			return false
		}

		App.startLoad()
		this.$('.add-tag-save-button').attr('disabled', true)

		var tagSaved = new jQuery.Deferred()

		// Check if the new tag already exists in the DB.
		var tag = this.tagList.findWhere({name: tagName})

		if (!tag) {
			tag = this.tagList.create({name: tagName, type: 'files'})
			tag.once('sync', function() {
				tagSaved.resolve()
			})
		} else {
			tagSaved.resolve()
		}

		// Add tag to all sizes of this image once tag is ready.
		tagSaved.then(function() {
			this.model.addTag(tag, true).then(function() {
				this.model.trigger('change', this.model)
				App.stopLoad()
			}.bind(this))
		}.bind(this))
	},

	removeTag: function(e) {
		// Don't let the button click trigger/link navigate.
		e.stopPropagation()
		e.preventDefault()

		if (!confirm($.t('mediaLibrary.confirmTagRemoval'))) {
			return
		}

		App.startLoad()

		// Remove tag from all resolution images.
		var id = $(e.currentTarget).data('id')

		this.model.removeTag({id: id}, true).then(function() {
			this.model.trigger('change', this.model)
			App.stopLoad()
		}.bind(this))
	}
})
