var StormView = require('lib/storm-view'),
	/** @type {StormGlobals} */
	globals = require('globals')

/**
 * Exports {@link HazardsSectionView}.
 * @module
 */
module.exports = StormView.extend(/** @lends HazardsSectionView.prototype */{
	/**
	 * @constructs HazardsSectionView
	 * @extends StormView
	 * @override
	 */
	initialize: function() {
	},

	/** @override */
	loadData: function() {
		return Storm.app.pluginList.fetchOnce()
	},

	/** @override */
	getSectionTitle: function() {
		return $.t('hazards.title')
	},

	/** @override */
	getPages: function() {
		var acl = globals.getAcl()

		return [
			{
				name: $.t('hazards.disasters.title'),
				url: 'hazards',
				isVisible: function() {
					return acl.hasAccess('Hazards', 'Disasters')
				},
				views: [
					require('hazards/disasters-view')
				]
			},
			{
				name: $.t('hazards.alertZones.title'),
				url: 'alert-zones',
				isVisible: function() {
					if (Storm.app) {
						if (!Storm.app.pluginList.hasPlugin('Zones')) {
							return false
						}
						return acl.hasAccess('Hazards', 'Disasters') || acl.hasAccess('Hazards', 'Alerts')
					}
					return false
				},
				views: [
					require('hazards/alert-zones-view'),
					require('hazards/alert-zones-edit-view')
				]
			},
			{
				name: $.t('hazards.alerts.title'),
				url: 'alerts',
				isVisible: function() {
					return acl.hasAccess('Hazards', 'Alerts')
				},
				views: [
					require('hazards/alerts-view'),
					require('hazards/alert-edit-view'),
					require('hazards/alert-test-view')
				]
			},
			{
				name: $.t('hazards.generalNotifications.title'),
				url: 'general-notifications',
				isVisible: function() {
					return acl.hasAccess('Hazards', 'Alerts') && App.system.apiCode === 'GDPC'
				},
				views: [
					require('hazards/general-notifications-view'),
					require('hazards/general-notifications-edit-view')
				]
			},
			{
				name: $.t('whatnow.whatnow'),
				url: 'whatnow',
				isVisible: function() {
					return App.userRoles.roleExists(109) && App.system.apiCode === 'GDPC' // Look for Role  109
				},
				views: [
					require('whatnow/whatnow-view'),
					require('whatnow/whatnow-item-edit-view')
				]
			},
			{
				name: $.t('hazards.feeds.title'),
				url: 'feeds',
				isVisible: function() {
					if (Storm.app) {
						if (!Storm.app.pluginList.hasPlugin('Feeds')) {
							return false
						}
						return acl.hasAccess('Hazards', 'Disasters') || acl.hasAccess('Hazards', 'Alerts')
					}
					return false
				},
				views: [
					require('hazards/feeds/feeds-view')
				]
			},
			{
				name: $.t('moderation.title'),
				url: 'moderation',
				isVisible: function() {
					// Moderation currently only available on ARC Wildfire
					return App.system.id === 9 && Storm.app.id === 6
				},
				views: [
					require('moderation/image-moderation-view')
				]
			}
		]
	},

	/** @override */
	isVisible: function() {
		var acl       = globals.getAcl(),
			disasters = acl.hasAccess('Hazards', 'Disasters'),
			alerts    = acl.hasAccess('Hazards', 'Alerts')

		var hasPermission = disasters || alerts,
			isHazardsApp  = Storm.app && Storm.app.isHazardsApp(),
			isFirstAid = Storm.app && Storm.app.isFirstAidApp()
		if (App.userRoles) {
			return (hasPermission && isHazardsApp) || (App.userRoles.roleExists(109) && App.system.apiCode === 'GDPC' && !isFirstAid)
		}
		return hasPermission && isHazardsApp
	}
})
