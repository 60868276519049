module.exports = Backbone.View.extend({
	template: require('./localisation-editor-view-template'),

	events: {
		'click .save-localisations-button': 'saveButtonClick',
		'click .key-view-control button': 'keyViewControlButtonClick'
	},

	initialize: function() {
		this.filterText = ''
	},

	getRenderData: function() {
		return this.model
	},

	saveButtonClick: function() {
		this.trigger('save')
	},

	// Get a hash of strings which have been modified in the editor and not saved.
	getChangedStrings: function() {
		var dirtyStrings = {}

		// Update model from inputs.
		this.model.forEach(function(localisation) {
			var newString = {}

			localisation.languages.forEach(function(lang) {
				var newValue = this.$('input[data-key=' + localisation.key + '][data-code=' + lang.code + ']').val()
				newString[lang.code] = newValue
			}, this)

			var isDirty = localisation.languages.some(function(lang) {
				return newString[lang.code] !== lang.value
			})

			if (isDirty) {
				dirtyStrings[localisation.key] = newString
			}
		}, this)

		return dirtyStrings
	},

	// Set the text to filter by.
	setFilter: function(filter) {
		this.filterText = filter
		this.filter()
	},

	// Hide rows which don't match the current filter text.
	filter: function() {
		this.$('tbody tr.hidden').removeClass('hidden')

		this.model.forEach(function(string, i) {
			if (!this.stringMatchesFilter(string, this.filterText)) {
				this.$('tbody tr').eq(i).addClass('hidden')
			}
		}, this)
	},

	// Match Filter value against actual values and the key value
	stringMatchesFilter: function(stringObj, filter) {
		var keyFilterValue = filter.replace(/\s/g, '_').toUpperCase()
		var literalValueFound = false
		stringObj.languages.forEach(function(language) {
			if (!literalValueFound && language.value) {
				literalValueFound = language.value.toLowerCase().includes(filter.toLowerCase())
			}
		})

		return stringObj.key.match(keyFilterValue) || literalValueFound
	},

	// Toggle between formatted string and raw key display.
	keyViewControlButtonClick: function(e) {
		var $button = $(e.currentTarget)
		this.$('.key-view-control .active').removeClass('active')
		$button.addClass('active')
		this.setKeyDisplay($button.val())
	},

	// Switch between key and formatted string display.
	setKeyDisplay: function(type) {
		this.$('.key-col span').addClass('hidden')
		this.$('.key-col .' + type).removeClass('hidden')
	}
})
