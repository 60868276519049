var StormQLListView = require('stormql/stormql-list-view')

module.exports = StormQLListView.extend({
	template: require('./stormql-blood-list-view-template'),

	afterInitialize: function() {
		StormQLListView.prototype.afterInitialize.apply(this, arguments)

		this.events['click .upload-journey'] = 'uploadJourneyButtonClick'
		this.events['change .journey-upload-input'] = 'uploadInputChange'
		this.delegateEvents()
	},

	uploadJourneyButtonClick: function() {
		this.$('.journey-upload-input').click()
	},

	uploadInputChange: function(e) {
		App.startLoad()

		var formData = new FormData()
		formData.append('file', e.currentTarget.files[0])

		$.ajax({
			url: App.bloodRoot + 'journeys/import',
			type: 'POST',
			data: formData,
			contentType: false,
			processData: false,
			headers: App.session.getHeadersObject()
		}).then(uploadSuccess, uploadFail)
	}
})

function uploadSuccess() {
	App.stopLoad()
	App.showToast('CSV imported successfully.')
}

function uploadFail() {
	App.stopLoad()
	swal($.t('error.oops'), 'CSV import failed.', 'error')
}
