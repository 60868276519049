var UploadList = require('./upload-list'),
	Upload     = require('./upload'),
	UploadVariants = require('./upload-variants')

var legacyFileName = /^(.*_)x\d(?:\.\d{1,2})?(\.\w{3,4})$/

module.exports = Backbone.View.extend({
	template: require('./media-edit-view-template'),
	className: 'media-edit storm-modal fade in',

	events: {
		'click .cancel-button': 'close',
		'click .done-button': 'done',
		'click .delete-button': 'deleteImage',
		'click .density-switch a': 'densitySwitch',
		'sortupdate .animation-frames': 'frameChange',
		'click .global-delete': 'globalDeleteButtonClick',
		'click .add-frame-link': 'addFrame',
		'click .remove-frame-link': 'removeFrame',
		'click .convert-to-image': 'convertToImage',
		'click .convert-to-icon': 'convertToIcon',
		'click .remove-media-type': 'removeMediaType'
	},

	initialize: function(options) {
		this.media = options.media
		this.tagList_ = options.tagList
		this.frame = 0
		this.frames = []
		this.mediaType = null
		this.animationId = null
		this.animationTimer = 0

		this.app_ = options.app

		var filename = this.media.get('filename')
		this.isLegacyFileType = legacyFileName.test(filename)

		if (!this.isLegacyFileType) {
			this.variants = new UploadVariants({id: this.media.get('parentId')})
			this.variants.fetch().then(function() {
				this.variants.sort()
				this.render()
			}.bind(this))
		}

		// Extract media type tag, animation ID.
		this.media.get('tags').forEach(function(tagId) {
			var tag = this.tagList_.get(tagId)

			if (!tag) {
				return
			}

			var tagName = tag.get('name')

			switch (tagName) {
				case 'animation':
				case 'image':
				case 'icon':
				case 'video':
					this.mediaType = tagName
					return
			}

			var animationMatch = tagName.match(/^animation-(\w+)$/)

			if (animationMatch !== null) {
				this.animationId = animationMatch[1]
			}
		}, this)

		// Fetch all frames for animations.
		if (this.animationId !== null) {
			var frames = new UploadList(),
				self   = this

			App.startLoad()

			frames.fetch({
				headers: {Range: 'indices=0-9999'},
				data: {tags: 'animation-' + this.animationId}
			}).then(function() {
				frames.forEach(function(frame) {
					var frameIndex = frame.getFrameIndex(self.tagList_, self.animationId)

					if (frameIndex !== null) {
						self.frames[frameIndex] = frame
					}
				})

				self.render()

				App.stopLoad()
			})
		}
	},

	getRenderData: function() {
		var data = {
			mediaType: this.mediaType
		}

		if (this.mediaType === 'video') {
			// Return single media object for videos.
			data.video = this.media.toJSON()
			data.downloadLink = this.media.get('filename')
		} else if (this.mediaType === 'animation') {
			// Return array of single density frames for animations.
			data.animation = this.frames.map(function(file) {
				return file.toJSON()
			})
		} else {
			// Get all image resolutions from initial filename.
			var image    = [],
				filename = this.media.get('filename')
				// is Legacy type
			if (legacyFileName.test(filename)) {
				var filenameMatch = filename.match(legacyFileName);

				['x2', 'x1.5', 'x1', 'x0.75'].forEach(function(density) {
					image.push({
						density: density,
						filename: filenameMatch[1] + density + filenameMatch[2]
					})
				}, this)

				// Return array of all image densities for images.
				data.downloadLink = filenameMatch[1] + 'orig' + filenameMatch[2]
				data.legacy = true
			} else {
				// New way of handling file names:
					// Sort Variants in order of size.
				this.variants.forEach(function(source, key) {
					switch (key) {
						case 0: // Original Link
							data.downloadLink = source.get('filename')
							break
						case 1:
							image.push({
								density: 'x2',
								filename: source.get('filename')
							})
							break
						case 2:
							image.push({
								density: 'x1',
								filename: source.get('filename')
							})
							break
						case 3:
							break
					}
				})
			}
			data.image = image
		}
		return data
	},

	afterRender: function() {
		// Animation-specific rendering
		if (this.mediaType === 'animation') {
			// Start animation preview
			clearInterval(this.animationTimer)
			this.animationTimer = setInterval(this.nextAnimationFrame.bind(this), 500)
			this.nextAnimationFrame()

			// Enable drag and drop frame reordering
			this.$('.animation-frames').sortable()

			this.fileUploadInit()
		}

		// Show global delete button for developer accounts
		if (App.developerMode) {
			this.$('.global-delete').show()
		}
	},

	done: function() {
		if (this.mediaType === 'animation') {
			this.saveFrameOrder()
			this.media.trigger('change', this.media)
		}

		this.close()
	},

	close: function() {
		this.trigger('close')
		this.destroy()
	},

	densitySwitch: function(e) {
		var density = $(e.currentTarget).data('density')
		this.$('.selected').removeClass('selected')
		$(e.currentTarget).addClass('selected')

		this.$('img').hide()
		this.$('img[data-density="' + density + '"]').show()
	},

	deleteImage: function() {
		if (!confirm($.t('editor.inspector.confirmDelete'))) {
			return
		}

		var model = JSON.stringify({
			apps: this.app_.id,
			all: true
		})

		var files

		if (this.mediaType === 'animation') {
			files = this.frames
		} else {
			files = [this.media]
		}

		files.forEach(function(file) {
			// Remove from uploads collection
			if (file.collection) {
				file.collection.remove(file)
			}

			// Remove file on server
			Backbone.sync('delete', new Backbone.Model(), {
				url: App.apiRoot + 'uploads/' + file.id,
				data: model,
				contentType: 'application/json'
			})
		})

		// Return to media library
		this.close()
	},

	nextAnimationFrame: function() {
		this.$('.image-preview img').hide()
		this.$('.image-preview img[data-frame=' + this.frame + ']').show()

		if (++this.frame === this.frames.length) {
			this.frame = 0
		}
	},

	// Called when frames reordered
	frameChange: function() {
		this.$('.animation-frames img').each(function(i) {
			var arrayIndex = $(this).attr('data-index')
			$('.image-preview img[data-index=' + arrayIndex + ']').attr('data-frame', i)
		})
	},

	// Update frame indexes for each image
	saveFrameOrder: function() {
		var frames      = this.frames,
			tagList     = this.tagList_,
			animationId = this.animationId

		this.$('.image-preview img').each(function() {
			var frame = Number($(this).attr('data-frame')),
				index = Number($(this).attr('data-index'))

			if (frame !== index) {
				// Update frame index for this file
				var oldFrameTag = tagList.findWhere({name: animationId + '-frame-' + index}),
					newFrameTag = tagList.findWhere({name: animationId + '-frame-' + frame})

				var file = frames[index]

				file.removeTag(oldFrameTag, true)
				file.addTag(newFrameTag, true)
			}
		})
	},

	globalDeleteButtonClick: function() {
		if (!confirm('This image will be removed from the media library for all societies.\n\nContinue?')) {
			return
		}

		this.globalDelete()

		// Return to media library
		this.close()
	},

	// Deletes file from database. Removes from ALL apps' media libraries
	globalDelete: function(files) {
		// Get default set of files to delete if none specified.
		if (!files) {
			if (this.mediaType === 'animation') {
				files = this.frames
			} else {
				files = [this.media]
			}
		}

		files.forEach(function(file) {
			// Remove from uploads collection
			if (file.collection) {
				file.collection.remove(file)
			}

			// Remove file on server
			Backbone.sync('delete', new Backbone.Model(), {
				url: App.apiRoot + 'uploads/' + file.id,
				data: JSON.stringify({all: true}),
				contentType: 'application/json'
			})
		})
	},

	// Upload a new image and add to this animation.
	addFrame: function() {
		this.$('.frame-input').click()
	},

	removeFrame: function(e) {
		var img = $(e.currentTarget).prev()

		var frameIndex  = Number(img.attr('data-index')),
			frameNumber = Number(img.attr('data-frame')),
			files       = [this.frames[frameIndex]],
			frame

		this.globalDelete(files)

		// Remove frame from view.
		this.frames.splice(frameIndex, 1)
		this.$('.animation-frames img[data-index=' + frameIndex + ']').parent().remove()
		this.$('img[data-index=' + frameIndex + ']').remove()

		while (++frameIndex <= this.frames.length) {
			frame = this.$('img[data-index=' + frameIndex + ']')
			frame.attr('data-index', frameIndex - 1)
		}

		while (++frameNumber <= this.frames.length) {
			frame = this.$('img[data-frame=' + frameNumber + ']')
			frame.attr('data-frame', frameNumber - 1)

			var index = Number(frame.attr('data-index'))

			// Update frame tag for this frame.
			var oldFrameTag = this.tagList_.findWhere({name: this.animationId + '-frame-' + frameNumber}),
				newFrameTag = this.tagList_.findWhere({name: this.animationId + '-frame-' + (frameNumber - 1)})

			var file = this.frames[index]

			file.removeTag(oldFrameTag, true)
			file.addTag(newFrameTag, true)
		}

		// Trigger change to update preview in library.
		this.media.trigger('change', this.media)
	},

	fileUploadInit: function() {
		// Get next frame index tag.
		var tags       = [],
			frameIndex = this.animationId + '-frame-' + this.frames.length,
			frameTag   = this.tagList_.findWhere({name: frameIndex})

		if (frameTag) {
			tags.push(frameTag.id)
		} else {
			frameTag = this.tagList_.create({
				name: frameIndex,
				type: 'files'
			})

			frameTag.once('sync', function() {
				this.fileUploadInit()
			}.bind(this))

			return
		}

		// Get tags for any new uploads from  the existing frame
		this.media.get('tags').forEach(function(tagId) {
			var tag     = this.tagList_.get(tagId),
				tagName = tag.get('name')

			if (tagName.indexOf('frame-') === -1) {
				tags.push(tagId)
			}
		}, this)

		var formData = {
			Authorization: App.session.get('token'),
			appId: this.app_.id,
			tags: tags.join(',')
		}

		this.$('.frame-input').fileupload({
			dataType: 'json',
			traditional: true,
			url: UploadList.prototype.url(),

			formData: formData,

			submit: function() {
				App.startLoad()
			},

			error: function(jqXHR) {
				switch (jqXHR.status) {
					case 413:
						swal($.t('error.oops'), $.t('mediaLibrary.upload.filesizeError') + ' 4MB', 'error')
						break

					case 415:
						swal($.t('error.oops'), $.t('mediaLibrary.upload.formatError'), 'error')
						break

					default:
						swal($.t('error.oops'), $.t('mediaLibrary.upload.genericError') + ' ' + jqXHR.status, 'error')
				}

				App.stopLoad()
			},

			done: function(e, data) {
				this.frames.push(new Upload(data.result))
				this.render()

				// Trigger change on the first frame to re-render preview in
				// library.
				this.media.trigger('change', this.media)

				App.stopLoad()
			}.bind(this)
		})
	},

	beforeDestroy: function() {
		if (this.mediaType === 'animation') {
			try {
				this.$('.frame-input').fileupload('destroy')
			} catch (e) {
			}
		}
	},

	// Remove 'icon' tag, add 'image'.
	convertToImage: function() {
		if (!confirm('Are you sure?')) {
			return
		}

		var oldTag = this.tagList_.findWhere({name: 'icon'}),
			newTag = this.tagList_.findWhere({name: 'image'})

		this.media.removeTag(oldTag, true)
		this.media.addTag(newTag, true)

		// Remove from uploads collection
		if (this.media.collection) {
			this.media.collection.remove(this.media)
		}

		this.close()
	},

	// Remove 'image' tag, add 'icon'.
	convertToIcon: function() {
		if (!confirm('Are you sure?')) {
			return
		}

		var oldTag = this.tagList_.findWhere({name: 'image'}),
			newTag = this.tagList_.findWhere({name: 'icon'})

		this.media.removeTag(oldTag, true)
		this.media.addTag(newTag, true)

		// Remove from uploads collection
		if (this.media.collection) {
			this.media.collection.remove(this.media)
		}

		this.close()
	},

	// Remove any media type tags - hide media from library entirely.
	removeMediaType: function() {
		if (!confirm('Are you sure you want to hide this item? It will no longer be visible for any society.')) {
			return
		}

		var media        = this.media,
			tagsToRemove = ['animation', 'video', 'image', 'icon']

		tagsToRemove = tagsToRemove.map(function(name) {
			return this.tagList_.findWhere({name: name})
		}, this)

		tagsToRemove = tagsToRemove.filter(function(tag) {
			return media.get('tags').indexOf(tag.id) > -1
		})

		tagsToRemove.forEach(function(tag) {
			if (tag) {
				media.removeTag(tag, true)
			}
		})

		// Remove from uploads collection
		if (media.collection) {
			media.collection.remove(media)
		}

		this.close()
	}
})
