var ListViewItem = require('list-view/list-view-item')

module.exports = ListViewItem.extend({
	template: require('./achievement-list-item-view-template'),

	initialize: function(options) {
		this.appId = options.appId
	},

	getRenderData: function() {
		var data = this.model.toJSON()

		data.appId = this.appId
		return data
	},

	afterRender: function() {
		var permission = App.acl.getPermission('Content')

		// "Read only" if the model has a page ID (broken, wont be able to edit).
		if (this.model.get('pageId') !== 0) {
			permission = 'Read'
		}

		var $tools = this.$('.tools')

		$tools.find('.delete, .edit').hide()

		switch (permission) {
			case 'Delete':
				$tools.find('.delete, .edit').show()
				break

			case 'Write':
				$tools.find('.edit').show()
				break
		}
	}
})
