var FrontendList = require('./frontend-list'),
	DeploymentSectionView = require('./deployment-section-view')

function sortVersions(versions) {
	return versions.sort(function(a, b) {
		a = a.tag.substr(1).split('.').map(Number)
		b = b.tag.substr(1).split('.').map(Number)

		if (a[0] < b[0]) {
			return -1
		}

		if (a[0] > b[0]) {
			return 1
		}

		if (a[1] < b[1]) {
			return -1
		}

		if (a[1] > b[1]) {
			return 1
		}

		if (a[2] < b[2]) {
			return -1
		}

		if (a[2] > b[2]) {
			return 1
		}

		return 0
	})
}

module.exports = DeploymentSectionView.extend({
	template: require('./deployment-view-template'),

	events: {
		'click .update-button': 'updateSystem',
		'click .settings-button': 'settingsClick',
		'click .deploy-new-system': 'deployNewSystem'
	},

	initialize: function() {
		this.model = new FrontendList()

		$.ajax({
			url: App.deploymentRoot + 'auth',
			headers: App.session.getHeadersObject(),
			dataType: 'text',
			success: this.authSuccess.bind(this),
			error: this.authError.bind(this)
		})
	},

	authSuccess: function() {
		this.listenTo(this.model, 'sync', this.ready, this)
		this.model.fetch()
	},

	authError: function() {
		App.router.navigate('/', {trigger: true})
	},

	ready: function() {
		this.render()
		App.stopLoad()
	},

	getRenderData: function() {
		var data = this.model.toJSON()

		if (!data.versions) {
			return {}
		}

		// Work out latest version.
		data.versions = sortVersions(data.versions)
		var latest = this.latest = data.versions[data.versions.length - 1]

		// Pair up all frontend tags with their versions.
		data.frontends.forEach(function(frontend) {
			var version = _.findWhere(data.versions, {sha: frontend.sha}) || {}
			frontend.version = version.tag

			// Mark as 'old' if it's one or more versions behind.
			frontend.old = frontend.version !== latest.tag

			var frontendVersionComponents = frontend.version.substr(1).split('.'),
				latestVersionComponents = latest.tag.substr(1).split('.')

			// Tag as 'very old' if we're one or more minor/major versions behind.
			frontend.veryOld = frontendVersionComponents[0] !== latestVersionComponents[0] || frontendVersionComponents[1] !== latestVersionComponents[1]
		})

		return {
			latest: latest,
			versions: data.versions,
			frontends: data.frontends
		}
	},

	updateSystem: function(e) {
		var system = $(e.currentTarget).data('system'),
			version = this.latest

		// Check if a custom version has been selected.
		var select = this.$('.version-select[data-system="' + system + '"]')

		if (select.length) {
			var sha = select.val()
			version = _.findWhere(this.model.get('versions'), {sha: sha})
		}

		if (!confirm('Are you sure you want to update ' + system.toUpperCase() + ' to ' + version.tag + '?')) {
			return
		}

		this.deploy(system, version.sha)
	},

	settingsClick: function(e) {
		var system = $(e.currentTarget).data('system')

		var options = '',
			versions = this.model.get('versions')

		versions = sortVersions(versions)

		for (var i = versions.length - 1; i >= 0; i--) {
			var tag = versions[i]
			options += '<option value="' + tag.sha + '">' + tag.tag + '</option>'
		}

		var select = $('<select class="version-select" data-system="' + system + '">' + options + '</select>')
		$(e.currentTarget).replaceWith(select)
		select.val(this.latest.sha)
	},

	deployNewSystem: function() {
		var system = this.$('.new-system-name').val().toLowerCase(),
			version = this.latest,
			validNameRegex = /^[\w\.\-]+$/

		if (!validNameRegex.test(system)) {
			swal($.t('error.oops'), 'Invalid system name', 'error')
			return
		}

		if (!confirm('Are you sure you want to deploy ' + system.toUpperCase() + ' to ' + version.tag + '?')) {
			return
		}

		this.deploy(system, version.sha)
	},

	deploy: function(system, sha) {
		App.startLoad()
		this.$('button').attr('disabled', true)

		var self = this

		$.ajax({
			url: this.model.url() + '/' + system + '/deploy/' + sha,
			method: 'POST',
			dataType: 'json',
			headers: App.session.getHeadersObject(),

			xhr: function() {
				self.xhr = new xhook.XMLHttpRequest()
				self.xhr.addEventListener('progress', self.processResponse.bind(self), false)
				self.responseStart = 0
				return self.xhr
			},

			complete: this.deployComplete.bind(this)
		})

		console.info('Starting deploy...')
	},

	processResponse: function() {
		// Get portion of response not already processed.
		var response = this.xhr.response || '',
			newResponse = response.substr(this.responseStart)

		// Don't process the response until the message delimiter is reached.
		if (newResponse.substr(-1) !== '\n') {
			return
		}

		// Set new response position.
		this.responseStart = this.xhr.responseText.length

		var lines = newResponse.trim().split('\n')

		lines.forEach(function(json) {
			var obj = JSON.parse(json)

			if (obj.stdout) {
				console.log(obj.stdout)
			} else if (obj.stderr) {
				console.warn(obj.stderr)
			} else if (obj.exit) {
				console.info(obj.exit)
			}
		})
	},

	deployComplete: function() {
		console.info('Deploy complete')

		// Fetch frontend info again to update versions.
		this.model.fetch()
	}
})
