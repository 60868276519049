var ListView = require('list-view/list-view'),
	StormQL = require('models/stormql'),
	PageList = require('editor/page-list/page-list')

module.exports = ListView.extend({
	template: require('./levels-view-template'),
	list: require('./level-list'),
	listItem: require('./level-list-item-view'),

	afterInitialize: function(options) {
		this._readyCount = 0

		this.appId = options.appId
		this.app = App.appList.get(this.appId)

		// Don't allow levels to be accessed outside of SWIM
		if (App.system.id !== 9 || this.appId !== 3) {
			App.router.navigate('/', {trigger: true, replace: true})
			return
		}

		this.badgeList = new StormQL(null, {app: this.app})
		this.badgeList.once('sync', this.ready, this)
		this.badgeList.fetch({data: {class: 'Badge'}})

		this.pageList = new PageList(null, {appId: this.appId})
		this.pageList.once('sync', this.ready, this)
		this.pageList.fetch()
	},

	ready: function() {
		if (++this._readyCount !== 3) {
			return
		}

		this.collection.each(function(level) {
			// Cross-reference page names
			var pageId = App.getIdFromCacheUrl(level.get('pageUrl'))
			var page = this.pageList.get(pageId)

			if (page) {
				level.set('pageTitle', page.get('title'))
			}

			// Cross-reference badge objects
			var badgeId = level.get('badgeId')
			var badge = this.badgeList.get(badgeId)

			if (badge) {
				level.set('badge', badge.toJSON())
			}
		}, this)

		this.trigger('ready')
		App.stopLoad()
	},

	getRenderData: function() {
		return {appId: this.appId}
	}
})
