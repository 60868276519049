/**
 * Set of static methods for manipulating {@link StormObject} instances (and in
 * many cases, raw Storm object data).
 * @namespace StormObjectUtils
 */
var StormObjectUtils = {
	/**
	 * Strips all object IDs recursively from the specified object. Page IDs
	 * will also be removed, unless a new page ID has been specified, in which
	 * case that will be used to overwrite any existing pageId values.
	 * @param {Object} object Raw Storm object data from which to recursively
	 *     strip IDs.
	 * @param {number} [pageId] New pageId value to set recursively on object.
	 *     If not specified, all pageIds will be removed.
	 */
	stripIDs: function(object, pageId) {
		if (object.pageId !== undefined && object.pageId !== 0) {
			if (pageId !== undefined) {
				object.pageId = pageId
			} else {
				delete object.pageId
			}
		}

		if (object.id !== undefined) {
			delete object.id
		}

		_.each(object, function(value, key) {
			if (key === 'content') {
				// Language objects may contain 'id' keys which aren't IDs.
				return
			}

			if (value instanceof Array || value instanceof Backbone.Collection) {
				value.forEach(function(child) {
					if (child instanceof Backbone.Model) {
						StormObjectUtils.stripIDs(child.attributes, pageId)
					} else if (child instanceof Object) {
						StormObjectUtils.stripIDs(child, pageId)
					}
				})
			} else if (value instanceof Backbone.Model) {
				StormObjectUtils.stripIDs(value.attributes, pageId)
			} else if (value instanceof Object) {
				StormObjectUtils.stripIDs(value, pageId)
			}
		})
	}
}

/**
 * Exports {@link StormObjectUtils}.
 * @module
 */
module.exports = StormObjectUtils
