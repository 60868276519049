module.exports = Backbone.Model.extend({
	noAuth: true,

	urlRoot: function() {
		return App.newRobotoRoot + 'apps'
	},

	defaults: {
		name: 'Storm App',
		parent_id: '5486df585efe00b42a000006',
		organisation_id: '5486df175efe00b42a000004',
		app_options: {
			TSCAppId: 0,
			CFBundleDisplayName: 'Storm App',
			TSCTrackingId: 'UA-56643500-1'
		},
		users: []
	},

	toJSON: function() {
		return {
			app: Backbone.Model.prototype.toJSON.apply(this, arguments)
		}
	},

	parse: function(response) {
		return response.app
	}
})
