var APICompat = require('lib/api-compat')

var classes = {
	ListItem: require('./list-item-view'),
	CollectionListItem: require('collection-view/collection-list-item-view')
}

/**
 * Facilitates building list item views to be used in the canvas preview.
 */
module.exports = {

	/**
	 * Builds a new {@link classes.ListItem} subclass based on the input model
	 * class.
	 * @param {StormObject} model The StormObject model for the list item.
	 * @returns {CanvasItemView} View representing the specified model for
	 *     displaying a canvas preview.
	 */
	build: function(model) {
		var modelClass = APICompat.normaliseClassName(model.get('class'))
		var ListItem = classes[modelClass] || classes.ListItem

		return new ListItem({model: model})
	}
}
