var StormObject    = require('editor/storm-object'),
	TabPreview     = require('./tab-preview'),
	CanvasItemView = require('./canvas-item-view')

/**
 * Exports {@link TabPagePreview}.
 * @module
 */
module.exports = CanvasItemView.extend(/** @lends TabPagePreview.prototype */{
	/** @override */
	template: require('./tab-page-preview-template'),

	/** @override */
	events: function() {
		var events = CanvasItemView.prototype.events()
		events['click .add-tab'] = 'createTab'
		return events
	},

	/**
	 * @constructs TabPagePreview
	 * @extends CanvasItemView
	 * @override
	 */
	initialize: function(options) {
		this.collection = options.pages
		this.model = this.collection
		this.listViews = []
		this.listenTo(this.collection, 'add reset remove', this.render)

		CanvasItemView.prototype.initialize.apply(this, arguments)
	},

	/** @override */
	afterRender: function() {
		this.listViews.forEach(function(view) {
			view.destroy()
		})

		this.listViews = []

		// Render all tabs
		this.collection.each(this.appendTab, this)

		if (this.collection.parent.editing) {
			this.startEditing()
		}
	},

	appendTab: function(tab) {
		var view = new TabPreview({model: tab})

		this.listViews.push(view)
		this.$('.tab-bar .add-tab').before(view.render().el)
	},

	createTab: function(e) {
		var pageId = this.collection.parent.get('pageId'),
			tab    = StormObject.fromClassName('TabPageDescriptor', pageId)

		// Add to JSON and save
		this.collection.add(tab)
		this.collection.parent.save()

		// Set inspector to new tab for editing
		Storm.view.views.canvas.setInspector(tab)

		e.stopPropagation()
	},

	/**
	 * Propagate the startEditing event to all child tabs.
	 * @override
	 */
	startEditing: function() {
		CanvasItemView.prototype.startEditing.apply(this, arguments)

		this.collection.forEach(function(child) {
			child.trigger('startEditing')
		})
	},

	/**
	 * Propagate the stopEditing event to all child tabs.
	 * @override
	 */
	stopEditing: function() {
		CanvasItemView.prototype.stopEditing.apply(this, arguments)

		this.model.get('children').forEach(function(child) {
			child.trigger('stopEditing')
		})
	}
})
