module.exports = Backbone.Collection.extend({
	initialize: function(models, options) {
		options = options || {}
		this.appId = options.appId
	},

	url: function() {
		return App.apiRoot + 'apps/' + this.appId + '/audit'
	},

	comparator: function(model) {
		return -model.get('timestamp')
	}
})
