{{#ifExists image}}
	{{! Support legacy image format }}
	{{#ifExists image.src}}
		<img src="{{getPreviewUrl image.src}}">
	{{else}}
		<img src="{{getPreviewUrl image}}">
	{{/ifExists}}
{{else}}
	<i class="icon-picture" style="font-size:12em"></i>
{{/ifExists}}

<div class="selected-area editable">
	<div class="edit-handle top-left"></div>
	<div class="edit-handle top"></div>
	<div class="edit-handle top-right"></div>
	<div class="edit-handle left"></div>
	<div class="edit-handle right"></div>
	<div class="edit-handle bottom-left"></div>
	<div class="edit-handle bottom"></div>
	<div class="edit-handle bottom-right"></div>
</div>
