var classCompatMap = {
	GroupView: 'List',
	SpotlightImageListItemView: 'SpotlightListItem',
	SpotlightImage: 'Spotlight',
	AnimatedImageListItemView: 'AnimationListItem',
	AnimationImage: 'AnimationFrame',
	BulletListItemView: 'UnorderedListItem',
	AnnotatedListItemView: 'OrderedListItem',
	QuizQuestion: 'QuizItem',
	TextSelectionQuestion: 'TextQuizItem',
	ImageSelectionQuestion: 'ImageQuizItem',
	AreaSelectionQuestion: 'AreaQuizItem',
	ImageSliderSelectionQuestion: 'SliderQuizItem',
	SliderSelectionQuestion: 'SliderQuizItem',
	CollectionCell: 'CollectionItem',
	QuizCollectionCell: 'QuizCollectionItem',
	AppCollectionCell: 'AppCollectionItem',
	LinkCollectionCell: 'LinkCollectionItem',
	MultiVideoListItemView: 'VideoListItem'
}

// Construct reverse mapping.
var reverseClassCompatMap = {}

Object.keys(classCompatMap).forEach(function(key) {
	var newKey = classCompatMap[key]

	reverseClassCompatMap[newKey] = key
})

/**
 * Defines a set of static helper functions for maintaining compatibility
 * across multiple API versions.
 */
module.exports = {
	/**
	 * Normalises a Storm class name, mapping any old class name strings to
	 * their new versions.
	 * @param {string} name The class name to normalise.
	 * @returns {string} A normalised class name, compatible with the CMS.
	 */
	normaliseClassName: function(name) {
		// Check for any explicit mappings.
		if (name in classCompatMap) {
			return classCompatMap[name]
		}

		// Strip 'View' from '*ListItemView' classes.
		if (/ListItemView$/.test(name)) {
			return name.substr(0, name.length - 4)
		}

		return name
	},

	/**
	 * Un-normalises a Storm class name, mapping CMS-compatible names to those
	 * used by the active API version.
	 * @param {string} name The class name to un-normalise.
	 * @returns {string} A class name compatible with the target Storm API.
	 */
	unNormaliseClassName: function(name) {
		// Don't do anything if this class name is supported by the API.
		if (App.classes.has(name)) {
			return name
		}

		if (name in reverseClassCompatMap) {
			return reverseClassCompatMap[name]
		}

		// Add 'View' back to the end of '*ListItem' classes.
		if (/ListItem$/.test(name)) {
			return name + 'View'
		}

		return name
	}
}
