var Bug         = require('./bug'),
	CommentView = require('./comment-view'),
	UsersList   = require('users/user-list'),
	BugFileView = require('./bug-file-view')

var replaceNewlines = function(text) {
	return text.replace(/\n/g, '<br>')
}

module.exports = Backbone.View.extend({
	template: require('./bug-view-template'),

	events: {
		'click .comment-submit-button': 'submitComment',
		'click .comment-reply-button': 'replyToComment',
		'click .save-status-button': 'saveStatus',
		'click .close-button': 'closeIssue',
		'click .open-button': 'openIssue',
		'click .upload-button': 'chooseFile'
	},

	initialize: function(options) {
		this.listViews = []
		this.replyId = null

		this.model = new Bug({id: options.id})
		this.model.once('sync', this.bugLoaded, this)
		this.model.fetch()

		this.commentList = this.model.commentList
		this.commentList.on('add', this.addComment, this)
		this.commentList.once('sync', this.commentsLoaded, this)
		this.commentList.fetch({reset: true})

		this.uploadList = this.model.uploadList
		this.uploadList.on('add', this.addFile, this)
		this.uploadList.once('sync', this.filesLoaded, this)
		this.uploadList.fetch({reset: true})

		this.flatCommentList = new Backbone.Collection()
	},

	afterRender: function() {
		if (!App.developerMode) {
			this.$('.admin-controls').hide()
		}

		var self = this

		this.$('.file-upload').fileupload({
			dataType: 'json',
			traditional: true,
			url: this.model.url() + '/uploads',
			formData: App.session.getHeadersObject(),
			fileInput: this.$('.file-upload'),

			submit: function() {
				self.$('progress').show().attr('value', 0)
				self.$('.upload-button').hide()
			},

			error: function(jqXHR) {
				switch (jqXHR.status) {
					case 413:
						swal($.t('error.oops'), $.t('mediaLibrary.upload.filesizeError') + '4MB', 'error')
						break

					case 415:
						swal($.t('error.oops'), $.t('mediaLibrary.upload.formatError'), 'error')
						break

					default:
						swal($.t('error.oops'), $.t('mediaLibrary.upload.genericError') + ' ' + jqXHR.status, 'error')
				}

				self.$('progress').hide()
				self.$('.upload-button').show()
			},

			done: function(e, data) {
				data.result.forEach(function(file) {
					if (file.id) {
						self.uploadList.add(file)
					} else {
						_.each(file, function(value, key) {
							swal(key + ': ' + value)
						})
					}
				})

				self.$('progress').hide()
				self.$('.upload-button').show()
			},

			progressall: function(e, data) {
				var progress = parseInt(data.loaded / data.total * 100, 10)
				self.$('progress').attr('value', progress)
			}
		})
	},

	bugLoaded: function() {
		this.$('.bug-description').html(replaceNewlines(this.model.get('description')))
		this.$('.bug-steps').html(replaceNewlines(this.model.get('steps')))
		this.$('.bug-expected').html(replaceNewlines(this.model.get('expected')))
		this.$('.bug-actually').html(replaceNewlines(this.model.get('actually')))
		this.$('.bug-date').text(Handlebars.helpers.formatDate(this.model.get('reportedDate')))

		this.updateStatus()

		this.$('.bug-loading').hide()
		this.$('.bug-details').show()

		App.stopLoad()
	},

	commentsLoaded: function() {
		this.$('.comments-loading').hide()

		if (this.commentList.length === 0) {
			this.$('.no-comments').show()
		} else {
			this.$('.comment-list').show()
		}

		// Fetch user data for participating users
		var userIds = this.getUsersFromComments(this.commentList.toJSON())

		this.usersList = new UsersList({ids: _.uniq(userIds)})
		this.usersList.once('sync', this.usersFetched, this)
		this.usersList.fetch()
	},

	filesLoaded: function() {
		this.$('.files-loading').hide()

		if (this.uploadList.length === 0) {
			this.$('.no-uploads').show()
		}

		this.uploadList.each(this.addFile, this)
	},

	addFile: function(file) {
		var view = this.addView(new BugFileView({model: file}))
		this.$('.file-list').append(view.render().el)

		if (this.commentList.length > 0) {
			this.$('.no-uploads').hide()
		}
	},

	getUsersFromComments: function(comments) {
		var ids = []

		comments.forEach(function(comment) {
			ids.push(comment.userId)

			if (comment.replies) {
				ids = ids.concat(this.getUsersFromComments(comment.replies))
			}
		}, this)

		return ids
	},

	usersFetched: function() {
		this.commentList.each(this.addComment, this)

		// Get all comment replies and insert into flat list
		for (var i = 0; i < this.flatCommentList.length; i++) {
			var comment = this.flatCommentList.at(i)
			comment.get('replies').each(function(reply) {
				this.flatCommentList.add(reply)
			}, this)
		}
	},

	addComment: function(comment) {
		// Add comment to flat list
		this.flatCommentList.add(comment)

		// Insert new replies in correct location
		if (comment.has('inReplyToCommentId')) {
			var parent = this.flatCommentList.get(comment.get('inReplyToCommentId'))
			parent.get('replies').add(comment)
			return
		}

		if (this.commentList.length > 0) {
			this.$('.no-comments').hide()
			this.$('.comments-list').show()
		}

		comment.usersList = this.usersList

		var view = this.addView(new CommentView({model: comment}))
		this.$('.comment-list').append(view.render().el)
	},

	submitComment: function() {
		var text = this.$('.comment-compose-text').val()

		if (!text) {
			return App.showToast($.t('errors.allFields'))
		}

		App.startLoad()

		this.$('.comment-compose-text, .comment-submit-button').attr('disabled', true)

		this.commentList.once('sync', function() {
			this.$('.comment-compose-text').val('').removeAttr('disabled')
			this.$('.comment-submit-button').removeAttr('disabled')
			this.$('.comment-highlight').removeClass('comment-highlight')

			this.replyId = null

			App.stopLoad()
		}, this)

		this.commentList.create({
			comment: text,
			inReplyToCommentId: this.replyId
		}, {wait: true})
	},

	replyToComment: function(e) {
		// Clear previous comment highlights
		this.$('.comment-highlight').removeClass('comment-highlight')
		this.$('.comment-compose-text').focus()

		this.replyId = parseInt($(e.currentTarget).data('id'), 10)
	},

	getBugStatus: function(status) {
		switch (status) {
			case 0:
				return 'deleted'
			case 1:
				return 'open'
			case 2:
				return 'fixed'
			case 3:
				return 'wontFix'
			case 4:
				return 'postponed'
			case 5:
				return 'duplicate'
			case 6:
				return 'notReproducible'
			case 7:
				return 'byDesign'
			case 8:
				return 'closed'
		}
	},

	saveStatus: function() {
		App.startLoad()

		var status = parseInt(this.$('.status-switch').val(), 10)

		this.model.once('sync', this.updateStatus, this)
		this.model.save('status', status)
	},

	closeIssue: function() {
		App.startLoad()

		this.model.once('sync', this.updateStatus, this)
		this.model.save('status', 8)
	},

	openIssue: function() {
		App.startLoad()

		this.model.once('sync', this.updateStatus, this)
		this.model.save('status', 1)
	},

	updateStatus: function() {
		App.stopLoad()

		var status = this.getBugStatus(this.model.get('status'))
		this.$('.report-status').text($.t('bugs.status.title') + ': ' + $.t('bugs.status.' + status))
		this.$('.status-switch').val(this.model.get('status'))

		switch (this.model.get('status')) {
			case 1:
			case 4:
			case 6:
				// Issue currently open
				this.$('.open-button').hide()
				this.$('.close-button').show()
				break

			case 2:
			case 3:
			case 5:
			case 7:
			case 8:
				// Issue currently closed
				this.$('.open-button').show()
				this.$('.close-button').hide()
				break
		}
	},

	chooseFile: function() {
		this.$('.file-upload').click()
	}
})
