var AnimationPreviewView = require('./animation-preview-view'),
	utils = require('lib/utils')

module.exports = Backbone.View.extend({
	className: 'embedded-media',

	initialize: function() {
		this.views = {}
	},

	render: function() {
		if (this.views.animationPreview) {
			this.views.animationPreview.destroy()
		}

		this.$el.empty()

		var firstModel = this.collection.first()

		if (firstModel) {
			var className = firstModel.get('class')

			if (className === 'Animation') {
				this.views.animationPreview = new AnimationPreviewView({model: firstModel})
				this.$el.append(this.views.animationPreview.render().el)
			} else {
				this.$el.html('<img src="' + utils.getImagePreviewUrl(this.collection.toJSON()) + '">')
			}
		}

		return this
	}
})
