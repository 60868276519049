<div class="row">
	<div class="app add-app col-xs-6 col-sm-4 col-lg-3">
		<a href="/apps/new" class="app-icon">&plus;</a>
		<h3>{{t "manageApps.yourNextApp"}}</h3>
		<p>{{t "manageApps.easilySetUpYourNextApp"}}</p>
	</div>
	{{#each apps}}
		<div class="app col-xs-6 col-sm-4 col-lg-3">
			<a href="apps/{{id}}/settings" class="settings-link"><i class="icon-cog"></i></a>
			<a href="/apps/{{id}}" class="app-icon"></a>
			<h3>{{getBrowserLocaleText name}}</h3>
			<p>{{society}}</p>
		</div>
	{{/each}}
</div>
