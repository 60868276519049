{{partial "bugs/bugs-nav"}}

<div class="title-bar">
	<div class="header">
		<a href="/bugs"><span class="back-button"></span></a>
		<span>{{t "bugs.bugReport"}}</span>
		<span class="report-status"></span>
	</div>
</div>

<div class="container pad-top">
	<div class="row">
		<div class="span12 title-header">{{t "bugs.issueDetails"}}</div>
	</div>
	<div class="row">
		<div class="span12">

			<div class="bug-loading empty-table-text">{{t "bugs.loadingDetails"}}</div>

			<div class="bug-details row" style="display:none">
				<div class="span6">
					<h4>{{t "common.description"}}</h4>
					<p class="bug-description well well-small"></p>

					<h4>{{t "bugs.steps"}}</h4>
					<p class="bug-steps well well-small"></p>

					<h4>{{t "bugs.expected"}}</h4>
					<p class="bug-expected well well-small"></p>

					<h4>{{t "bugs.actually"}}</h4>
					<p class="bug-actually well well-small"></p>

					<small class="bug-date"></small>
				</div>

				<div class="span6">
					<h4>{{t "bugs.status.title"}}</h4>

					<button class="button negative close-button"><i class="icon-remove"></i> {{t "bugs.closeIssue"}}</button>
					<button class="button positive open-button"><i class="icon-signin"></i> {{t "bugs.reopenIssue"}}</button>

					<div class="admin-controls">
						<div>
							<select class="status-switch input-large">
								<option value="0">{{t "bugs.status.deleted"}}</option>
								<option value="1">{{t "bugs.status.open"}}</option>
								<option value="2">{{t "bugs.status.fixed"}}</option>
								<option value="3">{{t "bugs.status.wontFix"}}</option>
								<option value="4">{{t "bugs.status.postponed"}}</option>
								<option value="5">{{t "bugs.status.duplicate"}}</option>
								<option value="6">{{t "bugs.status.notReproducible"}}</option>
								<option value="7">{{t "bugs.status.byDesign"}}</option>
								<option value="8">{{t "bugs.status.closed"}}</option>
							</select>
							<button class="button positive save-status-button">{{t "common.save"}}</button>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>

	<div class="row">
		<div class="span12 title-header">{{t "bugs.screenshots"}}</div>
	</div>
	<div class="row">
		<div class="span12">
			<div class="files-loading empty-table-text">{{t "bugs.loadingFiles"}}</div>
			<div class="no-files empty-table-text" style="display:none">{{t "bugs.noFiles"}}</div>

			<div class="row file-list"></div>
			<button class="button upload-button bugs-upload-button">{{t "common.upload"}}</button>
			<progress class="bug-file-progress" value="0" max="100"></progress>
			<input class="file-upload" type="file" name="files" accept="image/gif,image/jpeg,image/jpg,image/png,text/plain" multiple>
		</div>
	</div>

	<div class="row">
		<div class="span12 title-header">{{t "bugs.comments"}}</div>
	</div>
	<div class="row">
		<div class="span12">

			<div class="comments-loading empty-table-text">{{t "bugs.loadingComments"}}</div>
			<div class="no-comments empty-table-text" style="display:none">{{t "bugs.noComments"}}</div>

			<div class="comment-list"></div>
			<div class="comment-compose">
				<textarea class="input-block-level comment-compose-text" placeholder="{{t "bugs.addComment"}}"></textarea>
				<button class="button positive comment-submit-button">{{t "common.submit"}}</button>
			</div>

		</div>
	</div>
</div>
