var StandaloneStormObject = require('editor/standalone-storm-object'),
	EditorSectionView = require('editor/editor-section-view')

module.exports = EditorSectionView.extend({
	className: 'crossBorders',
	template: require('./contact-edit-view-template'),
	// TODO need to update this to match badges view, if it changes.
	activeTabIndex: 4,

	events: {
		'click .save': 'save'
	},

	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)

		this._readyCount = 0

		this.app_ = options.app
		this.parentId = options.parentId

		// Fetch enabled languages for this app.
		var languagePromise = this.app_.languageList.fetchOnce()

		this.parentModel = null

		var modelPromise = Promise.resolve()
		var parentPromise

		if (options.id !== 'new') {
			this.model = new StandaloneStormObject()
			this.model.set('id', options.id)

			modelPromise = this.model.fetch().then(function() {
				// Add in any missing text objects to the model.
				['title'].forEach(function(key) {
					if (!this.model.has(key)) {
						var text = App.getClassStructure('Text', 0)
						this.model.set(key, text)
					}
				}, this)
				this.model.requestLock()
			}.bind(this))
		} else {
			this.parentModel = new StandaloneStormObject()
			this.parentModel.set('id', this.parentId)

			// Load parent model in which to store new child obj
			parentPromise = this.parentModel.fetch()

			this.model = StandaloneStormObject.fromClassName('CrossBorderContactItem')
		}

		Promise.all([modelPromise, languagePromise, parentPromise])
			.then(App.stopLoad)
			.then(this.render.bind(this))
	},

	afterRender: function() {
		this.delegateEvents()

		// Show current input values
		_.each(this.model.get('title..content'), function(val, key) {
			this.$('.title-input[data-code=' + key + ']').val(val)
		})
	},

	getRenderData: function() {
		var data = this.model.toJSON()
		data.appId = this.app_.id
		data.languages = this.app_.languageList.toJSON()
		return data
	},

	save: function() {
		App.startLoad()

		var self = this

		this.model.once('sync', function() {
			App.router.navigate('/apps/' + self.app_.id + '/cross-borders/' + self.parentId, {trigger: true})
		})

		// Get all new input values
		var titles = {},
			number = this.$('#field-number').val()

		this.$('.title-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				titles[code] = value
			}
		})

		this.model.set('number', number)
		this.model.set('title..content', titles)

		if (this.model.isNew()) {
			this.parentModel.once('sync', function() {
				App.router.navigate('/apps/' + self.app_.id + '/cross-borders/' + self.parentId, {trigger: true})
			})

			// save new model onto parent
			this.parentModel.get('contacts').push(this.model)
			this.parentModel.requestLock(function() {
				this.parentModel.save()
			}.bind(this))
		} else {
			this.model.save(null, {appId: this.app_.id}).then(function() {
				App.stopLoad()
				this.model.requestUnlock()
			}.bind(this))
		}

		return false
	}
})
