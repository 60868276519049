var ListViewItem = require('list-view/list-view-item')

module.exports = ListViewItem.extend({
	template: require('./product-list-item-view-template'),

	initialize: function(options) {
		this.model = options.product
		this.appId = options.appId
	},

	getRenderData: function() {
		var data = {
			product: this.model.toJSON(),
			appId: this.appId
		}
		return data
	},

	afterRender: function() {

	}
})
