var StageList = require('./stage-list')

module.exports = Backbone.Model.extend({
	urlRoot: function() {
		return App.apiRoot + 'pools'
	},

	initialize: function() {
		this.details = new Backbone.Collection()
		this.stageList = new StageList()

		var model = this

		this.details.url = function() {
			return model.url() + '/details'
		}

		this.stageList.url = function() {
			if (model.isNew()) {
				return App.apiRoot + 'stages'
			}

			return model.url() + '/stages'
		}
	}
})
