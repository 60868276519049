<tr>
	<td class="audit-list-item-col">
		<div class="row">
			<div class="publish-audit-box">
				<div><input type="checkbox" class="storm-checkbox publish" value="{{pageId}}"></div>
				<div>
					<h4>{{#if tag}}<span class="page-tag {{tagClassName tag}}">{{{firstChar tag}}}</span>{{/if}}
					{{#if pageName}}
						{{pageName}}
					{{else}}
						{{#ifEqual pageName 0}}
							{{pageName}}
						{{else}}
							{{capitaliseFirstLetter pluginClassName}} #{{pageId}}
						{{/ifEqual}}
					{{/if}}</h4>
					<p>{{t "publish.lastModified"}}: {{formatTime timestamp}}</p>
					<a href class="see-more-link">{{t "publish.detail"}} <i class="icon-sort-down"></i></a>
				</div>
			</div>
		</div>


</td>
	<!-- {{!-- <td class="center"><b>{{formatTime timestamp}}</b></td>
	<td class="center">{{pageName}}</td>
	<td class="center">{{tag}}</td>
	<td class="center preview-hide"><input type="checkbox" class="storm-checkbox publish" value="{{pageId}}"></td> --}}
</tr>
{{!-- <tr>
	<td colspan="5" class="publish-details-row">
		<a href class="see-more-link">{{t "publish.detail"}} <i class="icon-sort-down"></i></a>
	</td>
</tr> --}} -->
<tr style="display:none" class="detail-row">
	<td colspan="5" class="publish-details-row">
		<div class='child-list-items'></div>
	</td>
</tr>
