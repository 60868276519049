var DisasterList = require('./disaster-list'),
	CustomAlert = require('./custom-alert'),
	HazardsSectionViw = require('./hazards-section-view')

var EARTHQUAKE = 8

var alertTypeRegex = /^(\d+)-(\d+)$/

module.exports = HazardsSectionViw.extend({
	template: require('./alert-test-view-template'),

	events: {
		'change #alert-type': 'alertTypeChange',
		'input #alert-severity': 'alertSeverityChange',
		'click .save-button': 'save'
	},

	initialize: function(options) {
		this.appId = options.appId

		this.disasterList = new DisasterList()
		this.disasterList.comparator = disasterComparator
		this.disasterList.once('sync', this.ready, this)
		this.disasterList.fetch()

		this.model = new CustomAlert()
	},

	getPageTitle: function() {
		return $.t('hazards.alerts.testAlert')
	},

	ready: function() {
		this.render()
		App.stopLoad()
	},

	getRenderData: function() {
		return {
			appId: this.appId,
			disasters: this.disasterList.toJSON()
		}
	},

	afterRender: function() {
		this.alertTypeChange()
	},

	alertTypeChange: function() {
		var typeMatch = alertTypeRegex.exec(this.$('#alert-type').val())

		if (typeMatch === null) {
			return
		}

		var type = Number(typeMatch[1])

		var showSlider = type === EARTHQUAKE
		this.$('#severity-control').toggle(showSlider)
	},

	alertSeverityChange: function() {
		var severity = this.$('#alert-severity').val()
		this.$('#alert-severity-count').text(severity)
	},

	save: function() {
		App.startLoad()

		var severity = Number(this.$('#alert-severity').val()),
			type = alertTypeRegex.exec(this.$('#alert-type').val()),
			disasterId = Number(type[1])

		type = Number(type[2])

		if (disasterId !== EARTHQUAKE) {
			severity = 0
		}

		this.model.set('severity', severity)
		this.model.set('type', {
			category: disasterId,
			event: type
		})

		this.$('.save-button').attr('disabled', true)
		this.model.once('sync', this.alertSaved, this)
		this.model.save()

		return false
	},

	alertSaved: function() {
		App.router.navigate('/apps/' + this.appId + '/alerts', {trigger: true})
		App.showToast($.t('hazards.alerts.custom.alertQueued'))
	}
})

function disasterComparator(disaster) {
	return Handlebars.helpers.getBrowserLocaleText(disaster.get('name')).toLowerCase()
}
