var ListViewItem = require('list-view/list-view-item')

module.exports = ListViewItem.extend({
	template: require('./whatnow-translation-edit-view-template'),

	initialize: function(options) {
		this.data = options.data
		this.index = options.index
	},

	afterInitialize: function() {
		this.events['click .reject'] = 'reject'
		this.delegateEvents()
	},

	getRenderData: function() {
		return {
			data: this.data,
			index: this.index
		}
	}
})
