module.exports = Backbone.Model.extend({
	urlRoot: function() {
		return App.apiRoot + 'templates'
	},

	getPreview: function() {
		var structure = this.get('content')

		if (typeof (structure) === 'string') {
			structure = JSON.parse(structure)
		}

		var page = this.generatePreview(structure, 'div')

		// Add page header and title to the preview
		var header = document.createElement('div')
		header.className = 'page-header'
		header.textContent = this.get('name')

		page.insertBefore(header, page.firstElementChild)

		return page
	},

	generatePreview: function(attrs, tag) {
		var type

		// Set child element tag name
		switch (attrs.class) {
			case 'ListPage':
				type = 'ul'
				break

			case 'List':
				type = 'li'
				break

			default:
				type = 'div'
				break
		}

		var el = document.createElement(tag)
		el.className = attrs.class

		// Recursively render any child objects
		if ('children' in attrs) {
			attrs.children.forEach(function(child) {
				el.appendChild(this.generatePreview(child, type))
			}, this)
		}

		return el
	}
})
