var AnimationPreviewView     = require('./animation-preview-view'),
	EmbeddedMediaPreviewView = require('./embedded-media-preview-view'),
	LinkableImageContainerPreview = require('./linkable-image-container-preview-view'),
	CanvasItemView           = require('./canvas-item-view'),
	APICompat                = require('lib/api-compat'),
	utils                    = require('lib/utils')

/**
 * Exports {@link ListItemView}.
 * @module
 */
module.exports = CanvasItemView.extend(/** @lends ListItemView.prototype */{
	/** @override */
	tagName: 'li',
	/** @override */
	className: 'ListItem editable',
	/** @override */
	template: require('./list-item-view-template'),

	/**
	 * @constructs ListItemView
	 * @extends CanvasItemView
	 * @override
	 */
	initialize: function() {
		CanvasItemView.prototype.initialize.apply(this, arguments)

		this.views = {}

		var embeddedLinks = this.model.get('embeddedLinks')

		if (embeddedLinks) {
			this.listenTo(embeddedLinks, 'add remove reset', this.render)

			embeddedLinks.forEach(function(link) {
				this.listenTo(link, 'change', this.render, this)
			}, this)

			this.listenTo(embeddedLinks, 'add', function(link) {
				this.listenTo(link, 'change', this.render, this)
			})

			embeddedLinks.listenTo(embeddedLinks, 'remove', function(link) {
				this.stopListening(link, 'change')
			})
		}

		// If this contains an animation/spotlights, render a preview.
		var className = this.model.get('class')
		className = APICompat.normaliseClassName(className)

		var hasAnimation = className === 'SpotlightListItem' ||
			className === 'AnimationListItem'

		if (hasAnimation) {
			this.views.animation = new AnimationPreviewView({model: this.model})
		}

		var isLinkableImageContainer = className === "LinkableImageItemContainer"

		if (isLinkableImageContainer) {
			this.views.linkableImageContainer = new LinkableImageContainerPreview({model: this.model})
			var children = this.model.get('children')

			if (children) {
				this.listenTo(children, 'add remove reset', this.render)

				children.forEach(function(link) {
					this.listenTo(link, 'change', this.render, this)
				}, this)

				children.listenTo(children, 'remove', function(link) {
					this.stopListening(link, 'change')
				})
			}
		}

		// Render embedded media preview.
		var embeddedMedia = this.model.get('embeddedMedia')

		if (embeddedMedia) {
			this.views.embeddedMedia = new EmbeddedMediaPreviewView({
				collection: embeddedMedia
			})
		}
	},

	/** @override */
	getRenderData: function() {
		var data = this.model.toJSON()
		data.language = Storm.view.language

		if (data.images instanceof Backbone.Collection) {
			data.images = data.images.toJSON()
		}

		// Display link title for LogoListItem objects
		var className = this.model.get('class')
		if (className === 'LogoListItem') {
			data.showLinkTitle = true
		}

		return data
	},

	/** @override */
	afterRender: function() {
		CanvasItemView.prototype.afterRender.apply(this, arguments)

		// Render embedded links
		if (this.model.attributes.embeddedLinks !== undefined) {
			this.model.get('embeddedLinks').each(function(link) {
				var $button = $('<button>').addClass('button form-control')
					.text(App.l(link.get('title')))

				this.$('.text-container').append($button)
			}, this)
		}
	},

	click: function(e) {
		var apiClass        = this.model.get('class'),
			normalisedClass = APICompat.normaliseClassName(apiClass)

		if ($('.preview').hasClass('preview-mode')) {
			// Toggle content in ToggleableListItem objects.
			if (normalisedClass === 'ToggleableListItem') {
				this.$el.toggleClass('toggled')
			}

			// Navigate to the linked page if this object has one.
			var button = this.model.get('button')
			var link = null
			if (button) {
				link = this.model.get('button').link
			}

			if (normalisedClass === 'StandardListItem') {
				link = this.model.get('link')
			}

			// QuizCollectionItem views link to a quiz under a 'quiz' key.
			if (normalisedClass === 'QuizCollectionItem') {
				link = this.model.get('quiz')
			}

			// Pick the correct language on a VideoListItem.
			var isVideoListItem = apiClass === 'VideoListItem' ||
				apiClass === 'MultiVideoListItemView'

			if (isVideoListItem) {
				var video = this.model.get('videos').first()

				this.model.get('videos').forEach(function(obj) {
					var locale = obj.get('locale')

					if (locale && Storm.view.language === locale.substr(4)) {
						video = obj
					}
				})

				if (video) {
					link = video.get('src')
				} else {
					// Fallback for old api
					link = this.model.get('link')
				}
			}

			if (link && link.destination) {
				var isExternal = link.class === 'ExternalLink' ||
					link.class === 'UriLink'
				if (isVideoListItem) {
					this.navigateToVideoLink(link)
				} else if (link.class === 'InternalLink') {
					this.navigateToInternalUrl(link)
				} else if (isExternal) {
					window.open(link.destination, '_blank')
				}
			}
			// Don't do anything if we're already editing this element.
			// TODO don't read this from the DOM
		} else if (!this.$el.hasClass('editing')) {
			Storm.view.views.canvas.setInspector(this.model)
			this.$el.addClass('editing')
		}

		// Don't bubble up to the parent view.
		e.stopPropagation()
	},

	navigateToVideoLink: function(link) {
		if (link.destination && link.destination.match(/^cache:\/\/.*/) !== null) {
			var url = utils.getRemoteUrl(link.destination)
			window.open(url, '_blank')
		}
	},

	navigateToInternalUrl: function(link) {
		var url = link.destination

		var internalPageLink = link.destination
		internalPageLink = internalPageLink.replace("cache://", "")
		internalPageLink = internalPageLink.replace(".json", "")
		url = '/apps/' + App.getCurrentApp().id + '/' + internalPageLink

		App.router.navigate(url, {trigger: true})
	}
})
