var UserExtra = require('./user-extra')

module.exports = Backbone.Collection.extend({
	url: function() {
		return App.apiRoot + 'users'
	},

	model: UserExtra,

	initialize: function() {
		// Users list may not exist on any given system. If errors, trigger sync anyway
		this.on('error', function() {
			this.trigger('sync')
		}, this)
	}
})
