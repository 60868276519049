module.exports = Backbone.Model.extend({

	initialize: function(options) {
		this.appId = options.appId
		this.type = options.type || 'all'
		console.log('translations.js', this.appId)
	},

	url: function() {
		return App.apiRoot + 'apps/' + this.appId + '/translations/?type=' + this.type
	}
})
