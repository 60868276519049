<div class="add-view add-form zero-height row">
	<div class="col-xs-12">
		<h4 data-i18n="localisations.addNew"></h4>
	</div>
	<form>
		<div class="form-group col-sm-5">
			<div class="input-group">
				<span class="input-group-addon" data-i18n="localisations.key"></span>
				<input type="text" class="new-localisation-key form-control" placeholder="_APP_THINGS_TEXT">
			</div>
		</div>
		<div class="form-group col-sm-5">
			{{#each languages}}
				<div class="form-group">
					<div class="input-group">
						<span class="input-group-addon">{{name}}</span>
						<input type="text" class="new-localisation-string form-control" data-code="{{code}}" placeholder="App things">
					</div>
				</div>
			{{/each}}
		</div>
		<div class="form-group col-sm-2">
			<button class="button wide add-localisation-button" data-i18n="common.add"></button>
		</div>
	</form>
	<div class="col-xs-12">
		<h4 data-i18n="localisations.addMultiple"></h4>
		<small><span data-i18n="localisations.addMultipleInstructions"></span>&nbsp;<strong>key{{#each languages}},{{code}}{{/each}}</strong></small>
	</div>
	<div class="col-xs-12">
		<button class="button csv-upload-button" data-i18n="localisations.uploadCsv"></button>
		<input type="file" class="hidden csv-upload-input">
	</div>
	<div class="col-xs-12 localisations-copy-form">
		<h4 data-i18n="localisations.copyFromAnotherApp"></h4>
		<div class="row">
			<div class="col-sm-4">
				<select id="copy-from-app" class="form-control">{{{appSelect}}}</select>
			</div>
			<div class="col-sm-2">
				<button type="button" class="button copy-localisations-button"><i class="icon-copy"></i>&nbsp;<span data-i18n="editor.inspector.copy"></span></button>
			</div>
		</div>
		<label><input type="checkbox" id="overwrite-existing">&nbsp;<span data-i18n="localisations.overwriteExisting"></span></label>
		<p class="alert alert-warning"><strong><span data-i18n="common.warning"></span>: </strong><span data-i18n="localisations.overwriteWarning"></span></p>
	</div>
</div>

<button class="button wide pull-right add-button" data-i18n="common.add"></button>
<button class="button wide white pull-right hidden close-add-button" data-i18n="common.close"></button>
<h3 data-i18n="[html]localisations.all"></h3>
<input type="search" class="form-control filter-input" data-i18n="[placeholder]common.filter">
<div class="localisation-editor-container"></div>
