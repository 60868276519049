const regex = /(\d+)\.(.+)\.(\d+)/m // REGEX for getting the individual parts of the identifier for a moudule

var EditorSectionView = require('editor/editor-section-view'),
	StandaloneStormObject = require('editor/standalone-storm-object'),
	MediaLibrary          = require('media-library/media-library-view'),
	MediaSelectorView     = require('media-library/media-selector-view'),
	ModuleBlock           = require('./module-block-view'),
	Section               = require('./section-view'),
	AddSectionView        = require('./add-section-view'),
	StormObject           = require('editor/storm-object'),
	LinkSelector          = require('editor/inspector/link-selector')

var keysToStrip = ['attributes',
									'data',
									'src',
									'text',
									'title',
									'startLabel',
									'endLabel',
									'range',
									'options',
									'models',
									'children',
									'links']
// TODO: Add radio and check keys to array above to strip

module.exports = EditorSectionView.extend({
	className: 'moduleEdit',
	template: require('./module-edit-view-template'),
	activeTabIndex: 4,

	/** @override */
	events: {
		'click .add-image': 'selectImage',
		'click .save': 'save',
		'input .span-title': 'updateTitle',
		'input .time': 'updateTime',
		'input .people': 'updatePeople',
		'input .module-logic': 'updateModuleLogic',
		'click .module-logic': 'clickModuleLogic',
		'click .delete-block': 'removeBlock',
		'click .remove-section': 'removeSection',
		'mousemove .blocks': '_onMouseMove',
		'click .back-button': 'goBack',
		'input #module-identifier': 'updateIdentifier',
		'click .visibility': 'toggleModuleLogic'
	},

	initialize: function(options) {
		App.startLoad()
		this.drake = null
		this.app = options.app
		this.appId = options.appId
		this.id = options.id
		this.childViews = []
		this.toDelete = []

		var requests = []

		this.model = StandaloneStormObject.fromProperties({id: options.id})
		requests.push(this.model.fetch())

		// List of module block types
		var moduleBlocksListPromise = this.getBlockModules()
		requests.push(moduleBlocksListPromise)

		// List of assessment block types
		var assessmentBlocksListPromise = this.getBlockAssessments()
		requests.push(assessmentBlocksListPromise)
		this.needsSaving = false
		this.listenTo(this.model, 'change:image', this.updateImagePreview, this)
		// Render page once all data loaded.
		Promise.all(requests).then(this.ready.bind(this))
	},

	ready: function() {
		// this.getBlockModules()
		App.stopLoad()
		this.model.requestLock(function() {
			this.render()
		}.bind(this))
	},

	getPageTitle: function() {
		return $.t('r4b.title')
	},

	getRenderData: function() {
		var blocks = this.moduleBlocksList
		var isModule = true
		if (this.model.get('class') === 'R4b_Assessment') {
			blocks = this.assessmentBlocksList
			isModule = false
		}
		var identifierArr = this.getIdentierArr()
		return {
			model: this.model.toJSON(),
			identifierArr: identifierArr,
			blocks: blocks,
			isModule: isModule,
			appId: this.appId
		}
	},

	getIdentierArr: function() {
		var m
		var str = this.model.get('identifier')
		if ((m = regex.exec(str)) !== null) {
			// The result can be accessed through the `m`-variable.
			// m.forEach((match, groupIndex) => {
			// 	console.log(`Found match, group ${groupIndex}: ${match}`)
			// });
			return m
		}
	},

	afterRender: function() {
		var addSectionView = new AddSectionView()
		this.childViews.push(addSectionView)
		this.addSectionEl = addSectionView.render().el

		// Hide developer-only controls.
		if (!App.developerMode) {
			this.$('.developer-mode').remove()
		}

		this.renderBlocks()
		this.startDragAndDrop()
	},

	/**
	 * Initialises the drag and drop plugin for dragging new items onto the
	 * page.
	 */
	startDragAndDrop: function() {
		this.stopDragAndDrop()

		var sideBarInputItems = $('.input-holder')[0],
			sideBarMediaItems = $('.input-holder')[1],
			blocks = $('.section-blocks').toArray(),
			newSection = $('.add-section')[0]

		this.drake = dragula([sideBarInputItems, sideBarMediaItems, blocks, newSection], {
			copy: function(el, source) {
				return source === sideBarInputItems || source === sideBarMediaItems
			},
			// copy: true,
			accepts: function(el, target) {
				return target !== sideBarInputItems && target !== sideBarMediaItems
			},
			isContainer: function(el) {
				return el.classList.contains('section-blocks')
			}
		})

		this.drake.on('drop', this.handleObjectDrop.bind(this))
	},

	/**
	 * Tears down the drag and drop plugin for dragging new items onto the page.
	 */
	stopDragAndDrop: function() {
		if (this.drake) {
			this.drake.destroy()
			this.drake = null
		}
	},

	/**
	 * Handles a drop event from the drag and drop plugin. Inserts a new model
	 * on the current page to match the dropped template.
	 * @param {HTMLElement} el The DOM element being dropped.
	 * @param {HTMLElement} target The DOM element in which {@link el} is being
	 *     dropped.
	 * @param {HTMLElement} source The DOM element from which {@link el} has
	 *     been removed.
	 * @param {HTMLElement} sibling The DOM element which {@link el} has been
	 *     dropped before, or {@code null} if {@link el} has been dropped at
	 *     the end of the list.
	 */
	handleObjectDrop: function(el, target, source) {
		if (!target) {
			return
		}
		var isNewModel = false
		// Get section to go into..
		var targetSectionPageId = target.getAttribute('data-id')
		var sourceSectionPageId = source.getAttribute('data-id')
		// Create new object of the specified type.
		var className = $(el).data('classname')
		// Get sections
		var sections = this.model.get('children')
		var newModel

		// If a new block
		if (className) {
			isNewModel = true

			// Create new model and insert into right section
			var	pageId    = this.model.get('pageId')
			newModel  = StormObject.fromClassName(className, pageId)
			var section
				// Drop the new model into the right section
			if (targetSectionPageId) {
				section = sections.get(targetSectionPageId)
			} else if (target.classList.contains('add-section')) {
				// Create a new section...
				var newSectionName = this.model.get('class') === 'R4b_Assessment' ? 'R4b_AssessmentSection' : 'R4b_ModuleSection'
				section = StormObject.fromClassName(newSectionName, pageId)
				sections.add(section)
			}
			if (section) {
				section.get('children').add(newModel)
				// Get the view of the new model just added and render
				this.addBlock(newModel)
			}
		}

		// Get model just moved
		var model
		if (isNewModel) {
			model = newModel
			sourceSectionPageId = targetSectionPageId
		} else {
			var modelId = $(el).attr('id')
			// Get the model from the source section...
			model = sections.get(sourceSectionPageId).get('children').get(modelId)
		}

		$(el).addClass('moduleBlock')

		// Get the indicies of the model and it's new position
		if (targetSectionPageId && sourceSectionPageId) {
			var newIndex = $.inArray(el, $('.section-blocks[data-id=' + targetSectionPageId + '] .moduleBlock').get())
			var currentIndex = $(el).data('index')
			if (isNewModel) {
				currentIndex = sections.get(sourceSectionPageId).get('children').length - 1
			}
			if (currentIndex === -1 || currentIndex === undefined) {
				currentIndex = newIndex
			}
			// Move the model to the right place
			this.moveChildModel(currentIndex, newIndex, sourceSectionPageId, targetSectionPageId)
		}

		// Render
		this.renderBlocks()
		if (model) {
			model.trigger('change')
		}
		$(el).remove()
	},

	showMediaSelect: function(model, mediaType) {
		this.mediaLibrary = new MediaSelectorView({
			app: Storm.view.app,
			model: model,
			mediaType: mediaType
		})

		$('body').append(this.mediaLibrary.el)
		this.mediaLibrary.render().show()
	},

	selectImage: function(e) {
		var property = $(e.currentTarget).data('property')
		var model = property ? this.model.get(property) : this.model.get('image')

		var propertyComponents = property.split('..')
		var mediaType = MediaLibrary.types.IMAGE

		if (propertyComponents[propertyComponents.length - 1] === 'icon') {
			mediaType = MediaLibrary.types.ICON
		}

		// Show media library
		this.showMediaSelect(model, mediaType)

		this.mediaLibrary.on('change', function() {
			this.model.trigger('change change:image', this.model)
		}, this)
	},

	updateImagePreview: function() {
		var style = Handlebars.helpers.getBackgroundImageStyle(this.model.get('image').src, 'cover')
		$('.add-image').attr('style', style)
	},

	renderBlocks: function() {
		this.childViews = []
		this.sectionCount = 0
		this.sections = []
		$('.blocks').empty()
		if (this.model.get('children')) {
			this.model.get('children').forEach(function(childModel) {
				this.sectionCount++
				this.addBlock(childModel)
			}.bind(this))
		}
		this.renderChildViews()
	},

	addBlock: function(childModel) {
		var selectorId = Math.random().toString(16).substr(2)
		var view
		if (childModel.get('class') === "R4b_AssessmentSection" || childModel.get('class') === "R4b_ModuleSection" || childModel.get('class') === "R4b_TaskModuleSection") {
			view = new Section({
				model: childModel,
				appId: this.appId,
				parent: this,
				selectorId: selectorId,
				sectionNumber: this.sectionCount
			})
			this.sections.push(childModel)
		} else {
			view = new ModuleBlock({
				model: childModel,
				appId: this.appId,
				parent: this,
				selectorId: selectorId
			})
		}

		this.listenTo(childModel, 'change', this.updateSaving)
		this.childViews.push(view)
		return view
	},

	renderChildViews: function() {
		// Render each of the child views
		this.childViews.forEach(function(view) {
			this.renderChildView(view)
		}.bind(this))
		// Add the 'Add section here' view
		$('.blocks').append(this.addSectionEl)
		this.removeAllToDelete()
	},

	renderChildView: function(view) {
		$('.blocks').append(view.render().el)
		view.toggleTaskContent()
		if (view.model.get('class') === "R4b_AssessmentSection" || view.model.get('class') === "R4b_ModuleSection" || view.model.get('class') === "R4b_TaskModuleSection") {
			view.renderBlocks()
		} else if (view.model.get('class') === "R4b_LinkMediaModuleBlock") {
			// Move to Section view?
			var linkSelector = new LinkSelector({
				link: view.model.get('data'),
				titleDisabled: false,
				language: 'en'
			})
			this.listenTo(linkSelector, 'change', function() {
				this.updateSaving()
			}, this)
			$('.link-selector[data-id=' + view.selectorId + ']').html(linkSelector.render().el)
		}
	},

	updateSaving: function() {
		this.needsSaving = true
		$('.save-status').text(Handlebars.helpers.t('r4b.modules.unsaved'))
	},

	getBlockModules: function() {
		return new Promise(
			function(resolve, reject) {
				$.ajax({
					url: App.apiRoot + 'classes/R4B_ModuleBlock/valid',
					type: 'GET',

					headers: App.session.getHeadersObject(),

					success: function(data) {
						this.moduleBlocksList = data
						resolve()
					}.bind(this),

					error: function(jqXHR) {
						reject(jqXHR)
					}
				})
			}.bind(this)
		)
	},

	getBlockAssessments: function() {
		return new Promise(
			function(resolve, reject) {
				$.ajax({
					url: App.apiRoot + 'classes/R4B_AssessmentBlock/valid',
					type: 'GET',

					headers: App.session.getHeadersObject(),

					success: function(data) {
						this.assessmentBlocksList = data
						resolve()
					}.bind(this),

					error: function(jqXHR) {
						reject(jqXHR)
					}
				})
			}.bind(this)
		)
	},

	/**
	 * [moveChildModel move a model between collections and to the right index]
	 * @param  {integer} originalIndex original index of model in collection
	 * @param  {integer} newIndex new index for the model in the collection to go to
	 * @param  {integer} sourceSection page id for the source section (where the model is being moved from)
	 * @param  {integer} targetSection page id for the target section (where the model is being moved to)
	 * @returns {undefined}
	 */
	moveChildModel: function(originalIndex, newIndex, sourceSection, targetSection) {
		var sections = this.model.get('children')
		var tempModel = sections.get(sourceSection).get('children').at(originalIndex)
		var tempSourceCollection = sections.get(sourceSection).get('children')
		var tempTargetCollection = sections.get(targetSection).get('children')
		var newModel = tempModel.clone()
		if (!tempModel.isNew() && sourceSection !== targetSection) {
			this.stripIds(newModel)
			this.addToDelete(tempModel.get('id'), 'block')
		}
		if (sourceSection === targetSection) {
			// Remove it from the source
			tempSourceCollection.remove(tempModel)
		}

		// Add it to the target at the right index
		tempTargetCollection.add(newModel, {at: newIndex})
	},

	stripIds: function(object) {
		// Unset id if object
		if (object instanceof Backbone.Model) {
			object.unset('id')
		}
		if (object.id !== undefined) {
			delete object.id
		}

		_.each(object, function(value, key) {
			if (_.contains(keysToStrip, key)) {
				if (value instanceof Array || value instanceof Backbone.Collection) {
					value.forEach(function(child) {
						this.stripIds(child)
					}.bind(this))
				} else {
					this.stripIds(value)
				}
			} else {
				return
			}
		}.bind(this))
	},

	save: function() {
		App.startLoad()
		var self = this
		this.model.save().then(function() {
			// Delete all 'To deletes'
			if (self.toDelete.length) {
				var i = 0
				_.each(self.toDelete, function(object) {
					// Get model
					var model
					if (object.type === 'block') {
						model = self.getBlockModel(object.id)
					} else if (object.type === 'section') {
						model = self.getSectionModel(object.id)
					}
					if (model) {
						model.destroy().then(function() {
							i++
							// After deletion of all models stopload
							if (i === self.toDelete.length) {
								// Empty
								self.finishSave()
							}
						})
					}
				})
			} else {
				self.finishSave()
			}
		})
	},

	getBlockModel: function(id) {
		var returnModel
		this.model.get('children').forEach(function(model) {
			model.get('children').forEach(function(model) {
				if (model.get('id') === id) {
					returnModel = model
					return
				}
			})
			if (returnModel) {
				return
			}
		})
		return returnModel
	},

	getSectionModel: function(id) {
		var returnModel
		this.model.get('children').forEach(function(model) {
			if (model.get('id') === id) {
				returnModel = model
				return
			}
		})
		return returnModel
	},

	cloneSection: function(model) {
		// Get section position
		var sectionPos = 0
		this.model.get('children').each(function(section, index) {
			if (model === section) {
				sectionPos = index
				return
			}
		})
		// Create a copy
		var newSection = model.clone()

		// Strip Ids
		this.stripIds(newSection)

		// Add to collection
		this.model.get('children').add(newSection, {at: sectionPos})
		return newSection
	},

	finishSave: function() {
		App.stopLoad()
		$('.save-status').text(Handlebars.helpers.t('r4b.modules.saved'))
		this.model.fetch().then(function() {
			this.renderBlocks()
			this.toDelete = []
		}.bind(this))
	},

	updateTitle: function() {
		var value = $('.span-title').html()
		var text = this.model.get('name')
		text.content.en = value
		this.updateSaving()
	},

	updateTime: function() {
		var value = parseInt($('.time').html(), 10)
		this.model.set('time', value * 60)
		this.updateSaving()
	},

	updatePeople: function() {
		var value = parseInt($('.people').html(), 10)
		this.model.set('people', value)
		this.updateSaving()
	},

	updateModuleLogic: function() {
		var value = $('.module-logic').html()
		this.model.set('logic', value)
		this.updateSaving()
	},

	clickModuleLogic: function(e) {
		e.preventDefault()
		e.stopPropagation()
	},

	toggleModuleLogic: function() {
		$('.visibility').toggleClass('visibility-open')
	},

	updateIdentifier: function(e) {
		this.model.set('identifier', this.model.get('level') + '.' + $(e.currentTarget).html() + '.' + this.model.get('version'))
	},

	removeBlock: function(e) {
		var id = parseInt($(e.currentTarget).attr('data-id'), 10)
		swal({
			title: $.t('editor.inspector.areYouSure'),
			text: $.t('editor.inspector.confirmDelete'),
			showCancelButton: true
		}, function(didConfirm) {
			if (didConfirm) {
				// this.addToDelete(id)
				this.addToDelete(id, 'block')
				this.removeDomBlock(id)
			}
		}.bind(this))
	},

	removeSection: function(e) {
		var id = parseInt(
			$(e.currentTarget).attr('data-id'), 10)
		swal({
			title: $.t('editor.inspector.areYouSure'),
			text: $.t('editor.inspector.confirmDelete'),
			showCancelButton: true
		}, function(didConfirm) {
			if (didConfirm) {
				this.addToDelete(id, 'section')
				this.removeSectionBlock(id)
			}
		}.bind(this))
	},

	removeDomBlock: function(id) {
		$('.moduleBlock[id="' + id + '"]').remove()
	},

	removeSectionBlock: function(id) {
		$('.sectionBlock[id="' + id + '"]').remove()
	},

	removeAllToDelete: function() {
		this.toDelete.forEach(function(object) {
			if (object.type === 'section') {
				this.removeSectionBlock(object.id)
			} else if (object.type === 'block') {
				this.removeDomBlock(object.id)
			}
		}.bind(this))
	},

	addToDelete: function(id, type) {
		var exists = false
		this.toDelete.forEach(function(object) {
			if (object.id === id) {
				exists = true
			}
		})
		if (exists) {
			return
		}

		this.toDelete.push({id: id, type: type})
	},

	addSectionToDelete: function(id) {
		// Find model in collections...
		this.model.get('children').forEach(function(model) {
			if (model.get('id') === id) {
				this.toSectionDelete.push(model)
			}
		}.bind(this))
	},

	deleteBlock: function(model) {
		App.startLoad()
		model.destroy().then(function() {
			// document.location.reload()

			App.stopLoad()
		})
	},

	goBack: function() {
		this.model.requestUnlock().then(function() {

		})
	},

	// Scrolling for dragula https://github.com/bevacqua/dragula/issues/121: TODO Fix
	_onMouseMove: function(e) {
		this._pageY = e.pageY
		if (this.drake) {
			if (this.drake.dragging) {
				// scroll while drag
				var y = this._pageY
				var container = document.querySelector('body')
				var containerBottom = container.offsetTop + container.offsetHeight
				var containerTop = container.offsetTop

				if (containerBottom - y < 120) {
					this._scrollDown(container, y)
				} else if (containerTop + y < 120) {
					this._scrollUp(container, y)
				}
			}
		}
	},

	_scrollDown: function(container, pageY) {
		if (this.drake) {
			if (this.drake.dragging && pageY === this._pageY) {
				container.scrollTop += 5
				setTimeout(this._scrollDown.bind(this, container, pageY), 20)
			}
		}
	},

	_scrollUp: function(container, pageY) {
		if (this.drake) {
			if (this.drake.dragging && pageY === this._pageY) {
				container.scrollTop -= 5
				setTimeout(this._scrollUp.bind(this, container, pageY), 20)
			}
		}
	}
})
