{{partial "stories/stories-nav"}}

<div class="title-bar">
	<div class="header">
		<span>{{t "stories.title"}}</span>
		<button class="button add">{{t "common.add"}}</button>
	</div>
</div>

<div class="overlay"></div>

<div class="container pad-top">
	<div class="row">
		<div class="span12 title-header">{{t "stories.list"}}</div>
		<div class="pagination"></div>
	</div>
	<div class="row">
		<div class="span12">

			<table class="header-float">
				<thead>
					<tr>
						<th style="width:200px">{{t "mediaLibrary.image"}}</th>
						<th>{{t "editor.inspector.properties.title.title"}}</th>
						<th>{{t "stories.subtitle"}}</th>
						<th>{{t "stories.created"}}</th>
						<th>{{t "common.tools"}}</th>
					</tr>
				</thead>
				<tbody class="list-items"></tbody>
			</table>
			<div class="pagination"></div>

		</div>
	</div>
</div>
