{{partial "levels/levels-nav"}}

<div class="title-bar">
	<div class="header">
		<span>{{t "levels.title"}}</span>
		<a href="/apps/{{appId}}/levels/new"><button class="button">{{t "common.add"}}</button></a>
	</div>
</div>

<div class="container pad-top">
	<div class="row">
		<div class="span12 title-header">{{t "levels.list"}}</div>
	</div>
	<div class="row">
		<div class="span12">

			<table class="header-float">
				<thead>
					<tr>
						<th>{{t "editor.inspector.properties.badge.title"}}</th>
						<th>{{t "editor.inspector.properties.title.title"}}</th>
						<th>{{t "common.page"}}</th>
						<th>{{t "common.tools"}}</th>
					</tr>
				</thead>
				<tbody class="list-items"></tbody>
			</table>

		</div>
	</div>

</div>
