var StormView = require('lib/storm-view'),
	NoAppsView = require('editor/no-apps-view')

/**
 * Exports {@link ListView}.
 * @module
 */
module.exports = StormView.extend(/** @lends ListView.prototype */{
	/** @override */
	events: {
		'click .add': 'addModel',
		'click .sort-button': 'changeSortComparator'
	},

	/**
	 * @constructs ListView
	 * @extends StormView
	 * @override
	 * @classdesc Defines a generic list view class for displaying lists of
	 *     arbitrary items. Extended by {@link PaginatedListView} to provide
	 *     pagination.
	 *     <p>
	 *     Concrete implementations are created by extending this view and
	 *     specifying {@link List#template}, {@link ListView#list} and {@link
	 *     ListView#listItem}. The list will automatically be instantiated
	 *     and fetched, with one list item instance created for each model.
	 *     <p>
	 *     To pass extra data to {@link #listItem} instances during
	 *     instantiation, specify the <code>listItemOptions</code> parameter
	 *     when constructing the {@link ListView} instance. This optional key
	 *     accepts an Object which is used as options for each list item
	 *     instantiation.
	 *     <p>
	 *     If extra query parameters are required to fetch the collection (e.g.
	 *     with {@link StormQL} classes), specify the <code>fetchData</code>
	 *     parameter when constructing the {@link ListView} instance. This
	 *     parameter is passed directly to the collection fetch.
	 * @example
	 * var MyListView = ListView.extend({
	 *   template: require('./template'),
	 *   list: require('./list'),
	 *   listItem: require('./list-item')
	 * });
	 *
	 * var view = new MyListView({
	 *   listItemOptions: {...}
	 * });
	 */
	initialize: function(options) {
		options = options || {}

		if (!options.app) {
			var noAppsView = new NoAppsView()
			Storm.setPage(noAppsView)
		}

		this.app = options.app
		this.fetchData = options.fetchData || {}
		/** @private {Object} */
		this.listItemOptions_ = options.listItemOptions || {}

		this.listViews = []

		var ListConstructor = this.list

		this.collection = new ListConstructor(null, options)
		this.collection.on('add', this.addListItem, this)
		this.collection.once('sync', this.ready, this)
		this.collection.fetch(this.fetchData)

		if (this.afterInitialize) {
			this.afterInitialize(options)
		}
	},

	/**
	 * {@link Backbone.Collection} class (not instance) which should be used to
	 * construct the backing collection for this list view.
	 * @type {Backbone.Collection}
	 * @abstract
	 */
	list: null,

	/**
	 * {@link Backbone.View} class (not instance) which should be used to
	 * construct individual views for each model in the collection.
	 * @type {Backbone.View}
	 * @abstract
	 */
	listItem: null,

	/**
	 * Constructs a new instance of {@link #listItem} with the specified model.
	 * @param {Backbone.View} item Item model to add.
	 * @returns {Backbone.View} View instance wrapping the item.
	 * @private
	 */
	getListItem_: function(item) {
		var options = $.extend({}, this.listItemOptions_, {
			model: item,
			appId: this.app.id
		})

		var ListItemConstructor = this.listItem

		return this.addView(new ListItemConstructor(options))
	},

	addListItem: function(item) {
		var view = this.getListItem_(item),
			edit = item.isNew()

		this.$('.list-items').append(view.render({edit: edit}).el)
	},

	prependListItem: function(item) {
		var view = this.getListItem_(item),
			edit = item.isNew()

		this.$('.list-items').prepend(view.render({edit: edit}).el)
	},

	ready: function() {
		this.trigger('ready')
		App.stopLoad()
	},

	addModel: function() {
		this.collection.add({})
	},

	sort: function(reverse) {
		this.collection.sort()

		if (reverse) {
			this.collection.models = this.collection.models.reverse()
		}

		this.listViews.forEach(function(view) {
			view.destroy()
		})

		this.listViews = []

		this.collection.forEach(this.addListItem, this)
	},

	changeSortComparator: function(e) {
		var comparator = $(e.currentTarget).data('property'),
			reverse    = $(e.currentTarget).hasClass('reverse')

		this.$('.sort-button.reverse').removeClass('reverse')

		if (!reverse) {
			$(e.currentTarget).addClass('reverse')
		}

		this.collection.comparator = comparator
		this.sort(reverse)
	}
})
