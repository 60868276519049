/**
 * Exports {@link RoleGroup}.
 * @module
 */
module.exports = Backbone.Model.extend(/** @lends RoleGroup.prototype */{
	/**
	 * @constructs RoleGroup
	 * @extends Backbone.Model
	 * @override
	 */
	initialize: function() {
	}
})
