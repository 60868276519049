var ListItemView = require('list-view/list-view-item')

module.exports = ListItemView.extend({
	template: require('./deployment-role-list-item-view-template'),

	events: {
		'click .deploy-button': 'deploy'
	},

	deploy: function() {
		if (!confirm('Are you sure you want to deploy server "' + this.model.get('name') + '"?\n\nOpen your browser Javascript console before continuing to view shell output.')) {
			return
		}

		var self = this

		$.ajax({
			url: this.model.url() + '/deploy',
			method: 'POST',
			dataType: 'json',
			headers: App.session.getHeadersObject(),

			xhr: function() {
				self.xhr = new xhook.XMLHttpRequest()
				self.xhr.addEventListener('progress', self.processResponse.bind(self), false)
				self.responseStart = 0
				return self.xhr
			},

			complete: this.deployComplete.bind(this)
		})

		console.info('Starting deploy...')
		App.startLoad()
	},

	processResponse: function() {
		// Get portion of response not already processed.
		var response = this.xhr.response || '',
			newResponse = response.substr(this.responseStart)

		// Don't process the response until the message delimiter is reached.
		if (newResponse.substr(-1) !== '\n') {
			return
		}

		// Set new response position.
		this.responseStart = this.xhr.responseText.length

		var lines = newResponse.trim().split('\n')

		lines.forEach(function(json) {
			var obj = JSON.parse(json)

			if (obj.stdout) {
				console.log(obj.stdout)
			} else if (obj.stderr) {
				console.warn(obj.stderr)
			} else if (obj.exit) {
				console.info(obj.exit)
			}
		})
	},

	deployComplete: function() {
		App.stopLoad()
		console.info('Deploy complete')
	}
})
