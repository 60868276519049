<table>
	<thead>
		<tr>
			<th style="width:100px">{{t "crossBorders.country.code"}}</th>
			<th>
				<div>{{t "crossBorders.country.name"}}</div>
				<div class="sub-text">{{t "crossBorders.country.description"}}</div>
			</th>
			<th>{{t "common.tools"}}</th>
		</tr>
	</thead>
	<tbody class="list-items"></tbody>
</table>
