{{partial "admin/admin-nav"}}

<div class="title-bar">
	<div class="header">
		<span>{{t "roles.title"}}</span>
		<button class="button add">{{t "common.add"}}</button>
	</div>
</div>

<div class="container pad-top">
	<div class="row">
		<div class="span12 title-header">{{t "roles.list"}}</div>
	</div>
	<div class="row">
		<div class="span12">

			<table>
				<thead>
					<tr>
						<th>{{t "users.name"}}</th>
						<th>{{t "common.description"}}</th>
						<th>{{t "common.tools"}}</th>
					</tr>
				</thead>
				<tbody class="list-items"></tbody>
			</table>

		</div>
	</div>
</div>
