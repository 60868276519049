<div class="empty-table-text">{{t "hazards.alerts.noAlerts"}}</div>
<div class="pagination"></div>
<table class="alerts-table">
	<thead>
		<tr>
			<th class="narrow">{{t "push.type"}}</th>
			<th>{{t "hazards.alerts.alert"}}</th>
            <th class="narrow">{{t "hazards.alerts.custom.notify"}}</th>
            <th class="narrow">{{t "hazards.alerts.custom.pushes"}}</th>
			<th class="narrow">{{t "agreement.status"}}</th>
		</tr>
	</thead>
	<tbody class="list-items"></tbody>
</table>
<div class="pagination"></div>
