var LanguageList = require('editor/language-list'),
	ManageSectionView = require('manage/manage-section-view')

module.exports = ManageSectionView.extend({
	template: require('./organization-view-template'),

	events: {
		'click .add-language-button': 'addLanguage',
		'click .save-button': 'save'
	},

	getPageTitle: function() {
		return $.t('organization.title')
	},

	initialize: function(options) {
		App.startLoad()

		this.appId = options.appId

		// Get society for the currently selected app.
		var app = App.appList.get(this.appId),
			societyId = app.get('societyId')

		this.model = App.societiesList.get(societyId)

		this.languageList = new LanguageList()
		var langFetch = this.languageList.fetch()

		this.societyLanguageList = new LanguageList(null, {societyId: societyId})
		var societyLangFetch = this.societyLanguageList.fetch()

		$.when(langFetch, societyLangFetch).then(this.ready.bind(this))
	},

	getRenderData: function() {
		var data = this.model.toJSON()
		data.appId = this.appId
		data.languages = this.languageList.toJSON()
		return data
	},

	afterRender: function() {
		this.$('#organization-locale').val(this.model.get('locale'))

		// Populate current society language assignments.
		if (this.societyLanguageList.length > 0) {
			this.$('.no-languages').hide()
		}

		this.societyLanguageList.forEach(function(lang) {
			this.$('.assigned-languages').append('<li>' + lang.get('name') + ' (' + lang.get('code') + ')</li>')
		}, this)

		if (!App.acl.hasWritePermission('Admin', 'Organization')) {
			this.$('.form-actions').remove()
			this.$('#organization-language').remove()
			this.$('.add-language-button').remove()

			this.$('input, select').attr('disabled', true)
		}
	},

	ready: function() {
		App.stopLoad()
		this.render()
	},

	addLanguage: function() {
		var id = Number(this.$('#organization-language').val()),
			lang = this.languageList.get(id),
			self = this

		if (!lang) {
			return
		}

		if (!confirm($.t('organization.confirmLanguageAssignment'))) {
			return
		}

		App.startLoad()

		var data = {
			languages: [lang.toJSON()]
		}

		this.societyLanguageList.save(null, {
			data: JSON.stringify(data)
		}).then(function() {
			App.stopLoad()
			self.render()
		})

		this.societyLanguageList.add(lang)
	},

	save: function() {
		App.startLoad()

		this.model.save({
			name: this.$('#organization-name').val(),
			description: this.$('#organization-description').val(),
			locale: this.$('#organization-locale').val()
		})

		this.model.once('sync', App.stopLoad)
	}
})
