<form class="row">
	<div class="col-xs-12">
		<div class="row">
			<div class="col-sm-6">
				<h3 data-i18n="crossBorders.editItem"></h3>
			</div>
			<div class="col-sm-6 actions-col">
				<a href="javascript:history.back()"><button class="button red wide" type="button">{{t "common.cancel"}}</button></a>
				<button class="button green wide save" type="submit">{{t "common.save"}}</button>
			</div>
		</div>
	</div>

    <div class="col-sm-6">
        <div class="form-group">
            <label class="control-label">{{t "crossBorders.country.code"}}</label>
        </div>
        <div class="form-group">
            <div class="input-group">
                <span class="input-group-addon">{{t "crossBorders.country.code"}}</span>
                <select id="country-code" class="form-control">
				{{#each countryCodes}}
                    <option value="{{alpha-2}}">{{alpha-2}} - {{name}}</option>
                {{/each}}
				</select>
            </div>
        </div>
    </div>

	<div class="col-sm-6">
		<div class="form-group">
			<label class="control-label">{{t "crossBorders.country.name"}}</label>
		</div>
		{{#each languages}}
			<div class="form-group">
				<div class="input-group">
					<span class="input-group-addon">{{name}}</span>
					<input type="text" class="name-input form-control" data-code="{{code}}">
				</div>
			</div>
		{{/each}}
	</div>

</form>

<div class="row child-views">

</div>
