<button type="button" class="close-modal" aria-label="Close"></button>

<div class="content">
	<div class="upload-button image-list-item">
		<div class="inner">
			<div class="add-placeholder">&plus;</div>
			<h4 data-i18n="mediaLibrary.upload.title"></h4>
			<p data-i18n="mediaLibrary.upload.instructions"></p>
		</div>
	</div>
	<div class="existing-button image-list-item">
		<div class="inner">
			<div>
				<i class="icon-picture"></i>
			</div>
			<h4 data-i18n="mediaLibrary.chooseExisting"></h4>
		</div>
	</div>
</div>
