<form class="row">
	<div class="col-xs-12">
		<div class="row">
			<div class="col-sm-6">
				<h3 data-i18n="crossBorders.phrase.editItem"></h3>
			</div>
			<div class="col-sm-6 actions-col">
				<a href="javascript:history.back()"><button class="button red wide" type="button">{{t "common.cancel"}}</button></a>
				<button class="button green wide save" type="submit">{{t "common.save"}}</button>
			</div>
		</div>
	</div>

	<div class="col-sm-12">

		<fieldset>
		<legend>{{t "crossBorders.phrase.phrase"}}</legend>

		{{#each languages}}
			<div class="form-group">
				<div class="input-group">
					<span class="input-group-addon">{{name}}</span>
					<input type="text" class="phrase-input form-control" data-code="{{code}}">
				</div>
			</div>
		{{/each}}

        </fieldset>
	</div>

    <div class="col-sm-12">
        <fieldset>
            <legend>{{t "crossBorders.translations"}}</legend>
			<table class="table-striped">
				<thead>
				<tr>
					<th>{{t "gdpcConsole.devPools.language"}}</th>
                    <th colspan="2">{{t "crossBorders.phrase.translation"}}</th>
				</tr>
				</thead>
				<tbody>
				{{#each translations}}
                    <tr>
                        <td class="center">{{code}}</td>
                        <td class="center">{{text}}</td>
                        <td style="width:1px">
                            <a href class="remove-row" data-id="{{id}}"><i class="icon-remove"></i></a>
                        </td>
                    </tr>
				{{/each}}
				<tr class="new-trans-row">
					<td>
                        <select class="form-control new-trans-lang">
							{{#each languageCodes}}
                                <option value="{{this.[0]}}">{{this.[2]}} - {{this.[0]}}</option>
							{{/each}}
                        </select>
					</td>
                    <td>
						<input type="text" class="form-control new-trans-text">
					</td>
					<td style="width:1px">
						<a href class="add-row"><i class="icon-ok"></i></a>
					</td>
				</tr>
				</tbody>
			</table>
		</fieldset>
	</div>

</form>

<div class="row child-views">

</div>
