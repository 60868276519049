var StormObject = require('editor/storm-object'),
	Page = require('editor/page-list/page'),
	DisasterList = require('./disaster-list'),
	DisasterListItemView = require('./disaster-list-item-view'),
	StormQL = require('models/stormql'),
	User = require('users/user'),
	HazardsSectionView = require('./hazards-section-view')

// TODO change this to /native/content/
// iOS can handle it fine, will break current Android apps.
var NATIVE_IMAGE_PREFIX = 'app://native/images/'

module.exports = HazardsSectionView.extend({
	template: require('./disasters-view-template'),

	tutorial: 'disasters',

	events: {
		'click .enable-button': 'enableDisaster',
		'click .save-button': 'save'
	},

	initialize: function(options) {
		this.app = App.appList.get(options.appId)
	},

	getPageTitle: function() {
		return $.t('hazards.disasters.title')
	},

	getRenderData: function() {
		return {appId: this.app.id}
	},

	afterRender: function() {
		// Kick out if we're not looking at a Hazards app.
		var app = App.appList.get(this.app.id)

		if (!app.isHazardsApp() && !App.developerMode) {
			setTimeout(function() {
				App.router.navigate('/', {trigger: true})
			})

			return
		}

		this.listViews = []

		var requests = []

		// Fetch full disasters list.
		this.disasterList = new DisasterList()
		var disasterFetch = this.disasterList.fetch()
		requests.push(disasterFetch)

		// Fetch disasters assigned to this app.
		this.appDisasterList = new StormQL(null, {app: this.app})
		this.appDisasterList.comparator = 'order'
		var appDisasterFetch = this.appDisasterList.fetch({data: {class: 'StormQLDisaster'}})
		requests.push(appDisasterFetch)

		// Rebuild tab page when disasters are removed.
		this.listenTo(this.appDisasterList, 'remove', this.disasterRemoved, this)

		// Fetch page list to construct root page dropdowns.
		this.pageList = this.app.pageList
		var pageListFetch = this.pageList.fetchOnce()
		requests.push(pageListFetch)

		// Fetch root page.
		this.rootPage = new Page({id: app.get('objectId')})
		var rootPageFetch = this.rootPage.fetch()
		requests.push(rootPageFetch)

		// Hide features based on permissions.
		if (!App.acl.hasWritePermission('Hazards', 'Disasters')) {
			this.$('.save-button').remove()
		}

		// Render page again once all data loaded.
		Promise.all(requests).then(this.ready.bind(this))
	},

	ready: function() {
		// Generate page list dropdown.
		var pageListDropdownOptions = '<option value="">-</option>'

		// Get array of all unique tag names
		var tags = _.filter(this.pageList.pluck('tag'), function(elem, pos, self) {
			return self.indexOf(elem) === pos
		}).sort()

		// Output an option group for each tag
		_.each(tags, function(tag) {
			var taggedPages = this.pageList.where({tag: tag})
			var options = ''

			// Output an option for each page with this tag
			_.each(taggedPages, function(page) {
				options += '<option value="cache://pages/' + page.id + '.json">' + App.l(page.get('title')) + '</option>'
			})

			pageListDropdownOptions += '<optgroup label="' + tag + '">' + options + '</optgroup>'
		}, this)

		this.rootPageOptions = pageListDropdownOptions
		this.renderDisasters()
		App.stopLoad()
	},

	renderDisasters: function() {
		this.listViews.forEach(function(view) {
			view.destroy()
		})

		this.listViews = []

		// Order disasters list with active disasters first.
		this.appDisasterList.forEach(function(appDisaster, i) {
			var disaster = this.disasterList.get(appDisaster.get('disasterId'))

			this.disasterList.remove(disaster)
			this.disasterList.add(disaster, {at: i})
		}, this)

		this.disasterList.forEach(this.addDisaster, this)

		// Setup table row drag/drop plugin (if write permission).
		if (App.acl.hasWritePermission('Hazards', 'Disasters')) {
			this.$('table').tableDnD({
				onDragClass: 'dragging',
				onDrop: this.rowDrop.bind(this)
			})
		}
	},

	addDisaster: function(disaster) {
		// Get app disaster assignment associated with this disaster (if exists).
		var model = this.appDisasterList.findWhere({disasterId: disaster.id})

		var view = new DisasterListItemView({
			disaster: disaster,
			model: model,
			rootPageOptions: this.rootPageOptions
		})

		this.addView(view)
		this.$('.list-items').append(view.render().el)
	},

	enableDisaster: function(e) {
		// Create new StormQLDisaster object for this disaster type.
		var disasterId = $(e.currentTarget).data('id')
		var disaster = StormObject.fromClassName('StormQLDisaster', 0)

		disaster.set('disasterId', disasterId)
		disaster.set('src..class', 'InternalLink')
		disaster.set('order', this.appDisasterList.length)

		this.appDisasterList.push(disaster)
		this.renderDisasters()
	},

	rowDrop: function(table) {
		var rows = table.tBodies[0].rows

		_.forEach(rows, function(row, i) {
			if (!row.id) {
				return
			}

			var id = row.id.match(/^row-(\w+)$/)[1],
				disaster = this.appDisasterList.get(id)

			if (disaster.get('order') !== i) {
				disaster.set('order', i)
				disaster.needsSaving = true
			}
		}, this)

		this.appDisasterList.sort()
	},

	save: function() {
		// Don't allow disasters to be saved if there are disasters with no pages specified.
		var missingPage = this.appDisasterList.some(function(disaster) {
			return disaster.get('src..destination') === ''
		})

		if (missingPage) {
			App.showToast($.t('hazards.disasters.pleaseSpecifyPages'))
			return
		}

		App.startLoad()

		var requests = [],
			regenerateTabs = false,
			appId = this.app.id

		// Save each changed disaster
		this.appDisasterList.forEach(function(disaster) {
			if (disaster.isNew()) {
				requests.push(disaster.save(null, {appId: appId}))
				regenerateTabs = true
			} else if (disaster.needsSaving) {
				// Remove all events flagged as 'deleted'.
				var events = Array.prototype.slice.call(disaster.get('events').models, 0)

				events.forEach(function(e) {
					if (e.deleted) {
						e.requestLock(function() {
							requests.push(e.destroy())
						})
					}
				})
				// Lock then save
				disaster.requestLock(function() {
					requests.push(disaster.save().then(disaster.requestUnlock()))
				})
				regenerateTabs = true
			}

			delete disaster.needsSaving
		})

		// Rebuild tab page if disasters added/reordered.
		// ONLY on GDPC Hazards.
		var isGDPC = App.system.id === 3

		// We no longer need to rebuild the tab page
		regenerateTabs = false
		if (regenerateTabs && isGDPC) {
			requests = requests.concat(this.rebuildTabPage())
		}

		// Wait for all save/delete requests to complete.
		$.when.apply($, requests).then(function() {
			App.showToast($.t('hazards.disasters.saveSuccess'))
		}, function() {
			App.showToast($.t('hazards.disasters.saveError'))
		}).always(App.stopLoad)
	},

	// Remove all existing disaster tabs and create new for each enabled disaster.
	rebuildTabPage: function() {
		var requests = []

		// We can't perform page saves until we have both Page and PageCollection subclasses.
		var deferred = new jQuery.Deferred()
		requests.push(deferred)

		// Fetch list of app languages so we know which disaster localisations to set.
		var appLanguages = this.app.languageList

		// Attempt to lock the root page so we can edit it.
		var lockPromise = this.rootPage.lock.lock(),
			self = this

		lockPromise.catch(function(err) {
			console.log(err)
		})

		Promise.all([
			lockPromise,
			Promise.resolve(App.getSubclasses('Page')),
			Promise.resolve(App.getSubclasses('PageCollection')),
			Promise.resolve(appLanguages.fetchOnce())
		]).then(function() {
			var pageId = self.rootPage.id,
				pages = self.rootPage.get('pages')

			// Destroy all old disaster tabs (those with 'disaster-icon-*' icons).
			for (var i = pages.length - 1; i >= 0; i--) {
				var page = pages.at(i),
					icon = page.get('tabBarItem').get('image')

				if (icon.class === 'NativeImage' && icon.src.search('disaster-icon-') > -1) {
					page.destroy()
				}
			}

			// Save root page to update ordering, with existing tabs first.
			self.rootPage.save().then(function() {
				// Create new tabs in order.
				self.appDisasterList.forEach(function(disaster) {
					var fullDisaster = self.disasterList.get(disaster.get('disasterId')),
						codeName = fullDisaster.get('codeName')

					var tab = StormObject.fromClassName('TabPageDescriptor', pageId),
						destUrl = disaster.get('src..destination'),
						destId = App.getIdFromCacheUrl(destUrl)

					// Fetch page object to get class name.
					var page = self.pageList.get(destId)

					tab.set('src', destUrl)
					tab.set('type', page.get('class'))

					// Set icons to NativeImage objects.
					var tabBarItem = tab.get('tabBarItem')

					tabBarItem.set('image', App.getClassStructure('NativeImage', pageId))
					tabBarItem.set('placeholderImage', App.getClassStructure('NativeImage', pageId))

					// Set NativeImage sources.
					tabBarItem.set('image..src', NATIVE_IMAGE_PREFIX + 'disaster-icon-' + codeName)
					tabBarItem.set('placeholderImage..src', NATIVE_IMAGE_PREFIX + 'disaster-placeholder-' + codeName)

					// Set disaster name in all applicable languages.
					var content = tab.get('tabBarItem').get('title..content'),
						disasterName = fullDisaster.get('name')

					appLanguages.forEach(function(language) {
						var code = language.get('code')

						content[code] = disasterName[code]
					})

					// Set default restrictions.
					tab.attributes.restrictions = [
						'edit',
						'delete'
					]

					pages.push(tab)
				})

				// Save root page with new tabs.
				self.rootPage.save().then(deferred.resolve, deferred.reject).always(function() {
					self.rootPage.unlock()
				})
			})
		}, function(jqXHR) {
			if (jqXHR.status === 423) {
				// Fetch name of locking user
				var user = new User({id: jqXHR.responseJSON['client error'].data.userId})
				user.fetch()
				user.once('sync', function() {
					App.showToast($.t('error.lockedBy') + user.get('firstName') + ' ' + user.get('lastName'))
					deferred.reject()
				})
			} else {
				swal($.t('error.oops'), $.t('error.generic'), 'error')
				deferred.reject()
			}
		})

		return requests
	},

	// Rebuild tab page when disaster removed.
	disasterRemoved: function(model) {
		if (model.isNew()) {
			return
		}

		// Model is being deleted on the server - show loading indicator.
		App.startLoad()

		var requests = this.rebuildTabPage()

		$.when.apply($, requests).always(function() {
			App.stopLoad()
		})
	}
})
