<td valign="top" class="publish-history-item">
	<div class="row clearfix">
		<div class="publish-audit-box">
			<div class="col-xs-12">
				<div>
					<h4>{{comment}}</h4>
				</div>
				<div class="status-gem status-{{status}} pull-left"
						 title="{{getPublishStatus status}}"></div>
				<span>&nbsp;{{getPublishStatus status}}</span>
				<br>
				<p>by {{user}}, <span class="timestamp">{{formatTime timestamp}}</span></p> <br>
				<a href class="pages-link">{{t "publish.detail"}} <i class="icon-sort-down"></i></a>
				<div class="pages-list hidden"></div>
			</div>
		</div>
	</div>
</td>
