{
	"error": {
		"noAccess": "Anda tidak memiliki akses ke sistem ini. Silakan hubungi administrator",
		"noAccessJoin": "Anda saat ini tidak memiliki akses ke sistem ini. Apakah Anda ingin bergabung?",
		"generic": "Sebuah kesalahan telah terjadi.",
		"saveFailed": "Gagal menyimpan. Silakan segarkan halaman.",
		"rootPage": "Halaman akar tidak dapat ditemukan.",
		"noApps": "Anda tidak memiliki akses ke aplikasi apa pun",
		"cantPaste": "Jenis objek ini tidak dapat ditempelkan di sini.",
		"connect": "Tidak dapat terhubung ke server API.",
		"exitWarning": "Data aplikasi masih disimpan. Jika Anda meninggalkan halaman sekarang data mungkin hilang.",
		"noPermission": "Anda tidak memiliki izin untuk melakukan tindakan itu.",
		"oops": "Ups!",
		"lockedBy": "Objek ini saat ini dikunci oleh",
		"allFields": "Harap isi semua bidang."
	},
	"common": {
		"filter": "Filter",
		"linkTypes": {
			"email": "Email",
			"internal": "Intern",
			"native": "Asli",
			"external": "Luar",
			"sms": "SMS",
			"share": "Bagikan",
			"call": "Panggilan",
			"emergency": "Keadaan darurat",
			"uri": "URI",
			"appLink": "Inter-app",
			"timer": "Timer"
		},
		"linkDescriptions": {
			"email": "Tautan untuk mengirim email ke alamat yang disebutkan.",
			"timer": "Tautan yang memulai penghitung waktu mundur pada perangkat.",
			"internal": "Tautan ke halaman berbeda dalam aplikasi.",
			"share": "Tautan untuk membagikan aplikasi melalui dialog 'bagikan' asli di perangkat.",
			"native": "Tautan ke bagian fungsi asli dalam aplikasi.",
			"uri": "Tautan ke laman web atau aplikasi eksternal. Akan menyebabkan pengguna meninggalkan aplikasi.",
			"appLink": "Tautan ke laman di aplikasi lain.",
			"external": "Tautan ke halaman web eksternal. Akan terbuka di dalam aplikasi.",
			"sms": "Tautan untuk mengirim pesan SMS dari perangkat pengguna.",
			"emergency": "Tautan untuk menghubungi nomor telepon darurat yang ditentukan dalam pengaturan pengguna.",
			"call": "Tautan untuk menghubungi nomor telepon dari perangkat pengguna."
		},
		"pageTags": {
			"other": "Lain",
			"emergency": "Keadaan darurat",
			"info": "Info",
			"learn": "Belajar",
			"prepare": "Mempersiapkan",
			"tests": "Tes"
		},
		"publish": "Menerbitkan",
		"approved": "Disetujui",
		"pleaseWait": "Mohon tunggu",
		"reject": "Menolak",
		"approve": "Menyetujui",
		"upload": "Unggah",
		"sections": "Bagian",
		"clickToBrowseMap": "Klik untuk menelusuri peta",
		"submit": "Menyerahkan",
		"description": "Deskripsi",
		"save": "Menyimpan",
		"back": "Kembali",
		"username": "Nama pengguna",
		"warning": "PERINGATAN",
		"tag": "Menandai",
		"next": "Berikutnya",
		"cancel": "Membatalkan",
		"language": "Bahasa",
		"email": "Alamat email",
		"settings": "Pengaturan",
		"position": "Posisi",
		"page": "Halaman",
		"view": "Melihat",
		"close": "Dekat",
		"preview": "Preview",
		"finish": "Selesai",
		"add": "Menambahkan",
		"done": "Selesai",
		"edit": "Edit",
		"tools": "Alat-alat",
		"closeThisMessage": "Tutup pesan ini",
		"longLoad": "Mengambil sejumlah besar data, ini bisa memakan waktu hingga satu menit."
	},
	"login": {
		"title": "Login",
		"inputs": {
			"password": "Kata sandi"
		},
		"error": {
			"emailNotFound": "Alamat email tidak ditemukan.",
			"title": "Oopsie!",
			"body": "Anda memasukkan nama pengguna atau kata sandi yang salah.",
			"linkExpired": "Tautan setel ulang kata sandi ini telah kedaluwarsa."
		},
		"forgot": {
			"body": "Masukkan alamat email yang tertaut ke akun Anda.",
			"title": "Terkadang kita semua lupa!"
		},
		"success": {
			"title": "Keberhasilan!",
			"body": "Anda harus segera menerima email dengan instruksi untuk mereset kata sandi Anda.",
			"loginBody": "Anda berhasil masuk, tetapi tidak ada tempat untuk pergi! Anda berada di auth.3sidedcube.com. Silakan pergi ke Storm CMS organisasi Anda."
		},
		"links": {
			"forgot": "Saya lupa...",
			"return": "Kembali untuk masuk"
		},
		"reset": {
			"confirm": "Memastikan",
			"newPassword": "Kata sandi baru",
			"submit": "Menyerahkan",
			"success": "Kata sandi Anda telah diatur, Anda sekarang dapat menutup jendela ini."
		}
	},
	"localisations": {
		"title": "Pelokalan",
		"string": "Utas",
		"key": "Kunci",
		"addNew": "Tambah pelokalan baru",
		"addMultiple": "Tambah beberapa pelokalan",
		"addMultipleInstructions": "Tambah atau perbarui pelokalan dalam ruah. Gunakan CSV untuk pemformatan",
		"uploadCsv": "Unggah CSV",
		"copyFromAnotherApp": "Salin dari aplikasi lain",
		"confirmCopy": "Anda yakin ingin menyalin pelokalan dari aplikasi yang dipilih?",
		"overwriteExisting": "Timpa pelokalan yang ada",
		"overwriteWarning": "Semua terjemahan yang telah Anda lakukan akan ditimpa.",
		"all": "<b>All</b> pelokalan",
		"errors": {
			"csvTooManyColumns": "Terlalu banyak header dalam kolom.",
			"csvInvalidHeader": "Header tidak berlaku.",
			"csvInvalidKeyOnLine": "Kunci pelokalan tidak berlaku pada baris",
			"csvInvalidColumnCountOnLine": "Jumlah kolom tidak berlaku pada baris",
			"expected": "Akan datang",
			"got": "Didapatkan",
			"invalidKey": "Kunci pelokalan tidak valid. Kunci harus dimulai dengan garis bawah dan hanya berisi huruf besar dan garis bawah. Kunci harus berisi setidaknya dua bagian (bit yang dipisahkan oleh garis bawah)."
		}
	},
	"appSetup": {
		"expandAll": "Perluas semua",
		"addCsv": "Tambahkan CSV",
		"startPage": "Halaman awal",
		"live": "Hidup",
		"type": "Mengetik",
		"key": "Kunci",
		"value": "Nilai",
		"properties": "Properties",
		"translation": "Terjemahan",
		"dev": "Dev",
		"localisation": "Lokalisasi aplikasi",
		"languages": "Bahasa aplikasi",
		"title": "Pengaturan Aplikasi",
		"publishable": "Dapat dipublikasikan ke:",
		"publishableLanguages": "Bahasa yang dapat dipublikasikan",
		"bundleIdentifiers": "SDK Bundle Identifiers",
		"addMoreLanguages": "Hanya bahasa yang ditetapkan untuk organisasi ini tersedia di sini. Tetapkan lebih banyak di bagian Admin.",
		"translationError": "Gagal mengunduh file terjemahan. Harap periksa aplikasi dan organisasi setidaknya memiliki satu bahasa yang diaktifkan dan dapat dipublikasikan.",
		"appName": "Nama aplikasi"
	},
	"metadata": {
		"addScreenshot": "Tambahkan Tangkapan Layar",
		"appIconHint": "Harus berukuran 1024 x 1024 tanpa transparansi. Harus dalam format JPG atau PNG, dengan resolusi minimal sekurang-kurangnya 72 DPI, dan dalam ruang warna RGB. Tidak dapat mengandung lapisan atau pojok-pojok yang dibulatkan",
		"appPreviewVideo": "Video pratinjau aplikasi",
		"appPreviewVideoHint": "Pratinjau video aplikasi harus dalam format M4V, MP4, atau MOV dan tidak boleh melebihi 500 MB",
		"appStoreTitle": "Judul toko aplikasi",
		"delete": "Hapus",
		"emailHint": "Sediakan alamat email untuk dihubungi oleh App store atau pengguna",
		"featureGraphic": "Grafik fitur",
		"featureGraphicHint": "Google Play store mengharuskan aplikasi untuk memiliki grafik fitur atau 'banner' - dimensinya harus 1024w x 500h. Banner ini dapat berupa gambar terpisah atau gambar latar untuk video promo (jika tersedia)",
		"languages": "Bahasa",
		"nameOnDevice": "Nama aplikasi pada perangkat",
		"phoneNumberHint": "Harus terdapat kode panggilan negara",
		"platformSpecific": "Metadata spesifik platform",
		"price": "Harga",
		"rating": "Peringkat",
		"screenshotsHint": "Termasuk tangkapan layar untuk setiap ukuran layar. Tangkapan layar harus dalam format JPG atau PNG, dan dalam ruang warna RGB",
		"shared": "Metadata lintas platform",
		"shortAppDescription": "Deskripsi singkat aplikasi",
		"supportUrlHint": "URL dengan informasi dukungan untuk aplikasi Anda. URL ini akan tampak pada App Store",
		"websiteHint": "URL dengan informasi pemasaran tentang aplikasi Anda. URL ini akan tampak pada App Store",
		"launchDate": "Tanggal peluncuran",
		"noBuildPlatforms": "Tidak ada platform build untuk aplikasi ini",
		"iconSize": "Ikon asli harus berukuran 1024x1024.",
		"description": "Deskripsi",
		"name": "Nama aplikasi",
		"features": "fitur",
		"phoneNumber": "Nomor telepon",
		"website": "URL pemasaran",
		"supportUrl": "URL dukungan",
		"title": "Metadata",
		"keywords": "Kata kunci",
		"privacyPolicyUrl": "URL kebijakan privasi",
		"secondaryCategory": "Kategori sekunder",
		"appStore": "Detail App Store",
		"primaryCategory": "Kategori utama",
		"appIcon": "Ikon aplikasi",
		"copyright": "hak cipta"
	},
	"plugins": {
		"title": "Plugin",
		"availablePlugins": "Plugin Tersedia"
	},
	"editor": {
		"inspector": {
			"swapItem": "Sapu item",
			"properties": {
				"progressMessage": "Pesan kemajuan",
				"annotation": {
					"title": "Anotasi",
					"description": "Nomor langkah"
				},
				"title": {
					"description": "Daftar judul item",
					"title": "Judul"
				},
				"name": {
					"title": "Nama",
					"description": "Nama sistem internal"
				},
				"hint": {
					"title": "Petunjuk",
					"description": "Instruksi untuk pengguna"
				},
				"image": {
					"title": "Gambar",
					"chooseButton": "Pilih gambar",
					"removeButton": "Hapus gambar"
				},
				"video": {
					"title": "Video",
					"loop": "Loop",
					"chooseButton": "Pilih video",
					"addLanguage": "Tambahkan bahasa",
					"attributes": "Atribut"
				},
				"link": {
					"testLink": "Uji tautan",
					"linkDestination": "Tautan Tujuan",
					"linkTitle": "Judul Tautan",
					"type": "Mengetik",
					"destination": "Tujuan",
					"title": "Link",
					"body": "Teks isi",
					"duration": "Durasi pengatur waktu (menit)",
					"recipients": "Penerima (satu per baris)"
				},
				"images": {
					"add": "Menambahkan gambar"
				},
				"animation": {
					"chooseButton": "Pilih animasi",
					"delay": "Penundaan bingkai (detik)"
				},
				"embeddedLinks": {
					"add": "Tambahkan tautan",
					"title": "Tautan yang disematkan"
				},
				"embeddedMedia": {
					"title": "Media tersemat"
				},
				"header": {
					"title": "Header",
					"description": "Muncul di atas grup item"
				},
				"footer": {
					"title": "Footer",
					"description": "Muncul di bawah grup item"
				},
				"badge": {
					"completion": {
						"title": "Teks penyelesaian",
						"description": "Ditampilkan kepada pengguna saat penyelesaian kuis"
					},
					"how": {
						"title": "Cara menghasilkan",
						"description": "Teks yang menjelaskan cara meraih lencana ini"
					},
					"title": "Lencana",
					"icon": "Ikon"
				},
				"units": {
					"description": "detik / meter dll.",
					"title": "Unit"
				},
				"range": {
					"length": "Panjangnya",
					"start": "Mulai",
					"title": "Jarak",
					"increment": "Kenaikan"
				},
				"completionText": {
					"title": "Teks penyelesaian",
					"description": "Ditampilkan kepada pengguna saat menjawab dengan benar"
				},
				"failureText": {
					"title": "Teks kegagalan",
					"description": "Ditampilkan kepada pengguna saat menjawab salah"
				},
				"winThreshold": {
					"title": "Menangkan ambang (%)",
					"description": "Persentase jawaban yang benar yang diperlukan untuk lulus kuis"
				},
				"winMessage": {
					"description": "Ditampilkan kepada pengguna saat lulus tes",
					"title": "Menangkan pesan"
				},
				"loseMessage": {
					"description": "Ditampilkan kepada pengguna saat gagal tes",
					"title": "Kalah pesan"
				},
				"shareMessage": {
					"title": "Bagikan pesan",
					"description": "Teks untuk dibagikan dengan lencana di jejaring sosial"
				},
				"answer": "Menjawab",
				"textContent": "Konten teks",
				"quizzes": "Kuis",
				"volatile": "Volatile (jangan bertahan)",
				"quiz": "Ulangan",
				"initialPosition": "Posisi awal",
				"button": "Tombol",
				"correctAnswer": "Jawaban yang benar",
				"standard": "Standar",
				"relatedLinks": "Tautan yang berhubungan",
				"columns": "Kolom",
				"selectionLimit": "Batas pilihan",
				"caption": "Caption",
				"gridType": "Jenis jaringan",
				"loseRelatedLinks": "Kalah tautan terkait",
				"winRelatedLinks": "Menangkan tautan terkait",
				"placeholderImage": "Gambar placeholder",
				"overlay": "Teks overlay",
				"delay": "Delay (s)",
				"confirmGridTypeChange": "Apakah Anda yakin ingin mengubah jenis grid? Ini akan mengubah semua item grid."
			},
			"title": "Edit Properti",
			"moveDown": "Turun",
			"addAnother": "Tambahkan yang lain",
			"copySuccess": "Objek disalin ke papan klip",
			"confirmPageDelete": "Konfirmasikan Penghapusan Halaman",
			"rootPageDelete": "Ini adalah halaman root dari aplikasi. Itu tidak bisa dihapus.",
			"confirmPageDeleteWarning": "PERINGATAN: Anda menghapus objek halaman. Semua konten halaman akan dihapus. Apakah anda yakin ingin melanjutkan?",
			"copy": "Salinan",
			"areYouSure": "Apakah kamu yakin?",
			"paste": "Pasta",
			"newType": "Tipe baru",
			"moveUp": "Pindah ke atas",
			"addBulletItem": "Tambahkan butir peluru",
			"copiedItem": "Item yang disalin",
			"changeType": "Ubah jenis",
			"confirmDelete": "Anda yakin ingin menghapus elemen ini?",
			"confirmApproval": "Anda yakin ingin menyetujui ini?"
		},
		"objectPreviews": {
			"TextListItem": "Gunakan item ini untuk teks yang mengawali, biasanya di bagian atas halaman",
			"TitleListItem": "Gunakan item ini untuk teks satu baris",
			"DescriptionListItem": {
				"title": "Item ini memiliki judul dan deksripsi",
				"description": "Gunakan item ini jika Anda ingin menyampaikan banyak hal"
			},
			"StandardListItem": {
				"title": "Selalu gunakan item ini",
				"description": "Item ini memiliki judul, deskripsi dan ikon. Anda dapat tidak mengisinya -- misalnya ikon"
			},
			"ToggleableListItem": {
				"title": "Gunakan ini jika ada yang ingin disembunyikan",
				"description": "Teks ini dapat ditampilkan dan disembunyikan oleh pengguna. Sesuai jika Anda memiliki banyak detail -- mungkin seperti FAQ"
			},
			"OrderedListItem": {
				"title": "Daftar urut",
				"description": "Gunakan ini jika Anda ingin daftar yang bernomor"
			},
			"UnorderedListItem": {
				"title": "Daftar tak urut",
				"description": "Gunakan ini jika Anda ingin daftar hanya memiliki poin tanpa nomor"
			},
			"CheckableListItem": "Gunakan ini untuk daftar periksa",
			"ButtonListItem": "Gunakan ini jika Anda ingin menggunakan tombol",
			"HeaderListItem": {
				"title": "Gunakan ini di bagian atas halaman",
				"description": "Dapat memiliki judul dan deskripsi lebih panjang"
			},
			"LogoListItem": "Gunakan ini jika Anda ingin menyertakan gambar, keterangan, dan tautan misalnya untuk logo",
			"SpotlightListItem": {
				"first": "Berputar",
				"second": "Sorot"
			},
			"ChunkyListItemView": {
				"title": "Seperti item daftar standar -- dengan lebih banyak bantalan",
				"description": "Ini memiliki judul, deskripsi dan ikon. Anda dapat membiarkannya kosong -- misalnya ikon"
			},
			"ListItemView": "Item ini hanya memiliki judul"
		},
		"pageList": {
			"allPages": "Semua halaman",
			"title": "Halaman",
			"filter": "Filter halaman"
		},
		"title": "Editor",
		"pageType": "Jenis halaman",
		"addQuiz": "Tambahkan kuis",
		"confirmRelock": "Objek ini sudah dikunci oleh Anda. Apakah Anda ingin menguncinya lagi? Kunci Anda sebelumnya akan dicabut.",
		"confirmRequestNewLock": "Kunci Anda untuk halaman ini telah dicabut. Apakah Anda ingin meminta kunci baru?",
		"addPage": "Tambah halaman",
		"saving": "Penghematan...",
		"pageName": "Nama halaman",
		"never": "tak pernah",
		"pageTag": "Tag halaman",
		"pageStyle": "Gaya halaman",
		"addLink": "Tambahkan tautan",
		"addObject": "Tambahkan objek di sini",
		"addApp": "Tambahkan aplikasi",
		"savesQueued": "menghemat antrean",
		"newFromTemplate": "Baru dari template",
		"lastSaved": "Terakhir disimpan:"
	},
	"mediaLibrary": {
		"chooseExisting": "Pilih yang tersedia",
		"upload": {
			"instructions": "Klik di sini atau seret file untuk mulai",
			"cropType": "Tipe pemotongan",
			"noCrop": "Tanpa pemotongan",
			"square": "Kotak",
			"squareDesc": "Gunakan pemotongan ini untuk ikon dan gambar kuis. Gambar kuis harus <b>640(w)</b> x <b>640(h)</b> piksel",
			"errors": {
				"tooManyFiles": "Pilih satu file"
			},
			"requiresTransparency": "Perlu latar transparan",
			"spotlightDesc": "Gunakan tanaman ini untuk lampu sorot, ujung ke ujung, tampilan slide dan gambar video. Gambar harus berukuran <b>640 (w)</b> dengan ukuran <b>320 (h)</b> piksel.",
			"title": "Unggah media",
			"tags": "Tag",
			"newTag": "Tag baru",
			"genericError": "Pengunggahan file gagal. Kode kesalahan:",
			"filesizeError": "Gagal mengunggah. File terlalu besar. Ukuran file maksimum yang diizinkan adalah",
			"selectType": "Silakan pilih jenis gambar yang Anda unggah",
			"invalidTag": "Tag tidak valid. Tag tidak boleh berisi simbol wildcard (*).",
			"agreementText": "Semua konten yang diunggah ke sistem ini tunduk pada ketentuan",
			"gdpcHeaderDesc": "Gunakan potongan ini untuk gambar header besar di aplikasi Bahaya. Gambar harus berukuran <b>640 (w)</b> dengan ukuran <b>568 (h)</b> piksel.",
			"headerDesc": "Gunakan potongan ini untuk gambar header besar yang muncul di bagian atas halaman. Gambar harus berukuran <b>640 (w)</b> dengan ukuran <b>568 (h)</b> piksel.",
			"formatError": "Gagal mengunggah. Format file tidak valid. Format gambar yang diizinkan: .jpeg, .png, .gif Format video yang diizinkan: .mp4, .m4v",
			"gdpcSpotlightDesc": "Gunakan crop ini untuk header di aplikasi First Aid, lampu sorot, ujung ke ujung, tampilan slide dan gambar video. Gambar harus berukuran <b>640 (w)</b> dengan ukuran <b>320 (h)</b> piksel.",
			"imageDesc": "Gambar <b>640 (w)</b> dengan <b>320 (h)</b> piksel direkomendasikan untuk gambar tepi ke tepi, gambar slideshow dan gambar video. Gambar kuis harus <b>640 (w)</b> dengan <b>640 (h)</b> piksel."
		},
		"video": "Video",
		"image": "Gambar",
		"loadMore": "Muat lebih",
		"icon": "Ikon",
		"section": "Bagian",
		"animation": "Animasi",
		"society": "Masyarakat",
		"general": "Umum",
		"editMedia": "Edit media",
		"refine": "Sempurnakan pustaka",
		"filterByTags": "Filter berdasarkan tag",
		"clearFilters": "Hapus filter",
		"title": "Perpustakaan Media",
		"additionalTags": "Tag tambahan",
		"dragToReorder": "Seret untuk menyusun ulang",
		"type": "Jenis media",
		"confirmTagRemoval": "Anda yakin ingin menghapus tag ini?",
		"delete": "Menghapus",
		"commas": "Pisahkan dengan koma"
	},
	"viewPicker": {
		"TextListItem": "Teks ujung ke ujung antarkelompok",
		"TitleListItem": "Teks satu baris",
		"StandardListItem": "Judul, tautan dan ikon",
		"DescriptionListItem": "Judul dan deskripsi teks multi-baris",
		"ToggleableListItem": "Judul yang dapat diklik yang menunjukkan konten teks lebih panjang",
		"OrderedListItem": "Judul dan kontek teks multibaris, dengan anotasi atau nomor langkah",
		"UnorderedListItem": "Item daftar poin dengan judul dan deskripsi",
		"CheckableListItem": "Judul dan kotak periksa. Kondisi dapat bertahan di antara pengguna aplikasi",
		"ButtonListItem": "Judul dan tombol",
		"ImageListItem": "Satu gambar, ujung ke ujung",
		"HeaderListItem": "Gambar besar dengan judul timpa dan deskripsi",
		"VideoListItem": "Objek video yang mendukung banyak sumber",
		"AnimatedImageListItem": "Seri gambar ditayangkan sebagai slideshow, memberikan animasi",
		"SpotlightImageListItem": "Satu atau lebih gambar sorot, berisi gambar, keterangan dan tautan",
		"LogoListItem": "Digunakan untuk menampilkan brand perusahaan",
		"SliderQuizItem": "Pertanyaan pilihan slider gambar",
		"TextQuizItem": "Pertanyaan pilihan teks",
		"ImageQuizItem": "Pertanyaan pilihan gambar",
		"AreaQuizItem": "Pertanyaan pilihan daerah",
		"CollectionListItem": "Tampilan 'Kumpulkan Semua' untuk aplikasi, lencana dan tautan",
		"spotlight": "Menyoroti",
		"text": "Teks",
		"filter": "Filter objek",
		"hiddenText": "Teks tersembunyi",
		"title": "Objek",
		"basicObjects": "Objek dasar",
		"interactiveObjects": "Objek interaktif",
		"mediaObjects": "Objek media",
		"multiLineObjects": "Objek multi-garis",
		"leadInText": "Timbul teks",
		"dragAndDrop": "Tarik dan lepaskan objek untuk menambahkannya ke halaman",
		"subText": "Sub-teks"
	},
	"users": {
		"removeUserConfirm": "Anda yakin ingin menghapus pengguna ini?",
		"failedToRemove": "Gagal menghapus pengguna",
		"firstName": "Nama depan",
		"name": "Nama",
		"role": "Peran",
		"lastName": "nama keluarga",
		"createUser": "Buat pengguna",
		"organisation": "Organisasi",
		"apps": "Aplikasi",
		"complete": "Pengguna berhasil dibuat",
		"title": "Pengguna",
		"lastActive": "Terakhir aktif",
		"roles": "Peran",
		"list": "Daftar pengguna",
		"success": "Keberhasilan! Pengguna baru akan menerima email dengan instruksi untuk mengaktifkan akun mereka.",
		"usernameHelp": "Nama pengguna hanya boleh berisi huruf, angka, setrip bawah, tanda hubung, dan titik.",
		"error": "Tidak dapat membuat pengguna. Periksa nama pengguna dan email belum terdaftar."
	},
	"userApps": {
		"title": "Aplikasi pengguna"
	},
	"push": {
		"messageDescription": "Pesan notifikasi push",
		"categories": {
			"region": "Daerah",
			"future": "Masa depan",
			"custom": "Adat",
			"standard": "Standar"
		},
		"custom": {
			"area": "Daerah target",
			"areaHelp": "Klik peta untuk menentukan daerah untuk notifikasi ini"
		},
		"error": {
			"length": "Pesan notifikasi push terlalu panjang",
			"messageEmpty": "Masukkan pesan",
			"missingRegion": "Pilih daerah"
		},
		"what": "Apa yang ingin Anda kirim?",
		"when": "Kapan Anda ingin mengirimnya?",
		"addToQueue": "Tambahkan ke antrian",
		"types": {
			"default": "Default"
		},
		"priorities": {
			"high": "Tinggi",
			"low": "Rendah"
		},
		"approve": "Menyetujui",
		"reject": "Menolak",
		"numberSent": "Total",
		"notes": "Catatan",
		"createdBy": "Dibuat oleh",
		"assignments": "Tugas",
		"type": "Mengetik",
		"numberCompleted": "Lengkap",
		"message": "Pesan",
		"noNotifications": "Tidak ada pemberitahuan",
		"sending": "Mengirim",
		"showAllApps": "Tampilkan semua aplikasi",
		"noPageSpecified": "Tidak ada halaman yang ditentukan",
		"pushDetails": "Detail pemberitahuan",
		"editPush": "Edit pemberitahuan push",
		"existing": "Pemberitahuan push yang ada",
		"priority": "Prioritas",
		"sent": "Dorong terkirim",
		"saveAndSend": "Simpan dan kirim",
		"payload": "Muatan",
		"category": "Kategori"
	},
	"hazards": {
		"title": "Bahaya",
		"disasters": {
			"categories": {
				"winter": "Musim dingin",
				"wildfire": "Kebakaran",
				"hurricane": "Badai",
				"wind": "Angin",
				"storm": "Badai",
				"earthquake": "Gempa bumi",
				"nuclear": "Nuklir",
				"tornado": "Angin topan",
				"tsunami": "Tsunami",
				"coastal": "Pesisir",
				"heatwave": "Heatwave",
				"volcano": "Gunung berapi",
				"flood": "Banjir"
			},
			"tutorial": {
				"heading": "Apa arti dari berbagai negara?",
				"body": "- Diaktifkan artinya halaman akan muncul di editor konten untuk Anda mulai mengerjakannya. <br> - Dev berarti halaman akan muncul di build pengembangan untuk aplikasi ini. <br> - Live berarti halaman akan muncul di app store build untuk aplikasi ini."
			},
			"configure": "Konfigurasikan",
			"enable": "Memungkinkan",
			"whatNow": "Halaman 'Apa sekarang'",
			"disable": "Nonaktifkan",
			"title": "Bencana",
			"rootPage": "Halaman root",
			"alertSettings": "Pengaturan tanda",
			"saveError": "Gagal menyimpan konfigurasi aplikasi.",
			"pleaseSpecifyPages": "Silakan tentukan halaman untuk setiap jenis bencana yang dipilih.",
			"saveSuccess": "Konfigurasi aplikasi disimpan."
		},
		"alerts": {
			"times": {
				"hour": "Jam",
				"day": "Hari",
				"allTime": "Sepanjang waktu",
				"week": "Minggu",
				"month": "Bulan"
			},
			"custom": {
				"types": {
					"announcement": "Pengumuman",
					"pin": "Halaman yang dipasang pin",
					"announcementDesc": "Pengumuman digunakan untuk membuat peringatan untuk acara yang tidak disediakan dari penyedia peringatan lainnya.",
					"pinDesc": "Halaman yang di-pin digunakan untuk menampilkan halaman-halaman penting yang sensitif terhadap waktu dan lokasi."
				},
				"errors": {
					"pleaseProvideEvent": "Harap berikan acara untuk pengumuman ini.",
					"pleaseProvideMessage": "Harap berikan pesan untuk pengumuman ini.",
					"pleaseProvideHeadline": "Harap berikan judul untuk pengumuman ini.",
					"pleaseProvideAreaDescription": "Harap berikan deskripsi area untuk pengumuman ini.",
					"effectiveWrong": "Tanggal efektif tidak boleh setelah tanggal kedaluwarsa",
					"pleaseSelectPage": "Silakan pilih halaman yang akan disematkan.",
					"pleaseSelectRegion": "Silakan pilih wilayah untuk peringatan ini."
				},
				"expiry": "Tanggal kadaluarsa",
				"sendNotification": "Kirim pemberitahuan push ke pengguna ini.",
				"updateMap": "Perbarui peta",
				"notify": "Memberitahu",
				"cap_create": "TOPI",
				"effective": "Tanggal berlaku",
				"severity": "Kerasnya",
				"pushes": "Dorong",
				"area": "Area peringatan",
				"cap_send": "Hasilkan peringatan CAP",
				"severityHelp": "Seberapa penting peringatan ini?",
				"pinPage": "Halaman yang akan ditampilkan.",
				"expiryHelp": "Tanggal peringatan ini akan dihapus.",
				"onset": "Tanggal mulai",
				"languageHelp": "Bahasa yang ditulis dalam tanda.",
				"polygon": "Tambahkan data poligon khusus di sini.",
				"alertQueued": "Lansiran diantrekan untuk dikirim.",
				"message": "Pesan yang dikirim dengan peringatan ini.",
				"drawRegion": "Gambar di sekitar area peringatan ini harus dikirim",
				"effectiveHelp": "Tanggal peringatan ini akan efektif dari.",
				"showPolyData": "Tampilkan data poligon",
				"announcementPage": "Halaman tersebut ditampilkan di bagian \"apa sekarang\".",
				"areaHelp": "Peringatan akan dikirim ke semua pengguna yang memantau lokasi di area yang dipilih.",
				"onsetHelp": "Tanggal bahaya akan terjadi."
			},
			"alertActive": "Lansiran aktif",
			"alert": "Waspada",
			"show": "Menunjukkan",
			"pin": "Pin",
			"issuedBy": "dikeluarkan oleh",
			"title": "Lansiran",
			"pinned": "Pinned",
			"testAlert": "Buat tanda uji",
			"expired": "Kadaluarsa",
			"createNewAlert": "Buat peringatan baru",
			"noAlerts": "Tidak ada lansiran",
			"effective": "Efektif",
			"inTheLast": "Terakhir",
			"allAlerts": "Semua peringatan",
			"specifySinglePolygon": "Beberapa poligon tidak didukung untuk peringatan. Tolong gambar satu poligon tunggal.",
			"testAlertDesc": "Peringatan tes ini akan dikirim ke semua pengguna yang memantau lokasi di Antartika."
		},
		"generalNotifications": {
			"customMapData": {
				"moreCoords": "Harap berikan lebih dari tiga koordinat",
				"firstAndLast": "Koordinat pertama dan terakhir harus sama",
				"notFound": "Tidak ditemukan data Poligon, harap tambahkan minimal tiga koordinat. yaitu [bujur, lintang], [bujur, lintang], [bujur, lintang]",
				"placeholder": "Klik pada peta di atas atau tambahkan minimal tiga koordinat di sini untuk membuat poligon. yaitu [bujur, lintang], [bujur, lintang], [bujur, lintang]",
				"fisrtFoundNotLast": "Koordinat pertama ditemukan dalam koordinat, hanya koordinat pertama dan terakhir yang harus sama"
			},
			"dropdown": {
				"update": "Memperbarui",
				"minor": "Minor",
				"rescue": "Menyelamatkan",
				"other": "Lain",
				"alert": "Waspada",
				"cancel": "Membatalkan",
				"draft": "Konsep",
				"possible": "Mungkin",
				"immediate": "Segera",
				"actual": "Sebenarnya",
				"public": "Publik",
				"private": "Pribadi",
				"likely": "Mungkin",
				"future": "Masa depan",
				"test": "Uji",
				"system": "Sistem",
				"error": "Kesalahan",
				"unlikely": "Tidak sepertinya",
				"safety": "Keamanan",
				"restricted": "Terbatas",
				"transport": "Mengangkut",
				"severe": "Parah",
				"past": "Lalu",
				"extreme": "Ekstrim",
				"security": "Keamanan",
				"fire": "Api",
				"moderate": "Moderat",
				"health": "Kesehatan",
				"expected": "Diharapkan",
				"observed": "Diamati",
				"unknown": "Tidak dikenal"
			},
			"severity": "Kerasnya",
			"status": "Status",
			"type": "Mengetik",
			"urgency": "Urgensi",
			"scope": "Cakupan",
			"certainty": "Kepastian",
			"category": "Kategori",
			"area_description": "Deskripsi daerah",
			"headline": "Judul",
			"event": "Peristiwa",
			"createNotification": "Buat Notifikasi",
			"title": "Pemberitahuan Umum"
		},
		"alertZones": {
			"type": "Mengetik",
			"area": "Daerah",
			"name": "Nama",
			"events": "Acara",
			"title": "Zona Peringatan",
			"blacklist": "Daftar hitam",
			"addAlertZone": "Tambahkan Alert Zone",
			"editAlertZone": "Edit Alert Zone",
			"whitelist": "Whitelist",
			"areaSelectModalTitle": "Gambar di sekitar area yang berlaku zona ini"
		},
		"feeds": {
			"tutorial": {
				"heading": "Menggunakan Feed",
				"steps": "<ol><li> Pastikan Jenis dan kategori Bencana yang dipilih diaktifkan untuk aplikasi ini. Ini akan menentukan feed dan acara mana yang dapat diaktifkan di bawah ini. </li><li> Pilih feed dan jenis acara yang ingin Anda sumbangkan untuk aplikasi ini. </li></ol>",
				"body": "Beberapa jenis peringatan dapat berasal dari beberapa sumber peringatan. Bagian ini memungkinkan Anda untuk mengaktifkan atau menonaktifkan feed dan jenis peristiwa tertentu dari masing-masing feed untuk aplikasi ini."
			},
			"delete": {
				"confirm": "Apakah kamu yakin?",
				"yes": "Ya, hapus",
				"events": "Semua acara yang diaktifkan akan dinonaktifkan untuk umpan ini."
			},
			"enable": "Memungkinkan",
			"feed": "Makan",
			"code": "Kode",
			"scope": "Cakupan",
			"type": "Mengetik",
			"events": "Acara",
			"disable": "Nonaktifkan",
			"configure": "Konfigurasikan",
			"title": "Feed",
			"languages": "Bahasa yang tersedia"
		}
	},
	"appCreate": {
		"createNewOrganization": "Buat organisasi baru",
		"emptyApp": "Aplikasi kosong",
		"title": "Buat aplikasi baru",
		"copyFrom": "Salin konten dari",
		"details": "Detail aplikasi"
	},
	"manageApps": {
		"title": "Kelola aplikasi",
		"yourNextApp": "Aplikasi Anda selanjutnya",
		"easilySetUpYourNextApp": "Buat aplikasi selanjutnya dengan mudah"
	},
	"dashboard": {
		"title": "Dashboard"
	},
	"manage": {
		"title": "Kelola"
	},
	"nav": {
		"items": {
			"appSetup": {
				"title": "Pengaturan Aplikasi",
				"description": "Konfigurasikan nama aplikasi, ikon, pelokalan teks, dan info App Store"
			},
			"content": {
				"title": "Konten",
				"description": "Edit konten dalam aplikasi"
			},
			"publish": {
				"description": "Publikasikan perubahan ke dev atau live build",
				"title": "Menerbitkan"
			},
			"stats": {
				"title": "Statistik",
				"description": "Lihat bagaimana kinerja aplikasi"
			},
			"userAgreement": {
				"title": "Perjanjian pengguna",
				"description": "Lihat dan tandatangani Perjanjian Program Aplikasi Universal"
			},
			"users": {
				"title": "Pengguna",
				"description": "Kelola semua pengguna"
			},
			"stories": {
				"title": "Cerita",
				"description": "Kelola cerita"
			},
			"levels": {
				"title": "Tingkat",
				"description": "Kelola tingkat kemajuan aplikasi"
			},
			"badges": {
				"title": "Lencana",
				"description": "Kelola lencana yang diberikan untuk menyelesaikan kegiatan dalam aplikasi"
			},
			"push": {
				"title": "Pemberitahuan push",
				"description": "Kelola dan kirim pemberitahuan push ke pengguna"
			},
			"sprint": {
				"title": "Papan status",
				"description": "Lihat status perkembangan sprint saat ini"
			},
			"bugs": {
				"description": "Laporkan masalah dengan editor atau pembuatan aplikasi",
				"title": "Pelaporan Bug"
			},
			"gdpcConsole": {
				"title": "Konsol NS",
				"description": "Lihat status National Society dan Dev Pool"
			},
			"hazards": {
				"title": "Bencana dan Peringatan",
				"description": "Prioritas, manajemen bencana, peringatan dan moderasi"
			},
			"decisionTree": {
				"description": "Lihat dan edit pohon keputusan untuk aplikasi",
				"title": "Pohon keputusan"
			},
			"admin": {
				"title": "Admin",
				"description": "Lihat statistik, edit pengguna dan detail organisasi"
			}
		},
		"logout": "Keluar",
		"navigate": "Menavigasi"
	},
	"publish": {
		"lastModified": "Terakhir diubah",
		"live": "Hidup",
		"noChanges": "Tidak ada perubahan",
		"endTime": "Akhir waktu",
		"startTime": "Waktu mulai",
		"history": "Sejarah",
		"user": "Pengguna",
		"time": "Waktu",
		"selectAll": "Pilih Semua",
		"comment": "Komentar",
		"test": "Uji",
		"publishing": "Penerbitan...",
		"objectType": "Jenis objek",
		"details": "Detail",
		"detail": "Detail",
		"auditTrail": "Jejak audit",
		"publishToDev": "Publikasikan ke dev",
		"publishLandmark": "Landmark Publish?",
		"success": "Terbitkan sukses.",
		"fail": "Publikasikan gagal.",
		"toApps": "Publikasikan ke aplikasi",
		"publishToLive": "Publikasikan untuk hidup",
		"progress1": "Perubahan yang Anda pilih sekarang sedang diterbitkan.",
		"progress2": "Ini mungkin membutuhkan waktu beberapa saat. Jangan keluar dari halaman ini.",
		"error": "Silakan pilih satu atau beberapa halaman untuk diterbitkan.",
		"publishType": "Publikasikan jenis",
		"progress3": "Sepertinya ini memakan waktu cukup lama. Anda dapat kembali lagi nanti.",
		"pleaseConfirm": "Silakan periksa kotak centang untuk mengonfirmasi bahwa Anda telah meninjau semua perubahan.",
		"publishConfirm": "Saya mengonfirmasi bahwa saya telah meninjau semua perubahan yang dipilih dan memiliki izin untuk mendorongnya secara langsung",
		"warning1": "Anda telah memilih halaman di bawah ini untuk dipublikasikan ke aplikasi iOS dan Android melalui pembaruan delta.",
		"title": "Menerbitkan",
		"warning2": "Harap teliti semua perubahan sebelum melanjutkan dan konfirmasikan pilihan Anda di bagian bawah halaman."
	},
	"publishHistory": {
		"previousPublishes": "Publikasi sebelumnya",
		"title": "Publikasikan riwayat"
	},
	"agreement": {
		"email": "Alamat email",
		"signed": "Tertanda",
		"sign": "Tanda",
		"signedBy": "Tertanda oleh",
		"notSigned": "Tidak ditandai",
		"date": "Tanggal",
		"status": "Status",
		"fullName": "Nama lengkap",
		"viewThe": "Lihat",
		"nationalSociety": "Masyarakat nasional",
		"details": "Detail perjanjian",
		"signAgreement": "Menandatangani perjanjian",
		"hazardsTitle": "Perjanjian Program Aplikasi Bahaya Universal",
		"daysRemaining": "__count__ hari tersisa",
		"title": "Perjanjian Program Aplikasi Universal",
		"daysRemaining_plural": "__count__ hari tersisa",
		"checkToAgree": "Harap centang kotak untuk mengonfirmasi bahwa Anda telah membaca dan menyetujui persyaratan.",
		"iAgree": "Saya menyetujui persyaratan yang diberikan dalam"
	},
	"roles": {
		"permissions": "Izin",
		"title": "Peran pengguna",
		"list": "Daftar peran"
	},
	"permissions": {
		"read": "Baca baca",
		"write": "Menulis",
		"none": "Tidak ada",
		"delete": "Tulis / Hapus",
		"edit": "Edit izin",
		"title": "Izin peran"
	},
	"apps": {
		"title": "Tetapkan aplikasi",
		"assign": "Menetapkan",
		"app": "Aplikasi",
		"assignNew": "Tetapkan aplikasi baru",
		"subtitle": "Tetapkan aplikasi ke pengguna"
	},
	"stories": {
		"content": "Konten",
		"subtitle": "Subtitle",
		"title": "Cerita",
		"created": "Dibuat",
		"editStory": "Edit cerita",
		"list": "Daftar kisah"
	},
	"badges": {
		"editBadge": "Edit lencana",
		"title": "Lencana",
		"list": "Daftar lencana"
	},
	"crossBorders": {
		"link": {
			"destination": "Tujuan",
			"placeholder": "Judul tujuan",
			"title": "Judul"
		},
		"contact": {
			"title": "Judul",
			"number": "Nomor telepon",
			"editItem": "Edit Kontak"
		},
		"phrase": {
			"phrase": "Frasa",
			"translation": "Terjemahan",
			"editItem": "Edit Frase"
		},
		"country": {
			"code": "Kode negara",
			"name": "Nama negara",
			"description": "Negara tujuan"
		},
		"contacts": "Kontak",
		"title": "Lintas Perbatasan",
		"links": "Tautan",
		"phrases": "Frase",
		"list": "Daftar tujuan",
		"editItem": "Edit Negara Tujuan",
		"translations": "Terjemahan Frasa"
	},
	"levels": {
		"details": "Detail level",
		"children": "Tingkat anak-anak",
		"title": "Tingkat",
		"list": "Daftar tingkat",
		"editLevel": "Sunting level"
	},
	"bugs": {
		"status": {
			"deleted": "Dihapus",
			"wontFix": "Tidak akan memperbaiki",
			"postponed": "Ditunda",
			"title": "Status",
			"open": "Buka",
			"duplicate": "Duplikat",
			"closed": "Tutup",
			"byDesign": "Dengan Desain",
			"notReproducible": "Tidak Dapat Diproduksi ulang",
			"fixed": "Tetap"
		},
		"version": "Versi",
		"title": "Bug",
		"build": "Membangun",
		"report": "Melaporkan",
		"noComments": "Tidak ada komentar",
		"noFiles": "Tidak ada data",
		"addComment": "Tambahkan komentar",
		"listPending": "Daftar laporan bug yang tertunda",
		"listClosed": "Daftar laporan bug yang ditutup",
		"listOpen": "Daftar laporan bug yang terbuka",
		"closeIssue": "Tutup masalah",
		"bugReport": "Laporan bug",
		"comments": "Komentar",
		"issueDetails": "Detail masalah",
		"platformDetails": "Detail platform",
		"loadingFiles": "Memuat file ...",
		"noReports": "Tidak ada laporan",
		"loadingDetails": "Memuat detail bug ...",
		"screenshots": "Tangkapan layar",
		"reopenIssue": "Buka kembali masalah",
		"actually": "Perilaku yang sebenarnya",
		"device": "Versi perangkat / OS",
		"steps": "Langkah-langkah untuk mereproduksi",
		"expected": "Perilaku yang diharapkan",
		"reportBug": "Laporkan bug"
	},
	"gdpcConsole": {
		"nationalSocieties": {
			"contact": "Kontak",
			"goLive": "Hiduplah",
			"pool": "Kolam",
			"totalDownloads": "Total Unduhan",
			"title": "Ikhtisar",
			"countries": "Negara-negara",
			"details": "Detail Masyarakat",
			"edit": "Edit Society",
			"locale": "Lokal",
			"stage": "Tahap",
			"downloads": "Unduh",
			"averageRating": "Penilaian rata-rata"
		},
		"devPools": {
			"stages": "Tahapan",
			"completed": "Lengkap",
			"title": "Status Dev Pool",
			"noApps": "Tidak ada aplikasi di kolam dev ini",
			"expected": "Diharapkan",
			"language": "Bahasa",
			"responsibility": "Tanggung jawab",
			"requiredBy": "Diperlukan oleh",
			"estimatedDate": "Perkiraan tanggal aplikasi saat ini",
			"editPool": "Edit dev pool"
		}
	},
	"organization": {
		"title": "Organisasi",
		"details": "Detail organisasi",
		"noLanguages": "Tidak ada bahasa yang ditugaskan",
		"confirmLanguageAssignment": "Anda yakin ingin menetapkan bahasa ini? Tugas bahasa tidak dapat dihapus."
	},
	"mailingList": {
		"dateFrom": "Dari",
		"export": "Ekspor",
		"dateTo": "Untuk",
		"title": "Daftar surat",
		"list": "Daftar yang tersedia"
	},
	"moderation": {
		"image": {
			"location": "Lokasi",
			"title": "Citra moderasi"
		},
		"title": "Moderasi"
	},
	"posts": {
		"title": "Kiriman"
	},
	"achievements": {
		"name": "Nama",
		"badge": "Lencana",
		"completion": "Penyelesaian",
		"image": "Gambar",
		"edit": "Edit Prestasi",
		"title": "Prestasi"
	},
	"triggers": {
		"subclasses": {
			"RaisedTrigger": {
				"amount": "Jumlah",
				"singular": "Jumlah dibangkitkan"
			}
		},
		"plural": "Pemicu"
	},
	"mind-moderation": {
		"emoodji": {
			"singular": "Emoodji"
		},
		"empty": "Tidak ada Emoodjis sampai sedang",
		"title": "Moderasi Emoodji"
	},
	"whatnow": {
		"attribution": {
			"url": "URL Pengaitan",
			"message": "Pesan Pengaitan",
			"image": "Gambar Pengaitan",
			"attribution": "Atribusi",
			"socName": "Atribusi Nama Masyarakat",
			"imageHelp": "Unggahan Gambar / Ikon.",
			"messageHelp": "Pesan yang sesuai dengan atribusi.",
			"socNameHelp": "Nama Perhimpunan yang akan dikaitkan dengan apa yang terjadi.",
			"urlHelp": "URL yang tertaut ke informasi lebih lanjut tentang Penyedia."
		},
		"edit": {
			"moreinfoHelp": {
				"placeholder": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedap eiusmod incididunt ut labore et dolore magna aliqua. Untuk itu, Anda dapat meminimalkan penggunaan, dan Anda harus melakukan tindakan yang lebih baik dari itu."
			},
			"missing": "Konten hilang",
			"languages": "Bahasa",
			"warning": "PERINGATAN",
			"watch": "Menonton",
			"recover": "Memulihkan",
			"immediate": "Segera",
			"before": "Sebelum",
			"forecast": "Ramalan cuaca",
			"recoverHelp": "Setelah",
			"during": "Selama",
			"immediateHelp": "Menanggapi",
			"warningHelp": "Bersiaplah untuk menanggapi",
			"watchHelp": "Bersiaplah untuk menanggapi",
			"beforeHelp": "Cara mempersiapkan sebelum acara itu terjadi.",
			"empty": "Silakan tambahkan bahasa untuk membuat Apa Sekarang",
			"duringHelp": "Apa yang harus dilakukan saat acara berlangsung.",
			"midtermHelp": "Mengurangi risiko (tindakan jangka panjang)",
			"translations": "Terjemahan",
			"title": "Judul",
			"event": "Bahaya",
			"after": "Setelah",
			"selectLang": "Pilih bahasa",
			"new": "Tambahkan bahasa baru",
			"description": "Deskripsi",
			"moreinfohelp": "URL untuk informasi lebih lanjut tentang Apa sekarang.",
			"descriptionHelp": "Deskripsi untuk Apa sekarang.",
			"editWhatnow": "Sunting Apa Sekarang",
			"addALang": "Tambahkan bahasa untuk memulai",
			"midterm": "Prediksi jangka menengah",
			"titleHelp": "Judul untuk Apa sekarang.",
			"forecastHelp": "Menilai dan merencanakan (Tindakan jangka pendek)",
			"afterHelp": "Apa yang harus dilakukan setelah peristiwa itu terjadi.",
			"eventhelp": "Jenis acara untuk Apa sekarang.",
			"moreinfo": "URL informasi"
		},
		"after": "Setelah",
		"whatnow": "Apa sekarang?",
		"before": "Sebelum",
		"during": "Selama",
		"title": "Judul",
		"eventType": "Bahaya",
		"description": "Deskripsi",
		"content-not-set": "Konten tidak disetel",
		"selectSoc": "Pilih sebuah masyarakat",
		"empty": "Tidak ada entri apa sekarang, klik tambahkan untuk membuatnya"
	},
	"arpro": {
		"codeGroup": {
			"description": "Deskripsi",
			"codes": "Kode",
			"codeGroup": "Kelompok Kode",
			"editItem": "Edit Kode Grup",
			"addCodes": "Masukkan kode di sini",
			"name": "Nama"
		},
		"colours": {
			"name": "Nama",
			"description": "Deskripsi",
			"colours": "Warna"
		},
		"search": {
			"tensileStrength": "Daya tarik",
			"search": "Pencarian Produk",
			"burningRate": "Tingkat Kebakaran",
			"compressionStrength25": "Kekuatan Kompresi 25",
			"mouldedDensity": "Molded Density",
			"compressionStrength50": "Kekuatan Kompresi 50",
			"tensileElongation": "Elongasi Tarik",
			"equivalentYoungsModulus": "Modulus Youngs yang Setara",
			"resilience75DynamicCompression": "Ketahanan 75 Dynamic Compression",
			"compressionStrength75": "Kekuatan Kompresi 75"
		},
		"product": {
			"properties": {
				"packaging": "Pengemasan",
				"weight": "Berat badan (mg)",
				"food": "Makanan disetujui",
				"size": "Ukuran (mm)",
				"bulk": "Bulk density (g / l)",
				"moulded": "Kerapatan cetakan (g / l)",
				"bag": "Tas",
				"bulkLabel": "Jumlah besar"
			},
			"product": "Produk",
			"editItem": "Edit Produk",
			"colour": "Warna",
			"name": "Nama",
			"noneLinked": "Tidak ada kode akses",
			"notes": "Catatan",
			"access": "Kode Akses",
			"rawDataError": "Data Raw JSON - Data JSON tidak valid",
			"moulded": "Molded Density Range (g / l)",
			"physicalProps": "Properti fisik",
			"grade": "Kelas",
			"specifications": "Spesifikasi",
			"rawData": "Data JSON mentah",
			"linked": "Memiliki kode akses"
		},
		"arpro": "arpro",
		"noitems": "Tidak ada item untuk ditampilkan, klik tambahkan di atas."
	},
	"r4b": {
		"level": {
			"green": "hijau",
			"colour": "Warna",
			"red": "Merah",
			"blue": "Biru",
			"title": "Tingkat",
			"levels": "Tingkat",
			"alpha": "Alfa",
			"selectBadge": "Silakan pilih lencana"
		},
		"modules": {
			"blockContent": {
				"logic": {
					"title": "Bagian Visibility Logic"
				},
				"slider": {
					"startLabel": "Mulai Label",
					"interval": "Selang",
					"endLabel": "Label Akhir"
				},
				"question": {
					"title": "Pertanyaan dan jawaban",
					"textHelp": "Masukkan pertanyaan",
					"addAnotherResponse": "Tambahkan tanggapan lain",
					"answerHelp": "Masukkan jawaban",
					"addResponse": "Tambahkan tanggapan",
					"responseHelp": "Masukkan tanggapan",
					"selectionHelp": "Pilih bagian untuk melanjutkan ke"
				},
				"quote": {
					"citation": "Masukkan kutipan di sini",
					"title": "Kutipan"
				},
				"checkbox": {
					"title": "Kotak centang"
				},
				"textInput": {
					"help": "Masukkan label input di sini",
					"multi": "Teks multi-baris?",
					"title": "Input Teks"
				},
				"link": {
					"title": "Tautan"
				},
				"help": {
					"title": "Objek Bantuan"
				},
				"task": {
					"title": "Objek Tugas",
					"help": "Masukkan tujuan tugas"
				},
				"text": {
					"title": "Objek Teks"
				},
				"radio": {
					"title": "Tombol radio"
				},
				"clickToEdit": "Klik untuk mengedit",
				"videoLink": "Tautkan ke video eksternal",
				"section": "Bagian",
				"identifier": "Identifier",
				"addIdentifier": "Tambahkan pengenal",
				"break": "Istirahat halaman",
				"addSection": "Seret item ke sini untuk membuat bagian baru",
				"enterText": "Masukkan teks di sini",
				"enterTitle": "Masukkan judul",
				"inputLabel": "Label Masukan Teks"
			},
			"section": {
				"title": "BAGIAN"
			},
			"blockTypes": {
				"check": "MEMERIKSA",
				"image": "GAMBAR",
				"radio": "RADIO",
				"link": "LINK",
				"media": "BLOK MEDIA",
				"task": "TUGAS",
				"help": "MEMBANTU",
				"slider": "SLIDER",
				"qna": "Q & A",
				"textInput": "TEXT ENTRY",
				"video": "VIDEO",
				"userInput": "BLOK PENGGUNA INPUT",
				"quote": "KUTIPAN",
				"text": "TEKS"
			},
			"version": "Versi",
			"saved": "Semua perubahan disimpan",
			"module": "Modul",
			"person": "orang",
			"people": "orang-orang",
			"title": "Modul",
			"mins": "menit",
			"unsaved": "Ada perubahan yang belum disimpan",
			"level": "Tingkat",
			"visibility": "Visibilitas",
			"levels": "Tingkat"
		},
		"assessments": {
			"pre": "Sebelum penilaian",
			"assessment": "Penilaian"
		},
		"languages": "Bahasa",
		"person": "ORANG",
		"title": "ATLAS",
		"mediaBlocks": "BLOK MEDIA",
		"minutes": "MINS",
		"sections": "BAGIAN",
		"empty": "Tidak ada modul atau penilaian di sini, tambahkan satu ke tingkat ini untuk memulai.",
		"people": "ORANG-ORANG",
		"questions": "Pertanyaan",
		"contentBlocks": "Blok Konten",
		"admin": "Dasbor Admin",
		"languageVars": "Variasi Bahasa",
		"inputBlocks": "BLOK PENGGUNA INPUT"
	},
	"rspb": {
		"factFile": {
			"addFeatures": "Masukkan fitur di sini",
			"show": "Menunjukkan",
			"seen": "Terlihat",
			"funFacts": "Fakta menyenangkan",
			"commonName": "Nama yang umum",
			"description": "Deskripsi",
			"activities": "Kegiatan",
			"scientificName": "Nama ilmiah",
			"features": "fitur",
			"images": "Gambar",
			"name": "Nama",
			"addImages": "Pilih gambar",
			"addTags": "Masukkan tag di sini",
			"start": "Mulai",
			"factFile": "File Fakta",
			"editItem": "Edit File Fakta",
			"tags": "Tag",
			"addActivities": "Masukkan aktivitas di sini",
			"addFact": "Masukkan fakta di sini"
		},
		"planItem": {
			"difficulty": {
				"difficulty": "Pilih kesulitannya",
				"hard": "Keras",
				"medium": "Medium",
				"easy": "Mudah"
			},
			"garden": {
				"public": "Publik",
				"medium": "Medium",
				"large": "Besar",
				"small": "Kecil",
				"balconyroof": "Balkon / Atap",
				"selectType": "Pilih jenis taman"
			},
			"duration": "Durasi (detik)",
			"activityCode": "Kode aktivitas",
			"from": "tersedia dari",
			"type": "Mengetik",
			"description": "Deskripsi",
			"image": "Gambar",
			"dates": "tanggal",
			"title": "Judul",
			"solo": "Untuk keluarga?",
			"leastImportant": "Paling tidak penting",
			"months": "Bulan",
			"region": "Wilayah",
			"mostImportant": "Yang terpenting",
			"source": "Sumber",
			"indefinite": "Tak terbatas?",
			"identifier": "Identifier",
			"tags": "Tag",
			"visible": "Terlihat dari",
			"weighting": "Penentuan bobot paket",
			"to": "Tanggal akhir",
			"planItems": "Item Paket",
			"pageHelp": "Ini adalah halaman yang akan ditautkan oleh item paket Anda",
			"weightingHelp": "Prioritas tempat item akan muncul di aplikasi",
			"regionHelp": "Klik untuk memilih wilayah untuk item paket ini"
		},
		"rspb": "RSPB",
		"noitems": "Tidak ada item untuk ditampilkan, klik tambahkan di atas."
	}
}