var Template = require('./template')

module.exports = Backbone.Collection.extend({
	model: Template,

	url: function() {
		return App.apiRoot + 'apps/' + this.appId + '/templates'
	},

	initialize: function(models, options) {
		this.appId = options.appId
	}
})
