var HazardsSectionView = require('./hazards-section-view'),
	DisasterList = require('./disaster-list'),
	StormQL = require('models/stormql'),
	StandaloneStormObject = require('editor/standalone-storm-object'),
	Maps = require('lib/maps'),
	RegionDrawView = require('./region-draw-view')

module.exports = HazardsSectionView.extend({
	template: require('./alert-zones-edit-view-template'),

	events: {
		'click .map-button': 'mapClick',
		'click .save-button': 'save'
	},

	initialize: function(options) {
		this.app = options.app
		this.id = options.id

		// Kick out if we're not looking at a Hazards app.
		if (!this.app.isHazardsApp() && !App.developerMode) {
			return setTimeout(function() {
				App.router.navigate('/', {trigger: true})
			})
		}

		this.views = {}

		var requests = []

		// Fetch full disasters list.
		this.disasterList = new DisasterList()
		var disasterListFetch = this.disasterList.fetch()
		requests.push(disasterListFetch)

		// Fetch disasters assigned to this app.
		this.activeDisasterEvents = new StormQL(null, {app: this.app})
		var activeDisasterEventsFetch = this.activeDisasterEvents.fetch({data: {class: 'StormQLEvent'}})
		requests.push(activeDisasterEventsFetch)

		// Load/create model
		if (this.id !== 'new') {
			this.model = StandaloneStormObject.fromProperties({id: options.id})
			requests.push(this.model.fetch())
		} else {
			this.model = StandaloneStormObject.fromClassName('AlertZone')
		}

		// Update map preview on change
		this.listenTo(this.model, 'change:region..*', this.renderRegionPreview)

		// Process responses and render page again once all data loaded.
		Promise.all(requests)
			.then(function() {
				var eventIds = this.activeDisasterEvents.pluck('eventId')
				this.filteredCategories = this.disasterList.toJSON().filter(function(category) {
					category.events = category.events.filter(function(event) {
						return eventIds.indexOf(event.id) > -1
					})
					return category.events.length
				})

				var region = this.model.get('region')
				if (region) {
					this.model.set('region', JSON.parse(region), {silent: true})
				}

				this.ready()
			}.bind(this))
	},

	getPageTitle: function() {
		return $.t('hazards.alertZones.title')
	},

	getRenderData: function() {
		return {
			appId: this.app.id,
			categories: this.filteredCategories,
			isNew: this.id === 'new'
		}
	},

	afterRender: function() {
		var permission = App.acl.getPermission('Content')

		this.$('.save-button').hide()

		switch (permission) {
			case 'Delete':
			case 'Write':
				this.$('.save-button').show()
		}
	},

	ready: function() {
		// Re-render now that required data has loaded
		this.render()

		this.$('#zone-name').val(this.model.get('name'))

		var selectedEvents = []

		if (this.model.get('blacklist').length) {
			this.$('#zone-type').val('blacklist')
			selectedEvents = this.model.get('blacklist')
		}

		if (this.model.get('whitelist').length) {
			this.$('#zone-type').val('whitelist')
			selectedEvents = this.model.get('whitelist')
		}

		$('input[name="event"]')
			.filter(function(i, el) {
				return selectedEvents.indexOf(Number(el.value)) > -1
			})
			.attr('checked', true)

		Maps.init(this.initGoogleMap.bind(this))

		App.stopLoad()
	},

	save: function() {
		App.startLoad()

		this.model.once('sync', function() {
			this.model.requestUnlock()
			App.router.navigate('/apps/' + this.app.id + '/alert-zones', {trigger: true})
		}, this)

		this.model.set('name', this.$('#zone-name').val())
		this.model.set('blacklist', [])
		this.model.set('whitelist', [])

		var listType = this.$('#zone-type').val()
		var checked = $('input[name="event"]:checked').map(function(i, el) {
			return Number(el.value)
		}).toArray()
		this.model.set(listType, checked)

		this.model.set('region', JSON.stringify(this.model.get('region')))

		this.model.requestLock(function() {
			this.model.save(null, {appId: this.app.id})
		}.bind(this))
	},

	initGoogleMap: function() {
		// Initialize Google map
		this.map = new google.maps.Map(this.$('.alert-preview-map')[0], {
			disableDefaultUI: true,
			center: new google.maps.LatLng(40, -93),
			zoom: 5
		})

		this.renderRegionPreview()

		App.stopLoad()
	},

	// Show region draw modal on map click.
	mapClick: function() {
		if (this.views.regionDrawView) {
			this.views.regionDrawView.destroy()
		}

		this.views.regionDrawView = new RegionDrawView({
			model: this.model,
			modalTitle: $.t('hazards.alertZones.areaSelectModalTitle')
		})

		$('body').append(this.views.regionDrawView.render().el)
		this.views.regionDrawView.show()
	},

	// Draw polygons on map preview and fit bounds.
	renderRegionPreview: function() {
		// Clear previous polygons.
		if (this.overlay) {
			this.overlay.setMap(null)
		}

		// Draw new polygons
		var bounds = new google.maps.LatLngBounds(),
			region = this.model.get('region'),
			paths = []

		if (!region) {
			return
		}

		region.coordinates.forEach(function(polygon) {
			var latLngs = polygon.map(function(path) {
				return path.map(function(coord) {
					var latLng = new google.maps.LatLng(coord[1], coord[0])
					bounds.extend(latLng)
					return latLng
				})
			})

			paths = paths.concat(latLngs)
		})

		// Construct the polygon.
		this.overlay = new google.maps.Polygon({
			paths: paths,
			strokeColor: '#F93A2F',
			strokeOpacity: 1,
			strokeWeight: 2,
			fillColor: '#F93A2F',
			fillOpacity: 0.28,
			map: this.map
		})

		this.map.fitBounds(bounds)
	}
})
