var StormView = require('lib/storm-view'),
	/** @type {StormGlobals} */
	globals = require('globals')

/**
 * Exports {@link ManageSectionView}.
 * @module
 */
module.exports = StormView.extend(/** @lends ManageSectionView.prototype */{
	/**
	 * @constructs ManageSectionView
	 * @extends StormView
	 * @override
	 */
	initialize: function() {
	},

	/** @override */
	getSectionTitle: function() {
		return $.t('manage.title')
	},

	/** @override */
	getPages: function() {
		var acl = globals.getAcl()

		return [
			{
				name: $.t('organization.title'),
				url: 'organization',
				isVisible: function() {
					return acl.hasAccess('Admin', 'Organization')
				},
				views: [
					require('admin/organization-view')
				]
			},
			{
				name: $.t('users.title'),
				url: 'users',
				isVisible: function() {
					var usersList     = acl.hasAccess('Users', 'Users list'),
						appAssignment = acl.hasAccess('Users', 'App assignment')

					return usersList || appAssignment
				},
				views: [
					require('users/users-view'),
					require('users/user-apps-view')
				]
			},
			{
				name: $.t('mind-moderation.title'),
				url: 'mind-moderation',
				isVisible: function() {
					return App.apiRoot.indexOf('mind') > -1
				},
				views: [
					require('mind-moderation/moderation-view')
				]
			}
		]
	}
})
