const regex = /(TERM\(\'(\d\..+\d)\.(.+)\'([-+><=]{1,2})(.+)\))|((AND|OR)\(\'(\d\..+\d)\.(.+)\'([-+><=]{1,2})(.+)(\,)\'(\d\..+\d)\.(.+)\'([-+><=])(.+)\))/m
// This REGEX matches:
// TERM('3.test.1.how_often'>3)
// AND('3.test.1.how_often'>3,'3.test.1.another'=1)
// OR('3.test.1.how_often'>3,'3.test.1.another'=1)

module.exports = Backbone.View.extend({
	template: require('./section-logic-view-template'),

	/** @override */
	events: {
		'input .logic': 'updateLogic'
	},

	initialize: function(options) {
		this.appId = options.appId
		this.selectorId = options.selectorId
		this.model = options.model
	},

	getRenderData: function() {
		return {
			model: this.model.toJSON(),
			selectorId: this.selectorId
		}
	},

	updateLogic: function(e) {
		var value = $(e.currentTarget).val()
		// REMOVED Validation till we have a defined regex
		// if (this.validateLogic(value)) {
			// $(e.currentTarget).parent().removeClass('has-error')
		this.model.set('logic', value)
		this.model.trigger('change')
		// } else {
		// 	$(e.currentTarget).parent().addClass('has-error')
		// }
	},

	validateLogic: function(str) {
		var m

		if ((m = regex.exec(str)) !== null) {
			//	The result can be accessed through the `m`-variable.
			// m.forEach((match, groupIndex) => {
			// 	console.log(`Found match, group ${groupIndex}: ${match}`)
			// })
			//
			m.forEach(function(match, groupIndex) {
				console.log(match, groupIndex)
				// console.log("Found match, group ${groupIndex}: ${match}")
			})
			return true
		}
		return false
	}
})
