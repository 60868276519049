{{partial "bugs/bugs-nav"}}

<div class="title-bar">
	<div class="header">
		<span>{{t "bugs.title"}}</span>
		<a href="/bugs/new"><button class="button">{{t "bugs.report"}}</button></a>
	</div>
</div>

<div class="container pad-top bugs">
	<div class="row">
		<div class="span12 title-header">{{t "bugs.listOpen"}}</div>
	</div>
	<div class="row">
		<div class="span12">

			<div class="open-view"></div>

		</div>
	</div>

	<div class="row">
		<div class="span12 title-header">{{t "bugs.listPending"}}</div>
	</div>
	<div class="row">
		<div class="span12">

			<div class="pending-view"></div>

		</div>
	</div>

	<div class="row">
		<div class="span12 title-header">{{t "bugs.listClosed"}}</div>
	</div>
	<div class="row">
		<div class="span12">

			<div class="closed-view"></div>

		</div>
	</div>
</div>
