var utils    = require('./utils'),
	pageTags = require('editor/page-tags')

// Custom partial handler. Inject another template
Handlebars.registerHelper('partial', function(name) {
	var template = require(name)

	return new Handlebars.SafeString(template(this))
})

// Check property's existence
Handlebars.registerHelper('ifExists', function(key, options) {
	if (key !== undefined) {
		return options.fn(this)
	}

	return options.inverse(this)
})

// Get the remote URL for an Image or array of Images.
Handlebars.registerHelper('getPreviewUrl', utils.getImagePreviewUrl)

// Get the remote URL for a bundle resource.
Handlebars.registerHelper('remoteUrl', utils.getRemoteUrl)

Handlebars.registerHelper('getDownloadUrl', function(value) {
	if (typeof value !== 'string') {
		console.warn('Cannot generate download URL')
		return ''
	}

	value = (value || '').replace(/_x\d\.?\d{0,2}\.(\w{3,4})$/, '_orig.$1')
	return utils.getImagePreviewUrl(value)
})

// Get a preview URL for native images
Handlebars.registerHelper('getNativeImage', utils.getNativeImagePreviewUrl)

// Conditional if an object is a page
Handlebars.registerHelper('ifIsPage', function(options) {
	var className = this.class,
		isPage    = className === 'ListPage' ||
			className === 'QuizPage' ||
			className === 'NativePage' ||
			className === 'GridPage'

	if (isPage) {
		return options.fn(this)
	}

	return options.inverse(this)
})

Handlebars.registerHelper('ifNotImageQuizItem', function(options) {
	if (this.class !== 'ImageQuizItem') {
		return options.fn(this)
	}
})

// Get localised text content based on the current editor language.
Handlebars.registerHelper('getLocaleText', utils.getViewLocaleText)

// Get localised text content, with newlines replaced with <br> tags.
Handlebars.registerHelper('getLocaleTextHTML', utils.getViewLocaleTextHTML)

// Get localised text content based on the CMS language.
Handlebars.registerHelper('getBrowserLocaleText', utils.getBrowserLocaleText)

Handlebars.registerHelper('getTextForLang', utils.getTextForLang)

// If a == b
Handlebars.registerHelper('ifEqual', function(a, b, options) {
	if (a === b) {
		return options.fn(this)
	}
	return options.inverse(this)
})

// If a != b
Handlebars.registerHelper('ifNotEqual', function(a, b, options) {
	if (a !== b) {
		return options.fn(this)
	}
	return options.inverse(this)
})

// If a or b
Handlebars.registerHelper('ifOr', function(a, b, options) {
	if (a || b) {
		return options.fn(this)
	}
	return options.inverse(this)
})

// If a === b || a === c
Handlebars.registerHelper('ifBorC', function(a, b, c, options) {
	if (a === b || a === c) {
		return options.fn(this)
	}
	return options.inverse(this)
})

Handlebars.registerHelper('getPageIcon', function(value) {
	switch (value) {
		case 'ListPage':
			return '<i class="page-icon icon-file icon-large"></i>'
		case 'TabbedPageCollection':
			return '<i class="page-icon icon-folder-close icon-large"></i>'
		case 'QuizPage':
			return '<i class="page-icon icon-check icon-large"></i>'
		case 'NativePage':
			return '<i class="page-icon icon-briefcase icon-large"></i>'
		case 'GridPage':
			return '<i class="page-icon icon-th-large icon-large"></i>'
	}
})

Handlebars.registerHelper('add', function(a, b) {
	return a + b
})

Handlebars.registerHelper('secondsToMinutes', function(val) {
	return val / 60000
})

Handlebars.registerHelper('secondsToMinutes1dp', function(seconds) {
	return Math.round((seconds / 60) * 10) / 10
})

Handlebars.registerHelper('divide1000', function(val) {
	return val / 1000
})

Handlebars.registerHelper('setIndex', function(index) {
	this.index = index
})

Handlebars.registerHelper('collectionEach', function(collection, options) {
	var ret = ''

	collection.each(function(item, i) {
		ret += options.fn(item.toJSON(), {data: {index: i}})
	})

	return ret
})

Handlebars.registerHelper('loop', function(iterations, data, options) {
	var ret = ''

	for (var i = 0; i < iterations; i++) {
		ret += options.fn(this, {data: {index: i, data: data}})
	}

	return ret
})

Handlebars.registerHelper('times', function(n, options) {
	var ret = ''
	for (var i = 0; i < n; ++i) {
		ret += options.fn(i)
	}
	return ret
})

Handlebars.registerHelper('getRoleById', function(id) {
	var role = Handlebars.helpers.getRoleById.roleList.get(id)

	if (role) {
		return role.get('name')
	}

	return '-'
})

Handlebars.helpers.getRoleById.roleList = new Backbone.Collection()

Handlebars.registerHelper('formatTime', function(timestamp) {
	if (!timestamp) {
		return '-'
	}

	var diff = Date.now() / 1000 - timestamp

	if (diff < 60) {
		return 'Now'
	}

	if ((diff /= 60) < 60) {
		diff = parseInt(diff, 10)
		return diff + ' minute' + ((diff !== 1) ? 's' : '') + ' ago'
	}

	if ((diff /= 60) < 24) {
		diff = parseInt(diff, 10)
		return diff + ' hour' + ((diff !== 1) ? 's' : '') + ' ago'
	}

	var date = new Date(timestamp * 1000)
	return date.toLocaleDateString()
})

Handlebars.registerHelper('formatDate', function(timestamp) {
	if (!timestamp) {
		return '-'
	}

	var date = new Date(timestamp * 1000)
	return date.toLocaleDateString()
})

Handlebars.registerHelper('formatDateTime', function(timestamp) {
	if (!timestamp) {
		return '-'
	}

	// Not *1000 as this is being used for alerts, which have ISO timestamps.
	var date = new Date(timestamp)
	return date.toLocaleTimeString() + ' ' + date.toDateString()
})

Handlebars.registerHelper('firstChar', function(string) {
	if (string) {
		return string.charAt(0)
	}

	return '&nbsp;'
})

// Transform a string to make it safe to use in a class name
Handlebars.registerHelper('className', function(string) {
	if (string) {
		return string.toLowerCase().replace(/\s/g, '-')
	}

	return ''
})

/**
 * Handlebars helper to return the page list class name to display a page tag
 * with the specified name.
 */
Handlebars.registerHelper('tagClassName', function(tag) {
	var tags  = pageTags.get(App.system.id)

	for (var i = 0; i < tags.length; i++) {
		if (tags[i].name === tag) {
			return 'tag-' + (i + 1) // Return as soon as the object is found
		}
	}
	return 'tag-0'
})

/**
 * Handlebars helper to return the page list class name to display a page tag
 * with the specified name.
 */
Handlebars.registerHelper('getTagColor', function(tag) {
	var tags  = pageTags.get()

	for (var i = 0; i < tags.length; i++) {
		if (tags[i].name === tag) {
			return tags[i].colour // Return as soon as the object is found
		}
	}
	return 'tag-0'
})

Handlebars.registerHelper('ifClass', function(name, options) {
	if (this.class === name) {
		return options.fn(this)
	}

	return options.inverse(this)
})

Handlebars.registerHelper('timeToDays', function(time) {
	var days

	if (time < 1) {
		days = 0
	} else {
		days = Math.ceil(time / 60 / 60 / 24)
	}

	return $.t('agreement.daysRemaining', {count: days})
})

Handlebars.registerHelper('key_value', function(obj, keyName, valName, opts) {
	var buffer = '',
		key

	for (key in obj) {
		if (obj.hasOwnProperty(key)) {
			var data = {}

			data[keyName] = key
			data[valName] = obj[key]
			buffer += opts.fn(data)
		}
	}

	return buffer
})

Handlebars.registerHelper('makeClassName', function(string) {
	if (string) {
		return string.replace(/\s/g, '-').toLowerCase()
	}

	return ''
})

Handlebars.registerHelper('t', function(key) {
	var result = $.t(key)

	return new Handlebars.SafeString(result)
})

Handlebars.registerHelper('getPublishStatus', function(status) {
	switch (status) {
		case -1:
			return 'Unknown'
		case 0:
			return 'Waiting'
		case 1:
			return 'Processing'
		case 2:
			return 'Published'
		case 3:
			return 'Uploaded'
	}
})

Handlebars.registerHelper('addOne', function(num) {
	return num + 1
})

Handlebars.registerHelper('getPushStatus', function(status) {
	switch (status) {
		case 0:
			return 'Deactivated'
		case 1:
			return 'Pending Approval'
		case 2:
			return 'Scheduled'
		case 3:
			return 'Sending'
		case 4:
			return 'Sent'
	}
})

Handlebars.registerHelper('localisePushType', function(type) {
	var key    = 'push.types.' + type,
		result = $.t(key)

	// Return localisation, if present
	if (key !== result) {
		return result
	}

	// Key not present, return original value
	return type
})

Handlebars.registerHelper('localisePushCategory', function(type) {
	var key    = 'push.categories.' + type,
		result = $.t(key)

	// Return localisation, if present
	if (key !== result) {
		return result
	}

	// Key not present, return original value
	return type
})

Handlebars.registerHelper('formatDateUTC', function(timestamp) {
	if (!timestamp) {
		return '-'
	}

	var date = new Date(timestamp * 1000)
	return date.toUTCString()
})

Handlebars.registerHelper('getLocalDateTime', function(timestamp) {
	if (!timestamp) {
		return '-'
	}
	var date = utils.getLocalDate(timestamp)
	var options = {localeMatcher: 'best fit',
	timeZoneName: 'short'}
	return date.toLocaleString([], options)
})

Handlebars.registerHelper('getPushPriority', function(priority) {
	switch (priority) {
		case 1:
		case 2:
			return 'High'
		case 3:
			return 'Low'
	}
})

Handlebars.registerHelper('getSocietyDescriptionById', function(id) {
	var society = App.societiesList.get(id)

	if (society) {
		return society.get('description')
	}

	return '-'
})

Handlebars.registerHelper('ifDateIsInPast', function(timestamp, options) {
	var date

	if (typeof timestamp === 'number') {
		date = new Date(timestamp * 1000)
	} else {
		date = new Date(timestamp)
	}

	if (Date.now() - date.getTime() > 0) {
		return options.fn(this)
	}

	return options.inverse(this)
})

Handlebars.registerHelper('starify', function(count) {
	var width = (count / 5) * 100

	var emptyStars = '<div class="star-container">',
		fullStars  = '<div class="star-container" style="width:' + width + '%">'

	for (var i = 0; i < 5; i++) {
		fullStars += '<i class="icon-star"></i>'
		emptyStars += '<i class="icon-star-empty"></i>'
	}

	fullStars += '</div>'
	emptyStars += '</div>'

	return '<div class="stars-container" title="' + count + '/5">' +
		fullStars + emptyStars
})

Handlebars.registerHelper('formatNumber', function(count) {
	if (!count) {
		return 0
	}

	return count.toLocaleString()
})

Handlebars.registerHelper('ifIsImage', function(filename, options) {
	if (filename.match(/(?:\.png|\.jpg|\.jpeg)$/) !== null) {
		return options.fn(this)
	}

	return options.inverse(this)
})

Handlebars.registerHelper('toUpperCase', function(string) {
	return string.toUpperCase()
})

Handlebars.registerHelper('toLowerCase', function(string) {
	return string.toLowerCase()
})

Handlebars.registerHelper('newlineToBr', function(string) {
	return string.replace(/\n/g, '<br>')
})

Handlebars.registerHelper('stringify', function(obj) {
	return JSON.stringify(obj, null, 2)
})

Handlebars.registerHelper('gravatar', function(email, options) {
	if (!email) {
		return ''
	}

	var size = options.hash.size || 80,
		hash = md5(email.toLowerCase())

	return 'https://www.gravatar.com/avatar/' + hash + '?s=' + size
})

Handlebars.registerHelper('link', function(slug) {
	return '/apps/' + Storm.app.id + '/' + slug
})

Handlebars.registerHelper('timestampToUTCString', function(timestamp) {
	// Construct date with ms since epoch
	return new Date(timestamp * 1000).toUTCString()
})

Handlebars.registerHelper('oneOf', function() {
	if (!arguments.length) {
		throw new Error('At least one argument must be provided')
	}

	for (var i = 0; i < arguments.length; i++) {
		if (arguments[i]) {
			return arguments[i]
		}
	}

	return arguments[arguments.length - 1]
})

Handlebars.registerHelper("log", function(something) {
	console.log(something)
})

Handlebars.registerHelper('selectLang', function(lang) {
	if (lang === App.clientLang) {
		return 'selected'
	}
})

Handlebars.registerHelper("localeName", function(name, currentLang, appLanguages) {
	if (name[currentLang]) {
		return name[currentLang]
	}
	// Return the top app language as a fallback.
	if (name[appLanguages[0].code]) {
		return name[appLanguages[0].code]
	}
	// Return english as a fallback fallback (just incase)
	return name.en
})

Handlebars.registerHelper('capitaliseFirstLetter', function(string) {
	return string.charAt(0).toUpperCase() + string.slice(1)
})

Handlebars.registerHelper('findSelected', function(firstString, secondString) {
	return (firstString === secondString ? 'selected' : '')
})

// Get locale name from code
Handlebars.registerHelper('getLocaleName', function(code, languageList) {
	for (var i = 0; i < languageList.length; i++) {
		if (languageList[i].code === code) {
			return languageList[i].name
		}
	}
	return code
})

// Disable a language in a dropdown for whatnow
Handlebars.registerHelper('enabledLang', function(code, translationList) {
	for (var i = 0; i < translationList.length; i++) {
		if (translationList[i].lang === code) {
			return "disabled"
		}
	}
	return
})

// Disable a language in a dropdown for whatnow attribution
Handlebars.registerHelper('enabledLangKey', function(code, translationList) {
	return translationList[code] ? 'disabled' : ''
})

Handlebars.registerHelper('ifExistsInArray', function(array, object, options) {
	return ($.inArray(object, array) === -1 ? options.inverse(this) : options.fn(this))
})

Handlebars.registerHelper('countNumOfProps', function(object) {
	var count = 0
	for (var k in object) {
		if (object.hasOwnProperty(k)) {
			++count
		}
	}
	return count
})

Handlebars.registerHelper('getBackgroundImageStyle', function(imageSrc, backgroundType) {
	// Get background url... (transparent image if not there..)
	var imageURL = utils.getImagePreviewUrl(imageSrc)
	var applyType = true
	if (imageURL === "//:0" || imageURL === "") {
		imageURL = "/images/transparent.png"
		applyType = false
	}

	// Layer background image with transparent for transparent images
	var withType = "background: no-repeat center/" + backgroundType + " url(" + imageURL + "), url('/images/transparent.png');"
	var withoutType = "background: url(" + imageURL + "), url('/images/transparent.png');"

	return applyType ? withType : withoutType
})

Handlebars.registerHelper('selectDisableSection', function(currentSectionNumber, blockSectionNumber, sectionNumber) {
	var selected = false,
		disabled = false
	// Add one as we are dealing with the number not the index
	currentSectionNumber++
	if (currentSectionNumber === sectionNumber) {
		selected = true
	}
	if (currentSectionNumber === blockSectionNumber) {
		disabled = true
	}
	if (selected) {
		return 'selected'
	} else if (disabled) {
		return 'disabled'
	}

	return ''
})

// Uses the Strom Colour Class:
// 	{
// 		"class": "Colour",
// 		"a": "<Number>",
// 		"r": "<Number>",
// 		"g": "<Number>",
// 		"b": "<Number>"
// 	}
Handlebars.registerHelper('getColourStyle', function(colour) {
	return ('background-color: rgba(' + colour.r + ', ' + colour.g + ', ' + colour.b + ', ' + colour.a + ');')
})

Handlebars.registerHelper('getRGBAString', function(colour) {
	return 'rgba(' + colour.r + ',' + colour.g + ',' + colour.b + ',' + colour.a + ')'
})

Handlebars.registerHelper('ifStringContains', function(str, contains, options) {
	if (str.indexOf(contains) !== -1) {
		return options.fn(this)
	}
	return options.inverse(this)
})

Handlebars.registerHelper('getObjectTitleById', function(id, collection) {
	var model = collection.get(id)
	if (model) {
		return App.l(model.get('title'))
	}
	return id
})

Handlebars.registerHelper('valExistsInClass', function(classObject, key, val, options) {
	if (classObject[key] && classObject[key][val]) {
		return options.fn(this)
	}
	return options.inverse(this)
})
