<div class="modal-header">
	<button class="button negative left" data-dismiss="modal">{{t "common.cancel"}}</button>
	<button class="button positive right save-button">{{t "common.save"}}</button>
	<h3>{{t "stories.editStory"}}</h3>
</div>
<div class="modal-body">
	<div class="form-horizontal">

		<div class="control-group">
			<label class="control-label">{{t "mediaLibrary.image"}}</label>
			<div class="controls">
				<img src="{{imageUrl}}" style="margin-bottom: 10px">
				<button class="button choose-image-button">{{t "editor.inspector.properties.image.chooseButton"}}</button>
			</div>
		</div>

		<div class="control-group">
			<label class="control-label" for="story-title">{{t "editor.inspector.properties.title.title"}}</label>
			<div class="controls">
				<input type="text" id="story-title" class="input-block-level" value="{{title}}" required>
			</div>
		</div>

		<div class="control-group">
			<label class="control-label" for="story-sub-title">{{t "stories.subtitle"}}</label>
			<div class="controls">
				<input type="text" id="story-sub-title" class="input-block-level" value="{{subtitle}}" required>
			</div>
		</div>

		<div class="control-group">
			<label class="control-label" for="story-link">{{t "editor.inspector.properties.link.title"}}</label>
			<div class="controls">
				<input type="url" id="story-link" class="input-block-level" value="{{link}}" required>
			</div>
		</div>

		<div class="control-group">
			<label class="control-label" for="story-timestamp">{{t "stories.created"}}</label>
			<div class="controls">
				<input type="text" id="story-timestamp" class="input-block-level" style="display:none" required>
			</div>
		</div>

		<div class="control-group">
			<label class="control-label" for="story-content">{{t "stories.content"}}</label>
		</div>
		<textarea id="story-content" class="input-block-level" style="height:200px" required>{{content}}</textarea>

	</div>
</div>
