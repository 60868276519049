<div class="row">
	<div class="col-sm-6">
		<h3>Edit Server Deployment</h3>
	</div>
	<div class="col-sm-6 actions-col">
		<a href="javascript:history.go(-1)"><button class="button white">{{t "common.back"}}</button></a>
		<button class="button green save">{{t "common.save"}}</button>
	</div>
</div>
<div class="row">
	<div class="col-xs-12">
		<form>
			<legend>{{name}}</legend>

			<div class="form-group">
				<label class="control-label" for="role-description">Description</label>
				<div class="controls">
					<input type="text" id="role-description" class="form-control" value="{{description}}">
				</div>
			</div>

			<div class="form-group">
				<label class="control-label" for="role-default-attributes">Default attributes</label>
				<div class="controls">
					<textarea id="role-default-attributes" class="form-control" style="height:300px">{{stringify default_attributes}}</textarea>
				</div>
			</div>
		</form>
	</div>
</div>
