var PaginatedListView       = require('list-view/paginated-list-view'),
	Maps                    = require('lib/maps'),
	alertInfoWindowTemplate = require('./general-notification-info-window-template'),
	DisasterList            = require('./disaster-list'),
	HazardsSectionView      = require('./hazards-section-view')

var categoryColours = [
	'lime',    // General
	'blue',    // Tsunami
	'red',     // Hurricane
	'orange',  // Tornado
	'green',   // Flood
	'purple',  // Wildfire
	'blue',    // Wind
	'#0087FF', // Storm
	'black',   // Earthquake
	'red',     // Heatwave
	'yellow',  // Winter
	'magenta', // Volcano
	'lime'     // Nuclear
]

var GDPCAlertListView = PaginatedListView.extend({
	template: require('./general-notification-list-view-template'),
	list: require('./general-notification-list'),
	listItem: require('./general-notification-list-item-view'),

	initialize: function(options) {
		var initializeArgs = arguments

		var parent = options.parent

		// Fetch disaster list to get event IDs.
		this.disasterList = new DisasterList()

		// Init PaginatedListView after we've got the required disasterList/eventList
		this.disasterList.once('sync', function() {
			this.generateEventMap()
			PaginatedListView.prototype.initialize.apply(this, initializeArgs)
			parent.afterParentInit()
		}, this)

		// Create empty event collection to pass to each list item.
		// Hacky.
		this.eventList = new Backbone.Collection()
		this.listItem.prototype.disasterList = this.disasterList
		this.listItem.prototype.eventList = this.eventList

		this.disasterList.fetch()
	},

	generateEventMap: function() {
		var self = this

		this.disasterList.forEach(function(disaster) {
			disaster.get('events').forEach(function(event) {
				self.eventList.add(event)
			})
		})

		// Trigger reset to indicate list population - re-render list items.
		this.eventList.trigger('reset')
	}
})

module.exports = HazardsSectionView.extend({
	template: require('./general-notifications-view-template'),

	events: {
		'click .map-overlay': 'unlockMap',
		'click .info-window-pin-button': 'infoWindowPinButtonClick'
	},

	initialize: function(options) {
		this.app = App.appList.get(options.appId)

		this.views = {
			list: new GDPCAlertListView({
				app: this.app,
				parent: this
			})
		}
	},

	afterParentInit: function() {
		Maps.init(this.mapLoaded.bind(this))
	},

	getPageTitle: function() {
		return $.t('hazards.alerts.title')
	},

	getRenderData: function() {
		return {appId: this.app.id}
	},

	afterRender: function() {
		if (Maps.loaded) {
			setTimeout(this.initGoogleMap.bind(this))
		} else {
			this.once('mapReady', this.initGoogleMap, this)
		}

		if (!App.acl.hasWritePermission('Hazards', 'Alerts')) {
			this.$('.alert-buttons').remove()
		}
		// Test alert function only available on ARC.
		var isARC = App.system.id === 9

		if (!isARC) {
			this.$('.test-alert-button').remove()
		}
	},

	infoWindowPinButtonClick: function() {
		App.startLoad()

		var options = _.clone(this.infoWindowAlert.get('options'))
		options.pinned = !options.pinned

		this.infoWindowAlert.unset('region')
		this.infoWindowAlert.once('sync', this.infoWindowPinComplete, this)
		this.infoWindowAlert.save({options: options}, {globalAuth: true})
	},

	infoWindowPinComplete: function() {
		// Re-render InfoWindow.
		this.infoWindow.setContent(alertInfoWindowTemplate(this.infoWindowAlert.toJSON()))
		App.stopLoad()
	},

	mapLoaded: function() {
		this.trigger('mapReady')
	},

	initGoogleMap: function() {
		if (this.map) {
			return
		}

		// Initialize Google map
		this.map = new google.maps.Map(this.$('.map')[0], Maps.getOptions())
		this.bounds = new google.maps.LatLngBounds()
		this.infoWindow = new google.maps.InfoWindow()

		// Store a pointer to the alert being shown in the InfoWindow
		this.infoWindowAlert = null

		// Bind add/remove events to draw on the map.
		this.listenTo(this.views.list.collection, 'add', this.addAlertToMap)
		this.listenTo(this.views.list.collection, 'remove', this.removeAlertFromMap)

		// Render all initial markers, if they loaded before Google Maps (and the add event handler didn't fire).
		if (this.views.list.collection && this.views.list.collection.length) {
			this.views.list.collection.forEach(this.addAlertToMap, this)
		}
	},

	unlockMap: function() {
		this.$('.map-overlay').fadeOut(500)
		this.$('.map').css('height', '400px')

		setTimeout(function() {
			google.maps.event.trigger(this.map, 'resize')
			this.map.fitBounds(this.bounds)
		}.bind(this), 500)
	},

	addAlertToMap: function(alert) {
		var paths = [],
			region = alert.get('region') || alert.get('marker'),
			colour = categoryColours[alert.get('category')]

		if (region.type === 'MultiPolygon') {
			region.coordinates.forEach(function(polygon) {
				var latLngs = polygon.map(function(path) {
					return path.map(function(coord) {
						var latLng = new google.maps.LatLng(coord[1], coord[0])
						this.bounds.extend(latLng)
						return latLng
					}, this)
				}, this)

				paths = paths.concat(latLngs)
			}, this)

			// Construct the polygon.
			alert.marker = new google.maps.Polygon({
				paths: paths,
				strokeColor: colour,
				strokeOpacity: 0.8,
				strokeWeight: 1,
				fillColor: colour,
				fillOpacity: 0.35,
				map: this.map
			})
		} else if (region.type === 'Circle') {
			// Add a circle to the map.
			alert.marker = new google.maps.Circle({
				paths: paths,
				strokeColor: colour,
				strokeOpacity: 0.8,
				strokeWeight: 1,
				fillColor: colour,
				fillOpacity: 0.35,
				center: new google.maps.LatLng(region.coordinates[1], region.coordinates[0]),
				radius: region.radius,
				map: this.map
			})
		} else if (region.type === 'Point') {
			alert.marker = new google.maps.Marker({
				position: new google.maps.LatLng(region.coordinates[1], region.coordinates[0]),
				map: this.map
			})
		} else {
			return
		}

		this.map.fitBounds(this.bounds)

		google.maps.event.addListener(alert.marker, 'click', function(e) {
			this.polygonClick(e, alert)
		}.bind(this))
	},

	removeAlertFromMap: function(alert) {
		if (alert.marker) {
			alert.marker.setMap(null)

			// Clear paths on markers with them (polygons) for hopeful GC.
			if (alert.marker.setPath) {
				alert.marker.setPaths([])
			}
		}
	},

	polygonClick: function(e, alert) {
		var data = alert.toJSON()

		var categoryId = alert.get('type').category
		var category = this.views.list.disasterList.get(categoryId)
		if (category) {
			data.categoryName = category.get('codeName')
		}

		var eventId = alert.get('type').event
		var event = this.views.list.eventList.get(eventId)
		if (event) {
			data.eventName = event.get('name')
		}

		var feedRegex = new RegExp(/\[(.*?)\]/)
		var regexResult = feedRegex.exec(alert.get('description'))
		data.feed = regexResult ? regexResult[1] : App.system.apiCode

		this.infoWindow.setContent(alertInfoWindowTemplate(data))
		this.infoWindow.setPosition(e.latLng)
		this.infoWindow.open(this.map)

		// Store a reference to the current alert for pinning/re-rendering.
		this.infoWindowAlert = alert

		if (!App.acl.hasWritePermission('Hazards', 'Alerts')) {
			this.$('.info-window-pin-button').attr('disabled', true)
		}

		// Hide alert pinning on ARC.
		if (App.system.id === 9) {
			this.$('.info-window-pin-button').remove()
		}
	}
})
