<div class="sidebar">
	{{#with user}}
		<div class="avatar"
				 style="background-image: url('{{gravatar email size=170}}')"></div>
		<h1 id="user-name">{{firstName}} {{lastName}}</h1>
	{{/with}}
	<h2><a href="/logout" data-i18n="nav.logout" class="logout-link"></a></h2>
	<div class="section">
		<a href="/apps">
			<button class="button block" data-i18n="manageApps.title"></button>
		</a>
	</div>
	{{!<div class="section">
		<a href="/dashboard" data-i18n="dashboard.title"></a>
	</div>}}
	{{#each sections}}
		<div class="section">
			<h2>{{name}}</h2>
			<ul class="list-unstyled">
				{{#each pages}}
					<li><a href="/apps/{{#if ../../app.id}}{{../../app.id}}{{else}}noApp{{/if}}/{{url}}">{{name}}</a></li>
				{{/each}}
			</ul>
		</div>
	{{/each}}
	<div class="language-selector-wrap section">
		<h2 data-i18n="gdpcConsole.devPools.language"></h2>
		<select class="client-language">
			<option value="en" {{selectLang 'en'}}>English</option>
			<option value="ar" {{selectLang 'ar'}}>العربية</option>
			<option value="es" {{selectLang 'es'}}>Español</option>
			<option value="fr" {{selectLang 'fr'}}>français</option>
			<option value="id" {{selectLang 'id'}}>Bahasa Indonesia</option>
			<option value="my" {{selectLang 'my'}}>ဗမာစာ</option>
			<option value="ru" {{selectLang 'ru'}}>Русский</option>
			<option value="vi" {{selectLang 'vi'}}>tiếng Việt</option>
			<option value="zh" {{selectLang 'zh'}}>官话</option>
		</select>
	</div>
	<div class="sidebar-version">{{cmsVersion}}</div>
</div>
<div class="content">
	<div class="nav-bar">
		<button class="hamburger"></button>
		<div class="breadcrumbs">
			<span>{{sectionTitle}}</span>
			{{#if pages.length}}
				<span class="chevron">&nbsp;</span>
			{{/if}}
			{{#each pages}}
				<a href="/apps/{{../app.id}}/{{url}}">{{name}}</a>
			{{/each}}
		</div>
		<div class="actions"></div>
		<div class="section">
			{{#if appSelectOptions}}
			<select class="app-select">{{{appSelectOptions}}}</select>
			{{/if}}
		</div>
	</div>
	<div class="storm-view-content"></div>
	<pre class="api-root developer-mode">{{apiRoot}}</pre>
</div>
