<div class="row">
	<div class="col-xs-12">
		<h3>{{t "gdpcConsole.nationalSocieties.title"}}</h3>
		<a href="/apps/{{appId}}/societies/new"><button class="button add">{{t "common.add"}}</button></a>
	</div>
	<div class="col-xs-12">
		<table>
			<thead>
				<tr>
					<th class="sort-button" data-property="description">{{t "agreement.nationalSociety"}}</th>
					<th>{{t "apps.app"}}</th>
					<th class="sort-button" data-property="stage">{{t "gdpcConsole.nationalSocieties.stage"}}</th>
					<th class="rating-column sort-button" data-property="rating">{{t "gdpcConsole.nationalSocieties.averageRating"}}</th>
					<th class="sort-button" data-property="iosDownloads">iOS {{t "gdpcConsole.nationalSocieties.downloads"}}</th>
					<th class="sort-button" data-property="androidDownloads">Android {{t "gdpcConsole.nationalSocieties.downloads"}}</th>
					<th class="sort-button" data-property="totalDownloads">{{t "gdpcConsole.nationalSocieties.totalDownloads"}}</th>
					<th class="sort-button" data-property="pool">{{t "gdpcConsole.nationalSocieties.pool"}}</th>
					<th class="sort-button" data-property="goLive">{{t "gdpcConsole.nationalSocieties.goLive"}}</th>
				</tr>
			</thead>
			<tbody class="list-items">
				{{#each societies}}
					<tr>
						<td class="center">{{description}} <a href="/apps/{{../appId}}/societies/{{id}}" class="edit-link"><i class="icon-pencil"></i></a></td>
						<td class="center">{{getBrowserLocaleText app}}</td>
						<td class="center">{{stage}}</td>
						<td class="center">{{{starify rating}}}</td>
						<td class="center">{{formatNumber iosDownloads}}</td>
						<td class="center">{{formatNumber androidDownloads}}</td>
						<td class="center">{{formatNumber totalDownloads}}</td>
						<td class="center">{{pool}}</td>
						<td class="center">{{formatDate goLive}}</td>
					</tr>
				{{/each}}
			</tbody>
			<tfoot>
				<tr>
					{{#with totals}}
						<td colspan="4" class="center"></td>
						<td class="center">{{formatNumber ios}}</td>
						<td class="center">{{formatNumber android}}</td>
						<td class="center">{{formatNumber total}}</td>
						<td colspan="2" class="center"></td>
					{{/with}}
				</tr>
			</tfoot>
		</table>
	</div>
</div>
