var ListViewItem = require('list-view/list-view-item')

module.exports = ListViewItem.extend({
	template: require('./stories-list-item-view-template'),

	afterRender: function() {
		if (!App.acl.hasWritePermission('Stories')) {
			this.$('.tools').text('-')
		}
	},

	// Noop function to event can bubble up
	edit: function() {}
})
