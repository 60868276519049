var ListView = require('list-view/list-view')

module.exports = ListView.extend({
	template: require('./roles-view-template'),
	list: require('./role-list'),
	listItem: require('./role-list-item-view'),

	getRenderData: function() {
		var currentApp = App.getCurrentApp(),
			appId

		if (currentApp) {
			appId = currentApp.id
		}

		return {
			appId: appId
		}
	},

	afterRender: function() {
		if (!App.acl.hasWritePermission('Users', 'Role management')) {
			this.$('.add').hide()
		}

		App.stopLoad()
	}
})
