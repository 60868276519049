var LinkSelector = require('./link-selector')

module.exports = Backbone.View.extend({
	template: require('./multi-link-selector-template'),

	events: {
		'click .add-link-item': 'addNewlink'
	},

	initialize: function(options) {
		console.log('init multi link selector')
		this.pageId = options.pageId
		this.model = options.array
		this.language = options.language

		this.listViews = []

		this.listenTo(this.model, 'add', this.addlink, this)
		this.listenTo(this.model, 'remove', this.removelink, this)
	},

	afterRender: function() {
		this.model.each(this.addlink, this)
	},

	removelink: function(model) {
		// Child link removed from collection - delete it and save the collection back to the server
		model.destroy()
		this.model.parent.save()
	},

	addlink: function(link) {
		var view = new LinkSelector({
			link: link,
			titleDisabled: false,
			language: 'en'
		})

		this.$('.multi-link-list').append(view.render().el)

		// Trigger change on link update to set needsSaving.
		this.listenTo(link, 'change', function() {
			this.model.parent.needsSaving = true
		}, this)
	},

	addNewlink: function() {
		var structure = App.getClassStructure('DestinationLink', this.pageId)
		this.model.add(structure)
	}
})
