<div class="container">
  <div class="clearfix">
    <div class="col-sm-6 panel panel-default">
      <div class="panel-body">
        <h4>Create Colour</h4>
        <form class="row new-colour-form">
          <div class="col-xs-8">
            <div class="form-group">
              <label class="control-label" for="name-input">Name</label>
              <div class="input-group">
                <span class="input-group-addon">English</span>
                <input type="text" class="form-control" id="name-input" value="">
              </div>
            </div>

            <div class="form-group">
              <label class="control-label" for="colour-input">Colour</label>
              <input type="text" class="form-control" id="colour-input" value="">
            </div>

            <div class="form-group">
              <label class="control-label" for="border-colour-input">Border Colour</label>
              <input type="text" class="form-control" id="border-colour-input" value="">
            </div>

            <div class="form-group">
              <button type="button" class="button wide js-add-btn">Add</button>
            </div>
            <div class="colour-preview colour-preview--large js-live-colour-preview"
                 style="background-color: rgba({{ colour.r }}, {{ colour.g }}, {{ colour.b }}, {{ colour.a }});">
             </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="panel panel-default">
    <div class="panel-body">
      <h4>COLOURS</h4>
      <ul class="tab-list" id="items">
        {{#each this.colourList}}
          <li class="tab" data-colour="{{ id }}">
            <span class="tab__handle">
              <img class="tab__handle-icon" src="/images/arpro/sortable-handle.png">
            </span>
            <div class="tab__header">
              <div class="colour-preview" style="background-color: rgba({{ colour.r }}, {{ colour.g }}, {{ colour.b }}, {{ colour.a }}); {{#if border }} border: 3px solid rgba({{ border.r }}, {{ border.g }}, {{ border.b }}, {{ border.a }}); {{/if}}"></div>

              <span>
                {{ name.content.en }}
              </span>
              <button type="button" class="tab__cta {{#ifEqual visible false }}is-disabled{{/ifEqual}}" data-colour="{{ id }}">
                <?xml version="1.0" encoding="UTF-8"?>
                <svg class="on" width="15px" height="9px" viewBox="0 0 15 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Layer/ON</title>
                    <desc>Created with Sketch.</desc>
                    <defs></defs>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.712522645">
                        <g transform="translate(-144.000000, -1969.000000)" fill="#000000">
                            <g transform="translate(118.000000, 1821.000000)">
                                <g transform="translate(26.000000, 146.500000)">
                                    <g transform="translate(0.000000, 1.400000)">
                                        <g fill-rule="nonzero">
                                            <path d="M7.34999987,0.47314814 C4.20003576,0.47314814 1.49257677,2.19300922 0,4.60509251 C1.4913225,7.01498831 4.19752723,8.73703689 7.34999987,8.73703689 C10.5002148,8.73703689 13.207423,7.01717581 14.6999997,4.60509251 C13.2084264,2.19519672 10.5024725,0.47314814 7.34999987,0.47314814 Z M10.611092,4.60509251 C10.611092,6.35509248 9.15614319,7.76481468 7.34999987,7.76481468 C5.54385656,7.76481468 4.08890778,6.35509248 4.08890778,4.60509251 C4.08890778,2.85509254 5.54385656,1.44537035 7.34999987,1.44537035 C9.15614319,1.44537035 10.611092,2.85509254 10.611092,4.60509251 Z M1.17901022,4.60509251 C1.53020475,4.14328697 2.38310576,3.12245365 3.76279857,2.36898144 C3.33634807,3.02523143 3.08549483,3.77870364 3.08549483,4.60509251 C3.08549483,5.43148139 3.33634807,6.1849536 3.76279857,6.84120359 C2.40819109,6.08773138 1.53020475,5.06689806 1.17901022,4.60509251 Z M10.9372012,6.84120359 C11.3636517,6.1849536 11.6145049,5.43148139 11.6145049,4.60509251 C11.6145049,3.77870364 11.3636517,3.02523143 10.9372012,2.36898144 C12.2918087,3.12245365 13.169795,4.14328697 13.5209895,4.60509251 C13.169795,5.06689806 12.2918087,6.08773138 10.9372012,6.84120359 Z M5.84488045,4.60509251 C5.84488045,3.80300919 6.52218419,3.14675921 7.34999987,3.14675921 C8.17781556,3.14675921 8.8551193,3.80300919 8.8551193,4.60509251 C8.8551193,5.40717583 8.17781556,6.06342582 7.34999987,6.06342582 C6.52218419,6.06342582 5.84488045,5.40717583 5.84488045,4.60509251 Z" id="Combined-Shape"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>

                <?xml version="1.0" encoding="UTF-8"?>
                <svg class="off" width="15px" height="13px" viewBox="0 0 15 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Layer/ON</title>
                    <desc>Created with Sketch.</desc>
                    <defs></defs>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.184442935">
                        <g transform="translate(-144.000000, -2112.000000)" fill="#000000">
                            <g transform="translate(118.000000, 1821.000000)">
                                <g transform="translate(26.000000, 291.500000)">
                                    <g>
                                        <g id="noun_878103-copy" transform="translate(0.000000, 1.400000)" fill-rule="nonzero">
                                            <path d="M7.34999987,0.47314814 C4.20003576,0.47314814 1.49257677,2.19300922 0,4.60509251 C1.4913225,7.01498831 4.19752723,8.73703689 7.34999987,8.73703689 C10.5002148,8.73703689 13.207423,7.01717581 14.6999997,4.60509251 C13.2084264,2.19519672 10.5024725,0.47314814 7.34999987,0.47314814 Z M10.611092,4.60509251 C10.611092,6.35509248 9.15614319,7.76481468 7.34999987,7.76481468 C5.54385656,7.76481468 4.08890778,6.35509248 4.08890778,4.60509251 C4.08890778,2.85509254 5.54385656,1.44537035 7.34999987,1.44537035 C9.15614319,1.44537035 10.611092,2.85509254 10.611092,4.60509251 Z M1.17901022,4.60509251 C1.53020475,4.14328697 2.38310576,3.12245365 3.76279857,2.36898144 C3.33634807,3.02523143 3.08549483,3.77870364 3.08549483,4.60509251 C3.08549483,5.43148139 3.33634807,6.1849536 3.76279857,6.84120359 C2.40819109,6.08773138 1.53020475,5.06689806 1.17901022,4.60509251 Z M10.9372012,6.84120359 C11.3636517,6.1849536 11.6145049,5.43148139 11.6145049,4.60509251 C11.6145049,3.77870364 11.3636517,3.02523143 10.9372012,2.36898144 C12.2918087,3.12245365 13.169795,4.14328697 13.5209895,4.60509251 C13.169795,5.06689806 12.2918087,6.08773138 10.9372012,6.84120359 Z M7.48255343,3.15240661 L5.85044078,4.73054728 C5.84675955,4.68918335 5.84488045,4.64734023 5.84488045,4.60509251 C5.84488045,3.80300919 6.52218419,3.14675921 7.34999987,3.14675921 C7.39464982,3.14675921 7.43886189,3.14866837 7.48255343,3.15240661 Z M8.84955897,4.47963775 C8.8532402,4.52100168 8.8551193,4.5628448 8.8551193,4.60509251 C8.8551193,5.40717583 8.17781556,6.06342582 7.34999987,6.06342582 C7.30534993,6.06342582 7.26113786,6.06151666 7.21744632,6.05777842 L8.84955897,4.47963775 Z" id="Combined-Shape"></path>
                                        </g>
                                        <polygon fill-rule="nonzero" points="2.48169892 11.6282124 13.2793824 1.18759514 12.3930323 0.33055555 1.59534881 10.7711728"></polygon>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>

                <span class="visually-hidden">Toggle visibility</span>
              </button>
            </div>

            <input id="{{ id }}" class="tab__input" type="checkbox" name="tabs">
            <label for="{{ id }}" class="tab__label">
              <span class="tab__btn edit-btn">
                Edit
              </span>
              <span class="tab__btn save-btn" data-colour="{{ id }}">
                Save
              </span>
            </label>
            <div class="tab__content">
              <div class="row clearfix tab__content-inner">
                <div class="form-group col-sm-4">
              		<label class="control-label" for="{{ id }}-name-input">Name</label>
                  <div class="input-group">
          					<span class="input-group-addon">English</span>
                    <input type="text" class="form-control" id="{{ id }}-name-input" value="{{ name.content.en }}">
          				</div>
              	</div>

                <div class="form-group col-sm-4">
                  <label class="control-label" for="{{ id }}-colour-input">Colour</label>

                  <div class="preview-input">
                    <input type="text" class="form-control js-existing-fill-input" id="{{ id }}-colour-input" value="rgba({{ colour.r }}, {{ colour.g }}, {{ colour.b }}, {{ colour.a }})">
                    <div class="colour-preview" style="background-color: rgba({{ colour.r }}, {{ colour.g }}, {{ colour.b }}, {{ colour.a }});"></div>
                  </div>
              	</div>

                <div class="form-group col-sm-4">
                  <label class="control-label" for="{{ id }}-border-colour-input">Border Colour</label>

                  <div class="preview-input">
                    <input type="text" class="form-control js-existing-border-input" id="{{ id }}-border-colour-input" value="rgba({{ border.r }}, {{ border.g }}, {{ border.b }}, {{ border.a }})">
                    <div class="colour-preview" style="background-color: #ffffff; border: 3px solid rgba({{ border.r }}, {{ border.g }}, {{ border.b }}, {{ border.a }})"></div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        {{/each}}
      </ul>
    </div>
  </div>

</div>
