<!-- TODO localise -->
<h3>[L]Sign up</h3>
<form class="row">
	<div class="form-group col-xs-6">
		<input type="text" class="form-control first-name" placeholder="[L]First name">
	</div>
	<div class="form-group col-xs-6">
		<input type="text" class="form-control first-name" placeholder="[L]Last name">
	</div>
	<div class="form-group col-xs-12">
		<input type="email" class="form-control email" placeholder="[L]Email address">
	</div>
	<div class="form-group col-xs-12">
		<input type="password" class="form-control password" placeholder="[L]Password">
	</div>
	<h4 class="small">[L]By signing up you agree to our <a>terms of service</a>.<!-- TODO Add in terms of service --></h4>
	<div class="col-xs-12">
		<button type="submit" class="button big signup-button">[L]Sign up</button>
	</div>
</form>
