<td>
	<dl>
		{{#key_value phrase.content "lang" "text"}}
			<dt>{{lang}}</dt>
			<dd>{{text}}</dd>
		{{/key_value}}
	</dl>
</td>
<td>
    <dl>
		{{#each translations}}
            <dt>{{code}}</dt>
            <dd>{{text}}</dd>
		{{/each}}
    </dl>
</td>
<td class="tools">
	<a href="/apps/{{appId}}/cross-borders/phrase/{{parentId}}/{{id}}" title="{{t "common.edit"}}"><i class="icon-pencil"></i></a>
	<i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}"></i>
</td>
