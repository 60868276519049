var DeploymentRole = require('./deployment-role')

module.exports = Backbone.Collection.extend({
	model: DeploymentRole,

	url: function() {
		return App.deploymentRoot + 'roles'
	},

	parse: function(response) {
		var arr = []

		for (var i in response) {
			if (response.hasOwnProperty(i)) {
				arr.push({name: i})
			}
		}

		arr.sort(function(a, b) {
			return a.name.localeCompare(b.name)
		})

		return arr
	}
})
