<div class="login-container">
	<div class="login-inner">
		<div class="logo"></div>

		<form class="login-form">
			<div class="form-group">
				<input type="text" class="form-control input-lg username" data-i18n="[placeholder]common.username">
			</div>
			<div class="form-group">
				<input type="password" class="form-control input-lg password" data-i18n="[placeholder]login.inputs.password">
			</div>
			<div class="message error-message" data-i18n="login.error.body"></div>
			<div class="form-group">
				<button class="button med block login-button" type="submit"><span data-i18n="login.title"></span><span class="login-timeout"></span></button>
			</div>
			<div class="form-group">
				<button class="button med wide white forgot-button" type="button" data-i18n="login.links.forgot"></button>
			</div>
		</form>

		<form class="forgot-form">
			<div class="message forgot-message">
				<strong data-i18n="login.forgot.title"></strong> <span data-i18n="login.forgot.body"></span>
			</div>
			<div class="form-group">
				<input type="email" class="form-control input-lg email" data-i18n="[placeholder]common.email">
			</div>
			<div class="form-group">
				<button class="button med block forgot-submit-button" type="submit" data-i18n="common.submit"></button>
			</div>
			<div class="form-group">
				<button class="button med wide white forgot-return-button" type="button" data-i18n="login.links.return"></button>
			</div>
		</form>

		<select class="client-language-select">
			<option value="en" {{selectLang 'en'}}>English</option>
			<option value="ar" {{selectLang 'ar'}}>العربية</option>
			<option value="es" {{selectLang 'es'}}>Español</option>
			<option value="fr" {{selectLang 'fr'}}>français</option>
			<option value="id" {{selectLang 'id'}}>Bahasa Indonesia</option>
			<option value="my" {{selectLang 'my'}}>ဗမာစာ</option>
			<option value="ru" {{selectLang 'ru'}}>Русский</option>
			<option value="vi" {{selectLang 'vi'}}>tiếng Việt</option>
			<option value="zh" {{selectLang 'zh'}}>官话</option>
		</select>

		<div class="message forgot-success-message" data-i18n="login.success.body"></div>

		<div class="message login-success-message" data-i18n="login.success.loginBody"></div>


	</div>
</div>
