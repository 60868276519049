<table class="header-float">
	<thead>
	<tr>
		<th>
			<div class="sort-button" data-property="name">{{t "users.name"}}</div>
			<div class="sub-text sort-button" data-property="username">{{t "common.username"}}</div>
		</th>
		<th class="job-title sort-button" data-property="jobTitle">{{t "users.organisation"}}</th>
		<th class="sort-button" data-property="email">{{t "common.email"}}</th>
		<th class="sort-button" data-property="roleId">{{t "users.role"}}</th>
		<th>{{t "users.apps"}}</th>
		<th class="sort-button" data-property="lastSeen">{{t "users.lastActive"}}</th>
		<th>{{t "common.tools"}}</th>
	</tr>
	</thead>
	<tbody class="list-items"></tbody>
</table>
