var StormQLListView = require('stormql/stormql-list-view')

/**
 * Exports {@link StormQLPostListView}.
 * @module
 */
module.exports = StormQLListView.extend(/** @lends StormQLPostListView.prototype */{
	/** @override */
	template: require('./stormql-post-list-view-template'),

	/** @override */
	getPageTitle: function() {
		return $.t('posts.title')
	}
})
