var CanvasItemView = require('./canvas-item-view'),
	ListItemViewBuilder = require('./list-item-view-builder')

module.exports = CanvasItemView.extend({
	template: require('./list-view-template'),

	initialize: function() {
		CanvasItemView.prototype.initialize.apply(this, arguments)

		this.listViews = []

		this.delegateEvents()

		this.collection = this.model.get('children')
		this.listenTo(this.collection, 'add', this.addItem, this)
		this.listenTo(this.collection, 'remove', this.afterRender, this)
		this.listenTo(this.collection, 'reset', this.afterRender, this)
	},

	getRenderData: function() {
		var data = this.model.toJSON()
		data.language = Storm.view.language
		return data
	},

	afterRender: function() {
		CanvasItemView.prototype.afterRender.apply(this, arguments)

		// If re-rendering, clear any previous children.
		this.listViews.forEach(function(view) {
			view.destroy()
		})

		this.listViews = []

		this.$('.view-children').empty()

		// Populate with all child views.
		this.collection.each(this.addItem, this)

		// Hide add button if restricted
		var restrictions = this.model.get('restrictions')

		if (restrictions) {
			if (restrictions.indexOf('add') > -1) {
				this.$('.add').hide()
			}
		}
	},

	addItem: function(model, collection, options) {
		var view = ListItemViewBuilder.build(model),
			el = view.render().el

		this.listViews.push(view)

		// Options can either be an array of elements in the collection
		// or it can be the details of an item added! Classic JS, :')
		// Check to see if it is an array first before checking .at exists.
		if (!Array.isArray(options) && options.at !== undefined) {
			// New item added part way through the list.
			if (options.at === 0) {
				this.$('.view-children').prepend(el)
			} else {
				this.$('.view-children > li').eq(options.at - 1).after(el)
			}
		} else {
			// No index specified, add to end of list.
			this.$('.view-children').append(el)
		}

		// Set new views to editing mode, if we're in it.
		if (this.model.editing) {
			view.startEditing()
		}
	},

	addItemClick: function(e) {
		Storm.view.views.canvas.addView({model: this.model})

		$(e.currentTarget).addClass('editing')

		// Don't let the canvas handler fire too
		e.stopPropagation()
	},

	click: function(e) {
		// Don't do anything if we're already editing this element.
		// TODO don't read this from the DOM
		if (!this.$el.hasClass('editing')) {
			Storm.view.views.canvas.setInspector(this.model)
			if (!$('.preview').hasClass('preview-mode')) {
				this.$el.addClass('editing')
			}
		}

		// Don't bubble up to the page
		e.stopPropagation()
	},

	// Set every child model to edit mode.
	startEditing: function() {
		CanvasItemView.prototype.startEditing.apply(this, arguments)

		this.collection.forEach(function(child) {
			child.trigger('startEditing')
		})
	},

	// Stop editing all child models.
	stopEditing: function() {
		CanvasItemView.prototype.stopEditing.apply(this, arguments)

		this.model.get('children').forEach(function(child) {
			child.trigger('stopEditing')
		})
	}
})
