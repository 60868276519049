<div class="disaster-box" data-selection-id="{{selectorId}}">
  <div class="form-inline disaster-category-form">
    <div class="form-group">
      <label for="category-title">Title:</label>
      <input type="text" class="form-control category-title" id="category-title" data-selection-id="{{selectorId}}" placeholder="Add title" value="{{getLocaleText model.name}}">
    </div>
    <div class="form-group">
      <label>Icon:</label>
      <button class="button circle update-category-icon" data-category-id="{{model.id}}" data-selection-id="{{selectorId}}" style="{{#if meta}} {{#if meta.icon.src.x1}} background-image: url('{{getPreviewUrl meta.icon}}'); background-color: #fff; {{else}}text-shadow: none; {{/if}} {{else}} text-shadow: none; {{/if}}">
        <i class="icon-plus"></i>
      </button>
    </div>
    <div class="form-group">
      <label>Page:</label>
      <select class="update-page" data-category-id="{{model.id}}" data-selection-id="{{selectorId}}">
        {{{pages}}}
      </select>
    </div>
    <div class="form-group">
      <label>Order:</label>
      <i class="icon-arrow-up move-category-up blue-settings"></i>
      <i class="icon-arrow-down move-category-down blue-settings"></i>
    </div>

    <div class="form-group">
      <i class="icon-trash delete-category red-delete"></i>
    </div>
  </div>
  <div class="disaster-event-box" data-selection-id="{{selectorId}}">

  </div>
</div>
