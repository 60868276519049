/**
 * Exports {@link PluginList}.
 * @module
 */
module.exports = Backbone.Collection.extend(/** @lends PluginList.prototype */{
	/**
	 * @constructs PluginList
	 * @extends Backbone.Collection
	 * @override
	 */
	initialize: function(models, options) {
		options = options || {}
		this.appId = options.appId
	},

	/** @override */
	url: function() {
		var url = App.apiRoot + 'plugins'

		if (this.appId != null) {
			url = App.apiRoot + 'apps/' + this.appId + '/plugins'
		}

		return url
	},

	/**
	 * Manually inject system-level plugins.
	 * @override
	 */
	parse: function(response) {
		if (App.system.id === 22) {
			response.push({name: 'Tips'})
		}

		return response
	},

	/**
	 * Checks whether a plugin with the specified name exists (is enabled).
	 * @param {string} name The name of the plugin to check for.
	 * @returns {boolean} Whether the plugin is enabled on this app.
	 */
	hasPlugin: function(name) {
		return this.findWhere({name: name}) !== undefined
	},

	/**
	 * Checks whether a plugin with the specified code name exists (is enabled).
	 * @param {string} codeName The code name of the plugin to check for.
	 * @returns {boolean} Whether the plugin is enabled on this app.
	 */
	hasPluginByCode: function(codeName) {
		return this.findWhere({codeName: codeName}) !== undefined
	},

	/**
	 * [getByCodeName Finds the model by codeName]
	 * @param  {string} codeName The codeName of the plugin
	 * @returns {Model} The plugin model that matches the codename.
	 */
	getByCodeName: function(codeName) {
		return this.findWhere({codeName: codeName})
	},

	/**
	 * [getByClassName Finds the model by className]
	 * @param  {string} className The className of the plugin
	 * @returns {Model} The plugin model that matches the className.
	 */
	getByClassName: function(className) {
		return this.findWhere({className: className})
	}
})
