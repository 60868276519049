/**
 * Exports {@link TriggerListView}.
 * @module
 */
module.exports = Backbone.View.extend({
	/** @override */
	template: require('./trigger-list-view-template'),

	/** @override */
	events: {
		'click .add-trigger': 'addTrigger'
	},

	triggerViews: {
		RaisedTrigger: require('./trigger/raised-trigger-view')
	},

	/**
	 * @constructs TriggerListView
	 * @extends Backbone.View
	 * @override
	 */
	initialize: function(options) {
		this.collection = options.collection
	},

	/** @override */
	getRenderData: function() {
		return {
			triggerSubclasses: _.keys(this.triggerViews)
		}
	},

	/** @override */
	afterRender: function() {
		var list = this.$el.find('#achievement-trigger-list')
		var triggerViews = this.triggerViews

		this.collection.forEach(function(trigger, i) {
			var TriggerView = triggerViews[trigger.get('class')]

			var view = new TriggerView({
				trigger: trigger,
				index: i
			})

			list.append(view.render().el)
		})
	},

	addTrigger: function() {
		var className = this.$el.find('select[name="trigger-subclass"]').val()
		var trigger = App.getClassStructure(className, 0)

		this.collection.push(trigger)
		this.render()
	}
})
