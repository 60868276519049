<div class="row">
	<div class="col-sm-6">
		<h3>Server Deployment</h3>
	</div>
	<div class="col-sm-6 actions-col">
		<a href="deployment/server/new"><button class="button add">{{t "common.add"}}</button></a>
	</div>
</div>
<div class="row">
	<div class="col-xs-12">
		<table>
			<thead>
				<tr>
					<th>Name</th>
					<th>{{t "common.tools"}}</th>
				</tr>
			</thead>
			<tbody class="list-items"></tbody>
		</table>
	</div>
</div>
