var APICompat      = require('lib/api-compat'),
	CanvasItemView = require('editor/canvas/canvas-item-view')

/**
 * Exports {@link GridViewItem}.
 * @module
 */
module.exports = CanvasItemView.extend(/** @lends GridViewItem.prototype */{
	/** @override */
	template: require('./grid-view-item-template'),
	/** @override */
	tagName: 'li',
	/** @override */
	className: 'GridItem',

	/** @override */
	events: function() {
		var events = CanvasItemView.prototype.events()

		delete events.click
		events['click .grid-item-content'] = 'click'

		return events
	},

	/**
	 * @constructs GridItemView
	 * @extends CanvasItemView
	 * @override
	 */
	initialize: function() {
		CanvasItemView.prototype.initialize.apply(this, arguments)
	},

	/** @override */
	getRenderData: function() {
		var data = this.model.toJSON()
		data.language = Storm.view.language
		return data
	},

	/** @override */
	afterRender: function() {
		var className = APICompat.normaliseClassName(this.model.get('class'))

		this.$el.addClass(className)

		// Calculate width based on grid view column count
		var gridView = this.model.collection.parent
		var columns = parseInt(gridView.get('columns'), 10)
		var width = Math.floor(100 / columns) + '%'

		this.$el.css('width', width)

		if (this.model.editing) {
			this.startEditing()
			this.$('.grid-view-item-content').addClass('editing')
		}
	},

	/** @override */
	click: function(e) {
		Storm.view.views.canvas.setInspector(this.model)

		if (!$('.preview').hasClass('preview-mode')) {
			this.$('.grid-view-item-content').addClass('editing')
		}

		// Don't bubble up to the List
		e.stopPropagation()
	}
})
