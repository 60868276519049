{{#if badgeUrl}}
	<div class="cell-image" style="background-image:url('{{badgeUrl}}')"></div>
{{/if}}

{{#ifExists icon}}
	<div class="cell-image" style="background-image:url('{{getPreviewUrl icon}}')"></div>
{{/ifExists}}

{{#ifExists image}}
	<div class="cell-image" style="background-image:url('{{getPreviewUrl image}}')"></div>
{{/ifExists}}

{{#if overlay}}
	<div class="overlay-text">{{getLocaleText overlay}}</div>
{{/if}}
