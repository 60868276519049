var Template = require('./template')

module.exports = Backbone.View.extend({
	template: require('./template-create-view-template'),

	events: {
		'click .template-save-button': 'save'
	},

	initialize: function(options) {
		this.json = options.json
	},

	afterRender: function() {
		this.$('.template-name').focus()
	},

	save: function() {
		var name = this.$('.template-name').val(),
			description = this.$('.template-description').val()

		if (!name || !description) {
			return
		}

		App.startLoad()

		var template = new Template({
			content: this.json,
			name: name,
			description: description,
			appId: Storm.view.app.id
		})

		template.once('sync', function() {
			Storm.view.app.templateList.add(template)
			App.stopLoad()

			this.destroy()
		}, this)

		template.save()
	}
})
