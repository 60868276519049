module.exports = Backbone.Model.extend({
	noAuth: true,

	urlRoot: function() {
		return App.newRobotoRoot + 'builds'
	},

	defaults: {
		platform: 'ios',
		branch: 'master',
		cascade_children: false
	},

	toJSON: function() {
		return {
			build: Backbone.Model.prototype.toJSON.apply(this, arguments)
		}
	},

	parse: function(response) {
		return response.build
	}
})
