var PageListItem = require('./page-list-item-view')

module.exports = Backbone.View.extend({
	className: 'page-list span3',
	template: require('./page-list-view-template'),

	events: {
		'keyup .list-filter input': 'filter'
	},

	initialize: function(options) {
		this.listViews = []
		this.app = options.app

		// Re-render when language changed
		this.on('change:language', function() {
			this.render()
			this.collection.sort()
		}, this)

		// Fetch page list for given app
		this.collection = this.app.pageList
		this.listenTo(this.collection, 'add', this.addNewPage, this)
		this.listenTo(this.collection, 'remove', this.removePage, this)
		this.collection.fetch({reset: true})
			.then(this.render.bind(this))
	},

	afterRender: function() {
		// Clear current list and add each item again
		_.each(this.listViews, function(view) {
			view.destroy()
		})

		this.listViews = []
		this.$('.list-content').empty()

		// Render page list content only once page list has loaded
		if (this.collection.fetched) {
			// Render app structure
			this.renderStructure()

			// Display every page in the 'all pages' folder
			this.linkedPages = new PageListItem.LinkedPagesItem({pageList: this.collection})
			this.listViews.push(this.linkedPages)
			this.$('.list-content').append(this.linkedPages.render().el)

			this.linkedPages.renderStructure()
		}

		// Hide add page button if no write permission
		var permission = App.acl.getPermission('Content')

		if (permission !== 'Write' && permission !== 'Delete') {
			this.$('.add-page-button').hide()
		}

		// Switch text direction to RTL for Arabic/Hebrew
		var direction = 'ltr'

		if (Storm.view.language === 'ar' || Storm.view.language === 'he' || Storm.view.language === 'fa') {
			direction = 'rtl'
		}

		this.$el.css('direction', direction)
	},

	renderStructure: function() {
		// Get start page object
		var rootId = this.app.get('objectId')
		var rootPage = this.collection.get(rootId)

		if (rootPage) {
			// Create view and render it to the DOM
			var view = new PageListItem({
				model: rootPage,
				pageList: this.collection
			})

			this.listViews.push(view)

			this.$('.list-content').append(view.render().el)
		}

		// All data loaded - inform parent view
		this.trigger('ready')
	},

	addNewPage: function() {
		this.linkedPages.renderStructure()
	},

	removePage: function() {
		// Close current page - it was probably being edited
		Storm.view.setPageId(null)
		this.linkedPages.renderStructure()
	},

	filter: function() {
		var val = this.$('.list-filter input').val()
		this.linkedPages.setFilter(val)

		// Expand the 'All pages' item if it isn't already.
		var isExpanded = this.linkedPages.$('> ul').is(':visible')

		if (!isExpanded) {
			this.linkedPages.collapse()
		}
	}
})
