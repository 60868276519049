<div class="pagination bugs-top-pagination"></div>

<div class="empty-table-text">{{t "bugs.noReports"}}</div>

<table class="header-float" style="display:none">
	<thead>
		<tr>
			<th>{{t "apps.app"}}</th>
			<th>{{t "bugs.device"}}</th>
			<th>{{t "bugs.version"}}</th>
			<th>{{t "bugs.build"}}</th>
			<th>{{t "common.description"}}</th>
			<th>{{t "publish.user"}}</th>
		</tr>
	</thead>
	<tbody class="list-items"></tbody>
</table>

<div class="pagination"></div>
