<td class="center" style="width:150px">
	{{#if badge}}
		<h5><a href="/apps/{{appId}}/badges/{{badgeId}}">{{getLocaleText badge.title}}</a></h5>
		<a href="/apps/{{appId}}/badges/{{badgeId}}"><img src="{{getPreviewUrl badge.icon}}"></a>
	{{else}}
		<span>-</span>
	{{/if}}
</td>
<td class="center">{{name}}</td>
<td class="center">
	{{#if pageTitle}}
		{{getLocaleText pageTitle}}
	{{else}}
		<span>-</span>
	{{/if}}
</td>
<td class="tools">
	<a href="/apps/{{appId}}/levels/{{id}}" title="{{t "common.edit"}}"><i class="icon-pencil"></i></a>
	<i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}"></i>
</td>
