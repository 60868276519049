var PluginList = require('editor/plugin-list'),
	AppSetupSectionView = require('app-setup/app-setup-section-view')

module.exports = AppSetupSectionView.extend({
	template: require('./plugins-view-template'),

	events: {
		'click .save-button': 'save',
		'change .plugin-enabled': 'toggle'
	},

	initialize: function(options) {
		this.appId = options.appId

		this.availablePlugins = new PluginList()
		var availableFetch = this.availablePlugins.fetch()

		this.collection = new PluginList(null, {appId: this.appId})
		var collectionFetch = this.collection.fetch()

		$.when(availableFetch, collectionFetch).then(this.ready.bind(this))
	},

	getPageTitle: function() {
		return $.t('plugins.title')
	},

	getRenderData: function() {
		var plugins = new Backbone.Collection(this.availablePlugins.toJSON())

		this.collection.forEach(function(plugin) {
			plugins.get(plugin.id).set('enabled', true)
		})

		return {
			appId: this.appId,
			plugins: plugins.toJSON()
		}
	},

	afterRender: function() {
		if (!App.acl.hasWritePermission('App Setup', 'Plugins')) {
			this.$('input, select, button').attr('disabled', true)
		}
	},

	ready: function() {
		this.render()
		App.stopLoad()
	},

	toggle: function(e) {
		var id = $(e.currentTarget).data('id')

		if (e.currentTarget.checked) {
			this.collection.push({id: id})
		} else {
			this.collection.remove(this.collection.get(id))
		}
	},

	save: function() {
		App.startLoad()
		this.collection.save()
			.then(this.saveComplete.bind(this), this.saveError)
	},

	saveComplete: function() {
		App.stopLoad()
		App.showToast($.t('hazards.disasters.saveSuccess'))

		// Update local plugin list for this app.
		var app = App.appList.get(this.appId)

		if (app) {
			app.pluginList.reset(this.collection.models)
		}
	},

	saveError: function() {
		App.stopLoad()
		swal($.t('error.oops'), $.t('error.generic'), 'error')
	}
})
