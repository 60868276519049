var MediaLibraryView = require('./media-library-view')

/**
 * Exports {@link MediaLibraryModalSelectionView}.
 * @module
 */
module.exports = Backbone.View.extend(/** @lends MediaLibraryModalSelectionView.prototype */{
	/** @override */
	className: 'storm-modal stretch modal fade',

	/** @override */
	template: require('./media-library-modal-selection-view-template'),

	/** @override */
	events: {
		'click .cancel-button': 'cancelButtonClick_',
		'click .save-button': 'saveButtonClick_'
	},

	/**
	 * @constructs MediaLibraryModalSelectionView
	 * @extends Backbone.View
	 * @override
	 * @classdesc Wraps {@link MediaLibraryView} in a modal and proxies
	 *     selection events.
	 */
	initialize: function(options) {
		options = options || {}

		if (!options.app) {
			throw new Error('No app specified')
		}

		/** @type App */
		this.app_ = options.app
		/** @type number */
		this.mediaType_ = options.mediaType

		this.selectedFile_ = {
			/** @type number */
			type: 0,
			/** @type UploadList#model */
			file: null
		}

		/** @type MediaLibraryView */
		this.mediaLibraryView_ = new MediaLibraryView({
			app: this.app_,
			model: this.model,
			mediaType: this.mediaType_
		})

		this.listenTo(this.mediaLibraryView_, 'select', this.mediaSelect_)
	},

	/** @override */
	getRenderData: function() {
		if (this.model) {
			return (this.model.toJSON) ? this.model.toJSON : this.model
		}

		return {}
	},

	/** @override */
	afterRender: function() {
		this.$('.modal-body').append(this.mediaLibraryView_.el)
		this.mediaLibraryView_.render()
	},

	/**
	 * Shows the view for the first time, animating in as a modal.
	 */
	show: function() {
		this.$el.modal({keyboard: false, backdrop: 'static'})
	},

	/**
	 * Dismisses the view's modal and destroys the view.
	 */
	close: function() {
		this.$el.modal('hide')

		// Destroy view after modal animated out.
		setTimeout(this.destroy.bind(this), 500)
	},

	/**
	 * Handles click events on the 'Cancel' button. Dismisses the modal and
	 * closes the view.
	 * @private
	 */
	cancelButtonClick_: function() {
		this.close()
	},

	/**
	 * Handles click events on the 'Save' button. Triggers a 'select' event
	 * with the selected file as a payload and closes the view.
	 * @private
	 */
	saveButtonClick_: function() {
		if (!this.selectedFile_.file) {
			return
		}

		this.trigger('select', this.selectedFile_.file, this.selectedFile_.type)
		this.close()
	},

	/**
	 * Handles 'select' events from the child {@link MediaLibraryView}. Stores
	 * the new file data for saving later.
	 * @param {UploadList#model} file The newly selected file.
	 * @param {number} type The type of the selected file. Can be compared to
	 *     values in {@link MediaLibraryView#types}.
	 * @private
	 */
	mediaSelect_: function(file, type) {
		this.selectedFile_.file = file
		this.selectedFile_.type = type
	},

	/** @override */
	beforeDestroy: function() {
		this.mediaLibraryView_.destroy()
	}
})
