var BoxDrawView = require('./box-draw-view'),
	Maps        = require('lib/maps'),
	Push        = require('./push')

var TOKEN_LENGTH   = 37,
	PAYLOAD_LENGTH = 750 - TOKEN_LENGTH

/**
 * Exports {@link PushEditorView}.
 * @module
 */
module.exports = Backbone.View.extend(/** @lends PushEditorView.prototype */{
	/** @override */
	template: require('./push-editor-view-template'),

	/** @override */
	events: {
		'input #push-message': 'messageKeypress_',
		'change #push-page': 'messageKeypress_',
		'change #push-category': 'categoryChange_',
		'click .map-button': 'mapClick_'
	},

	/**
	 * @constructs PushEditorView
	 * @extends Backbone.View
	 * @override
	 * @classdesc Provides editing/creation of push notifications. Contains no
	 *     page UI — may be embedded anywhere.
	 */
	initialize: function(options) {
		if (!options.app) {
			throw new Error('No app specified')
		}

		/** @private Object */
		this.views = {}

		/** @private {App} */
		this.app = options.app
		/** @private {Pikaday} */
		this.datepicker_ = null
		/** @private {BoxDrawView} */
		this.boxDrawView_ = null
		/** @private {new google.maps.Map} */
		this.map_ = null
		/** @private {google.maps.Polygon} */
		this.overlay_ = null
		/** @private {PageList} */
		this.pageList_ = this.app.pageList

		this.model = new Push({appId: this.app.id})

		var pageListFetch = this.pageList_.fetchOnce(),
			fetches       = [pageListFetch]

		if (options.id !== undefined && options.id !== 'new') {
			this.model.set('id', options.id)
			var modelFetch = this.model.fetch()

			fetches.push(modelFetch)
		}

		this.listenTo(this.model, 'change', this.renderRegionPreview_)

		Promise.all(fetches)
			.then(this.render.bind(this))
			.then(App.stopLoad)
	},

	/** @override */
	afterRender: function() {
		// Initialise datepicker
		this.datepicker_ = new Pikaday({
			field: this.$('#push-date')[0],
			bound: true,
			showTime: true
		})

		var date      = new Date(),
			timestamp = this.model.get('timestamp')

		if (timestamp !== undefined) {
			date = new Date(timestamp * 1000)
		}

		this.datepicker_.setDate(date)

		// Initialise Google maps preview.
		Maps.init(this.initGoogleMap_.bind(this))

		// Populate page selector
		// Get array of all unique tag names
		var tags = _.filter(this.pageList_.pluck('tag'), function(elem, pos, self) {
			return self.indexOf(elem) === pos
		})

		_.each(tags, function(tag) {
			var taggedPages = this.pageList_.where({tag: tag})
			var options = ''

			_.each(taggedPages, function(page) {
				if (page.get('class') === 'NativePage') {
					options += '<option value="app://native/pages/' + page.get('name') + '">' + App.l(page.get('title')) + '</option>'
				} else {
					options += '<option value="cache://pages/' + page.id + '.json">' + App.l(page.get('title')) + '</option>'
				}
			})

			this.$('#push-page').append('<optgroup label="' + tag + '">' + options + '</optgroup>')
		}, this)

		this.$('#push-message').val(this.model.get('payload..message'))
		// Set current select values
		this.$('#push-priority').val(this.model.get('priority'))
		this.$('#push-category').val(this.model.get('category'))
		this.$('#push-page').val(this.model.get('payload..url'))

		// Only show 'Region' push category for First Aid apps. Hide on ASPCA.
		if (this.app.get('type') !== 0 || App.system.id === 8) {
			this.$('#push-category option[value=region]').remove()
		}

		// Remove 'Custom' push type for non-developers.
		if (!App.developerMode) {
			this.$('#push-category option[value=custom]').remove()
		}

		this.messageKeypress_()
		this.categoryChange_()
		this.delegateEvents()
	},

	/**
	 * Saves the new or modified push notification.
	 * @returns {Promise} Promise wrapping jqXHR for save request. Resolves on
	 *     save complete. Rejects on invalid data with an error message, or on
	 *     request failure.
	 */
	save: function() {
		var message = this.$('#push-message').val()

		if (message.length === 0) {
			return Promise.reject($.t('push.error.messageEmpty'))
		}

		if (lengthInUtf8Bytes(message) > PAYLOAD_LENGTH) {
			return Promise.reject($.t('push.error.length'))
		}

		var date    = this.datepicker_.getDate(),
			payload = this.model.get('payload'),
			url     = this.$('#push-page').val()

		if (url) {
			payload.url = url
		}

		payload.message = message
		payload.type = 'default'

		this.model.set({
			// priority: Number(this.$('#push-priority').val()),
			category: this.$('#push-category').val(),
			notes: this.$('#push-notes').val(),
			timestamp: Math.floor(date.getTime() / 1000),
			payload: payload
		})

		// Set tokens for custom notifications.
		if (this.model.get('category') === 'custom') {
			var tokens = this.$('#push-tokens').val().split('\n')
			this.model.set('tokens', tokens)
		}

		// Check region is set for region notifications.
		if (this.model.get('category') === 'region') {
			var region = this.model.get('region')

			if (!region) {
				return Promise.reject($.t('push.error.missingRegion'))
			}
		}

		return Promise.resolve(this.model.save())
	},

	/**
	 * Handles keypress events in the message input. Updates character count
	 * and trims the message if over the limit.
	 * @private
	 */
	messageKeypress_: function() {
		var text = this.$('#push-message').val(),
			url  = this.$('#push-page').val()

		var payload = {
			aps: {
				alert: text,
				badge: 0,
				sound: 'default'
			},
			payload: {
				id: 1234,
				type: 'default'
			}
		}

		if (url) {
			payload.payload.url = url
		}

		var payloadJSON   = JSON.stringify(payload),
			payloadLength = lengthInUtf8Bytes(payloadJSON)

		// Update char count
		this.$('.message-char-count').text('(' + (PAYLOAD_LENGTH - payloadLength) + ')')

		// Cap full push payload to 256 characters.
		if (payloadLength > PAYLOAD_LENGTH) {
			text = text.substr(0, text.length - (payloadLength - PAYLOAD_LENGTH))
			this.$('#push-message').val(text).trigger('input')
		}
	},

	/**
	 * Handles change events for the 'category' dropdown. Toggles region select
	 * as appropriate.
	 * @private
	 */
	categoryChange_: function() {
		var category = this.$('#push-category').val(),
			isCustom = category === 'custom',
			isRegion = category === 'region'

		this.$('.push-tokens').toggle(isCustom)

		if (isRegion) {
			this.$('.map-preview-group').removeClass('hidden')
			google.maps.event.trigger(this.map_, 'resize')
		} else {
			this.model.set('region', false)
			this.$('.map-preview-group').addClass('hidden')
		}
	},

	/**
	 * Initialises the region preview Google map.
	 * @private
	 */
	initGoogleMap_: function() {
		this.map_ = new google.maps.Map(this.$('.push-preview-map')[0], {
			disableDefaultUI: true,
			center: new google.maps.LatLng(0, 0),
			zoom: 5
		})

		this.renderRegionPreview_()
	},

	/**
	 * Handles click events on the Google map and shows the region draw modal.
	 * @private
	 */
	mapClick_: function() {
		if (this.views.boxDrawView_) {
			this.views.boxDrawView_.destroy()
		}

		this.views.boxDrawView_ = new BoxDrawView({model: this.model})

		$('body').append(this.views.boxDrawView_.render().el)
		this.views.boxDrawView_.show()
	},

	/**
	 * Draws polygons on map preview and fits bounds.
	 * @private
	 */
	renderRegionPreview_: function() {
		// Clear previous polygons.
		if (this.overlay_) {
			this.overlay_.setMap(null)
		}

		if (!this.model.get('region')) {
			return
		}

		// Draw new polygon
		var bounds = new google.maps.LatLngBounds(),
			region = this.model.get('region')

		var latLngs = region.coordinates[0].map(function(coord) {
			var latLng = new google.maps.LatLng(coord[1], coord[0])

			bounds.extend(latLng)
			return latLng
		})

		var map = this.map_

		// Construct the polygon.
		this.overlay_ = new google.maps.Polygon({
			paths: latLngs,
			strokeColor: '#F93A2F',
			strokeOpacity: 1,
			strokeWeight: 2,
			fillColor: '#F93A2F',
			fillOpacity: 0.28,
			map: map
		})

		map.fitBounds(bounds)
	}
})

/**
 * Calculates the number of UTF8 bytes in the input string.
 * @param {string} str String to evaluate.
 * @returns {number} The length of the string.
 * @see {@link
	*     https://stackoverflow.com/questions/5515869/string-length-in-bytes-in-javascript}
 */
function lengthInUtf8Bytes(str) {
	// Matches only the 10.. bytes that are non-initial characters in a
	// multi-byte sequence.
	var m = encodeURIComponent(str).match(/%[89ABab]/g)
	return str.length + (m ? m.length : 0)
}
