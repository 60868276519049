<div class="row">
	<form class="form-horizontal">
		<fieldset class="col-sm-8 edit-form">
			<legend>{{t "hazards.generalNotifications.createNotification"}}</legend>

			<!-- Checkboxes (Notification and cap) -->
			<div class="form-group">
				<label class="control-label col-sm-2" for="alert-notify">{{t "hazards.alerts.custom.notify"}}</label>
				<div class="col-sm-10">
					<label class="checkbox"><input type="checkbox" id="alert-notify" checked> {{t "hazards.alerts.custom.sendNotification"}}</label>
				</div>
			</div>

			<div class="form-group">
				<label class="control-label col-sm-2" for="alert-cap">{{t "hazards.alerts.custom.cap_create"}}</label>
				<div class="col-sm-10">
					<label class="checkbox"><input type="checkbox" id="alert-cap" checked> {{t "hazards.alerts.custom.cap_send"}}</label>
				</div>
			</div>
			<!-- End checkboxes -->

			<!-- Page -->
			<div class="form-group">
				<label class="control-label col-sm-2" for="alert-page">{{t "common.page"}}</label>
				<div class="col-sm-10">
					<select id="alert-page" class="form-control">{{{pageSelectOptions}}}</select>
					<p class="help-block">
						<span>{{t "hazards.alerts.custom.announcementPage"}}</span>
					</p>
				</div>
			</div>

			<!-- Alert language -->
			<div class="form-group">
					<label class="control-label col-sm-2" for="alert-language">{{t "common.language"}}</label>
					<div class="col-sm-10">
							<select id="alert-language" class="form-control">
							{{#each societyLanguageList}}
									<option value="{{code}}">{{name}}</option>
							{{/each}}
						</select>
					</div>
			</div>

			<!-- Headline -->
			<div class="form-group cap">
				<label class="control-label col-sm-2" for="alert-headline">{{t "hazards.generalNotifications.headline"}}</label>
				<div class="col-sm-10">
					<textarea id="alert-headline" class="form-control"></textarea>
				</div>
			</div>

			<!-- Alert message-->
			<div class="form-group">
				<label class="control-label col-sm-2" for="alert-message">{{t "push.message"}}</label>
				<div class="col-sm-10">
					<textarea id="alert-message" class="form-control"></textarea>
					<p class="help-block">{{t "hazards.alerts.custom.message"}}</p>
				</div>
			</div>

			<!-- Area picker -->
			<div class="form-group">
				<label class="control-label col-sm-2">{{t "hazards.alerts.custom.area"}}</label>
				<div class="col-sm-10">
					<div class="map-preview">
						<div class="alert-preview-map google-maps"></div>
						<div class="map-button"></div>
					</div>
					<p class="help-block">{{t "hazards.alerts.custom.areaHelp"}}</p>
					<label class="checkbox"><input type="checkbox" id="show-poly-data"> {{t "hazards.alerts.custom.showPolyData"}}</label>
					<div id="polywrapper" style="display:none;">
						<p class="help-block" style="display:none;">{{t "hazards.alerts.custome.polygonError"}}</p>
						<textarea id="alert-custom-polygon" class="form-control" placeholder="{{t "hazards.generalNotifications.customMapData.placeholder"}}"></textarea>
						<p class="help-block">{{t "hazards.alerts.custom.polygon"}}</p>
						<p class="help-block" id="map-error"></p>
						<button class="button green update-map">{{t "hazards.alerts.custom.updateMap"}}</button>
					</div>
				</div>
			</div>

			<!-- Area Description -->
			<div class="form-group cap">
				<label class="control-label col-sm-2" for="alert-area_description">{{t "hazards.generalNotifications.area_description"}}</label>
				<div class="col-sm-10">
					<textarea id="alert-area_description" class="form-control"></textarea>
				</div>
			</div>

			<!-- Event type -->
			<div class="form-group cap">
					<label class="control-label col-sm-2" for="alert-event">{{t "hazards.generalNotifications.event"}}</label>
					<div class="col-sm-10">
							<select id="alert-event" class="form-control">
							{{#each disasterList}}
									<optgroup label="{{localeName name ../currentLang ../societyLanguageList}}">
										{{#each events}}
											<option value="{{name.en}}">{{localeName name ../../currentLang ../../societyLanguageList}}</option>
										{{/each}}
									</optgroup>
							{{/each}}
						</select>
					</div>
			</div>

			<!-- Type -->
			<div class="form-group cap">
				<label class="control-label col-sm-2" for="alert-type">{{t "hazards.generalNotifications.type"}}</label>
				<div class="col-sm-10">
					<select id="alert-type" class="form-control">
						<option value="alert">{{t "hazards.generalNotifications.dropdown.alert"}}</option>
						<option value="update">{{t "hazards.generalNotifications.dropdown.update"}}</option>
						<option value="cancel">{{t "hazards.generalNotifications.dropdown.cancel"}}</option>
					</select>
				</div>
			</div>

			<!-- Status -->
			<div class="form-group cap">
				<label class="control-label col-sm-2" for="alert-status">{{t "hazards.generalNotifications.status"}}</label>
				<div class="col-sm-10">
					<select id="alert-status" class="form-control">
						<option value="actual">{{t "hazards.generalNotifications.dropdown.actual"}}</option>
						<option value="system">{{t "hazards.generalNotifications.dropdown.system"}}</option>
						<option value="test">{{t "hazards.generalNotifications.dropdown.test"}}</option>
					</select>
				</div>
			</div>

			<!-- Scope
			<div class="form-group">
				<label class="control-label col-sm-2" for="alert-scope">{{t "hazards.generalNotifications.scope"}}</label>
				<div class="col-sm-10">
					<select id="alert-scope" class="form-control">
						<option value="public">{{t "hazards.generalNotifications.dropdown.public"}}</option>
						<option value="restricted">{{t "hazards.generalNotifications.dropdown.restricted"}}</option>
						<option value="private">{{t "hazards.generalNotifications.dropdown.private"}}</option>
					</select>
				</div>
			</div> -->

			<!-- Category -->
			<div class="form-group cap">
				<label class="control-label col-sm-2" for="alert-category">{{t "hazards.generalNotifications.category"}}</label>
				<div class="col-sm-10">
					<select id="alert-category" class="form-control">
						<option value="geo">Geo</option>
						<option value="met">Met</option>
						<option value="safety">{{t "hazards.generalNotifications.dropdown.safety"}}</option>
						<option value="security">{{t "hazards.generalNotifications.dropdown.security"}}</option>
						<option value="rescue">{{t "hazards.generalNotifications.dropdown.rescue"}}</option>
						<option value="fire">{{t "hazards.generalNotifications.dropdown.fire"}}</option>
						<option value="health">{{t "hazards.generalNotifications.dropdown.health"}}</option>
						<option value="env">Env</option>
						<option value="transport">{{t "hazards.generalNotifications.dropdown.transport"}}</option>
						<option value="infra">infra</option>
						<option value="CBRNE">CBRNE</option>
						<option value="other">{{t "hazards.generalNotifications.dropdown.other"}}</option>
					</select>
				</div>
			</div>

			<!-- Urgency -->
			<div class="form-group cap">
				<label class="control-label col-sm-2" for="alert-urgency">{{t "hazards.generalNotifications.urgency"}}</label>
					<div class="col-sm-10">
						<select id="alert-urgency" class="form-control">
							<option value="immediate">{{t "hazards.generalNotifications.dropdown.immediate"}}</option>
							<option value="expected">{{t "hazards.generalNotifications.dropdown.expected"}}</option>
							<option value="future">{{t "hazards.generalNotifications.dropdown.future"}}</option>
							<option value="past">{{t "hazards.generalNotifications.dropdown.past"}}</option>
						</select>
					</div>
      </div>

			<!-- Severity -->
			<div class="form-group cap">
				<label class="control-label col-sm-2" for="alert-severity">{{t "hazards.generalNotifications.severity"}}</label>
				<div class="col-sm-10">
					<select id="alert-severity" class="form-control">
						<option value="extreme">{{t "hazards.generalNotifications.dropdown.extreme"}}</option>
						<option value="severe">{{t "hazards.generalNotifications.dropdown.severe"}}</option>
						<option value="moderate">{{t "hazards.generalNotifications.dropdown.moderate"}}</option>
						<option value="minor">{{t "hazards.generalNotifications.dropdown.minor"}}</option>
					</select>
				</div>
			</div>

			<!-- Certainty -->
			<div class="form-group cap">
				<label class="control-label col-sm-2" for="alert-certainty">{{t "hazards.generalNotifications.certainty"}}</label>
				<div class="col-sm-10">
					<select id="alert-certainty" class="form-control">
						<option value="observed">{{t "hazards.generalNotifications.dropdown.observed"}}</option>
						<option value="likely">{{t "hazards.generalNotifications.dropdown.likely"}}</option>
						<option value="possible">{{t "hazards.generalNotifications.dropdown.possible"}}</option>
						<option value="unlikely">{{t "hazards.generalNotifications.dropdown.unlikely"}}</option>
					</select>
				</div>
			</div>

			<!-- Effective date-->
			<div class="form-group">
				<label class="control-label col-sm-2" for="alert-effective">{{t "hazards.alerts.custom.effective"}}</label>
				<div class="col-sm-10">
					<input type="text" id="alert-effective" class="form-control">
					<p class="help-block">{{t "hazards.alerts.custom.effectiveHelp"}}</p>
				</div>
			</div>

			<!-- Onset date -->
			<div class="form-group">
				<label class="control-label col-sm-2" for="alert-onset">{{t "hazards.alerts.custom.onset"}}</label>
				<div class="col-sm-10">
					<input type="text" id="alert-onset" class="form-control">
					<p class="help-block">{{t "hazards.alerts.custom.onsetHelp"}}</p>
				</div>
			</div>

			<!-- Expiry date -->
			<div class="form-group">
				<label class="control-label col-sm-2" for="alert-expiry">{{t "hazards.alerts.custom.expiry"}}</label>
				<div class="col-sm-10">
					<input type="text" id="alert-expiry" class="form-control">
					<p class="help-block">{{t "hazards.alerts.custom.expiryHelp"}}</p>
				</div>
			</div>

		</fieldset>
	</form>

	<!-- -->
	<div class="col-sm-5 alert-preview hidden">
		<div class="display-preview">
			<h1>20:50</h1>
			<div><b>Hazards</b> now</div>
			<p class="alert-preview-text"></p>
			<div><small>slide to view</small></div>
			<h4>Slide to unlock</h4>
		</div>
	</div>
	<div class="col-sm-7 alert-preview hidden">
		<div class="canvas-area"></div>
	</div>
	<div class="col-sm-5 alert-preview hidden"></div>
	<div class="col-sm-4 actions-col">
		<a href="javascript:history.go(-1)"><button class="button white">{{t "common.cancel"}}</button></a>
		<button class="button green preview-button">{{t "common.next"}}</button>
		<button class="button white preview-back-button hidden">{{t "common.back"}}</button>
		<button class="button green save-button hidden">{{t "push.saveAndSend"}}</button>
	</div>
</div>
