var EditorSectionView = require('editor/editor-section-view'),
	StandaloneStormObject = require('editor/standalone-storm-object'),
	StormQL = require('models/stormql')

/**
 * Uses the search enpoint to get all the R4b_Unit objects
 * @type {Backbone.Collection}
 */
var AssessmentModuleList = Backbone.Collection.extend({
	url: App.apiRoot + 'objects/search/'
})

module.exports = EditorSectionView.extend({
	className: 'admin',
	template: require('./admin-view-template'),
	activeTabIndex: 4,

	/** @override */
	events: {
		'click .add': 'addModule',
		'click .module-delete': 'removeModule',
		'click .level-delete': 'removeLevel',
		'change .badges': 'updateLevel',
		'input .colour-pick': 'updateLevel',
		'click .add-level': 'addLevel',
		'click .edit-levels': 'toggleLevelsEdit',
		'click .close-edit-levels': 'toggleLevelsEdit'
	},

	initialize: function(options) {
		App.startLoad()
		this.app = App.appList.get(options.appId)
		this.appId = options.appId

		this.requests()
	},

	requests: function() {
		var requests = []

		// Levels
		this.levelList = new StormQL(null, {app: this.app})
		requests.push(this.levelList.fetch({data: {class: 'R4b_Level'}}))

		// Badges
		this.badgeList = new StormQL(null, {app: this.app})
		requests.push(this.badgeList.fetch({data: {class: 'Badge'}}))

		// Fetch units
		this.units = new AssessmentModuleList()
		requests.push(this.units.fetch({
			type: 'POST',
			data: JSON.stringify({
				class: "R4b_Unit",
				appId: this.appId,
				inherit: true,
				type: "cms"
			})
		}))

		// Render page once all data loaded.
		Promise.all(requests).then(this.ready.bind(this))
	},

	ready: function() {
		App.stopLoad()
		this.mapToLevels()
		this.setCounts()
		this.render()
	},

	getPageTitle: function() {
		return $.t('r4b.title')
	},

	getRenderData: function() {
		return {
			appId: this.appId,
			levels: this.levels,
			levelList: this.levelList.toJSON(),
			badges: this.badgeList.toJSON(),
			levelCount: Handlebars.helpers.countNumOfProps(this.levels)
		}
	},

	afterRender: function() {
		// Hide developer-only controls.
		if (!App.developerMode) {
			this.$('.developer-mode').remove()
		}

		var self = this
		$('.colour-visual').each(function() {
			var id = $(this).attr('data-id')
			$(this).colorpicker({
				align: 'left',
				color: $(this).attr('data-rgba')
			}).on('hidePicker', function(e) {
				self.setUIColours(id, e.color.toRGB())
			})
		})
	},

	// Set UI Colours when the picker has selected
	setUIColours: function(id, rgba) {
		$('.colour-pick[data-id=' + id + '][data-colour="r"]').val(rgba.r)
		$('.colour-pick[data-id=' + id + '][data-colour="g"]').val(rgba.g)
		$('.colour-pick[data-id=' + id + '][data-colour="b"]').val(rgba.b)
		$('.colour-pick[data-id=' + id + '][data-colour="a"]').val(rgba.a)
		this.setLevelValues(id)
	},

	/**
	 * Map modules and assessments into one array (this.levels) that is defined by levels
	 * @returns {undefined}
	 */
	mapToLevels: function() {
		this.levels = {}

		this.units.forEach(function(unit) {
			var level = unit.get('level')

			if (this.levels[level]) {
				this.levels[level].push(unit.toJSON())
			} else {
				this.levels[level] = [unit.toJSON()]
			}
		}.bind(this))

		this.levelList.forEach(function(level, index) {
			level.set('units', this.levels[index])
			level.set('hasAssessment', this.hasAssessment(index + 1))
		}.bind(this))
	},

	/**
	 * Set the content block counts and language counts for the modules and assessments.
	 * @returns {undefined}
	 */
	setCounts: function() {
		this.levelList.forEach(function(level) {
			if (level.get('units')) {
				level.get('units').forEach(function(item) {
					item.set('contentBlockLength', item.get('children').length)
					// if (item.get('children').children.length) {
					// 	item.set('languageLength', Handlebars.helpers.countNumOfProps(item.children[0].children[0].text.content))
					// } TODO: Language Length
				})
			}
		})
	},

	/**
	 * Get the count for the number of questions in an assessment
	 * @param  {R4b_Assessment} assessment Assessment
	 * @returns {Integer} Number of questions
	 */
	getAssessmentQuestionCount: function(assessment) {
		var questionCount = 0
		if (assessment.children.length) {
			_.forEach(assessment.children, function(children) {
				_.forEach(children.children, function(question) {
					if (question.class === "R4b_QuestionAssessmentBlock" || question.class === "R4b_InputAssessmentBlock") {
						++questionCount
					}
				})
			})
		}
		return questionCount
	},

	/**
	 * Adds a module to the view and to the backend
	 * @param {event} e Event
	 */
	addModule: function(e) {
		var className = $(e.currentTarget).attr('data-class')
		var levelNo = parseInt($(e.currentTarget).data('level'), 10)
		var model = StandaloneStormObject.fromClassName(className)
		if (className === "R4b_Module" || (className === 'R4b_Assessment' && !this.hasAssessment(levelNo + 1))) {
			model.set('level', levelNo)
			App.startLoad()
			model.save(null, {appId: this.appId}).then(function() {
				this.requests()
			}.bind(this))
		}
	},

	/**
	 * Does a level have an assessment in it?
	 * @param  {number}  levelNo Number of level (Not Index)
	 * @returns {Boolean}         Returns True if it exists
	 */
	hasAssessment: function(levelNo) {
		var isAssessment = false
		// Get level fron index
		var level = this.levelList.at(levelNo - 1)
		if (level.get('units')) {
			level.get('units').forEach(function(item) {
				if (item.get('class') === "R4b_Assessment") {
					isAssessment = true
				}
			})
		}
		return isAssessment
	},

	/**
	* Removes a module from the backend
	* @param {event} e Event
	 */
	removeModule: function(e) {
		e.preventDefault()
		e.stopPropagation()
		var id = $(e.currentTarget).data('id')

		this.removeModel(id, function() {
			// Remove module from dom.
			this.requests()
			App.stopLoad()
		}.bind(this))
	},

	/**
	 * Adds a module to the view and to the backend
	 * @param {event} e Event
	 */
	addLevel: function() {
		var model = StandaloneStormObject.fromClassName('R4b_Level')
		model.set('identifier', this.levelList.length)
		App.startLoad()
		model.save(null, {appId: this.appId}).then(function() {
			this.requests()
		}.bind(this))
	},

	/**
	* Updates a level
	* @param {event} e Event
	 */
	updateLevel: function(e) {
		var id = $(e.currentTarget).data('id')
		this.setLevelValues(id)
	},

	setLevelValues: function(id) {
		var model = this.levelList.get(id)
		// Colours
		var r = parseInt($('.colour-pick[data-id=' + id + '][data-colour="r"]').val(), 10),
			g = parseInt($('.colour-pick[data-id=' + id + '][data-colour="g"]').val(), 10),
			b = parseInt($('.colour-pick[data-id=' + id + '][data-colour="b"]').val(), 10),
			a = parseInt($('.colour-pick[data-id=' + id + '][data-colour="a"]').val(), 10),
			badgeId = "-"
		if ($('.badges[data-id=' + id + ']').val() !== "-") {
			badgeId = parseInt($('#badges-' + id).val(), 10)
		}
		if (model.get('colour..r') !== r || model.get('colour..r') !== g || model.get('colour..r') !== b || model.get('colour..r') !== a || model.get('badgeId') !== badgeId) {
			model.set('colour..r', r)
			model.set('colour..g', g)
			model.set('colour..b', b)
			model.set('colour..a', a)
			var level = $('.span-colour[data-id=' + id + ']').data('level')
			// Update span
			$('.colour-visual[data-id=' + id + ']').attr('style', Handlebars.helpers.getColourStyle(model.get('colour')))
			// Update Cards
			$('.module-card[data-level=' + level + ']').attr('style', Handlebars.helpers.getColourStyle(model.get('colour')))
			$('.module-card[data-level=' + level + '] i').attr('style', 'color:' + Handlebars.helpers.getRGBAString(model.get('colour')) + ';')
			$('.module-card[data-level=' + level + '] .bottom-row').attr('style', 'border-bottom:' + Handlebars.helpers.getRGBAString(model.get('colour')) + '5px solid;')

			// Badge ID
			if (badgeId !== "-") {
				model.set('badgeId', badgeId)
			}
			// Save
			model.needsSaving = true
			if (!model.saving) {
				this.saveLevel(model)
			}
		}
	},

	// Save the level only if the level is not saving.. if it still needs saving during the request the save runs again.
	saveLevel: function(level) {
		level.unset('units') // This is only used on the UI in the handlebars template, remove it before save.
		level.unset('contentBlockLength') // This is only used on the UI in the handlebars template, remove it before save.
		level.unset('hasAssessment') // This is only used on the UI in the handlebars template, remove it before save.

		var self = this
		level.saving = true
		level.needsSaving = false
		level.requestLock(function() {
			level.save(null, {appId: this.appId}).then(function() {
				level.requestUnlock().then(function() {
					if (level.needsSaving) {
						// Change made during save, save again!
						self.saveLevel(level)
					} else {
						level.saving = false
					}
				})
			})
		})
	},

	// Remove a level
	removeLevel: function(e) {
		e.preventDefault()
		e.stopPropagation()
		var id = $(e.currentTarget).data('id')
		this.removeModel(id, this.requests.bind(this))
	},

	// Remove a model (Level or Module/Assessment)
	removeModel: function(id, callback) {
		if (id) {
			swal({
				title: $.t('editor.inspector.areYouSure'),
				text: $.t('editor.inspector.confirmDelete'),
				showCancelButton: true
			}, function(didConfirm) {
				if (didConfirm) {
					var model = StandaloneStormObject.fromProperties({id: id})
					model.fetch().then(function() {
						model.requestLock(function() {
							App.startLoad()
							model.destroy().then(callback)
						})
					})
				}
			})
		}
	},

	// Hide / Show the levels view
	toggleLevelsEdit: function() {
		var levels = $(".levels, .add-level, .close-edit-levels"),
			editButton = $(".edit-levels")

		if (levels.is(':visible')) {
			levels.hide(100)
			editButton.show()
		} else {
			levels.show(100)
			editButton.hide()
		}
	}
})
