var StormObject = require('./storm-object')

module.exports = Backbone.View.extend({
	template: require('./save-state-view-template'),
	className: 'save-state',

	startSaving: function() {
		this.updateQueueCount()
		this.$('.saving').show()
		this.$('.last-saved').hide()
	},

	stopSaving: function() {
		var date = new Date(),
			time = date.toLocaleTimeString()

		this.$('.saving').hide()
		this.$('.last-saved').show().find('.last-saved-date').text(time)
	},

	setQueueCount: function(count) {
		this.$('.queue-count').text(count)
		this.$('.queue-info').toggle(count > 0)
	},

	updateQueueCount: function() {
		this.setQueueCount(StormObject._saveQueue.length)
	}
})
