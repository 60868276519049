<div class="modal-header">
	<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
	<h4 data-i18n="users.createUser"></h4>
</div>
<div class="modal-body">
	<form class="stage1">
		<div class="form-group">
			<label class="control-label" for="new-email" data-i18n="agreement.email"></label>
			<input type="email" class="form-control" id="new-email" required>
		</div>

		<input type="submit" style="display:none">
	</form>
	<form class="stage2" style="display:none">
		<div class="form-group">
			<label for="new-username" data-i18n="common.username"></label>
			<div class="input-append">
				<input type="text" class="form-control" id="new-username" required>
				<span class="add-on"><i class="username-status username-error icon-remove"></i></span>
			</div>
			<p class="help-block" data-i18n="users.usernameHelp"></p>
		</div>
		<div class="form-group">
			<label for="new-firstname" data-i18n="users.firstName"></label>
			<input type="text" class="form-control" id="new-firstname" required>
		</div>
		<div class="form-group">
			<label for="new-lastname" data-i18n="users.lastName"></label>
			<input type="text" class="form-control" id="new-lastname" required>
		</div>

		<input type="submit" style="display:none">
	</form>
	<form class="stage2-gdpc" style="display:none">
		<div class="form-group">
			<label for="new-job-title" data-i18n="users.organisation"></label>
			<input type="text" class="form-control" id="new-job-title" required>
		</div>

		<input type="submit" style="display:none">
	</form>
	<form class="stage3" style="display:none">
		<div class="form-group">
			<label for="new-role-group" data-i18n="users.role"></label>
			<select class="form-control" id="new-role-group" required></select>
		</div>
		<div class="form-group">
			<label data-i18n="apps.title"></label>
		</div>
		<div class="app-assign-list form-group">
			{{#each societies}}
				<h4>{{description}}</h4>
				{{#each apps}}
					<div class="checkbox">
						<label><input type="checkbox" class="app-assign-checkbox" value="{{id}}"> {{getLocaleText name}}</label>
					</div>
				{{/each}}
			{{/each}}
		</div>

		<input type="submit" style="display:none">
	</form>
	<div class="complete" style="display:none">
		<div class="alert alert-success">
			<h4 data-i18n="users.complete"></h4>
		</div>
	</div>
	<div class="col-xs-12 actions-col">
		<div class="stage1">
			<button class="button red" data-dismiss="modal" data-i18n="common.cancel"></button>
			<button class="button green stage1-button" data-i18n="common.next"></button>
		</div>
		<div class="stage2" style="display:none">
			<button class="button red" data-dismiss="modal" data-i18n="common.cancel"></button>
			<button class="button green stage2-button" disabled data-i18n="common.next"></button>
		</div>
		<div class="stage2-gdpc" style="display:none">
			<button class="button red" data-dismiss="modal" data-i18n="common.cancel"></button>
			<button class="button green stage2-gdpc-button" data-i18n="common.next"></button>
		</div>
		<div class="stage3" style="display:none">
			<button class="button red" data-dismiss="modal" data-i18n="common.cancel"></button>
			<button class="button green stage3-button" data-i18n="common.finish"></button>
		</div>
		<div class="complete" style="display:none">
			<button class="button green" data-dismiss="modal" data-i18n="common.close"></button>
		</div>
	</div>
</div>
