<div class="login-container">
	<div class="login-inner update">
		<div class="logo"></div>

		<form class="update-form">
			<div class="form-group">
				<input type="password" class="form-control input-lg confirm" data-i18n="[placeholder]login.reset.newPassword">
			</div>
			<div class="form-group">
				<input type="password" class="form-control input-lg password" data-i18n="[placeholder]login.reset.confirm">
			</div>
			<div class="message error-message" data-i18n="login.error.body"></div>
			<div class="form-group">
				<button class="button med block" type="submit" disabled>
					<i></i>
					<span data-i18n="login.reset.submit"></span>
					<span class="login-timeout"></span>
				</button>
			</div>
		</form>
		<div class="message update-success-message" data-i18n="login.reset.success"></div>
	</div>
</div>
