var EditorSectionView = require('editor/editor-section-view'),
	Tip               = require('./tip'),
	TipList           = require('./tip-list'),
	TipAnswer         = require('./tip-answer'),
	PageList          = require('editor/page-list/page-list')

/**
 * Exports {@link TipEditView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends TipEditView.prototype */{
	/** @override */
	template: require('./tip-edit-view-template'),

	/** @override */
	events: {
		'click .save-button': 'saveButtonClick',
		'change #tip-object-type': 'tipObjectTypeChange',
		'click .add-answer-button': 'addAnswerButtonClick',
		'click .delete-answer-button': 'deleteAnswerButtonClick'
	},

	/**
	 * @constructs TipEditView
	 * @extends Backbone.View
	 * @override
	 */
	initialize: function(options) {
		/** @private @type StormApp */
		this.app_ = options.app
		/** @override @type Tip */
		this.model = new Tip()
		/** @private @type PageList */
		this.pageList_ = new PageList(null, {appId: this.app_.id})
		/** @private @type TipList */
		this.tipList_ = new TipList()

		App.startLoad()
		var requests = [
			this.pageList_.fetch(),
			this.tipList_.fetch()
		]

		if (options.tipId !== 'new') {
			this.model.set('id', options.tipId)
		}

		$.when.apply($, requests)
			.then(this.dataLoaded.bind(this))
	},

	/** @override */
	getRenderData: function() {
		return {
			pageSelectOptions: this.getPageSelectOptions(),
			tip: this.model.toJSON()
		}
	},

	/** @override */
	afterRender: function() {
		// Set values for select/date inputs.
		this.$('#tip-object-type').val(this.model.get('objectType'))
		this.$('#tip-content').val(this.model.get('content'))
		this.$('#tip-display').val(this.model.get('display'))

		var publishDate = new Date(this.model.get('publishDate'))
		var expiryDate = new Date(this.model.get('expiryDate'))

		setDateInputValue(this.$('#tip-publish-date')[0], publishDate)
		setDateInputValue(this.$('#tip-expiry-date')[0], expiryDate)

		// Set answers form visibility based on object type.
		var isQuestionTip = this.model.get('objectType') === 'QuestionTip'

		this.$('#answers-form').toggleClass('hidden', !isQuestionTip)
		this.$('.tip-content-container').toggleClass('hidden', isQuestionTip)
	},

	/**
	 * Constructs the list of HTML select options for all content pages, grouped
	 * by tag.
	 * @returns {string} String of HTML options.
	 * @private
	 */
	getPageSelectOptions: function() {
		var pageSelect = '<option value="">-</option>'

		// Get array of all unique tag names
		var allTags = this.pageList_.pluck('tag'),
			tags    = _.filter(allTags, function(elem, pos, list) {
				return list.indexOf(elem) === pos
			}).sort()

		// Output an option group for each tag
		_.each(tags, function(tag) {
			var taggedPages = this.pageList_.where({tag: tag}),
				$optgroup = $('<optgroup>').attr('label', tag)

			// Output an option for each page with this tag
			_.each(taggedPages, function(page) {
				var $option = $('<option>')
					.val('cache://pages/' + page.id + '.json')
					.text(App.l(page.get('title')))

				$optgroup.append($option)
			})

			pageSelect += $optgroup.prop('outerHTML')
		}, this)

		return pageSelect
	},

	/**
	 * Handles {@link PageList}/{@link TipList} fetch completion and re-renders
	 * the view.
	 */
	dataLoaded: function() {
		// If we're editing an existing tip, set the model from the fetched
		// list.
		if (this.model.id !== undefined) {
			this.model = this.tipList_.get(this.model.id)
		}

		this.render()
		App.stopLoad()
	},

	/**
	 * Handles change events from the object type select. Toggles visibility of
	 * the answers form.
	 */
	tipObjectTypeChange: function() {
		var type = this.$('#tip-object-type').val(),
			isQuestionTip = type === 'QuestionTip'

		this.$('#answers-form').toggleClass('hidden', !isQuestionTip)
		this.$('.tip-content-container').toggleClass('hidden', isQuestionTip)
	},

	/**
	 * Handles clicks to the add answer button, updating the model from the view
	 * and re-rendering.
	 * @returns {boolean} false, to prevent form submission.
	 */
	addAnswerButtonClick: function() {
		var answerTitle = this.$('.add-answer-title').val()

		if (answerTitle) {
			this.model.attributes.answers = this.model.attributes.answers || []
			this.model.get('answers').push({title: answerTitle})
			this.updateModelFromView()
			this.render()
			this.$('.add-answer-title').focus()
		}

		return false
	},

	/**
	 * Removes the specified answer. If the answer has already been saved (has
	 * an ID), delete it from the server. If not, remove it locally.
	 * @param {MouseEvent} e The Event object from the click.
	 */
	deleteAnswerButtonClick: function(e) {
		var index = $(e.currentTarget).data('index')
		var answer = this.model.get('answers')[index]

		if (answer.id !== undefined) {
			var answerModel = new TipAnswer(answer, {tipId: this.model.id})

			answerModel.destroy()
		}

		this.model.get('answers').splice(index, 1)
		this.render()
	},

	/** Updates all model properties based on user input to the view. */
	updateModelFromView: function() {
		var publishDateValue  = this.$('#tip-publish-date')[0].value,
			expiryDateValue   = this.$('#tip-expiry-date')[0].value,
			publishDateString = null,
			expiryDateString  = null

		if (publishDateValue) {
			var publishDate = new Date(publishDateValue)

			publishDateString = getISOStringWithoutMillis(publishDate)
		}

		if (expiryDateValue) {
			var expiryDate = new Date(expiryDateValue)

			if (expiryDate !== null) {
				expiryDateString = getISOStringWithoutMillis(expiryDate)
			}
		}

		var data = {
			objectType: this.$('#tip-object-type').val(),
			title: this.$('#tip-title').val(),
			subtitle: this.$('#tip-subtitle').val(),
			buttonTitle: this.$('#tip-button-title').val(),
			content: this.$('#tip-content').val() || null,
			display: this.$('#tip-display').val(),
			minMood: Number(this.$('#tip-min-mood').val()),
			maxMood: Number(this.$('#tip-max-mood').val()),
			publishDate: publishDateString,
			expiryDate: expiryDateString
		}

		if (data.objectType === 'QuestionTip') {
			// "content" not allowed for questions.
			data.content = null
		} else {
			// "answers" only allowed for questions.
			data.answers = null
		}

		this.model.set(data)
	},

	/**
	 * Handles the click event from the save button. Validates and saves data.
	 */
	saveButtonClick: function() {
		this.updateModelFromView()

		if (!this.model.isValid()) {
			swal({
				title: $.t('error.oops'),
				text: this.model.validationError,
				type: 'error'
			})

			return
		}

		App.startLoad()
		this.tempAnswers = this.model.get('answers') || []
		this.model.save()
			.then(this.saveAnswers.bind(this))
	},

	/** Saves any new question answers. */
	saveAnswers: function() {
		if (this.model.get('objectType') !== 'QuestionTip') {
			this.saveCompleted()
		} else {
			var requests = []

			this.tempAnswers.forEach(function(answer) {
				if (answer.id === undefined) {
					var answerModel = new TipAnswer(answer, {
						tipId: this.model.id
					})

					requests.push(answerModel.save())
				}
			}, this)

			$.when.apply(requests).then(this.saveCompleted.bind(this))
		}
	},

	/** Handles tip save completion and redirects back to the Tips view. */
	saveCompleted: function() {
		App.router.navigate('/apps/' + this.app_.id + '/tips', {trigger: true})
	}
})

/**
 * Strips the fractional seconds from the JS ISO timestamp, as PHP doesn't
 * support it.
 * @param {Date} date The date object from which to extract the timestamp.
 * @returns {string} Date string in ISO format, without fractional seconds.
 */
function getISOStringWithoutMillis(date) {
	if (!date) {
		return ''
	}

	return date.toISOString().replace(/\.\d{3}/, '')
}

/**
 * Sets the value of the specified input to the specified date, without the use
 * of valueAsDate.
 * @param {!HTMLInputElement} input The date input to set the value of.
 * @param {!Date} date The Date object to set the input's value to.
 */
function setDateInputValue(input, date) {
	if (date.toJSON() === null) {
		input.value = ''
		return
	}

	var local = new Date(date)

	local.setMinutes(date.getMinutes() - date.getTimezoneOffset())
	input.value = local.toJSON().slice(0, 10)
}
