var AppList           = require('editor/app-list'),
	AppListItem       = require('./user-app-list-item-view'),
	ManageSectionView = require('manage/manage-section-view')

module.exports = ManageSectionView.extend({
	template: require('./user-apps-view-template'),

	getPageTitle: function() {
		return $.t('userApps.title')
	},

	events: {
		'click .assign-button': 'assign',
		'click .unassign': 'unassign'
	},

	initialize: function(options) {
		this.listViews = []

		this.collection = new AppList(null, {id: options.id})
		this.collection.on('add', this.addListItem, this)
		this.collection.once('sync', this.ready, this)
		this.collection.fetch()

		this.allApps = new AppList(null, {id: 'all'})
		this.allApps.once('sync', this.allAppsLoaded, this)
		this.allApps.fetch()
	},

	allAppsLoaded: function() {
		// Sort and render app select.
		var sorted = this.allApps.sortBy(function(app) {
			var society = App.societiesList.get(app.get('societyId'))

			if (!society) {
				return ''
			}

			app.set('societyName', society.get('name'))

			return society.get('name')
		})

		// Remove any apps already assigned to this user.
		for (var i = 0; i < sorted.length; i++) {
			var app = sorted[i]

			if (this.collection.get(app.id)) {
				sorted.splice(i, 1)
				i--
			}
		}

		var options = ''

		sorted.forEach(function(item) {
			options += '<option value="' + item.id + '">' + item.get('societyName') + ': ' + App.l({content: item.get('name')}) + '</option>'
		})

		this.$('.all-apps').html(options)
	},

	ready: function() {
		this.allAppsLoaded()
		App.stopLoad()
	},

	getRenderData: function() {
		var currentApp = App.getCurrentApp(),
			appId

		if (currentApp) {
			appId = currentApp.id
		}

		return {
			appId: appId
		}
	},

	afterRender: function() {
		if (!App.acl.hasWritePermission('Users', 'App assignment')) {
			this.$('.assign').hide()
		}
	},

	addListItem: function(item) {
		item.view = this.addView(new AppListItem({model: item}))
		this.$('.list-items').append(item.view.render().el)
	},

	assign: function() {
		var id  = parseInt(this.$('.all-apps').val(), 10),
			app = this.allApps.get(id)

		if (!app) {
			return
		}

		// Add to local list
		this.collection.push(app.toJSON())

		// Sync with server
		this.sync('update', id)

		// Remove from assignment dropdown.
		this.allAppsLoaded()
	},

	unassign: function(e) {
		var id = parseInt($(e.currentTarget).data('id'), 10)
		var app = this.collection.get(id)

		// Remove from local list
		app.view.destroy()
		this.collection.remove(app)

		// Sync with server
		this.sync('delete', id)

		// Update assignment dropdown.
		this.allAppsLoaded()
	},

	sync: function(method, id) {
		var model = {apps: [{id: id}]}

		Backbone.sync(method, new Backbone.Model(), {
			url: this.collection.url(),
			data: JSON.stringify(model),
			contentType: 'application/json'
		})
	}
})
