var UploadList    = require('./upload-list'),
	TagList       = require('./tag-list'),
	ImageItem     = require('./media-library-image-item-view'),
	VideoItem     = require('./media-library-video-item-view'),
	MediaEditView = require('./media-edit-view')

var PAGE_SIZE                = 20,
	TYPE_SELECT_ACTIVE_CLASS = 'selected',
	DRAGGING_CLASS           = 'dragging'

var MediaLibrary = Backbone.View.extend({
	template: require('./media-library-view-template'),
	className: 'media-library',

	events: {
		'click .type-filter li': 'typeFilterButtonClick',
		'change .tag-filter .as-values': 'filterChange',

		'click .upload-button': 'uploadButtonClick',
		'click .more-button': 'loadMoreImages',

		'click .file': 'uploadClick',
		'click .save-button': 'save',
		'click .close-button': 'close',
		'dblclick .file': 'save',

		'click .tag-button': 'addTagFilter',
		'click .image-edit-link': 'editMedia',

		'dragover': 'dragOver_',
		'dragleave': 'dragLeave_',
		'drop': 'drop_'
	},

	initialize: function(options) {
		if (!options.app) {
			throw new Error('No app specified')
		}

		App.startLoad()

		this.listViews = []
		this.filters = []
		this.app_ = options.app
		this.mediaType = options.mediaType
		this.collection = new UploadList()
		this.totalImages = 0
		this.imagesLoaded = 0
		this.fetching = false

		if (App.mediaCache) {
			// Use cached tags list if available
			this.tagList_ = App.mediaCache.tags
			this.tagListCached = true
		} else {
			App.mediaCache = {}

			// Fetch and cache list of all file tags
			this.tagList_ = App.mediaCache.tags = new TagList({type: 'files'})
			this.tagList_.fetch({data_: {type: 'files'}})
			this.tagList_.once('sync', this.tagsFetched, this)
		}

		// Get name of current model's page
		var pageName

		if (this.model) {
			var model = this.model

			if (this.model instanceof Backbone.Collection) {
				model = this.model.parent
			}

			var pageList = this.app_.pageList

			if (pageList.length) {
				var pageId = (model.get) ? model.get('pageId') : model.pageId
				var page = pageList.get(pageId)

				if (page) {
					var title = page.get('title')
					pageName = App.l(title)
				}
			}
		}

		// Set up child views.
		var MediaUploadView = require('./media-upload-view')

		this.views = {
			uploadView: new MediaUploadView({
				app: this.app_,
				collection: this.collection,
				tagList: this.tagList_,
				restrictType: (this.model !== undefined),
				pageName: pageName
			})
		}

		this.listenTo(this.views.uploadView, 'select', this.uploadViewSelectHandler_)
		this.listenTo(this.views.uploadView, 'close', this.childViewClose)

		this.listenTo(this.collection, 'sync error', this.uploadsFetched)
		this.listenTo(this.collection, 'add', this.addFile)
		this.listenTo(this.collection, 'remove', this.removeFile)
		this.listenTo(this.collection, 'upload', this.doneUpload)
	},

	tagsFetched: function() {
		var tags = this.tagList_.map(function(tag) {
			return {value: tag.get('name')}
		})

		// AutoSuggest requires at least one tag to be initialised.
		if (!tags.length) {
			tags.push({value: 'Test'})
		}

		// Set up tag input autocomplete
		this.$('.tags').autoSuggest(tags, {
			startText: $.t('mediaLibrary.filterByTags')
		})

		// Fetch initial media.
		this.fetchFilteredList()
		App.stopLoad()
	},

	uploadsFetched: function(collection, response, options) {
		var range = options.xhr.getResponseHeader('Content-Range')

		if (range) {
			this.totalImages = parseInt(range.match(/.*\/(\d+)/)[1], 10)
		}

		this.fetching = false
		this.stopLoad()
	},

	getRenderData: function() {
		if (this.model) {
			return (this.model.toJSON) ? this.model.toJSON : this.model
		}

		return {}
	},

	afterRender: function() {
		// Bind scroll event for infinite scrolling.
		this.$('.media-list-col').on('scroll', _.throttle(this.scroll, 50).bind(this))

		if (this.mediaType === undefined) {
			// Set initial tab to images.
			this.$('.type-filter li[data-type=0]').addClass(TYPE_SELECT_ACTIVE_CLASS)

			// Don't show save button - we have nothing to save.
			this.$('.save-button').hide()
		} else {
			// Media library being used as a selector - limit to the specified
			// type.
			this.$('.type-filter li').hide()
			this.$('.type-filter li[data-type=' + this.mediaType + ']').addClass(TYPE_SELECT_ACTIVE_CLASS).show()
			if (this.mediaType <= 1) {
				// Show Image and Icon if either Image or Icon
				this.$('.type-filter li[data-type=0]').show()
				this.$('.type-filter li[data-type=1]').show()
			}
			this.$('.type-filter li[data-type=4]').show()
		}

		if (this.tagListCached) {
			this.tagsFetched()
		}
	},

	childViewClose: function() {
		this.$('.media-selector').show()
	},

	/**
	 * Handles click events to type filter buttons and sets the new type
	 * filter.
	 * @param {MouseEvent} e Event object containing details of the click.
	 */
	typeFilterButtonClick: function(e) {
		this.$('.type-filter li').removeClass(TYPE_SELECT_ACTIVE_CLASS)
		$(e.currentTarget).addClass(TYPE_SELECT_ACTIVE_CLASS)

		var mediaType = $(e.currentTarget).data('type')

		// Show tags view
		if (mediaType === 4) {
			this.$('.image-list').hide()
			this.$('.tag-list').show()
		} else {
			this.$('.image-list').show()
			this.$('.tag-list').hide()

			// Fetch new upload list with this type.
			this.fetchFilteredList()
		}
	},

	uploadButtonClick: function() {
		this.views.uploadView.chooseButtonClick()
	},

	uploadClick: function(e) {
		var $selectedFile = $(e.currentTarget)

		// Set selected state if we have a model.
		if (this.model) {
			this.$('.image-list .file.selected').removeClass('selected')
			$selectedFile.addClass('selected')
		}

		// Trigger 'select' event with new file[s].
		var fileId = $selectedFile.data('id'),
			file   = this.collection.get(fileId)

		var type

		if ($selectedFile.hasClass('animation')) {
			type = MediaLibrary.types.ANIMATION
		} else if ($selectedFile.hasClass('image')) {
			type = MediaLibrary.types.IMAGE
		} else if ($selectedFile.hasClass('video')) {
			type = MediaLibrary.types.VIDEO
		} else {
			throw new Error('Unknown media type')
		}

		this.trigger('select', file, type)
	},

	/**
	 * Handles 'select' events from the media upload view, presenting the view
	 * to the user.
	 * @private
	 */
	uploadViewSelectHandler_: function() {
		this.$('.media-selector').hide()
		this.views.uploadView.mediaType = this.getTypeFilter()
		this.views.uploadView.$el.show()
	},

	fetchFilteredList: function(startCount) {
		$('.more-button').prop('disabled', true)
		this.fetching = true
		this.startLoad()

		// Clear list if no start specified.
		if (startCount === undefined) {
			this.$('.image-list').css('height', '')
			this.collection.remove(this.collection.models)
			startCount = 0
		}

		this.imagesLoaded = startCount + PAGE_SIZE

		// Get selected media type filter
		var mediaType       = this.getTypeFilter(),
			mediaTypeString = MediaLibrary.types.get(mediaType)

		var tags = [mediaTypeString].concat(this.filters)

		// If we're viewing animations, only get the first frame for each.
		if (mediaTypeString === 'animation') {
			tags.push('*frame-0')
		}

		this.collection.fetch({
			remove: false,

			headers: {
				Range: 'indices=' + startCount + '-' + (startCount + PAGE_SIZE - 1)
			},

			data: {
				tags: tags.join(',')
			}
		}).then(function() {
			$('.more-button').prop('disabled', false)
		})
	},

	addFile: function(file, collection, options) {
		// Don't show files which have been deleted on this app.
		if ((file.get('restrictions') & (1 << (this.app_.id - 1))) !== 0) {
			return
		}
		// Render out preview.
		if (file.get('mime').indexOf('video/') > -1) {
			file.view = new VideoItem({
				model: file,
				tagList: this.tagList_
			})
		} else {
			file.view = new ImageItem({
				model: file,
				tagList: this.tagList_
			})
		}

		this.addView(file.view.render())

		var files = this.$('.image-list .file')

		if (!Array.isArray(options) && options.at === 0 && files.length) {
			// New item added part way through the list.
			files.eq(options.at).before(file.view.el)
		} else {
			this.$('.image-list').append(file.view.el)
		}
	},

	removeFile: function(file) {
		if (file.view) {
			file.view.destroy()
		}
	},

	filterChange: function() {
		setTimeout(function() {
			this.filters = []
			var tags = this.$('.tag-filter .as-values').val().split(',')

			tags.forEach(function(tag) {
				if (tag) {
					this.filters.push(tag.trim().toLowerCase())
				}
			}, this)

			this.fetchFilteredList()
		}.bind(this))
	},

	addTagFilter: function(e) {
		var tagName = $(e.currentTarget).find('span').text()

		var keypress = new jQuery.Event('keydown')
		keypress.keyCode = 9
		this.$('.tag-filter .as-input').val(tagName).trigger(keypress)

		e.stopPropagation()
	},

	startLoad: function() {
		this.$('.image-list').append('<div class="loader"><img src="/images/loader.png"></div>')
	},

	stopLoad: function() {
		this.$('.image-list .loader').remove()
	},

	// Called once media upload complete. Automatically select new media.
	doneUpload: function(file) {
		// Add file to collection if it matches all filters.
		var mediaType       = this.getTypeFilter(),
			mediaTypeString = MediaLibrary.types.get(mediaType)

		var tagNames = [mediaTypeString].concat(this.filters)

		var matches = tagNames.every(function(tagName) {
			var tag = this.tagList_.findWhere({name: tagName})

			if (!tag) {
				return false
			}

			return file.tags.indexOf(tag.id) > -1
		}, this)

		if (matches) {
			// Remove the image from the collection if it already exists so we
			// can move it to the start.
			var model = this.collection.get(file.id)

			if (model) {
				this.collection.remove(model)
			}
			file.parentId = file.id
			this.collection.add(file, {at: 0})
		}
	},

	// Show media edit screen on edit link click.
	editMedia: function(e) {
		e.stopPropagation()

		var id    = $(e.currentTarget).data('id'),
			media = this.collection.get(id)
		this.views.mediaEditView = new MediaEditView({
			app: this.app_,
			media: media,
			tagList: this.tagList_
		})

		this.listenTo(this.views.mediaEditView, 'close', this.childViewClose, this)

		this.$el.append(this.views.mediaEditView.render().el)
		this.$('.media-selector').hide()
	},

	// Load more images when we near the end of the list.
	scroll: function() {
		// Only load when media library is visible.
		if (!this.$('.image-list').is(':visible')) {
			return
		}

		var scrollContainer = this.$('.media-list-col')[0],
			distanceFromBottom = scrollContainer.scrollHeight - scrollContainer.scrollTop - scrollContainer.offsetHeight

		if (!this.fetching && this.imagesLoaded < this.totalImages && distanceFromBottom < 600) {
			this.fetchFilteredList(this.imagesLoaded)
		}
	},

	// Method for scroll and load more button to call more images
	loadMoreImages: function() {
		this.fetchFilteredList(this.imagesLoaded)
	},

	/**
	 * Reads the currently selected media type filter from the DOM.
	 * @returns {number} The selected type. Can be compared to the constants in
	 *     {@link MediaLibrary#types}.
	 */
	getTypeFilter: function() {
		var type = this.$('.type-filter .' + TYPE_SELECT_ACTIVE_CLASS)
			.data('type')

		return Number(type)
	},

	/**
	 * Handles dragover events triggered on the view's root element, for file
	 * uploads. Sets the drop effect and updates the view's root element class
	 * for dragging.
	 * @param {jQuery.Event} e jQuery Event object.
	 * @private
	 */
	dragOver_: function(e) {
		e.stopPropagation()
		e.preventDefault()
		e.originalEvent.dataTransfer.dropEffect = 'copy'
		this.$el.addClass(DRAGGING_CLASS)
	},

	/**
	 * Handles dragleave events triggered on the view's root element, for file
	 * uploads. Remove the view's root element dragging class.
	 * @param {jQuery.Event} e jQuery Event object.
	 * @private
	 */
	dragLeave_: function(e) {
		e.stopPropagation()
		e.preventDefault()
		this.$el.removeClass(DRAGGING_CLASS)
	},

	/**
	 * Handles drop events triggered on the view's root element, for file
	 * uploads. Passes files to the child MediaUploadView.
	 * @param {jQuery.Event} e jQuery Event object.
	 * @private
	 */
	drop_: function(e) {
		e.stopPropagation()
		e.preventDefault()
		this.$el.removeClass(DRAGGING_CLASS)

		var files = e.originalEvent.dataTransfer.files

		this.views.uploadView.addFiles(files)
	},

	beforeDestroy: function() {
		// Set render method to a noop so this doesn't get accidentally
		// re-rendered later.
		this.render = function() {
			return this
		}
	}
})

// Set media type constants
var types = [
	'image',
	'icon',
	'animation',
	'video'
]

MediaLibrary.types = {
	IMAGE: 0,
	ICON: 1,
	ANIMATION: 2,
	VIDEO: 3,
	get: function(id) {
		return types[id] || ''
	},
	getMime: function(id) {
		if (id === 3) {
			return 'video/'
		}

		return 'image/'
	}
}

module.exports = MediaLibrary
