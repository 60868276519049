<img class="info-window-icon" src="/images/hazards/disaster-icons/{{categoryName}}.png">
<h4>{{getBrowserLocaleText eventName}}</h4>
<div>{{t "hazards.alerts.effective"}} {{formatDateTime timestamps.effective}}, {{t "hazards.alerts.issuedBy"}} {{toUpperCase feed}}</div>
<div class="info-window-status">
	{{#ifDateIsInPast timestamps.expires}}
		<span class="status expired">{{t "hazards.alerts.expired"}}</span>
	{{else}}
		<span class="status active">{{t "hazards.alerts.alertActive"}}</span>
	{{/ifDateIsInPast}}
</div>
{{#if options.pinned}}
	<button class="button positive info-window-pin-button">{{t "hazards.alerts.pinned"}}</button>
{{else}}
	<button class="button light info-window-pin-button">{{t "hazards.alerts.pin"}}</button>
{{/if}}
