var CommentList = require('./comment-list')

module.exports = Backbone.Model.extend({
	urlRoot: function() {
		return App.bugsRoot + 'bugs'
	},

	initialize: function() {
		this.commentList = new CommentList()
		this.commentList.url = function() {
			return this.url() + '/comments'
		}.bind(this)

		this.uploadList = new Backbone.Collection()
		this.uploadList.url = function() {
			return this.url() + '/uploads'
		}.bind(this)
	},

	defaults: function() {
		return {
			systemCode: App.system.apiCode,
			status: 1
		}
	},

	validate: function(attributes) {
		if (!(attributes.app && attributes.model && attributes.description && attributes.steps && attributes.expected && attributes.actually)) {
			return $.t('error.allFields')
		}
	}
})
