<td class="center">
	{{#key_value codeGroup.name.content "lang" "text"}}
		<dt>{{lang}}</dt>
		<dd>{{text}}</dd>
	{{/key_value}}
</td>
<td class="center">
	{{#key_value codeGroup.description.content "lang" "text"}}
		<dt>{{lang}}</dt>
		<dd>{{text}}</dd>
	{{/key_value}}
</td>
<td>
	<ul>
		{{#each codeGroup.codes}}<li>{{this}}</li>{{/each}}
	</ul>
</td>
<td class="tools">
	<a href="/apps/{{appId}}/code-groups/{{codeGroup.id}}" title="{{t "common.edit"}}"><i class="icon-pencil"></i></a>
	<!-- <i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}"></i> -->
</td>
