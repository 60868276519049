var StormView = require('lib/storm-view'),
	/** @type {StormGlobals} */
	globals = require('globals')

/**
 * Exports {@link GDPCSectionView}.
 * @module
 */
module.exports = StormView.extend(/** @lends GDPCSectionView.prototype */{
	/**
	 * @constructs GDPCSectionView
	 * @extends StormView
	 * @override
	 */
	initialize: function() {
	},

	/** @override */
	getSectionTitle: function() {
		return $.t('nav.items.gdpcConsole.title')
	},

	/** @override */
	getPages: function() {
		var acl = globals.getAcl()

		return [
			{
				name: $.t('gdpcConsole.nationalSocieties.title'),
				url: 'societies',
				isVisible: function() {
					return acl.hasAccess('GDPC Console', 'National Societies')
				},
				views: [
					require('gdpc-console/societies-view'),
					require('gdpc-console/societies-edit-view')
				]
			},
			{
				name: $.t('gdpcConsole.devPools.title'),
				url: 'pools',
				isVisible: function() {
					return acl.hasAccess('GDPC Console', 'Dev Pools')
				},
				views: [
					require('gdpc-console/dev-pool-list-view'),
					require('gdpc-console/dev-pool-edit-view'),
					require('gdpc-console/dev-pool-country-view')
				]
			},
			{
				name: $.t('nav.items.userAgreement.title'),
				url: 'user-agreement',
				isVisible: function() {
					return acl.hasAccess('User Agreement')
				},
				views: [
					require('user-agreement/user-agreement-view')
				]
			}
		]
	}
})
