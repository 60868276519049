<td class="center" data-id="{{ product.id }}">
	<span class="tab__handle">
		<img class="tab__handle-icon" src="/images/arpro/sortable-handle.png">
	</span>
	{{product.grade}}
</td>
<td class="center">{{product.name}}</td>
<td class="center">{{#if product.access}}{{t "arpro.product.linked"}} <i class="icon-check" aria-hidden="true"></i>{{else}}{{t "arpro.product.noneLinked"}} <i class="icon-check-empty" aria-hidden="true">{{/if}}</td>

<td class="tools">
	<a href="/apps/{{appId}}/products/{{product.id}}" title="{{t "common.edit"}}"><i class="icon-pencil"></i></a>
	<i class="icon-trash delete-code" title="{{t "mediaLibrary.delete"}}" data-grade="{{product.grade}}"></i>
</td>
