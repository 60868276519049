module.exports = Backbone.Model.extend({
	noAuth: true,
	idAttribute: 'flag',

	urlRoot: function() {
		return App.authRoot + 'passwords'
	},

	// Set flag to trigger password reset email.
	flagForReset: function(email) {
		var data = JSON.stringify({
			email: email
		})

		var jqXHR = this.save(null, {
			data: data,
			global: false
		})

		return Promise.resolve(jqXHR)
	},

	// Verify the validity of a password reset flag.
	verify: function() {
		if (!this.get('flag')) {
			return Promise.reject()
		}

		var jqXHR = this.fetch({global: false})

		return Promise.resolve(jqXHR)
	},

	// Change the user's password with a valid password reset flag.
	setPassword: function(password) {
		if (!this.get('flag') || !password) {
			return Promise.reject()
		}

		var data = JSON.stringify({
			password: password
		})

		var jqXHR = this.save(null, {
			data: data,
			global: false
		})

		return Promise.resolve(jqXHR)
	}
})
