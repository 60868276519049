<div class="row">
	<div class="col-xs-12">
		<h3><a href="/apps/{{appId}}/pools" class="header-link">{{t "gdpcConsole.devPools.title"}}</a> / {{getSocietyDescriptionById country.societyId}}</h3>
	</div>
</div>
<div class="row">
	<div class="col-xs-12">
		{{> devPoolCountryPartial}}
	</div>
</div>
