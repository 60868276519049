var StormQL = require('models/stormql'),
	EditorSectionView = require('editor/editor-section-view'),
	ProductListItemView = require('./product-list-item-view'),
	StandaloneStormObject = require('editor/standalone-storm-object')

module.exports = EditorSectionView.extend({
	className: 'productSearch',
	template: require('./product-view-template'),
	// TODO this may change once plugin navigation is done.
	activeTabIndex: 4,

	initialize: function(options) {
		App.startLoad()
		var requests = []
		this.app = App.appList.get(options.appId)
		this.appId = options.appId
		// Fetch Products assigned to this app.
		this.appProducts = new StormQL(null, {app: this.app})
		var appProductsFetch = this.appProducts.fetch({data: {class: 'StormQLProduct'}})
		requests.push(appProductsFetch)

		// Render page once all data loaded.
		Promise.all(requests)
			.then(this.ready.bind(this))

		Promise.all(requests)
		.then(function(t) {
			$('.tab__handle').addClass('is-initialised')
			var el = document.getElementById('products')
			var sortable = Sortable.create(el, {
				draggable: ".product-row",
				handle: ".tab__handle",
				ghostClass: "ghost",
				onUpdate: function(evt) {
					var promises = []
					var oldPosition = evt.oldIndex,
						newPosition = evt.newIndex

					if (oldPosition !== newPosition) {
						var items = t[0]
						App.startLoad()
						sortable.option("disabled", true)
						$('.tab__handle').addClass('is-disabled')

						items.forEach(function(item) {
							var updateOrderModel = StandaloneStormObject.fromClassName('StormQLProduct')
							updateOrderModel.set('id', item.id)
							var newOrderId = $('.list-items tr td[data-id=' + item.id + ']').parent('tr').index()
							promises.push(new Promise(function(resolve) {
								updateOrderModel.fetch().then(function() {
									updateOrderModel.set('order', newOrderId)
									updateOrderModel.requestLock(function() {
										updateOrderModel.save().then(function() {
											updateOrderModel.requestUnlock().then(resolve())
										})
									})
								})
							}))
						})
						Promise.all(promises).then(function() {
							App.stopLoad()
							sortable.option("disabled", false)
							$('.tab__handle').removeClass('is-disabled')
						})
					}
				}
			})
		})
	},

	ready: function() {
		App.stopLoad()
		this.render()
		if (this.appProducts.length === 0) {
			$('#noItems').show()
			$('table').hide()
		} else {
			$('#noItems').hide()
			$('table').show()
		}
	},

	getPageTitle: function() {
		return $.t('arpro.product.product')
	},

	getRenderData: function() {
		return {
			appId: this.app.id
		}
	},

	afterRender: function() {
		var orderedProducts = _.sortBy(this.appProducts.models, function(obj) {
			return obj.attributes.order
		})

		this.appProducts.models = orderedProducts

		this.appProducts.forEach(this.addProduct, this)
		// Hide developer-only controls.
		if (!App.developerMode) {
			this.$('.developer-mode').remove()
		}
	},

	addProduct: function(product) {
		var view = new ProductListItemView({
			product: product,
			appId: this.appId
		})

		this.$('.list-items').append(view.render().el)
		view.render().el.className = 'product-row'
	}
})
