<div class="modal-header">
	<button class="button light left cancel-button"><i class="icon-chevron-left"></i></button>
	<button class="button positive right done-button"><i class="icon-check-alt"></i> {{t "common.done"}}</button>
	{{#if downloadLink}}
		<a href="{{remoteUrl downloadLink}}" class="download-link" target="_blank" title="Download" download>
			<button class="button right"><i class="icon-download-alt"></i></button>
		</a>
	{{/if}}
	<h4>{{t "mediaLibrary.editMedia"}}</h4>
</div>
<div class="modal-body">
	{{#if image}}
		<div class="image-preview">
			<div class="density-switch">
				{{#if legacy}}<a href data-density="x0.75">0.75x</a>{{/if}}
				<a href data-density="x1">1x</a>
				{{#if legacy}}<a href data-density="x1.5">1.5x</a>{{/if}}
				<a href data-density="x2" class="selected">2x</a>
			</div>
			<div class="images">
				{{#each image}}
					<a href="{{remoteUrl filename}}" target="_blank" download>
						<img src="{{remoteUrl filename}}" data-density="{{density}}" {{#ifEqual density "x2"}}style="display: inline;"{{/ifEqual}}>
					</a>
				{{/each}}
			</div>
		</div>
	{{/if}}
	{{#if animation}}
		<div class="image-preview">
			{{#each animation}}
				<img src="{{remoteUrl filename}}" data-frame="{{@index}}" data-index="{{@index}}">
			{{/each}}
		</div>

		<ul class="animation-frames">
			{{#each animation}}
				<li>
					<img src="{{remoteUrl filename}}" data-frame="{{@index}}" data-index="{{@index}}">
					<a href class="remove-frame-link">&times;</a>
				</li>
			{{/each}}
			<a href class="add-frame-link"><i class="icon-plus"></i></a>
		</ul>
		<p class="help">{{t "mediaLibrary.dragToReorder"}}</p>
		<input type="file" class="frame-input" name="files" accept="image/*" style="display: none">
	{{/if}}
	{{#if video}}
		<div class="image-preview">
			<video src="{{remoteUrl video.filename}}" controls style="width:100%"></video>
		</div>
	{{/if}}

	<div class="controls">
		<button class="button negative delete-button"><i class="icon-trash"></i> {{t "mediaLibrary.delete"}}</button>
		<button class="button negative global-delete">Global delete</button>
	</div>

	<div class="developer-mode controls">
		{{#ifEqual mediaType "icon"}}
			<button class="button negative convert-to-image">Convert to image</button>
		{{/ifEqual}}

		{{#ifEqual mediaType "image"}}
			<button class="button negative convert-to-icon">Convert to icon</button>
		{{/ifEqual}}

		<button class="button negative remove-media-type">Hide from media library</button>
	</div>
</div>
