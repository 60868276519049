<div class="row">
	<div class="col-xs-12 actions-col">
		<a href="/apps/{{appId}}/product-search/new"><button class="button wide add">{{t "common.add"}}</button></a>
	</div>
</div>
<div class="app-error" id="noItems" style="display:none;">{{t "arpro.noitems"}}</div>

<table style="display:none;">
	<thead>
		<tr>
			<th>id</th>
			<!-- <th>{{t "arpro.search.burningRate"}}</th> -->
			<th>{{t "arpro.search.compressionStrength25"}}</th>
			<th>{{t "arpro.search.compressionStrength50"}}</th>
			<th>{{t "arpro.search.compressionStrength75"}}</th>
			<th>{{t "arpro.search.equivalentYoungsModulus"}}</th>
			<th>{{t "arpro.search.mouldedDensity"}}</th>
			<th>{{t "arpro.search.resilience75DynamicCompression"}}</th>
			<!-- <th>{{t "arpro.search.tensileElongation"}}</th> -->
			<!-- <th>{{t "arpro.search.tensileStrength"}}</th> -->
			<th>{{t "common.tools"}}</th>
		</tr>
	</thead>
	<tbody class="list-items"></tbody>
</table>
