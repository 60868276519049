var MediaLibrary      = require('media-library/media-library-view'),
	MediaSelectorView = require('media-library/media-selector-view'),
	LinkSelector      = require('editor/inspector/link-selector'),
	utils             = require('lib/utils')

// Set of keys which should use a textarea rather than a text input.
var LONG_TEXT_KEYS = [
	'story',
	'post'
]

/**
 * Exports {@link StormQLEditorView}.
 * @module
 */
module.exports = Backbone.View.extend(/** @lends StormQLEditorView.prototype */{
	/** @override */
	template: require('./stormql-editor-view-template'),
	/** @override */
	className: 'storm-modal modal fade create-modal stormql-editor',

	/** @override */
	events: {
		'click .save-button': 'save',
		'click .choose-image-button': 'chooseImage'
	},

	/**
	 * @constructs StormQLEditorView
	 * @extends Backbone.View
	 * @override
	 */
	initialize: function(options) {
		if (!options.app) {
			throw new Error('No app specified')
		}

		this.views = {}
		this.listViews = []
		this.app = options.app
	},

	/** @override */
	afterRender: function() {
		// Create input fields for all properties on the model.
		var structure = App.classes.get(this.model.get('class')),
			keys      = Object.keys(structure)

		keys.forEach(function(key) {
			if (key === 'class') {
				return
			}

			var prop = getPropertyOverride(structure, key)

			var isLongText = LONG_TEXT_KEYS.indexOf(key) > -1

			var $label = $('<label>')
				.addClass('control-label title-case col-xs-3')
				.attr('for', 'string-' + key)
				.text(key)

			var $controls = $('<div>').addClass('col-xs-9')

			switch (prop) {
				case '<String>':
					var id = 'string-' + key

					if (isLongText) {
						var $longString = $('<textarea>')
							.attr('id', id)
							.attr('data-key', key)
							.addClass('form-control')
							.prop('required', true)

						$controls.append($longString)
					} else {
						var $stringInput = $('<input>')
							.attr('type', 'text')
							.attr('id', id)
							.attr('data-key', key)
							.addClass('form-control')
							.prop('required', true)

						$controls.append($stringInput)
					}

					break

				case '<Number>':
					var $numberInput = $('<input>')
						.attr('type', 'number')
						.attr('id', id)
						.attr('data-key', key)
						.prop('required', true)

					$controls.append($numberInput)
					break

				case '<Boolean>':
					var $checkbox = $('<input>')
						.attr('type', 'checkbox')
						.attr('id', 'string-' + id)
						.attr('data-key', key)
						.prop('required', true)

					$controls.append($checkbox)
					break

				case '{Text}':
					this.app.languageList.forEach(function(lang) {
						var code     = lang.get('code'),
							textId   = 'text-' + key + '-' + code,
							modelKey = key + '..content..' + code

						var $langLabel = $('<label>')
							.attr('for', 'text-' + key + '-' + code)
							.text(code)

						$controls.append($langLabel)

						if (isLongText) {
							var $longLang = $('<textarea>')
								.attr('id', textId)
								.attr('data-key', modelKey)
								.addClass('form-control')
								.prop('required', true)

							$controls.append($longLang)
						} else {
							var $shortLang = $('<input>')
								.attr('type', 'text')
								.attr('id', textId)
								.attr('data-key', modelKey)
								.addClass('form-control')
								.prop('required', true)

							$controls.append($shortLang)
						}
					})
					break

				case '{Image}':
					var $img = $('<img>')
						.attr('data-key', key)
						.css('margin-bottom', '10px')

					var $imgButton = $('<button>')
						.addClass('button choose-image-button')
						.attr('data-key', key)
						.text($.t('editor.inspector.properties.image.' +
							'chooseButton'))

					$controls.append($img).append($imgButton)
					break

				case '{Link}':
				case '{DestinationLink}':
					var $linkSelectorContainer = $('<div>')
						.addClass('col-xs-12')
						.attr('id', 'link-selector-' + key)

					$controls.append($linkSelectorContainer)

					var linkSelector = new LinkSelector({
						link: this.model.get(key),
						titleDisabled: true,
						pageList: this.app.pageList
					})

					linkSelector.el = '#link-selector-' + key
					this.listViews.push(linkSelector)
					break

				// TODO this is specific to Post. Factor it out into that class.
				case '{Share}':
					var $shareTextLabel = $('<h4>').text('Text')

					$controls.append($shareTextLabel)

					this.app.languageList.forEach(function(lang) {
						var code     = lang.get('code'),
							shareId  = 'text-share-text-' + code,
							modelKey = 'share..text..content..' + code

						var $langLabel = $('<label>')
							.attr('for', 'text-share-text-' + code)
							.text(code)

						var $langInput = $('<textarea>')
							.addClass('form-control')
							.attr('data-key', modelKey)
							.attr('id', shareId)
							.text(code)

						$controls.append($langLabel).append($langInput)
					})

					var $shareLinkLabel             = $('<h4>').text('Link'),
						$shareLinkSelectorContainer = $('<div>')
							.addClass('col-xs-12')
							.attr('id', 'link-selector-share-link')

					$controls
						.append($shareLinkLabel)
						.append($shareLinkSelectorContainer)

					var shareLinkSelector = new LinkSelector({
						link: this.model.get('share..link'),
						titleDisabled: true,
						pageList: this.pageList
					})

					shareLinkSelector.el = '#link-selector-share-link'
					this.listViews.push(shareLinkSelector)

					break

				default:
					return
			}

			var $controlGroup = $('<div>')
				.addClass('control-group')
				.append($label)
				.append($controls)

			this.$('.stormql-controls').append($controlGroup)
		}, this)

		// Populate current values.
		this.updateInputValues()
		this.updateImagePreviews()

		// Render any child views.
		this.listViews.forEach(function(view) {
			view.setElement(document.querySelector(view.el))
			view.render()
		})
	},

	// Populate all inputs with respective values from the model.
	updateInputValues: function() {
		var model = this.model

		this.$('input:not([type="checkbox"]), textarea').each(function() {
			var key   = $(this).data('key'),
				value = model.get(key)

			$(this).val(value)
		})

		this.$('input[type="checkbox"]').each(function() {
			var key = $(this).data('key')

			this.checked = model.get(key)
		})
	},

	// Update all image tags with x1 image previews from the model.
	updateImagePreviews: function() {
		var model = this.model

		this.$('img').each(function() {
			var key   = $(this).data('key'),
				value = model.get(key)

			if (value.class === 'Image' || value.class === 'Icon') {
				value = value.src.x1
			}

			var url = utils.getRemoteUrl(value)

			if (url) {
				this.src = url
			}
		})
	},

	chooseImage: function(e) {
		var key   = $(e.currentTarget).data('key'),
			model = this.model.get(key)

		// Allow setting images on single-source models.
		if (typeof model === 'string') {
			model = this.model
		}

		// Show media library
		this.views.mediaLibrary = new MediaSelectorView({
			app: this.app,
			model: model,
			mediaType: MediaLibrary.types.IMAGE
		})

		this.views.mediaLibrary.on('change', this.updateImagePreviews, this)

		$('body').append(this.views.mediaLibrary.el)
		this.views.mediaLibrary.render().show()
	},

	// Copy all form changes back into the model.
	copyBackToModel: function() {
		var model = this.model

		this.$('input:not([type=checkbox]), textarea').each(function() {
			var key   = $(this).data('key'),
				value = $(this).val()

			model.set(key, value)
		})

		this.$('input[type=checkbox]').each(function() {
			var key = $(this).data('key')

			model.set(key, this.checked)
		})
	},

	save: function() {
		App.startLoad()

		this.copyBackToModel()
		this.model.once('sync', this.saveDone, this)

		var appId = this.app.id

		if (this.model.isNew()) {
			// New objects must be posted with app ID
			this.model.save(null, {appId: appId})
		} else {
			this.model.save()
		}
	},

	saveDone: function() {
		App.stopLoad()

		if (this.model.collection === undefined) {
			this.model.trigger('create', this.model)
		}

		$('.create-modal').modal('hide')
	}
})

function getPropertyOverride(structure, key) {
	if (structure.class === 'StormQLFrame' && key === 'src') {
		return '{Image}'
	}

	// No overrides.
	return structure[key]
}
