/**
 * Exports {@link UsersMeList}.
 * @module
 */
module.exports = Backbone.Collection.extend(/** @lends UsersMeList.prototype */{
	/**
	 * @constructs UsersMeList
	 * @extends Backbone.Collection
	 * @override
	 */
	initialize: function() {
	},

	/** @override */
	url: function() {
		return App.apiRoot + 'users/me/users'
	},

	/** @override */
	parse: function(response) {
		return response.map(function(id) {
			return {id: id}
		})
	}
})
