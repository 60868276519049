var ListView = require('list-view/list-view'),
	StandaloneStormObject = require('editor/standalone-storm-object'),
	StormQLEditorView = require('./stormql-editor-view'),
	ListItem = require('./stormql-list-item-view')

module.exports = ListView.extend({
	template: require('./stormql-list-view-template'),
	list: require('models/stormql'),
	listItem: ListItem,

	afterInitialize: function(options) {
		this.views = {}
		this.slug = options.slug
		this.className = options.className

		// Override edit click
		this.events['click .edit'] = 'edit'
		this.delegateEvents()

		// Fetch enabled languages/page list for this app.
		this.app.languageList.fetchOnce()
		this.app.pageList.fetchOnce()

		// Fetch the page list if there's a link selector in this class.
		var links = ['{Link}', '{DestinationLink}', '{Share}']

		for (var i in App.classes[this.className]) {
			if (links.indexOf(App.classes[this.className][i]) > -1) {
				this.pageList = this.app.pageList
				this.pageList.fetch()
			}
		}

		ListItem.pageList = this.app.pageList
	},

	ready: function() {
		// Render table header.
		var structure = App.classes.get(this.className),
			keys = Object.keys(structure),
			header = ''

		var imageIndex = keys.indexOf('image')

		if (imageIndex > -1) {
			keys.splice(imageIndex, 1)
			keys.unshift('image')
		}

		for (var i = 0; i < keys.length; i++) {
			var key = keys[i]

			if (key === 'class') {
				continue
			}

			var type = getPropertyOverride(structure, key)

			if (type === '{Image}') {
				header += '<th style="width:200px">'
			} else {
				header += '<th>'
			}

			header += key + '</th>'
		}

		this.$('thead tr').prepend(header)

		this.trigger('ready')
		App.stopLoad()
	},

	getRenderData: function() {
		return {
			appId: this.appId,
			slug: this.slug
		}
	},

	afterRender: function() {
		if (App.acl.hasReadPermission('Content')) {
			this.$('.add').hide()
		}

		App.stopLoad()
	},

	// Override add method to provide modal editing
	addModel: function() {
		var model = StandaloneStormObject.fromClassName(this.className)

		this.listenToOnce(model, 'create', this.objectCreated, this)
		this.showModalWithModel(model)
	},

	edit: function(e) {
		var id = Number($(e.currentTarget).data('id'))
		var model = this.collection.get(id)

		this.showModalWithModel(model)

		e.stopPropagation()
	},

	showModalWithModel: function(model) {
		if (this.views.modal) {
			this.views.modal.destroy()
		}

		this.views.modal = new StormQLEditorView({
			model: model,
			app: this.app
		})

		$('body').append(this.views.modal.el)
		this.views.modal.render()
		this.views.modal.$el.modal()
	},

	objectCreated: function(model) {
		this.collection.push(model)
	}
})

function getPropertyOverride(structure, key) {
	if (structure.class === 'StormQLFrame' && key === 'src') {
		return '{Image}'
	}

	// No overrides.
	return structure[key]
}
