var DevPoolDetailsList = require('./dev-pool-details-list'),
	countryTemplate    = require('./dev-pool-country-item-template'),
	GDPCSectionView    = require('./gdpc-section-view')

/**
 * Exports {@link SocietyStatusView}.
 * @module
 */
module.exports = GDPCSectionView.extend(/** @lends SocietyStatusView.prototype */{
	/** @override */
	template: require('./society-status-view-template'),

	/**
	 * @constructs SocietyStatusView
	 * @extends GDPCSectionView
	 * @override
	 * @classdesc Defines a view for viewing the dev pool status for all apps
	 *     assigned to the current user. This view is used in place of {@link
	 *     DevPoolListView} for non-admin users, to provide a simpler overview
	 *     for a smaller number of apps.
	 */
	initialize: function(options) {
		this.appId = Number(options.appId)

		this.collection = new DevPoolDetailsList()
		this.collection.once('sync', this.ready, this)
		this.collection.fetch()
	},

	/** @override */
	getPageTitle: function() {
		return $.t('gdpcConsole.devPools.title')
	},

	ready: function() {
		// Remove pools which don't contain the current app.
		this.collection.each(function(pool) {
			var details = pool.attributes.details
			var i = 0

			while (i < details.length) {
				if (details[i].appId !== this.appId) {
					details.splice(i, 1)
				} else {
					i++
				}
			}

			if (details.length > 0) {
				// Pad each app language stage list to full length of stages,
				// copy in stage names
				_.each(details.languages, function(lang) {
					for (var i in pool.stages) {
						if (pool.stages.hasOwnProperty(i)) {
							if (lang.stages[i] === undefined) {
								lang.stages[i] = {
									stageDue: pool.stages[i].timestamp,
									stageId: pool.stages[i].stageId,
									stageName: pool.stages[i].name
								}
							}

							lang.stages[i].responsibility = pool.stages[i].responsibility
							lang.stages[i].notes = pool.stages[i].notes
						}
					}

					// Set estimated completion date as 1 week after iOS
					// submission
					var submission = lang.stages.filter(function(stage) {
						return stage.stageId === 5
					})[0]

					if (submission === undefined) {
						return
					}

					var dueDate = new Date(submission.stageDue * 1000)
					dueDate.setDate(dueDate.getDate() + 7)

					lang.expected = parseInt(dueDate.getTime() / 1000, 10)
				})

				// Output country status view.
				this.$('.list-items').append(countryTemplate({country: details[0]}))
			}
		}, this)

		App.stopLoad()
	}
})
