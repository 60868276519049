var EditorSectionView 			= require('editor/editor-section-view')
/**
 * Exports {@link ProductEditView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends ProductEditView.prototype */{
	/** @override */
	className: 'PhyisicalPropertyTable',
	/** @override */
	template: require('./physical-property-table-view-template'),
	activeTabIndex: 4,

	/** @override */
	events: {
		'change .arpro-properties-data': 'setPropertiesData',
		'change .property-header': 'setPropertyHeader',
		'click .addColumn': 'newColumn',
		'click .reRender': 'reRender',
		'click .hide-row': 'hideRow',
		'click .remove-column': 'removeCol'
	},

	/**
	 * @constructs ProductEditView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)
		this.model = options.model
		this.tests = this.model.get('data..tests')
		this.pageId = this.model.get('pageId')
		this.exampleTests = [
			{
				pageId: this.pageId,
				method: 'ISO 844 (5 mm/min)',
				name: 'Compressive strength',
				disabled: false,
				data: [
					{
						pageId: this.pageId,
						class: 'Series',
						label: '25% strain (kPa)',
						data: [],
						disabled: false
					},
					{
						pageId: this.pageId,
						class: 'Series',
						label: '50% strain (kPa)',
						data: [],
						disabled: false
					},
					{
						pageId: this.pageId,
						class: 'Series',
						label: '75% strain (kPa)',
						data: [],
						disabled: false
					}
				],
				class: 'StormQLTest'
			},
			{
				pageId: this.pageId,
				method: 'ISO 1798',
				name: 'Tensile',
				disabled: false,
				data: [
					{
						pageId: this.pageId,
						class: 'Series',
						label: 'Strength (kPa)',
						data: [],
						disabled: false
					},
					{
						pageId: this.pageId,
						class: 'Series',
						label: 'Elongation (%)',
						data: [],
						disabled: false
					}
				],
				class: 'StormQLTest'
			},
			{
				pageId: this.pageId,
				method: 'ISO 1856 C (Stabilising 24 hours)',
				name: 'Compression set',
				disabled: false,
				data: [
					{
						pageId: this.pageId,
						class: 'Series',
						label: '25% strain - 22 hrs - 23⁰C (%)',
						data: [],
						disabled: false
					}
				],
				class: 'StormQLTest'
			},
			{
				pageId: this.pageId,
				method: 'ISO 3795 (12.5 mm thick)',
				name: 'Burn rate (mm/min)',
				disabled: false,
				data: [
					{
						pageId: this.pageId,
						class: 'Series',
						label: 'Burn rate (mm/min)',
						data: [],
						disabled: false
					}
				],
				class: 'StormQLTest'
			}
		]

		this.populateTestsIfEmpty()
	},

	reRender: function() {
		// We have to trigger an event for the parent to pick up..
		this.model.trigger('renderTable')
	},

	removeCol: function(e) {
		this.tableUpdated()
		var colIndex = $(e.currentTarget).data('index')
		var colLabelVal = $(e.currentTarget).prev().val()

		this.tests.forEach(function(test) {
			colIndex = $(e.currentTarget).data('index')
			if (test.data) {
				test.data.forEach(function(series) {
					colIndex = $(e.currentTarget).data('index')
					if (series.data) {
						var cell = series.data[colIndex]
						if (cell) {
							if (cell.toDelete) {
								for (var i = 0; i < series.data.length; i++) {
									if (series.data[i].label === colLabelVal) {
										break
									}
									if (series.data[i].toDelete) {
										colIndex += 1
									}
								}
								cell = series.data[colIndex]
							}
							cell.toDelete = true
						}
					}
				})
			}
		})
		this.headers = this.getPropertyHeaders()
		this.reRender()
	},

	hideRow: function(e) {
		e.preventDefault()
		e.currentTarget.classList.toggle('is-disabled')

		var target = $(e.currentTarget)
		var index = target.data('index')
		var testIndex = target.parent().parent().prevAll('.table-row-section').first().data('index')

		this.tests[testIndex].data[index].disabled = target.hasClass('is-disabled')
	},

	/** @override */
	getRenderData: function() {
		var data = {}
		if (this.tests) {
			data.propertyHeaders = this.getPropertyHeaders()
			this.headers = data.propertyHeaders
			data.tests = this.tests
		}
		return data
	},

	setVisibilities: function() {
		if (this.tests) {
			this.tests.forEach(function(test) {
				if (test.data) {
					test.data.forEach(function(series) {
						series.disabled = true
						series.data.forEach(function(seriesData) {
							if (seriesData.value != null) {
								series.disabled = false
							}
						})
					})
				}
			})
		}
	},

	afterRender: function() {
		if (this.headers) {
			if (this.headers.length >= 6) {
				this.$('.addColumn').prop('disabled', true)
			}
		}
	},

	newColumn: function() {
		this.tableUpdated()
		this.addColumn()
	},

	addColumn: function() {
		var self = this
		if (this.tests) {
			this.tests.forEach(function(test) {
				if (test.data) {
					test.data.forEach(function(series) {
						var activeSeries = series.data.length
						series.data.forEach(function(seriesData) {
							if (seriesData.toDelete) {
								activeSeries -= 1
							}
						})

						if (activeSeries < 6) {
							if (series.data) {

							} else {
								series.data = []
							}

							var dataToAdd = {
								class: 'SeriesData',
								value: null,
								label: ''
							}
							if (self.pageId) {
								dataToAdd.pageId = self.pageId
							}
							// We need to add a new storm object here..
							series.data.push(dataToAdd)
						}
					})
				}
			})
			this.model.set('data..tests', this.tests)
			this.reRender()
		}
	},

	setPropertyHeader: function() {
		this.tableUpdated()
		var tests = this.model.get('data..tests')
		var seriesIndex = 0

		tests.forEach(function(test) {
			test.data.forEach(function(series) {
				seriesIndex = 0
				series.data.forEach(function(singleSeries) {
					var seriesVal = $('.property-header[data-index="' + seriesIndex + '"]').val()
					if (singleSeries.toDelete) {
						return
					}
					singleSeries.label = seriesVal
					seriesIndex += 1
				})
			})
		})
	},

	setHiddenAttrs: function(rowInputs) {
		// Are all of the values null on that row?
		var emptyCount = 0
		var inputs = rowInputs
		var length = inputs.length
		inputs.each(function() {
			if ($(this).val() === "") {
				emptyCount++
			}
		})
		if (emptyCount === length) {
			rowInputs.parent().parent().attr('empty', true)
		} else {
			rowInputs.parent().parent().attr('empty', false)
		}
	},

	setPropertiesData: function(e) {
		this.tableUpdated()
		var target = $(e.target)
		var value = e.target.value

		var index = target.data('index')
		var seriesIndex = target.parent().parent().data('index')
		var testIndex = target.parent().parent().prevAll('.table-row-section').first().data('index')

		var tests = this.tests
		var data = tests[testIndex].data[seriesIndex].data[index]

		data.value = value
		this.model.set('data..tests', tests)
		this.tests = this.model.get('data..tests')
		this.model.trigger('change:data')

		this.setHiddenAttrs(target.parent().parent().children('td').children('input'))
	},

	populateTestsIfEmpty: function() {
		var self = this
		if (this.tests) {
			var propertyHeaders = this.getPropertyHeaders()
			if (this.tests.length === 0) {
				// We need to give it something to render..
				// Deep clone
				this.tests = JSON.parse(JSON.stringify(this.exampleTests))
				this.addColumn()
			} else {
				// We're missing a test, it would have been hidden... give it something to render with a visibility flag
				this.exampleTests.forEach(function(exampleTest) {
					var foundTextIndex = self.tests.findIndex(function(test) {
						return test.name === exampleTest.name
					})
					if (foundTextIndex === -1) {
						// Add Row to the right place
						var exampleTestCopy = JSON.parse(JSON.stringify(exampleTest))
						exampleTestCopy.data.forEach(function(series) {
							series.disabled = false
						})
						exampleTestCopy.disabled = false
						self.tests.splice(foundTextIndex, 0, exampleTestCopy)
					} else {
						// Check the rows exist
						exampleTest.data.forEach(function(exampleSeries, seriesIndex) {
							var foundRow = self.tests[foundTextIndex].data.find(function(series) {
								return exampleSeries.label === series.label
							})
							if (!foundRow) {
								self.tests[foundTextIndex].data.splice(seriesIndex, 0, exampleSeries)
							}
						})
					}
				})
			}
			// Check to make sure all of the tests have the correct series data
			this.tests.forEach(function(test, testIndex) {
				if (testIndex <= self.exampleTests.length - 1) {
					propertyHeaders.forEach(function(header, index) {
						test.data.forEach(function(series) {
							var foundSeriesData = series.data.find(function(seriesData) {
								return seriesData.label === header.title
							})
							if (!foundSeriesData) {
								// Add it
								series.data.splice(index, 0,
									{
										class: 'SeriesData',
										value: null,
										label: header.title
									})
							}
						})
					})
				}
			})
		}
	},

	tableUpdated: function() {
		this.model.trigger('tableChanged')
	},

	getPropertyHeaders: function() {
		var tests = this.tests
		var headers = []

		if (tests && tests[0] && tests[0].data[0]) {
			tests[0].data[0].data.forEach(function(data) {
				if (!data.toDelete) {
					headers.push({title: data.label})
				}
			})
		}

		return headers
	}
})
