var Session = require('../session')

/**
 * Exports {@link FakeSession}.
 * @module
 */
module.exports = Session.extend(/** @lends FakeSession.prototype */{
	/**
	 * @constructs FakeSession
	 * @extends Session
	 * @override
	 * @classdesc Provides a fake implementation of {@link Session} for testing.
	 */
	initialize: function() {
		Session.prototype.initialize.apply(this, arguments)

		/** @private {?string} */
		this.fakeUser_ = null
		/** @private {?string} */
		this.fakePass_ = null
		/** @private {?string} */
		this.fakeToken_ = null
	},

	/**
	 * Sets the fake username which will authenticate successfully.
	 * @param {string} username Username to set.
	 */
	setFakeUser: function(username) {
		this.fakeUser_ = username
	},

	/**
	 * Sets the fake password which will authenticate successfully.
	 * @param {string} password Password to set.
	 */
	setFakePass: function(password) {
		this.fakePass_ = password
	},

	/** @override */
	authenticate: function(username, password) {
		if (username === this.fakeUser_ && password === this.fakePass_) {
			return Promise.resolve()
		}

		return Promise.reject()
	},

	/** @override */
	renew: function() {
		return Promise.resolve()
	},

	/** @override */
	verify: function() {
		if (this.get('token') === this.fakeToken_) {
			return Promise.resolve()
		}

		return Promise.reject()
	},

	/** @override */
	getTimeRemaining: function() {
		return 100
	},

	/** @override */
	isAuthenticated: function() {
		return true
	}
})
