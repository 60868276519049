var MediaLibraryView     = require('./media-library-view'),
	MediaUploadModalView = require('./media-upload-modal-view'),
	ModalSelectionView   = require('./media-library-modal-selection-view'),
	SourcePickerView     = require('./media-source-picker-view'),
	StormObject          = require('editor/storm-object'),
	UploadList           = require('./upload-list'),
	MediaUploader        = require('./media-uploader'),
	TagList              = require('./tag-list')

/**
 * Exports {@link MediaSelectorView}.
 * @module
 */
module.exports = Backbone.View.extend(/** @lends MediaSelectorView.prototype */{
	/**
	 * @constructs MediaSelectorView
	 * @extends Backbone.View
	 * @override
	 * @classdesc Handles media selection from the media library/new uploads,
	 *     and persisting media data back to models.
	 */
	initialize: function(options) {
		options = options || {}

		if (!options.app) {
			throw new Error('No app specified')
		}

		if (typeof options.mediaType !== 'number') {
			throw new Error('No media type specified')
		}

		/** @private {App} */
		this.app_ = options.app
		/** @private {MediaLibraryView.types} */
		this.mediaType_ = options.mediaType
		/** @private {SourcePickerView} */
		this.sourcePickerView_ = new SourcePickerView()
		/** @private {ModalSelectionView} */
		this.modalSelectionView_ = new ModalSelectionView({
			app: this.app_,
			model: this.model,
			mediaType: this.mediaType_
		})
		/** @private {MediaUploadModalView} */
		this.mediaUploadModalView_ = new MediaUploadModalView({
			app: this.app_,
			restrictType: true,
			mediaType: this.mediaType_
		})

		this.listenTo(this.sourcePickerView_, 'upload', this.uploadHandler_)
		this.listenTo(this.sourcePickerView_, 'existing', this.existingHandler_)
		this.listenTo(this.sourcePickerView_, 'files', this.filesHandler_)
		this.listenTo(this.mediaUploadModalView_, 'select', this.uploadSelectHandler_)
		this.listenTo(this.mediaUploadModalView_, 'upload', this.uploadCompleteHandler_)
		this.listenTo(this.modalSelectionView_, 'select', this.librarySelectHandler_)

		this.tagList_ = new TagList({type: 'files'})
		this.tagList_.fetch({data_: {type: 'files'}})
	},

	/** @override */
	getRenderData: function() {
		var accept   = 'image/*',
			multiple = false,
			types    = MediaLibraryView.types

		if (this.mediaType_ === types.VIDEO) {
			accept = 'video/*'
		} else if (this.mediaType_ === types.ANIMATION) {
			multiple = true
		}

		return {
			accept: accept,
			multiple: multiple
		}
	},

	/** @override */
	afterRender: function() {
		this.$el.append(this.sourcePickerView_.el)
		this.$el.append(this.modalSelectionView_.el)
		this.$el.append(this.mediaUploadModalView_.el)
		this.sourcePickerView_.render()
		this.modalSelectionView_.render()
		this.mediaUploadModalView_.render()
	},

	/**
	 * Kicks off the media selection process. Brings in the {@link
		* SourcePickerView} modally.
	 */
	show: function() {
		this.sourcePickerView_.show()
	},

	/**
	 * Dismisses the view's modal and destroys the view.
	 */
	close: function() {
		this.$el.modal('hide')

		// Destroy view after modal animated out.
		setTimeout(this.destroy.bind(this), 500)
	},

	/**
	 * Handles 'upload' events from the {@link SourcePickerView}, indicating
	 * the user has selected to upload new media. Shows the native file picker.
	 * @private
	 */
	uploadHandler_: function() {
		this.mediaUploadModalView_.chooseButtonClick()
	},

	/**
	 * Handles 'existing' events from the {@link SourcePickerView}, indicating
	 * the user has selected to upload new media. Shows the media library.
	 * @private
	 */
	existingHandler_: function() {
		this.sourcePickerView_.close()
		this.modalSelectionView_.show()
	},

	/**
	 * Handles 'file' events from the {@link SourcePickerView}, passing a list
	 * of files dropped onto the view for upload.
	 * @param {FileList} files List of files to upload. May not be of valid
	 *     type for the {@link #mediaType_}.
	 * @private
	 */
	filesHandler_: function(files) {
		var isValid = MediaUploader.mediaIsValidForType(files, this.mediaType_)

		if (isValid !== true) {
			swal($.t('error.oops'), isValid, 'error')
			return
		}

		this.mediaUploadModalView_.addFiles(files)
	},

	/**
	 * Handles 'select' events triggered by the media upload view, indicating
	 * media has been selected for upload. Shows the upload view.
	 * @private
	 */
	uploadSelectHandler_: function() {
		this.sourcePickerView_.close()
		this.mediaUploadModalView_.show()
	},

	/**
	 * Handles 'select' events from the media library view, indicating the
	 * specified file should be saved.
	 * @param {Upload} file The file to persist back to the model.
	 * @param {MediaLibraryView.types} type The type of the media to save.
	 * @private
	 */
	librarySelectHandler_: function(file, type) {
		this.save_(file, type)
			.then(this.saveComplete_.bind(this))
	},

	/**
	 * Handles 'upload' events from the child {@link MediaUploadModalView},
	 * indicating a file has been successfully uploaded. The file is then saved
	 * to the model.
	 * @param {Upload} file The uploaded file.
	 * @private
	 */
	uploadCompleteHandler_: function(file) {
		this.tagList_ = new TagList({type: 'files'})
		this.tagList_.fetch({data_: {type: 'files'}})
		.then(function() {
			this.save_(file, this.mediaType_)
		}.bind(this))
		.then(this.saveComplete_.bind(this))
	},

	/**
	 * Triggers a 'change' event to indicate the {@link #model} has been
	 * updated, closes the view and stops the loading spinner.
	 * @private
	 */
	saveComplete_: function() {
		console.warn('Save complete!')
		this.trigger('change')
		this.close()
		App.stopLoad()
	},

	/**
	 * Persists the specified file to the model and emits a 'change' event.
	 * This method is asynchronous as extra requests may be needed to fetch
	 * other animation frames etc.
	 * @param {Upload} file The file to save.
	 * @param {MediaLibraryView.types} fileType The type of the file being
	 *     saved.
	 * @returns {Promise} Promise which is fulfilled upon model update
	 *     completion.
	 * @private
	 */
	save_: function(file, fileType) {
		if (!file.parentId) {
			file.parentId = file.id
		}

		if (!this.model) {
			throw new Error('No model specified')
		}

		var types = MediaLibraryView.types

		if (!file) {
			this.close()
		}

		App.startLoad()
		this.$('button').attr('disabled', true)

		switch (fileType) {
			case types.IMAGE:
			case types.ICON:
				return this.saveImage_(file)

			case types.ANIMATION:
				return this.saveAnimation_(file)

			case types.VIDEO:
				return this.saveVideo_(file)

			default:
				throw new Error('Unsupported media type')
		}
	},

	/**
	 * Stores a {@link MediaLibraryView#types#IMAGE} (or {@link
		* MediaLibraryView#types#ICON}) in the specified model. All resolutions
	 * of the image will be retrieved and set on the model. If no model
	 * parameter is passed, {@link #model} will be used.
	 * @param {Upload} file A single resolution from the desired
	 *     file.
	 * @param {Backbone.Model|Backbone.Collection|Object} [model=this.model] The
	 *     model to update. Supports Backbone.Model objects for compatibility
	 *     with the legacy image format and models which take a single image
	 *     resolution. Supports raw objects for custom Story models, which use
	 *     a single imageUrl property.
	 * @returns {Promise} Promise which is fulfilled upon model update
	 *     completion.
	 * @private
	 */
	saveImage_: function(file, model) {
		model = model || this.model
		return new Promise(function(resolve) {
			// Handle legacy image format.
			var legacyFilename = /_x\d(\.\d+)?(\..*)$/,
				filename       = file.get('filename'),
				isCollection   = model instanceof Backbone.Collection

			var variants = file.getVariantList()

			if (!isCollection && legacyFilename.test(filename)) {
				this.saveImageCompat_(file, model)
				resolve()
				return
			} else if (!isCollection) {
				variants.fetch().then(function() {
					// Sort by area
					variants.comparator = function(a, b) {
						var aArea = a.get('dimensions..width') * a.get('dimensions..height')
						var bArea = b.get('dimensions..width') * b.get('dimensions..height')

						return aArea > bArea ? 1 : aArea < bArea ? -1 : 0
					}

					variants.sort()
					var src
					if (model.attributes) {
						// Backbone Model has been passed in...
						src = model.get('src')
					} else {
						src = model.src
					}
					// Set all variants except the original.
					variants.forEach(function(source, key) {
						var srcFileName = 'cache://content/' + source.get('filename')
						// Skip the original
						if (source.get('parentId') === null) {
							return
						}
						switch (key) {
							case 0: // Shouldn't exist
								break
							case 1:
								src.x2 = srcFileName
								break
							case 2:
								break
							case 3:
								src.x1 = srcFileName
								break
						}
					})
					if (model.attributes) {
						model.set('src', src)
						model.trigger('change')
					}
					resolve()
				})
				return
			}

			variants.fetch().then(function() {
				var orig = variants.get(file.get('parentId'))

				if ('imageUrl' in model) {
					// Special case for Stories, which use a fake model with
					// single property.
					model.imageUrl = orig.get('filename')
				} else if (model instanceof Backbone.Model) {
					// Setting single-source image on something with an src.
					model.set('src', 'cache://content/' + orig.get('filename'))
				} else {
					// Clear all previous resolutions.
					var oldImages = model.models.slice(0)

					var saves = oldImages.map(function(img) {
						return img.destroy()
					})

					Promise.all(saves).then(function() {
						model.reset()

						// Set all variants except the original.
						variants.forEach(function(source) {
							// Skip the original.
							if (source.get('parentId') === null) {
								return
							}

							var pageId = model.parent.get('pageId'),
								image  = App.getClassStructure('Image', pageId)

							image.dimensions.width = source.get('dimensions').width
							image.dimensions.height = source.get('dimensions').height
							image.dimensions.length = source.get('dimensions').length
							image.mime = source.get('mime')
							image.size = source.get('size')
							image.src = App.getClassStructure('InternalLink', pageId)
							image.src.destination = 'cache://content/' + source.get('filename')

							model.add(image)
						})
						resolve()
					})
				}
			})
		}.bind(this))
	},

	/**
	 * Sets the specified model's source URLs for all resolutions in the
	 * old ImageDescriptor format to that of the specified file. If no
	 * model is specified, the model attached to this {@link MediaLibrary}
	 * instance is used.
	 *
	 * For compatibility, the model may be either a {@link
		* StandaloneStormObject} or a flat Object instance.
	 * @param {Upload} file The file to use as the base resolution.
	 *     All other resolutions will be found by replacing the resolution
	 *     suffix in the filename.
	 * @param {StandaloneStormObject|Object} [model] The model to update.
	 * @deprecated
	 * @private
	 */
	saveImageCompat_: function(file, model) {
		model = model || this.model

		var filenameMatch = file.get('filename').match(/(^[0-9]+_.*_)x\d(?:\.\d+)?(\..*)$/),
			filename      = filenameMatch[1],
			fileExt       = filenameMatch[2]

		// Set all resolutions of this image.
		var densities = ['x2', 'x1.5', 'x1', 'x0.75', 'orig'],
			src       = {}

		densities.forEach(function(density) {
			src[density] = filename + density + fileExt
		})

		var orig = src.orig || src.x2

		if ('imageUrl' in model) {
			// Special case for Stories, which use a fake model with a single
			// property.
			model.imageUrl = orig
			return
		}

		var imageSrc

		if (model.get) {
			imageSrc = model.get('src')
		} else {
			imageSrc = model.src
		}

		if (imageSrc.class !== 'ImageDescriptor') {
			// Setting single-source image on something with an src.
			model.set('src', 'cache://content/' + orig)
			return
		}

		// Set all resolutions on model (except original).
		densities.pop()

		densities.forEach(function(density) {
			// If no image at this density, use original image.
			var url = 'cache://content/' + (src[density] || orig)

			if (model instanceof Backbone.Model) {
				model.set('src..' + density, url)
			} else {
				model.src[density] = url
			}
		})
	},

	/**
	 * Sets the source URL of the {@link #model} to a cache link to the
	 * specified video.
	 * @param {Upload} file The file whose source to set.
	 * @returns {Promise} Promise which is fulfilled upon model update
	 *     completion.
	 * @private
	 */
	saveVideo_: function(file) {
		return new Promise(function(resolve) {
			var videoUrl = 'cache://content/' + file.get('filename')

			if (this.model instanceof Backbone.Model) {
				this.model.set('destination', videoUrl)
			} else {
				this.model.destination = videoUrl
			}

			resolve()
		}.bind(this))
	},

	/**
	 * Clears any existing animation frames from the {@link #model} and
	 * populates with the set of frames for the given animation, passed as its
	 * first frame.
	 * @param {Upload} frame0 The first frame of the animation to
	 *     set.
	 * @returns {Promise} Promise which is fulfilled upon model update
	 *     completion.
	 * @private
	 */
	saveAnimation_: function(frame0) {
		var self = this

		return new Promise(function(resolve) {
			// Clear all previous animation frames.
			var frames = this.model.models.slice(0)

			frames.forEach(function(frame) {
				frame.destroy()
			})

			// Save parent to update collection properly.
			this.model.parent.save().then(function() {
				// Extract the animation ID from the file tags.
				var animationId = null

				frame0.get('tags').forEach(function(tagId) {
					var tag      = this.tagList_.get(tagId),
						tagMatch = tag.get('name').match(/^animation-(\w+)$/)

					if (tagMatch !== null) {
						animationId = tagMatch[1]
					}
				}, self)

				var frameList = new UploadList()

				frameList.fetch({
					headers: {Range: 'indices=0-9999'},
					data: {tags: 'animation-' + animationId}
				})

				frameList.once('sync', function() {
					var tagList      = self.tagList_,
						sortedFrames = frameList.models.slice(0)

					sortedFrames.sort(function(a, b) {
						return a.getFrameIndex(tagList, animationId) - b.getFrameIndex(tagList, animationId)
					})

					var promises = sortedFrames.map(function(file) {
						var frame = StormObject.fromClassName('AnimationFrame', this.model.parent.get('pageId'))
						frame.set('delay', 1000)
						this.model.push(frame)

						// Set image URLs on this frame model.
						var model

						// Handle legacy image format.
						if (frame.get('class') === 'AnimationImage') {
							model = frame
						} else {
							model = frame.get('image')
						}
						return this.saveImage_(file, model)
					}, this)

					Promise.all(promises).then(resolve)
				}, self)
			})
		}.bind(this))
	},

	/** @override */
	beforeDestroy: function() {
		this.sourcePickerView_.destroy()
		this.modalSelectionView_.destroy()
	}
})
