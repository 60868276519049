<div class="container">
	<div class="row">
		<div class="col-xs-12 header-with-buttons">
			<p class="text-left">Event</p>
			<input type="text" class="search-input form-control" placeholder="Search events" style="width: 50%;">
			<button class="button save-button">&times; Save and close</button>
		</div>
	</div>
	<div class="row">
		<div class="col-xs-12">
			<div class="events-list">
				<!-- Events list here -->
			</div>
		</div>
	</div>
</div>
