<div class="row media-selector">
	<div class="col-sm-2">
		<h4 data-i18n="common.filter"></h4>
		<div class="storm-list-group">
			<ul class="list-content">
				<li class="type-filter">
					<div class="content">
						<span class="is-bold is-uppercase" data-i18n="mediaLibrary.type"></span>
					</div>
					<ul class="open">
						<li data-type="0">
							<div class="content">
								<i class="icon-picture"></i>
								<span data-i18n="mediaLibrary.image"></span>
							</div>
						</li>
						<li data-type="1">
							<div class="content">
								<i class="icon-star"></i>
								<span data-i18n="mediaLibrary.icon"></span>
							</div>
						</li>
						<li data-type="2">
							<div class="content">
								<i class="icon-film"></i>
								<span data-i18n="mediaLibrary.animation"></span>
							</div>
						</li>
						<li data-type="3">
							<div class="content">
								<i class="icon-facetime-video"></i>
								<span data-i18n="mediaLibrary.video"></span>
							</div>
						</li>
					</ul>
				</li>
			</ul>
		</div>
	</div>
	<div class="col-sm-10 media-list-col">
		<h4></h4>
		<div class="tag-filter">
			<i class="icon-search"></i>
			<input type="text" class="tags additional-tags">
		</div>
		<div class="image-list">
			<div class="upload-button image-list-item">
				<div class="inner">
					<div class="add-placeholder">&plus;</div>
					<h4 data-i18n="mediaLibrary.upload.title"></h4>
					<p data-i18n="mediaLibrary.upload.instructions"></p>
				</div>
			</div>
		</div>
		<div class="more-button-wrapper">
			<button class="button more-button">
				<i class="icon-chevron-down"></i>
				<span data-i18n="mediaLibrary.loadMore"></span>
			</button>
		</div>

	</div>
</div>
