<div class="row">
    <div class="col-sm-6">
        {{#if isNew}}
            <h3>{{t "hazards.alertZones.addAlertZone"}}</h3>
        {{else}}
            <h3>{{t "hazards.alertZones.editAlertZone"}}</h3>
        {{/if}}
    </div>
    <div class="col-sm-6 actions-col">
        <a href="javascript:history.back()"><button type="button" class="button red wide">{{t "common.cancel"}}</button></a>
        <button type="button" class="button green wide save-button">{{t "common.save"}}</button>
    </div>
</div>

<div class="row">
    <form class="form-horizontal">
        <fieldset class="col-sm-5 edit-form">
            <div class="form-group">
                <label class="control-label col-sm-2" for="zone-name">{{t "hazards.alertZones.name"}}</label>
                <div class="col-sm-10">
                    <input id="zone-name" type="text" class="form-control" />
                </div>
            </div>

            <div class="form-group">
                <label class="control-label col-sm-2" for="zone-type">{{t "hazards.alertZones.type"}}</label>
                <div class="col-sm-10">
                    <select id="zone-type" class="form-control">
                        <option>Please select...</option>
                        <option value="blacklist">{{t "hazards.alertZones.blacklist"}}</option>
                        <option value="whitelist">{{t "hazards.alertZones.whitelist"}}</option>
                    </select>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label col-sm-2" for="alert-page">{{t "hazards.alertZones.events"}}</label>
                <div class="col-sm-10">
                    {{#each categories}}
                        <table>
                            <thead>
                                <tr>
                                    <th colspan="2" class="text-left">
                                        <img src="/images/hazards/disaster-icons/{{codeName}}.png" width="32">&nbsp;
                                        {{getBrowserLocaleText name}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            {{#each events}}
                                <tr>
                                    <td class="narrow">
                                        <input name="event" id="event-{{id}}" value="{{id}}" type="checkbox" class="storm-checkbox">
                                    </td>
                                    <td>
                                        <label for="event-{{id}}">
                                            {{getBrowserLocaleText name}}
                                        </label>
                                    </td>
                                </tr>
                            {{/each}}
                            </tbody>
                        </table>
                    {{/each}}
                </div>
            </div>

            <div class="form-group">
                <label class="control-label col-sm-2">{{t "hazards.alertZones.area"}}</label>
                <div class="col-sm-10">
                    <div class="map-preview">
                        <div class="alert-preview-map google-maps"></div>
                        <div class="map-button"></div>
                    </div>
                </div>
            </div>
        </fieldset>
    </form>
</div>
