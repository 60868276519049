<p class="help-block">
	Note: Empty rows will not be visible in the app
</p>
<button class="button addColumn pull-right js-form-control">Add column</button>
<br>
<table class="arpro-properties" id="arpro-properties-table">
	<thead>
	<tr>
			<th><span class="pull-right text-left" style="width: 53%;">Test method</span></th>
			{{#each propertyHeaders}}
				<th class="text-right">
					<div class="label-header">
						<input type="text" class="form-control property-header js-form-control" value="{{title}}" data-index="{{@index}}">
						<button type="button" class="delete-cta remove-column js-form-control" data-index="{{@index}}">&times;</button>
					</div>
				</th>
			{{/each}}
	</tr>
	</thead>
	<tbody>
		{{#each tests}}
			<tr data-index="{{@index}}" class="table-row-section">
				<td class="row-header">
					<span>
						{{#ifEqual name "Compressive strength"}}
							Compressive <br /> strength
						{{else ifEqual name "Compression set"}}
							Compression <br /> set
						{{else ifEqual name "Surface resistance"}}

						{{else ifEqual name "Burn rate (mm/min)"}}

						{{else ifEqual name "Burn Classification "}}

						{{else ifEqual name "Tensile"}}

						{{else ifEqual name "Thermal conductivity (mW/m/K)"}}

						{{else ifEqual name "Acoustic absorption coefficient"}}

						{{else}}
							{{name}}
						{{/ifEqual}}
					</span>

					<span style="width:53%;">
						{{#ifEqual method "ISO 844 (5 mm/min)"}}
							ISO 844 <br /> 5 mm/min
						{{else ifEqual method "ISO 1856 C (Stabilising 24 hours)"}}
							ISO 1856 C <br /> Stabilising 24 hours
						{{else ifEqual method "ISO 3795 (12.5 mm thick)"}}

						{{else ifEqual method "EN 61340-2-3"}}

						{{else ifEqual method "UL-94 (Thickness: 3 to 13 mm)"}}

						{{else ifEqual method "ISO 1798"}}

						{{else ifEqual method "ISO 8301-8302 (10°C)"}}

						{{else ifEqual method "ISO 354 (1250 Hz 30 mm)"}}

						{{else}}
							{{method}}
						{{/ifEqual}}
					</span>
				</td>
				{{#each ../propertyHeaders}}
					<td></td>
				{{/each}}
			</tr>
			{{#each data}}
			<tr data-index="{{@index}}" class="table-row-data">
				<td class="row-header">
					{{#ifEqual label "Strength (kPa)"}}
						<div>
						Tensile <br /> strength (kPa)
						</div>
						<div style="width:53%;">
						ISO 1798
						</div>
					{{else ifEqual label "Elongation (%)"}}
						Tensile <br /> elongation (%)
					{{else ifEqual label "Burn rate (mm/min)"}}
						<div>
							Burn rate <br /> (mm/min)
						</div>
						<div style="width:53%;">
							ISO 3795 <br /> 12.5 mm thick
						</div>
					{{else ifEqual label "Surface resistance"}}
						<div>
							Surface <br /> resistance
						</div>
						<div style="width:53%;">
							EN 61340-2-3
						</div>
					{{else ifEqual label "Burn Classification "}}
						<div>
							Burn <br /> classification
						</div>
						<div style="width:53%;">
							UL-94 <br /> Thickness: 3<br /> to 13 mm
						</div>

					{{else ifEqual label "Thermal conductivity (mW/m/K)"}}
						<div>
							Thermal <br />
							conductivity<br />
							(mW/m/K)
						</div>
						<div style="width:53%;">
							ISO 8301-8302<br />
							10°C
						</div>

					{{else ifEqual label "25% strain - 22 hrs - 23⁰C (%)"}}
					<div style="width:90%;">
						25% strain -<br />
						22 hrs - 23⁰C (%)
					</div>
					<div style="width:0%;">
					</div>

					{{else ifEqual label "Acoustic absorption coefficient"}}
						<div>
							Acoustic <br /> absorption <br />coefficient
						</div>
						<div style="width:53%;">
							ISO 354<br />
							1250 Hz<br />
							30 mm
						</div>
						{{else}}

						{{label}}
					{{/ifEqual}}
					<button type="button" class="tab__cta hide-row js-form-control {{#if disabled}}is-disabled{{/if}}" data-index="{{@index}}" style="display:none;">
						<?xml version="1.0" encoding="UTF-8"?>
						<svg class="on" width="15px" height="9px" viewBox="0 0 15 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
								<!-- Generator: Sketch 47.1 (45422) - http://www.bohemiancoding.com/sketch -->
								<title>Layer/ON</title>
								<desc>Created with Sketch.</desc>
								<defs></defs>
								<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.712522645">
										<g transform="translate(-144.000000, -1969.000000)" fill="#000000">
												<g transform="translate(118.000000, 1821.000000)">
														<g transform="translate(26.000000, 146.500000)">
																<g transform="translate(0.000000, 1.400000)">
																		<g fill-rule="nonzero">
																				<path d="M7.34999987,0.47314814 C4.20003576,0.47314814 1.49257677,2.19300922 0,4.60509251 C1.4913225,7.01498831 4.19752723,8.73703689 7.34999987,8.73703689 C10.5002148,8.73703689 13.207423,7.01717581 14.6999997,4.60509251 C13.2084264,2.19519672 10.5024725,0.47314814 7.34999987,0.47314814 Z M10.611092,4.60509251 C10.611092,6.35509248 9.15614319,7.76481468 7.34999987,7.76481468 C5.54385656,7.76481468 4.08890778,6.35509248 4.08890778,4.60509251 C4.08890778,2.85509254 5.54385656,1.44537035 7.34999987,1.44537035 C9.15614319,1.44537035 10.611092,2.85509254 10.611092,4.60509251 Z M1.17901022,4.60509251 C1.53020475,4.14328697 2.38310576,3.12245365 3.76279857,2.36898144 C3.33634807,3.02523143 3.08549483,3.77870364 3.08549483,4.60509251 C3.08549483,5.43148139 3.33634807,6.1849536 3.76279857,6.84120359 C2.40819109,6.08773138 1.53020475,5.06689806 1.17901022,4.60509251 Z M10.9372012,6.84120359 C11.3636517,6.1849536 11.6145049,5.43148139 11.6145049,4.60509251 C11.6145049,3.77870364 11.3636517,3.02523143 10.9372012,2.36898144 C12.2918087,3.12245365 13.169795,4.14328697 13.5209895,4.60509251 C13.169795,5.06689806 12.2918087,6.08773138 10.9372012,6.84120359 Z M5.84488045,4.60509251 C5.84488045,3.80300919 6.52218419,3.14675921 7.34999987,3.14675921 C8.17781556,3.14675921 8.8551193,3.80300919 8.8551193,4.60509251 C8.8551193,5.40717583 8.17781556,6.06342582 7.34999987,6.06342582 C6.52218419,6.06342582 5.84488045,5.40717583 5.84488045,4.60509251 Z" id="Combined-Shape"></path>
																		</g>
																</g>
														</g>
												</g>
										</g>
								</g>
						</svg>

						<?xml version="1.0" encoding="UTF-8"?>
						<svg class="off" width="15px" height="13px" viewBox="0 0 15 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
								<!-- Generator: Sketch 47.1 (45422) - http://www.bohemiancoding.com/sketch -->
								<title>Layer/ON</title>
								<desc>Created with Sketch.</desc>
								<defs></defs>
								<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.184442935">
										<g transform="translate(-144.000000, -2112.000000)" fill="#000000">
												<g transform="translate(118.000000, 1821.000000)">
														<g transform="translate(26.000000, 291.500000)">
																<g>
																		<g id="noun_878103-copy" transform="translate(0.000000, 1.400000)" fill-rule="nonzero">
																				<path d="M7.34999987,0.47314814 C4.20003576,0.47314814 1.49257677,2.19300922 0,4.60509251 C1.4913225,7.01498831 4.19752723,8.73703689 7.34999987,8.73703689 C10.5002148,8.73703689 13.207423,7.01717581 14.6999997,4.60509251 C13.2084264,2.19519672 10.5024725,0.47314814 7.34999987,0.47314814 Z M10.611092,4.60509251 C10.611092,6.35509248 9.15614319,7.76481468 7.34999987,7.76481468 C5.54385656,7.76481468 4.08890778,6.35509248 4.08890778,4.60509251 C4.08890778,2.85509254 5.54385656,1.44537035 7.34999987,1.44537035 C9.15614319,1.44537035 10.611092,2.85509254 10.611092,4.60509251 Z M1.17901022,4.60509251 C1.53020475,4.14328697 2.38310576,3.12245365 3.76279857,2.36898144 C3.33634807,3.02523143 3.08549483,3.77870364 3.08549483,4.60509251 C3.08549483,5.43148139 3.33634807,6.1849536 3.76279857,6.84120359 C2.40819109,6.08773138 1.53020475,5.06689806 1.17901022,4.60509251 Z M10.9372012,6.84120359 C11.3636517,6.1849536 11.6145049,5.43148139 11.6145049,4.60509251 C11.6145049,3.77870364 11.3636517,3.02523143 10.9372012,2.36898144 C12.2918087,3.12245365 13.169795,4.14328697 13.5209895,4.60509251 C13.169795,5.06689806 12.2918087,6.08773138 10.9372012,6.84120359 Z M7.48255343,3.15240661 L5.85044078,4.73054728 C5.84675955,4.68918335 5.84488045,4.64734023 5.84488045,4.60509251 C5.84488045,3.80300919 6.52218419,3.14675921 7.34999987,3.14675921 C7.39464982,3.14675921 7.43886189,3.14866837 7.48255343,3.15240661 Z M8.84955897,4.47963775 C8.8532402,4.52100168 8.8551193,4.5628448 8.8551193,4.60509251 C8.8551193,5.40717583 8.17781556,6.06342582 7.34999987,6.06342582 C7.30534993,6.06342582 7.26113786,6.06151666 7.21744632,6.05777842 L8.84955897,4.47963775 Z" id="Combined-Shape"></path>
																		</g>
																		<polygon fill-rule="nonzero" points="2.48169892 11.6282124 13.2793824 1.18759514 12.3930323 0.33055555 1.59534881 10.7711728"></polygon>
																</g>
														</g>
												</g>
										</g>
								</g>
						</svg>

						<span class="visually-hidden">Toggle visibility</span>
					</button>
				</td>
				{{#each data}}
					{{#if toDelete}}{{else}}
					<td class="text-right">
						<input type="text" class="form-control arpro-properties-data has-spinners-disabled js-form-control"
							data-id="{{id}}"
							data-index="{{@index}}"
							data-seriesid="{{../id}}"
							data-testid="{{../../id}}"
							value="{{value}}">
					</td>
					{{/if}}
				{{/each}}
			</tr>
			{{/each}}
		{{/each}}
	</tbody>
</table>
