/**
 * Exports {@link AuthSearch}.
 * @module
 */
module.exports = Backbone.Collection.extend(/** @lends AuthSearch.prototype */{
	/**
	 * @constructs AuthSearch
	 * @extends Backbone.Collection
	 * @override
	 */
	initialize: function() {
	},

	/** @override */
	url: function() {
		return App.authRoot + 'search'
	},

	/** @override */
	parse: function(response) {
		if (!response instanceof Array) {
			return [response]
		}

		return response
	}
})
