module.exports = Backbone.View.extend({
	template: require('./disaster-event-edit-item-template'),

	events: {
		'change .onoffswitch-checkbox': 'toggleEnabled'
	},

	initialize: function(options) {
		this.model = options.model
		this.pages = options.pages
		this.selectorId = Math.random().toString(16).substr(2)
	},

	getRenderData: function() {
		return {
			model: this.model.toJSON(),
			pages: this.pages,
			selectorId: this.selectorId
		}
	},

	setPageSelection: function() {
		if (this.model.get('meta')) {
			$('#page-' + this.selectorId).val(this.model.get('meta').src)
		}
	},

	toggleEnabled: function(e) {
		// Get selectionID
		if ($(e.currentTarget).prop("checked")) {
			$('#on-label-' + this.selectorId).addClass('event-toggleOn')
			$('#off-label-' + this.selectorId).removeClass('event-toggleOn')
		} else {
			$('#off-label-' + this.selectorId).addClass('event-toggleOn')
			$('#on-label-' + this.selectorId).removeClass('event-toggleOn')
		}
	}
})
