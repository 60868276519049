{{#ifBorC model.class "R4b_TextMediaModuleBlock" "R4b_TextAssessmentBlock"}}
  <!-- R4b_TextMediaModuleBlock -->
  <i class="icon-align-left"></i>
  <div class="block-content">
    <b><code><i class="icon-lightbulb"></i> {{model.class}}</code></b><br>
    <label>{{t "r4b.modules.blockContent.text.title"}}</label>
    <br><span class="text-content enter-text" data-placeholder="{{t 'r4b.modules.blockContent.enterText'}}" contenteditable="true">{{#ifEqual model.class "R4b_TextMediaModuleBlock"}}{{getLocaleText model.data.content}}{{else}}{{getLocaleText model.text.content}}{{/ifEqual}}</span>

{{else ifEqual model.class "R4b_TextInputModuleBlock"}}
  <!-- R4b_TextMediaModuleBlock -->
  <i class="icon-pencil"></i>
  <div class="block-content"><b><code><i class="icon-lightbulb">
  </i> {{model.class}}</code></b><br>
    <label>{{t "r4b.modules.blockContent.textInput.title"}}</label>
    <br><span class="input-content enter-text" data-placeholder="{{t 'r4b.modules.blockContent.textInput.help'}}" contenteditable="true">{{getLocaleText model.text.content}}</span>
    <div style="display: flex; margin-top: 5px;">
      <label id="task-label" for="input-area-{{selectorId}}" style="margin-right: 5px;"> {{t "r4b.modules.blockContent.textInput.multi"}} </label>
        <div class="onoffswitch">
          <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox text-input-area" id="input-area-{{selectorId}}" {{#if model.area}}checked{{/if}}>
          <label class="onoffswitch-label" for="input-area-{{selectorId}}"></label>
        </div>
    </div>

{{else ifEqual model.class "R4b_TaskMediaModuleBlock"}}
<!-- R4b_TextMediaModuleBlock -->
  <i class="icon-ok"></i>
  <div class="block-content"><b><code><i class="icon-lightbulb">
  </i> {{model.class}}</code></b><br>
    <label>{{t "r4b.modules.blockContent.task.title"}}</label>
    <br><span class="task-content enter-text" data-placeholder="{{t 'r4b.modules.blockContent.enterText'}}" contenteditable="true">{{getLocaleText model.data.content}}</span>

{{else ifEqual model.class "R4b_QuoteMediaModuleBlock"}}
<!-- R4b_TextMediaModuleBlock -->
  <i class="icon-quote-left"></i>
  <div class="block-content">
    <b><code><i class="icon-lightbulb"></i> {{model.class}}</code></b><br>
    <label>{{t "r4b.modules.blockContent.quote.title"}}</label>
    <br><span class="text-content quote-input enter-text" data-placeholder="{{t 'r4b.modules.blockContent.enterText'}}" contenteditable="true">{{getLocaleText model.data.content}}</span>
		<br><span class="citation-content enter-text" data-placeholder="{{t 'r4b.modules.blockContent.quote.citation'}}" contenteditable="true">{{getLocaleText model.citation.content}}</span>

{{else ifEqual model.class "R4b_BreakMediaModuleBlock"}}
  <!-- R4b_BreakMediaModuleBlock -->
  <i class="icon-minus"></i>
  <div class="block-content"><b><code><i class="icon-lightbulb">
  </i> {{model.class}}</code></b><br>
    <p style="text-align: center;">{{t "r4b.modules.blockContent.break"}}</p>

{{else ifBorC model.class "R4b_VideoMediaModuleBlock" "R4b_VideoAssessmentBlock"}}
  <!-- R4b_VideoMediaModuleBlock -->
  <i class="icon-facetime-video"></i>
  <div class="block-content"><b><code><i class="icon-lightbulb">
  </i> {{model.class}}</code></b><br>
    <label>{{t "r4b.modules.blockContent.videoLink"}}</label>
    <br><input type="url" style="width: 100%;" class="link-content" placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ" value="{{model.data.src.destination}}"></input>

{{else ifBorC model.class "R4b_LinkMediaModuleBlock" "R4b_HelpMediaModuleBlock"}}
  <!-- R4b_LinkMediaModuleBlock -->
  <i class="{{#ifEqual model.class 'R4b_LinkMediaModuleBlock'}}icon-link{{else}}icon-question-sign{{/ifEqual}}"></i>
  <div class="block-content"><b><code><i class="icon-lightbulb">
    </i> {{model.class}}</code></b>
    <br>
    <label>{{#ifEqual model.class "R4b_LinkMediaModuleBlock"}} {{t "r4b.modules.blockContent.link.title"}} {{else}} {{t "r4b.modules.blockContent.help.title"}} {{/ifEqual}}</label>
    {{#ifEqual model.class "R4b_HelpMediaModuleBlock"}}<span class="help-content enter-text" data-placeholder="{{t 'r4b.modules.blockContent.enterText'}}" contenteditable="true">{{getLocaleText model.data.content}}</span>{{/ifEqual}}
    <div class="link-selector" data-id="{{selectorId}}"></div>

{{else ifBorC model.class "R4b_ImageMediaModuleBlock" "R4b_ImageAssessmentBlock"}}
  <!-- R4b_ImageMediaModuleBlock -->
  <i class="icon-picture"></i>
  <div class="block-content"><b><code><i class="icon-lightbulb">
    </i> {{model.class}}</code></b>
    <div class="add-block-image" style="{{getBackgroundImageStyle model.data.src 'contain'}}" data-property="data" data-id="{{selectorId}}"></div>

{{else ifEqual model.class "R4b_SliderInputModuleBlock"}}
  <!-- R4b_SliderInputModuleBlock -->
  <i class="icon-resize-horizontal"></i>
  <div class="block-content"><b><code><i class="icon-lightbulb">
    </i> {{model.class}}</code></b><br>
		<label>{{t "r4b.modules.blockContent.text.title"}}</label>
    <br><span class="input-content enter-text" data-placeholder="{{t 'r4b.modules.blockContent.enterText'}}" contenteditable="true">{{getLocaleText model.text.content}}</span>
    <div class="form-group col-sm-6">
      <label for="{{selectorId}}-start">{{t "editor.inspector.properties.range.start"}}</label>
      <input id="{{selectorId}}-start" type="number" class="form-control slider" value="{{model.range.start}}"></input>
      <label for="{{selectorId}}-length">{{t "editor.inspector.properties.range.length"}}</label>
      <input id="{{selectorId}}-length" type="number" class="form-control slider" value="{{model.range.length}}"></input>
    </div>
    <div class="form-group col-sm-6">
      <label for="{{selectorId}}-value">{{t "editor.inspector.properties.initialPosition"}}</label>
      <input id="{{selectorId}}-value" type="number" class="form-control slider" value="{{model.value}}"></input>
      <label for="{{selectorId}}-interval">{{t "r4b.modules.blockContent.slider.interval"}}</label>
      <input id="{{selectorId}}-interval" type="number" class="form-control slider" value="{{model.interval}}"></input>
    </div>
    <div class="form-group col-sm-6">
      <label for="{{selectorId}}-startLabel">{{t "r4b.modules.blockContent.slider.startLabel"}}</label>
      <input id="{{selectorId}}-startLabel" class="form-control slider" value="{{getLocaleText model.startLabel.content}}"></input>
    </div>
    <div class="form-group col-sm-6">
      <label for="{{selectorId}}-endLabel">{{t "r4b.modules.blockContent.slider.endLabel"}}</label>
      <input id="{{selectorId}}-endLabel" class="form-control slider" value="{{getLocaleText model.endLabel.content}}"></input>
    </div>
    <label>{{t "common.preview"}}</label>
    <input id="{{selectorId}}-slider" type="range" disabled></input>

{{else ifBorC model.class "R4b_CheckboxInputModuleBlock" "R4b_RadioInputModuleBlock" }}
  <!-- R4b_CheckboxInputModuleBlock -->
  {{#ifEqual model.class "R4b_CheckboxInputModuleBlock"}}
    <i class="icon-check"></i>
  {{else}}
    <i class="icon-circle-blank"></i>
  {{/ifEqual}}
  <div class="block-content"><b><code><i class="icon-lightbulb">
    </i> {{model.class}}</code></b>
    <div class="form-group">
      <label for="{{selectorId}}-CheckRadioText">{{t "editor.inspector.properties.title.title"}}</label>
      <input type="text" class="form-control checkRadio-title" id="{{selectorId}}-CheckRadioText" value="{{getLocaleText model.text.content}}">
    </div>
    <div class="form-group">
      <label for="{{selectorId}}-CheckRadioAdd">
        {{#ifEqual model.class "R4b_CheckboxInputModuleBlock"}}
          {{t "r4b.modules.blockContent.checkbox.title"}}
        {{else}}
          {{t "r4b.modules.blockContent.radio.title"}}
        {{/ifEqual}}
      </label>
      <div class="input-group">
        <input type="text" class="form-control" id="{{selectorId}}-CheckRadioAdd">
        <span class="input-group-btn">
          <button class="btn btn-default add-checkbox-radio" type="button">{{t "common.add"}}</button>
        </span>
      </div><!-- /input-group -->
    </div>
    {{#each model.options}}
      {{#ifEqual ../model.class "R4b_CheckboxInputModuleBlock"}}
        <input type="checkbox" data-key="{{@key}}" id="{{../selectorId}}-{{@key}}-checkbox">
          <label for="{{../selectorId}}-{{@key}}-checkbox">{{getLocaleText this.content}}</label>
        </input><br>
      {{else}}
        <input type="radio" data-key="{{@key}}" id="{{../selectorId}}-{{@key}}-checkbox" name="{{../selectorId}}">
          <label for="{{../selectorId}}-{{@key}}-checkbox">{{getLocaleText this.content}}</label>
        </input><br>
      {{/ifEqual}}
    {{/each}}
{{else}}
<!-- Anything else -->
  <i class="icon-lightbulb"></i>
  <div class="block-content">
    <b><code><i class="icon-lightbulb"></i> {{model.class}}</code></b>
{{/ifBorC}}

  <hr>
	<div class="bottom">
		<div class="identifier-form">
			{{#if identifierExists}}
		  <label for="{{selectorId}}-identifier">{{t "r4b.modules.blockContent.identifier"}}</label>
		  <input type="text" class="identifier" id="{{selectorId}}-identifier" placeholder="{{t 'r4b.modules.blockContent.addIdentifier'}}" value="{{model.identifier}}">
			{{/if}}
		</div>
		<i{{#if model.id}} data-id={{model.id}} {{/if}} class="icon-trash delete-block red-delete"></i>
	</div>
</div>
