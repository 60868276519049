<div id="top-nav"></div>
<div class="title-bar">
	{{#if showAddApp}}
	<a href="/apps/new">
		<button class="button right" data-i18n="appCreate.title"></button>
	</a>
	{{/if}}
</div>
<!-- <div class="app-error" data-i18n="error.noApps"></div> -->
<div class="container-fluid">
	<div class="row">
		<div class="col-md-12">
			{{#if sections}}
				<legend>{{t "common.sections"}}</legend>
			{{/if}}
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			{{#each sections}}
				<div class="dash-section">
					<h2>{{name}}</h2>
					<div class="dash-list-container">
						{{#each pages}}
							<div class="dash-list-item">
								<a href="/apps/{{#if ../../app.id}}{{../../app.id}}{{else}}noApp{{/if}}/{{url}}">
									<button class="button">{{name}}</button>
								</a>
							</div>
						{{/each}}
					</div>
				</div>
			{{/each}}
		</div>
	</div>
</div>
