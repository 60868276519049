<td>
	{{name}}
</td>
<td>
	{{code}}
</td>
<td>
	{{scope}}
</td>
<td>
	{{#each languages}}
		{{this}}
	{{/each}}
</td>
<td>
    <button class="button light configure-button input-block-level"{{#unless enabled}} disabled{{/unless}}>{{t "hazards.feeds.configure"}}</button>
</td>
<td class="tools">
	{{#if available}}
		{{#if enabled}}
			<button class="button red delete-feed">{{t "hazards.feeds.disable"}}</button>
		{{else}}
        	<button class="button enable-feed" data-id="{{id}}">{{t "hazards.feeds.enable"}}</button>
		{{/if}}
	{{/if}}
</td>
