module.exports = Backbone.Model.extend({
	urlRoot: function() {
		return App.apiRoot + 'alerts'
	},

	defaults: function() {
		return {
			type: {category: 0, event: 0},
			from: parseInt(Date.now() / 1000, 10)
		}
	}
})
