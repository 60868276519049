var ListItemView = require('editor/canvas/list-item-view')

module.exports = Backbone.View.extend({
	template: require('./collection-item-view-template'),
	className: 'CollectionItem editable',

	events: {
		click: 'click'
	},

	initialize: function() {
		this.listenTo(this.model, 'change', this.render, this)
	},

	afterRender: function() {
		this.$el.addClass(this.model.get('class'))

		// Add editing state if the inspector's open
		if (this.model.editing) {
			this.$el.addClass('editing')
		}
	},

	click: ListItemView.prototype.click
})
