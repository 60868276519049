/**
 * How often to check for expiry, in seconds.
 * @const {number}
 * @default
 */
var EXPIRY_CHECK_INTERVAL = 5

/**
 * How long before actual expiry to treat as expired, in seconds.
 * @const {number}
 * @default
 */
var EXPIRY_THRESHOLD = 30

/**
 * How long before actual expiry to ask to relock, in seconds.
 * @type {number}
 * @default
 */
var EXPIRY_CHECK_THRESHOLD = 90

/**
 * Exports {@link Lock}.
 * @module
 */
module.exports = Backbone.Model.extend(/** @lends Lock.prototype */{
	idAttribute: 'token',

	/**
	 * @constructs Lock
	 * @extends Backbone.Model
	 * @override
	 */
	initialize: function() {
	},

	url: function() {
		if (!this.objectId) {
			throw new Error('Called lock() on an empty Lock.')
		}

		return App.apiRoot + 'objects/' + this.objectId + '/lock'
	},

	defaults: {
		isLocked: false,
		isExpiring: false,
		expires: 0,
		token: null
	},

	/**
	 * Attempt to lock the page with the specified object ID.
	 * @returns {Promise} jqXHR wrapper in a Promise, resolving on request
	 *     completion.
	 */
	lock: function() {
		var jqXHR = this.save(null, {data: null})
		jqXHR.fail(this.lockFail.bind(this))

		return Promise.resolve(jqXHR)
	},

	/**
	 * Attempt to unlock the page wth the specified object ID.
	 * @param {boolean} [block=false] Whether or not this request should be
	 *     blocking.
	 * @returns {Promise} jqXHR wrapper in a Promise, resolving on request
	 *     completion.
	 */
	unlock: function(block) {
		var jqXHR = this.destroy({async: !block})

		// Clear data immediately.
		this.clearLock()
		return Promise.resolve(jqXHR)
	},

	// Modify server response to fit a flat model.
	parse: function(response) {
		this.checkExpiryInterval = setInterval(this.checkExpiry.bind(this), EXPIRY_CHECK_INTERVAL * 1000)

		response.isLocked = response.owner
		response.token = response.token || null
		response.expires = this.setExpires(response.expires.remaining)
		response.isExpiring = response.expires.remaining < EXPIRY_CHECK_THRESHOLD

		// Set the token to a truthy value if this can be re-locked.
		if (response.owner && !response.token) {
			response.token = ''
		}

		return response
	},

	// Set token expiry timestamp from number of seconds remaining.
	setExpires: function(remaining) {
		var expires = Math.floor(Date.now() / 1000) + remaining
		this.set('expires', expires)
		return expires
	},

	// Update model representation with existing lock details.
	lockFail: function(jqXHR) {
		var data = jqXHR.responseJSON

		if (jqXHR.status === 423) {
			var attrs = this.parse(data['client error'].data)
			this.set(attrs)
		}
	},

	/**
	 * Resets the lock to its initial state.
	 */
	clearLock: function() {
		this.clear({silent: true})
		this.set(this.defaults)
	},

	// Check token expiry time, trigger relevant events.
	checkExpiry: function() {
		var remaining  = this.getTimeRemaining(),
			isExpiring = remaining < EXPIRY_CHECK_THRESHOLD

		this.set('isExpiring', isExpiring)

		if (remaining <= 0) {
			this.set({
				isLocked: false,
				token: null
			})

			clearInterval(this.checkExpiryInterval)
		}
	},

	/**
	 * @returns {number} The number of seconds remaining before the current
	 *     token expires.
	 */
	getTimeRemaining: function() {
		var remaining = this.get('expires') - parseInt(Date.now() / 1000, 10) - EXPIRY_THRESHOLD
		return Math.max(remaining, 0)
	},

	/**
	 * @returns {boolean} Whether or not this lock has been acquired.
	 */
	isLocked: function() {
		return this.get('isLocked')
	},

	/**
	 * @returns {boolean} Whether or not the token is about to expire in the
	 *     next {@link EXPIRY_THRESHOLD} seconds.
	 */
	isExpiring: function() {
		return this.get('isExpiring')
	}
})
