/**
 * Exports {@link SystemUsersList}.
 * @module
 */
module.exports = Backbone.Collection.extend(/** @lends SystemUsersList.prototype */{
	/**
	 * @constructs SystemUsersList
	 * @extends Backbone.Collection
	 * @override
	 * @classdesc Defines a list for retrieving users of a single system within
	 *     Global Auth.
	 */
	initialize: function(models, options) {
		options = options || {}

		if (options.systemId === undefined) {
			throw new Error('No system ID specified')
		}

		/** @type {number} */
		this.systemId = options.systemId
	},

	/** @override */
	url: function() {
		return App.authRoot + 'systems/' + this.systemId + '/users'
	}
})
