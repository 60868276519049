<details>
	<summary>
		<h5><i class="icon-link"></i> Linkable image: {{{getLocaleTextHTML data.link.title}}}</h5>
		<span class="c-image-list__actions">
			<button class="c-image-list__button btn-link up" aria-label="move item up"><i class="icon-arrow-up"></i></button>
			<button class="c-image-list__button btn-link down" aria-label="move item down"><i class="icon-arrow-down"></i></button>
			<button class="c-image-list__button btn-link remove" aria-label="remove item"><i class="icon-remove"></i></button>
		</span>
	</summary>
	<div>
		<div class="js-image-detail">
			<div class="form-group">
				<label data-i18n="editor.inspector.properties.image.title" for="{{viewId}}-image"></label>
				<img src="{{getPreviewUrl data.image}}" data-property="image" class="js-img-preview">
				<div class="row">
					<div class="col-xs-6">
						<button class="button block js-image-select-button" id="{{viewId}}-image" data-i18n="editor.inspector.properties.image.chooseButton"></button>
					</div>
					<div class="col-xs-6">
						<button class="button block red col-xs-6 js-image-remove-button" data-property="image">
							<i class="icon-remove"></i>&nbsp;<span data-i18n="editor.inspector.properties.image.removeButton"></span>
						</button>
					</div>
				</div>
			</div>
			<div class="form-group link-title-select">
				<label for="{{viewId}}-alt" data-i18n="editor.inspector.properties.images.alt"></label>
				<input type="text" class="form-control js-alt" id="{{viewId}}-alt" value="{{getLocaleText data.image_alt}}">
			</div>
		</div>
		<div class="form-group developer-mode">
			<label for="{{viewId}}-analytics" data-i18n="editor.inspector.properties.analyticsEvent"></label>
			<input type="text" class="form-control js-analytics" id="{{viewId}}-analytics" value="{{data.analytics_event}}">
		</div>
		<div class="js-link-detail"></div>
	</div>

</details>

