<div class="form-inline form-group">
    <select name="trigger-subclass" class="form-control">
        {{#each triggerSubclasses}}
            <option value="{{this}}">
                {{t (add (add 'triggers.subclasses.' this) '.singular')}}
            </option>
        {{/each}}
    </select>

    <button type="button" class="button add-trigger">{{t "common.add"}}</button>
</div>

<div id="achievement-trigger-list" class="form-group"></div>
