<div class="modal-header">
	<button class="button light pull-left cancel-button">{{t "common.cancel"}}</button>
	<button class="button positive pull-right save-button">{{t "common.save"}}</button>
	<h4>{{getBrowserLocaleText name}} {{t "hazards.alerts.title"}}</h4>
</div>
<div class="modal-body">
	<table>
		<thead>
			<th>{{t "hazards.disasters.enable"}}</th>
			<th>{{t "push.type"}}</th>
			<th>{{t "hazards.disasters.whatNow"}}</th>
		</thead>
		<tbody>
			{{#each alerts}}
				<tr data-id="{{id}}">
					<td class="center"><input type="checkbox" class="storm-checkbox enabled-checkbox" {{#if assignment}}checked{{/if}}></td>
					<td>{{getBrowserLocaleText name}}</td>
					<td><select class="input-block-level what-now-page" {{#unless assignment}}disabled{{/unless}}>{{{../selectOptions}}}</select></td>
				</tr>
			{{/each}}
		</tbody>
	</table>
</div>
