module.exports = Backbone.Collection.extend({
	url: function() {
		return App.apiRoot + 'stages'
	},

	// Data must be saved under a 'stages' key. Override save.
	save: function(attrs, options) {
		options = options || {}
		options.attrs = {stages: this.toJSON()}

		Backbone.Collection.prototype.save.call(this, null, options)
	}
})
