<div class="row">
	<form class="form-horizontal">
		<fieldset>
			<div class="col-sm-5 edit-form">
				<legend>{{t "hazards.alerts.testAlert"}}</legend>

				<div class="form-group">
					<label class="col-sm-3 control-label" for="alert-type">{{t "push.type"}}</label>
					<div class="col-sm-9">
						<select id="alert-type" class="form-control">
							{{#each disasters}}
								<optgroup label="{{getBrowserLocaleText name}}">
									{{#each events}}
										<option value="{{disasterId}}-{{id}}">{{getBrowserLocaleText name}}</option>
									{{/each}}
								</optgroup>
							{{/each}}
						</select>
						<p class="help-block">{{t "hazards.alerts.testAlertDesc"}}</p>
					</div>
				</div>

				<div class="form-group" id="severity-control">
					<label class="col-sm-3 control-label" for="alert-severity">{{t "hazards.alerts.custom.severity"}}</label>
					<div class="col-sm-9">
						<input type="range" id="alert-severity" class="col-sm-4" value="1" min="1" max="10" step="0.1"> <span id="alert-severity-count">1</span>
					</div>
				</div>
			</div>
			<div class="col-sm-7 actions-col">
				<a href="javascript:history.go(-1)"><button class="button white" type="button">{{t "common.back"}}</button></a>
				<button class="button green save-button" type="submit">{{t "push.saveAndSend"}}</button>
			</div>
		</fieldset>
	</form>
</div>
