module.exports = Backbone.Model.extend({
	initialize: function() {
		this.children = new Backbone.Model()
		this.children.url = function() {
			return this.url() + '/children'
		}.bind(this)
	},

	urlRoot: function() {
		return App.apiRoot + 'levels'
	},

	defaults: function() {
		return {
			badgeId: 0,
			build: 'd',
			name: '',
			description: '',
			pageUrl: '',
			parentId: 0,
			position: 0
		}
	}
})
