module.exports = [
	'Book',
	'Business',
	'Catalogs',
	'Education',
	'Entertainment',
	'Finance',
	'Food & Drink',
	'Games',
	'Health & Fitness',
	'Lifestyle',
	'Kids',
	'Medical',
	'Music',
	'Navigation',
	'News',
	'Newsstand',
	'Photo & Video',
	'Productivity',
	'Reference',
	'Social Networking',
	'Sports',
	'Travel',
	'Utilities',
	'Weather'
]
