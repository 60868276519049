var LanguageList = require('editor/language-list')

module.exports = Backbone.View.extend({
	template: require('./languages-view-template'),

	events: {
		'click .save-languages': 'saveLanguages',
		'click .add-language': 'addLanguage'
	},

	initialize: function(options) {
		this.readyCount = 0

		// Get app details
		this.app = options.app

		// Fetch list of all society languages
		this.allLanguages = new LanguageList(null, {societyId: this.app.get('societyId')})
		this.allLanguages.once('sync', this.ready, this)
		this.allLanguages.fetch()

		// Fetch list of languages enabled for this app
		this.appLanguages = new LanguageList(null, {appId: this.app.id})
		this.appLanguages.once('sync', this.ready, this)
		this.appLanguages.fetch()

		this.allLanguages.comparator = this.appLanguages.comparator = 'name'
	},

	getRenderData: function() {
		return {
			appId: this.app.id,
			languages: this.allLanguages.toJSON(),
			appLanguages: this.appLanguages.toJSON()
		}
	},

	afterRender: function() {
		// Set current publishable values.
		this.appLanguages.each(function(language) {
			this.$('.publish-option[value=' + language.id + ']').each(function(i) {
				if ((language.get('publishable') & (i + 1)) !== 0) {
					this.checked = true
				}
			})
		}, this)

		this.delegateEvents()
	},

	ready: function() {
		if (++this.readyCount < 2) {
			return
		}

		// Remove all selected languages from the full language list.
		this.appLanguages.forEach(function(lang) {
			this.allLanguages.remove(this.appLanguages.get(lang.id))
		}, this)

		this.render()
	},

	addLanguage: function() {
		var id = Number($('.new-language').val()),
			lang = this.allLanguages.get(id)

		if (!lang) {
			return
		}

		// Add language assignment, remove from dropdown.
		this.appLanguages.add(lang)
		this.allLanguages.remove(lang)

		lang.set('publishable', 0)

		this.render()
	},

	saveLanguages: function() {
		App.startLoad()

		// Update all publishable statuses
		this.appLanguages.each(function(lang) {
			var publishable = 0

			this.$('.publish-option[value=' + lang.id + ']').each(function(i) {
				if (this.checked) {
					publishable += 1 << i
				}
			})

			lang.set('publishable', publishable)
		}, this)

		var data = {
			languages: this.appLanguages.toJSON()
		}

		this.appLanguages.once('sync', this.saveComplete, this)
		this.appLanguages.save(null, {
			data: JSON.stringify(data)
		})

		return false
	},

	saveComplete: function() {
		App.stopLoad()
		App.showToast($.t('hazards.disasters.saveSuccess'))
		this.render()

		// Update language list on app model.
		this.app.languageList.reset(this.appLanguages.models)
	}
})
