var Tip = require('./tip')

/**
 * Exports {@link TipList}.
 * @module
 */
module.exports = Backbone.Collection.extend(/** @lends TipList.prototype */{
	/**
	 * @constructs TipList
	 * @extends Backbone.Collection
	 * @override
	 * @classdesc Stores a list of {@link Tip} models for the Mind Emoodji app.
	 */
	initialize: function() {
	},

	/** @override */
	model: Tip,

	/**
	 * @override
	 * @returns {string} URL of the Mind tips endpoint.
	 */
	url: function() {
		return Tip.prototype.urlRoot()
	},

	/**
	 * Extracts the tip list from the "data" key in the response.
	 * @override
	 */
	parse: function(response) {
		return response.data
	}
})
