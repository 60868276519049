/**
 * Exports {@link StormView}.
 * @module
 */
module.exports = Backbone.View.extend(/** @lends StormView.prototype */{
	/**
	 * @constructs StormView
	 * @extend Backbone.View
	 * @override
	 */
	initialize: function() {
	},

	/**
	 * @returns {string} The title of this navigation section.
	 */
	getSectionTitle: function() {
		return ''
	},

	/**
	 * @returns {string} the title of this page within the navigation section.
	 */
	getPageTitle: function() {
		return ''
	},

	/**
	 * Describes a page accessible from a Storm section's subnavigation.
	 * @typedef {Object} StormView~PageDef
	 * @property {string} name The name of the page, displayed as the link
	 *     title.
	 * @property {string} url The relative URL slug of the page, used as the
	 *     link destination. Should not include any leading or trailing
	 *     slashes.
	 * @property {StormView~Visibility} isVisible Visibility check.
	 * @property {Array.<StormView>} views List of pages which are to be
	 *     treated as
	 *     'children' of this page, i.e. pages for which this navigation item
	 *     should have a selected state when visited.
	 */

	/**
	 * Describes a visibility check for a {@link StormView}.
	 * @typedef {Function} StormView~Visibility
	 * @returns {boolean} Whether or not the view should be displayed.
	 */

	/**
	 * @returns {Array.<StormView~PageDef>} The list of child pages to render
	 *     in the subnavigation
	 */
	getPages: function() {
		return []
	},

	/**
	 * @returns {Array.<StormView~PageDef>} Result of {@link #getPages},
	 *     filtered to only return those which are visible.
	 */
	getVisiblePages: function() {
		return this.getPages().filter(function(p) {
			return ('isVisible' in p) && p.isVisible()
		})
	},

	/**
	 * View of actions to be rendered in the right hand side of the nav bar.
	 * @type {Backbone.View}
	 */
	actionsView: null,

	/**
	 * @returns {boolean} Whether or not the view should be visible (based on
	 *     permissions/app type etc.)
	 */
	isVisible: function() {
		// By default, show if any child pages are visible.
		return this.getVisiblePages().length > 0
	},

	/**
	 * Loads any common data required by this section.
	 * @returns {Promise|jQuery.Deferred} Promise which is resolved on data
	 *     load completion.
	 */
	loadData: function() {
		return Promise.resolve()
	}
})
