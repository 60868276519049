var RobotoApp = require('./roboto-app')

var HAZARDS_SYSTEM_ID = 100006

var RobotoAppList = Backbone.Collection.extend({
	model: RobotoApp,

	initialize: function(models, options) {
		this.systemId = options.systemId
		this.overrideExtraFetch = options.overrideExtraFetch
	},

	url: function() {
		return App.robotoRoot + 'systems/' + this.systemId + '/apps'
	},

	// Override fetch on GDPC to also fetch Hazards system apps.
	fetch: function() {
		if (this.systemId === 3 && !this.overrideExtraFetch) {
			var hazardsApps = new RobotoAppList(null, {systemId: HAZARDS_SYSTEM_ID}),
				gdpcApps = new RobotoAppList(null, {systemId: this.systemId, overrideExtraFetch: true}),
				fetch1 = gdpcApps.fetch(),
				fetch2 = hazardsApps.fetch(),
				promise = new jQuery.Deferred(),
				self = this

			var addApps = function(apps) {
				self.add(apps)
			}

			fetch1.then(addApps)
			fetch2.then(addApps)

			$.when(fetch1, fetch2).then(function() {
				self.trigger('sync', self, [], arguments[0])
				promise.resolve(self.toJSON())
			})

			return promise
		}

		return Backbone.Collection.prototype.fetch.apply(this, arguments)
	}
})

module.exports = RobotoAppList
