<div class="panel panel-default">
    <div class="panel-heading">
        {{t "triggers.subclasses.RaisedTrigger.amount"}}
        <button type="button" class="close remove-trigger">&times;</button>
    </div>
    <div class="panel-body form-inline">
        <input name="amount" value="{{amount}}" class="form-control" />
        <select name="currency" class="form-control">
            {{#each currencies}}
                <option value="{{code}}" {{#ifEqual code ../currency}}selected{{/ifEqual}}>{{description}}</option>
            {{/each}}
        </select>
    </div>
</div>
