    {{#if user}}
    <tr>
        <td>Changed by</td>
        <td>{{user}}</td>
    </tr>
    {{/if}}
    <tr>
        <td>Changed at</td>
        <td>{{timestampToUTCString timestamp}}</td>
    </tr>
  {{#if type}}
	<tr>
		<td class="audit-child-list-item-col">Object Type</td>
		<td>{{type}}</td>
	</tr>
  {{/if}}
	<tr>
		<td>Change Type</td>
		<td>
			{{details.type}}
			{{#ifEqual details.type "Language"}}
				<em>({{details.language}})</em>
			{{/ifEqual}}

		</td>
	</tr>
	<tr>
		<td>From</td>
		<td>
			{{#with details}}
				{{#ifEqual type "Language"}}
						{{#if from}}
							{{from}}
							{{else}}
							<em>-</em>
						{{/if}}

				{{/ifEqual}}

				{{#ifEqual type "Update Array Values"}}
					{{#if from}}
							{{from}}
							{{else}}
							<em>-</em>
						{{/if}}
				{{/ifEqual}}

				{{#ifEqual type "Moved Array Value"}}
					{{#if from}}
							{{from}}
							{{else}}
							<em>-</em>
						{{/if}}
				{{/ifEqual}}

				{{#ifEqual type "New Object"}}
					<p>N/A</p>
				{{/ifEqual}}

				{{#ifEqual type "Remove Object"}}
					{{class}}
				{{/ifEqual}}

				{{#ifEqual type "Update Value"}}
					{{#if from}}
							{{from}}
							{{else}}
							<em>-</em>
						{{/if}}
				{{/ifEqual}}

				{{#ifEqual type "Native Strings"}}
					{{#key_value changes "key" "value"}}
						Key: <code>{{key}}</code>
						 <br>

								{{#key_value value "lang" "obj"}}
										<em>({{lang}}):</em>
										{{#if obj.from}}
											{{obj.from}}
											{{else}}
											-
										{{/if}}
										<br>
								{{/key_value}}
					{{/key_value}}
			{{/ifEqual}}

				{{!-- {{from}} --}}
			{{/with}}
		</td>
		<tr style="border-bottom: 1px solid #000">
			<td>To</td>

		<td>
			{{#with details}}

			{{#ifEqual type "Language"}}
						{{#if to}}
							{{to}}
							{{else}}
							<em>-</em>
						{{/if}}

				{{/ifEqual}}

				{{#ifEqual type "Update Array Values"}}
					{{#if to}}
							{{to}}
							{{else}}
							<em>-</em>
						{{/if}}
				{{/ifEqual}}

				{{#ifEqual type "Moved Array Value"}}
					{{#if to}}
							{{to}}
							{{else}}
							<em>-</em>
						{{/if}}
				{{/ifEqual}}

				{{#ifEqual type "New Object"}}
					{{class}}
				{{/ifEqual}}

				{{#ifEqual type "Remove Object"}}
					<p>N/A</p>
				{{/ifEqual}}

				{{#ifEqual type "Update Value"}}
						{{#if to}}
							{{to}}
							{{else}}
							<em>-</em>
						{{/if}}
				{{/ifEqual}}



			{{#ifEqual type "Native Strings"}}
					{{#key_value changes "key" "value"}}
						Key: <code>{{key}}</code>
						 <br>

								{{#key_value value "lang" "obj"}}
										<em>({{lang}}):</em>
										{{#if obj.to}}
											{{obj.to}}
											{{else}}
											-
										{{/if}}
										<br>
								{{/key_value}}
					{{/key_value}}
			{{/ifEqual}}



			{{/with}}
		</td>
	</tr>

	</tr>


{{!--
<td>{{type}}</td>
<td>
	{{#with details}}
		<dl class="dl-horizontal">
			<dt>Change type</dt>
			<dd>{{type}}</dd>

			{{#ifEqual type "Language"}}
				<dt>Language</dt>
				<dd>{{language}}</dd>
				<dt>From</dt>
				<dd>{{#if from}}{{from}}{{else}}-{{/if}}</dd>
				<dt>To</dt>
				<dd>{{#if to}}{{to}}{{else}}-{{/if}}</dd>
			{{/ifEqual}}

			{{#ifEqual type "New Object"}}
				<dt>Type</dt>
				<dd>{{class}}</dd>
			{{/ifEqual}}

			{{#ifEqual type "Update Array Values"}}
				<dt>Property</dt>
				<dd>{{property}}</dd>
				<dt>From:</dt>
				<dd>
					<ol>
						{{#each from}}
							<li>{{this}}</li>
						{{/each}}
					</ol>
				</dd>
				<dt>To:</dt>
				<dd>
					<ol>
						{{#each to}}
							<li>{{this}}</li>
						{{/each}}
					</ol>
				</dd>
			{{/ifEqual}}

			{{#ifEqual type "Moved Array Value"}}
				<dt>Property</dt>
				<dd>{{property}}</dd>
				<dt>From</dt>
				<dd>{{#if from}}{{from}}{{else}}-{{/if}}</dd>
				<dt>To</dt>
				<dd>{{#if to}}{{to}}{{else}}-{{/if}}</dd>
			{{/ifEqual}}

			{{#ifEqual type "Update Value"}}
				<dt>Property</dt>
				<dd>{{property}}</dd>
				<dt>From</dt>
				<dd>{{#if from}}{{from}}{{else}}-{{/if}}</dd>
				<dt>To</dt>
				<dd>{{#if to}}{{to}}{{else}}-{{/if}}</dd>
			{{/ifEqual}}

			{{#ifEqual type "Native Strings"}}
				{{#key_value changes "key" "value"}}
					<dt>{{key}}</dt>
					<dd>
						<ul>
							{{#key_value value "lang" "obj"}}
								<li>
									<b>{{lang}}</b>:
									<dl>
										<dt>From</dt>
										<dd>{{#if obj.from}}{{obj.from}}{{else}}-{{/if}}</dd>
										<dt>To</dt>
										<dd>{{#if obj.to}}{{obj.to}}{{else}}-{{/if}}</dd>
									</dl>
								</li>
							{{/key_value}}
						</ul>
					</dd>
				{{/key_value}}
			{{/ifEqual}}
		</dl>
	{{/with}}
</td>
 --}}
