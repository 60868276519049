var MediaLibrary          = require('media-library/media-library-view'),
	MediaSelectorView       = require('media-library/media-selector-view')

module.exports = Backbone.View.extend({
	className: 'moduleBlock',
	template: require('./module-block-view-template'),

	attributes: function() {
		return {
			id: this.model.get('id')
		}
	},

	/** @override */
	events: {
		'input .text-content': 'updateText',
		'change .text-input-area': 'toggleTextArea',
		'input .task-content': 'updateText',
		'input .citation-content': 'updateCitation',
		'input .checkRadio-title': 'updateTextFromInput',
		'input .input-content': 'updateInput',
		'input .help-content': 'updateText',
		'input .identifier': 'updateIdentifier',
		'input .link-content': 'updateLink',
		'click .add-block-image': 'selectImage',
		'click .add-checkbox-radio': 'addCheckboxRadio',
		'input .slider': 'updateSlider'
	},

	initialize: function(options) {
		this.appId = options.appId
		this.id = options.id
		this.selectorId = options.selectorId
		this.parent = options.parent
	},

	ready: function() {
	},

	getRenderData: function() {
		var identifierExists = true
		if (this.model.get('identifier') === undefined) {
			identifierExists = false
		}
		return {
			model: this.model.toJSON(),
			identifierExists: identifierExists,
			selectorId: this.selectorId
		}
	},

	updateText: function(e) {
		var value = this.updateEditable(e.currentTarget)
		var data
		if (this.model.get('class') === "R4b_TextAssessmentBlock") {
			data = this.model.get('text')
		} else {
			data = this.model.get('data')
		}

		data.content.en = value
		this.model.trigger('change')
	},

	toggleTextArea: function() {
		var area = this.model.get('area')
		if (this.model.has('area')) {
			this.model.set('area', !area)
		} else {
			this.model.set('area', true)
		}
		this.model.trigger('change')
	},

	updateCitation: function(e) {
		var value = this.updateEditable(e.currentTarget)
		$(e.currentTarget).html(value)

		var text = this.model.get('citation')
		text.content.en = value
		this.model.trigger('change')
	},

	updateInput: function(e) {
		var value = this.updateEditable(e.currentTarget)
		$(e.currentTarget).html(value)
		var text = this.model.get('text')
		text.content.en = value
		this.model.trigger('change')
	},

	updateTextFromInput: function(e) {
		var value = $(e.currentTarget).val()
		var text = this.model.get('text')
		text.content.en = value
		this.model.trigger('change')
	},

	updateLink: function(e) {
		var value = $(e.currentTarget).val()
		var data = this.model.get('data')
		data.src.destination = value
		this.model.trigger('change')
	},

	updateIdentifier: function(e) {
		var value = $(e.currentTarget).val()
		this.model.set('identifier', value)
		this.model.trigger('change')
	},

	showMediaSelect: function(model, mediaType) {
		this.mediaLibrary = new MediaSelectorView({
			app: Storm.view.app,
			model: model,
			mediaType: mediaType
		})

		$('body').append(this.mediaLibrary.el)
		this.mediaLibrary.render().show()
	},

	selectImage: function(e) {
		var property = $(e.currentTarget).data('property')
		var model = property ? this.model.get(property) : this.model.get('image')

		var propertyComponents = property.split('..')
		var mediaType = MediaLibrary.types.IMAGE

		if (propertyComponents[propertyComponents.length - 1] === 'icon') {
			mediaType = MediaLibrary.types.ICON
		}

		// Show media library
		this.showMediaSelect(model, mediaType)

		this.mediaLibrary.on('change', function() {
			this.model.trigger('change')
			this.updateImagePreview()
		}, this)
	},

	updateImagePreview: function() {
		var style = Handlebars.helpers.getBackgroundImageStyle(this.model.get('data').src, 'contain')
		$('.add-block-image[data-id=' + this.selectorId + ']').attr('style', style)
	},

	updateSlider: function() {
		var start = $('#' + this.selectorId + '-start').val(),
			length = $('#' + this.selectorId + '-length').val(),
			value = $('#' + this.selectorId + '-value').val(),
			interval = $('#' + this.selectorId + '-interval').val(),
			startLabel = $('#' + this.selectorId + '-startLabel').val(),
			endLabel = $('#' + this.selectorId + '-endLabel').val()

		this.model.set('endLabel..content..en', endLabel)
		this.model.set('startLabel..content..en', startLabel)
		this.model.set('range..start', start)
		this.model.set('range..length', length)
		this.model.set('value', value)
		this.model.set('interval', interval)
	},

	addCheckboxRadio: function() {
		var toAdd = $('#' + this.selectorId + '-CheckRadioAdd').val().trim()
		var options = this.model.get('options')
		if (!this.existsInOptions(toAdd, options) && toAdd !== '') {
			var textObject = App.getClassStructure('Text')
			textObject.content.en = toAdd
			options.push(textObject)
			this.model.trigger('change')
			this.render()
		}
	},

	existsInOptions: function(toAdd, options) {
		var exists = false
		options.forEach(function(option) {
			var content = option.get('content')
			if (toAdd === content.en.trim()) {
				exists = true
			}
		})
		return exists
	},

	updateEditable: function(element) {
		var caretPosition = this.getCaretPosition(element)
		var value = this.stripTags(element)
		$(element).html(value)
		this.setCaretPosition(element, caretPosition)
		return value
	},

	stripTags: function(element) {
		// inner Text does not work in firefox so...
		if (element.innerText) {
			return element.innerText
		} else if (element.html) {
			return element.html().replace(/<br>/g, '\n')
		}
		return ''
	},

	getCaretPosition: function(element) {
		// http://stackoverflow.com/questions/16736680/get-caret-position-in-contenteditable-div-including-tags
		var caretOffset = 0
		if (typeof window.getSelection !== "undefined") {
			var range = window.getSelection().getRangeAt(0)
			var preCaretRange = range.cloneRange()
			preCaretRange.selectNodeContents(element)
			preCaretRange.setEnd(range.endContainer, range.endOffset)
			caretOffset = preCaretRange.toString().length
		} else if (typeof document.selection !== "undefined" && document.selection.type !== "Control") {
			var textRange = document.selection.createRange()
			var preCaretTextRange = document.body.createTextRange()
			preCaretTextRange.moveToElementText(element)
			preCaretTextRange.setEndPoint("EndToEnd", textRange)
			caretOffset = preCaretTextRange.text.length
		}
		return caretOffset
	},

	setCaretPosition: function(editable, position) {
		// http://stackoverflow.com/questions/40632975/set-caret-position-in-a-content-editable-element
		var range = document.createRange()
		var sel = window.getSelection()
		try {
			range.setStart(editable.childNodes[0], position)
			range.collapse(true)
			sel.removeAllRanges()
			sel.addRange(range)
			editable.focus()
		} catch (error) {
			// Empty childnode or pasting in unrecognisable stuff..
			return
		}
	}

})
