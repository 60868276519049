<td class="icon"><img src="/images/hazards/disaster-icons/{{categoryName}}.png"></td>
<td>
	<h4>{{getBrowserLocaleText eventName}}</h4>
	<div>{{t "hazards.alerts.effective"}} {{formatDateTime timestamps.effective}}, {{t "hazards.alerts.issuedBy"}} {{toUpperCase feed}}</div>
</td>
<td class="status-column">
	{{#ifDateIsInPast timestamps.expires}}
		<span class="status expired">{{t "hazards.alerts.expired"}}</span>
	{{else}}
		<span class="status active">{{t "hazards.alerts.alertActive"}}</span>
	{{/ifDateIsInPast}}
</td>
<td>
	{{#if options.pinned}}
		<button class="button positive pin-button">{{t "hazards.alerts.pinned"}}</button>
	{{else}}
		<button class="button light pin-button">{{t "hazards.alerts.pin"}}</button>
	{{/if}}
</td>
