var Page = require('./page')

module.exports = Backbone.Collection.extend({
	model: Page,

	url: function() {
		return App.apiRoot + 'apps/' + this.appId + '/pages'
	},

	initialize: function(models, options) {
		this.appId = options.appId
	},

	comparator: function(page) {
		var title = App.l(page.get('title'))
		return (title) ? title.toLowerCase() : ''
	},

	rebuildCache: function() {
		return this.save(null, {
			data: null,
			type: 'POST'
		})
	}
})
