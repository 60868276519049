var ModuleBlock         = require('./module-block-view'),
	QuestionModuleBlock   = require('./question-block-view'),
	LinkSelector          = require('editor/inspector/link-selector'),
	LinkList              = require('editor/inspector/link-list-view'),
	LogicView             = require('./section-logic-view')

module.exports = Backbone.View.extend({
	className: 'sectionBlock',
	template: require('./section-view-template'),

	attributes: function() {
		return {
			id: this.model.get('id')
		}
	},

	/** @override */
	events: {
		'change .task-check': 'toggleTask',
		'input .task-content': 'updateTask',
		'click .toggle-tools': 'toggleTools',
		'input .logic': 'updateLogic'
	},

	initialize: function(options) {
		this.appId = options.appId
		this.id = options.id
		this.selectorId = options.selectorId
		this.parent = options.parent
		this.sectionNumber = options.sectionNumber
	},

	getRenderData: function() {
		return {
			model: this.model.toJSON(),
			selectorId: this.selectorId,
			sectionNumber: this.sectionNumber,
			taskModuleSection: this.model.get('class') === 'R4b_TaskModuleSection' ? true : false
		}
	},

	toggleTaskContent: function() {
		if (this.model.get('class') === 'R4b_TaskModuleSection') {
			$('.task-content[data-selection="' + this.selectorId + '"]').show()
		} else {
			$('.task-content[data-selection="' + this.selectorId + '"]').hide()
		}
	},

	toggleTools: function() {
		$('.section-tools[data-selection="' + this.selectorId + '"]').toggle()
	},

	renderBlocks: function() {
		this.childViews = []
		$('.section-blocks[data-selection=' + this.selectorId + ']').empty()
		if (this.model.get('children')) {
			this.model.get('children').forEach(function(childModel, index) {
				this.addBlock(childModel, index)
			}.bind(this))
		}
		// Also render logic.
		if (this.model.get('class') === 'R4b_TaskModuleSection' || this.model.get('class') === "R4b_ModuleSection") {
			// Render Multi Link Selector
			var logic = new LogicView({
				model: this.model,
				appId: this.appId,
				selectorId: this.selectorId
			})

			this.listenTo(logic, 'change', function() {
				this.parent.updateSaving()
			}, this)

			$('.logic-view[data-selection=' + this.selectorId + ']').html(logic.render().el)
		}
	},

	addBlock: function(childModel, index) {
		if (index !== 0) {
			index = index || -1
		}
		var selectorId = Math.random().toString(16).substr(2)
		var view
		if (childModel.get('class') === "R4b_QuestionAssessmentBlock") {
			// R4b_QuestionAssessmentBlock is a bit more complicated than a normal ModuleBlock so it has it's own custom view.
			view = new QuestionModuleBlock({
				model: childModel,
				appId: this.appId,
				parent: this,
				selectorId: selectorId
			})
		} else {
			view = new ModuleBlock({
				model: childModel,
				appId: this.appId,
				parent: this,
				selectorId: selectorId
			})
		}
		view.el.setAttribute('data-selection', selectorId)
		view.el.setAttribute('data-index', index)

		this.listenTo(childModel, 'change', this.parent.updateSaving)
		this.childViews.push(view)
		$('.section-blocks[data-selection=' + this.selectorId + ']').append(view.render().el)
		if (childModel.get('class') === "R4b_LinkMediaModuleBlock") {
			// Render Link selector
			var linkSelector = new LinkSelector({
				link: childModel.get('data'),
				titleDisabled: false,
				language: 'en'
			})

			this.listenTo(linkSelector, 'change', function() {
				this.parent.updateSaving()
			}, this)

			$('.link-selector[data-id=' + selectorId + ']').html(linkSelector.render().el)
		} else if (childModel.get('class') === "R4b_HelpMediaModuleBlock") {
			// Render Multi Link Selector
			var linkList = new LinkList({
				collection: childModel.get('links'),
				pageId: this.model.get('pageId'),
				language: 'en',
				parentLock: this.parent.model.lock
			})

			this.listenTo(linkList, 'change', function() {
				this.parent.updateSaving()
			}, this)

			$('.link-selector[data-id=' + selectorId + ']').html(linkList.render().el)
		}
		return view
	},

	toggleTask: function() {
		if (this.model.get('class') === 'R4b_TaskModuleSection') {
			// Swap to Module Section...
			this.model.set('class', 'R4b_ModuleSection')
			// Remove intro
			this.model.unset('intro')
		} else if (this.model.get('class') === 'R4b_ModuleSection') {
			// Swap to Task Section...
			this.model.set('class', 'R4b_TaskModuleSection')
		}
		if (!this.model.isNew()) {
			// Clone and add to module
			var newModel = this.parent.cloneSection(this.model)
			// Delete old one
			this.parent.addToDelete(this.model.get('id'), 'section')

			// Replace model with new model
			this.model = newModel
		}
		this.toggleTaskContent()
	},

	updateTask: function(e) {
		var content = $(e.currentTarget).html()
		if (!this.model.get('intro')) {
			var newModel = App.getClassStructure('R4b_TextMediaModuleBlock')
			this.model.set('intro', newModel)
		}
		var text = this.model.get('intro').data
		text.content.en = content
	}
})
