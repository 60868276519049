<div class="nav-overlay">
	<div class="nav-container">
		<div class="nav-intro">
			<span>{{t "nav.navigate"}}</span>
			<span><a href="/logout">{{t "nav.logout"}}</a></span>
		</div>
		<div class="nav-group">
			<ul>
				<li class="admin-link">
					<a href="/apps/{{appId}}/organization">
						<span class="nav-icon admin"></span>
						<span class="nav-title">{{t "nav.items.admin.title"}}</span>
						<span class="nav-description">{{t "nav.items.admin.description"}}</span>
					</a>
				</li>
				<li class="sprint-link">
					<a href="/sprint">
						<span class="nav-icon sprint"></span>
						<span class="nav-title">{{t "nav.items.sprint.title"}}</span>
						<span class="nav-description">{{t "nav.items.sprint.description"}}</span>
					</a>
				</li>
				<li class="gdpc-console-link">
					<a href="/apps/{{appId}}/societies">
						<span class="nav-icon gdpc-console"></span>
						<span class="nav-title">{{t "nav.items.gdpcConsole.title"}}</span>
						<span class="nav-description">{{t "nav.items.gdpcConsole.description"}}</span>
					</a>
				</li>
				<li class="hazards-link">
					<a href="/apps/{{appId}}/hazards">
						<span class="nav-icon hazards"></span>
						<span class="nav-title">{{t "nav.items.hazards.title"}}</span>
						<span class="nav-description">{{t "nav.items.hazards.description"}}</span>
					</a>
				</li>
				<li class="setup-link">
					<a href="/apps/{{appId}}/setup">
						<span class="nav-icon setup"></span>
						<span class="nav-title">{{t "nav.items.appSetup.title"}}</span>
						<span class="nav-description">{{t "nav.items.appSetup.description"}}</span>
					</a>
				</li>
				<li class="content-link">
					<a href="/apps/{{appId}}/pages">
						<span class="nav-icon content"></span>
						<span class="nav-title">{{t "nav.items.content.title"}}</span>
						<span class="nav-description">{{t "nav.items.content.description"}}</span>
					</a>
				</li>
				<li class="decision-tree-link">
					<a href="/apps/{{appId}}/decision-tree">
						<span class="nav-icon decision-tree"></span>
						<span class="nav-title">{{t "nav.items.decisionTree.title"}}</span>
						<span class="nav-description">{{t "nav.items.decisionTree.description"}}</span>
					</a>
				</li>
				<li class="stories-link">
					<a href="/stories">
						<span class="nav-icon stories"></span>
						<span class="nav-title">{{t "nav.items.stories.title"}}</span>
						<span class="nav-description">{{t "nav.items.stories.description"}}</span>
					</a>
				</li>
				<li class="levels-link">
					<a href="/apps/{{appId}}/levels">
						<span class="nav-icon levels"></span>
						<span class="nav-title">{{t "nav.items.levels.title"}}</span>
						<span class="nav-description">{{t "nav.items.levels.description"}}</span>
					</a>
				</li>
				<li class="publish-link">
					<a href="/apps/{{appId}}/publish">
						<span class="nav-icon publish"></span>
						<span class="nav-title">{{t "nav.items.publish.title"}}</span>
						<span class="nav-description">{{t "nav.items.publish.description"}}</span>
					</a>
				</li>
				<li class="push-link">
					<a href="/apps/{{appId}}/push">
						<span class="nav-icon push"></span>
						<span class="nav-title">{{t "nav.items.push.title"}}</span>
						<span class="nav-description">{{t "nav.items.push.description"}}</span>
					</a>
				</li>
				<li class="user-agreement-link">
					<a href="/apps/{{appId}}/user-agreement">
						<span class="nav-icon agreement"></span>
						<span class="nav-title">{{t "nav.items.userAgreement.title"}}</span>
						<span class="nav-description">{{t "nav.items.userAgreement.description"}}</span>
					</a>
				</li>
				<li class="bugs-link">
					<a href="/bugs">
						<span class="nav-icon bugs"></span>
						<span class="nav-title">{{t "nav.items.bugs.title"}}</span>
						<span class="nav-description">{{t "nav.items.bugs.description"}}</span>
					</a>
				</li>
			</ul>
		</div>

	</div>
</div>

<select class="app-select"></select>
<div class="unactive nav-item nav-button"><span class="toggle"></span></div>
