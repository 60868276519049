<td class="icon">
    <img src="/images/hazards/disaster-icons/{{categoryName}}.png">
</td>
<td>
	<h4>{{toUpperCase feed}}</h4>
	<div>{{t "hazards.alerts.effective"}} {{formatDateTime timestamps.effective}}</div>
</td>
<td class="text-center">
    {{#if options.notify}}
        <i class="icon-ok icon-2x" />
    {{else}}
        <i class="icon-remove icon-2x" />
    {{/if}}
</td>
<td class="text-center">{{pushes}}</td>
<td class="status-column">
	{{#ifDateIsInPast timestamps.expires}}
		<span class="status expired">{{t "hazards.alerts.expired"}}</span>
	{{else}}
		<span class="status active">{{t "hazards.alerts.alertActive"}}</span>
	{{/ifDateIsInPast}}
</td>
