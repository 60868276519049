<div class="row">
	<div class="col-xs-12">

		<h4><a href="/apps/{{appId}}/pools/{{id}}" class="dev-pool-edit-link"><i class="icon-pencil"></i></a> {{name}}</h4>
	</div>
</div>
<div class="row">
	<div class="col-xs-12">

		<div class="pool-no-apps">{{t "gdpcConsole.devPools.noApps"}}</div>

		<table style="margin-bottom:40px">
			<tbody>
				<tr>
					<th>{{t "gdpcConsole.nationalSocieties.countries"}}</th>
					{{#each stages}}
						<th>
							<div>{{name}}</div>
							<div class="sub-text">{{formatDate timestamp}}</div>
						</th>
					{{/each}}
					<th class="admin">Complete stage and advance</th>
				</tr>
				<tr class="expected-row">
					<td class="center">{{t "gdpcConsole.devPools.expected"}}</td>
					{{#each stages}}
						<td>
							{{#ifDateIsInPast timestamp}}
								<div class="status-gem-container">
									<div class="status-gem positive"></div>
								</div>
							{{/ifDateIsInPast}}
						</td>
					{{/each}}
					<td class="admin"></td>
				</td>
				{{#each details}}
					{{#key_value languages "key" "val"}}
						<tr>
							<td class="center"><a href="/apps/{{../../appId}}/pools/{{../../id}}/details/{{../societyId}}">{{getSocietyDescriptionById ../societyId}} ({{key}})</a></td>
							{{#each val.stages}}
								<td class="center">
									{{#if stageCompleted}}
										<div class="status-gem-container">
											<div class="status-gem positive">
												<div class="status-gem-inner"></div>
												<div class="status-gem-popover">
													<p><strong>{{t "gdpcConsole.devPools.completed"}}</strong></p>
													<p>{{formatDate stageCompleted}}</p>
												</div>
											</div>
										</div>
									{{/if}}
								</td>
							{{/each}}
							<td class="admin center">
								<button class="button advance-stage form-control" data-lang="{{key}}" data-app-id="{{../appId}}"><i class="icon-circle-arrow-right"></i></button>
							</td>
						</tr>
					{{/key_value}}
				{{/each}}
			</tbody>
		</table>

	</div>
</div>
