module.exports = Backbone.View.extend({
	template: require('./bug-list-view-item-template'),
	tagName: 'tr',
	className: 'bug-row',

	events: {
		click: 'click'
	},

	initialize: function() {
		this.listenTo(this.model, 'change', this.render, this)
	},

	click: function() {
		App.router.navigate('/bugs/' + this.model.id, {trigger: true})
	}
})
