var CanvasItemView = require('./canvas-item-view')

module.exports = CanvasItemView.extend({
	className: 'answer editable',
	template: require('./quiz-answer-view-template'),

	click: function(e) {
		if ($('.preview').hasClass('preview-mode')) {
			return
		}

		if (!this.$el.hasClass('editing')) {
			Storm.view.views.canvas.setInspector(this.model)
			this.$el.addClass('editing')
		}

		// Don't bubble up to the parent view.
		e.stopPropagation()
	}
})
