var VALID_DISPLAY_VALUES = ['you', 'feature', 'feed', 'mood']

/**
 * Exports {@link Tip}.
 * @module
 */
module.exports = Backbone.Model.extend(/** @lends Tip.prototype */{
	/**
	 * @constructs Tip
	 * @extends Backbone.View
	 * @override
	 * @classdesc Stores details of an individual tip for the Mind Emoodji app.
	 */
	initialize: function() {
	},

	/**
	 * @override
	 * @returns {string} URL root for the tips collection.
	 */
	urlRoot: function() {
		return 'https://dev-api-mind.cubeapis.com/tips'
	},

	/**
	 * @returns {boolean} Whether the tip is of standard Tip type.
	 */
	isStandardTip: function() {
		return this.get('objectType') === 'Tip'
	},

	/**
	 * @returns {boolean} Whether the tip is of standard Tip type.
	 */
	isQuestionTip: function() {
		return this.get('objectType') === 'Question'
	},

	/** @override */
	validate: function(attributes) {
		var validTypes = ['Tip', 'QuestionTip']

		if (validTypes.indexOf(attributes.objectType) === -1) {
			return 'Invalid object type'
		}

		if (!attributes.title) {
			return 'Missing title'
		}

		if (!attributes.subtitle) {
			return 'Missing subtitle'
		}

		if (!attributes.buttonTitle) {
			return 'Missing button title'
		}

		if (attributes.objectType === 'QuestionTip' && attributes.content) {
			return 'Question tips cannot link to content pages'
		}

		if (VALID_DISPLAY_VALUES.indexOf(attributes.display) === -1) {
			return 'Invalid display value'
		}

		if (attributes.objectType === 'Question' &&
				(!attributes.answers ||
				!attributes.answers.data ||
				attributes.answers.data.length === 0)) {
			return 'Missing question answers'
		}

		if (parseFloat(attributes.minMood) !== attributes.minMood) {
			return 'Invalid min mood value'
		}

		if (parseFloat(attributes.maxMood) !== attributes.maxMood) {
			return 'Invalid max mood value'
		}

		if (attributes.minMood > 10 || attributes.minMood < -10) {
			return 'Min mood value must be between -10 and 10'
		}

		if (attributes.maxMood > 10 || attributes.maxMood < -10) {
			return 'Max mood value must be between -10 and 10'
		}

		if (attributes.minMood > attributes.maxMood) {
			return 'Min mood cannot be greater than max mood'
		}

		var publishDate = new Date(attributes.publishDate)
		var expiryDate = new Date(attributes.expiryDate)

		if (!publishDate) {
			return 'Tip must contain a publish date'
		}

		if (attributes.expiryDate !== null && !expiryDate) {
			return 'Invalid expiry date'
		}

		if (attributes.expiryDate !== null && publishDate >= expiryDate) {
			return 'Expiry date must be after the publish date'
		}
	},

	/**
	 * Extracts the tip list from the "data" key in the response.
	 * @override
	 */
	parse: function(response) {
		return response.data || response
	}
})
