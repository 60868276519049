var StormView = require('lib/storm-view')

/**
 * Exports {@link DeploymentSectionView}.
 * @module
 */
module.exports = StormView.extend(/** @lends DeploymentSectionView.prototype */{
	/**
	 * @constructs DeploymentSectionView
	 * @extends StormView
	 * @override
	 */
	initialize: function() {
	},

	/** @override */
	getSectionTitle: function() {
		return 'Deployment'
	},

	/** @override */
	getPages: function() {
		return [
			{
				name: 'Frontend',
				url: 'deployment',
				isVisible: function() {
					return true
				},
				views: [
					require('deployment/deployment-view')
				]
			},
			{
				name: 'Backend',
				url: 'deployment/server',
				isVisible: function() {
					return true
				},
				views: [
					require('deployment/server-deployment-view')
				]
			}
		]
	}
})

