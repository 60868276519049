module.exports = Backbone.Model.extend({
	url: function() {
		return (this.get('id') ? App.whatnowRoot + 'whatnow/' + this.get('id') : App.whatnowRoot + 'whatnow')
	},

	defaults: {
		translations: []
	},

	initialize: function() {
		this.bind("error", this.catchError)
	},

	catchError: function(model, error) {
		App.stopLoad()
		if (error.responseJSON.status === 409) {
			swal({
				title: 'Error',
				text: 'Event type for whatnow already exists, please select a unique one.',
				type: 'error'
			})
		} else {
			console.warn(error)
		}
	},

	/**
	 * Extracts the whatnow from the "data" key in the response.
	 * @override
	 */
	parse: function(response) {
		return response.data || response
	}
})
