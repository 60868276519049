<p class="lead" data-i18n="viewPicker.dragAndDrop"></p>

<div class="paste-wrapper">
	<h4 data-i18n="editor.inspector.copiedItem"></h4>
	<div class="list-item-preview drag paste-preview"></div>
</div>

<div class="list-header">
	<h4 data-i18n="viewPicker.title"></h4>
</div>
