<select class="form-control video-type">
	<option value="local">Local</option>
	<option value="streaming">Streaming</option>
</select>
<div class="internal">
	{{#if link}}
		<video controls src="{{remoteUrl link.destination}}"></video>
	{{/if}}
	{{#if src}}
		{{#ifEqual src.class 'InternalLink'}}
			<video controls src="{{remoteUrl src.destination}}"></video>
		{{/ifEqual}}
	{{/if}}
	<button class="button video-select-button neutral">{{t "editor.inspector.properties.video.chooseButton"}}</button>
</div>
<div class="external">
	<input type="text" class="form-control video-url" data-property="link..destination">
</div>
