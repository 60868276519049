var EditorSectionView = require('editor/editor-section-view'),
	TipList           = require('./tip-list')

/**
 * Exports {@link TipsView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends TipsView.prototype */{
	/** @override */
	template: require('./tips-view-template'),

	/** @override */
	events: {
		'click .delete-button': 'deleteButtonClick'
	},

	/** @override */
	getPageTitle: function() {
		return $.t('Tips')
	},

	/**
	 * @constructs TipsView
	 * @extends Backbone.View
	 * @override
	 * @classdesc Displays the list of all tips for the Mind Emoodji app.
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)
		App.startLoad()

		/** @private {StormApp} */
		this.app_ = options.app
		/** @private {TipList} */
		this.tipList_ = new TipList()
		this.tipList_.fetch()
			.then(this.tipsLoaded.bind(this))
	},

	/** @override */
	getRenderData: function() {
		return {
			appId: this.app_.id,
			tips: this.tipList_.toJSON()
		}
	},

	/** Handles {@link TipList} fetch completion and re-renders the list. */
	tipsLoaded: function() {
		this.render()
		App.stopLoad()
	},

	/**
	 * Confirms and performs tip deletion.
	 * @param {jQuery.Event} e jQuery Event object.
	 */
	deleteButtonClick: function(e) {
		var id  = $(e.currentTarget).data('id'),
			tip = this.tipList_.get(id)

		if (!tip) {
			return
		}

		swal({
			title: $.t('editor.inspector.areYouSure'),
			text: $.t('editor.inspector.confirmDelete'),
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: $.t('mediaLibrary.delete'),
			confirmButtonColor: '#DD6B55'
		}, function() {
			App.startLoad()
			tip.destroy().then(this.tipsLoaded.bind(this))
		}.bind(this))
	}
})
