/**
 * Exports {@link AppProperties}.
 * @module
 */
module.exports = Backbone.Collection.extend(/** @lends AppProperties.prototype */{
	/**
	 * @constructs AppProperties
	 * @extends Backbone.Collection
	 * @override
	 */
	initialize: function(models, options) {
		options = options || {}

		if (options.appId === undefined) {
			throw new Error('No appId specified')
		}

		this.appId = options.appId
	},

	/** @override */
	url: function() {
		return App.apiRoot + 'apps/' + this.appId + '/properties'
	}
})
