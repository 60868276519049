<div class="col-xs-12 actions-col">
	<button class="button green right save-button">{{t "common.save"}}</button>
</div>
<div class="container pad-top decision-tree">
	<div class="row">
		<div class="col-xs-12 form-horizontal">

			<legend>Question</legend>

			<div class="form-group">
				<label class="control-label col-sm-3">{{t "viewPicker.text"}}</label>
				<div class="col-sm-9">
					{{#each @languages}}
						<label>{{code}}</label>
						<textarea class="question-text form-control"
								  data-lang="{{code}}"></textarea>
					{{/each}}
				</div>
			</div>

			{{!-- Not localised --}}
			{{#each answers}}
				<div data-answer="{{@index}}">
					<legend>Answer</legend>

					<div class="form-group">
						<label class="control-label col-sm-3">{{t "viewPicker.text"}}</label>
						<div class="col-sm-9">
							{{#each @languages}}
								<label>{{code}}</label>
								<textarea class="answer-text form-control"
										  data-lang="{{code}}"></textarea>
							{{/each}}
						</div>
					</div>

					<div class="form-group">
						<label class="control-label col-sm-3">{{t "editor.inspector.properties.image.title"}}</label>
						<div class="col-sm-9">
							<button type="button" class="button choose-image-button">{{t "editor.inspector.properties.image.chooseButton"}}</button>
							<img src="{{getPreviewUrl image}}">
						</div>
					</div>

					<div class="form-group">
						<label class="control-label col-sm-3">Question</label>
						<div class="col-sm-9">
							{{#if question}}
								<a href="/apps/{{../appId}}/decision-tree/{{question.id}}">{{t "common.edit"}}</a>
							{{else}}
								<a href class="add-question-link">{{t "common.add"}}</a>
							{{/if}}
						</div>
					</div>

					<div class="form-group">
						<label class="control-label col-sm-3">Page</label>
						<div class="col-sm-9">
							<select class="answer-page form-control">
								{{{../pageSelect}}}
							</select>
						</div>
					</div>
				</div>
			{{/each}}

			<button class="button add-answer-button">
				<i class="icon-plus"></i> Add answer
			</button>
		</div>
	</div>
</div>
