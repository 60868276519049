var HazardsSectionView = require('./hazards-section-view')
var ListView = require('list-view/list-view')

var AlertZonesListView = ListView.extend({
	template: require('./alert-zones-list-view-template'),
	list: require('models/stormql'),
	listItem: require('./alert-zones-list-item-view')
})

module.exports = HazardsSectionView.extend({
	template: require('./alert-zones-view-template'),

	initialize: function(options) {
		this.app = options.app

		this.views = {
			list: new AlertZonesListView({
				fetchData: {data: {class: 'AlertZone'}},
				app: this.app
			})
		}

		App.stopLoad()
	},

	getPageTitle: function() {
		return $.t('hazards.alertZones.title')
	},

	getRenderData: function() {
		return {appId: this.app.id}
	},

	afterRender: function() {
		var permission = App.acl.getPermission('Content')

		this.$('.actions-col .add').parent().hide()

		switch (permission) {
			case 'Delete':
			case 'Write':
				this.$('.actions-col .add').parent().show()
		}
	}
})
