<div class="row">
    <div class="col-sm-12">
        <h4 data-i18n="crossBorders.links"></h4>
    </div>
  </div>
<table>
	<thead>
		<tr>
			<th>{{t "crossBorders.link.title"}}</th>
			<th>{{t "crossBorders.link.destination"}}</th>
      <th></th>
		</tr>
	</thead>
	<tbody class="list-items"></tbody>
</table>
