var ListViewItem = require('list-view/list-view-item')

module.exports = ListViewItem.extend({
	template: require('./role-list-item-view-template'),

	afterRender: function() {
		if (!App.acl.hasWritePermission('Users', 'Role management')) {
			this.$('.tools').text('-')
		}
	},

	save: function() {
		this.model.save({
			name: this.$('.name').val(),
			description: this.$('.description').val()
		})
	}
})
