var AlertConfigureView = require('./disaster-configure-view')

module.exports = Backbone.View.extend({
	template: require('./disaster-list-item-view-template'),
	tagName: 'tr',

	events: {
		'change .root-page': 'rootPageChange',
		'change .publish-option': 'publishCheckboxChange',
		'click .configure-button': 'configureAlerts',
		'click .delete-disaster': 'deleteDisaster'
	},

	initialize: function(options) {
		this.views = {}

		this.disaster = options.disaster
		this.rootPageOptions = options.rootPageOptions
	},

	getRenderData: function() {
		var data = this.disaster.toJSON()
		data.rootPageOptions = this.rootPageOptions
		data.enabled = this.model != null

		return data
	},

	afterRender: function() {
		if (this.model) {
			// Set current root page.
			this.$('.root-page').val(this.model.get('src..destination'))

			// Set current publishable values.
			var model = this.model

			this.$('.publish-option').each(function(i) {
				if ((model.get('publishable') & (i + 1)) !== 0) {
					this.checked = true
				}
			})

			this.$el.attr('id', 'row-' + this.model.cid)
		} else {
			this.$el.addClass('nodrag nodrop')
		}

		// Hide features based on permissions.
		if (!App.acl.hasWritePermission('Hazards', 'Disasters')) {
			this.$('.publish-option, select, .enable-button').attr('disabled', true)
			this.$('.tools').text('-')
		}
	},

	rootPageChange: function() {
		this.model.set('src..destination', this.$('.root-page').val())
		this.model.needsSaving = true
	},

	publishCheckboxChange: function() {
		var publishable = 0

		this.$('.publish-option').each(function(i) {
			if (this.checked) {
				publishable += 1 << i
			}
		})

		this.model.set('publishable', publishable)
		this.model.needsSaving = true
	},

	configureAlerts: function() {
		if (this.views.configureAlerts) {
			this.views.configureAlerts.destroy()
		}

		this.views.configureAlerts = new AlertConfigureView({
			model: this.model,
			disaster: this.disaster,
			selectOptions: this.rootPageOptions
		})

		$('body').append(this.views.configureAlerts.render().el)
		this.views.configureAlerts.$el.modal({backdrop: 'static'})
	},

	// Delete this StormQLDisaster object.
	deleteDisaster: function() {
		if (confirm($.t('editor.inspector.confirmDelete'))) {
			this.model.requestLock(function() {
				this.model.destroy()
				this.model = null
				this.render()
			}.bind(this))
		}
	}
})
