var MediaLibrary = require('media-library/media-library-view'),
	MediaSelectorView = require('media-library/media-selector-view'),
	utils = require('lib/utils')

module.exports = Backbone.View.extend({
	template: require('./video-inspector-view-template'),

	events: {
		'change .video-type': 'typeChange',
		'click .video-select-button': 'selectVideo'
	},

	initialize: function() {
		this.views = {}

		this.listenTo(this.model, 'change', this.updatePreview, this)

		// MultiVideo objects have 'src' properties, individual uses 'link'
		this.property = (this.model.has('link')) ? 'link' : 'src'
	},

	afterRender: function() {
		var type = (this.model.get(this.property).class === 'ExternalLink') ? 'streaming' : 'local'
		this.$('.video-type').val(type)
		this.$('.video-url').val(this.model.get(this.property).destination)
		this.showCorrectSelector()
	},

	typeChange: function() {
		var link = this.model.get(this.property)
		var type = (this.$('.video-type').val() === 'local') ? 'InternalLink' : 'ExternalLink'

		// Delete old link object, create new
		var newLink = App.getClassStructure(type, this.model.get('pageId'))

		if (link && link.id) {
			var model = this.model,
				headers = App.session.getHeadersObject(),
				view = Storm.view

			// Pull in lock data from the parent page, if there is one.
			if (view.views && view.views.canvas && view.views.canvas.model.lock.isLocked()) {
				headers.Lock = view.views.canvas.model.lock.get('token')
			}

			$.ajax({
				url: App.apiRoot + 'objects/' + link.id,
				type: 'DELETE',
				headers: headers,
				complete: function() {
					// Don't save new link until old one's been deleted
					model.save()
				}
			})
		}

		this.model.unset(this.property, {silent: true})
		this.model.set(this.property, newLink)

		if (!(link && link.id) && this.model.id) {
			// No previous link to delete - save immediately
			this.model.save()
		}

		this.render()
	},

	showCorrectSelector: function() {
		var type = this.$('.video-type').val()
		if (type === 'local') {
			this.$('.external').remove()
		} else {
			this.$('.internal').remove()
		}

		this.$('.video-url').val(this.model.get(this.property).destination)
	},

	updatePreview: function() {
		// Only update preview for local videos
		if (this.model.get(this.property).class === 'InternalLink') {
			this.$('video').attr('src', utils.getRemoteUrl(this.model.get(this.property).destination))
		}
	},

	selectVideo: function() {
		// Show video selector
		this.views.mediaLibrary = new MediaSelectorView({
			app: Storm.view.app,
			model: this.model.get(this.property),
			mediaType: MediaLibrary.types.VIDEO
		})

		this.views.mediaLibrary.on('change', function() {
			this.model.trigger('change change:video', this.model)
		}, this)

		$('body').append(this.views.mediaLibrary.el)
		this.views.mediaLibrary.render().show()
	}
})
