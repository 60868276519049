<div class="container arpro-product-edit">
	<div class="row">
		<div class="col-md-6">
			<legend data-i18n="arpro.product.editItem"></legend>
		</div>
		<div class="col-md-6 actions-col">
			<a href="javascript:history.back()"><button class="button red wide js-form-control js-remove-lock" type="button">{{t "common.cancel"}}</button></a>
			<button class="button green wide save js-form-control" type="submit">{{t "common.save"}}</button>
		</div>
	</div>

	<!-- Name -->
	<div class="form-group row">
		<label class="control-label col-xs-2" for="name-input">{{t "arpro.product.name"}}</label>
		<div class="col-xs-5">
			<input type="text" class="form-control js-form-control" id="name-input" value="{{model.name}}">
		</div>
	</div>

	<!-- Grade -->
	<div class="form-group row">
		<label class="control-label col-xs-2" for="grade-input">{{t "arpro.product.grade"}}</label>
		<div class="col-xs-5">
			<input type="text" class="form-control js-form-control" id="grade-input" value="{{model.grade}}">
		</div>
	</div>

	<!-- Colour -->
	<div class="form-group row">
		<label class="control-label col-xs-2" for="colour-input">{{t "arpro.product.colour"}}</label>
		<div class="col-xs-5">
			<div class="input-group">
				<div class="input-group-addon"></div>
				<select id="colour-input" class="form-control js-form-control select-radius">
					{{#each colourList}}<option value={{id}} {{findSelected id ../model.colour}}>{{getLocaleText name}}</option>{{/each}}
				</select>
			</div>
		</div>
	</div>

	<!-- Access Codes -->
	<div class="form-group row">
		<label class="control-label col-xs-2" for="code-input">{{t "arpro.product.access"}}</label>
		<div class="col-xs-5">
			<div class="input-group">
				<select id="code-input" class="form-control js-form-control select-radius">
					<option value="" selected="selected">None</option>
					{{#each codeGroups}}<option value={{id}}>{{getLocaleText name}}</option>{{/each}}
				</select>
				<span class="input-group-btn">
					<button class="btn btn-default js-form-control" type="button" id="addCode">{{t "common.add"}}</button>
				</span>
			</div>
			<ul style="margin-top:10px;" id="code-list">
			</ul>
		</div>
	</div>

	<!-- Category -->
	<div class="form-group row">
		<label class="control-label col-xs-2" for="category-input">Category</label>
		<div class="col-xs-5">
			<select class="form-control js-form-control" id="category-input">
				<option value="ARPRO Black <100 g/l">ARPRO Black <100 g/l</option>
				<option value="ARPRO Black >100 g/l">ARPRO Black >100 g/l</option>
				<option value="ARPRO Grey <100 g/l">ARPRO Grey <100 g/l</option>
				<option value="ARPRO White <100 g/l">ARPRO White <100 g/l</option>
				<optgroup label="ARPRO Application Specific">
					<option value="EDSP">Electostatic discharge protection</option>
					<option value="Recycled">Recycled</option>
					<option value="LS">Low stick / slip</option>
					<option value="Finer Detail">Complex geometry</option>
					<option value="Flame Retardant">Flame Retardant</option>
				</optgroup>
				<option value="On-site Expansion">On-site Expansion</option>
				<option value="ARPRO Porous">ARPRO Porous</option>
				<option value="ARPRO Colours">ARPRO Colours</option>
				<option value="custom">Custom grade</option>
			</select>
			<!-- <input type="text" class="form-control" id="category-input" value="{{ model.category }}"> -->
		</div>
	</div>

	<!-- Specs -->
	<div class="row">
		<div class="panel panel-default col-md-10">
			<div class="panel-body">
				<h3 class="panel-body-title">{{t "arpro.product.specifications"}}</h3>
				<table class="arpro-specification">
					<tbody class="list-items">
						<tr>
							<td class="narrow"><b>{{t "arpro.product.properties.weight"}}</b><b class="js-asterisk weight"></b></td>
							<td class="small-inputs">
								<input type="number" class="text-right has-spinners-disabled form-control spec-input js-form-control" id="weight-input" value="{{model.weight}}" placeholder="Add value here">
							</td>
						</tr>
						<tr>
							<td class="narrow"><b>{{t "arpro.product.properties.size"}}</b><b class="js-asterisk size"></b></td>
							<td class="small-inputs">
								<input type="number" class="text-right has-spinners-disabled form-control spec-input js-form-control" id="size-input-1" value="{{model.size.from}}" placeholder="Add value here">
								<b>-</b>
								<input type="number" class="text-right has-spinners-disabled form-control spec-input js-form-control" id="size-input-2" value="{{model.size.to}}" placeholder="Add value here">
							</td>
						</tr>
						<tr>
							<td class="narrow"><b>{{t "arpro.product.properties.bulk"}}</b><b class="js-asterisk density bulk"></b></td>
							<td class="small-inputs">
								<input type="number" class="text-right has-spinners-disabled form-control spec-input js-form-control" id="bulk-input-1" value="{{model.density.bulk.from}}" placeholder="Add value here">
								<b>-</b>
								<input type="number" class="text-right has-spinners-disabled form-control spec-input js-form-control" id="bulk-input-2" value="{{model.density.bulk.to}}" placeholder="Add value here">
							</td>
						</tr>
						<tr>
							<td class="narrow"><b>{{t "arpro.product.properties.moulded"}}</b><b class="js-asterisk density moulded"></b></td>
							<td class="small-inputs">
								<input type="number" class="text-right has-spinners-disabled form-control spec-input js-form-control" id="moulded-input-1" value="{{model.density.moulded.from}}" placeholder="Add value here">
								<b>-</b>
								<input type="number" class="text-right has-spinners-disabled form-control spec-input js-form-control" id="moulded-input-2" value="{{model.density.moulded.to}}" placeholder="Add value here">
							</td>
						</tr>
						<tr>
							<td class="narrow"><b>{{t "arpro.product.properties.packaging"}}</b><b class="js-asterisk packaging"></b></td>
							<td class="radio-buttons">
								<label class="radio inline" for="radio-bag">
									<input type="checkbox" id="checkbox-bag" class="spec-input js-form-control" {{#if model.packaging.bag}} checked {{/if}}>
									{{t "arpro.product.properties.bag"}}
								</label>
								<label class="radio inline" for="checkbox-bulk">
									<input type="checkbox" id="checkbox-bulk" class="spec-input js-form-control" {{#if model.packaging.bulk}} checked {{/if}}>
									{{t "arpro.product.properties.bulkLabel"}}
								</label>
							</td>
						</tr>
						<tr>
							<td class="narrow"><b>{{t "arpro.product.properties.food"}}</b><b class="js-asterisk foodApproved"></b></td>
							<td >
								<div class="toggle-container" id="food-approved">
									<label class="toggle-label {{#if model.foodApproved}} {{else}} enabled {{/if}}">NO</label>
									<div class="onoffswitch">
										<input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox text-input-area js-form-control" id="food-appoved-toggle" {{#if model.foodApproved}} checked {{/if}}>
										<label class="onoffswitch-label" for="food-appoved-toggle"></label>
									</div>
									<label class="toggle-label {{#if model.foodApproved}} enabled {{/if}}">YES</label>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="panel panel-default col-md-12">
			<div class="panel-body">
				<h3 class="panel-body-title">{{t "arpro.product.notes"}}</h3>
				<table>
					<tbody>
						{{#each model.notes}}
						<tr>
							<td>
								<label class="visually-hidden" for="note-value-{{id}}">Please enter a note about the product</label>
								<input type="text" class="form-control js-update-note js-form-control" data-id="{{ id }}" id="note-value-{{id}}" value="{{ details.content.en }}" placeholder="Add notes here">
							</td>

							<td class="narrow">
								<div class="justify-flex">
									<label class="visually-hidden" for="specification-value">Please select a specification</label>
									<select class="styled-select js-update-field js-update-note-specification" data-id="{{ id }}" for="specification-value">
										<option value="" {{#ifEqual field null }}selected{{/ifEqual}}>None</option>
										<option value="weight" {{#ifEqual field 'weight' }}selected{{/ifEqual}}>{{t "arpro.product.properties.weight"}}</option>
										<option value="size" {{#ifEqual field 'size' }}selected{{/ifEqual}}>{{t "arpro.product.properties.size"}}</option>
										<option value="density.bulk" {{#ifEqual field 'density.bulk' }}selected{{/ifEqual}}>{{t "arpro.product.properties.bulk"}}</option>
										<option value="density.moulded" {{#ifEqual field 'density.moulded' }}selected{{/ifEqual}}>{{t "arpro.product.properties.moulded"}}</option>
										<option value="packaging" {{#ifEqual field 'packaging' }}selected{{/ifEqual}}>{{t "arpro.product.properties.packaging"}}</option>
										<option value="foodApproved" {{#ifEqual field 'foodApproved' }}selected{{/ifEqual}}>{{t "arpro.product.properties.food"}}</option>
									</select>
									<button type="button" class="delete-cta js-remove-note" data-id="{{ id }}">&times;</button>
								</div>
							</td>
						</tr>
						{{/each}}

						{{#ifEqual model.notes.length 0 }}
						<tr>
							<td>
								<label class="visually-hidden" for="note-value">Please enter a note about the product</label>
								<input type="text" class="form-control js-new-note js-form-control" id="note-value" value="" placeholder="Add notes here">
							</td>
							<td class="narrow">
								<div class="justify-flex">
									<label class="visually-hidden" for="specification-value">Please select a specification</label>
									<select class="styled-select js-new-specification js-update-note-specification" for="specification-value">
										<option value="" selected>None</option>
										<option value="weight">{{t "arpro.product.properties.weight"}}</option>
										<option value="size">{{t "arpro.product.properties.size"}}</option>
										<option value="density.bulk">{{t "arpro.product.properties.bulk"}}</option>
										<option value="density.moulded">{{t "arpro.product.properties.moulded"}}</option>
										<option value="packaging">{{t "arpro.product.properties.packaging"}}</option>
										<option value="foodApproved">{{t "arpro.product.properties.food"}}</option>
									</select>

									<button type="button" class="delete-cta js-remove-note" data-id="{{ id }}">&times;</button>
								</div>
							</td>
						</tr>
						{{/ifEqual}}

						<tr>
							<td>
							</td>
							<td class="narrow">
								<button type="button" class="button block js-add-new-row js-form-control">Add new row</button>
							</td>
						</tr>
					</thead>
				</table>

			</div>
		</div>
	</div>

	<div class="row">
		<div class="panel panel-default col-md-12">
			<div class="panel-body">
				<h3 class="panel-body-title">{{t "arpro.product.moulded"}}</h3>

				<table>
					<thead>
						<tr>
							<td>

							</td>
							{{#each formattedDensityData.0.data }}
							<td>
								<span class="is-bold">
									{{ label }}
								</span>
								<button type="button" class="tab__cta tab__cta--is-centered js-hide-column js-density-column js-form-control" data-header="{{ label }}" data-header-index="{{ @index }}">
									<?xml version="1.0" encoding="UTF-8"?>
									<svg class="on" width="15px" height="9px" viewBox="0 0 15 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
											<!-- Generator: Sketch 47.1 (45422) - http://www.bohemiancoding.com/sketch -->
											<title>Layer/ON</title>
											<desc>Created with Sketch.</desc>
											<defs></defs>
											<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.712522645">
													<g transform="translate(-144.000000, -1969.000000)" fill="#000000">
															<g transform="translate(118.000000, 1821.000000)">
																	<g transform="translate(26.000000, 146.500000)">
																			<g transform="translate(0.000000, 1.400000)">
																					<g fill-rule="nonzero">
																							<path d="M7.34999987,0.47314814 C4.20003576,0.47314814 1.49257677,2.19300922 0,4.60509251 C1.4913225,7.01498831 4.19752723,8.73703689 7.34999987,8.73703689 C10.5002148,8.73703689 13.207423,7.01717581 14.6999997,4.60509251 C13.2084264,2.19519672 10.5024725,0.47314814 7.34999987,0.47314814 Z M10.611092,4.60509251 C10.611092,6.35509248 9.15614319,7.76481468 7.34999987,7.76481468 C5.54385656,7.76481468 4.08890778,6.35509248 4.08890778,4.60509251 C4.08890778,2.85509254 5.54385656,1.44537035 7.34999987,1.44537035 C9.15614319,1.44537035 10.611092,2.85509254 10.611092,4.60509251 Z M1.17901022,4.60509251 C1.53020475,4.14328697 2.38310576,3.12245365 3.76279857,2.36898144 C3.33634807,3.02523143 3.08549483,3.77870364 3.08549483,4.60509251 C3.08549483,5.43148139 3.33634807,6.1849536 3.76279857,6.84120359 C2.40819109,6.08773138 1.53020475,5.06689806 1.17901022,4.60509251 Z M10.9372012,6.84120359 C11.3636517,6.1849536 11.6145049,5.43148139 11.6145049,4.60509251 C11.6145049,3.77870364 11.3636517,3.02523143 10.9372012,2.36898144 C12.2918087,3.12245365 13.169795,4.14328697 13.5209895,4.60509251 C13.169795,5.06689806 12.2918087,6.08773138 10.9372012,6.84120359 Z M5.84488045,4.60509251 C5.84488045,3.80300919 6.52218419,3.14675921 7.34999987,3.14675921 C8.17781556,3.14675921 8.8551193,3.80300919 8.8551193,4.60509251 C8.8551193,5.40717583 8.17781556,6.06342582 7.34999987,6.06342582 C6.52218419,6.06342582 5.84488045,5.40717583 5.84488045,4.60509251 Z" id="Combined-Shape"></path>
																					</g>
																			</g>
																	</g>
															</g>
													</g>
											</g>
									</svg>

									<?xml version="1.0" encoding="UTF-8"?>
									<svg class="off" width="15px" height="13px" viewBox="0 0 15 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
											<!-- Generator: Sketch 47.1 (45422) - http://www.bohemiancoding.com/sketch -->
											<title>Layer/ON</title>
											<desc>Created with Sketch.</desc>
											<defs></defs>
											<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.184442935">
													<g transform="translate(-144.000000, -2112.000000)" fill="#000000">
															<g transform="translate(118.000000, 1821.000000)">
																	<g transform="translate(26.000000, 291.500000)">
																			<g>
																					<g id="noun_878103-copy" transform="translate(0.000000, 1.400000)" fill-rule="nonzero">
																							<path d="M7.34999987,0.47314814 C4.20003576,0.47314814 1.49257677,2.19300922 0,4.60509251 C1.4913225,7.01498831 4.19752723,8.73703689 7.34999987,8.73703689 C10.5002148,8.73703689 13.207423,7.01717581 14.6999997,4.60509251 C13.2084264,2.19519672 10.5024725,0.47314814 7.34999987,0.47314814 Z M10.611092,4.60509251 C10.611092,6.35509248 9.15614319,7.76481468 7.34999987,7.76481468 C5.54385656,7.76481468 4.08890778,6.35509248 4.08890778,4.60509251 C4.08890778,2.85509254 5.54385656,1.44537035 7.34999987,1.44537035 C9.15614319,1.44537035 10.611092,2.85509254 10.611092,4.60509251 Z M1.17901022,4.60509251 C1.53020475,4.14328697 2.38310576,3.12245365 3.76279857,2.36898144 C3.33634807,3.02523143 3.08549483,3.77870364 3.08549483,4.60509251 C3.08549483,5.43148139 3.33634807,6.1849536 3.76279857,6.84120359 C2.40819109,6.08773138 1.53020475,5.06689806 1.17901022,4.60509251 Z M10.9372012,6.84120359 C11.3636517,6.1849536 11.6145049,5.43148139 11.6145049,4.60509251 C11.6145049,3.77870364 11.3636517,3.02523143 10.9372012,2.36898144 C12.2918087,3.12245365 13.169795,4.14328697 13.5209895,4.60509251 C13.169795,5.06689806 12.2918087,6.08773138 10.9372012,6.84120359 Z M7.48255343,3.15240661 L5.85044078,4.73054728 C5.84675955,4.68918335 5.84488045,4.64734023 5.84488045,4.60509251 C5.84488045,3.80300919 6.52218419,3.14675921 7.34999987,3.14675921 C7.39464982,3.14675921 7.43886189,3.14866837 7.48255343,3.15240661 Z M8.84955897,4.47963775 C8.8532402,4.52100168 8.8551193,4.5628448 8.8551193,4.60509251 C8.8551193,5.40717583 8.17781556,6.06342582 7.34999987,6.06342582 C7.30534993,6.06342582 7.26113786,6.06151666 7.21744632,6.05777842 L8.84955897,4.47963775 Z" id="Combined-Shape"></path>
																					</g>
																					<polygon fill-rule="nonzero" points="2.48169892 11.6282124 13.2793824 1.18759514 12.3930323 0.33055555 1.59534881 10.7711728"></polygon>
																			</g>
																	</g>
															</g>
													</g>
											</g>
									</svg>

									<span class="visually-hidden">Toggle visibility</span>
								</button>
							</td>
							{{/each}}
						</tr>
					</thead>

					<tbody>
						{{#each formattedDensityData }}
						<tr class="js-density-row" data-technique="{{ label }}">
							<td class="density-row-header">
								{{ label }}
								<button type="button" class="tab__cta density-row-header__cta js-hide-row js-form-control">
									<?xml version="1.0" encoding="UTF-8"?>
									<svg class="on" width="15px" height="9px" viewBox="0 0 15 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
											<!-- Generator: Sketch 47.1 (45422) - http://www.bohemiancoding.com/sketch -->
											<title>Layer/ON</title>
											<desc>Created with Sketch.</desc>
											<defs></defs>
											<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.712522645">
													<g transform="translate(-144.000000, -1969.000000)" fill="#000000">
															<g transform="translate(118.000000, 1821.000000)">
																	<g transform="translate(26.000000, 146.500000)">
																			<g transform="translate(0.000000, 1.400000)">
																					<g fill-rule="nonzero">
																							<path d="M7.34999987,0.47314814 C4.20003576,0.47314814 1.49257677,2.19300922 0,4.60509251 C1.4913225,7.01498831 4.19752723,8.73703689 7.34999987,8.73703689 C10.5002148,8.73703689 13.207423,7.01717581 14.6999997,4.60509251 C13.2084264,2.19519672 10.5024725,0.47314814 7.34999987,0.47314814 Z M10.611092,4.60509251 C10.611092,6.35509248 9.15614319,7.76481468 7.34999987,7.76481468 C5.54385656,7.76481468 4.08890778,6.35509248 4.08890778,4.60509251 C4.08890778,2.85509254 5.54385656,1.44537035 7.34999987,1.44537035 C9.15614319,1.44537035 10.611092,2.85509254 10.611092,4.60509251 Z M1.17901022,4.60509251 C1.53020475,4.14328697 2.38310576,3.12245365 3.76279857,2.36898144 C3.33634807,3.02523143 3.08549483,3.77870364 3.08549483,4.60509251 C3.08549483,5.43148139 3.33634807,6.1849536 3.76279857,6.84120359 C2.40819109,6.08773138 1.53020475,5.06689806 1.17901022,4.60509251 Z M10.9372012,6.84120359 C11.3636517,6.1849536 11.6145049,5.43148139 11.6145049,4.60509251 C11.6145049,3.77870364 11.3636517,3.02523143 10.9372012,2.36898144 C12.2918087,3.12245365 13.169795,4.14328697 13.5209895,4.60509251 C13.169795,5.06689806 12.2918087,6.08773138 10.9372012,6.84120359 Z M5.84488045,4.60509251 C5.84488045,3.80300919 6.52218419,3.14675921 7.34999987,3.14675921 C8.17781556,3.14675921 8.8551193,3.80300919 8.8551193,4.60509251 C8.8551193,5.40717583 8.17781556,6.06342582 7.34999987,6.06342582 C6.52218419,6.06342582 5.84488045,5.40717583 5.84488045,4.60509251 Z" id="Combined-Shape"></path>
																					</g>
																			</g>
																	</g>
															</g>
													</g>
											</g>
									</svg>

									<?xml version="1.0" encoding="UTF-8"?>
									<svg class="off" width="15px" height="13px" viewBox="0 0 15 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
											<!-- Generator: Sketch 47.1 (45422) - http://www.bohemiancoding.com/sketch -->
											<title>Layer/ON</title>
											<desc>Created with Sketch.</desc>
											<defs></defs>
											<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.184442935">
													<g transform="translate(-144.000000, -2112.000000)" fill="#000000">
															<g transform="translate(118.000000, 1821.000000)">
																	<g transform="translate(26.000000, 291.500000)">
																			<g>
																					<g id="noun_878103-copy" transform="translate(0.000000, 1.400000)" fill-rule="nonzero">
																							<path d="M7.34999987,0.47314814 C4.20003576,0.47314814 1.49257677,2.19300922 0,4.60509251 C1.4913225,7.01498831 4.19752723,8.73703689 7.34999987,8.73703689 C10.5002148,8.73703689 13.207423,7.01717581 14.6999997,4.60509251 C13.2084264,2.19519672 10.5024725,0.47314814 7.34999987,0.47314814 Z M10.611092,4.60509251 C10.611092,6.35509248 9.15614319,7.76481468 7.34999987,7.76481468 C5.54385656,7.76481468 4.08890778,6.35509248 4.08890778,4.60509251 C4.08890778,2.85509254 5.54385656,1.44537035 7.34999987,1.44537035 C9.15614319,1.44537035 10.611092,2.85509254 10.611092,4.60509251 Z M1.17901022,4.60509251 C1.53020475,4.14328697 2.38310576,3.12245365 3.76279857,2.36898144 C3.33634807,3.02523143 3.08549483,3.77870364 3.08549483,4.60509251 C3.08549483,5.43148139 3.33634807,6.1849536 3.76279857,6.84120359 C2.40819109,6.08773138 1.53020475,5.06689806 1.17901022,4.60509251 Z M10.9372012,6.84120359 C11.3636517,6.1849536 11.6145049,5.43148139 11.6145049,4.60509251 C11.6145049,3.77870364 11.3636517,3.02523143 10.9372012,2.36898144 C12.2918087,3.12245365 13.169795,4.14328697 13.5209895,4.60509251 C13.169795,5.06689806 12.2918087,6.08773138 10.9372012,6.84120359 Z M7.48255343,3.15240661 L5.85044078,4.73054728 C5.84675955,4.68918335 5.84488045,4.64734023 5.84488045,4.60509251 C5.84488045,3.80300919 6.52218419,3.14675921 7.34999987,3.14675921 C7.39464982,3.14675921 7.43886189,3.14866837 7.48255343,3.15240661 Z M8.84955897,4.47963775 C8.8532402,4.52100168 8.8551193,4.5628448 8.8551193,4.60509251 C8.8551193,5.40717583 8.17781556,6.06342582 7.34999987,6.06342582 C7.30534993,6.06342582 7.26113786,6.06151666 7.21744632,6.05777842 L8.84955897,4.47963775 Z" id="Combined-Shape"></path>
																					</g>
																					<polygon fill-rule="nonzero" points="2.48169892 11.6282124 13.2793824 1.18759514 12.3930323 0.33055555 1.59534881 10.7711728"></polygon>
																			</g>
																	</g>
															</g>
													</g>
											</g>
									</svg>

									<span class="visually-hidden">Toggle visibility</span>
								</button>
							</td>
							{{#each data }}
							<td>
								<label class="visually-hidden" for="{{ id }}">Please enter a value</label>
								<input type="number" class="text-right has-spinners-disabled form-control js-arpro-density-data js-form-control" id="{{ id }}" data-id="{{ id }}" data-index="{{ @index }}" data-type="{{ label }}" value="{{ value }}" placeholder="Enter a value">
							</td>
							{{/each}}

						</tr>
						{{/each}}

					</thead>
				</table>

				<div id="mouldedDensityChart-wrapper" class="text-centre">
					<canvas id="mouldedDensityChart" class="density-chart" width="250" height="220"></canvas>
				</div>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="panel panel-default col-md-12">
			<div class="panel-body">
				<h3 class="panel-body-title">{{t "arpro.product.physicalProps"}}</h3>
				<div class="arpro-table-container" id="arpro-table-container">
				</div>
			</div>
		</div>
	</div>


	<!-- JSON Data -->
	<div id="json-area" class="form-group row developer-mode">
		<label id="json-label" class="control-label col-xs-12" for="raw-input">{{t "arpro.product.rawData"}}</label>
		<div class="col-xs-7">
			<textarea class="form-control json-area" id="raw-input">{{stringify model}}</textarea>
		</div>
	</div>
</div>
