<td>
    <img src="{{getPreviewUrl badge.src.x1}}">
</td>
<td>
    <dl>
        {{#key_value title.content "lang" "text"}}
            <dt>{{lang}}</dt>
            <dd>{{text}}</dd>
        {{/key_value}}
    </dl>
</td>
<td class="tools">
    <a href="/apps/{{appId}}/achievements/{{id}}" title="{{t "common.edit"}}" class="edit"><i class="icon-pencil"></i></a>
    <i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}"></i>
</td>
