{{#if @edit}}
	<td><input type="text" class="name" value="{{name}}"></td>
	<td><textarea class="description">{{description}}</textarea></td>
	<td class="tools">
		<i class="icon-save save"></i>
		<i class="icon-remove cancel"></i>
	</td>
{{else}}
	<td>{{name}}</td>
	<td>{{description}}</td>
	<td class="tools">
		<i class="icon-pencil edit"></i>
		{{! <i class="icon-trash delete"></i> }}
	</td>
{{/if}}
