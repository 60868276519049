/**
 * Exports {@link ListViewItem}.
 * @module
 */
module.exports = Backbone.View.extend(/** @lends ListViewItem.prototype */{
	/** @override */
	tagName: 'tr',

	/** @override */
	events: {
		'click .edit': 'edit',
		'click .cancel': 'cancel',
		'click .save': 'saveClick',
		'click .delete': 'delete',
		'click .delete-code': 'deleteCode'
	},

	/**
	 * @constructs ListViewItem
	 * @extends Backbone.View
	 * @override
	 */
	initialize: function(options) {
		this.listenTo(this.model, 'change', this.render)
		this.listenTo(this.model, 'sync', App.stopLoad)

		if (this.afterInitialize) {
			this.afterInitialize(options)
		}
	},

	edit: function() {
		this.render({edit: true})
	},

	cancel: function() {
		if (this.model.isNew()) {
			this.model.destroy()
			this.destroy()
		} else {
			this.render()
		}
	},

	delete: function() {
		this.model.requestLock(this.requestDelete.bind(this))
	},

	deleteCode: function(e) {
		this.model.requestLock(this.requestCodeDelete.bind(this, e))
	},

	requestCodeDelete: function(e) {
		var selectedGrade = e.currentTarget.dataset.grade
		swal({
			title: $.t('editor.inspector.areYouSure'),
			text: $.t('editor.inspector.confirmDelete'),
			type: 'input',
			inputPlaceholder: "Type in the grade name",
			showCancelButton: true,
			closeOnConfirm: false
		}, function(inputVal) {
			if (inputVal === selectedGrade) {
				App.startLoad()
				this.model.destroy().then(App.stopLoad).then(function() {
					swal("Success!", "Your product has been deleted.", "success")
				})
				this.remove()
			} else {
				swal("Incorrect grade name", "The product was not deleted", "error")
			}
		}.bind(this))
	},

	requestDelete: function() {
		swal({
			title: $.t('editor.inspector.areYouSure'),
			text: $.t('editor.inspector.confirmDelete'),
			showCancelButton: true
		}, function(didConfirm) {
			if (didConfirm) {
				App.startLoad()
				this.model.destroy().then(App.stopLoad)
				this.remove()
			}
		}.bind(this))
	},

	saveClick: function() {
		if (this.save) {
			// Show loading spinner
			App.startLoad()

			this.once('sync', function() {
				App.stopLoad()
			})

			this.save()
		}
		this.render()
	}
})
