var ManageAppsSectionView = require('./manage-apps-section-view'),
	LanguageList = require('editor/language-list'),
	SignupView = require('auth/signup-view')

module.exports = ManageAppsSectionView.extend({
	className: 'app-creation',
	template: require('./app-creation-view-template'),

	getPageTitle: function() {
		return $.t('manageApps.setup')
	},

	getPages: function() {
		return [
			{
				name: '[L]Setup',
				url: 'new'
			}
		]
	},

	initialize: function() {
		this.languageList = new LanguageList()
		this.languageList.fetch().then(this.ready.bind(this))

		this.views = {
			signup: new SignupView()
		}
	},

	events: {
		'click .content-type': 'step1ContentTypeClick',
		'click .step-1-next-button': 'step1NextButtonClick',
		'click .step-2-next-button': 'step2NextButtonClick',
		'click .step-3-next-button': 'step3NextButtonClick',
		'click .step-2-back-button': 'step2BackButtonClick',
		'click .step-3-back-button': 'step3BackButtonClick',
		'click .edit-content-types-button': 'step2BackButtonClick',
		'click .edit-languages-button': 'step3BackButtonClick',
		'click .edit-name-button': 'step5BackButtonClick',
		'change .app-languages': 'appLanguagesSelectChange',
		'input .app-name': 'appNameInputChange'
	},

	ready: function() {
		this.render()
		App.stopLoad()
	},

	getRenderData: function() {
		return {
			languages: this.languageList.toJSON()
		}
	},

	afterRender: function() {
		this.views.signup.remove()
		this.$('.signup-container').append(this.views.signup.el)
		this.views.signup.delegateEvents()
	},

	showStep: function(stepNumber) {
		this.$('.step.visible').removeClass('visible')
		this.$('.step-' + stepNumber).addClass('visible')
	},

	step1ContentTypeClick: function(e) {
		$(e.currentTarget).toggleClass('active')

		var canContinue = this.$('.content-type.active').length > 0,
			$nextButton = this.$('.step-1-next-button')

		if (canContinue) {
			$nextButton.removeAttr('disabled')
		} else {
			$nextButton.prop('disabled', true)
		}
	},

	step1NextButtonClick: function() {
		this.showStep(2)
		this.$('.app-languages').chosen()
		return false
	},

	step2NextButtonClick: function() {
		this.showStep(3)
		this.$('.app-name').focus()
		return false
	},

	step3NextButtonClick: function() {
		// TODO go to step 4, once implemented.
		this.step4NextButtonClick()
		return false
	},

	step4NextButtonClick: function() {
		var self = this

		var languages = this.$('.app-languages option:selected').map(function() {
			return self.languageList.get(Number(this.value))
		}).get()

		var languagesPreview = languages.map(function(lang) {
			return '<div>' + lang.get('name') + '</div>'
		}).join('')

		var appName = this.$('.app-name').val()

		var localisationsEnabled = this.$('.content-type.localisations').hasClass('active'),
			contentEnabled = this.$('.content-type.content-pages').hasClass('active')

		this.$('.summary-row .languages').html(languagesPreview)
		this.$('.app-name-preview').text(appName)
		this.$('.content-type-description.localisations').toggleClass('hidden', !localisationsEnabled)
		this.$('.content-type-description.content').toggleClass('hidden', !contentEnabled)

		this.showStep(5)
		return false
	},

	step2BackButtonClick: function() {
		this.showStep(1)
		return false
	},

	step3BackButtonClick: function() {
		this.showStep(2)
		return false
	},

	step5BackButtonClick: function() {
		// TODO go back to step 4
		this.showStep(3)
		return false
	},

	appLanguagesSelectChange: function(e) {
		var canContinue = $(e.currentTarget).find('option:selected').length > 0,
			$nextButton = this.$('.step-2-next-button')

		if (canContinue) {
			$nextButton.removeAttr('disabled')
		} else {
			$nextButton.prop('disabled', true)
		}
	},

	appNameInputChange: function(e) {
		var canContinue = e.currentTarget.value.trim().length > 0,
			$nextButton = this.$('.step-3-next-button')

		if (canContinue) {
			$nextButton.removeAttr('disabled')
		} else {
			$nextButton.prop('disabled', true)
		}
	}
})
