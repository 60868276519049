module.exports = Backbone.View.extend({
	template: require('./dev-pool-list-item-view-template'),

	events: {
		'click .advance-stage': 'completeStage'
	},

	initialize: function(options) {
		this.appId = options.appId
		this.listenTo(this.model, 'change', this.render, this)
	},

	getRenderData: function() {
		var data = _.deepClone(this.model.toJSON())

		// Pad each app language stage list to full length of stages
		data.details.forEach(function(app) {
			_.each(app.languages, function(lang) {
				while (lang.stages.length < data.stages.length) {
					lang.stages.push({})
				}
			})
		})

		data.appId = this.appId

		return data
	},

	afterRender: function() {
		if (this.model.get('details').length === 0) {
			this.$('.pool-no-apps').show()
			this.$('table').hide()
		}

		if (!App.acl.hasWritePermission('GDPC Console', 'Dev Pools')) {
			this.$('.dev-pool-edit-link, .admin').remove()
		}
	},

	completeStage: function(e) {
		App.startLoad()

		var lang = $(e.currentTarget).data('lang'),
			appId = $(e.currentTarget).data('app-id')

		// Find the app with the given language and app ID
		var app

		this.model.get('details').forEach(function(details) {
			if (details.appId === appId) {
				app = details
			}
		})

		var appLang = app.languages[lang]
		var appLanguageId = appLang.appLanguageId
		var stages = appLang.stages

		var lastStage = stages[stages.length - 1]

		// Don't try to complete the last stage if it's already completed
		if (lastStage.stageCompleted) {
			return App.stopLoad()
		}

		Backbone.sync('update', new Backbone.Model(), {
			url: App.apiRoot + 'stages/' + lastStage.stageId + '/complete',

			attrs: {appLanguageId: appLanguageId},

			success: function(model) {
				// Update local stage list
				appLang.stages = model[0].languages[lang].stages
				this.model.trigger('change', this.model)
				App.stopLoad()
			}.bind(this),

			error: function(jqXHR) {
				var reason

				try {
					var resp = JSON.parse(jqXHR.responseText)
					reason = resp['client error'].data
				} catch (err) {
					reason = 'Unknown error'
				}
				swal($.t('error.oops'), reason, 'error')
			}
		})
	}
})
