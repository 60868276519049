<h5 data-i18n="editor.inspector.properties.image.title">Image</h5>
<span class="actions">
	<button class="btn-link up"><i class="icon-arrow-up"></i></button>
	<button class="btn-link down"><i class="icon-arrow-down"></i></button>
	<button class="btn-link remove"><i class="icon-remove"></i></button>
</span>
	<div class="span4">
		<a href="{{getDownloadUrl src.x1}}" target="_blank" download><img src="{{getPreviewUrl src}}"></a>
	</div>

	{{#if text}}
		<div class="span1">
			<h6 data-i18n="editor.inspector.properties.caption"></h6>
		</div>
		<div class="span3">
			<input type="text" class="form-control text" value="{{getLocaleText text}}">
		</div>
	{{/if}}

	<div class="span1">
		<h6 data-i18n="editor.inspector.properties.delay"></h6>
	</div>

	<div class="span3">
		<input type="number" class="form-control delay" min="1" value="{{divide1000 delay}}">
	</div>
	<hr>
