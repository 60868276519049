var StormObject = require('./storm-object')

/**
 * Exports {@link StormCollection}.
 * @module
 */
var StormCollection = Backbone.Collection.extend(/** @lends StormCollection.prototype */{
	/** @override */
	model: StormObject,

	/**
	 * @constructs StormCollection
	 * @extends Backbone.Collection
	 * @override
	 */
	initialize: function() {
	},

	/**
	 * Merges the specified collection into this list.
	 * @param {StormCollection} collection Separate collection instance to
	 *     merge.
	 */
	mergeModel: function(collection) {
		// Pass through list once to update ordering of known models.
		// Necessary due to bugs in the API where ordering indices can get
		// messed up and models returned in the wrong order.
		collection.forEach(function(model, i) {
			var existingModel = this.get(model.id)

			if (this.indexOf(existingModel) !== i) {
				this.remove(existingModel)
				this.add(existingModel, {at: i})
			}
		}, this)

		// Pass through list again to merge each model.
		this.forEach(function(model, i) {
			model.mergeModel(collection.at(i))
		})
	}
})

/**
 * Creates a new {@link StormCollection} instance from the specified array of
 * raw model attributes. This method should be used over the constructor in all
 * instances, as it creates {@link StormObject} instances with the correct
 * subclass.
 * @param {Array.<Object>} objects Array of raw StormObject data.
 * @param {Object} [options] Optional Backbone options hash to pass to the
 *     constructor.
 * @returns {StormCollection} Collection of {@link StormObject} (or subclass)
 *     instances representing the input data.
 */
StormCollection.fromArray = function(objects, options) {
	var models = objects.map(function(item) {
		if (item instanceof Backbone.Model) {
			return item
		}

		return StormObject.fromProperties(item)
	})

	return new StormCollection(models, options)
}

module.exports = StormCollection
