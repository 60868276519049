<div class="container-fluid">
	<div class="row">
		<div class="col-xs-12"><h3>{{t "whatnow.whatnow"}}</h3></div>
	</div>
	<div class="row">
		<div class="col-md-6">
			{{#if noApp}}
			<select id="soc-selector" class="form-control">
					<option value="-">{{t "whatnow.selectSoc"}}</option>
					{{#each countryCodes}}
							<option value="{{code}}" {{#ifEqual code ../currentLang}}selected{{/ifEqual}}>{{code}} - {{description}}</option>
					{{/each}}
			</select>
			{{/if}}
		</div>
		<div class="col-md-6">
			<a id="addButton" href="/apps/{{currentLang}}/whatnow/new">
				<button type="button" class="button btn btn-primary add-whatnow-button pull-right"><i class="icon icon-plus"></i> {{t "common.add"}}</button>
			</a>
			<a id="attributionButton" href="/apps/{{currentLang}}/whatnow-attribution">
				<button type="button" class="button btn btn-primary attribution-button pull-right"><i class="icon icon-pencil"></i> {{t "whatnow.attribution.attribution"}}</button>
			</a>
		</div>
	</div>
	<div class="row">

	</div>
	<table class="whatnow-table" style="display:none">
		<thead>
			<tr>
				<th>{{t "whatnow.eventType"}}</th>
				<th>{{t "whatnow.title"}}</th>
				<th>{{t "whatnow.description"}}</th>
				<th>{{t "common.tools"}}</th>
			</tr>
		</thead>
		<tbody class="list-items"></tbody>
		<div class="app-error" id="noWhatnows" style="display:none">{{t "whatnow.empty"}}</div>

	</table>
</div>
