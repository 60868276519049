var MediaLibrary = require('media-library/media-library-view'),
	MediaSelectorView = require('media-library/media-selector-view'),
	EmbeddedMediaPreviewView = require('editor/canvas/embedded-media-preview-view')

module.exports = Backbone.View.extend({
	template: require('./embedded-media-selector-template'),

	events: {
		'click .image-select-button': 'selectImage',
		'click .animation-select-button': 'selectAnimation',
		'change .image-delay': 'imageDelayChange'
	},

	initialize: function(options) {
		this.collection = options.collection
		this.pageId = options.pageId
		this.views = {
			embeddedMediaPreview: new EmbeddedMediaPreviewView({collection: this.collection})
		}
	},

	getRenderData: function() {
		return this.collection.toJSON()
	},

	afterRender: function() {
		clearInterval(this.animationInterval)

		// Show animation preview, if applicable
		var first = this.collection.first()

		if (first && first.get('class') === 'AnimationImage') {
			this.animationInterval = setInterval(this.nextAnimationFrame.bind(this), first.get('delay'))
			this.currentAnimationFrame = -1
			this.nextAnimationFrame()

			// Set current delay value
			var delay = first.get('delay') / 1000
			this.$('.image-delay').val(delay)
		} else {
			this.$('.image-delay-container').remove()
		}

		this.collection.parent.trigger('change', this.collection.parent)

		// Move preview to the correct contianer.
		this.views.embeddedMediaPreview.$el.remove()
		this.$('.media-preview').append(this.views.embeddedMediaPreview.render().el)
	},

	nextAnimationFrame: function() {
		if (++this.currentAnimationFrame === this.collection.length) {
			this.currentAnimationFrame = 0
		}

		var images = this.$('.media-preview img')

		images.hide()
		images.eq(this.currentAnimationFrame).show()
	},

	selectImage: function(e) {
		this.collection.parent.needsSaving = true

		if (this.mediaLibrary) {
			this.mediaLibrary.destroy()
		}

		// Show media library
		this.mediaLibrary = new MediaSelectorView({
			app: Storm.app,
			model: this.collection,
			mediaType: MediaLibrary.types.IMAGE
		})

		this.mediaLibrary.on('change', this.render, this)

		$('body').append(this.mediaLibrary.el)
		this.mediaLibrary.render().show()

		// Don't bubble up to inspector
		e.stopPropagation()
	},

	selectAnimation: function(e) {
		this.collection.parent.needsSaving = true

		var model = this.collection.first()

		// Replace the current set of media with a new Animation, open the media library with that, if necessary.
		if (!model || model.get('class') !== 'Animation') {
			var oldMedia = this.collection.models.slice(0)

			oldMedia.forEach(function(media) {
				media.destroy()
			})

			var animation = App.getClassStructure('Animation', this.pageId)
			model = this.collection.add(animation)

			this.collection.parent.save()
		}

		// Show media library
		if (this.mediaLibrary) {
			this.mediaLibrary.destroy()
		}

		this.mediaLibrary = new MediaSelectorView({
			app: Storm.app,
			model: model.get('frames'),
			mediaType: MediaLibrary.types.ANIMATION
		})

		this.mediaLibrary.on('change', this.render, this)

		$('body').append(this.mediaLibrary.el)
		this.mediaLibrary.render().show()

		// Don't bubble up to inspector
		e.stopPropagation()
	},

	imageDelayChange: function() {
		var delay = parseFloat(this.$('.image-delay').val())

		if (delay < 0.1) {
			delay = 0.1
		}

		this.collection.each(function(frame) {
			frame.set('delay', delay * 1000)
		})

		this.collection.parent.needsSaving = true
		this.collection.parent.trigger('change', this.collection.parent)

		// Update preview
		clearInterval(this.animationInterval)
		this.animationInterval = setInterval(this.nextAnimationFrame.bind(this), delay * 1000)
	},

	beforeDestroy: function() {
		clearInterval(this.animationInterval)

		if (this.mediaLibrary) {
			this.mediaLibrary.destroy()
		}
	}
})
