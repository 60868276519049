var Page     = require('editor/page-list/page'),
	pageTags = require('editor/page-tags')

/**
 * Exports {@link TemplatesView}.
 * @module
 */
module.exports = Backbone.View.extend(/** @lends TemplatesView.prototype */{
	/** @override */
	template: require('./templates-view-template'),

	/** @override */
	className: 'add-form row',

	/** @override */
	events: {
		'click .template': 'selectTemplate',
		'click .cancel-new-page-button': 'cancel',
		'click .save-new-page-button': 'save'
	},

	/**
	 * @constructs TemplatesView
	 * @extends Backbone.View
	 * @override
	 */
	initialize: function(options) {
		this.app = options.app
		this.collection = this.app.templateList
	},

	/** @override */
	getRenderData: function() {
		var data = {
			templates: [],
			pageTags: pageTags.get(App.system.id)
		}

		this.collection.forEach(function(child) {
			var el = child.toJSON()
			el.preview = child.getPreview().outerHTML
			data.templates.push(el)
		})

		return data
	},

	cancel: function(e) {
		this.destroy()
		e.stopPropagation()
	},

	selectTemplate: function(e) {
		this.id = parseInt($(e.currentTarget).data('id'), 10)
		this.$('.template.selected').removeClass('selected')
		$(e.currentTarget).addClass('selected')
	},

	save: function() {
		// Get given properties
		var name = this.$('.new-page-name').val(),
			tag  = this.$('.new-page-tag').val()

		if (name && this.id) {
			App.startLoad()

			// Disable buttons and inputs
			this.$('input, select, button').attr('disabled', true)

			// Get class structure and create page
			var template    = this.collection.get(this.id),
				classObject = $.extend(true, {}, template.get('content'))

			classObject.title = App.getClassStructure('Text')
			classObject.title.content[Storm.view.language] = name
			classObject.tag = tag

			var page = Page.fromProperties(classObject),
				self = this

			// Do a triple save to fix ordering. Hack until API gets fixed.
			page.save(null, {appId: this.app.id}).then(function() {
				return page.lock.lock()
			}).then(function() {
				return Promise.resolve(page.save())
			}).then(function() {
				return Promise.resolve(page.save())
			}).then(function() {
				// Add to page list and set as current page
				Storm.view.views.pageList.collection.add(page)
				Storm.view.setPageId(page.id)
				Storm.view.cancelNewPage()

				self.destroy()
				App.stopLoad()
				page.unlock()
			})
		}
	}
})
