<div class="col-xs-12">
	<h4 data-i18n="editor.newFromTemplate"></h4>
</div>
<div class="form-group col-sm-4">
	<div class="input-group">
		<span class="input-group-addon" data-i18n="editor.pageName"></span>
		<input type="text" class="form-control new-page-name">
	</div>
</div>
<div class="form-group col-sm-3">
	<div class="input-group">
		<span class="input-group-addon" data-i18n="editor.pageTag"></span>
		<select class="form-control new-page-tag">
			{{#each pageTags}}
				<option>{{this.name}}</option>
			{{/each}}
		</select>
	</div>
</div>
<div class="col-xs-12">
	<h4 data-i18n="editor.pageType"></h4>
</div>
<div class="col-xs-12 templates-list">
	{{#each templates}}
		<div class="template" title="{{name}}" data-id="{{id}}">
			<div class="template-preview">{{{preview}}}</div>
			<p>{{description}}</p>
		</div>
	{{/each}}
</div>
<div class="col-xs-12">
	<h5>&nbsp;</h5>
	<button class="button green save-new-page-button">{{t "editor.addPage"}}</button>
	<button class="button white cancel-new-page-button">{{t "common.cancel"}}</button>
</div>
