module.exports = Backbone.Model.extend({
	urlRoot: function() {
		return App.apiRoot + 'mailer'
	},

	doExport: function(from, to) {
		if (this.isNew()) {
			return $.when()
		}

		var url = this.url() + '/export?'

		if (from) {
			url += 'from=' + from + '&'
		}

		if (to) {
			url += 'to=' + to
		}

		return $.ajax({
			url: url,
			headers: App.session.getHeadersObject()
		})
	}
})
