<!-- TODO localise -->
<form class="step step-1 row visible">
	<div class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2">
		<h1 class="light">[L]What type of content do you use?</h1>
		<h2><small>[l]All apps are built for iOS and Android and you can preview what apps will look like in both.</small></h2>
		<div class="row">
			<div class="col-sm-6">
				<div class="content-type localisations">
					<div class="img"></div>
					<h4>[L]Localisations</h4>
					<div>[L]Changeable strings in multiple languages</div>
				</div>
			</div>
			<div class="col-sm-6">
				<div class="content-type content-pages">
					<div class="img"></div>
					<h4>[L]Content pages</h4>
					<div>[l]FAQs, legal pages, terms and conditions, etc.</div>
				</div>
			</div>
			<div class="col-sm-8 col-sm-offset-2 form-group">
				<button class="step-1-next-button button big btn-block" type="submit" data-i18n="common.next" disabled></button>
			</div>
		</div>
	</div>
</form>
<form class="step step-2 row">
	<div class="col-md-6 col-md-offset-3 col-sm-6 col-sm-offset-3">
		<h3 class="light">[L]What languages will it be in?</h3>
		<h4><small>[L]You will have to add content for each language you select.</small></h4>
		<div class="form-group">
			<select class="app-languages form-control" multiple data-placeholder="[L]Select a language...">
				{{#each languages}}
					<option value="{{id}}">{{name}}</option>
				{{/each}}
			</select>
		</div>
		<div class="form-group">
			<button class="button big btn-block step-2-next-button" type="submit" data-i18n="common.next" disabled></button>
			<button class="button white step-2-back-button step-back-button"><i class="icon-chevron-left"></i>&nbsp;<span data-i18n="common.back"></span></button>
		</div>
	</div>
</form>
<form class="step step-3 row">
	<div class="col-md-6 col-md-offset-3 col-sm-6 col-sm-offset-3">
		<h3 class="light">[L]What's your app called?</h3>
		<h4><small>[L]We recommend something short and punchy!</small></h4>
		<div class="form-group">
			<input type="text" class="app-name form-control input-lg" placeholder="My Awesome App">
		</div>
		<div class="form-group">
			<button class="button big btn-block step-3-next-button" type="submit" data-i18n="common.next" disabled></button>
			<button class="button white step-3-back-button step-back-button"><i class="icon-chevron-left"></i>&nbsp;<span data-i18n="common.back"></span></button>
		</div>
	</div>
</form>
<form class="step step-4 row">
	<div class="col-md-6 col-md-offset-3 col-sm-6 col-sm-offset-3">
		<h3 class="light">[L]Choose an icon</h3>
		<h4><small>[L]You can change this later</small></h4>
		<div class="form-group">
			<button class="button big btn-block step-4-next-button" type="submit" data-i18n="common.next" disabled></button>
			<button class="button white step-4-back-button step-back-button"><i class="icon-chevron-left"></i>&nbsp;<span data-i18n="common.back"></span></button>
		</div>
	</div>
</form>
<form class="step step-5 row">
	<div class="col-md-6 col-md-offset-3 col-sm-6 col-sm-offset-3">
		<h3 class="light">[L]Review your app <span class="app-name"></span></h3>
		<h4><small>[L]Make sure everything is a-ok before you create your app!</small></h4>
		<div class="summary">
			<div class="row summary-row">
				<div class="col-sm-2 col-xs-3">
					<img src="/images/app-content-types-icon.png">
				</div>
				<div class="col-sm-7 col-xs-9">
					<h4>[L]Content types</h4>
					<p>[L]<span class="content-types-count">12</span> content type selected</p>
				</div>
				<div class="col-sm-3 col-xs-6 col-xs-offset-3 col-sm-offset-0">
					<button class="button white edit-button edit-content-types-button" data-i18n="common.edit"></button>
				</div>
				<div class="col-xs-10 col-xs-offset-2">
					<div class="content-type-description localisations">
						<div class="type-icon"></div>&nbsp;<h4>[L]Localisations</h4>
					</div>
					<div class="content-type-description content">
						<div class="type-icon"></div>&nbsp;<h4>[L]Content</h4>
					</div>
				</div>
			</div>
			<div class="row summary-row">
				<div class="col-sm-2 col-xs-3">
					<img src="/images/app-languages-icon.png">
				</div>
				<div class="col-sm-7 col-xs-9">
					<h4>[L]Languages</h4>
					<div class="languages"></div>
				</div>
				<div class="col-sm-3 col-xs-6 col-xs-offset-3 col-sm-offset-0">
					<button class="button white edit-button edit-languages-button" data-i18n="common.edit"></button>
				</div>
			</div>
			<div class="row summary-row">
				<div class="col-sm-2 col-xs-3">
					<img src="/images/app-name-icon.png">
				</div>
				<div class="col-sm-7 col-xs-9">
					<h4 class="app-name-preview"></h4>
					<p>[L]This is the name for your app</p>
				</div>
				<div class="col-sm-3 col-xs-6 col-xs-offset-3 col-sm-offset-0">
					<button class="button white edit-button edit-name-button" data-i18n="common.edit"></button>
				</div>
			</div>
		</div>
		<div class="form-group">
			<button class="button big btn-block step-3-next-button" type="submit" disabled>[L]Create</button>
		</div>
	</div>
</form>
<div class="modal signup-modal">
	<div class="modal-dialog">
		<div class="modal-content col-xs-12 signup-container"></div>
	</div>
</div>
