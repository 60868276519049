<div class="modal-header">
	<button class="button light pull-left cancel-button">{{t "common.cancel"}}</button>
	<button class="button positive pull-right save-button">{{t "common.save"}}</button>
	<h4>{{feed.code}} {{t "hazards.feeds.events"}}</h4>
</div>
<div class="modal-body">
	<table>
		<thead>
			<th>{{t "hazards.feeds.enable"}}</th>
			<th>{{t "hazards.feeds.type"}}</th>
		</thead>
		<tbody>
			{{#each feedEvents}}
				<tr data-id="{{id}}">
				<td class="center"><input type="checkbox" class="storm-checkbox enabled-checkbox" {{#unless isEnabled}}disabled{{/unless}} {{#if isAssigned}}checked{{/if}}></td>
					<td>{{getBrowserLocaleText name}}</td>
				</tr>
			{{/each}}
		</tbody>
	</table>
</div>
