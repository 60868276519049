module.exports = Backbone.View.extend({
	template: require('./push-list-item-view-template'),
	tagName: 'tr',

	className: 'push-row',

	events: {
		'click .delete': 'deleteItem'
	},

	initialize: function(options) {
		this.listenTo(this.model, 'change', this.render, this)
		this.appId = options.appId
	},

	getRenderData: function() {
		var data = this.model.toJSON()
		data.appId = this.appId
		return data
	},

	afterRender: function() {
		if (App.acl.hasReadPermission('Push')) {
			this.$('.tools').text('-')
		}

		if (App.acl.getPermission('Push') !== 'Approve') {
			this.$('.ns-name').remove()
		}

		// Don't show tools on sent notifications.
		if (this.model.get('status') > 1) {
			this.$('.tools').text('')
		}
	},

	deleteItem: function(e) {
		if (confirm($.t('editor.inspector.confirmDelete'))) {
			App.startLoad()

			this.model.once('sync', function() {
				this.destroy()
				App.stopLoad()
			}, this)

			this.model.destroy()
		}

		e.stopPropagation()
	}
})
