var ListViewItem = require('list-view/list-view-item')

module.exports = ListViewItem.extend({
	template: require('./contact-list-item-view-template'),

	initialize: function(options) {
		this.appId = options.appId
		this.parentId = options.parentId
	},

	getRenderData: function() {
		var data = this.model.toJSON()
		data.appId = this.appId
		data.parentId = this.parentId
		return data
	},

	afterRender: function() {
		if (!App.acl.hasWritePermission('CrossBorder')) {
			this.$('.tools').text('-')
		}
	}
})
