module.exports = Backbone.Model.extend({
	initialize: function(options) {
		options = options || {}

		// Support appId option for legacy compatibility.
		if (options.appId !== undefined) {
			this.appId = options.appId
			this.set('id', options.appId)
		}
	},

	url: function() {
		return App.apiRoot + 'apps/' + this.id + '/native'
	}
})
