var MediaLibraryItemView = require('./media-library-item-view'),
	UploadList           = require('./upload-list'),
	utils                = require('lib/utils')

module.exports = MediaLibraryItemView.extend({
	className: 'image-list-item image file paused',

	afterInitialize: function() {
		// Check if the model is an animation.
		this.isAnimation = false
		this.currentFrame = 0
		this.animationId = null

		this.model.get('tags').forEach(function(tagId) {
			var tag = this.tagList.get(tagId)

			// TODO taglist is undefined sometimes

			if (tag.get('name') === 'animation') {
				this.isAnimation = true
				this.$el.addClass('animation')
				this.$el.removeClass('image')
			}
		}, this)

		this.frames = new UploadList()

		// Bind extra event listeners.
		this.events['click .play-button i'] = 'playButtonClick'
		this.events['click .pause-button i'] = 'pauseButtonClick'

		this.delegateEvents()
	},

	afterRender: function() {
		var url = utils.getRemoteUrl(this.model.get('filename'))
		this.$('.image-backdrop').html('<img src="' + url + '">')

		// Show play button on animations.
		if (this.isAnimation) {
			this.$('.image-backdrop').append('<div class="play-button"><i class="icon-play"></i></div><div class="pause-button"><i class="icon-pause"></i></div>')
			this.$('.image-backdrop').append('<i class="spinner icon-spinner"></i>')

			this.$el.addClass('paused').removeClass('playing')
			this.currentFrame = 0
			clearInterval(this.animationTimer)

			// Clear frames list.
			this.frames.reset()
		}
	},

	playButtonClick: function(e) {
		if (this.frames.length === 0) {
			this.showSpinner()

			// Find animation ID.
			this.model.get('tags').forEach(function(tagId) {
				var tag = this.tagList.get(tagId)
				var tagMatch = tag.get('name').match(/^animation-(\w+)$/)

				if (tagMatch !== null) {
					this.animationId = tagMatch[1]
				}
			}, this)

			this.frames.once('sync', this.framesFetched, this)
			this.frames.fetch({
				headers: {Range: 'indices=0-9999'},
				data: {tags: 'animation-' + this.animationId}
			})
		} else {
			this.playAnimation()
		}

		e.stopPropagation()
	},

	pauseButtonClick: function(e) {
		this.$el.addClass('paused').removeClass('playing')
		clearInterval(this.animationTimer)
		e.stopPropagation()
	},

	framesFetched: function() {
		// Remove preview frame.
		this.$('img').remove()

		// Output all frames.
		this.frames.forEach(function(frame) {
			var frameIndex = frame.getFrameIndex(this.tagList, this.animationId)

			if (frameIndex === null) {
				console.warn('Animation frame index tag missing')
				return
			}

			var url = utils.getRemoteUrl(frame.get('filename'))
			this.$('.image-backdrop').append('<img src="' + url + '" data-frame="' + frameIndex + '">')
		}, this)

		// Only show first frame.
		this.$('img').hide()
		this.$('img[data-frame=0]').show()

		// Start animation preview.
		this.playAnimation()
		this.hideSpinner()
	},

	playAnimation: function() {
		this.animationTimer = setInterval(this.nextAnimationFrame.bind(this), 250)
		this.$el.addClass('playing').removeClass('paused')
	},

	showSpinner: function() {
		this.$('.spinner').show().addClass('icon-spin').show()
	},

	hideSpinner: function() {
		this.$('.spinner').removeClass('icon-spin').hide()
	},

	nextAnimationFrame: function() {
		if (++this.currentFrame === this.frames.length) {
			this.currentFrame = 0
		}

		this.$('img').hide()
		this.$('img[data-frame=' + this.currentFrame + ']').show()
	},

	beforeDestroy: function() {
		clearInterval(this.animationTimer)
	}
})
