<td class="center">{{code}}</td>
<td>
	<dl>
		{{#key_value country.content "lang" "text"}}
			<dt>{{lang}}</dt>
			<dd>{{text}}</dd>
		{{/key_value}}
	</dl>
</td>
<td class="tools">
	<a href="/apps/{{appId}}/cross-borders/{{id}}" title="{{t "common.edit"}}"><i class="icon-pencil"></i></a>
	<i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}"></i>
</td>
