var UploadVariants = require('./upload-variants')

module.exports = Backbone.DeepModel.extend({
	urlRoot: function() {
		return App.apiRoot + 'uploads'
	},

	removeTag: function(tag, all) {
		// Remove tag from the local model.
		var tags = this.get('tags'),
			index = tags.indexOf(tag.id)

		if (index) {
			tags.splice(index, 1)
			this.set('tags', tags)
		}

		return this.syncTags('delete', this.id, tag, all)
	},

	addTag: function(tag, all) {
		// Add tag to the local model.
		var tags = this.get('tags')
		tags.push(tag.id)
		this.set('tags', tags)

		return this.syncTags('update', this.id, tag, all)
	},

	syncTags: function(method, id, tag, all) {
		var model = {tags: [tag]},
			url = this.url() + '/tags'

		// Add/remove tag to all sizes if specified.
		if (all) {
			url += '?all=true'
		}

		return Backbone.sync(method, this, {
			url: url,
			data: JSON.stringify(model),
			contentType: 'application/json'
		})
	},

	// Get the index of this frame in the specified animation.
	getFrameIndex: function(tagList, animationId) {
		var frameRegex    = new RegExp('^' + animationId + '-frame-(\\d+)$'),
			fallbackRegex = /^frame-(\d+)$/,
			frameIndex    = null,
			fallbackIndex = null

		this.get('tags').forEach(function(tag) {
			var name        = tagList.get(tag).get('name'),
				frameTag    = frameRegex.exec(name),
				fallbackTag = fallbackRegex.exec(name)

			if (frameTag !== null) {
				frameIndex = Number(frameTag[1])
			}

			if (fallbackTag !== null) {
				fallbackIndex = Number(fallbackTag[1])
			}
		})

		if (frameIndex !== null) {
			return frameIndex
		}

		return fallbackIndex
	},

	// Get all variants for this upload (resolutions/formats etc.).
	getVariantList: function() {
		var parentId
		if (this.get('parentId')) {
			parentId = this.get('parentId')
		} else {
			parentId = this.parentId
		}

		if (!parentId || parentId === undefined) {
			parentId = this.id
		}
		return new UploadVariants({id: parentId})
	}
})
