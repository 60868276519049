var DashboardSectionView = require('dashboard/dashboard-section-view'),
	PushEditorView       = require('./push-editor-view')

/**
 * Exports {@link PushEditView}.
 * @module
 */
module.exports = DashboardSectionView.extend(/** @lends PushEditView.prototype */{
	/** @override */
	template: require('./push-edit-view-template'),

	/** @override */
	events: {
		'click .save-button': 'saveButtonClick_'
	},

	/** @override */
	getPageTitle: function() {
		return $.t('push.editPush')
	},

	/**
	 * @constructs PushEditView
	 * @extends DashboardSectionView
	 * @override
	 * @classdesc Wraps {@link PushEditorView} in a standalone Storm page.
	 */
	initialize: function(options) {
		if (!options.app) {
			throw new Error('No app specified')
		}

		/** @private {App} */
		this.app_ = options.app

		/** @private {PushEditorView} */
		this.pushEditorView_ = new PushEditorView(options)
	},

	/** @override */
	afterRender: function() {
		this.$el.append(this.pushEditorView_.el)
		this.pushEditorView_.render()
	},

	/**
	 * Handles clicks to the 'save' button. Saves the content of the editor and
	 * redirects back to the push list.
	 * @private
	 */
	saveButtonClick_: function() {
		App.startLoad()

		var appId = this.app_.id

		this.pushEditorView_.save().then(function() {
			App.stopLoad()
			App.router.navigate('/apps/' + appId + '/push', {trigger: true})
		}, function(msg) {
			var oops = $.t('error.oops'),
				error = 'error'

			if (typeof msg === 'string') {
				swal(oops, msg, error)
			} else {
				swal(oops, $.t('error.generic'), error)
			}

			App.stopLoad()
		})
	}
})
