<div class="row">
	<div class="col-xs-12 actions-col">
		<button class="button green wide save-button">{{t "common.save"}}</button>
	</div>

	<div class="col-xs-12 tutorial-box">
		<h4>{{t "hazards.disasters.tutorial.heading"}}</h4>
		<div>{{t "hazards.disasters.tutorial.body"}}</div>
		<button class="button white close-tutorial-button">{{t "common.closeThisMessage"}}</button>
	</div>

	<table class="disasters-table col-xs-12">
		<thead>
			<tr class="nodrag nodrop">
				<th>{{t "appSetup.dev"}}</th>
				<th>{{t "appSetup.live"}}</th>
				<th>{{t "push.type"}}</th>
				<th>{{t "hazards.disasters.rootPage"}}</th>
				<th>{{t "hazards.disasters.alertSettings"}}</th>
				<th>{{t "common.tools"}}</th>
			</tr>
		</thead>
		<tbody class="list-items"></tbody>
	</table>
</div>
