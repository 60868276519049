<div class="row">
	<form class="form-horizontal">
		<fieldset class="col-md-6 edit-form">
			<legend>{{t "hazards.alerts.createNewAlert"}}</legend>

			<div class="form-group">
				<label class="control-label col-sm-2" for="alert-page">{{t "common.page"}}</label>
				<div class="col-sm-10">
					<select id="alert-page" class="form-control">{{{pageSelectOptions}}}</select>
					<p class="help-block">
						<span>{{t "hazards.alerts.custom.announcementPage"}}</span>
					</p>
				</div>
			</div>

			<div class="form-group">
				<label class="control-label col-sm-2" for="alert-message">{{t "push.message"}}</label>
				<div class="col-sm-10">
					<textarea id="alert-message" class="form-control"></textarea>
					<p class="help-block">{{t "hazards.alerts.custom.message"}}</p>
				</div>
			</div>

			<div class="form-group">
				<label class="control-label col-sm-2" for="alert-expiry">{{t "hazards.alerts.custom.expiry"}}</label>
				<div class="col-sm-10">
					<input type="text" id="alert-expiry" class="form-control">
					<p class="help-block">{{t "hazards.alerts.custom.expiryHelp"}}</p>
				</div>
			</div>

			<div class="form-group">
				<label class="control-label col-sm-2">{{t "hazards.alerts.custom.area"}}</label>
				<div class="col-sm-10">
					<div class="map-preview">
						<div class="alert-preview-map google-maps"></div>
						<div class="map-button"></div>
					</div>
					<p class="help-block">{{t "hazards.alerts.custom.areaHelp"}}</p>
				</div>
			</div>

			<div class="form-group">
				<label class="control-label col-sm-2" for="alert-notify">{{t "hazards.alerts.custom.notify"}}</label>
				<div class="col-sm-10">
					<label class="checkbox"><input type="checkbox" id="alert-notify"> {{t "hazards.alerts.custom.sendNotification"}}</label>
				</div>
			</div>
		</fieldset>
	</form>
	<div class="col-sm-5 alert-preview hidden">
		<div class="display-preview">
			<h1>20:50</h1>
			<div><b>Hazards</b> now</div>
			<p class="alert-preview-text"></p>
			<div><small>slide to view</small></div>
			<h4>Slide to unlock</h4>
		</div>
	</div>
	<div class="col-sm-7 alert-preview hidden">
		<div class="canvas-area"></div>
	</div>
	<div class="col-sm-5 alert-preview hidden"></div>
	<div class="col-sm-7 actions-col">
		<a href="javascript:history.go(-1)"><button class="button white">{{t "common.cancel"}}</button></a>
		<button class="button green preview-button">{{t "common.next"}}</button>
		<button class="button white preview-back-button hidden">{{t "common.back"}}</button>
		<button class="button green save-button hidden">{{t "push.saveAndSend"}}</button>
	</div>
</div>
