var ListItemView = require('list-view/list-view-item'),
	utils = require('lib/utils')

var StormQLListItemView = ListItemView.extend({
	tagName: 'tr',

	initialize: function() {
		this.structure = App.classes.get(this.model.get('class'))
		this.listenTo(this.model, 'change', this.render, this)

		if (StormQLListItemView.pageList) {
			this.listenTo(StormQLListItemView.pageList, 'sync', this.render, this)
		}
	},

	render: function() {
		var html = '',
			data = this.model.toJSON(),
			keys = Object.keys(this.structure)

		var imageIndex = keys.indexOf('image')

		if (imageIndex > -1) {
			keys.splice(imageIndex, 1)
			keys.unshift('image')
		}

		for (var i = 0; i < keys.length; i++) {
			var key = keys[i]

			if (key === 'class') {
				continue
			}

			var type = getPropertyOverride(this.structure, key)

			html += '<td>'

			switch (type) {
				case '<String>':
				case '<Number>':
					html += data[key]
					break

				case '<Boolean>':
					html += data[key] ? 'Yes' : '-'
					break

				case '{Text}':
					html += getTextPreview(data[key])
					break

				case '{Image}':
					html += '<img src="' + utils.getImagePreviewUrl(data[key]) + '">'
					break

				case '<ImageURL>':
					html += '<img src="' + utils.getRemoteUrl(data[key]) + '">'
					break

				case '{Link}':
				case '{DestinationLink}':
					html += getLinkPreview(data[key])
					break

				case '{Share}':
					html += getTextPreview(data.share.text)
					html += getLinkPreview(data.share.link)
					break
			}

			html += '</td>'
		}

		html += '<td class="tools"><i class="icon-pencil edit" data-id="' + this.model.id + '"></i><i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}"></i></td>'

		this.$el.html(html)

		this.afterRender()
		return this
	},

	afterRender: function() {
		if (App.acl.hasReadPermission('Content')) {
			this.$('.tools').text('-')
		}
	},

	edit: function() {}
})

function getPropertyOverride(structure, key) {
	if (structure.class === 'StormQLFrame' && key === 'src') {
		return '<ImageURL>'
	}

	// No overrides.
	return structure[key]
}

/**
 * Constructs a preview for a Text object showing all languages.
 * @param {Object} text A Storm Text object.
 * @returns {string} An HTML string with the preview content.
 */
function getTextPreview(text) {
	var html = '<dl>'

	for (var lang in text.content) {
		if (text.content.hasOwnProperty(lang)) {
			html += '<dt>' + lang + '</dt>'
			html += '<dd>' + text.content[lang] + '</dd>'
		}
	}

	html += '</dl>'
	return html
}

/**
 * Constructs a preview for a Link object.
 * @param {Object} link A Storm Link object.
 * @returns {string} An HTML string with the preview content.
 */
function getLinkPreview(link) {
	var url = link.destination

	if (!url) {
		return ''
	}

	if (link.class === 'InternalLink') {
		var pageId = App.getIdFromCacheUrl(url),
			page   = StormQLListItemView.pageList.get(pageId)

		if (!page) {
			return ''
		}

		return '[' + page.get('tag') + '] ' + App.l(page.get('title'))
	}

	return '<a href="' + url + '">' + url + '</a>'
}

module.exports = StormQLListItemView
