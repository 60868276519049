var SocietiesPoolsList = require('./societies-pools-list'),
	DevPoolList        = require('./dev-pool-details-list'),
	AppList            = require('editor/app-list'),
	GDPCSectionView    = require('./gdpc-section-view')

module.exports = GDPCSectionView.extend({
	template: require('./societies-view-template'),

	events: {
		'click .sort-button': 'changeSortComparator'
	},

	initialize: function(options) {
		this._readyCount = 0
		this.appId = options.appId

		this.collection = new SocietiesPoolsList()
		this.collection.once('sync', this.ready, this)
		this.collection.fetch()

		this.devPoolList = new DevPoolList()
		this.devPoolList.once('sync', this.ready, this)
		this.devPoolList.fetch()

		this.appList = new AppList()
		this.appList.once('sync', this.ready, this)
		this.appList.fetch()

		this.societies = []
		this.totals = {
			ios: 0,
			android: 0,
			total: 0
		}
	},

	getPageTitle: function() {
		return $.t('gdpcConsole.nationalSocieties.title')
	},

	getRenderData: function() {
		return {
			societies: this.societies,
			appId: this.appId,
			totals: this.totals
		}
	},

	ready: function() {
		App.stopLoad()
		App.startLongLoad()
		this._readyCount++

		if (this._readyCount > 2) {
			// Create an entry for each app.
			this.appList.forEach(function(app) {
				var societyId    = app.get('societyId'),
					society      = this.collection.get(societyId),
					defaultStats = {
						ios: {},
						android: {}
					}

				var stats = _.findWhere(society.get('stats'), {appId: app.id}) || defaultStats,
					pool  = this.getPoolForApp(app.id, society.get('pools'))

				pool = pool ? pool.toJSON() : {name: '-'}

				stats.ios = stats.ios || {}
				stats.android = stats.android || {}

				this.societies.push({
					description: society.get('description'),
					app: app.get('name'),
					rating: stats.rating,
					androidDownloads: stats.android.downloads || 0,
					iosDownloads: stats.ios.downloads || 0,
					totalDownloads: stats.downloads || 0,
					pool: pool.name,
					stage: this.getCurrentStage(pool, app.id),
					goLive: this.getGoLiveDate(pool),
					id: society.id
				})
			}, this)

			// calculate overall totals
			this.totals = _.reduce(this.societies, function(runningTotals, society) {
				runningTotals.ios += society.iosDownloads
				runningTotals.android += society.androidDownloads
				runningTotals.total += society.totalDownloads

				return runningTotals
			}, this.totals)

			// Initially sort societies list by total downloads.
			this.societies = _.sortBy(this.societies, 'totalDownloads').reverse()

			this.render()
			App.stopLongLoad()
		}
	},

	getPoolForApp: function(appId, poolIds) {
		for (var i = 0; i < poolIds.length; i++) {
			var pool = this.devPoolList.get(poolIds[i])

			if (_.findWhere(pool.get('details'), {appId: appId})) {
				return pool
			}
		}

		return null
	},

	getCurrentStage: function(pool, appId) {
		if (!(pool && pool.details)) {
			return '-'
		}

		var details

		pool.details.some(function(app) {
			if (app.appId === appId) {
				details = app
				return true
			}

			return false
		})

		if (!details) {
			return '-'
		}

		var lang = Object.keys(details.languages)[0]
		var stages = details.languages[lang].stages

		return stages[stages.length - 1].stageName
	},

	getGoLiveDate: function(pool) {
		var stages = pool.stages

		if (stages && stages.length) {
			// Return final stage due date
			return stages[stages.length - 1].timestamp
		}

		return 0
	},

	afterRender: function() {
		if (!App.acl.hasWritePermission('GDPC Console', 'National Societies')) {
			this.$('.add').remove()
			this.$('.edit-link').remove()
		}
	},

	changeSortComparator: function(e) {
		var comparator = $(e.currentTarget).data('property')

		if (comparator !== this.comparator) {
			this.reverse = false
		} else {
			this.reverse = !this.reverse
		}

		this.comparator = comparator
		this.societies = _.sortBy(this.societies, comparator)

		if (this.reverse) {
			this.societies.reverse()
		}

		this.render()
	}
})
