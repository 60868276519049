<div class="row">
	<div class="form-group col-sm-12">
		<h4 data-i18n="push.what"></h4>
		<div class="input-group">
			<span class="input-group-addon" data-i18n="push.message"></span>
			<input type="text" id="push-message" class="form-control" placeholder="{{t "push.messageDescription"}}">
			<span class="input-group-addon message-char-count"></span>
		</div>
	</div>
	<div class="form-group col-sm-5">
		<h4 data-i18n="push.when"></h4>
		<div class="input-group">
			<span class="input-group-addon">{{t "agreement.date"}}</span>
			<input type="text" id="push-date" class="form-control">
		</div>
	</div>
	<div class="col-xs-12">
		<h4 data-i18n="common.settings"></h4>
		<div class="row">

			<div class="col-xs-12">
				<div class="row">

					<div class="col-sm-3">
						<div class="form-group">
							<div class="input-group">
								<span class="input-group-addon">{{t "common.page"}}</span>
								<select id="push-page" class="form-control">
									<option value="">{{t "permissions.none"}}</option>
								</select>
							</div>
						</div>
					</div>

					<!-- <div class="col-sm-3">
						<div class="form-group">
							<div class="input-group">
								<span class="input-group-addon">{{t "push.priority"}}</span>
								<select id="push-priority" class="form-control">
									<option value="3">{{t "push.priorities.low"}}</option>
									<option value="2">{{t "push.priorities.high"}}</option>
								</select>
							</div>
						</div>
					</div> -->

					<div class="col-sm-3">
						<div class="form-group">
							<div class="input-group">
								<span class="input-group-addon">{{t "push.category"}}</span>
								<select id="push-category" class="form-control">
									<option value="standard">{{t "push.categories.standard"}}</option>
									<option value="region">Region</option>
									<option value="custom">Custom</option>
								</select>
							</div>
						</div>
					</div>

					<div class="col-sm-3">
						<div class="input-group">
							<span class="input-group-addon">{{t "push.notes"}}</span>
							<input type="text" id="push-notes" class="form-control" placeholder="{{t "push.notes"}}">
						</div>
					</div>
				</div>
			</div>

			<div class="col-sm-6 col-lg-4 map-preview-group">
				<div class="form-group">
					<label class="control-label">{{t "push.custom.area"}}</label>
					<div class="map-preview">
						<div class="push-preview-map google-maps"></div>
						<div class="map-button"></div>
					</div>
					<p class="help-block">{{t "push.custom.areaHelp"}}</p>
				</div>
			</div>

			<div class="col-sm-6 col-lg-4 push-tokens">
				<div class="form-group">
					<label class="control-label" for="push-tokens">Push tokens</label>
					<textarea id="push-tokens" class="push-textarea form-control"></textarea>
					<p class="help-block">One per line</p>
				</div>
			</div>
		</div>
	</div>
</div>
