var FeedEventConfigureView = require('./feed-configure-view')

module.exports = Backbone.View.extend({
	template: require('./feeds-list-item-view-template'),
	tagName: 'tr',

	events: {
		'click .configure-button': 'configureEvents',
		'click .delete-feed': 'deleteFeed'
	},

	initialize: function(options) {
		this.views = {}

		this.feed = options.feed
		this.appId = options.appId
		this.enabledEvents = options.enabledEvents
		this.feedEvents = options.feedEvents
		this.model = options.model
	},

	getRenderData: function() {
		var data = this.feed.toJSON()

		// Is this feed available? (are the feed's events enabled for this app?)
		// strings to int!
		data.eventIds = data.eventIds.map(function(item) {
			return parseInt(item, 10)
		})

		// Which of this feed's events are enabled in this app's disaster/event settings
		data.available = _.intersection(data.eventIds, this.enabledEvents).length !== 0
		// Has this feed been enabled/saved for this app?
		data.enabled = this.model != null

		return data
	},

	configureEvents: function() {
		if (this.views.configureEvents) {
			this.views.configureEvents.destroy()
		}

		this.views.configureEvents = new FeedEventConfigureView({
			feed: this.feed,
			enabledEvents: this.enabledEvents,
			feedEvents: this.feedEvents,
			model: this.model
		})

		$('body').append(this.views.configureEvents.render().el)
		this.views.configureEvents.$el.modal({backdrop: 'static'})
	},

	deleteFeed: function() {
		this.model.requestLock(function() {
			swal({
				title: $.t('hazards.feeds.delete.confirm'),
				text: $.t('hazards.feeds.delete.events'),
				type: "warning",
				showCancelButton: true,
				confirmButtonText: $.t('hazards.feeds.delete.yes'),
				closeOnConfirm: true
			}, function(didConfirm) {
				if (didConfirm) {
					this.model.destroy()
					this.model = null
					this.render()
				}
			}.bind(this))
		}.bind(this))
	}
})
