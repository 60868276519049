var BugsPaginatedListView = require('./bugs-paginated-list-view')

module.exports = Backbone.View.extend({
	template: require('./bugs-view-template'),

	initialize: function() {
		this.views = {}
	},

	afterRender: function() {
		this.views.open = new BugsPaginatedListView({fetchData: {status: '1'}})
		this.views.pending = new BugsPaginatedListView({fetchData: {status: '4,6'}})
		this.views.closed = new BugsPaginatedListView({fetchData: {status: '2,3,5,7,8'}})

		this.$('.open-view').append(this.views.open.render().el)
		this.$('.pending-view').append(this.views.pending.render().el)
		this.$('.closed-view').append(this.views.closed.render().el)
	},

	ready: function() {
		App.stopLoad()
	}
})
