var ACL = require('../acl')

/**
 * Exports {@link FakeACL}.
 * @module
 */
module.exports = ACL.extend(/** @lends FakeACL.prototype */{
	/**
	 * @constructs FakeACL
	 * @extends ACL
	 * @override
	 * @classdesc Provides a fake {@link ACL} implementation for testing.
	 *     Allows permission values to be set directly by string, without the
	 *     use of a full ACL JSON response.
	 */
	initialize: function() {
		ACL.prototype.initialize.apply(this, arguments)

		/** @private {Object.<string, string>} */
		this.permissions_ = {}
	},

	/**
	 * Sets the value of the specified permission.
	 * @param {string} value Permission value to set, e.g. 'Read', 'Write.
	 * @param {string} section The permission section to set.
	 * @param {string} [item=''] The section item to set.
	 */
	setPermission: function(value, section, item) {
		item = item || ''

		var key = section + '.' + item

		this.permissions_[key] = value
	},

	/**
	 * Clears all set permissions.
	 */
	clear: function() {
		this.permissions_ = {}
	},

	/** @override */
	getStructure: function() {
		return {}
	},

	/** @override */
	getPermission: function(section, item) {
		item = item || ''

		var key = section + '.' + item

		return this.permissions_[key] || ''
	}
})
