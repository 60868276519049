<td>
	<a href="{{original.src}}" target="_blank"><img src="{{thumbnail.src}}"></a>
</td>
<td>{{description}}</td>
<td class="center"><a href="http://maps.google.com/?q={{marker.coordinates.[1]}},{{marker.coordinates.[0]}}" target="_blank">{{t "common.view"}}</a></td>
<td class="center">{{statusText}}</td>
<td class="tools">
	<i class="approve icon-thumbs-up" title="{{t "push.approve"}}"></i>
	<i class="reject icon-ban-circle" title="{{t "mediaLibrary.delete"}}"></i>
</td>
