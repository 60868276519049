var ManageAppsSectionView = require('./manage-apps-section-view')

module.exports = ManageAppsSectionView.extend({
	className: 'manage-apps',
	template: require('./manage-apps-view-template'),

	getPageTitle: function() {
		return $.t('manageApps.title')
	},

	initialize: function(options) {
		this.collection = options.appList
	},

	getRenderData: function() {
		var apps = this.collection.toJSON()
		apps.forEach(function(app) {
			var society = App.societiesList.get(app.societyId)

			if (society) {
				app.society = society.get('description')
			}
		})

		apps.sort(function(a, b) {
			if (a.society && b.society) {
				return a.society.localeCompare(b.society)
			}
			return 0
		})

		return {
			apps: apps
		}
	},

	afterRender: function() {
		App.stopLoad()
	}
})
