var StormView = require('lib/storm-view')

/**
 * Exports {@link ManageAppsSectionView}.
 * @module
 */
module.exports = StormView.extend(/** @lends ManageAppsSectionView.prototype */{
	/**
	 * @constructs ManageAppsSectionView
	 * @extends StormView
	 * @override
	 */
	initialize: function() {
	},

	/** @override */
	getSectionTitle: function() {
		return $.t('manageApps.title')
	}
})
