var Push                 = require('./push'),
	PageList             = require('editor/page-list/page-list'),
	Maps                 = require('lib/maps'),
	AppModel             = require('editor/app'),
	User                 = require('users/user'),
	DashboardSectionView = require('dashboard/dashboard-section-view')

module.exports = DashboardSectionView.extend({
	template: require('./push-details-view-template'),

	events: {
		'click .approve-button': 'approve',
		'click .reject-button': 'reject'
	},

	initialize: function(options) {
		App.startLoad()

		this.readyCount = 0
		this.totalReadyCount = 1

		this.appId = options.appId

		/** @private {new google.maps.Map} */
		this.map_ = null
		/** @private {google.maps.Polygon} */
		this.overlay_ = null

		this.model = new Push({id: options.id})
		this.model.once('sync', this.pushFetched, this)
		this.model.fetch()
	},

	getPageTitle: function() {
		return $.t('push.pushDetails')
	},

	getRenderData: function() {
		var data = this.model.toJSON()

		if (data.payload.url) {
			var nativePageMatch = data.payload.url.match(/^app:\/\/native\/pages\/(.+)$/),
				page

			if (nativePageMatch !== null) {
				page = this.pageList.findWhere({name: nativePageMatch[1]})
			} else {
				var id = App.getIdFromCacheUrl(data.payload.url)

				page = this.pageList.get(id)
			}

			if (page) {
				data.page = page.toJSON()
			}
		}

		if (this.app) {
			data.app = this.app.toJSON()
		}

		if (this.user) {
			data.user = this.user.toJSON()
		}

		data.appId = this.appId

		return data
	},

	afterRender: function() {
		// Initialise Google maps preview.
		Maps.init(this.initGoogleMap_.bind(this))

		if (this.model.get('category') !== 'region') {
			this.$('.map-preview-group').addClass('hidden')
		}

		var status         = this.model.get('status'),
			pushPermission = App.acl.getPermission('Push')

		if (status > 1 || pushPermission !== 'Approve') {
			this.$('.push-tools').remove()
		}
	},

	pushFetched: function() {
		// Fetch app.
		var appId = this.model.get('appId')
		this.app = App.appList.get(appId)

		if (!this.app) {
			this.totalReadyCount++
			this.app = new AppModel({id: appId})
			this.app.once('sync', this.ready, this)
		}

		// Fetch user.
		var userId = this.model.get('userId')
		this.user = new User({id: userId})
		this.user.once('sync', this.ready, this)
		this.user.fetch()

		// Fetch page being linked to.
		var url = this.model.get('payload').url

		if (url) {
			this.totalReadyCount++

			this.model.fetch()
			this.pageList = new PageList(null, {appId: this.appId})
			this.pageList.once('sync', this.ready, this)
			this.pageList.fetch()
		}
	},

	ready: function() {
		if (++this.readyCount >= this.totalReadyCount) {
			this.render()
			App.stopLoad()
		}
	},

	approve: function() {
		swal({
			title: $.t('push.sending'),
			text: $.t('common.pleaseWait'),
			type: 'info',
			closeOnConfirm: false
		}, function() {
		})

		this.model.once('sync', this.approvalComplete, this)
		this.model.approve()
	},

	reject: function() {
		App.startLoad()
		this.model.once('sync', this.goBack, this)
		this.model.reject()
	},

	approvalComplete: function() {
		this.goBack()

		swal({
			title: $.t('push.sent'),
			type: 'success'
		})
	},

	goBack: function() {
		App.router.navigate('/apps/' + this.appId + '/push', {trigger: true})
	},

	/**
	 * Initialises the region preview Google map.
	 * @private
	 */
	initGoogleMap_: function() {
		this.map_ = new google.maps.Map(this.$('.push-preview-map')[0], {
			disableDefaultUI: true,
			center: new google.maps.LatLng(0, 0),
			zoom: 5
		})

		this.renderRegionPreview_()
	},

	/**
	 * Draws polygons on map preview and fits bounds.
	 * @private
	 */
	renderRegionPreview_: function() {
		// Clear previous polygons.
		if (this.overlay_) {
			this.overlay_.setMap(null)
		}

		if (!this.model.get('region')) {
			return
		}

		// Draw new polygon
		var bounds = new google.maps.LatLngBounds(),
			region = this.model.get('region')

		var latLngs = region.coordinates[0].map(function(coord) {
			var latLng = new google.maps.LatLng(coord[1], coord[0])

			bounds.extend(latLng)
			return latLng
		})

		var map = this.map_

		// Construct the polygon.
		this.overlay_ = new google.maps.Polygon({
			paths: latLngs,
			strokeColor: '#F93A2F',
			strokeOpacity: 1,
			strokeWeight: 2,
			fillColor: '#F93A2F',
			fillOpacity: 0.28,
			map: map
		})

		map.fitBounds(bounds)
	}
})
