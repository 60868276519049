{
	"error": {
		"noPermission": "Bạn không được phép thực hiện hành động đó.",
		"noAccess": "Bạn không có quyền truy cập vào hệ thống này. Vui lòng thảo luận với quản trị viên của bạn.",
		"noAccessJoin": "Bạn hiện không có quyền truy cập vào hệ thống này. Bạn có muốn tham gia?",
		"saveFailed": "Không lưu được. Vui lòng khởi động lại trang này.",
		"connect": "Không thể kết nối với máy chủ API.",
		"noApps": "Bạn không tiếp cận với ứng dụng",
		"rootPage": "Không thể tìm thấy trang gốc.",
		"generic": "Lỗi đã xảy ra.",
		"exitWarning": "Dữ liệu ứng dụng đang được lưu. Nếu bạn thoát khỏi trang này bây giờ, các dữ liệu có thể bị mất.",
		"allFields": "Hãy điền vào tất cả các miền.",
		"cantPaste": "Loại đối tượng này không thể dán ở đây.",
		"lockedBy": "Nội dung này đang tạm thời bị khóa bởi",
		"oops": "Rất tiếc!"
	},
	"common": {
		"save": "Lưu",
		"submit": "Gửi",
		"cancel": "Hủy",
		"next": "Tiếp",
		"finish": "Kết thúc",
		"close": "Đóng",
		"back": "Lưng",
		"preview": "Xem thử",
		"publish": "Xuất bản",
		"upload": "Tải lên",
		"done": "Hoàn thành",
		"tag": "Thẻ",
		"page": "Trang",
		"view": "Xem",
		"email": "Địa chỉ email",
		"username": "Người dùng",
		"add": "Thêm",
		"edit": "Chỉnh sửa",
		"tools": "Công cụ",
		"description": "Mô tả",
		"position": "Định vị",
		"warning": "CẢNH BÁO",
		"settings": "Cài đặt",
		"clickToBrowseMap": "Chọn để tra cứu bản đồ",
		"closeThisMessage": "Đóng thông điệp",
		"pleaseWait": "xin vui lòng chờ",
		"pageTags": {
			"learn": "Học tập",
			"prepare": "Chuẩn bị",
			"emergency": "Khẩn cấp",
			"tests": "Kiểm tra",
			"info": "Thông tin",
			"other": "Khác"
		},
		"linkTypes": {
			"external": "Ngoài ứng dụng",
			"internal": "Nội bộ",
			"native": "Bản địa",
			"uri": "URI",
			"timer": "Tính giờ",
			"call": "Gọi",
			"sms": "SMS",
			"share": "Chia sẻ",
			"emergency": "Khẩn cấp",
			"appLink": "Trong ứng dụng",
			"email": "Email"
		},
		"linkDescriptions": {
			"external": "Đường dẫn đến một trang web ngoài. Sẽ mở trong giao diện của ứng dụng.",
			"internal": "Đường dẫn đến một trang khác trong ứng dụng.",
			"native": "Đường dẫn đến một tính năng gốc trong ứng dụng.",
			"uri": "Đường dẫn đến một trang web ngoài ứng dụng. Người dùng sẽ thoát khỏi ứng dụng.",
			"timer": "Đường dẫn khởi động đếm ngược trên thiết bị.",
			"call": "Đường dẫn đến số điện thoại của người dùng.",
			"sms": "Đường dẫn để gửi tin nhắn đến thiết bị của người dùng",
			"share": "Đường dẫn để chia sẻ ứng dụng qua hộp thoại 'chia sẻ' trên thiết bị.",
			"emergency": "Đường dẫn để gọi số khẩn cấp đã lưu trong máy của người dùng.",
			"appLink": "Đường dẫn đến một trang ở một ứng dụng khác.",
			"email": "Một liên kết để gửi email đến địa chỉ cụ thể."
		},
		"filter": "Lọc",
		"reject": "Từ chối",
		"approve": "Phê duyệt",
		"language": "Ngôn ngữ",
		"sections": "Phần",
		"approved": "Tán thành",
		"longLoad": "Truy xuất một lượng lớn dữ liệu, quá trình này có thể mất đến một phút."
	},
	"login": {
		"inputs": {
			"password": "Mật khẩu"
		},
		"error": {
			"title": "Oopsie!",
			"body": "Tên người dùng và mât khẩu không chính xác.",
			"emailNotFound": "Không tìm thấy địa chỉ email.",
			"linkExpired": "Link để cài dặtd lại mật khẩu đã hết hạn."
		},
		"forgot": {
			"title": "Chúng ta thỉnh thoảng vẫn hay quên!",
			"body": "Nhập địa chỉ email vào và liên kết tới tài khoản của bạn."
		},
		"success": {
			"title": "Thành công!",
			"body": "Bạn sẽ nhận được một email hướng dẫn cách thay đổi mật khẩu.",
			"loginBody": "Bạn đã đăng nhập thành công, nhưng không có nơi nào để đi! Bạn đang ở auth.3sidedcube.com. Vui lòng truy cập CMS Storm của tổ chức của bạn."
		},
		"links": {
			"forgot": "Tôi quên...",
			"return": "Quay lại trang đăng nhập"
		},
		"reset": {
			"newPassword": "Mật khẩu mới",
			"confirm": "xác nhận",
			"submit": "Gửi đi",
			"success": "Mật khẩu của bạn đã được thiết lập, bây giờ bạn có thể đóng cửa sổ này."
		},
		"title": "Đăng nhập"
	},
	"nav": {
		"navigate": "Di chuyển",
		"logout": "Thoát",
		"items": {
			"appSetup": {
				"title": "Cài đặt ứng dụng",
				"description": "Xác định tên ứng dụng, biểu tượng, địa phương hóa và trong kho ứng dụng"
			},
			"content": {
				"title": "Nội dung",
				"description": "Điều chỉnh nội dung bên trong ứng dụng"
			},
			"publish": {
				"title": "Nhập",
				"description": "Nhập các thay đổi vào định dạng chạy thử hoặc định dạng chuẩn"
			},
			"stats": {
				"title": "Trạng thái",
				"description": "Xem trạng thái ứng dụng"
			},
			"userAgreement": {
				"title": "Thỏa thuận người sử dụng",
				"description": "Xem và ký Thỏa thuận Chương trình Ứng dụng Phổ quát"
			},
			"users": {
				"title": "Người dùng",
				"description": "Quản trị dữ liệu tất cả người dùng"
			},
			"stories": {
				"title": "Câu chuyện",
				"description": "Quản lý các câu chuyện"
			},
			"levels": {
				"title": "Các cấp độ",
				"description": "Quản lý tiến độ ứng dụng"
			},
			"badges": {
				"title": "Huy hiệu",
				"description": "Quản lý các huy hiệu thưởng cho người hoàn thành các hoạt động trong ứng dụng."
			},
			"push": {
				"title": "Các thông báo chủ động",
				"description": "Quản lý và gửi thông báo chủ động đến người dùng"
			},
			"sprint": {
				"title": "Bảng trạng thái ",
				"description": "Xem trạng thái xây dựng cấp tốc"
			},
			"bugs": {
				"title": "Báo lỗi",
				"description": "Báo các vấn đề tới người biên tập hoặc xây dựng dữ liệu."
			},
			"gdpcConsole": {
				"title": "Thông tin về hội quốc gia",
				"description": "Xem các thông tin về Hội quốc gia"
			},
			"hazards": {
				"title": "Hiểm họa",
				"description": "​Ưu tiên, quản lý thiên tai và cảnh báo"
			},
			"decisionTree": {
				"title": "Quyết định cây",
				"description": "Xem và chỉnh sửa các cây quyết định cho các ứng dụng"
			},
			"admin": {
				"title": "Quản trị",
				"description": "Xem số liệu thống kê, chỉnh sửa người dùng và thông tin chi tiết tổ chức"
			}
		}
	},
	"localisations": {
		"title": "Các mục bản địa hóa",
		"string": "Chuỗi ký tự",
		"key": "Khóa",
		"uploadCsv": "Tải lên CSV",
		"all": "<b>Tất cả</b> các mục bản địa hóa",
		"addNew": "Thêm các mục bản địa hóa mới",
		"addMultiple": "Thêm nhiều mục bản địa hóa",
		"overwriteExisting": "Ghi đè các mục bản địa hóa hiện có",
		"addMultipleInstructions": "Thêm hoặc cập nhật các mục bản địa hóa theo lô. Sử dụng CVS trong định dạng",
		"copyFromAnotherApp": "Sao chép từ ứng dụng khác",
		"overwriteWarning": "Bản dịch có sẵn bất kỳ mà bạn đã thực hiện sẽ bị ghi đè.",
		"errors": {
			"csvTooManyColumns": "Quá nhiều cột trong đầu trang.",
			"csvInvalidHeader": "Đầu trang không hợp lệ.",
			"expected": "Kỳ vọng",
			"csvInvalidKeyOnLine": "Khóa bản địa hóa không hợp lệ trong dòng",
			"csvInvalidColumnCountOnLine": "Số lượng cột trong dòng không hợp lệ",
			"invalidKey": "Khóa bản địa hóa không hợp lệ. Các khóa phải bắt đầu bằng dấu gạch dưới và chỉ chứa các chữ cái viết hoa và dấu gạch chân. Các khóa phải chứa ít nhất hai phần (các bit được định giới bằng dấu gạch chân).",
			"got": "có"
		},
		"confirmCopy": "Bạn có chắc bạn muốn sao chép các mục bản địa hóa từ ứng dụng được chọn?"
	},
	"appSetup": {
		"title": "Cài đặt ứng dụng",
		"appName": "Tên ứng dụng",
		"startPage": "Trang khởi động",
		"languages": "Các ngôn ngữ ứng dụng",
		"localisation": "Địa phương hóa ứng dụng",
		"publishable": "Có thể chuyển tới",
		"dev": "Đang thử",
		"live": "Đang chạy",
		"key": "Chính",
		"value": "Trị số",
		"properties": "đặc sắc",
		"publishableLanguages": "Ngôn ngữ được xuất bản",
		"addMoreLanguages": "Chỉ có ngôn ngữ giao cho tổ chức này có sẵn ở đây. Chỉ định hơn trong phần quản trị.",
		"translationError": "Không thể tải tập tin dịch. Vui lòng kiểm tra các ứng dụng và các tổ chức có kích hoạt ít nhất một ngôn ngữ và được xuất bản.",
		"expandAll": "Bung tất cả",
		"addCsv": "Thêm CSV",
		"bundleIdentifiers": "SDK Bundle Identifiers",
		"translation": "Bản dịch",
		"type": "Kiểu"
	},
	"metadata": {
		"title": "Siêu dữ liệu",
		"noBuildPlatforms": "Không có miền để xây dựng ứng dụng này.",
		"appStore": "Chi tiết kho ứng dụng",
		"name": "Tên ứng dụng",
		"description": "Mô tả",
		"keywords": "Các từ khóa",
		"primaryCategory": "Nhóm chính",
		"secondaryCategory": "Loại thứ cấp",
		"copyright": "Bản quyền",
		"website": "URL thị",
		"supportUrl": "Hỗ trợ URL",
		"privacyPolicyUrl": "Chính sách quan trọng URL",
		"phoneNumber": "Số điện thoại",
		"launchDate": "Ngày đưa ra giới thiệu",
		"appIcon": "Biểu tượng ứng dụng",
		"iconSize": "Kích thước biểu tượng gốc nên là 1024x1024.",
		"addScreenshot": "Thêm Ảnh chụp màn hình",
		"appIconHint": "Phải là 1024 x 1024 không trong suốt. Phải ở định dạng JPG hoặc PNG, với độ phân giải tối thiểu ít nhất là 72 DPI, và trong khoảng màu RGB. Nó không được chứa các lớp hoặc các góc tròn",
		"appPreviewVideo": "Video xem trước ứng dụng",
		"appPreviewVideoHint": "Video xem trước ứng dụng phải thuộc định dạng M4V, MP4 hoặc MOV và không thể vượt quá 500 MB",
		"appStoreTitle": "Tiêu đề của App Store",
		"delete": "Xóa",
		"emailHint": "Cung cấp địa chỉ email nếu  App Store hoặc người dùng cần giữ liên lạc",
		"featureGraphic": "Đồ họa tính năng",
		"featureGraphicHint": "Google Play Store yêu cầu các ứng dụng phải có đồ họa tính năng hoặc 'biểu ngữ' - các kích thước phải là rộng 1024  x cao 500. Đây có thể là một ảnh riêng rẽ, hoặc sẽ là ảnh  nền cho video quảng bá (nếu được cung cấp)",
		"languages": "Ngôn ngữ",
		"nameOnDevice": "Tên ứng dụng trên thiết bị",
		"phoneNumberHint": "Phải bao gồm mã quay số quốc gia",
		"platformSpecific": "Siêu dữ liệu theo nền tảng cụ thể",
		"price": "Mức giá",
		"rating": "Đánh giá",
		"screenshotsHint": "Bao gồm ảnh chụp màn hình cho mỗi kích thước màn hình. Ảnh chụp màn hình phải ở định dạng JPG hoặc PNG, và trong khoảng màu RGB",
		"shared": "Siêu dữ liệu nền tảng chéo",
		"shortAppDescription": "Mô tả ứng dụng ngắn",
		"supportUrlHint": "Một URL với thông tin hỗ trợ cho ứng dụng của bạn. URL này sẽ được hiển thị trên App Store",
		"websiteHint": "Một URL chứa thông tin quảng bá cho ứng dụng của bạn. URL này sẽ được hiển thị trên App Store",
		"features": "Tính năng, đặc điểm"
	},
	"plugins": {
		"title": "Plugins",
		"availablePlugins": "Plugins có sẵn"
	},
	"editor": {
		"title": "Biên tập",
		"pageName": "Tên trang",
		"pageType": "Loại trang",
		"pageStyle": "Loại trang",
		"pageTag": "Thẻ trang",
		"addPage": "Tạo trang mới",
		"saving": "Đang lưu...",
		"lastSaved": "Lần lưu cuối:",
		"never": "chưa bao giờ",
		"newFromTemplate": "Tạo mới từ khuôn mẫu",
		"pageList": {
			"title": "Trang",
			"filter": "Các trang lọc",
			"allPages": "Tất cả các trang"
		},
		"addObject": "Thêm mục mới tại đây",
		"addQuiz": "Thêm câu đố",
		"addApp": "Thêm ứng dụng",
		"addLink": "Thêm đường link",
		"confirmRelock": "Mục này đã bị khóa. Bạn có muốn khóa lại không? Lệnh khóa bạn sử dụng lần trước sẽ bị hủy bỏ.",
		"confirmRequestNewLock": "Lệnh khóa đã bị hủy. Bạn có muốn đặt lệnh khóa mới hay không?",
		"savesQueued": "tiết kiệm xếp hàng đợi",
		"inspector": {
			"title": "Chỉnh sửa thuộc tính",
			"addAnother": "Thêm mới",
			"addBulletItem": "Thêm một đề mục",
			"rootPageDelete": "Đây là trang gốc của ứng dụng. Trang này không thể xóa.",
			"areYouSure": "Bạn có chắc chắn?",
			"confirmDelete": "Bạn có chắc chắn muốn xóa thành tố này?",
			"confirmPageDelete": "Xác nhận xóa trang",
			"confirmPageDeleteWarning": "CẢNH BÁO: Bạn đang xóa một trang. Tất cả nội dung trang sẽ bị dỡ bỏ. Bạn có chắc chắn muốn tiếp tục?",
			"moveUp": "Di chuyển lên",
			"moveDown": "Di chuyển xuống",
			"copy": "Sao chép",
			"paste": "Dán",
			"copySuccess": "Đã sao chép đối tượng vào clipboard",
			"changeType": "Đổi loại ",
			"newType": "Nhóm mới",
			"properties": {
				"annotation": {
					"title": "Chú giải",
					"description": "Bước số..."
				},
				"title": {
					"title": "Tiêu đề",
					"description": "Danh sách các tiêu đề"
				},
				"name": {
					"title": "Tên",
					"description": "Tên trong nội bộ hệ thống"
				},
				"hint": {
					"title": "Gợi ý",
					"description": "Chỉ dẫn cho người dùng"
				},
				"image": {
					"title": "Ảnh",
					"chooseButton": "Chọn ảnh",
					"removeButton": "Gỡ ảnh"
				},
				"video": {
					"title": "Video",
					"chooseButton": "Chọn video",
					"attributes": "Các thuộc tính",
					"loop": "Cuộn",
					"addLanguage": "Thêm ngôn ngữ"
				},
				"link": {
					"title": "Link",
					"type": "Loại hình",
					"destination": "Đích đến",
					"body": "Chữ trong phần nội dung",
					"duration": "Quãng hẹn giờ (phút)",
					"recipients": "Người nhận (mỗi người một dòng)",
					"testLink": "Thử link",
					"linkTitle": "Link Title",
					"linkDestination": "Điểm đến liên kết"
				},
				"images": {
					"add": "Thêm ảnh"
				},
				"animation": {
					"chooseButton": "Chọn chuyển động ",
					"delay": "Khung trễ (giây)"
				},
				"embeddedLinks": {
					"title": "Các liên kết nhúng",
					"add": "Thêm link"
				},
				"embeddedMedia": {
					"title": "Phương tiện nhúng"
				},
				"header": {
					"title": "Ghi chú đầu trang",
					"description": "Hiển thị phía trên nhóm đối tượng"
				},
				"footer": {
					"title": "Ghi chú cuối trang",
					"description": "Hiện thị phía dưới nhóm đối tượng"
				},
				"badge": {
					"title": "Huy hiệu",
					"icon": "Biểu tượng",
					"completion": {
						"title": "Nội dung hoàn thành",
						"description": "Hiển thị cho người dùng khi hoàn thành gói câu hỏi"
					},
					"how": {
						"title": "Làm thế nào để kiếm được",
						"description": "Văn bản mô tả làm thế nào để đạt được huy hiệu này"
					}
				},
				"units": {
					"title": "Các đơn vị",
					"description": "giây/ mét v.v."
				},
				"range": {
					"title": "Phạm vi",
					"start": "Bắt đầu",
					"length": "Độ dài",
					"increment": "Tăng"
				},
				"completionText": {
					"title": "Nội dung hoàn thành",
					"description": "Hiển thị cho người dùng khi họ trả lời đúng"
				},
				"failureText": {
					"title": "Thất bại",
					"description": "Hiển thị cho người dùng khi trả lời sai"
				},
				"winMessage": {
					"title": "Thông điệp chiến thắng",
					"description": "Hiển thị cho người dùng đã vượt qua bài kiểm tra"
				},
				"loseMessage": {
					"title": "Mất tin nhắn",
					"description": "Hiển thị cho người dùng không vượt qua được bài kiểm tra"
				},
				"shareMessage": {
					"title": "Chia sẻ thông điệp",
					"description": "Dòng chữ đi kèm khi chia sẻ huy hiệu trên các mạng xã hội"
				},
				"volatile": "Dễ biến đổi (trạng thái không bên vững)",
				"textContent": "Nội dung",
				"quizzes": "Các nhóm câu đố",
				"answer": "Trả lời",
				"selectionLimit": "Giới hạn lựa chọn",
				"correctAnswer": "Đáp án đúng",
				"initialPosition": "Vị trí ban đầu",
				"relatedLinks": "Các link liên quan",
				"winRelatedLinks": "Các đường link liên quan",
				"loseRelatedLinks": "Mất các link liên quan",
				"caption": "Chú thích",
				"delay": "Trễ (s)",
				"placeholderImage": "Hình ảnh giữ chỗ",
				"button": "Nút",
				"standard": "Tiêu chuẩn",
				"columns": "Cột",
				"quiz": "Câu đố",
				"overlay": "Nội dung bề mặt",
				"progressMessage": "Tin nhắn tiến trình",
				"gridType": "Loại ô kẻ",
				"confirmGridTypeChange": "Bạn có chắc chắn muốn thay đổi loại khung? Điều này sẽ thay đổi tất cả các mục có khung.",
				"winThreshold": {
					"title": "Ngưỡng giành chiến thắng (%)",
					"description": "Phần trăm câu trả lời đúng cần thiết để vượt qua bài kiểm tra"
				}
			},
			"swapItem": "Hàng Swap",
			"copiedItem": "mục sao chép",
			"confirmApproval": "Bạn có chắc chắn muốn phê duyệt điều này không?"
		},
		"objectPreviews": {
			"DescriptionListItem": {
				"title": "Mục này có tiêu đề và mô tả",
				"description": "Sử dụng mục này khi bạn có nhiều điều cần nói"
			},
			"StandardListItem": {
				"title": "Sử dụng mục này hầu hêt thời gian",
				"description": "Mục này có tiêu đề, mô tả và biểu tượng. Bạn có thể để các bit trong - như biểu tượng này."
			},
			"ToggleableListItem": {
				"title": "Sử dụng mục này khi bạn có điều cần ẩn đi",
				"description": "Văn bản này có thể được người dùng hiển thị và ẩn đi. Sẽ tốt nếu bạn có quá nhiều chi tiết - có thể là mục Hỏi Đáp"
			},
			"OrderedListItem": {
				"title": "Danh sách sắp xếp theo thứ tự",
				"description": "Sử dụng mục này khi bạn muốn nhiều mục trong một danh sách đánh số"
			},
			"UnorderedListItem": {
				"title": "Danh sách không sắp xếp theo thứ tự",
				"description": "Sử dụng mục này khi bạn muốn nhiều mục trong một danh sách nhưng chỉ bằng các gạch đầu dòng"
			},
			"HeaderListItem": {
				"title": "Sử dụng mục này ở phần trên cùng của trang",
				"description": "Nó có thể có tiêu đề và mô tả dài hơn"
			},
			"SpotlightListItem": {
				"first": "Xoay",
				"second": "Tiêu điểm"
			},
			"TitleListItem": "Sử dụng mục này cho dòng đơn của văn bản",
			"CheckableListItem": "Sử dụng mục này cho danh sách các mục cần kiểm tra",
			"TextListItem": "Sử dụng mục này cho văn bản đầu vào, thường ở trên cùng của trang",
			"LogoListItem": "Sử dụng mục này khi mục nào đó như biểu trưng, nơi bạn muốn một bức ảnh, chú thích hoặc liên kết",
			"ButtonListItem": "Sử dụng mục này nếu bạn muốn có một nút bấm",
			"ChunkyListItemView": {
				"title": "Mục này giống như mục danh sách chuẩn - với nhiều vùng đệm hơn",
				"description": "Nó có tiêu đề, mô tả và biểu tượng. Bạn có thể để các bit trông - như biểu tượng."
			},
			"ListItemView": "Mục này chỉ có tiêu đề"
		}
	},
	"mediaLibrary": {
		"title": "Thư viện truyền thông",
		"refine": "Sàng lọc thư viện",
		"filterByTags": "Lọc bằng thẻ",
		"type": "Loại phương tiện",
		"image": "Hình ảnh",
		"icon": "Biểu tượng",
		"video": "Video",
		"animation": "Chuyển động",
		"section": "Phần",
		"society": "Hội",
		"general": "Tổng quan",
		"additionalTags": "Các thẻ phụ",
		"commas": "Phân tách bằng dấu phẩy",
		"clearFilters": "Bỏ lọc",
		"editMedia": "Điều chỉnh phương tiện",
		"delete": "Xóa",
		"dragToReorder": "Kéo để thay đổi thứ tự",
		"confirmTagRemoval": "Bạn chắc chắn muốn bỏ thẻ này?",
		"upload": {
			"title": "Tải phương tiện",
			"tags": "Thẻ",
			"newTag": "Thẻ mới",
			"agreementText": "Tất cả nội dung tải lên hệ thống phải tuân theo các điều khoảncủa",
			"filesizeError": "Không thể tải lên. Tệp quá lớn. Kích thước tối đa của tệp là",
			"formatError": "Không thể tải lên. Định dạng tệp không tương thích. Các định dạng ảnh dùng được: .jpeg, .png, .gif CÁc định dạng video: .mp4, .m4v",
			"genericError": "Không thể tải lên. Lỗi số:",
			"selectType": "Chọn loại ảnh mà bạn đang muốn tải lên",
			"imageDesc": "Ảnh <b>640(w)</b> nhân <b>320(h)</b> pixels là tốt nhất cho nhóm ảnh chèn trực tiếp, ảnh trình chiếu, ảnh trong câu đố và video câu đố. Ảnh trong câu đó nên có kích thước là <b>640(w)</b> nhân <b>640(h)</b> pixels.",
			"invalidTag": "Thẻ vô hiệu. Các thẻ không được có dấu hiệu (*)",
			"instructions": "Nhấp vào đây hoặc kéo tập tin để bắt đầu",
			"errors": {
				"tooManyFiles": "Vui lòng chọn tập tin đơn"
			},
			"square": "Hình vuông",
			"cropType": "Kiểu xén",
			"noCrop": "Không có cây trồng",
			"squareDesc": "Sử dụng kiểu xén này cho biểu tượng và hình ảnh câu đố. Hình ảnh câu đố nên <b>là</b> 640 <b>(w)</b> <b>x</b> 640 <b>(h)</b> pixels.",
			"spotlightDesc": "Sử dụng loại cây này cho đèn sân khấu, cạnh đến cạnh, slideshow hình ảnh và video. Hình ảnh phải <b>640 (w)</b> <b>x</b> 320 <b>(h)</b> pixel trong kích thước.",
			"headerDesc": "Sử dụng loại cây này cho hình ảnh tiêu đề lớn mà xuất hiện ở phía trên cùng của trang. Hình ảnh phải <b>640 (w)</b> của <b>568 (h)</b> pixel trong kích thước.",
			"requiresTransparency": "Cần có hình nền trong suốt",
			"gdpcHeaderDesc": "Sử dụng loại cây này cho hình ảnh tiêu đề lớn trong các nguy hại ứng dụng. Hình ảnh phải <b>640 (w)</b> của <b>568 (h)</b> pixel trong kích thước.",
			"gdpcSpotlightDesc": "Sử dụng loại cây này cho tiêu đề trong ứng dụng First Aid, đèn sân khấu, cạnh đến cạnh, slideshow hình ảnh và video. Hình ảnh phải <b>640 (w)</b> <b>x</b> 320 <b>(h)</b> pixel trong kích thước."
		},
		"chooseExisting": "Chọn mục có sẵn",
		"loadMore": "Tải thêm"
	},
	"viewPicker": {
		"title": "Đối tượng",
		"filter": "Lọc đối tượng",
		"basicObjects": "Các đối tượng cơ bản",
		"multiLineObjects": "Đối tượng nhiều dòng",
		"mediaObjects": "Đối tượng phương tiện",
		"interactiveObjects": "Các đối tượng tương tác",
		"text": "Nội dung",
		"subText": "Văn bản phụ",
		"leadInText": "Dẫn mục",
		"hiddenText": "Văn bản ẩn",
		"spotlight": "Nổi bật",
		"TextListItem": "Văn bản sát  nhau giữa các nhóm",
		"TitleListItem": "Một dòng văn bản đơn lẻ",
		"StandardListItem": "Tiêu đề, liên kết và biểu tượng",
		"DescriptionListItem": "Tiêu đề và mô tả bằng nhiều dòng",
		"ToggleableListItem": "Tiêu đề có thể nhấp vào sẽ hiển thị nội dung văn bản dài hơn",
		"OrderedListItem": "Tiêu đề và nội dung văn bản da dòng, với một chú thích hoặc số bước",
		"UnorderedListItem": "Một mục danh sách chứa gạch đầu dòng với tiêu đề và mô tả",
		"CheckableListItem": "Một tiêu đề và hộp chọn. Trạng thái có thể tiếp tục giữa những người dùng ứng dụng",
		"ButtonListItem": "Tiêu đề và nút bấm",
		"ImageListItem": "Một ảnh đơn, nhập trực tiếp",
		"HeaderListItem": "Một hình ảnh lớn với một tiêu đề và mô tả được che phủ",
		"VideoListItem": "Một đối tượng video hỗ trợ nhiều nguồn",
		"AnimatedImageListItem": "Một loạt các hình ảnh được phát dưới dạng trình chiếu, cho hình ảnh động",
		"SpotlightImageListItem": "Một hoặc nhiều hình ảnh nổi bật, có chứa một hình ảnh, chú thích và một liên kết",
		"LogoListItem": "Được dùng để hiển thị thương hiệu công ty",
		"SliderQuizItem": "Câu hỏi lựa chọn con trượt ảnh",
		"TextQuizItem": "Câu hỏi lựa chọn văn bản",
		"ImageQuizItem": "Câu hỏi lựa chọn ảnh",
		"AreaQuizItem": "Câu hỏi lựa chọn khu vực",
		"CollectionListItem": "Các xem \"Thu thập Tất cả\" dành cho ứng dụng, huy hiệu và đường dẫn",
		"dragAndDrop": "Kéo và thả các đối tượng để thêm chúng vào trang"
	},
	"publish": {
		"title": "Nhập",
		"auditTrail": "Kiểm định",
		"noChanges": "Không thay đổi",
		"lastModified": "Thay đổi gần nhất",
		"publishToDev": "Nhập vào định dạng chạy thử",
		"publishToLive": "Nhập vào định dạng chính thức",
		"objectType": "Loại đối tượng",
		"detail": "Chi tiết",
		"details": "Chi tiết",
		"time": "Thời gian",
		"startTime": "Thời gian bắt đầu",
		"endTime": "Thời gian kết thúc",
		"user": "Người dùng",
		"selectAll": "Chọn tất cả",
		"error": "Chọn một hoặc vài trang để nhập",
		"warning1": "Bạn đã chọn các trang dưới đây để công bố đến các ứng dụng iOS và Android thông qua một bản cập nhật.",
		"warning2": "Vui lòng xem lại kỹ lưỡng tất cả các thay đổi trước khi tiếp tục và xác nhận lựa chọn của bạn ở dưới cùng của trang.",
		"comment": "Bình luận",
		"publishConfirm": "Tôi xác nhận đã xem các thay đổi và được phép nhập vào hệ thống.",
		"toApps": "Nhập vào các ứng dụng",
		"pleaseConfirm": "Chọn hộp để xác nhận đã rà soát tất cả các thay đổi",
		"publishing": "Đang nhập...",
		"progress1": "Các thay đổi bạn chọn đã được nhập.",
		"progress2": "Có thể mất vài phút. Vui lòng không thoát khỏi trang này.",
		"success": "Nhập thành công.",
		"fail": "Không thể nhập.",
		"history": "Lịch sử",
		"publishType": "Kiểu nhập",
		"test": "Thử",
		"live": "Đang chạy",
		"publishLandmark": "Xuất bản Landmark?",
		"progress3": "Có vẻ như việc này mất một lúc. Bạn có thể quay lại sau."
	},
	"publishHistory": {
		"title": "Lịch sử công bố",
		"previousPublishes": "Các lần công bố trước"
	},
	"agreement": {
		"title": "Thỏa thuận chương trình ứng dụng phổ quát",
		"hazardsTitle": "Hiệp định Chương trình phổ ứng dụng nguy hiểm",
		"details": "Chi tiết thỏa thuận",
		"status": "Trạng thái",
		"sign": "Ký",
		"signed": "Đã ký",
		"notSigned": "Chưa ký",
		"daysRemaining": "__count__ ngày nữa",
		"daysRemaining_plural": "__count__ ngày nữa",
		"signedBy": "Được ký bởi",
		"date": [
			"Ngày"
		],
		"nationalSociety": "Hội CTĐ quốc gia",
		"viewThe": "Xem ",
		"signAgreement": "Ký thỏa thuận",
		"fullName": "Họ và tên",
		"email": "địa chỉ email",
		"iAgree": "Tôi đồng ý với các điều khoản được nêu trong",
		"checkToAgree": "Đánh dấu vào ô trống để xác nhận bạn đã đọc và đồng ý với các điều khoản sử dụng."
	},
	"users": {
		"title": "Người dùng",
		"list": "Danh sách người dùng",
		"name": "Tên",
		"firstName": "Tên riêng",
		"lastName": "Họ",
		"organisation": "Tổ chức",
		"role": "Vai trò",
		"roles": "Các vai trò",
		"apps": "Ứng dụng",
		"lastActive": "Lần truy cập gần nhất",
		"success": "Thành công! Người dùng sẽ nhận được một email với các hướng dẫn để kích hoạt tài khoản của họ.",
		"complete": "Người dùng tạo thành công",
		"error": "Không thể tạo người dùng. Đảm bảo tên người dùng và email chưa được đăng ký.",
		"createUser": "Tạo người dùng",
		"usernameHelp": "Tên đăng nhập chỉ có thể chứa chữ cái, số, dấu gạch dưới, dấu gạch ngang và dấu chấm.",
		"removeUserConfirm": "Bạn có chắc bạn muốn xóa người dùng này?",
		"failedToRemove": "Không thể xóa người dùng"
	},
	"roles": {
		"title": "Vai trò người dùng",
		"list": "Danh mục các vai trò",
		"permissions": "Quyền truy cập"
	},
	"permissions": {
		"title": "Các vai trò truy cập",
		"edit": "Chỉnh sửa quyền truy cập",
		"none": "Không",
		"read": "Đọc",
		"write": "Viết",
		"delete": "Viết/Xóa"
	},
	"apps": {
		"title": "Gắn các ứng dụng",
		"subtitle": "Gắn ứng dụng với người dùng",
		"app": "Ứng dụng",
		"assignNew": "Gắn ứng dụng mới",
		"assign": "Gắn"
	},
	"stories": {
		"title": "Câu chuyện",
		"list": "Danh mục các câu chuyện",
		"created": "Đã tạo",
		"subtitle": "Tiêu đề phụ",
		"content": "Nội dung",
		"editStory": "Chỉnh sửa câu chuyện"
	},
	"badges": {
		"title": "Huy hiệu",
		"list": "Danh sách các huy hiệu",
		"editBadge": "Điều chỉnh huy hiệu"
	},
	"levels": {
		"title": "Các cấp độ",
		"list": "Danh sách các cấp độ",
		"editLevel": "Điều chỉnh cấp độ",
		"details": "Thông tin về cấp độ chơi",
		"children": "Cấp độ trẻ em"
	},
	"push": {
		"showAllApps": "Hiển thị tất cả các ứng dụng",
		"existing": "Thông báo chủ động hiện có",
		"category": "Thể loại",
		"notes": "Ghi chú",
		"type": "Loại hình",
		"numberSent": "Tổng số",
		"numberCompleted": "Hoàn thành",
		"createdBy": "Tạo bởi",
		"editPush": "Chỉnh sửa thông báo chủ động",
		"pushDetails": "Chi tiết thông báo",
		"assignments": "Công việc",
		"noPageSpecified": "Chưa chọn trang nào",
		"approve": "Duyệt",
		"reject": "Từ chối",
		"priority": "Ưu tiên",
		"message": "Thông điệp",
		"payload": "Tải trọng",
		"saveAndSend": "Lưu và gửi",
		"noNotifications": "Không có thông báo",
		"sending": "Gửi",
		"sent": "đẩy gửi",
		"categories": {
			"standard": "Tiêu chuẩn",
			"future": "Tương lai",
			"region": "Vùng",
			"custom": "Tập quán"
		},
		"types": {
			"default": "Mặc định"
		},
		"priorities": {
			"low": "Thấp",
			"high": "Cao"
		},
		"custom": {
			"area": "Khu vực mục tiêu",
			"areaHelp": "Nhấp vào biểu đồ để xác định khu vực mục tiêu cho thông báo này"
		},
		"error": {
			"length": "Tin nhắn thông báo đẩy quá dài",
			"messageEmpty": "Vui lòng nhập tin nhắn",
			"missingRegion": "Vui lòng chọn một vùng"
		},
		"messageDescription": "Tin nhắn thông báo đẩy",
		"what": "Bạn muốn gửi gì?",
		"when": "Khi nào bạn muốn gửi nó?",
		"addToQueue": "Thêm vào hàng"
	},
	"bugs": {
		"title": "Lỗi",
		"report": "Báo cáo",
		"listOpen": "Danh sách các báo cáo lỗi mở",
		"listPending": "Danh sách các báo cáo lỗi đang chờ xử lý",
		"listClosed": "Danh sách các báo cáo lỗi đóng",
		"version": "Phiên bản",
		"build": "Xây dựng",
		"device": "Thiết bị/ hệ điều hành",
		"screenshots": "Ảnh màn hình",
		"reportBug": "Báo cáo lỗi",
		"platformDetails": "Chi tiết miền thông tin",
		"issueDetails": "Chi tiết vấn đề",
		"steps": "Các bước sao chép",
		"expected": "Biểu hiện dự kiến",
		"actually": "Biểu hiện thực",
		"noReports": "Không báo cáo",
		"bugReport": "Báo cáo lỗi",
		"comments": "Bình luận",
		"noComments": "Không bình luận",
		"addComment": "Thêm bình luận",
		"loadingDetails": "Đang tải chi tiết lỗi",
		"closeIssue": "Đóng vấn đề",
		"reopenIssue": "Mở lại vấn đề",
		"loadingFiles": "Đang tải tệp...",
		"noFiles": "Không có tệp nào",
		"status": {
			"title": "Trạng thái",
			"deleted": "Đã xóa",
			"open": "Mở",
			"fixed": "Đã sửa",
			"wontFix": "Không thể sửa",
			"postponed": "Tạm dừng",
			"duplicate": "Bản sao",
			"notReproducible": "Không thể sao chép",
			"byDesign": "Tùy chọn thiết kế",
			"closed": "Đóng"
		}
	},
	"gdpcConsole": {
		"nationalSocieties": {
			"title": "Tổng quan",
			"countries": "Các quốc gia",
			"stage": "Giai đoạn",
			"averageRating": "Xếp hạng trung bình",
			"downloads": "Lượt tải về",
			"totalDownloads": "Tổng số lượt tải",
			"contact": "Liên hệ",
			"pool": "Phần",
			"goLive": "Chạy chính thức",
			"locale": "Địa điểm",
			"details": "Thông tin về hội chữ thập đỏ",
			"edit": "Điều chỉnh thông tin về hội"
		},
		"devPools": {
			"title": "Trạng thái chạy thử",
			"completed": "Hoàn thành",
			"noApps": "Không có ứng dụng nào trong phần chạy thử",
			"editPool": "Điều chỉnh phần chạy thử",
			"stages": "Các giai đoạn",
			"language": "Ngôn ngữ",
			"expected": "Dự kiến",
			"requiredBy": "Được yêu cầu bởi",
			"responsibility": "Trách nhiệm",
			"estimatedDate": "Ước tính ngày ứng dụng"
		}
	},
	"hazards": {
		"disasters": {
			"title": "Thiên tai",
			"rootPage": "Trang gốc",
			"alertSettings": "Cài đặt cảnh báo",
			"configure": "Định dạng",
			"enable": "Kích hoạt ",
			"disable": "Vô hiệu hóa",
			"saveSuccess": "Đã lưu định dạng ứng dụng.",
			"saveError": "Không thể lưu định dạng ứng dụng.",
			"pleaseSpecifyPages": "Vui lòng nêu rõ số trang cho từng loại thiên tai được chọn.",
			"whatNow": "Trang 'làm gì bây giờ'",
			"categories": {
				"tsunami": "Sóng thần",
				"hurricane": "Bão",
				"tornado": "Lốc xoáy",
				"flood": "Lũ lụt",
				"wildfire": "Cháy rừng",
				"wind": "Gió",
				"storm": "Bão",
				"earthquake": "Động đất",
				"heatwave": "Đợt nóng",
				"winter": "Mùa đông",
				"volcano": "Núi lửa",
				"nuclear": "Hạt nhân",
				"coastal": "Ven biển"
			},
			"tutorial": {
				"heading": "Các giai đoạn khác nhau nghĩa là gì?",
				"body": "Kích hoạt tức là trang sẽ xuất hiện trong phần điều chỉnh nội dung để bạn có thể xử lý.<br>- Đang chạy thử tức là trang sẽ hiện thị trong phần phát triển app.<br>- Đang chạy tức là trang sẽ xuất hiện trong kho ứng dụng đã thiết lập.."
			}
		},
		"alerts": {
			"title": "Các cảnh báo",
			"alert": "Cảnh báo",
			"expired": "Hết hạn",
			"alertActive": "Cảnh báo",
			"pin": "Đánh dấu",
			"pinned": "Đã đánh dấu",
			"effective": "Hiệu lực",
			"issuedBy": "đưa ra bởi",
			"noAlerts": "Không có cảnh báo nào",
			"show": "Hiển thị",
			"inTheLast": "Trong phần vừa rồi",
			"allAlerts": "Tất cả các cảnh báo",
			"createNewAlert": "Tạo cảnh báo mới",
			"testAlert": "Tạo bài kiểm tra cảnh báo",
			"testAlertDesc": "Cảnh báo kiểm tra này sẽ được gửi đến tất cả người dùng theo dõi một địa điểm ở Nam Cực.",
			"specifySinglePolygon": "Nhiều đa giác không được hỗ trợ cho các cảnh báo. Hãy vẽ một đa giác đơn.",
			"times": {
				"allTime": "Tất cả thời gian",
				"hour": "Giờ",
				"day": "Ngày",
				"week": "Tuần",
				"month": "Tháng"
			},
			"custom": {
				"types": {
					"announcement": "Thông báo ",
					"pin": "Trang đánh dấu",
					"announcementDesc": "Các thông báo được sử dụng để tạo cảnh báo cho các dự kiện chưa được các nhà lập trình khác đưa ra.",
					"pinDesc": "Các trang đánh dấu được dùng để đánh dấu các trang quan trọng nhạy cảm về thời gian và địa điểm ."
				},
				"errors": {
					"pleaseSelectRegion": "Chọn một vùng cho loại cảnh báo này.",
					"pleaseProvideMessage": "Hãy nhập thông điệp cho thông báo này.",
					"pleaseSelectPage": "Vui lòng chọn một trang để đánh dấu.",
					"pleaseProvideAreaDescription": "Vui lòng cung cấp mô tả khu vực cho thông báo này.",
					"pleaseProvideHeadline": "Vui lòng cung cấp dòng tiêu đề cho thông báo này.",
					"pleaseProvideEvent": "Vui lòng cung cấp sự kiện cho thông báo này.",
					"effectiveWrong": "Ngày có hiệu lực không thể sau ngày hết hạn"
				},
				"announcementPage": "Trang đang hiển thị trong mục \"làm gì bây giờ\"",
				"pinPage": "Trang cần đưa vào.",
				"message": "Thông điệp gửi kèm cảnh báo.",
				"area": "Khu vực cảnh báo",
				"areaHelp": "Cảnh báo sẽ được gửi tới tất cả người dùng đang theo dõi một địa điểm trong khu vực đã chọn.",
				"expiry": "Ngày hết hạn",
				"expiryHelp": "Ngày dỡ cảnh báo.",
				"severity": "Mức độ nghiêm trọng",
				"severityHelp": "Mức độ quan trọng của cảnh báo?",
				"alertQueued": "Cảnh báo chờ gửi.",
				"drawRegion": "Khoanh tròn khu vực cần gửi cảnh báo.",
				"notify": "Thông báo",
				"sendNotification": "Gửi thông báo đẩy cho những người sử dụng.",
				"pushes": "push",
				"onset": "Ngày bắt đầu",
				"cap_create": "MŨ LƯỠI TRAI",
				"effective": "Ngày có hiệu lực",
				"updateMap": "Cập nhật bản đồ",
				"showPolyData": "Hiển thị dữ liệu đa giác",
				"cap_send": "Tạo cảnh báo CAP",
				"polygon": "Thêm dữ liệu đa giác tùy chỉnh tại đây.",
				"onsetHelp": "Ngày nguy hiểm sẽ xảy ra.",
				"languageHelp": "Ngôn ngữ cảnh báo được viết bằng.",
				"effectiveHelp": "Ngày cảnh báo này sẽ có hiệu lực từ."
			}
		},
		"title": "Mối nguy hiểm",
		"generalNotifications": {
			"title": "Thông báo chung",
			"createNotification": "tạo thông báo",
			"customMapData": {
				"moreCoords": "Vui lòng cung cấp nhiều hơn ba tọa độ",
				"firstAndLast": "Tọa độ đầu tiên và cuối cùng phải giống nhau",
				"fisrtFoundNotLast": "Tọa độ đầu tiên được tìm thấy trong các tọa độ, chỉ tọa độ đầu tiên và cuối cùng phải giống nhau",
				"notFound": "Không tìm thấy dữ liệu Đa giác nào, vui lòng thêm tối thiểu ba tọa độ. nghĩa là [kinh độ, vĩ độ], [kinh độ, vĩ độ], [kinh độ, vĩ độ]",
				"placeholder": "Nhấp vào bản đồ ở trên hoặc thêm tối thiểu ba tọa độ tại đây để tạo đa giác. nghĩa là [kinh độ, vĩ độ], [kinh độ, vĩ độ], [kinh độ, vĩ độ]"
			},
			"dropdown": {
				"future": "Tương lai",
				"possible": "Khả thi",
				"cancel": "Hủy bỏ",
				"system": "Hệ thống",
				"other": "Khác",
				"alert": "Cảnh báo",
				"minor": "Diễn viên phụ",
				"extreme": "Cực",
				"expected": "Kỳ vọng",
				"unknown": "không xác định",
				"safety": "An toàn",
				"severe": "Nghiêm trọng",
				"security": "Bảo vệ",
				"health": "Sức khỏe",
				"moderate": "Vừa phải",
				"update": "Cập nhật",
				"rescue": "Cứu hộ",
				"actual": "Thực tế",
				"observed": "Đã quan sát",
				"restricted": "Bị giới hạn",
				"immediate": "Ngay lập tức",
				"likely": "Có khả năng",
				"unlikely": "Không chắc chắn",
				"past": "Quá khứ",
				"fire": "Ngọn lửa",
				"private": "Riêng tư",
				"test": "Kiểm tra",
				"draft": "Bản nháp",
				"transport": "Vận chuyển",
				"public": "Công cộng",
				"error": "lỗi"
			},
			"event": "Biến cố",
			"type": "Kiểu",
			"category": "thể loại",
			"headline": "Tiêu đề",
			"status": "Trạng thái",
			"area_description": "Mô tả khu vực",
			"severity": "Mức độ nghiêm trọng",
			"certainty": "Sự chắc chắn",
			"urgency": "Khẩn cấp",
			"scope": "Phạm vi"
		},
		"alertZones": {
			"area": "Khu vực",
			"blacklist": "Danh sách đen",
			"events": "Sự kiện",
			"title": "Khu Alert",
			"areaSelectModalTitle": "Vẽ xung quanh các khu vực vùng này áp dụng đối với",
			"whitelist": "Whitelist",
			"name": "Tên",
			"addAlertZone": "Thêm Alert Zone",
			"type": "Kiểu",
			"editAlertZone": "Sửa Zone Alert"
		},
		"feeds": {
			"tutorial": {
				"heading": "Sử dụng nguồn cấp dữ liệu",
				"steps": "<ol><li> Đảm bảo các loại và loại thiên tai đã chọn được bật cho ứng dụng này. Điều này sẽ xác định nguồn cấp dữ liệu và sự kiện nào có thể được bật bên dưới. </li><li> Chọn nguồn cấp dữ liệu và loại sự kiện bạn muốn đóng góp vào cảnh báo cho ứng dụng này. </li></ol>",
				"body": "Một số loại cảnh báo có thể đến từ nhiều nguồn cảnh báo. Phần này cho phép bạn bật hoặc tắt các nguồn cấp dữ liệu và loại sự kiện cụ thể từ các nguồn cấp dữ liệu riêng lẻ cho ứng dụng này."
			},
			"delete": {
				"confirm": "Bạn có chắc không?",
				"yes": "Có, hãy xóa nó",
				"events": "Tất cả các sự kiện đã bật sẽ bị tắt đối với nguồn cấp dữ liệu này."
			},
			"scope": "Phạm vi",
			"disable": "Vô hiệu hóa",
			"type": "Kiểu",
			"events": "Sự kiện",
			"feed": "Nuôi",
			"code": "Mã số",
			"enable": "Bật",
			"configure": "Định cấu hình",
			"title": "Nguồn cấp dữ liệu",
			"languages": "Ngon ngu co san"
		}
	},
	"appCreate": {
		"title": "Tạo ra ứng dụng mới",
		"details": "Chi tiết ứng dụng",
		"copyFrom": "Sao chép nội dung từ",
		"emptyApp": "Trống ứng dụng",
		"createNewOrganization": "Tạo tổ chức mới"
	},
	"organization": {
		"title": "Tổ chức",
		"details": "Các chi tiết về tổ chức",
		"noLanguages": "Không có ngôn ngữ giao",
		"confirmLanguageAssignment": "Bạn có chắc là bạn muốn chỉ định ngôn ngữ này? Bài tập ngôn ngữ không thể được gỡ bỏ."
	},
	"mailingList": {
		"title": "Danh sách gửi thư",
		"list": "Danh sách có sẵn",
		"export": "Xuất khẩu",
		"dateFrom": "Từ",
		"dateTo": "để"
	},
	"moderation": {
		"title": "Kiểm duyệt",
		"image": {
			"title": "Hình ảnh Kiểm duyệt",
			"location": "Đến từ"
		}
	},
	"manageApps": {
		"title": "Quản lý ứng dụng",
		"yourNextApp": "Ứng dụng tiếp theo của bạn",
		"easilySetUpYourNextApp": "Dễ dàng thiết lập ứng dụng tiếp theo của bạn"
	},
	"dashboard": {
		"title": "Bảng điều khiển"
	},
	"manage": {
		"title": "Quản lý"
	},
	"userApps": {
		"title": "Các ứng dụng của người dùng"
	},
	"posts": {
		"title": "bài viết"
	},
	"achievements": {
		"name": "Tên",
		"image": "Hình ảnh",
		"title": "Thành tựu",
		"badge": "Badge",
		"edit": "Sửa Achievement",
		"completion": "Hoàn thành"
	},
	"triggers": {
		"subclasses": {
			"RaisedTrigger": {
				"singular": "Số tiền huy động",
				"amount": "Lượng"
			}
		},
		"plural": "Triggers"
	},
	"mind-moderation": {
		"emoodji": {
			"singular": "Emoodji"
		},
		"title": "Emoodji Moderation",
		"empty": "Không Emoodjis đến trung bình"
	},
	"crossBorders": {
		"contact": {
			"editItem": "chỉnh sửa Liên hệ",
			"number": "Số điện thoại",
			"title": "Tiêu đề"
		},
		"phrase": {
			"translation": "Dịch",
			"phrase": "cụm từ",
			"editItem": "Sửa cụm từ"
		},
		"country": {
			"description": "Các quốc gia đến",
			"code": "Mã quốc gia",
			"name": "Tên quốc gia"
		},
		"translations": "Dịch cụm từ",
		"contacts": "Liên hệ",
		"title": "Hội Chữ thập Borders",
		"phrases": "cụm từ",
		"editItem": "Sửa Điểm đến Nước",
		"list": "Danh sách các điểm đến",
		"link": {
			"destination": "Nơi Đến",
			"placeholder": "Tiêu đề đích",
			"title": "Chức vụ"
		},
		"links": "Liên kết"
	},
	"whatnow": {
		"attribution": {
			"message": "Thông báo phân bổ",
			"url": "URL thuộc tính",
			"image": "Hình ảnh phân bổ",
			"attribution": "Ghi công",
			"imageHelp": "Tải lên hình ảnh / biểu tượng.",
			"socName": "Tên xã hội phân bổ",
			"messageHelp": "Thông điệp đi kèm với phân bổ.",
			"urlHelp": "URL liên kết đến nhiều thông tin hơn về Người đóng góp.",
			"socNameHelp": "Tên của Hiệp hội được phân bổ với những điều đó."
		},
		"edit": {
			"moreinfoHelp": {
				"placeholder": "Lorem ipsum dolor ngồi amet, consectetur adipiscing elit, sed làm eiusmod thời gian incididunt ut labore và dolore magna aliqua. Ut enim ad minim veniam, quis nostrud tập thể dục ullamco laboris nisi ut aliquip ex ea commodo hậu quả."
			},
			"recoverHelp": "Sau",
			"title": "Chức vụ",
			"during": "Suốt trong",
			"missing": "Thiếu nội dung",
			"moreinfo": "URL thông tin",
			"before": "Trước",
			"recover": "Bình phục",
			"watch": "Đồng hồ đeo tay",
			"editWhatnow": "Chỉnh sửa những gì bây giờ",
			"translations": "Bản dịch",
			"languages": "Ngôn ngữ",
			"forecast": "Dự báo",
			"watchHelp": "Chuẩn bị trả lời",
			"event": "Nguy hiểm",
			"selectLang": "Chọn ngôn ngữ",
			"immediate": "Ngay lập tức",
			"midterm": "Dự báo trung hạn",
			"warningHelp": "Chuẩn bị trả lời",
			"new": "Thêm ngôn ngữ mới",
			"addALang": "Thêm ngôn ngữ để bắt đầu",
			"titleHelp": "Tiêu đề cho những gì bây giờ.",
			"descriptionHelp": "Một mô tả cho những gì bây giờ.",
			"eventhelp": "Các loại sự kiện cho những gì bây giờ.",
			"duringHelp": "Phải làm gì trong khi sự kiện đang diễn ra.",
			"beforeHelp": "Cách chuẩn bị trước khi sự kiện diễn ra.",
			"description": "Sự miêu tả",
			"warning": "Cảnh báo",
			"empty": "Vui lòng thêm ngôn ngữ để tạo nội dung ngay bây giờ",
			"forecastHelp": "Đánh giá và lên kế hoạch (Hành động ngắn hạn)",
			"moreinfohelp": "URL để biết thêm thông tin về những gì bây giờ.",
			"afterHelp": "Phải làm gì sau khi sự kiện đã xảy ra.",
			"after": "Sau",
			"immediateHelp": "Trả lời",
			"midtermHelp": "Giảm thiểu rủi ro (Hành động dài hạn)"
		},
		"description": "Sự miêu tả",
		"before": "Trước",
		"after": "Sau",
		"during": "Suốt trong",
		"title": "Chức vụ",
		"eventType": "Nguy hiểm",
		"whatnow": "Những gì bây giờ?",
		"selectSoc": "Chọn một xã hội",
		"content-not-set": "Nội dung chưa được đặt",
		"empty": "Hiện không có mục nhập nào, hãy nhấp vào thêm để tạo một mục"
	},
	"arpro": {
		"codeGroup": {
			"codes": "Mã số",
			"addCodes": "Nhập mã tại đây",
			"codeGroup": "Nhóm mã",
			"editItem": "Chỉnh sửa nhóm mã",
			"description": "Sự miêu tả",
			"name": "Tên"
		},
		"colours": {
			"description": "Sự miêu tả",
			"colours": "Màu sắc",
			"name": "Tên"
		},
		"search": {
			"search": "Tìm kiếm sản phẩm",
			"compressionStrength25": "Cường độ nén 25",
			"compressionStrength75": "Cường độ nén 75",
			"mouldedDensity": "Mật độ đúc",
			"compressionStrength50": "Cường độ nén 50",
			"burningRate": "Tốc độ đốt",
			"tensileStrength": "Sức căng",
			"tensileElongation": "Độ bền kéo dài",
			"resilience75DynamicCompression": "Khả năng phục hồi 75 nén động",
			"equivalentYoungsModulus": "Tương đương Youngs Modulus"
		},
		"product": {
			"properties": {
				"packaging": "Bao bì",
				"bulkLabel": "Số lượng lớn",
				"size": "Kích thước (mm)",
				"food": "Thực phẩm đã được phê duyệt",
				"weight": "Trọng lượng (mg)",
				"bag": "Túi",
				"bulk": "Mật độ khối lượng lớn (g / l)",
				"moulded": "Mật độ đúc (g / l)"
			},
			"specifications": "Thông số kỹ thuật",
			"editItem": "Chỉnh sửa sản phẩm",
			"rawData": "Dữ liệu JSON thô",
			"access": "Mã truy cập",
			"linked": "Có mã truy cập",
			"noneLinked": "Không có mã truy cập",
			"product": "Các sản phẩm",
			"grade": "Cấp",
			"name": "Tên",
			"physicalProps": "Tính chất vật lý",
			"colour": "Màu",
			"notes": "Ghi chú",
			"rawDataError": "Dữ liệu JSON thô - Dữ liệu JSON không hợp lệ",
			"moulded": "Dải mật độ đúc (g / l)"
		},
		"arpro": "arpro",
		"noitems": "Không có mục nào để hiển thị, nhấp vào thêm ở trên."
	},
	"r4b": {
		"level": {
			"green": "màu xanh lá",
			"blue": "Màu xanh da trời",
			"title": "Cấp độ",
			"red": "Màu đỏ",
			"alpha": "Alpha",
			"levels": "Mức độ",
			"colour": "Màu",
			"selectBadge": "Vui lòng chọn huy hiệu"
		},
		"modules": {
			"blockContent": {
				"logic": {
					"title": "Phần hiển thị logic"
				},
				"slider": {
					"endLabel": "Nhãn kết thúc",
					"interval": "Khoảng thời gian",
					"startLabel": "Bắt đầu nhãn"
				},
				"question": {
					"title": "Câu hỏi và trả lời",
					"textHelp": "Nhập câu hỏi",
					"answerHelp": "Nhập câu trả lời",
					"addResponse": "Thêm phản hồi",
					"addAnotherResponse": "Thêm phản hồi khác",
					"responseHelp": "Nhập câu trả lời",
					"selectionHelp": "Chọn một phần để tiếp tục"
				},
				"quote": {
					"title": "Trích dẫn",
					"citation": "Nhập trích dẫn tại đây"
				},
				"checkbox": {
					"title": "Hộp kiểm"
				},
				"textInput": {
					"title": "Nhập văn bản",
					"help": "Nhập nhãn đầu vào tại đây",
					"multi": "Văn bản nhiều dòng?"
				},
				"link": {
					"title": "Liên kết"
				},
				"help": {
					"title": "Đối tượng trợ giúp"
				},
				"task": {
					"title": "Đối tượng nhiệm vụ",
					"help": "Nhập mục tiêu nhiệm vụ"
				},
				"text": {
					"title": "Đối tượng văn bản"
				},
				"radio": {
					"title": "Nút radio"
				},
				"section": "Phần",
				"enterText": "Nhập văn bản ở đây",
				"break": "Ngắt trang",
				"identifier": "Số nhận dạng",
				"inputLabel": "Nhãn nhập văn bản",
				"enterTitle": "Nhập tiêu đề",
				"addIdentifier": "Thêm số nhận dạng",
				"videoLink": "Liên kết đến video bên ngoài",
				"clickToEdit": "Bấm vào để chỉnh sửa",
				"addSection": "Kéo một mục vào đây để tạo mục mới"
			},
			"section": {
				"title": "PHẦN"
			},
			"blockTypes": {
				"help": "CỨU GIÚP",
				"text": "BẢN VĂN",
				"radio": "RADIO",
				"task": "BÀI TẬP",
				"quote": "TRÍCH DẪN",
				"video": "VIDEO",
				"link": "LIÊN KẾT",
				"image": "HÌNH ẢNH",
				"media": "MEDIA BLOCKS",
				"slider": "SLIDER",
				"qna": "Hỏi đáp",
				"textInput": "TIẾP THEO TEXT",
				"userInput": "USER INPUT BLOCKS",
				"check": "KIỂM TRA"
			},
			"person": "người",
			"people": "những người",
			"mins": "phút",
			"levels": "Mức độ",
			"visibility": "Hiển thị",
			"title": "Mô-đun",
			"module": "Mô-đun",
			"level": "Cấp độ",
			"version": "Phiên bản",
			"unsaved": "Có những thay đổi chưa được lưu",
			"saved": "Tất cả thay đổi được lưu"
		},
		"assessments": {
			"assessment": "Thẩm định, lượng định, đánh giá",
			"pre": "Đánh giá trước"
		},
		"people": "NHỮNG NGƯỜI",
		"questions": "Câu hỏi",
		"minutes": "MINS",
		"languages": "Ngôn ngữ",
		"languageVars": "Biến thể ngôn ngữ",
		"contentBlocks": "Khối nội dung",
		"title": "BẢN ĐỒ",
		"person": "NGƯỜI",
		"sections": "PHẦN",
		"mediaBlocks": "MEDIA BLOCKS",
		"admin": "Bảng điều khiển quản trị",
		"inputBlocks": "USER INPUT BLOCKS",
		"empty": "Không có mô-đun hoặc đánh giá nào ở đây, hãy thêm một mô-đun vào cấp độ này để bắt đầu."
	},
	"rspb": {
		"factFile": {
			"commonName": "Tên gọi chung",
			"features": "Tính năng, đặc điểm",
			"seen": "Đã xem",
			"description": "Sự miêu tả",
			"name": "Tên",
			"funFacts": "Những điều lý thú",
			"scientificName": "Tên khoa học",
			"show": "Chỉ",
			"start": "Khởi đầu",
			"addImages": "Chọn ảnh",
			"images": "Hình ảnh",
			"tags": "Thẻ",
			"activities": "Hoạt động",
			"factFile": "Tệp thực tế",
			"editItem": "Chỉnh sửa tệp tin thực tế",
			"addActivities": "Nhập hoạt động tại đây",
			"addFact": "Nhập thực tế tại đây",
			"addFeatures": "Nhập một tính năng tại đây",
			"addTags": "Nhập thẻ tại đây"
		},
		"planItem": {
			"difficulty": {
				"medium": "Trung bình",
				"hard": "Cứng",
				"easy": "Dễ dàng",
				"difficulty": "Chọn độ khó"
			},
			"garden": {
				"public": "Công cộng",
				"medium": "Trung bình",
				"small": "Nhỏ bé",
				"large": "Lớn",
				"selectType": "Chọn loại / s vườn",
				"balconyroof": "Ban công / mái nhà"
			},
			"months": "Tháng",
			"mostImportant": "Quan trọng nhất",
			"to": "Ngày cuối",
			"dates": "ngày",
			"title": "Chức vụ",
			"type": "Kiểu",
			"solo": "Dành cho gia đình?",
			"region": "Khu vực",
			"source": "Nguồn",
			"activityCode": "Mã hoạt động",
			"from": "Có sẵn từ",
			"indefinite": "Không xác định?",
			"identifier": "Số nhận dạng",
			"visible": "Hiển thị từ",
			"leastImportant": "Ít quan trọng nhất",
			"image": "Hình ảnh",
			"tags": "Thẻ",
			"weighting": "Lập kế hoạch mục trọng số",
			"planItems": "Mặt hàng kế hoạch",
			"duration": "Thời lượng (giây)",
			"pageHelp": "Đây là trang mà mục kế hoạch của bạn sẽ liên kết tới",
			"regionHelp": "Nhấp để chọn khu vực cho mục kế hoạch này",
			"description": "Sự miêu tả",
			"weightingHelp": "Mức độ ưu tiên của mục sẽ xuất hiện trong ứng dụng"
		},
		"rspb": "RSPB",
		"noitems": "Không có mục nào để hiển thị, nhấp vào thêm ở trên."
	}
}