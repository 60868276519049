module.exports = Backbone.View.extend({
	template: require('./user-app-list-item-view-template'),
	tagName: 'tr',

	getRenderData: function() {
		var data = this.model.toJSON()

		data.name = {content: data.name}
		return data
	},

	afterRender: function() {
		if (!App.acl.hasWritePermission('Users', 'App assignment')) {
			this.$('.tools').text('-')
		}
	}
})
