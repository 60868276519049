var DeploymentRoleList = require('./deployment-role-list'),
	DeploymentRoleListItemView = require('./deployment-role-list-item-view'),
	DeploymentSectionView = require('./deployment-section-view')

module.exports = DeploymentSectionView.extend({
	template: require('./server-deployment-view-template'),

	initialize: function() {
		this.listViews = []

		$.ajax({
			url: App.deploymentRoot + 'auth',
			headers: App.session.getHeadersObject(),
			dataType: 'text',
			success: this.authSuccess.bind(this),
			error: this.authError.bind(this)
		})
	},

	authSuccess: function() {
		this.roleList = new DeploymentRoleList()
		this.roleList.once('sync', App.stopLoad)
		this.roleList.fetch()

		this.listenTo(this.roleList, 'add', this.addRole, this)
	},

	authError: function() {
		App.router.navigate('/', {trigger: true})
	},

	addRole: function(role) {
		var view = this.addView(new DeploymentRoleListItemView({model: role}))
		this.$('.list-items').append(view.render().el)
	}
})
