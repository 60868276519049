module.exports = Backbone.Model.extend({
	url: function() {
		return (App.whatnowRoot + 'org/' + this.get('countryCode'))
	},

	initialize: function(options) {
		this.set('countryCode', options.countryCode)
		this.bind("error", this.catchError)
	},

	catchError: function(model, error) {
		App.stopLoad()
		if (error.responseJSON.status === 404) {
			swal({
				title: 'Error',
				text: 'Organisation does not exist',
				type: 'error'
			})
		} else {
			console.warn(error)
		}
	},

	/**
	 * Extracts the attribution from the "data" key in the response.
	 * @override
	 */
	parse: function(response) {
		return response.data || response
	}
})
