var RoleGroup = require('./role-group')

/**
 * Exports {@link GroupList}.
 * @module
 */
module.exports = Backbone.Collection.extend(/** @lends GroupList.prototype */{
	/** @override */
	model: RoleGroup,

	/**
	 * @constructs GroupList
	 * @extends Backbone.Collection
	 * @override
	 */
	initialize: function() {
	},

	/** @override */
	url: function() {
		return App.apiRoot + 'groups'
	}
})
