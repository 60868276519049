var StormObject         = require('editor/storm-object'),
	ListItemPreviewView = require('editor/canvas/list-item-preview-view'),
	APICompat           = require('lib/api-compat'),
	StormObjectUtils    = require('lib/storm-object-utils')

/**
 * Exports {@link ViewPicker}.
 * @module
 */
module.exports = Backbone.View.extend(/** @lends ViewPicker.prototype */{
	/** @override */
	template: require('./view-picker-template'),

	/** @override */
	className: 'inspector',

	subclasses: {},

	/**
	 * @constructs ViewPicker
	 * @extends Backbone.View
	 * @override
	 */
	initialize: function(options) {
		this.subclasses = []
		this.app = options.app
		this.drake = null

		/** @private {ListItemPreviewView} */
		this.listItemPreviewView_ = null

		// Get the list of possible classes that can be added
		var classSpec = App.classes.get(this.model.get('class'))
		var childType = classSpec.children

		if (!childType) {
			return
		}

		// Strip surrounding braces.
		childType = childType.substring(1, childType.length - 1)

		// Support legacy API class names.
		childType = APICompat.normaliseClassName(childType)

		if (childType === 'List') {
			childType = 'ListItem'
		}

		var apiChildType = APICompat.unNormaliseClassName(childType)

		App.getSubclasses(apiChildType).then(this.setSubclasses.bind(this))
	},

	/** @override */
	afterRender: function() {
		// Display paste preview if available.
		if (this.canPaste()) {
			var obj       = StormObject.fromProperties(App.clipboard.payload),
				className = APICompat.normaliseClassName(obj.get('class')),
				preview

			if (className === 'List') {
				preview = new ListItemPreviewView.ListPreviewView({
					model: obj
				})
			} else {
				preview = new ListItemPreviewView.ListItemPreviewView({
					model: obj
				})
			}

			this.$('.paste-preview').append(preview.render().el)
		} else {
			this.$('.paste-wrapper').remove()
		}

		if (this.listItemPreviewView_) {
			this.$el.append(this.listItemPreviewView_.render().el)
		}

		this.startDragAndDrop()
	},

	/**
	 * Initialises the drag and drop plugin for dragging new items onto the
	 * page.
	 */
	startDragAndDrop: function() {
		if (this.drake) {
			this.drake.destroy()
		}

		// TODO should take these in from the constructor.
		var listPage             = $('.ListPage').get(),
			quizQuestionSelector = $('.quiz-question-selector').get(),
			lists                = $('.ListPage .List .view-children').get(),
			pastePreview         = this.$('.paste-preview')[0],
			preview              = $('.preview')[0]

		var containers = listPage.concat(lists).concat(quizQuestionSelector)

		if (this.listItemPreviewView_) {
			containers.push(this.listItemPreviewView_.el)
		}

		if (pastePreview) {
			containers.push(pastePreview)
		}

		this.drake = dragula(containers, {
			mirrorContainer: preview,
			copy: true,
			moves: function(el, source) {
				return source.classList.contains('list-item-preview')
			},
			accepts: function(el, target) {
				return !target.classList.contains('list-item-preview')
			}
		})

		this.drake.on('drop', this.handleObjectDrop.bind(this))
	},

	/**
	 * Tears down the drag and drop plugin for dragging new items onto the page.
	 */
	stopDragAndDrop: function() {
		if (this.drake) {
			this.drake.destroy()
			this.drake = null
		}
	},

	/**
	 * Handles a drop event from the drag and drop plugin. Inserts a new model
	 * on the current page to match the dropped template.
	 * @param {HTMLElement} el The DOM element being dropped.
	 * @param {HTMLElement} target The DOM element in which {@link el} is being
	 *     dropped.
	 * @param {HTMLElement} source The DOM element from which {@link el} has
	 *     been removed.
	 * @param {HTMLElement} sibling The DOM element which {@link el} has been
	 *     dropped before, or {@code null} if {@link el} has been dropped at
	 *     the end of the list.
	 */
	handleObjectDrop: function(el, target, source, sibling) {
		if (!target) {
			return
		}

		// Create new object of the specified type.
		var className = $(el).data('class'),
			pageId    = this.model.get('pageId'),
			newModel  = StormObject.fromClassName(className, pageId)

		// Use clipboard data to create new model if required.
		if ($(source).hasClass('paste-preview')) {
			var data = $.extend(true, {}, App.clipboard.payload)

			StormObjectUtils.stripIDs(data, pageId)
			newModel = StormObject.fromProperties(data)
		}

		// Find parent model from drop location.
		var currentNode = target

		while (currentNode && !currentNode.model) {
			currentNode = currentNode.parentNode
		}

		if (!currentNode) {
			throw new Error('Failed to find target view element')
		}

		var targetModel = currentNode.model

		// Create new List container if required.
		var normalise     = APICompat.normaliseClassName,
			newModelClass = normalise(newModel.get('class')),
			targetClass   = normalise(targetModel.get('class'))

		if (targetClass === 'ListPage' && newModelClass !== 'List') {
			var listModel = StormObject.fromClassName('List', pageId)

			listModel.get('children').add(newModel)
			newModel = listModel
		}

		// Get index to add new model into target.
		var targetChildren = targetModel.get('children'),
			targetIndex

		if (sibling === null) {
			targetIndex = targetChildren.length
		} else {
			targetIndex = targetChildren.indexOf(sibling.model)
		}

		// Remove fake dropped view.
		target.removeChild(el)

		targetChildren.add(newModel, {at: targetIndex})
		targetModel.save().then(function() {
			$('.inline-editable').attr('contenteditable', true)

			$('.inline-editable:empty').each(function() {
				var $this = $(this),
					placeholder = $this.data('placeholder')
				$this.text(placeholder).addClass('placeholder')
			})
		})
	},

	setSubclasses: function(subclasses) {
		this.subclasses = subclasses

		// Don't show UnorderedListItem on GDPC first aid apps.
		// Should be removed in the future - current apps don't support this.
		if (App.system.id === 3 && !this.app.isHazardsApp()) {
			var index = this.subclasses.indexOf('UnorderedListItem')

			if (index > -1) {
				this.subclasses.splice(index, 1)
			}
		}

		var linkableItemIndex = this.subclasses.indexOf('LinkableImageItem')

		if (linkableItemIndex > -1) {
			// Remove Linkable as we only need the LinkableImageItemContainer
			if (linkableItemIndex > -1) {
				this.subclasses.splice(linkableItemIndex, 1)
			}
		}

		// Remove LockedLogoListItem - This is no longer needed.
		var logoIndex =  this.subclasses.indexOf('LockedLogoListItemView')

		if (logoIndex > -1) {
			this.subclasses.splice(logoIndex, 1)
		}

		this.listItemPreviewView_ = new ListItemPreviewView({
			subclasses: subclasses,
			draggable: true
		})

		this.render()
	},

	canPaste: function() {
		var classSpec = App.classes.get(this.model.get('class'))
		var childType = classSpec.children

		if (!childType) {
			return false
		}

		// Strip surrounding braces.
		childType = childType.substring(1, childType.length - 1)

		// Support legacy API class names.
		childType = APICompat.normaliseClassName(childType)
		var newClass = APICompat.normaliseClassName(App.clipboard.className)

		// Lists automatically take subclasses for ListItem and insert Lists
		// automatically Need to specifically handle List paste cases
		if (childType === 'List' && newClass === 'List') {
			return true
		}

		var apiNewClass = APICompat.unNormaliseClassName(newClass)

		return this.subclasses.indexOf(apiNewClass) > -1
	},

	/**
	 * This method is deprecated and should not be used. It must not be removed
	 * as it is still used by other classes, despite its private visibility.
	 * @see {@link StormObjectUtils#stripIDs}
	 * @param {Object} object Raw Storm object data from which to recursively
	 *     strip IDs.
	 * @private
	 * @deprecated
	 */
	_stripIDs: function(object) {
		var pageId

		if (this.model) {
			pageId = this.model.get('pageId')
		}

		StormObjectUtils.stripIDs(object, pageId)
	},

	/** @override */
	beforeDestroy: function() {
		this.stopDragAndDrop()
	}
})
