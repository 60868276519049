var PaginatedListView = require('list-view/paginated-list-view'),
	StoryEditView = require('./story-edit-view'),
	Story = require('./story')

module.exports = PaginatedListView.extend({
	template: require('./stories-view-template'),
	list: require('./story-list'),
	listItem: require('./stories-list-item-view'),

	afterInitialize: function() {
		this.views = {}

		// Override edit click
		this.$el.on('click', '.edit', this.edit.bind(this))
	},

	afterRender: function() {
		if (!App.acl.hasWritePermission('Stories')) {
			this.$('.add').hide()
		}

		App.stopLoad()
	},

	// Override add method to provide modal editing
	addModel: function() {
		var model = new Story()

		this.listenToOnce(model, 'create', this.objectCreated, this)
		this.showModalWithModel(model)
	},

	edit: function(e) {
		var id = parseInt($(e.currentTarget).data('id'), 10)
		var model = this.collection.get(id)

		this.showModalWithModel(model)

		e.stopPropagation()
	},

	showModalWithModel: function(model) {
		if (this.views.modal) {
			this.views.modal.destroy()
		}

		this.views.modal = new StoryEditView({model: model})
		this.$el.append(this.views.modal.render().el)
		this.views.modal.$el.modal()
	}
})
