<table>
	<thead>
		<tr>
			<th data-i18n="agreement.nationalSociety"></th>
			<th data-i18n="apps.app"></th>
			<th data-i18n="common.tools"></th>
		</tr>
	</thead>
	<tbody class="list-items"></tbody>
</table>
<div class="assign row">
	<div class="col-xs-12">
		<label data-i18n="apps.assignNew" for="new-app"></label>
	</div>
	<div class="col-sm-4">
		<select class="all-apps form-control" id="new-app"></select>
	</div>
	<div class="col-sm-1">
		<button class="button assign-button" data-i18n="apps.assign"></button>
	</div>
</div>
