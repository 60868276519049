module.exports = {
	'Content': {
		'': {
			Read: {
				system: {
					classes: {
						GET: true,
						GETBYID: true,
						GETBYIDVALID: true
					},
					societies: {
						GET: true,
						GETBYID: true,
						GETBYIDLANGUAGES: true,
						GETBYIDAPPS: true
					},
					languages: {
						GET: true,
						GETBYID: true
					},
					tags: {
						GET: true,
						GETBYID: true,
						GETBYIDFILES: true
					},
					uploads: {
						GET: true,
						GETBYID: true,
						GETBYIDTAGS: true
					},
					templates: {
						GET: true,
						GETBYID: true
					},
					apps: {
						GET: true,
						GETBYID: true,
						GETBYIDLANGUAGES: true,
						GETBYIDPAGES: true,
						GETBYIDTEMPLATES: true,
						GETBYIDSTRUCTURE: true
					},
					objects: {
						GETBYID: true,
						GETBYIDSTRUCTURE: true,
						POSTSEARCH: true
					},
					users: {
						GETBYIDAPPS: true
					}
				}
			},
			Write: {
				system: {
					classes: {
						GET: true,
						GETBYID: true,
						GETBYIDVALID: true
					},
					societies: {
						GET: true,
						GETBYID: true,
						GETBYIDLANGUAGES: true,
						GETBYIDAPPS: true
					},
					languages: {
						GET: true,
						GETBYID: true
					},
					tags: {
						GET: true,
						POST: true,
						GETBYID: true,
						PUTBYID: true,
						DELETEBYID: true,
						GETBYIDFILES: true
					},
					uploads: {
						GET: true,
						GETBYID: true,
						GETBYIDTAGS: true,
						PUTBYIDTAGS: true,
						DELETEBYIDTAGS: true,
						POST: true,
						GETBYIDVARIANTS: true
					},
					templates: {
						GET: true,
						GETBYID: true
					},
					apps: {
						GET: true,
						GETBYID: true,
						GETBYIDLANGUAGES: true,
						GETBYIDPAGES: true,
						GETBYIDTEMPLATES: true,
						GETBYIDSTRUCTURE: true
					},
					objects: {
						POST: true,
						GETBYID: true,
						PUTBYID: true,
						DELETEBYID: true,
						GETBYIDSTRUCTURE: true,
						GETBYIDLOCK: true,
						POSTBYIDLOCK: true,
						PUTBYIDLOCK: true,
						DELETEBYIDLOCK: true
					},
					users: {
						GETBYIDAPPS: true
					}
				}
			},
			Delete: {
				system: {
					classes: {
						GET: true,
						GETBYID: true,
						GETBYIDVALID: true
					},
					societies: {
						GET: true,
						GETBYID: true,
						GETBYIDLANGUAGES: true,
						GETBYIDAPPS: true
					},
					languages: {
						GET: true,
						GETBYID: true
					},
					tags: {
						GET: true,
						POST: true,
						GETBYID: true,
						PUTBYID: true,
						DELETEBYID: true,
						GETBYIDFILES: true
					},
					uploads: {
						GET: true,
						GETBYID: true,
						DELETEBYID: true,
						GETBYIDTAGS: true,
						PUTBYIDTAGS: true,
						DELETEBYIDTAGS: true
					},
					templates: {
						GET: true,
						GETBYID: true
					},
					apps: {
						GET: true,
						GETBYID: true,
						GETBYIDLANGUAGES: true,
						GETBYIDPAGES: true,
						GETBYIDTEMPLATES: true,
						GETBYIDSTRUCTURE: true
					},
					objects: {
						POST: true,
						GETBYID: true,
						PUTBYID: true,
						DELETEBYID: true,
						GETBYIDSTRUCTURE: true,
						EXTRA_ALLOW_DELETE: true,
						GETBYIDLOCK: true,
						POSTBYIDLOCK: true,
						PUTBYIDLOCK: true,
						DELETEBYIDLOCK: true
					},
					users: {
						GETBYIDAPPS: true
					}
				}
			}
		}
	},
	'Localisations': {
		'': {
			Read: {
				system: {
					apps: {
						GETBYIDNATIVE: true,
						GETBYIDLANGUAGES: true
					}
				}
			},
			Write: {
				system: {
					apps: {
						GETBYIDNATIVE: true,
						PUTBYIDNATIVE: true,
						GETBYIDLANGUAGES: true
					}
				}
			}
		}
	},
	'Publish': {
		'': {
			Read: {
				system: {
					classes: {
						GET: true,
						GETBYID: true,
						GETBYIDVALID: true
					},
					societies: {
						GET: true,
						GETBYID: true,
						GETBYIDLANGUAGES: true,
						GETBYIDAPPS: true
					},
					tags: {
						GET: true,
						GETBYID: true
					},
					audit: {
						GETBYID: true
					},
					apps: {
						GET: true,
						GETBYID: true,
						GETBYIDPAGES: true,
						GETBYIDAUDIT: true,
						GETBYIDHISTORY: true
					},
					objects: {
						GETBYID: true,
						GETBYIDAUDIT: true
					},
					users: {
						GETBYIDAPPS: true
					}
				},
				auth: {
					users: {
						POSTLIST: true
					}
				}
			},
			Write: {
				system: {
					classes: {
						GET: true,
						GETBYID: true,
						GETBYIDVALID: true
					},
					societies: {
						GET: true,
						GETBYID: true,
						GETBYIDLANGUAGES: true,
						GETBYIDAPPS: true
					},
					audit: {
						GETBYID: true,
						PUTBYID: true
					},
					apps: {
						GET: true,
						GETBYID: true,
						GETBYIDPAGES: true,
						GETBYIDAUDIT: true,
						POSTBYIDPUBLISH: true,
						GETBYIDHISTORY: true
					},
					objects: {
						POST: true,
						GETBYID: true,
						PUTBYID: true,
						DELETEBYID: true,
						GETBYIDAUDIT: true,
						GETBYIDSTRUCTURE: true,
						POSTSEARCH: true
					},
					users: {
						GETBYIDAPPS: true
					}
				},
				auth: {
					users: {
						POSTLIST: true
					}
				}
			}
		}
	},
	'App Setup': {
		'App setup': {
			Read: {
				system: {
					societies: {
						GETBYIDLANGUAGES: true
					},
					apps: {
						GETBYIDPAGES: true,
						GETBYIDLANGUAGES: true,
						GETBYIDNATIVE: true,
						GETBYIDPROPERTIES: true
					},
					users: {
						GETBYIDAPPS: true
					}
				}
			},
			Write: {
				system: {
					societies: {
						GETBYIDLANGUAGES: true
					},
					apps: {
						PUTBYID: true,
						GETBYIDPAGES: true,
						GETBYIDLANGUAGES: true,
						PUTBYIDLANGUAGES: true,
						GETBYIDNATIVE: true,
						PUTBYIDNATIVE: true,
						DELETEBYIDNATIVE: true
					},
					users: {
						GETBYIDAPPS: true
					}
				}
			}
		},
		'Translation': {
			Read: {
				system: {
					apps: {
						GETBYIDTRANSLATION: true
					}
				}
			},
			Write: {
				system: {
					apps: {
						POSTBYIDTRANSLATION: true
					}
				}
			}
		},
		'App properties': {
			Read: {
				system: {
					apps: {
						GETBYIDPROPERTIES: true
					}
				}
			},
			Write: {
				system: {
					apps: {
						GETBYIDPROPERTIES: true,
						PUTBYIDPROPERTIES: true,
						DELETEBYIDPROPERTIES: true
					}
				}
			}
		},
		'Plugins': {
			Read: {
				system: {
					plugins: {
						GET: true
					},
					apps: {
						GETBYIDPLUGINS: true
					}
				}
			},
			Write: {
				system: {
					plugins: {
						GET: true
					},
					apps: {
						GETBYIDPLUGINS: true,
						PUTBYIDPLUGINS: true
					}
				}
			}
		},
		'Metadata': {
			Read: {
				system: {
					apps: {
						GETBYIDSTORMQL: true
					}
				}
			},
			Write: {
				system: {
					apps: {
						GETBYIDSTORMQL: true
					},
					objects: {
						POST: true,
						PUTBYID: true
					},
					uploads: {
						GET: true,
						GETBYID: true,
						GETBYIDTAGS: true,
						PUTBYIDTAGS: true,
						DELETEBYIDTAGS: true,
						POST: true
					}
				}
			}
		}
	},
	'User Agreement': {
		'': {
			Read: {
				system: {
					users: {
						GETBYIDAPPS: true
					},
					societies: {
						GETBYIDAGREEMENTS: true
					}
				}
			},
			Write: {
				system: {
					societies: {
						GETBYIDAGREEMENTS: true,
						POSTBYIDAGREEMENTS: true,
						PUTBYID: true
					},
					users: {
						GETBYIDAPPS: true
					}
				}
			}
		}
	},
	'Users': {
		'Users list': {
			Read: {
				system: {
					classes: {
						GET: true,
						GETBYID: true,
						GETBYIDVALID: true
					},
					societies: {
						GET: true,
						GETBYID: true,
						GETBYIDAPPS: true
					},
					users: {
						GETBYIDAPPS: true,
						GET: true
					},
					groups: {
						GET: true
					}
				},
				auth: {
					systems: {
						GETBYIDUSERS: true,
						GETBYIDROLES: true
					}
				}
			},
			Write: {
				system: {
					classes: {
						GET: true,
						GETBYID: true,
						GETBYIDVALID: true
					},
					societies: {
						GET: true,
						GETBYID: true,
						GETBYIDAPPS: true
					},
					users: {
						GETBYIDAPPS: true,
						GET: true
					},
					groups: {
						GET: true
					}
				},
				auth: {
					users: {
						POST: true,
						GETBYID: true,
						PUTBYID: true,
						PUTBYIDROLES: true,
						EXTRA_EDIT_ALL: true
					},
					systems: {
						GETBYIDUSERS: true,
						GETBYIDROLES: true
					},
					search: {
						GET: true
					}
				}
			}
		},
		'App assignment': {
			Read: {
				system: {
					classes: {
						GET: true,
						GETBYID: true,
						GETBYIDVALID: true
					},
					societies: {
						GET: true,
						GETBYID: true,
						GETBYIDAPPS: true
					},
					users: {
						GETBYIDAPPS: true,
						GET: true
					},
					groups: {
						GET: true
					}
				},
				auth: {
					users: {
						GETBYID: true
					},
					systems: {
						GETBYIDUSERS: true,
						GETBYIDROLES: true
					}
				}
			},
			Write: {
				system: {
					classes: {
						GET: true,
						GETBYID: true,
						GETBYIDVALID: true
					},
					societies: {
						GET: true,
						GETBYID: true,
						GETBYIDAPPS: true
					},
					users: {
						GETBYIDAPPS: true,
						PUTBYIDAPPS: true,
						DELETEBYIDAPPS: true
					},
					groups: {
						GET: true
					}
				},
				auth: {
					users: {
						GETBYID: true
					},
					systems: {
						GETBYIDUSERS: true,
						GETBYIDROLES: true
					}
				}
			}
		},
		'Role management': {
			Read: {
				system: {
					classes: {
						GET: true,
						GETBYID: true,
						GETBYIDVALID: true
					},
					societies: {
						GET: true,
						GETBYID: true,
						GETBYIDAPPS: true
					},
					users: {
						GETBYIDAPPS: true
					},
					groups: {
						GET: true
					}
				},
				auth: {
					users: {
						GETBYID: true
					},
					roles: {
						GET: true,
						GETBYID: true,
						GETBYIDPERMISSIONS: true
					}
				}
			},
			Write: {
				system: {
					classes: {
						GET: true,
						GETBYID: true,
						GETBYIDVALID: true
					},
					societies: {
						GET: true,
						GETBYID: true,
						GETBYIDAPPS: true
					},
					users: {
						GETBYIDAPPS: true
					},
					groups: {
						GET: true
					}
				},
				auth: {
					users: {
						GETBYID: true
					},
					roles: {
						GET: true,
						POST: true,
						GETBYID: true,
						PUTBYID: true,
						DELETEBYID: true,
						GETBYIDPERMISSIONS: true,
						PUTBYIDPERMISSIONS: true
					}
				}
			}
		}
	},
	'Stories': {
		'': {
			Read: {
				system: {
					stories: {
						GET: true,
						GETBYID: true
					}
				}
			},
			Write: {
				system: {
					stories: {
						DELETEBYID: true,
						GET: true,
						GETBYID: true,
						POST: true,
						PUTBYID: true
					}
				}
			}
		}
	},
	'Levels': {
		'': {
			Read: {
				system: {
					levels: {
						GET: true,
						GETBYID: true,
						GETBYIDCHILDREN: true
					}
				}
			},
			Write: {
				system: {
					levels: {
						DELETEBYID: true,
						GET: true,
						GETBYID: true,
						GETBYIDCHILDREN: true,
						POST: true,
						POSTBYIDCHILDREN: true,
						PUTBYID: true
					}
				}
			}
		}
	},
	'Badges': {
		'': {
			Read: {
				system: {
					apps: {
						GETBYIDSTORMQL: true
					}
				}
			},
			Write: {
				system: {
					apps: {
						GETBYIDSTORMQL: true
					},
					objects: {
						POST: true,
						PUTBYID: true
					}
				}
			}
		}
	},
	'CrossBorder': {
		'': {
			Read: {
				system: {
					apps: {
						GETBYIDSTORMQL: true
					}
				}
			},
			Write: {
				system: {
					apps: {
						GETBYIDSTORMQL: true
					},
					objects: {
						POST: true,
						PUTBYID: true
					}
				}
			}
		}
	},
	'Push': {
		'': {
			Read: {
				system: {
					push: {
						GET: true,
						GETBYID: true,
						GETBYIDASSIGNMENTS: true,
						GETBYIDDETAILS: true
					}
				}
			},
			Write: {
				system: {
					push: {
						DELETEBYID: true,
						GET: true,
						GETBYID: true,
						GETBYIDASSIGNMENTS: true,
						GETBYIDDETAILS: true,
						POST: true,
						PUTBYID: true
					}
				}
			},
			Approve: {
				system: {
					push: {
						DELETEBYID: true,
						GET: true,
						GETBYID: true,
						GETBYIDASSIGNMENTS: true,
						GETBYIDDETAILS: true,
						POST: true,
						PUTBYID: true,
						PUTBYIDADMIN: true,
						PUTBYIDAPPROVE: true,
						PUTBYIDREJECT: true
					}
				}
			}
		}
	},
	'GDPC Console': {
		'National Societies': {
			Read: {
				system: {
					societies: {
						GETPOOLS: true
					},
					pools: {
						GETDETAILS: true
					},
					users: {
						GETBYIDAPPS: true
					}
				}
			},
			Write: {
				system: {
					apps: {
						GET: true
					},
					societies: {
						GETPOOLS: true,
						PUTBYID: true,
						POST: true
					},
					pools: {
						GETDETAILS: true
					}
				}
			}
		},
		'Dev Pools': {
			Read: {
				system: {
					pools: {
						GET: true,
						GETDETAILS: true,
						GETBYID: true,
						GETBYIDDETAILS: true,
						GETBYIDSTAGES: true
					}
				}
			},
			Write: {
				system: {
					pools: {
						DELETEBYID: true,
						GET: true,
						GETDETAILS: true,
						GETBYID: true,
						GETBYIDDETAILS: true,
						GETBYIDSTAGES: true,
						POST: true,
						PUTBYID: true,
						PUTBYIDAPP: true,
						PUTBYIDSTAGES: true
					},
					languages: {
						GET: true
					},
					apps: {
						GET: true
					},
					stages: {
						DELETEBYID: true,
						GET: true,
						GETBYID: true,
						POST: true,
						PUTBYID: true,
						PUTBYIDCOMPLETE: true
					}
				}
			}
		}
	},
	'Hazards': {
		Disasters: {
			Read: {
				system: {
					apps: {
						GETBYIDPAGES: true,
						GETBYIDSTORMQL: true
					},
					disasters: {
						GET: true
					}
				}
			},
			Write: {
				system: {
					apps: {
						GETBYIDPAGES: true,
						GETBYIDSTORMQL: true
					},
					disasters: {
						GET: true
					},
					objects: {
						DELETEBYID: true,
						POST: true,
						PUTBYID: true,
						GETBYIDLOCK: true,
						POSTBYIDLOCK: true,
						PUTBYIDLOCK: true,
						DELETEBYIDLOCK: true
					}
				}
			}
		},
		Alerts: {
			Read: {
				system: {
					alerts: {
						GET: true,
						GETFEED: true
					}
				}
			},
			Write: {
				system: {
					alerts: {
						GET: true,
						GETFEED: true,
						POST: true
					}
				}
			}
		}
	},
	'Apps': {
		'': {
			Write: {
				system: {
					apps: {
						DELETEBYID: true,
						POST: true,
						PUTBYID: true
					}
				}
			}
		}
	},
	'Admin': {
		'Organization': {
			Read: {
				system: {
					societies: {
						GETBYID: true
					}
				}
			},
			Write: {
				system: {
					societies: {
						GETBYID: true,
						PUTBYID: true,
						PUTBYIDLANGUAGES: true
					}
				}
			}
		},
		'Mailing lists': {
			Read: {
				system: {
					mailer: {
						GET: true,
						GETBYID: true,
						GETBYIDEXPORT: true
					}
				}
			}
		}
	}
}
