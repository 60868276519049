<div class="row">
    <div class="col-sm-6">
        <h4 data-i18n="crossBorders.contacts"></h4>
    </div>
    <div class="col-sm-6 actions-col">
        <a href="/apps/{{appId}}/cross-borders/contact/{{parentId}}/new">
            <button class="button wide add-contact">{{t "common.add"}}</button>
        </a>
    </div>
</div>
<table>
	<thead>
		<tr>
			<th>{{t "crossBorders.contact.title"}}</th>
			<th>{{t "crossBorders.contact.number"}}</th>
			<th>{{t "common.tools"}}</th>
		</tr>
	</thead>
	<tbody class="list-items"></tbody>
</table>
