var StormObject = require('editor/storm-object')

/**
 * Exports {@link QuizItemOption}.
 * @module
 */
module.exports = StormObject.extend(/** @lends QuizItemOption.prototype */{
	/**
	 * @constructs QuizItemOption
	 * @extends StormObject
	 * @override
	 * @classdesc StormObject wrapper class for fake {Text,Image}QuizItemOption
	 *     objects, which are used internally by the CMS for easier editing,
	 *     but map back to the correct structure on save.
	 */
	initialize: function() {
	},

	/**
	 * Proxies object saves up to the parent.
	 * @override
	 */
	save: function() {
		this.collection.parent.save()
	},

	/**
	 * Destroys any child text and image objects, and proxies the save up to
	 * the parent.
	 * @override
	 */
	destroy: function() {
		var parent = this.collection.parent

		// Delete both option and image objects.
		var titleId = this.get('title..id')

		// Get lock token for this model.
		var options = this.setLockHeader({})

		// Fire DELETE request on old model.
		Backbone.sync('delete', new Backbone.Model(), {
			url: this.url() + '/' + titleId,
			headers: options.headers
		})

		var image = this.get('image')

		if (image) {
			if (image.src) {
				// Handle legacy image format.
				Backbone.sync('delete', new Backbone.Model(), {
					url: this.url() + '/' + image.id,
					headers: options.headers
				})
			} else {
				var models = image.slice(0)

				models.forEach(function(model) {
					model.destroy()
				})
			}
		}

		// Remove this fake model and save.
		StormObject.prototype.destroy.apply(this, arguments)
		return parent.save()
	}
})
