var AppStrings   = require('./app-strings'),
	LanguageList = require('editor/language-list'),
	EditorSectionView = require('editor/editor-section-view'),
	LocalisationEditorView = require('./localisation-editor-view'),
	/** @type {StormGlobals} */
	globals = require('globals')

module.exports = EditorSectionView.extend({
	className: 'localisation',
	template: require('./localisation-view-template'),

	events: {
		'click .add-button': 'addButtonClick',
		'click .close-add-button': 'closeAddButtonClick',

		'input .new-localisation-key': 'addLocalisationKeyInputChange',
		'click .add-localisation-button': 'addLocalisationButtonClick',
		'click .csv-upload-button': 'csvUploadButtonClick',
		'change .csv-upload-input': 'csvUploadInputChange',

		'input .filter-input': 'filterInputChange',

		'change #overwrite-existing': 'overwriteInputChange',
		'click .copy-localisations-button': 'copyLocalisations'
	},

	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)
		App.startLoad()

		this.appId = options.appId

		// Fetch native app localisation strings
		this.model = new AppStrings({id: this.appId})
		var stringsFetch = this.model.fetch()

		// Fetch list of languages enabled for this app
		this.appLanguages = new LanguageList(null, {appId: this.appId})
		var langFetch = this.appLanguages.fetch()

		this.views = {
			editor: new LocalisationEditorView()
		}

		this.listenTo(this.views.editor, 'save', this.saveChangedStrings, this)

		Promise.all([stringsFetch, langFetch]).then(this.ready.bind(this))
	},

	getPageTitle: function() {
		return $.t('localisations.title')
	},

	ready: function() {
		this.views.editor.model = this.convertModelHashToArray()
		this.render()
		App.stopLoad()
	},

	getRenderData: function() {
		return {
			appId: this.appId,
			languages: this.appLanguages.toJSON(),
			appSelect: App.generateAppSelect()
		}
	},

	afterRender: function() {
		this.views.editor.remove()
		this.$('.localisation-editor-container').append(this.views.editor.$el)
		this.views.editor.delegateEvents()

		var appList = globals.getAppList()

		// Hide localisations copy form if only one app assigned.
		if (appList.length === 1) {
			this.$('.localisations-copy-form').addClass('hidden')
		}

		// Hide add/edit features without write permission.
		if (App.acl.hasReadPermission('Localisations')) {
			this.$('.add-button, .save-localisations-button').remove()
		}

		// Hide metadata tab for users without Roboto permissions
		if (App.userRoles.findWhere({systemId: 5}) === undefined) {
			this.$('.metadata-link').hide()
		}

		// Remove developer-only content.
		if (!App.developerMode) {
			this.$('.developer-mode').remove()
		}
	},

	// Convert the model's hash to an array with language names as well as codes.
	convertModelHashToArray: function() {
		var langs = this.appLanguages,
			strings = []

		_.each(this.model.attributes, function(val, key) {
			if (key === 'id') {
				return
			}

			strings.push({
				key: key,
				string: makeStringFromKey(key),
				languages: langs.map(function(lang) {
					var code = lang.get('code')

					return {
						name: lang.get('name'),
						code: code,
						value: val[code]
					}
				})
			})
		})

		return strings
	},

	// Button to show add panel/form.
	addButtonClick: function() {
		this.toggleAddPanel(true)
		this.$('.close-add-button').focus()
	},

	// Button to hide add panel/form.
	closeAddButtonClick: function() {
		this.toggleAddPanel(false)
		this.$('.add-button').focus()
	},

	// Disallow non-alphanumeric characters in the key field.
	addLocalisationKeyInputChange: function() {
		var $input = this.$('.new-localisation-key'),
			initialValue = $input.val(),
			value = initialValue.toUpperCase()
				.replace(/ /g, '_')
				.replace(/\W/g, '')

		// Force first character to underscore.
		if (value.charAt(0) !== '_') {
			value = '_' + value
		}

		if (value !== initialValue) {
			$input.val(value)
		}
	},

	// Button to add and save a new localisation.
	addLocalisationButtonClick: function() {
		var key = this.$('.new-localisation-key').val().toUpperCase().trim(),
			values = {}

		this.appLanguages.forEach(function(lang) {
			values[lang.get('code')] = this.$('.new-localisation-string[data-code=' + lang.get('code') + ']').val()
		}, this)

		if (!isValidKey(key)) {
			swal($.t('error.oops'), $.t('localisations.errors.invalidKey'), 'error')
			return false
		}

		var strings = {}
		strings[key] = values

		this.saveStrings(strings)

		// Reset form.
		this.$('.add-form input').val('')
		this.$('.new-localisation-key').focus()

		return false
	},

	// Show/hide add panel.
	toggleAddPanel: function(show) {
		this.$('.add-button').toggleClass('hidden', show)
		this.$('.close-add-button').toggleClass('hidden', !show)
		this.$('.add-form').toggleClass('zero-height', !show)
	},

	// Filter the editor based on the input string.
	filterInputChange: function(e) {
		this.views.editor.setFilter(e.currentTarget.value)
	},

	// Get modified strings from the editor and save.
	saveChangedStrings: function() {
		var strings = this.views.editor.getChangedStrings()
		this.saveStrings(strings)
	},

	// Save a hash of strings to the server. Can be a subset.
	saveStrings: function(strings) {
		App.startLoad()

		this.model.once('sync', this.saveComplete, this)
		this.model.save(null, {
			data: JSON.stringify({
				strings: strings
			})
		})
	},

	// Show file select on button click.
	csvUploadButtonClick: function() {
		this.$('.csv-upload-input').click()
	},

	// Read CSV on change and save.
	csvUploadInputChange: function(e) {
		var file = e.target.files[0]

		if (file) {
			var reader = new FileReader()

			reader.onload = function(e) {
				this.addStringsCsv(e.target.result)
				this.replaceCsvInput()
			}.bind(this)

			reader.readAsText(file)
		}
	},

	// Replace the current CSV file input with a new one to clear the value.
	replaceCsvInput: function() {
		var $input = this.$('.csv-upload-input'),
			html = $input[0].outerHTML

		$input.remove()
		this.$el.append($(html))
	},

	// Add multiple strings from CSV text.
	addStringsCsv: function(csv) {
		// Normalise to Unix line endings.
		csv = csv.replace(/\r/g, '')

		var colCount = this.appLanguages.length + 1

		var rows = csv.split('\n'),
			firstRow = rows[0].split(',')

		var expected = $.t('localisations.errors.expected'),
			got = $.t('localisations.errors.got')

		if (firstRow.length !== colCount) {
			var tooManyHeaders = $.t('localisations.errors.csvTooManyHeaders')
			swal($.t('error.oops'), tooManyHeaders + ' ' + expected + ' ' + colCount + ', ' + got + ' ' + firstRow.length + '.', 'error')
			return
		}

		// If there's a header row, check it's correct.
		if (firstRow[0].toLowerCase() === 'key') {
			var isValid = this.appLanguages.every(function(lang, i) {
				return lang.get('code') === firstRow[i + 1].toLowerCase().trim()
			})

			if (!isValid) {
				var correctHeader = this.appLanguages.map(function(lang) {
					return lang.get('code')
				}).join(',')

				var invalidHeader = $.t('localisations.errors.csvInvalidHeader')
				swal($.t('error.oops'), invalidHeader + ' ' + expected + ' "key,' + correctHeader + '", ' + got + ' "' + rows[0] + '"', 'error')
				return
			}

			// Remove the header row before processing.
			rows.splice(0, 1)
		}

		var newStrings = {}

		var validStrings = rows.every(function(row, i) {
			row = row.split(',')
			var key = row[0].toUpperCase().trim()

			if (!isValidKey(key)) {
				var invalidKeyOnLine = $.t('localisations.errors.csvInvalidKeyOnLine')
				swal($.t('error.oops'), invalidKeyOnLine + ' ' + (i + 1) + ', "' + key + '"', 'error')
				return false
			}

			if (row.length !== colCount) {
				var csvInvalidColumnCountOnLine = $.t('csvInvalidColumnCountOnLine')
				swal($.t('error.oops'), csvInvalidColumnCountOnLine + ' ' + (i + 1) + '. Expected ' + colCount + ', got ' + row.length + '.', 'error')
				return false
			}

			var values = newStrings[key] = {}

			this.appLanguages.forEach(function(lang, i) {
				values[lang.get('code')] = row[i + 1]
			})

			return true
		}, this)

		if (validStrings) {
			this.saveStrings(newStrings)
		}
	},

	// Update editor with new localisations.
	saveComplete: function() {
		// Update model on editor.
		this.views.editor.model = this.convertModelHashToArray()
		this.views.editor.render()

		App.stopLoad()
		App.showToast($.t('hazards.disasters.saveSuccess'))
	},

	// Show/hide the warning about existing localisations on checkbox change.
	overwriteInputChange: function(e) {
		this.$('.alert').toggle(e.currentTarget.value)
	},

	// Copy localisations from the selected app.
	// TODO rewrite this function.
	copyLocalisations: function() {
		if (!confirm($.t('localisations.confirmCopy'))) {
			return
		}

		App.startLoad()

		var sourceAppId = this.$('#copy-from-app').val(),
			overwrite = this.$('#overwrite-existing')[0].checked,
			newStrings = new AppStrings({id: sourceAppId})

		var self = this

		newStrings.fetch().then(function() {
			newStrings = newStrings.toJSON()
			delete newStrings.id

			// Add in correct set of language keys for this app.
			var strings = Object.keys(newStrings),
				languages = Object.keys(newStrings[strings[0]]),
				languageKey = languages[0]

			if (languages.en) {
				languageKey = 'en'
			}

			self.appLanguages.forEach(function(lang) {
				var code = lang.get('code')

				for (var i in newStrings) {
					if (newStrings.hasOwnProperty(i)) {
						var string = newStrings[i]
						string[code] = string[code] || string[languageKey]
					}
				}
			})

			// Remove any strings which already exist in this app, unless overwriting.
			if (!overwrite) {
				for (var key in self.model.attributes) {
					if (self.model.attributes.hasOwnProperty(key)) {
						if (newStrings[key]) {
							delete newStrings[key]
						}
					}
				}
			}

			// Update model and re-render.
			self.model.set(newStrings)

			var data = {
				strings: self.model.toJSON()
			}

			self.model.save(null, {
				data: JSON.stringify(data)
			}).then(self.saveComplete)

			self.render()
		})
	}
})

function isValidKey(key) {
	return key.match(/^_\w+_\w+$/) !== null
}

function makeStringFromKey(key) {
	return key.toLowerCase().substr(1).replace(/_/g, ' ')
}
