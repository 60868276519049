<div class="row">
	<div class="col-xs-12">
		<div class="row">
			<div class="col-sm-6">
				<h3 data-i18n="rspb.planItem.editItem"></h3>
			</div>
			<div class="col-sm-6 actions-col">
				<a href="javascript:history.back()"><button class="button red wide" type="button">{{t "common.cancel"}}</button></a>
				<button class="button green wide save" type="submit">{{t "common.save"}}</button>
			</div>
		</div>
	</div>
</div>

<div class="row">
	<!-- Title -->
	<div class="col-sm-6">
		<div class="form-group">
			<label class="control-label">{{t "rspb.planItem.title"}}</label>
		</div>
		{{#each languages}}
			<div class="form-group">
				<div class="input-group">
					<span class="input-group-addon">{{name}}</span>
					<input type="text" class="title-input form-control text-input" data-code="{{code}}" data-object="title">
				</div>
			</div>
		{{/each}}
		<!-- Type -->
		<div class="form-group">
			<label class="control-label">{{t "rspb.planItem.type"}}</label>
		</div>
		<div class="form-group">
			<select class="form-control type-input">
				<option value="story" {{#ifEqual type 'story'}} selected {{/ifEqual}}>Story</option>
				<option value="activity" {{#ifEqual type 'activity'}} selected {{/ifEqual}}>Activity</option>
				<option value="content" {{#ifEqual type 'content'}} selected {{/ifEqual}}>Content</option>
			</select>
		</div>
		<!--- Duration -->
		<div class="form-group">
			<label class="control-label">{{t "rspb.planItem.duration"}}</label>
		</div>
		<div class="form-group">
			<input type="number" class="duration-input form-control">
		</div>
		<!--- source -->
		<div class="form-group">
			<label class="control-label">{{t "rspb.planItem.source"}}</label>
		</div>
		<div class="form-group">
			<input type="text" class="source-input form-control">
		</div>
		<!--- activity code -->
		<div class="form-group">
			<label class="control-label">{{t "rspb.planItem.activityCode"}}</label>
		</div>
		<div class="form-group">
			<input type="text" class="activity-code-input form-control">
		</div>
		<!-- Solo -->
		<div class="form-check">
			<label class="form-check-label" for="solo-input">
			<input class="form-check-input" type="checkbox" id="solo-input">
				{{t "rspb.planItem.solo"}}
			</label>
		</div>
	</div>

	<!-- Image -->
	<div class="col-sm-6">
		<div class="form-group">
			<label class="control-label">{{t "rspb.planItem.image"}}</label>
		</div>
		<img src="{{getPreviewUrl image.src}}" class="plan-item-image" data-index="{{@key}}" />
	</div>
</div>

<div class="row">
	<!-- Description -->
	<div class="col-sm-12">
		<div class="form-group">
			<label class="control-label">{{t "rspb.planItem.description"}}</label>
		</div>
		{{#each languages}}
			<div class="form-group">
				<div class="input-group">
					<span class="input-group-addon">{{name}}</span>
					<textarea class="description-input form-control text-input" data-code="{{code}}" data-object="description"></textarea>
				</div>
			</div>
		{{/each}}
	</div>
</div>

<div class="row">
	<div class="col-sm-4">
		<!-- Page Select -->
		<div class="form-group">
			<label class="control-label" for="page-input">{{t "common.page"}}</label>
		</div>
		<div class="form-group">
			<div class="col-sm-12 input-group">
				<select id="page-input" class="form-control">{{{pageSelectOptions}}}</select>
				<p class="help-block">
					<span>{{t "rspb.planItem.pageHelp"}}</span>
				</p>
			</div>
		</div>
		<!-- Weighting -->
		<div class="form-group">
			<label class="control-label">{{t "rspb.planItem.weighting"}}</label>
			<span><small id="weightingValue">0</small></span>
			<br />
			<small>{{t "rspb.planItem.weightingHelp"}}</small>
		</div>
		<div class="form-group">
			<input type="range" class="weighting-input form-control" min="0" max="99" step="1">
			<span><small>{{t "rspb.planItem.mostImportant"}}</small></span>
			<span style="float:right;"><small>{{t "rspb.planItem.leastImportant"}}</small></span>
		</div>
	</div>

	<!-- Features -->
	<div class="col-sm-4">
		<div class="form-group">
			<label class="control-label">{{t "rspb.planItem.dates"}}</label>
		</div>
		<div class="input-group">
			<span class="input-group-addon">{{t "rspb.planItem.from"}}</span>
			<input type="text" id="date-from" class="form-control">
		</div>
		<br />
		<div class="input-group">
			<label class="form-check-label" for="indefinite-input">
			<input class="form-check-input" type="checkbox" id="indefinite-input">
				{{t "rspb.planItem.indefinite"}}
			</label>
		</div>
		<div class="input-group">
			<span class="input-group-addon">{{t "rspb.planItem.to"}}</span>
			<input type="text" id="date-to" class="form-control">
		</div>
		<br />
		<div class="input-group">
			<span class="input-group-addon">{{t "rspb.planItem.visible"}}</span>
			<input type="text" id="date-visible" class="form-control">
		</div>
	</div>
	<div class="col-sm-4">
		<div class="form-group">
			<label class="control-label">{{t "rspb.planItem.tags"}}</label>
		</div>

		<table>
			<tbody class="list-items">
				{{#each tags}}
					{{#unless toDelete}}
					<tr>
						<td>
							<!-- <input type="text" class="form-control string-input" data-object="tags" data-arrayitem="true" value="{{this}}" data-index="{{@key}}"> -->
							{{this}}
						</td>
						<td class="tools">
							<i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}" class="array-remove-button" data-object="tags" data-index="{{@key}}"></i>
						</td>
					</tr>
					{{/unless}}
				{{/each}}
				<tr>
					<td>
						<div class="form-group">
							<select class="form-control tag-input" data-object="tags" >
								{{#each allTags}}
									<option value="{{key}}" {{#if isDisabled}} disabled {{/if}}>{{languages.[0].value}}</option>
								{{/each}}
							</select>
							<!-- <input type="text" class="tag-input form-control" data-object="tags" placeholder="{{t "rspb.planItem.addTags"}}"> -->
						</div>
					</td>
					<td class="tools">
						<button class="btn btn-default array-add" type="button" data-object="tags">{{t "common.add"}}</button>
					</td>
				</tr>

			</tbody>
		</table>
	</div>
</div>

<div class="row" style="margin-bottom: 30px;">
	<!-- Garden Type -->
	<div class="col-md-4">
		<div class="form-group">
			<label class="control-label">{{t "rspb.planItem.garden.selectType"}}</label>
		</div>
		<div class="form-check">
			<label class="form-check-label" for="small-input" style="font-weight:normal;">
			<input class="form-check-input garden-check" type="checkbox" id="small-input" data-object="garden" data-type="small">
				{{t "rspb.planItem.garden.small"}}
			</label>
		</div>
		<div class="form-check">
			<label class="form-check-label" for="medium-input" style="font-weight:normal;">
			<input class="form-check-input garden-check" type="checkbox" id="medium-input" data-object="garden" data-type="medium">
				{{t "rspb.planItem.garden.medium"}}
			</label>
		</div>
		<div class="form-check">
			<label class="form-check-label" for="large-input" style="font-weight:normal;">
			<input class="form-check-input garden-check" type="checkbox" id="large-input" data-object="garden" data-type="large">
				{{t "rspb.planItem.garden.large"}}
			</label>
		</div>
		<div class="form-check">
			<label class="form-check-label" for="balcony-input" style="font-weight:normal;">
			<input class="form-check-input garden-check" type="checkbox" id="balcony-input" data-object="garden" data-type="balconyroof">
				{{t "rspb.planItem.garden.balconyroof"}}
			</label>
		</div>
		<div class="form-check">
			<label class="form-check-label" for="public-input" style="font-weight:normal;">
			<input class="form-check-input garden-check" type="checkbox" id="public-input" data-object="garden" data-type="public">
				{{t "rspb.planItem.garden.public"}}
			</label>
		</div>
		<!-- Difficulty -->
		<div class="form-group" style="margin-top: 10px;">
			<label class="control-label">{{t "rspb.planItem.difficulty.difficulty"}}</label>
		</div>
		<div class="form-check">
			<label class="form-check-label" for="difficulty-easy-input" style="font-weight:normal;">
			<input class="form-check-input difficulty-radio" type="radio" id="difficulty-easy-input" name="difficulty" value="easy">
				{{t "rspb.planItem.difficulty.easy"}}
			</label>
		</div>
		<div class="form-check">
			<label class="form-check-label" for="difficulty-medium-input" style="font-weight:normal;">
			<input class="form-check-input difficulty-radio" type="radio" id="difficulty-medium-input" name="difficulty" value="medium">
				{{t "rspb.planItem.difficulty.medium"}}
			</label>
		</div>
		<div class="form-check">
			<label class="form-check-label" for="difficulty-hard-input" style="font-weight:normal;">
			<input class="form-check-input difficulty-radio" type="radio" id="difficulty-hard-input" name="difficulty" value="hard">
				{{t "rspb.planItem.difficulty.hard"}}
			</label>
		</div>
	</div>
	<!-- Months -->
	<div class="col-md-4">
		<div class="form-group">
			<label class="control-label">{{t "rspb.planItem.months"}}</label>
		</div>
		<select id="monthSelector" multiple="multiple" style="width: 100%">
			<option value='0'>January</option>
			<option value='1'>February</option>
			<option value='2'>March</option>
			<option value='3'>April</option>
			<option value='4'>May</option>
			<option value='5'>June</option>
			<option value='6'>July</option>
			<option value='7'>August</option>
			<option value='8'>September</option>
			<option value='9'>October</option>
			<option value='10'>November</option>
			<option value='11'>December</option>
		</select>
	</div>
	<!-- Region -->
	<div class="col-md-4">
		<!-- Area picker -->
		<div class="form-group">
			<div class="form-group">
				<label class="control-label">{{t "rspb.planItem.region"}}</label>
			</div>
				<div class="map-preview">
					<div class="alert-preview-map google-maps"></div>
					<div class="map-button"></div>
				</div>
				<p class="help-block">{{t "rspb.planItem.regionHelp"}}</p>
		</div>
	</div>
</div>
