var ListViewItem = require('list-view/list-view-item')

module.exports = ListViewItem.extend({
	template: require('./alert-zones-list-item-view-template'),

	initialize: function(options) {
		this.appId = options.appId
	},

	getRenderData: function() {
		var data = this.model.toJSON()

		data.appId = this.appId

		return data
	},

	afterRender: function() {
		var permission = App.acl.getPermission('Content'),
			$tools = this.$('.tools')

		$tools.find('.edit, .delete').hide()

		switch (permission) {
			case 'Delete':
				$tools.find('.delete, .edit').show()
				break

			case 'Write':
				$tools.find('.edit').show()
				break
		}
	}
})
