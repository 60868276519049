var StandaloneStormObject = require('editor/standalone-storm-object'),
	APICompat             = require('lib/api-compat')

/**
 * Exports {@link Page}.
 * @module
 */
var Page = StandaloneStormObject.extend(/** @lends Page.prototype */{
	/**
	 * @constructs Page
	 * @extends StandaloneStormObject
	 * @override
	 * @classdesc Defines a wrapper {@link StormObject} class for handling all
	 *     Storm Page subclasses. Provides methods for locking not required for
	 *     other objects.
	 */
	initialize: function() {
		StandaloneStormObject.prototype.initialize.apply(this, arguments)
	},

	/**
	 * @see {@link Lock#lock}
	 * @returns {Promise} Result of {@link Lock#lock}.
	 * @deprecated
	 */
	lockObject: function() {
		return this.lock.lock()
	},

	/**
	 * @see {@link Lock#unlock}
	 * @param {boolean} [block=false] See {@link Lock#unlock}.
	 * @returns {Promise} Result of {@link Lock#unlock}.
	 * @deprecated
	 */
	unlock: function(block) {
		return this.lock.unlock(block)
	},

	/**
	 * @see {@link Lock#isLocked}
	 * @returns {Promise} Result of {@link Lock#isLocked}.
	 * @deprecated
	 */
	hasLock: function() {
		return this.lock.isLocked()
	}
})

/**
 * Creates a new Page instance with structure defined by the specified class
 * name.
 *
 * Note: input class names will automatically be mapped back to versions
 * compatible with the active API.
 * @param {string} className Class name of the object to construct.
 * @returns {Page} Complete Page instance for the specified
 *     object.
 */
Page.fromClassName = function(className) {
	var apiClassName = APICompat.unNormaliseClassName(className),
		structure    = App.getClassStructure(apiClassName)

	return new Page(structure)
}

module.exports = Page
