var ListViewItem = require('list-view/list-view-item')

var STATUS_TEXT = [
	'Inactive',
	'Active',
	'Inaccurate Info',
	'Inappropriate Image',
	'Not a Wildfire'
]

module.exports = ListViewItem.extend({
	template: require('./image-moderation-item-view-template'),

	afterInitialize: function() {
		this.events['click .approve'] = 'approve'
		this.events['click .reject'] = 'reject'
		this.delegateEvents()
	},

	getRenderData: function() {
		var data = this.model.toJSON(),
			media = data.media || []

		data.thumbnail = _.findWhere(media, {type: 'thumbnail'}) || media[0]
		data.original = _.findWhere(media, {type: 'original'}) || media[0]

		data.statusText = STATUS_TEXT[data.status]

		return data
	},

	approve: function() {
		this.model.save({status: 1})
		this.removeView()
	},

	reject: function() {
		this.model.save({status: 0})
		this.removeView()
	},

	removeView: function() {
		this.model.trigger('destroy')
		this.destroy()
	}
})
