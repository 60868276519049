var LinkSelector = require('./link-selector'),
	MediaLibrary = require('media-library/media-library-view'),
	MediaSelectorView = require('media-library/media-selector-view'),
	utils = require('lib/utils')

module.exports = Backbone.View.extend({
	template: require('./linkable-image-item-view-template'),
	className: "js-image-list__item c-image-list__item",
	events: {
		'click .remove': 'removeLink',
		'click .up': 'moveUp',
		'click .down': 'moveDown',
		'click .js-image-select-button': 'chooseImage',
		'click .js-image-remove-button': 'removeImage',
		'keypress .js-alt': 'altChange',
		'keydown .js-alt': 'altChange',
		'keyup .js-alt': 'altChange',
		'paste .js-alt': 'altChange',
		'keypress .js-analytics': 'analyticsChange',
		'keydown .js-analytics': 'analyticsChange',
		'keyup .js-analytics': 'analyticsChange',
		'paste .js-analytics': 'analyticsChange'
	},

	initialize: function(options) {
		this.currentLang = options.language || Storm.view.language
		this.views = {
			linkSelector: new LinkSelector({link: this.model.get("link"), language: this.currentLang})
		}
		this.parentLock = options.parentLock

		this.listenTo(this.views.linkSelector, 'change', function() {
			this.model.collection.parent.needsSaving = true
			this.model.collection.parent.needsStructureUpdate = true
			this.model.collection.parent.trigger('change')
		}, this)

		this.listenTo(this.model, 'change:image', function() {
			this.model.collection.parent.needsSaving = true
			this.model.collection.parent.needsStructureUpdate = true
			this.updateImage()
			this.model.collection.parent.trigger('change')
		}, this)
	},

	getRenderData: function() {
		return {
			viewId: this.cid,
			data: this.model.toJSON()
		}
	},

	afterRender: function() {
		this.$('.js-link-detail').html(this.views.linkSelector.render().el)
	},

	chooseImage: function() {
		this.views.mediaLibrary = new MediaSelectorView({
			app: Storm.view.app,
			model: this.model.get('image'),
			mediaType: MediaLibrary.types.IMAGE
		})

		this.views.mediaLibrary.on('change', function() {
			this.model.trigger('change:image', this.model)
		}, this)

		$('body').append(this.views.mediaLibrary.el)
		this.views.mediaLibrary.render().show()
	},

	removeImage: function() {
		var image = this.model.get('image')

		var densities = ['0.75', '1', '1.5', '2'],
			src = image.get ? image.get('src') : image.src

		densities.forEach(function(density) {
			src['x' + density] = ''
		})

		this.model.trigger('change:image', this.model)
	},

	updateImage: function() {
		// Support legacy image format.
		var image = this.model.get('image')

		if (!image.src) {
			image = image.toJSON()
		}

		this.$('.js-img-preview').attr('src', utils.getImagePreviewUrl(image))
	},

	removeLink: function() {
		if (this.parentLock) {
			var headers = App.session.getHeadersObject()
			// Pull in lock data from the parent page, if there is one.
			headers.Lock = this.parentLock.get('token')

			$.ajax({
				url: App.apiRoot + 'objects/' + this.model.id,
				type: 'DELETE',
				headers: headers
			}).then(this.remove.bind(this))
		} else {
			this.destroyLink()
		}
	},

	destroyLink: function() {
		this.remove()
		this.model.destroy()
	},

	moveUp: function() {
		// Move child down up space in order
		var model = this.model
		var collection = this.model.collection

		var index = collection.indexOf(model)
		if (index > 0) {
			// silence this to stop excess event triggers
			collection.remove(model, {silent: true})
			collection.add(model, {
				at: index - 1,
				silent: true
			})

			collection.trigger('reset')
		}

		// Parent needs to be sent to server
		model.collection.parent.needsSaving = true
	},

	moveDown: function() {
		// Move child down one space in order
		var model = this.model
		var collection = this.model.collection

		var index = collection.indexOf(model)
		if (index < collection.models.length) {
			// silence this to stop excess event triggers
			collection.remove(model, {silent: true})
			collection.add(model, {
				at: index + 1,
				silent: true
			})

			collection.trigger('reset')
		}

		// Parent needs to be sent to server
		model.collection.parent.needsSaving = true
	},

	altChange: function(e) {
		var val = $(e.currentTarget).val()
		this.model.set('image_alt..content..' + this.currentLang, val)
		this.changeRequired()
	},

	analyticsChange: function(e) {
		var val = $(e.currentTarget).val()
		this.model.set('analytics_event', val)
		this.changeRequired()
	},

	changeRequired: function() {
		this.model.collection.parent.needsSaving = true
		this.model.collection.parent.needsStructureUpdate = true
	}
})
