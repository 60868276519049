module.exports = {
	/**
	 * @param {number} systemId ID of the system to retrieve tags for.
	 * @returns {Array.<string>} The list of default page tags for the
	 *     specified system.
	 */
	get: function() {
		if (Storm.pageTags && Storm.pageTags !== []) {
			return Storm.pageTags
		}

		// else return this default list
		return [
			{
				colour: '#63A439',
				name: 'Learn',
				type: 'pages'
			},
			{
				colour: '#ff9900',
				name: 'Prepare',
				type: 'pages'
			},
			{
				colour: '#cc3300',
				name: 'Emergency',
				type: 'pages'
			},
			{
				colour: '#0099ff',
				name: 'Tests',
				type: 'pages'
			},
			{
				colour: '#ff99ff',
				name: 'Info',
				type: 'pages'
			},
			{
				colour: '#000000',
				name: 'Other',
				type: 'pages'
			}
		]
	}
}
