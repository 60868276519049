<div class="row">
	<div class="col-sm-6">
		<h3>{{t "plugins.availablePlugins"}}</h3>
	</div>
	<div class="col-sm-6 actions-col">
		<button class="button green save-button" data-i18n="common.save"></button>
	</div>
</div>
<div class="row">
	<form class="col-xs-12">
		<fieldset>
			<table>
				{{#each plugins}}
					<tr>
						<td style="width:1px"><input type="checkbox" class="storm-checkbox plugin-enabled" data-id="{{id}}" {{#if enabled}}checked{{/if}}></td>
						<td>{{name}}</td>
					</tr>
				{{/each}}
			</table>
		</fieldset>
	</form>
</div>
