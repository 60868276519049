var MediaLibraryItemView = require('./media-library-item-view'),
	utils = require('lib/utils')

module.exports = MediaLibraryItemView.extend({
	className: 'image-list-item video file',

	afterInitialize: function() {
		this.events['click video'] = 'videoClick'
	},

	afterRender: function() {
		var url = utils.getRemoteUrl(this.model.get('filename'))
		this.$('.image-backdrop').html('<video src="' + url + '" controls preload="none"></video>')
	},

	videoClick: function(e) {
		e.stopPropagation()
	}
})
