var RolesPermissions = require('./role-permissions'),
	Permissions = require('./permissions-structure'),
	PermissionsParser = require('./permissions-parser')

module.exports = Backbone.View.extend({
	template: require('./role-permissions-view-template'),

	events: {
		'change input': 'inputChange',
		'click .save': 'save'
	},

	initialize: function(options) {
		this.selectedPermissions = {}

		this.id = options.id

		// Fetch permissions for this role
		this.model = new RolesPermissions({id: this.id})
		this.model.once('sync', this.ready, this)
		this.model.fetch()
	},

	getRenderData: function() {
		return {
			permissions: Permissions,
			appId: App.getCurrentApp().id
		}
	},

	afterRender: function() {
		var self = this

		// Set current permission values
		$.each(this.selectedPermissions, function(group, tabs) {
			var makeClassName = Handlebars.helpers.makeClassName

			$.each(tabs, function(tab, value) {
				self.$('input[name=' + makeClassName(group) + '-' + makeClassName(tab) + '][value=' + value + ']').prop('checked', true)
			})
		})

		// Lock editing if no write permission
		if (!App.acl.hasWritePermission('Users', 'Role management')) {
			this.$('input').prop('disabled', true)
			this.$('.save').hide()
		}
	},

	ready: function() {
		// Work out current permissions
		this.selectedPermissions = PermissionsParser.parse(this.model.toJSON())

		this.render()
		App.stopLoad()
	},

	inputChange: function(e) {
		var input = $(e.currentTarget)
		this.selectedPermissions[input.data('group')][input.data('tab')] = input.val()
	},

	save: function() {
		var newPermissions = {}
		App.startLoad()

		// Iterate through each navigation group (missions, users etc)
		$.each(this.selectedPermissions, function(group, tabs) {
			// Iterate through each sub section of the groups (mission templates, active missions)
			$.each(tabs, function(tab, action) {
				// Copy required permissions for specified read/write action
				if (action) {
					// Iterate through each API controller
					$.each(Permissions[group][tab][action], function(controller, methods) {
						newPermissions[controller] = newPermissions[controller] || {}

						// Iterate through each controller method for this section
						$.each(methods, function(method, val) {
							newPermissions[controller][method] = newPermissions[controller][method] || val
						})
					})
				}
			})
		})

		// Save permissions back to server
		var id = this.model.id
		this.model.clear()
		this.model.save({
			id: id,
			permissions: newPermissions
		})

		this.model.once('sync', function() {
			// Return to roles screen
			App.router.navigate('/roles', {trigger: true})
		})
	}
})
