var DevPoolList         = require('./dev-pool-details-list'),
	DevPoolListItemView = require('./dev-pool-list-item-view'),
	GDPCSectionView     = require('./gdpc-section-view')

/**
 * Exports {@link DevPoolListView}.
 * @module
 */
module.exports = GDPCSectionView.extend(/** @lends DevPoolListView.prototype */{
	/** @override */
	template: require('./dev-pool-list-view-template'),

	/**
	 * @constructs DevPoolListView
	 * @extends GDPCSectionView
	 * @override
	 * @classdesc Defines a view to list all dev pools in the system, and the
	 *     status of each app within that dev pool in a grid of traffic lights.
	 *     This view should only be used for GDPC Admin and NS Admin roles.
	 *     Other roles should use {@link SocietyStatusView} instead.
	 */
	initialize: function(options) {
		this.listViews = []
		this.appId = options.appId

		this.collection = new DevPoolList()
		this.collection.once('sync', this.ready, this)
		this.collection.fetch()
	},

	/** @override */
	getPageTitle: function() {
		return $.t('gdpcConsole.devPools.title')
	},

	ready: function() {
		this.collection.each(function(pool) {
			// Find society object for this app
			var view = new DevPoolListItemView({model: pool, appId: this.appId})

			this.addView(view)
			this.$('.list-items').append(view.render().el)
		}, this)

		App.stopLoad()
	},

	/** @override */
	getRenderData: function() {
		return {appId: this.appId}
	},

	/** @override */
	afterRender: function() {
		if (!App.acl.hasWritePermission('GDPC Console', 'Dev Pools')) {
			this.$('.add').hide()
		}
	}
})
