var ListView = require('list-view/list-view'),
	EditorSectionView = require('editor/editor-section-view')

var BadgeListView = ListView.extend({
	template: require('./badge-list-view-template'),
	list: require('models/stormql'),
	listItem: require('./badge-list-item-view')
})

/**
 * Exports {@link BadgesView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends BadgesView.prototype */{
	/** @override */
	className: 'badges',
	/** @override */
	template: require('./badges-view-template'),

	/**
	 * @constructs BadgesView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)
		App.startLoad()

		this.app = App.appList.get(options.appId)

		this.views = {
			list: new BadgeListView({
				fetchData: {data: {class: 'Badge'}},
				app: this.app
			})
		}
	},

	/** @override */
	getPageTitle: function() {
		return $.t('badges.editBadge')
	},

	/** @override */
	getRenderData: function() {
		return {
			appId: this.app.id
		}
	},

	/** @override */
	afterRender: function() {
		if (!App.acl.hasWritePermission('Badges')) {
			this.$('.add').hide()
		}
	}
})
