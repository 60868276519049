module.exports = {
	loaded: false,
	callback: null,

	init: function(callback) {
		this.callback = callback

		// Inject Google Maps API, once only.
		if (!this.isGoogleMapsInjected()) {
			this.injectGoogleMaps()
		} else {
			this.googleMapsCallback()
		}
	},

	isGoogleMapsInjected: function() {
		return 'google' in window
	},

	injectGoogleMaps: function() {
		var script = '<script id="google-maps-api" src="//maps.googleapis.com/maps/api/js?key=AIzaSyBZoX1MZL_QP1UbJdab6u_SXkzBs1J71mU&libraries=drawing&callback=googleMapsInit"></script>'
		$('head').append(script)

		window.googleMapsInit = this.googleMapsCallback.bind(this)
	},

	googleMapsCallback: function() {
		this.loaded = true

		if (this.callback) {
			this.callback()
			this.callback = null
		}
	},

	getOptions: function() {
		return {
			center: new google.maps.LatLng(0, 0),
			zoom: 5,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			zoomControlOptions: {position: google.maps.ControlPosition.LEFT_CENTER},
			mapTypeControlOptions: {position: google.maps.ControlPosition.BOTTOM_LEFT},
			panControlOptions: {position: google.maps.ControlPosition.LEFT_CENTER}
		}
	}
}
