var Level = require('./level')

module.exports = Backbone.Collection.extend({
	model: Level,

	initialize: function(models, options) {
		this.appId = options.appId
	},

	url: function() {
		return App.apiRoot + 'levels'
	},

	parse: function(response) {
		// Filter out status 0 levels
		var i = 0
		while (i < response.length) {
			if (response[i].status === 0) {
				response.splice(i, 1)
			} else {
				i++
			}
		}

		return response
	},

	fetch: function(options) {
		options = options || {}
		options.data = options.data || {}
		options.data.appId = this.appId

		Backbone.Collection.prototype.fetch.call(this, options)
	}
})
