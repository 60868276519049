var Classes = require('./classes'),
	PluginList = require('./plugin-list'),
	PageList = require('./page-list/page-list'),
	LanguageList = require('./language-list'),
	Structure = require('./page-list/app-structure'),
	AuditList = require('publish/audit-list'),
	TemplateList = require('./templates/template-list'),
	PublishHistoryList = require('publish/publish-history-list'),
	NativeStrings = require('app-setup/app-strings'),
	PropertiesList = require('app-setup/app-properties')

module.exports = Backbone.DeepModel.extend({
	initialize: function() {
		this.classes        = null
		this.pluginList     = null
		this.pageList       = null
		this.languageList   = null
		this.auditList      = null
		this.structure      = null
		this.templateList   = null
		this.publishHistory = null
		this.nativeStrings  = null
		this.propertiesList = null

		if (this.id !== undefined) {
			this.idChange()
		}

		this.on('change:id', this.idChange, this)
	},

	urlRoot: function() {
		return App.apiRoot + 'apps'
	},

	// When a new app is saved, update the app ID in all child models.
	idChange: function() {
		var opts = {appId: this.id}

		this.classes        = new Classes(opts)
		this.pluginList     = new PluginList(null, opts)
		this.pageList       = new PageList(null, opts)
		this.languageList   = new LanguageList(null, opts)
		this.auditList      = new AuditList(null, opts)
		this.structure      = new Structure(opts)
		this.templateList   = new TemplateList(null, opts)
		this.publishHistory = new PublishHistoryList(null, opts)
		this.nativeStrings  = new NativeStrings(opts)
		this.propertiesList = new PropertiesList(null, opts)
	},

	// Whether the app is of First Aid type.
	isFirstAidApp: function() {
		return this.get('type') === 0
	},

	// Whether the app is of Hazards type.
	isHazardsApp: function() {
		return this.get('type') === 1
	},

	// Whether the app is of template type.
	isTemplateApp: function() {
		return this.get('type') === 2
	},

	// Whether the app is of legacy type.
	isLegacyApp: function() {
		return this.get('type') === 3
	}
})
