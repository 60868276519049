<table class="moderation-table">
    <thead>
        <tr>
            <th>{{t "mind-moderation.emoodji.singular"}}</th>
            <th>{{t "common.tools"}}</th>
        </tr>
    </thead>
    <tbody class="list-items"></tbody>
</table>

<div class="empty-table-text">{{t 'mind-moderation.empty'}}</div>
