{{partial "editor/editor-nav"}}

<div class="title-bar">
	<div class="header">
		<span>Frames</span>
		<button class="button add">Add</button>
	</div>
</div>

<div class="container pad-top">
	<div class="row">
		<div class="span12 title-header">List of frames</div>
		<div class="pagination"></div>
	</div>
	<div class="row">
		<div class="span12">

			<table class="header-float">
				<thead class="title-case">
					<tr>
						<th>{{t "common.tools"}}</th>
					</tr>
				</thead>
				<tbody class="list-items"></tbody>
			</table>
			<div class="pagination"></div>

		</div>
	</div>
</div>
