var StormQL = require('models/stormql'),
	EditorSectionView = require('editor/editor-section-view'),
	CodeGroupListItemView = require('./code-group-list-item-view')

module.exports = EditorSectionView.extend({
	className: 'codeGroup',
	template: require('./code-group-view-template'),
	// TODO this may change once plugin navigation is done.
	activeTabIndex: 4,

	initialize: function(options) {
		App.startLoad()
		var requests = []
		this.app = App.appList.get(options.appId)
		this.appId = options.appId
		// Fetch CodeGroups assigned to this app.
		this.appCodeGroups = new StormQL(null, {app: this.app})
		var appCodeGroupsFetch = this.appCodeGroups.fetch({data: {class: 'StormQLCodeGroup'}})
		requests.push(appCodeGroupsFetch)

		// Render page once all data loaded.
		Promise.all(requests).then(this.ready.bind(this))
	},

	ready: function() {
		App.stopLoad()
		this.render()
		if (this.appCodeGroups.length === 0) {
			$('#noItems').show()
			$('table').hide()
		} else {
			$('#noItems').hide()
			$('table').show()
		}
	},

	getPageTitle: function() {
		return $.t('arpro.codeGroup.codeGroup')
	},

	getRenderData: function() {
		return {
			appId: this.app.id
		}
	},

	afterRender: function() {
		this.appCodeGroups.forEach(this.addCodeGroup, this)
	},

	addCodeGroup: function(codeGroup) {
		var view = new CodeGroupListItemView({
			codeGroup: codeGroup,
			appId: this.appId
		})

		this.$('.list-items').append(view.render().el)
	}
})
