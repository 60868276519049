module.exports = [{
    "code": "AFG",
    "description": "Afghan Red Crescent"
}, {
    "code": "ALB",
    "description": "Albanian Red Cross"
}, {
    "code": "DZA",
    "description": "Algerian Red Crescent"
}, {
    "code": "USA",
    "description": "American Red Cross"
}, {
    "code": "AND",
    "description": "Andorran Red Cross"
}, {
    "code": "AGO",
    "description": "Angola Red Cross"
}, {
    "code": "ATG",
    "description": "Antigua and Barbuda Red Cross Society"
}, {
    "code": "ARG",
    "description": "Argentine Red Cross"
}, {
    "code": "ARM",
    "description": "Armenian Red Cross Society"
}, {
    "code": "AUS",
    "description": "Australian Red Cross"
}, {
    "code": "AUT",
    "description": "Austrian Red Cross"
}, {
    "code": "BHR",
    "description": "Bahrain Red Crescent Society"
}, {
    "code": "BGD",
    "description": "Bangladesh Red Crescent Society"
}, {
    "code": "SWZ",
    "description": "Baphalali Swaziland Red Cross Society"
}, {
    "code": "BLR",
    "description": "Belarus Red Cross"
}, {
    "code": "BEL",
    "description": "Belgian Red Cross"
}, {
    "code": "BLZ",
    "description": "Belize Red Cross Society"
}, {
    "code": "BOL",
    "description": "Bolivian Red Cross"
}, {
    "code": "BWA",
    "description": "Botswana Red Cross Society"
}, {
    "code": "BRA",
    "description": "Brazilian Red Cross"
}, {
    "code": "GBR",
    "description": "British Red Cross"
}, {
    "code": "BRN",
    "description": "Brunei Darussalam Red Crescent Society"
}, {
    "code": "BGR",
    "description": "Bulgarian Red Cross"
}, {
    "code": "BFA",
    "description": "Burkinabe Red Cross Society"
}, {
    "code": "BDI",
    "description": "Burundi Red Cross"
}, {
    "code": "KHM",
    "description": "Cambodian Red Cross Society"
}, {
    "code": "CMR",
    "description": "Cameroon Red Cross Society"
}, {
    "code": "CAF",
    "description": "Central African Red Cross Society"
}, {
    "code": "CHL",
    "description": "Chilean Red Cross"
}, {
    "code": "COL",
    "description": "Colombian Red Cross Society"
}, {
    "code": "COG",
    "description": "Congolese Red Cross"
}, {
    "code": "COK",
    "description": "Cook Islands Red Cross Society"
}, {
    "code": "CRI",
    "description": "Costa Rican Red Cross"
}, {
    "code": "HRV",
    "description": "Croatian Red Cross"
}, {
    "code": "CUB",
    "description": "Cuban Red Cross"
}, {
    "code": "CYP",
    "description": "Cyprus Red Cross Society"
}, {
    "code": "CZE",
    "description": "Czech Red Cross"
}, {
    "code": "DNK",
    "description": "Danish Red Cross"
}, {
    "code": "DMA",
    "description": "Dominica Red Cross Society"
}, {
    "code": "DOM",
    "description": "Dominican Red Cross"
}, {
    "code": "ECU",
    "description": "Ecuadorian Red Cross"
}, {
    "code": "EGY",
    "description": "Egyptian Red Crescent Society"
}, {
    "code": "EST",
    "description": "Estonia Red Cross"
}, {
    "code": "ETH",
    "description": "Ethiopian Red Cross Society"
}, {
    "code": "FJI",
    "description": "Fiji Red Cross Society"
}, {
    "code": "FIN",
    "description": "Finnish Red Cross"
}, {
    "code": "FRA",
    "description": "French Red Cross"
}, {
    "code": "GAB",
    "description": "Gabonese Red Cross Society"
}, {
    "code": "DEU",
    "description": "German Red Cross"
}, {
    "code": "GHA",
    "description": "Ghana Red Cross Society"
}, {
    "code": "GRD",
    "description": "Grenada Red Cross Society"
}, {
    "code": "GTM",
    "description": "Guatemalan Red Cross"
}, {
    "code": "HTI",
    "description": "Haiti Red Cross Society"
}, {
    "code": "GRC",
    "description": "Hellenic Red Cross"
}, {
    "code": "HND",
    "description": "Honduran Red Cross"
}, {
    "code": "HUN",
    "description": "Hungarian Red Cross"
}, {
    "code": "ISL",
    "description": "Icelandic Red Cross"
}, {
    "code": "IND",
    "description": "Indian Red Cross Society"
}, {
    "code": "IDN",
    "description": "Indonesian Red Cross Society"
}, {
    "code": "IRQ",
    "description": "Iraqi Red Crescent Society"
}, {
    "code": "IRL",
    "description": "Irish Red Cross Society"
}, {
    "code": "ISR",
    "description": "Israel - Magen David Adom in Israel"
}, {
    "code": "ITA",
    "description": "Italian Red Cross"
}, {
    "code": "JAM",
    "description": "Jamaica Red Cross"
}, {
    "code": "JPN",
    "description": "Japanese Red Cross Society"
}, {
    "code": "JOR",
    "description": "Jordan National Red Crescent Society"
}, {
    "code": "KAZ",
    "description": "Kazakh Red Crescent"
}, {
    "code": "KEN",
    "description": "Kenya Red Cross Society"
}, {
    "code": "KIR",
    "description": "Kiribati Red Cross Society"
}, {
    "code": "KWT",
    "description": "Kuwait Red Crescent Society"
}, {
    "code": "LAO",
    "description": "Lao Red Cross"
}, {
    "code": "LVA",
    "description": "Latvian Red Cross"
}, {
    "code": "LBN",
    "description": "Lebanese Red Cross"
}, {
    "code": "LSO",
    "description": "Lesotho Red Cross Society"
}, {
    "code": "LBR",
    "description": "Liberian Red Cross Society"
}, {
    "code": "LBY",
    "description": "Libyan Red Crescent"
}, {
    "code": "LIE",
    "description": "Liechtenstein Red Cross"
}, {
    "code": "LTU",
    "description": "Lithuanian Red Cross Society"
}, {
    "code": "LUX",
    "description": "Luxembourg Red Cross"
}, {
    "code": "MDG",
    "description": "Malagasy Red Cross Society"
}, {
    "code": "MWI",
    "description": "Malawi Red Cross Society"
}, {
    "code": "MYS",
    "description": "Malaysian Red Crescent Society"
}, {
    "code": "MDV",
    "description": "Maldivian Red Crescent"
}, {
    "code": "MLI",
    "description": "Mali Red Cross"
}, {
    "code": "MLT",
    "description": "Malta Red Cross Society"
}, {
    "code": "MRT",
    "description": "Mauritanian Red Crescent"
}, {
    "code": "MUS",
    "description": "Mauritius Red Cross Society"
}, {
    "code": "MEX",
    "description": "Mexican Red Cross"
}, {
    "code": "FSM",
    "description": "Micronesia Red Cross"
}, {
    "code": "MNG",
    "description": "Mongolian Red Cross Society"
}, {
    "code": "MAR",
    "description": "Moroccan Red Crescent"
}, {
    "code": "MOZ",
    "description": "Mozambique Red Cross Society"
}, {
    "code": "MMR",
    "description": "Myanmar Red Cross Society"
}, {
    "code": "NAM",
    "description": "Namibia Red Cross"
}, {
    "code": "NPL",
    "description": "Nepal Red Cross Society"
}, {
    "code": "NZL",
    "description": "New Zealand Red Cross"
}, {
    "code": "NIC",
    "description": "Nicaraguan Red Cross"
}, {
    "code": "NGA",
    "description": "Nigerian Red Cross Society"
}, {
    "code": "NOR",
    "description": "Norwegian Red Cross"
}, {
    "code": "PAK",
    "description": "Pakistan Red Crescent Society"
}, {
    "code": "PLW",
    "description": "Palau Red Cross Society"
}, {
    "code": "PNG",
    "description": "Papua New Guinea Red Cross Society"
}, {
    "code": "PRY",
    "description": "Paraguayan Red Cross"
}, {
    "code": "PER",
    "description": "Peruvian Red Cross"
}, {
    "code": "POL",
    "description": "Polish Red Cross"
}, {
    "code": "PRT",
    "description": "Portuguese Red Cross"
}, {
    "code": "QAT",
    "description": "Qatar Red Crescent Society"
}, {
    "code": "AZE",
    "description": "Red Crescent Society of Azerbaijan"
}, {
    "code": "DJI",
    "description": "Red Crescent Society of Djibouti"
}, {
    "code": "KGZ",
    "description": "Red Crescent Society of Kyrgyzstan"
}, {
    "code": "TJK",
    "description": "Red Crescent Society of Tajikistan"
}, {
    "code": "IRN",
    "description": "Red Crescent Society of the Islamic Republic of Iran"
}, {
    "code": "ARE",
    "description": "Red Crescent Society of the United Arab Emirates"
}, {
    "code": "TKM",
    "description": "Red Crescent Society of Turkmenistan"
}, {
    "code": "UZB",
    "description": "Red Crescent Society of Uzbekistan"
}, {
    "code": "BEN",
    "description": "Red Cross of Benin"
}, {
    "code": "CPV",
    "description": "Red Cross of Cape Verde"
}, {
    "code": "TCD",
    "description": "Red Cross of Chad"
}, {
    "code": "GNQ",
    "description": "Red Cross of Equatorial Guinea"
}, {
    "code": "MCO",
    "description": "Red Cross of Monaco"
}, {
    "code": "MNE",
    "description": "Red Cross of Montenegro"
}, {
    "code": "COD",
    "description": "Red Cross of the Democratic Republic of the Congo"
}, {
    "code": "SMR",
    "description": "Red Cross of the Republic of San Marino"
}, {
    "code": "VNM",
    "description": "Red Cross of Viet Nam"
}, {
    "code": "CHN",
    "description": "Red Cross Society of China"
}, {
    "code": "CIV",
    "description": "Red Cross Society of Côte d'Ivoire"
}, {
    "code": "GEO",
    "description": "Red Cross Society of Georgia"
}, {
    "code": "GIN",
    "description": "Red Cross Society of Guinea"
}, {
    "code": "GNB",
    "description": "Red Cross Society of Guinea-Bissau"
}, {
    "code": "NGA",
    "description": "Red Cross Society of Niger"
}, {
    "code": "PAN",
    "description": "Red Cross Society of Panama"
}, {
    "code": "PRK",
    "description": "Red Cross Society of the Democratic People's Republic of Korea"
}, {
    "code": "MDA",
    "description": "Red Cross Society of the Republic of Moldova"
}, {
    "code": "ROU",
    "description": "Romanian Red Cross"
}, {
    "code": "RWA",
    "description": "Rwandan Red Cross"
}, {
    "code": "KNA",
    "description": "Saint Kitts and Nevis Red Cross Society"
}, {
    "code": "LCA",
    "description": "Saint Lucia Red Cross"
}, {
    "code": "VCT",
    "description": "Saint Vincent and the Grenadines Red Cross"
}, {
    "code": "SLV",
    "description": "Salvadorean Red Cross Society"
}, {
    "code": "WSM",
    "description": "Samoa Red Cross Society"
}, {
    "code": "STP",
    "description": "Sao Tome and Principe Red Cross"
}, {
    "code": "SAU",
    "description": "Saudi Red Crescent Authority"
}, {
    "code": "SEN",
    "description": "Senegalese Red Cross Society"
}, {
    "code": "SYC",
    "description": "Seychelles Red Cross Society"
}, {
    "code": "SLE",
    "description": "Sierra Leone Red Cross Society"
}, {
    "code": "SGP",
    "description": "Singapore Red Cross Society"
}, {
    "code": "SVK",
    "description": "Slovak Red Cross"
}, {
    "code": "SVN",
    "description": "Slovenian Red Cross"
}, {
    "code": "SOM",
    "description": "Somali Red Crescent Society"
}, {
    "code": "SSD",
    "description": "South Sudan Red Cross"
}, {
    "code": "ESP",
    "description": "Spanish Red Cross"
}, {
    "code": "SUR",
    "description": "Suriname Red Cross"
}, {
    "code": "SWE",
    "description": "Swedish Red Cross"
}, {
    "code": "CHE",
    "description": "Swiss Red Cross"
}, {
    "code": "SYR",
    "description": "Syrian Arab Red Crescent"
}, {
    "code": "TZA",
    "description": "Tanzania Red Cross National Society"
}, {
    "code": "BHS",
    "description": "The Bahamas Red Cross Society"
}, {
    "code": "BRB",
    "description": "The Barbados Red Cross Society"
}, {
    "code": "CAN",
    "description": "The Canadian Red Cross Society"
}, {
    "code": "COM",
    "description": "The Comoros Red Crescent"
}, {
    "code": "GMB",
    "description": "The Gambia Red Cross Society"
}, {
    "code": "GUY",
    "description": "The Guyana Red Cross Society"
}, {
    "code": "NLD",
    "description": "The Netherlands Red Cross"
}, {
    "code": "PSE",
    "description": "The Palestine Red Crescent Society"
}, {
    "code": "PHL",
    "description": "The Philippine Red Cross"
}, {
    "code": "SRB",
    "description": "The Red Cross of Serbia"
}, {
    "code": "MKD",
    "description": "The Red Cross of The Former Yugoslav Republic of Macedonia"
}, {
    "code": "BIH",
    "description": "The Red Cross Society of Bosnia and Herzegovina"
}, {
    "code": "KOR",
    "description": "The Republic of Korea National Red Cross"
}, {
    "code": "RUS",
    "description": "The Russian Red Cross Society"
}, {
    "code": "SLB",
    "description": "The Solomon Islands Red Cross"
}, {
    "code": "ZAF",
    "description": "The South African Red Cross Society"
}, {
    "code": "LKA",
    "description": "The Sri Lanka Red Cross Society"
}, {
    "code": "SDN",
    "description": "The Sudanese Red Crescent"
}, {
    "code": "THA",
    "description": "The Thai Red Cross Society"
}, {
    "code": "TTO",
    "description": "The Trinidad and Tobago Red Cross Society"
}, {
    "code": "UGA",
    "description": "The Uganda Red Cross Society"
}, {
    "code": "TLS",
    "description": "Timor Leste Red Cross Society"
}, {
    "code": "TGO",
    "description": "Togolese Red Cross"
}, {
    "code": "TON",
    "description": "Tonga Red Cross Society"
}, {
    "code": "TUN",
    "description": "Tunisian Red Crescent"
}, {
    "code": "TUR",
    "description": "Turkish Red Crescent Society"
}, {
    "code": "UKR",
    "description": "Ukrainian Red Cross Society"
}, {
    "code": "URY",
    "description": "Uruguayan Red Cross"
}, {
    "code": "VUT",
    "description": "Vanuatu Red Cross Society"
}, {
    "code": "VEN",
    "description": "Venezuelan Red Cross"
}, {
    "code": "YEM",
    "description": "Yemen Red Crescent Society"
}, {
    "code": "ZMB",
    "description": "Zambia Red Cross Society"
}, {
    "code": "ZWE",
    "description": "Zimbabwe Red Cross Society"
}]
