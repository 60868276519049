var ListViewItem = require('list-view/list-view-item')

module.exports = ListViewItem.extend({
	template: require('./moderation-item-view-template'),

	afterInitialize: function() {
		// this.events['click .approve'] = 'approve'
		this.events['click .reject'] = 'reject'
		this.delegateEvents()
	},

	getRenderData: function() {
		return this.model.toJSON()
	},

	/* approve: function() {
		this.model.save({active: true}, {globalAuth: true, patch: true})
		this.destroy()
	}, */

	reject: function() {
		this.model.save({active: false}, {globalAuth: true, patch: true})
		this.destroy()
	}
})
