<img class="info-window-icon" src="/images/hazards/disaster-icons/{{categoryName}}.png">
<h4>{{getBrowserLocaleText eventName}}</h4>
<div>{{t "hazards.alerts.effective"}} {{formatDateTime timestamps.effective}}, {{t "hazards.alerts.issuedBy"}} {{toUpperCase feed}}</div>

<table style="margin-bottom: 0;">
    <tr>
        <td>{{t "hazards.alerts.custom.notify"}}</td>
        <td class="narrow text-center">
            {{#if options.notify}}
                <i class="icon-ok !icon-2x" />
            {{else}}
                <i class="icon-remove !icon-2x" />
            {{/if}}
        </td>
    </tr>
    <tr>
        <td>{{t "hazards.alerts.custom.pushes"}}</td>
        <td class="narrow text-center">{{pushes}}</td>
    </tr>
    <tr>
        <td>{{t "agreement.status"}}</td>
        <td class="narrow text-center">
            {{#ifDateIsInPast timestamps.expires}}
                <span class="status expired">{{t "hazards.alerts.expired"}}</span>
            {{else}}
                <span class="status active">{{t "hazards.alerts.alertActive"}}</span>
            {{/ifDateIsInPast}}
        </td>
    </tr>
</table>
