<div class="row">
	<div class="map-overlay"><span>{{t "common.clickToBrowseMap"}}</span></div>
	<div class="map google-maps"></div>

	<div class="col-xs-6 alerts-filters">
		<label>
			{{t "hazards.alerts.inTheLast"}}:&nbsp;
			<select class="form-control date-filter">
				<option value="">{{t "hazards.alerts.times.allTime"}}</option>
				<option value="hour">{{t "hazards.alerts.times.hour"}}</option>
				<option value="day">{{t "hazards.alerts.times.day"}}</option>
				<option value="week">{{t "hazards.alerts.times.week"}}</option>
				<option value="month">{{t "hazards.alerts.times.month"}}</option>
			</select>
		</label>
		<label>{{t "hazards.alerts.show"}}: <select class="form-control event-filter"><option value="">{{t "hazards.alerts.allAlerts"}}</option></select></label>
	</div>
	<div class="col-xs-6 actions-col">
		<a href="/apps/{{appId}}/alerts/new" class="create-alert-button"><button class="button green">{{t "hazards.alerts.createNewAlert"}}</button></a>
		<a href="/apps/{{appId}}/alerts/test" class="test-alert-button"><button class="button">{{t "hazards.alerts.testAlert"}}</button></a>
	</div>
</div>
