{{partial "bugs/bugs-nav"}}

<div class="title-bar">
	<div class="header">
		<a href="/bugs"><span class="back-button"></span></a>
		<span>{{t "bugs.reportBug"}}</span>
	</div>
</div>

<div class="container pad-top bugs">
	<div class="row">
		<div class="span12">

			<form class="form-horizontal">
				<fieldset>

					<legend>{{t "bugs.platformDetails"}}</legend>

					<div class="control-group">
						<label class="control-label" for="bug-app">{{t "apps.app"}}</label>
						<div class="controls">
							<select id="bug-app" class="input-large">
								<option>Frontend</option>
							</select>
						</div>
					</div>

					<div class="control-group">
						<label class="control-label" for="bug-os-device">{{t "bugs.device"}}</label>
						<div class="controls">
							<input type="text" id="bug-os-device" class="input-large">
						</div>
					</div>

					<div class="app-details">
						<div class="control-group">
							<label class="control-label" for="bug-app-version">{{t "bugs.version"}}</label>
							<div class="controls">
								<input type="text" placeholder="1.0.0" id="bug-app-version" class="input-large">
							</div>
						</div>

						<div class="control-group">
							<label class="control-label" for="bug-app-build">{{t "bugs.build"}}</label>
							<div class="controls">
								<input type="text" id="bug-app-build" class="input-large">
							</div>
						</div>
					</div>

					<legend>{{t "bugs.issueDetails"}}</legend>

					<div class="control-group">
						<label class="control-label" for="bug-description">{{t "common.description"}}</label>
						<div class="controls">
							<textarea id="bug-description" class="input-xxlarge"></textarea>
						</div>
					</div>

					<div class="control-group">
						<label class="control-label" for="bug-steps">{{t "bugs.steps"}}</label>
						<div class="controls">
							<textarea id="bug-steps" class="input-xxlarge"></textarea>
						</div>
					</div>

					<div class="control-group">
						<label class="control-label" for="bug-expected">{{t "bugs.expected"}}</label>
						<div class="controls">
							<textarea id="bug-expected" class="input-xxlarge"></textarea>
						</div>
					</div>

					<div class="control-group">
						<label class="control-label" for="bug-actually">{{t "bugs.actually"}}</label>
						<div class="controls">
							<textarea id="bug-actually" class="input-xxlarge"></textarea>
						</div>
					</div>

					<div class="form-actions">
						<button type="button" class="button positive submit-button">{{t "common.submit"}}</button>
					</div>

				</fieldset>
			</form>

		</div>
	</div>
</div>
