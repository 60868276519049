<div class="row">
	<div class="col-xs-12">
		<div class="pagination"></div>
		<table class="moderation-table">
			<thead>
				<tr>
					<th style="width:100px">{{t "mediaLibrary.image"}}</th>
					<th>{{t "common.description"}}</th>
					<th style="width:1px">{{t "moderation.image.location"}}</th>
					<th>{{t "agreement.status"}}</th>
					<th>{{t "common.tools"}}</th>
				</tr>
			</thead>
			<tbody class="list-items"></tbody>
		</table>
		<div class="pagination"></div>
	</div>
	<div class="empty-table-text">No images to moderate</div>
</div>
