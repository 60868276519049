{{partial "admin/admin-nav"}}

<div class="title-bar">
	<div class="header">
		<span>{{t "mailingList.title"}}</span>
	</div>
</div>

<div class="container pad-top">
	<div class="row">
		<div class="span12 title-header">{{t "mailingList.list"}}</div>
	</div>
	<div class="row">
		<div class="span12">

			<label for="export-from-date">{{t "mailingList.dateFrom"}}</label>
			<input type="text" id="export-from-date">
			<label for="export-to-date">{{t "mailingList.dateTo"}}</label>
			<input type="text" id="export-to-date">

			<table class="header-float">
				<thead>
					<tr>
						<th>{{t "users.name"}}</th>
						<th>{{t "common.tools"}}</th>
					</tr>
				</thead>
				<tbody class="list-items">
					{{#each collection}}
						<tr>
							<td>{{name}}</td>
							<td class="tools" style="width:1px">
								<button class="button export-button" data-id="{{id}}"><i class="icon-download"></i> {{t "mailingList.export"}}</button>
							</td>
						</tr>
					{{/each}}
				</tbody>
			</table>

		</div>
	</div>
</div>
