var StormView 				 = require('lib/storm-view'),
	AppSetupSectionView  = require('app-setup/app-setup-section-view'),
	EditorSectionView    = require('editor/editor-section-view'),
	HazardsSectionView   = require('hazards/hazards-section-view'),
	ManageSectionView    = require('manage/manage-section-view'),
	PublishSectionView   = require('publish/publish-section-view'),
	DashboardSectionView = require('dashboard/dashboard-section-view'),
	GDPCSectionView      = require('gdpc-console/gdpc-section-view'),
	globals              = require('globals')

module.exports = StormView.extend({
	template: require('./no-apps-view-template'),

	/** @type {Array.<StormView>} */
	sections: [
		new EditorSectionView(),
		new AppSetupSectionView(),
		new HazardsSectionView(),
		new ManageSectionView(),
		new PublishSectionView(),
		new DashboardSectionView(),
		new GDPCSectionView()
	],

	getRenderData: function() {
		return {
			showAddApp: globals.getAcl().hasAccess('App Setup', 'App setup'),
			sections: this.getSections_()
		}
	},

	afterRender: function() {
		App.stopLoad()
	},

	getPageTitle: function() {
		return ''
	},

	getSections_: function() {
		return this.sections.filter(function(section) {
			return section.isVisible()
		}).map(function(section) {
			return {
				name: section.getSectionTitle(),
				pages: section.getVisiblePages()
			}
		})
	}
})
