module.exports = Backbone.Collection.extend({

	initialize: function(options) {
		this.appId = options.appId
	},

	url: function() {
		return App.apiRoot + 'apps/' + this.appId + '/stormql?class=StormQLTest'
	}
})
