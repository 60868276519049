<form class="row">
	<div class="col-xs-12">
		<div class="row">
			<div class="col-sm-6">
				<h3 data-i18n="crossBorders.contact.editItem"></h3>
			</div>
			<div class="col-sm-6 actions-col">
				<a href="javascript:history.back()"><button class="button red wide" type="button">{{t "common.cancel"}}</button></a>
				<button class="button green wide save" type="button">{{t "common.save"}}</button>
			</div>
		</div>
	</div>

    <div class="col-sm-6">
		<div class="form-group">
			<label class="control-label" for="field-number">{{t "crossBorders.contact.number"}}</label>
        </div>

        <div class="form-group">
				<input type="text" class="form-control" name="number" id="field-number" value="{{number}}">
			</div>
		</div>
	</div>

	<div class="col-sm-6">
		<div class="form-group">
			<label class="control-label">{{t "crossBorders.contact.title"}}</label>
		</div>
		{{#each languages}}
			<div class="form-group">
				<div class="input-group">
					<span class="input-group-addon">{{name}}</span>
					<input type="text" class="title-input form-control" data-code="{{code}}">
				</div>
			</div>
		{{/each}}
	</div>

</form>

<div class="row child-views">

</div>
