var StandaloneStormObject = require('editor/standalone-storm-object'),
	MediaLibrary = require('media-library/media-library-view'),
	MediaSelectorView = require('media-library/media-selector-view'),
	EditorSectionView = require('editor/editor-section-view'),
	utils = require('lib/utils'),
	TriggerListView = require('./trigger-list-view')

/**
 * Exports {@link AchievementEditView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends AchievementEditView.prototype */{
	/** @override */
	className: 'achievements',
	/** @override */
	template: require('./achievement-edit-view-template'),

	/** @override */
	events: {
		'click .save': 'save',
		'click .choose-image-button': 'chooseImage'
	},

	/**
	 * @constructs AchievementEditView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)

		if (!options.app) {
			throw new Error('No app specified')
		}

		this.views = {}

		/** @private {App} */
		this.app_ = options.app

		var promises = []

		// Fetch enabled languages for this app.
		promises.push(this.app_.languageList.fetchOnce())

		if (options.id !== 'new') {
			this.model = StandaloneStormObject.fromProperties({id: options.id})
			this.model.once('sync', this.ready, this)
			promises.push(this.model.fetch())
		} else {
			this.model = StandaloneStormObject.fromClassName('Achievement')
		}

		this.listenTo(this.model, 'change:badge', this.updateBadge, this)

		Promise.all(promises)
			.then(function() {
				this.triggerList = new TriggerListView({
					app: this.app_,
					collection: this.model.get('triggers')
				})
			}.bind(this))
			.then(App.stopLoad)
			.then(this.render.bind(this))
	},

	/** @override */
	getPageTitle: function() {
		return $.t('achievements.title')
	},

	/** @override */
	getRenderData: function() {
		var data = this.model.toJSON()

		data.appId = this.app_.id
		data.languages = this.app_.languageList.toJSON()

		return data
	},

	/** @override */
	afterRender: function() {
		this.delegateEvents()

		var achievement = this.model

		;['title', 'description', 'completion', 'share-text', 'share-link-title'].forEach(function(fieldName) {
			_.each(achievement.get(fieldName.replace(/-/g, '..') + '..content'), function(val, lang) {
				this.$('.' + fieldName + '-input[data-code=' + lang + ']').val(val)
			})
		})

		if (this.triggerList) {
			$('#triggers-view').append(this.triggerList.render().el)
		}
	},

	save: function(e) {
		e.preventDefault()
		App.startLoad()

		this.model.once('sync', function() {
			App.router.navigate('/apps/' + this.app_.id + '/achievements', {trigger: true})
		}, this)

		var titles = {},
			descriptions = {},
			completions = {},
			shareTexts = {},
			linkTitles = {}

		this.$('.title-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				titles[code] = value
			}
		})

		this.$('.description-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				descriptions[code] = value
			}
		})

		this.$('.completion-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				completions[code] = value
			}
		})

		this.$('.share-text-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				shareTexts[code] = value
			}
		})

		this.$('.share-link-title-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				linkTitles[code] = value
			}
		})

		this.model.set('title..content', titles)
		this.model.set('description..content', descriptions)
		this.model.set('completion..content', completions)
		this.model.set('share..text..content', shareTexts)
		this.model.set('share..link..title..content', linkTitles)
		this.model.set('share..link..destination', $('.share-link-destination-input').val())

		this.model.save(null, {appId: this.app_.id})
	},

	chooseImage: function() {
		this.views.mediaLibrary = new MediaSelectorView({
			app: this.app_,
			model: this.model.get('badge'),
			mediaType: MediaLibrary.types.IMAGE
		})

		this.views.mediaLibrary.on('change', function() {
			this.model.trigger('change:badge', this.model)
		}, this)

		$('body').append(this.views.mediaLibrary.el)
		this.views.mediaLibrary.render().show()
	},

	updateBadge: function() {
		var badge = this.model.get('badge')

		if (!badge.src) {
			badge = badge.toJSON()
		}

		this.$('img').attr('src', utils.getImagePreviewUrl(badge))
	}
})
