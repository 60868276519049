<div class="row">
	<div class="col-xs-12 actions-col">
		<a href="javascript:history.go(-1)">
			<button class="button white" data-i18n="common.cancel"></button>
		</a>
		<button class="button green save-button">{{t "common.save"}}</button>
	</div>
</div>
<div class="row">
	<div class="col-sm-4 col-sm-offset-1">
		<div class="row">
			<fieldset>
				<legend>Tip content</legend>

				<label for="tip-object-type">Type</label>
				<select id="tip-object-type" class="form-control">
					<option>Tip</option>
					<option {{#ifEqual tip.objectType "QuestionTip"}}selected{{/ifEqual}}>QuestionTip</option>
				</select>

				<label for="tip-title">Title</label>
				<input type="text" id="tip-title" class="form-control" value="{{tip.title}}">

				<label for="tip-subtitle">Subtitle</label>
				<input type="text" id="tip-subtitle" class="form-control" value="{{tip.subtitle}}">

				<label for="tip-button-title">Button title</label>
				<input type="text" id="tip-button-title" class="form-control" value="{{tip.buttonTitle}}">

				<div class="tip-content-container">
					<label for="tip-content">Content</label>
					<select id="tip-content" class="form-control">
						{{{pageSelectOptions}}}
					</select>
				</div>

				<label for="tip-display">Display</label>
				<select id="tip-display" class="form-control">
					<option>you</option>
					<option>feature</option>
					<option>feed</option>
					<option>mood</option>
				</select>
			</fieldset>
		</div>
	</div>
	<div class="col-sm-4 col-sm-offset-2">
		<div class="row">
			<fieldset>
				<legend>When?</legend>

				<label for="tip-min-mood">Min mood</label>
				<input type="number" id="tip-min-mood" class="form-control" value="{{tip.minMood}}" min="-10" max="10" step="0.1">

				<label for="tip-max-mood">Max mood</label>
				<input type="number" id="tip-max-mood" class="form-control" value="{{tip.maxMood}}" min="-10" max="10" step="0.1">

				<label for="tip-publish-date">Publish date</label>
				<input type="date" id="tip-publish-date" class="form-control">

				<label for="tip-expiry-date">Expiry date (optional)</label>
				<input type="date" id="tip-expiry-date" class="form-control">
			</fieldset>
		</div>
	</div>
	<div class="col-xs-12"></div>
	<div class="col-sm-4 col-sm-offset-1" id="answers-form">
		<div class="row">
			<form>
				<fieldset>
					<legend>Answers</legend>

					<table>
						{{#each tip.answers}}
							<tr>
								<td>{{title}}</td>
								<td class="tools">
									<i class="icon icon-trash delete-answer-button" data-index="{{@index}}"></i>
								</td>
							</tr>
						{{/each}}
						<tr>
							<td>
								<input type="text" class="add-answer-title form-control" placeholder="New answer">
							</td>
							<td class="tools">
								<button class="button green add-answer-button">{{t "common.add"}}</button>
							</td>
						</tr>
					</table>
				</fieldset>
			</form>
		</div>
	</div>
</div>
