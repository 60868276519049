var StandaloneStormObject = require('editor/standalone-storm-object'),
	EditorSectionView = require('editor/editor-section-view'),
	StormObject = require('editor/storm-object'),
	MediaLibrary = require('media-library/media-library-view'),
	AppStrings   = require('app-setup/app-strings'),
	MediaSelectorView = require('media-library/media-selector-view'),
	Maps = require('lib/maps'),
	RegionDrawView = require('hazards/region-draw-view')

/**
 * Exports {@link PlanItemEditView}.
 * @module
 *     {
					"class": "Gnah_PlanItem",
					"image": "{Image}",
					"type": "<String>",
					"title": "{Text}",
					"description": "{Text}",
					"tags": "[String]",
					"duration": "<Number>",
					"solo": "<Boolean>",
					"timestamps": "{Gnah_PlanItemTimestamps}",
					"src": "<String>",
					"weight": "<Number>",
					"months": "<Number>",
					"region": "<String>",
					"garden": "[String]",
					"code": "<String>",
					"difficulty": "<String>",
					"source": "<String>"
    },
 */
module.exports = EditorSectionView.extend(/** @lends FactFileEditView.prototype */{
	/** @override */
	className: 'FactFileEditView',
	/** @override */
	template: require('./plan-item-edit-view-template'),
	activeTabIndex: 4,

	/** @override */
	events: {
		'click .save': 'save',
		'click #addCode': 'addCode',
		'click .array-add': 'addArrayItem',
		'change .garden-check': 'addArrayItem',
		'change .text-input': 'updateText',
		'change .type-input': 'updateType',
		'change .duration-input': 'updateDuration',
		'change .difficulty-radio': 'updateDifficulty',
		'input .weighting-input': 'updateWeighting',
		'input .id-input': 'updateIdentifier',
		'input .source-input': 'updateSource',
		'input .activity-code-input': 'updateActivityCode',
		'change #solo-input': 'updateSolo',
		'change #indefinite-input': 'updateIndefinite',
		'change #page-input': 'updatePage',
		'click .delete': 'deleteArrayItem',
		'click .plan-item-image': 'changeImage',
		'click .map-button': 'mapClick'
	},

	/**
	 * @constructs CrossBordersEditView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)

		/** @private {App} */
		this.app_ = options.app

		this.model = new StandaloneStormObject()

		// Fetch enabled languages for this app.
		var languagePromise = this.app_.languageList.fetchOnce()

		this.pageList = this.app_.pageList
		var pageListFetch = this.pageList.fetch()

		// Fetch native app localisation strings
		this.localisations = new AppStrings({id: this.app_.id})
		var stringsFetch = this.localisations.fetch()

		this.model.set('area', {})
		this.listenTo(this.model, 'change:area', this.renderRegionPreview)

		var modelPromise = Promise.resolve()

		if (options.id !== 'new') {
			this.model.set('id', options.id)
			this.model.once('sync', this.ready, this)

			modelPromise = this.model.fetch()
		} else {
			this.model = StandaloneStormObject.fromClassName('Gnah_PlanItem')
		}

		this.views = []
		Promise.all([modelPromise, pageListFetch, languagePromise, stringsFetch])
			.then(App.stopLoad)
			.then(this.render.bind(this))
	},

	/** @override */
	afterRender: function() {
		this.delegateEvents()
		// Initialise Google Maps API.
		Maps.init(this.initGoogleMap.bind(this))
		this.selectMonths()
		$("#monthSelector").select2({
			placeholder: "Select a month"
		})
		// Show current input values
		// Title
		_.each(this.model.get('title..content'), function(val, key) {
			this.$('.title-input[data-code=' + key + ']').val(val)
		})

		// Description
		_.each(this.model.get('description..content'), function(val, key) {
			this.$('.description-input[data-code=' + key + ']').val(val)
		})

		// Common name
		_.each(this.model.get('seen..content'), function(val, key) {
			this.$('.seen-input[data-code=' + key + ']').val(val)
		})

		// Scientific name
		this.$('.type-input').val(this.model.get('type'))
		// identifier
		// this.$('.id-input').val(this.model.get('identifier'))
		// Source
		this.$('.source-input').val(this.model.get('source'))
		// Activity Code
		this.$('.activity-code-input').val(this.model.get('code'))
		// Duration
		this.$('.duration-input').val(this.model.get('duration'))
		// Solo
		this.$('#solo-input').attr('checked', !this.model.get('solo'))
		// Weighting
		if (this.model.get('weight') === undefined && this.model.get('weight') !== 0) {
			this.model.set('weight', 50)
		}
		this.$('#weightingValue').text(this.model.get('weight'))
		this.$('.weighting-input').val(this.model.get('weight'))

		// Garden type
		this.setupGardenValues()

		// Difficulty
		this.setupDifficultyValue()

		// Dates
		this.setupDatePickers()

		App.stopLoad()
	},

	selectMonths: function() {
		if (this.model.get('months')) {
			var monthValue = this.model.get('months');
			[11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0].forEach(function(value) {
				var power = Math.pow(2, value)
				if ((monthValue & power) === power) {
					$('#monthSelector option[value="' + value + '"]').attr('selected', true)
				}
			})
		}
	},

	initGoogleMap: function() {
		// Initialize Google map
		this.map = new google.maps.Map(this.$('.alert-preview-map')[0], {
			disableDefaultUI: true,
			center: new google.maps.LatLng(40, -93),
			zoom: 5
		})

		// Render map
		if (this.model.get('region') && this.model.get('region') !== '{}') {
			this.model.set('area', JSON.parse(this.model.get('region')))
			this.renderRegionPreview()
		}

		App.stopLoad()
	},

	// Show region draw modal on map click.
	mapClick: function() {
		if (this.views.regionDrawView) {
			this.views.regionDrawView.destroy()
		}

		this.views.regionDrawView = new RegionDrawView({model: this.model, appId: this.app_.id})

		$('body').append(this.views.regionDrawView.render().el)
		this.views.regionDrawView.show()
	},

	// Draw polygons on map preview and fit bounds.
	renderRegionPreview: function() {
		// Clear previous polygons.
		if (this.overlay) {
			this.overlay.setMap(null)
		}

		// Draw new polygons
		var bounds = new google.maps.LatLngBounds(),
			region = this.model.get('area'),
			paths = []
		region.polygon.coordinates.forEach(function(polygon) {
			var latLngs = polygon.map(function(path) {
				return path.map(function(coord) {
					var latLng = new google.maps.LatLng(coord[1], coord[0])
					bounds.extend(latLng)
					return latLng
				})
			})

			paths = paths.concat(latLngs)
		})

		// Construct the polygon.
		this.overlay = new google.maps.Polygon({
			paths: paths,
			strokeColor: '#F93A2F',
			strokeOpacity: 1,
			strokeWeight: 2,
			fillColor: '#F93A2F',
			fillOpacity: 0.28,
			map: this.map
		})

		this.map.fitBounds(bounds)
	},

	setupGardenValues: function() {
		var gardenTypes = this.model.get('garden')
		if (gardenTypes) {
			gardenTypes.forEach(function(type) {
				switch (type) {
					case 'small':
						$('#small-input').prop('checked', true)
						break
					case 'medium':
						$('#medium-input').prop('checked', true)
						break
					case 'large':
						$('#large-input').prop('checked', true)
						break
					case 'balconyroof':
						$('#balcony-input').prop('checked', true)
						break
					case 'public':
						$('#public-input').prop('checked', true)
						break
				}
			})
		}
	},

	setupDifficultyValue: function() {
		var difficulty = this.model.get('difficulty')
		if (difficulty) {
			switch (difficulty) {
				case 'easy':
					$('#difficulty-easy-input').prop('checked', true)
					break
				case 'medium':
					$('#difficulty-medium-input').prop('checked', true)
					break
				case 'hard':
					$('#difficulty-hard-input').prop('checked', true)
					break
			}
		}
	},

	setupDatePickers: function() {
		if (this.model.get('timestamps')) {
			this.datepickers = {};
			// Initialise datepicker
			['from', 'to', 'visible'].forEach(function(value) {
				var datepicker = new Pikaday({
					field: this.$('#date-' + value)[0],
					bound: true,
					showTime: true
				})

				var date      = new Date(),
					timestamp = this.model.get('timestamps')[value]
				if (timestamp !== undefined && timestamp !== '') {
					date = new Date(timestamp)
				}

				datepicker.setDate(date)
				this.datepickers[value] = datepicker

				if (value === 'to' && timestamp === "") {
					$('#indefinite-input').prop('checked', true)
					$('#date-to').prop('disabled', true)
				}
			}, this)
		}
	},

	getPageList: function() {
		// Generate page list dropdown.
		var pageListDropdownOptions = '<option value="">-</option>'

		// Get array of all unique tag names
		var tags = _.filter(this.pageList.pluck('tag'), function(elem, pos, self) {
			return self.indexOf(elem) === pos
		}).sort()

		// Output an option group for each tag
		_.each(tags, function(tag) {
			var taggedPages = this.pageList.where({tag: tag})
			var options = ''
			// Output an option for each page with this tag
			_.each(taggedPages, function(page) {
				var selected = this.model.get('src') === 'cache://pages/' + page.id + '.json' ? 'selected' : ''

				options += '<option value="cache://pages/' + page.id + '.json" ' + selected + '>' + App.l(page.get('title')) + '</option>'
			}, this)

			pageListDropdownOptions += '<optgroup label="' + tag + '">' + options + '</optgroup>'
		}, this)

		this.pageSelectOptions = pageListDropdownOptions
	},

	mapLocalisations: function() {
		var langs = this.app_.languageList,
			strings = []

		_.each(this.localisations.attributes, function(val, key) {
			if (key === 'id') {
				return
			}
			if (!key.includes('_TAGS_')) {
				return
			}
			var isDisabled = false
			var shortendedKey = key.replace('_TAGS_', '').toLowerCase()
			if (this.model.get('tags')) {
				isDisabled = (this.model.get('tags').indexOf(shortendedKey) > -1) ? true : false
			}

			strings.push({
				key: shortendedKey,
				isDisabled: isDisabled,
				languages: langs.map(function(lang) {
					var code = lang.get('code')

					return {
						name: lang.get('name'),
						code: code,
						value: val[code]
					}
				})
			})
		}.bind(this))

		return strings
	},

	/** @override */
	getRenderData: function() {
		// Map Localisations
		App.startLoad()
		this.getPageList()
		var data = this.model.toJSON()
		data.allTags = this.mapLocalisations()
		data.appId = this.app_.id
		data.languages = this.app_.languageList.toJSON()
		data.pageSelectOptions = this.pageSelectOptions
		return data
	},

	updateText: function(e) {
		var textInput = $(e.currentTarget)
		// Get Object
		var object = textInput.data('object')
		// Get Lang Code
		var code = textInput.data('code')
		// Get value
		var value = textInput.val()

		var arrayItem = textInput.data('arrayitem')
		if (object && code) {
			if (!arrayItem) {
				this.model.set(object + '..content..' + code, value)
			} else {
				this.model.get(object).at(textInput.data('index')).set('content..' + code, value)
			}
		}
	},

	updateType: function() {
		this.model.set('type', this.$('.type-input').val())
	},

	updateDifficulty: function() {
		this.model.set('difficulty', this.$('.difficulty-radio:checked').val())
	},

	updateSource: function() {
		this.model.set('source', this.$('.source-input').val())
	},

	updateActivityCode: function() {
		this.model.set('code', this.$('.activity-code-input').val())
	},

	updateDuration: function() {
		this.model.set('duration', this.$('.duration-input').val())
	},

	updateWeighting: function() {
		this.model.set('weight', this.$('.weighting-input').val())
		this.$('#weightingValue').text(this.model.get('weight'))
	},

	updatePage: function() {
		if (this.$('#page-input').val() !== '-') {
			this.model.set('src', this.$('#page-input').val())
		}
	},

	updateIndefinite: function() {
		var checked = false
		if ($('#indefinite-input').is(":checked")) {
			checked = true
		}
		$('#date-to').prop('disabled', checked)
	},

	updateSolo: function() {
		var checked = true
		if ($('#solo-input').is(":checked")) {
			checked = false
		}
		this.model.set('solo', checked)
	},

	addArrayItem: function(e) {
		// What are we adding?
		var type = $(e.currentTarget).data('object')
		// Different cases for each object type
		if (type) {
			switch (type) {
				case 'features':
					// Text object
					// Create one and fill in langs
					var text = StormObject.fromClassName('Text', this.model.get('pageId'))
					this.$('.feature-input').each(function() {
						var code = $(this).data('code')
						var value = this.value
						if (value !== '') {
							text.set('content..' + code, value)
						}
					})
					var features = this.model.get('features')
					if (!features) {
						features = []
						features.push(text)
						this.model.set('features', features)
					} else {
						features.add(text)
					}

					break
				case 'tags':
					// String object
					var value = $('.tag-input').val()
					if (value !== '') {
						var tags = this.model.get('tags')
						if (!tags) {
							tags = []
							this.model.set('tags', tags)
						}
						tags.push(value)
					}
					break
				case 'garden':
					// String object
					var garden = this.model.get('garden')
					var checkbox = $(e.currentTarget)
					var gardenType = checkbox.data('type')
					if (!garden) {
						garden = []
						this.model.set('garden', garden)
					}
					var pos = garden.indexOf(gardenType)
					if (pos !== -1) {
						// It exists, remove it!
						garden.splice(pos, 1)
					} else {
						// Add it
						garden.push(gardenType)
					}
					break
				case 'images':
					// Image object
					var image = StormObject.fromClassName('Image', this.model.get('pageId'))
					if (!this.model.get('images')) {
						this.model.set('images', [])
					}

					this.chooseImage(image, this.model.get('images'))
					break
			}
		}
		this.render()

		// Populate it
	},

	deleteArrayItem: function(e) {
		var button = $(e.currentTarget)
		var object = button.data('object')
		var index = button.data('index')
		var model
		if (object !== 'tags') {
			model = this.model.get(object).at(index)
		}

		swal({
			title: $.t('editor.inspector.areYouSure'),
			text: $.t('editor.inspector.confirmDelete'),
			showCancelButton: true
		}, function(didConfirm) {
			if (didConfirm) {
				if (object !== 'tags') {
					model.set('toDelete', true)
				} else {
					// Splice out tags item as it's not a backbone collection
					this.model.get(object).splice(index, 1)
				}
				this.render()
			}
		}.bind(this))
	},

	changeImage: function() {
		var model = this.model.get('image')
		if (!model) {
			model = StormObject.fromClassName('Image', this.model.get('pageId'))
			this.model.set('image', model)
		}
		this.chooseImage(model)
	},

	chooseImage: function(imageModel, parent) {
		this.mediaLibrary = new MediaSelectorView({
			app: this.app_,
			model: imageModel,
			mediaType: MediaLibrary.types.IMAGE
		})

		this.mediaLibrary.on('change', function() {
			// Do something
			if (parent) {
				parent.add(imageModel)
			}
			this.render()
		}, this)

		$('body').append(this.mediaLibrary.el)
		this.mediaLibrary.render().show()
	},

	save: function() {
		App.startLoad()

		var self = this

		if (this.model.get('area')) {
			// Parse the area object to a string and set to region
			this.model.set('region', JSON.stringify(this.model.get('area')))
		}

		if (this.model.get('timestamps')) {
			StormObject.fromClassName('Gnah_PlanItemTimestamps', this.model.get('pageId'))
		}

		this.setMonthValue()

		for (var propertyName in this.datepickers) {
			if (this.datepickers[propertyName]) {
				this.model.get('timestamps')[propertyName] = moment(this.datepickers[propertyName].getDate()).format()
				// Is the to property indefinite?
				if (propertyName === 'to' && $('#indefinite-input').is(":checked")) {
					this.model.get('timestamps')[propertyName] = null
				}
			}
		}

		self.model.once('sync', function() {
			App.router.navigate('/apps/' + self.app_.id + '/plan-items', {trigger: true})
		})

		if (!self.model.isNew()) {
			self.model.requestLock(function() {
				var promises = []

				Promise.all(promises).then(function() {
					self.model.save(null, {appId: self.app_.id}).then(function() {
						self.model.requestUnlock()
					})
				})
			})
		} else {
			self.model.save(null, {appId: self.app_.id})
		}
		return false
	},

	setMonthValue: function() {
		var values = $("#monthSelector").val()
		var bitwise = 0
		if (values) {
			// I hate you bitwise, i really hate you.
			// Go through each value and OR (2 to the power of the value) together then set
			values.forEach(function(value) {
				// |= is shorthand for bitwise = bitwise | number
				bitwise |= Math.pow(2, value)
			})
		}
		this.model.set('months', bitwise)
	}
})
