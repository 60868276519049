<div class="container-fluid">
<!-- Header -->
<div class="row">
  <div class="col-md-12 module-edit-header">
    <a class="back-button" href="apps/{{appId}}/atlas_admin"><i class="icon-chevron-left"></i> {{t 'common.back'}}</a>
    <div class="title">
      <div class="title-inputs">
        <h3>{{t "r4b.modules.level"}} {{model.level}} {{#ifEqual model.class 'R4b_Module'}}- <span class="span-title enter-text" style="width: inherit;" data-placeholder="{{t 'r4b.modules.blockContent.enterTitle'}}" contenteditable="true">{{getLocaleText model.name.content}}</span>{{/ifEqual}} - {{t "r4b.modules.version"}} {{model.version}}</h3>
      <!--  {{identifierArr.[1]}}<input type="text" class="developer-mode" id="module-identifier" placeholder="{{t 'r4b.modules.blockContent.identifier'}}" value="{{identifierArr.[2]}}"></input>{{identifierArr.[3]}} -->
      </div>
			<h4>{{t 'r4b.modules.blockContent.identifier'}}: {{identifierArr.[1]}}.<span class="enter-text" id="module-identifier" style="width: inherit;" data-placeholder="{{t 'r4b.modules.blockContent.identifier'}}" contenteditable="true">{{identifierArr.[2]}}</span>.{{identifierArr.[3]}}</h4>
      <div class="visibility"><i class="icon-eye-open"></i><span class="module-logic enter-text" data-placeholder="Enter module logic here" contenteditable="true">{{model.logic}}</span></div>
    </div>
  </div>
</div>
<div class="row" style="height:100%;">
  <div class="col-md-3 left-sidebar">
    <div class="sidebar-controls">
      <button class="button wide save">{{t "common.save"}}</button>
      <p class="save-status"><b><i>{{t "r4b.modules.saved"}}</i></b></p>
    </div>
    <div class="sidebar-blocks no-select">
    <!-- Left sidebar -->
		<h5>{{t 'r4b.inputBlocks'}}</h5>
		<div class="input-holder">
      {{#each blocks}}
        {{#ifEqual this "R4b_RadioInputModuleBlock"}}
        <div class="left-block" data-className={{this}}><i class="icon-circle-blank"></i><h4>{{t "r4b.modules.blockTypes.radio"}}</h4></div>
        {{/ifEqual}}

        {{#ifEqual this "R4b_CheckboxInputModuleBlock"}}
        <div class="left-block" data-className={{this}}><i class="icon-check"></i><h4>{{t "r4b.modules.blockTypes.check"}}</h4></div>
        {{/ifEqual}}

        {{#ifEqual this "R4b_SliderInputModuleBlock"}}
        <div class="left-block" data-className={{this}}><i class="icon-resize-horizontal"></i><h4>{{t "r4b.modules.blockTypes.slider"}}</h4></div>
        {{/ifEqual}}

        {{#ifEqual this "R4b_TextInputModuleBlock"}}
        <div class="left-block" data-className={{this}}><i class="icon-pencil"></i><h4>{{t "r4b.modules.blockTypes.textInput"}}</h4></div>
        {{/ifEqual}}
      {{/each}}
		</div>
		<h5>{{t 'r4b.mediaBlocks'}}</h5>
		<div class="input-holder">
      {{#each blocks}}
				{{#ifEqual this "R4b_TextMediaModuleBlock"}}
				<div class="left-block" data-className={{this}}><i class="icon-align-left"></i><h4>{{t "r4b.modules.blockTypes.text"}}</h4></div>
				{{/ifEqual}}

				{{#ifEqual this "R4b_ImageMediaModuleBlock"}}
				<div class="left-block" data-className={{this}}><i class="icon-picture"></i><h4>{{t "r4b.modules.blockTypes.image"}}</h4></div>
				{{/ifEqual}}

				{{#ifEqual this "R4b_VideoMediaModuleBlock"}}
				<div class="left-block" data-className={{this}}><i class="icon-facetime-video"></i><h4>{{t "r4b.modules.blockTypes.video"}}</h4></div>
				{{/ifEqual}}

				{{#ifEqual this "R4b_LinkMediaModuleBlock"}}
				<div class="left-block" data-className={{this}}><i class="icon-link"></i><h4>{{t "r4b.modules.blockTypes.link"}}</h4></div>
				{{/ifEqual}}

				{{#ifEqual this "R4b_TaskMediaModuleBlock"}}
				<div class="left-block" data-className={{this}}><i class="icon-ok"></i><h4>{{t "r4b.modules.blockTypes.task"}}</h4></div>
				{{/ifEqual}}

				{{#ifEqual this "R4b_HelpMediaModuleBlock"}}
				<div class="left-block" data-className={{this}}><i class="icon-question-sign"></i><h4>{{t "r4b.modules.blockTypes.help"}}</h4></div>
				{{/ifEqual}}

				{{#ifEqual this "R4b_QuoteMediaModuleBlock"}}
				<div class="left-block" data-className={{this}}><i class="icon-quote-left"></i><h4>{{t "r4b.modules.blockTypes.quote"}}</h4></div>
				{{/ifEqual}}

				{{#ifEqual this "R4b_QuestionAssessmentBlock"}}
				<div class="left-block" data-className={{this}}><i class="icon-comments"></i><h4>{{t "r4b.modules.blockTypes.qna"}}</h4></div>
				{{/ifEqual}}

				{{#ifEqual this "R4b_InputAssessmentBlock"}}
				<div class="left-block" data-className={{this}}><i class="icon-pencil"></i><h4>{{t "r4b.modules.blockTypes.textInput"}}</h4></div>
				{{/ifEqual}}

				{{#ifEqual this "R4b_TextAssessmentBlock"}}
				<div class="left-block" data-className={{this}}><i class="icon-align-left"></i><h4>{{t "r4b.modules.blockTypes.text"}}</h4></div>
				{{/ifEqual}}

				{{#ifEqual this "R4b_VideoAssessmentBlock"}}
				<div class="left-block" data-className={{this}}><i class="icon-facetime-video"></i><h4>{{t "r4b.modules.blockTypes.video"}}</h4></div>
				{{/ifEqual}}

				{{#ifEqual this "R4b_ImageAssessmentBlock"}}
				<div class="left-block" data-className={{this}}><i class="icon-picture"></i><h4>{{t "r4b.modules.blockTypes.image"}}</h4></div>
				{{/ifEqual}}
      {{/each}}
		</div>
    </div>
  </div>
  <div class="col-md-9 section-area">
    <div class="module-details">
      <div class="detail">
        <i class="icon-picture"></i>
        <div class="detail-content add-image" style="{{getBackgroundImageStyle model.image.src 'cover'}}" data-property="image"></div>
      </div>
      <div class="detail">
        <i class="icon-time"></i>
        <div class="detail-content"><h4 class="time" contenteditable="true">{{secondsToMinutes1dp model.time}}</h4><small>{{t 'r4b.modules.mins'}}</small></div>
      </div>
      <div class="detail">
        <i class="icon-group"></i>
        <div class="detail-content"><h4 class="people" contenteditable="true">{{model.people}}</h4><small>{{#ifEqual model.people 1}}{{t 'r4b.modules.person'}}{{else}}{{t 'r4b.modules.people'}}{{/ifEqual}}</small></div>
      </div>
    </div>
    <!-- Section area-->
    <div class="blocks"></div>
  </div>
</div>
</div>
