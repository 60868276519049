var DeploymentRole = require('./deployment-role'),
	DeploymentSectionView = require('./deployment-section-view')

module.exports = DeploymentSectionView.extend({
	template: require('./server-deployment-edit-view-template'),

	events: {
		'click .save': 'save'
	},

	initialize: function(options) {
		this.model = new DeploymentRole()

		if (options.name !== 'new') {
			this.model.set('name', options.name)
			this.model.once('sync', this.ready, this)
			this.model.fetch()
		} else {
			this.ready()
		}
	},

	ready: function() {
		this.render()
		App.stopLoad()
	},

	save: function() {
		var description = this.$('#role-description').val(),
			json = this.$('#role-default-attributes').val()

		var obj

		try {
			obj = JSON.parse(json)
		} catch (e) {
			swal($.t('error.oops'), 'Invalid JSON', 'error')
		}

		App.startLoad()

		this.model.save({
			description: description,
			default_attributes: obj
		})

		this.model.once('sync', function() {
			App.router.navigate('/deployment/server', {trigger: true})
		})
	}
})
