var DRAGGING_CLASS = 'dragging'

/**
 * Modal view for allowing user to select the source of media as either a new
 * upload or existing from media library.
 */
module.exports = Backbone.View.extend({
	/** @override */
	className: 'media-source-picker storm-modal modal fade',

	/** @override */
	template: require('./media-source-picker-view-template'),

	/** @override */
	events: {
		'click .upload-button': 'uploadButtonClick_',
		'click .existing-button': 'existingButtonClick_',
		'dragover': 'dragOver_',
		'dragleave': 'dragLeave_',
		'drop': 'drop_',
		'click .close-modal': 'close'
	},

	/**
	 * Shows the view, animating in as a modal.
	 */
	show: function() {
		this.$el.modal({keyboard: false, backdrop: 'static'})
	},

	/**
	 * Dismisses the view's modal and destroys the view.
	 */
	close: function() {
		this.$el.modal('hide')

		// Destroy view after modal animated out.
		setTimeout(this.destroy.bind(this), 500)
	},

	/**
	 * Handles clicks to the 'Upload' button. Triggers an 'upload' event.
	 * @private
	 */
	uploadButtonClick_: function() {
		this.trigger('upload')
	},

	/**
	 * Handles clicks to the 'Choose existing' button. Triggers an 'existing'
	 * event.
	 * @private
	 */
	existingButtonClick_: function() {
		this.trigger('existing')
	},

	/**
	 * Handles dragover events triggered on the view's root element, for file
	 * uploads. Sets the drop effect and updates the view's root element class
	 * for dragging.
	 * @param {jQuery.Event} e jQuery Event object.
	 * @private
	 */
	dragOver_: function(e) {
		e.stopPropagation()
		e.preventDefault()
		e.originalEvent.dataTransfer.dropEffect = 'copy'
		this.$el.addClass(DRAGGING_CLASS)
	},

	/**
	 * Handles dragleave events triggered on the view's root element, for file
	 * uploads. Remove the view's root element dragging class.
	 * @param {jQuery.Event} e jQuery Event object.
	 * @private
	 */
	dragLeave_: function(e) {
		e.stopPropagation()
		e.preventDefault()
		this.$el.removeClass(DRAGGING_CLASS)
	},

	/**
	 * Handles drop events triggered on the view's root element, for file
	 * uploads. Triggers a 'files' event with the dropped files as a payload.
	 * @param {jQuery.Event} e jQuery Event object.
	 * @private
	 */
	drop_: function(e) {
		e.stopPropagation()
		e.preventDefault()
		this.$el.removeClass(DRAGGING_CLASS)

		var files = e.originalEvent.dataTransfer.files

		this.trigger('files', files)
	}
})
