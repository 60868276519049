<td class="center">
	<!-- Title -->
	{{capitaliseFirstLetter data.lang}}
</td>
<td class="center">
	<!-- Title -->
		{{#if data.title}}
			{{data.title}}
		{{else}}
			{{t "whatnow.content-not-set"}}
		{{/if}}
</td>
<td class="center">
	<!-- Description -->
		{{#if data.description}}
			{{data.description}}
		{{else}}
			{{t "whatnow.content-not-set"}}
		{{/if}}
</td>
<td class="center">
	<!-- Before -->
		{{#if data.before}}
			{{data.before}}
		{{else}}
			{{t "whatnow.content-not-set"}}
		{{/if}}
</td>
<td class="center">
	<!-- During -->
		{{#if data.during}}
			{{data.during}}
		{{else}}
			{{t "whatnow.content-not-set"}}
		{{/if}}
</td>
<td class="center">
	<!-- After -->
		{{#if data.after}}
			{{data.after}}
		{{else}}
			{{t "whatnow.content-not-set"}}
		{{/if}}
</td>
<td class="tools">
	<i class="icon-trash delete-translation" data-id="{{index}}"></i>
</td>
