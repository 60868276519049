module.exports = {
	GDPC: {
		styling: 'redcross'
	},
	ARC_STORM: {
		styling: 'redcross'
	},
	BRC_STORM: {
		styling: 'redcross'
	}
}
