<h3>Frontend Deployment</h3>
<div class="row">
	<div class="col-xs-12 title-header">Create new system</div>
</div>
<div class="row">
	<div class="col-xs-12">
		<div class="row">
			<div class="col-sm-offset-3 col-sm-3">
				<input type="text" class="form-control new-system-name" placeholder="new-system-name">
			</div>
			<div class="col-sm-2">
				<h4>.3sidedcube.com</h4>
			</div>
			<div class="col-sm-4 text-right">
				<button class="button green deploy-new-system">Deploy</button>
			</div>
		</div>
	</div>
</div>
<div class="row">
	<div class="col-xs-12 title-header">Systems</div>
</div>
<div class="row">
	<div class="col-xs-12 frontend-list">

		{{#each frontends}}
			<div class="row">
				<div class="col-sm-5">
					<h2>{{tag}}</h2>
				</div>
				<div class="col-sm-2">
					<span class="version{{#if old}} old{{/if}}{{#if veryOld}} very-old{{/if}}">{{version}}</span>
				</div>
				<div class="col-sm-5 text-right">
					<button class="button white settings-button" data-system="{{tag}}"><i class="icon-cog"></i></button>
					<button class="button red update-button" data-system="{{tag}}">Update</button>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12">
					<hr>
				</div>
			</div>
		{{/each}}

	</div>
</div>
