var PublishHistoryList = require('publish/publish-history-list'),
	AppAudit = require('publish/app-audit')

var PUBLISH_CHECK_DELAY = 5 * 1000

function AutoPublish(appId) {
	this.appId = appId
	this.audit = new AppAudit({appId: this.appId})
	this.publishHistory = new PublishHistoryList(null, {appId: appId})
}

AutoPublish.prototype.publishAll = function() {
	var self = this,
		deferred = new $.Deferred()

	// Should be a flat chain of .then's, but jQuery promises don't work.
	self.audit.fetch().then(function() {
		self.publishToTest().then(function() {
			self.forcePublish().then(function() {
				self.audit.fetch().then(function() {
					self.publishToLive().then(function() {
						self.forcePublish().then(deferred.resolve, deferred.reject)
					}, deferred.reject)
				}, deferred.reject)
			}, deferred.reject)
		}, deferred.reject)
	}, deferred.reject)

	return deferred
}

AutoPublish.prototype.publishToTest = function() {
	var pages = this.audit.get('pages') || []
	pages = pages.filter(isTestPublish)
		.map(toPagesArray)

	var app = this.audit.get('app') || []
	app = app.filter(isTestPublish)

	if (app.length) {
		pages.push({id: 0})
	}

	// If nothing to publish, resolve immediately.
	if (!pages.length) {
		return $.when()
	}

	return this._doPublish(pages, 'test')
}

AutoPublish.prototype.publishToLive = function() {
	var pages = this.audit.get('pages') || []
	pages = pages.filter(isLivePublish)
		.map(toPagesArray)

	var app = this.audit.get('app') || []
	app = app.filter(isLivePublish)

	if (app.length) {
		pages.push({id: 0})
	}

	// If nothing to publish, resolve immediately.
	if (!pages.length) {
		return $.when()
	}

	return this._doPublish(pages, 'live')
}

AutoPublish.prototype._doPublish = function(pages, type) {
	var model = {
		pages: pages,
		comment: 'Automated publish',
		type: type
	}

	return Backbone.sync('create', new Backbone.Model(), {
		url: App.apiRoot + 'apps/' + this.appId + '/publish',
		data: JSON.stringify(model),
		global: false
	})
}

AutoPublish.prototype._getPublishCompletionPromise = function(publishRequest) {
	var deferred = new $.Deferred()

	publishRequest.then(function(publish) {
		this._checkPublishCompletion(deferred, publish.id)
	}.bind(this), deferred.reject)

	return deferred
}

AutoPublish.prototype._checkPublishCompletion = function(deferred, id) {
	this.publishHistory.fetch().then(function() {
		var historyItem = this.publishHistory.get(id),
			status = historyItem.get('status')

		switch (status) {
			case 0:
			case 1:
				setTimeout(this._checkPublishCompletion.bind(this, deferred, id), PUBLISH_CHECK_DELAY)
				break

			case 2:
				deferred.resolve()
				break

			case 3:
				deferred.reject()
				break
		}
	}.bind(this))
}

AutoPublish.prototype.forcePublish = function() {
	return Backbone.sync('read', new Backbone.Model(), {
		url: App.apiRoot + 'publish',
		global: false
	})
}

module.exports = AutoPublish

function isTestPublish(page) {
	return (page.in & 1) !== 0
}

function isLivePublish(page) {
	return (page.in & 2) !== 0
}

function toPagesArray(page) {
	return {id: page.pageId}
}
