{{partial "sprint/sprint-nav"}}

<div class="title-bar">
	<div class="header">{{t "nav.items.sprint.title"}}</div>
</div>

<div class="main-container pad-top">
	<div class="main wrapper clearfix">

		<div class="summary">
			<div class="number-container"><span class="number one">1</span> Initial app build including all functionality.</div>

			<div class="number-container"><span class="number two">2</span> App with all functionality and aesthetics.</div>

			<div class="number-container"><span class="number three">3</span> Niceties and final bug fixes.</div>
		</div>

		<table bordercolor="#FFF" style="background-color:#F4F5F7" width="100%" cellpadding="3" cellspacing="3">
			<thead>
				<th>App</th>
				<th>Build Links</th>
				<th>Sprint</th>
				<th>We're Doing</th>
				<th>You're Doing</th>
				<th>Our Next Step</th>
				<th>Your Next Step</th>
				<th>Next Build</th>
				<th>Submission</th>
			</thead>
			<tr>
				<td><img src="/images/arc/pet.png" alt="Pet First Aid"></td>
				<td>iOS: <a href="http://3cu.be/iepfa">3cu.be/arcpfa</a><br>Android: <a href="http://3cu.be/iepfa">3cu.be/iepfa</a></td>
				<td><span class="number two">2</span></td>
				<td>-</td>
				<td>Testing</td>
				<td>-</td>
				<td>Deliver testing feedback</td>
				<td>Wed 11th Dec</td>
				<td>Thu 12th Dec</td>
			</tr>
			<tr>
				<td><img src="/images/arc/swim.png" alt="Swim"></td>
				<td>iOS: <a href="http://3cu.be/iepfa">3cu.be/ies</a><br>Android: <a href="http://3cu.be/iepfa">3cu.be/lts</a></td>
				<td><span class="number two">2</span></td>
				<td>-</td>
				<td>Testing</td>
				<td>-</td>
				<td>Deliver testing feedback</td>
				<td>Wed 11th Dec</td>
				<td>Fri 20th Dec</td>
			</tr>
		</table>

	</div>
</div>
