{{#if enabled}}
	<td class="center"><input type="checkbox" class="storm-checkbox publish-option"></td>
	<td class="center"><input type="checkbox" class="storm-checkbox publish-option"></td>
{{else}}
	<td colspan="2"><button class="button enable-button input-block-level" data-id="{{id}}">{{t "hazards.disasters.enable"}}</button></td>
{{/if}}
<td>
	<img src="/images/hazards/disaster-icons/{{codeName}}.png" width="32">&nbsp;
	{{getBrowserLocaleText name}}
	{{#if live}}
		<span class="status green">{{t "appSetup.live"}}</span>
	{{else}}
		{{#if dev}}
			<span class="status orange">{{t "appSetup.dev"}}</span>
		{{/if}}
	{{/if}}
</td>
<td>
	<select class="input-block-level root-page"{{#unless enabled}} disabled{{/unless}}>{{{rootPageOptions}}}</select>
</td>
<td><button class="button light configure-button input-block-level"{{#unless enabled}} disabled{{/unless}}>{{t "hazards.disasters.configure"}}</button></td>
<td class="tools">
	{{#if enabled}}<button class="button negative delete-disaster">{{t "hazards.disasters.disable"}}</button>{{else}}-{{/if}}
</td>
