<td class="center">
	<span style="background-image: url('{{getPreviewUrl planItem.image.src}}')" class="fact-file-image-table"></span>
</td>
<td class="center">
	{{capitaliseFirstLetter planItem.type}}
</td>
<td class="center">
	{{#key_value planItem.title.content "lang" "text"}}
		<dt>{{lang}}</dt>
		<dd>{{text}}</dd>
	{{/key_value}}
</td>
<td>
	{{#key_value planItem.description.content "lang" "text"}}
		<dt>{{lang}}</dt>
		<dd>{{text}}</dd>
	{{/key_value}}
</td>
<td class="tools">
	<a href="/apps/{{appId}}/plan-items/{{planItem.id}}" title="{{t "common.edit"}}"><i class="icon-pencil"></i></a>
	<i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}"></i>
</td>
