<blockquote>
	<span class="comment-controls">
		<a href class="comment-delete-button" data-id="{{id}}"><i class="icon-trash"></i></a>
		<a href class="comment-reply-button" data-id="{{id}}"><i class="icon-reply"></i></a>
	</span>

	<p>{{{newlineToBr comment}}}</p>
	<small>{{user.firstName}} {{user.lastName}} - {{formatTime commentDate}}</small>
	<div class="child-comments"></div>
</blockquote>
