/**
 * Exports {@link UserRoleList}.
 * @module
 */
module.exports = Backbone.Collection.extend(/** @lends UserRoleList.prototype */{
	/**
	 * @constructs UserRoleList
	 * @extends Backbone.Collection
	 * @override
	 */
	initialize: function(models, options) {
		options = options || {}

		this.userId = options.userId || 'me'
	},

	/** @override */
	url: function() {
		return App.authRoot + 'users/' + this.userId + '/roles'
	},

	/**
	 * [function Check to see if a role exists for the user]
	 * @param  {[type]} id [ID of role]
	 * @returns {[type]}    [True if exists]
	 */
	roleExists: function(id) {
		return this.get(id) ? true : false
	}
})
