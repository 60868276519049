module.exports = Backbone.View.extend({
	template: require('./disaster-category-view-template'),

	events: {
		'input .category-title': 'updateTitle',
		'click .category-add-new-event': 'addEvent',
		'click .disaster-event-item-remove': 'removeEvent',
		'click .move-category-up': 'moveCategoryUp',
		'click .move-category-down': 'moveCategoryDown',
		'click .delete-category': 'deleteCategory'
	},

	initialize: function(options) {
		this.model = options.model
		this.pages = options.pages
		this.app = options.app
		this.selectorId = Math.random().toString(16).substr(2)
		this.model.set('selectorId', this.selectorId)
		if (this.model.get('meta')) {
			this.model.get('meta').selectionId = this.selectorId
		}
	},

	getRenderData: function() {
		var meta = null
		if (this.model.get('meta')) {
			meta = this.model.get('meta').toJSON()
		}
		return {
			model: this.model.toJSON(),
			meta: meta,
			pages: this.pages,
			selectorId: this.selectorId
		}
	},

	updateTitle: function() {
		var str = $('.category-title[data-selection-id=' + this.selectorId + ']').val()
		this.model.set('name..content..en', str.trim())
		this.model.needsSaving = true
	},

	addEvent: function() {
		var eventId = parseInt($('.event-dropdown[data-selection-id=' + this.selectorId + ']').val(), 10)
		if (eventId || eventId === 0) {
			if (!this.model.get('events')) {
				this.model.set('events', [])
			}
			var events = this.model.get('events')
			if (events.indexOf(eventId) === -1) {
				events.push(eventId)
				this.model.needsSaving = true
				this.model.trigger('change:events', events)
			}
		}
	},

	removeEvent: function(e) {
		var eventId = parseInt($(e.currentTarget).data('eventid'), 10)
		if (eventId || eventId === 0) {
			var events = this.model.get('events')
			var index  = events.indexOf(eventId)
			if (index !== -1) {
				events.splice(index, 1)
				this.model.needsSaving = true
				this.model.trigger('change:events', events)
			}
		}
	},

	moveCategoryUp: function() {
		// This models order number...
		var currentOrder = this.model.get('order')
		// Can't move up any further if 0
		if (currentOrder === 0) {
			return
		}
		// Get model where the order is minus one of currentOrder
		var modelBelow = this.model.collection.findWhere({order: currentOrder - 1})

		if (modelBelow) {
			modelBelow.set('order', currentOrder)
			modelBelow.needsSaving = true
			this.model.set('order', currentOrder - 1)
			this.model.needsSaving = true
		}
		this.sortAndTriggerCollection()
	},

	moveCategoryDown: function() {
		// This models order number...
		var currentOrder = this.model.get('order')
		// Can't move up any further than length...
		if (currentOrder === this.model.collection.length - 1) {
			return
		}
		// Get model where the order is plus one of currentOrder
		var modelAbove = this.model.collection.findWhere({order: currentOrder + 1})

		if (modelAbove) {
			modelAbove.set('order', currentOrder)
			modelAbove.needsSaving = true
			this.model.set('order', currentOrder + 1)
			this.model.needsSaving = true
		}
		this.sortAndTriggerCollection()
	},

	deleteCategory: function() {
		swal({
			title: $.t('editor.inspector.areYouSure'),
			text: $.t('editor.inspector.confirmDelete'),
			showCancelButton: true
		}, function(didConfirm) {
			if (didConfirm) {
				// Remove
				// Get meta associated with category
				// Add needsDeleting to meta AND model
				delete this.model.needsSaving
				this.model.needsDeleting = true
				var meta = this.model.get('meta')
				if (meta) {
					meta.needsDeleting = true
					meta.collection.parent.collection.needsSaving = true // the parents parent needs saving on this instance
				}
				// Re-order ordering...
				var order = this.model.get('order')
				this.model.collection.forEach(function(category) {
					var catOrder = category.get('order')
					if (catOrder > order) {
						category.set('order', catOrder - 1) // Minus one from the order of the category.
						category.needsSaving = true
					}
				})
				this.model.unset('order')
				if (this.model.isNew()) {
					// Just destroy it too...
					this.model.destroy()
				}
				// Remove view
				this.destroy()
			}
		}.bind(this))
	},

	sortAndTriggerCollection: function() {
		this.model.collection.trigger('change:collection', this.model.collection)
	},

	setPageSelection: function() {
		if (this.model.get('meta')) {
			$('.update-page[data-selection-id=' + this.selectorId + ']').val(this.model.get('meta').get('src'))
		}
	}
})
