module.exports = Backbone.View.extend({
	template: require('./quiz-selector-template'),

	events: {
		'click .add-quiz': 'addQuiz',
		'click .remove-quiz': 'removeQuiz'
	},

	initialize: function(options) {
		var pages = Storm.view.views.pageList.collection
		this.quizPages = new Backbone.Collection(pages.where({class: 'QuizPage'}))

		this.parent = options.parent
	},

	getRenderData: function() {
		return _.map(this.collection, function(link) {
			var id = link.replace(/cache:\/\/pages\/([0-9]*).json/, '$1')

			var page = this.quizPages.get(id)
			return (page) ? App.l(page.get('title')) : '<page removed>'
		}, this)
	},

	afterRender: function() {
		var options = ''

		// Populate selector
		this.quizPages.each(function(page) {
			options += '<option value="' + page.id + '">' + App.l(page.get('title')) + '</option>'
		})

		this.$('.quiz-pages').html(options)
	},

	addQuiz: function() {
		this.collection.push('cache://pages/' + this.$('.quiz-pages').val() + '.json')
		this.render()

		this.parent.needsSaving = true
	},

	removeQuiz: function(e) {
		var index = parseInt($(e.currentTarget).data('index'), 10)
		this.collection.splice(index, 1)
		this.render()

		this.parent.needsSaving = true
	}
})
