var DevPool = require('./dev-pool')

module.exports = Backbone.Collection.extend({
	model: DevPool,
	comparator: 'name',

	url: function() {
		return App.apiRoot + 'pools/details'
	}
})
