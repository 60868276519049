var User = require('./user')

module.exports = Backbone.Collection.extend({
	model: User,

	url: function() {
		if (this.ids) {
			return App.authRoot + 'users/list'
		}

		return App.authRoot + 'systems/' + App.system.id + '/users'
	},

	initialize: function(options) {
		options = options || {}
		this.id = options.id
		this.ids = options.ids
		this.filterDeleted = options.filterDeleted || false
	},

	fetch: function(options) {
		options = options || {}

		var collection = this
		var success = options.success
		var method = 'fetch'
		var filterDeleted = this.filterDeleted

		if (this.ids) {
			options.data = JSON.stringify({ids: this.ids})
			method = 'create'
		}

		options.success = function(resp) {
			if (filterDeleted) {
				resp = resp.filter(function(user) {
					return user.status !== 0
				})
			}
			var method = options.reset ? 'reset' : 'set'
			collection[method](resp, options)

			if (success) {
				success(collection, resp, options)
			}

			collection.trigger('sync', collection, resp, options)
		}

		return this.sync(method, this, options)
	}
})
