var AppStructure = require('./app-structure')

var PageListItem = Backbone.View.extend({
	tagName: 'li',

	template: require('./page-list-item-view-template'),

	events: {
		'click': 'click',
		'click .collapse-link': 'collapse'
	},

	initialize: function(options) {
		this.listViews = []
		this.pageList = options.pageList

		this.model.on('change', this.renderTitle, this)
		this.model.on('change:tag', function() {
			this.render()
			this.renderStructure()
		}, this)
		this.listenTo(this.model, 'change:structure', this.renderStructure, this)
		this.pageTitle = this.model.get('title')
	},

	getRenderData: function() {
		var data = this.model.toJSON()
		data.language = Storm.view.language
		data.title = this.pageTitle
		return data
	},

	afterRender: function() {
		this.renderTitle()

		// Persist selected state
		if (this.model.selected) {
			// Timeout waits until after element rendered in DOM before adding class
			setTimeout(function() {
				this.$el.addClass('selected')
			}.bind(this))
		}
	},

	renderTitle: function() {
		this.$('> .content .title').text(App.l(this.model.get('title')))
	},

	renderStructure: function(depth) {
		if (depth === undefined) {
			depth = 0
		} else if (depth === 3) {
			return
		}

		if (this.model.structure) {
			this.$('> ul').empty()

			_.each(this.listViews, function(view) {
				view.destroy()
			})

			this.listViews = []

			// Render a view for each child page
			this.model.structure.get('children').each(function(child) {
				var model = this.pageList.get(child.id)

				if (model) {
					// Create list item and render it to the DOM
					var view = new PageListItem({
						model: model,
						pageList: this.pageList
					})

					this.listViews.push(view)
					this.$('> ul').append(view.render().el)

					// Render child page structures
					view.renderStructure(depth + 1)
				}
			}, this)
		}
	},

	click: function(e) {
		Storm.view.setPageId(this.model.id)

		// Clear selected page in page list
		$('.selected').removeClass('selected')
		$(e.currentTarget).addClass('selected')
		this.model.selected = true

		e.stopPropagation()
	},

	collapse: function(e) {
		if (!this.model.structure) {
			this.model.structure = new AppStructure({objectId: this.model.id})
		}

		// Only render if we've loaded the structure/no ID (for the 'all pages' folder)
		if (this.model.structure.fetched || !this.model.id) {
			// Hide collapse link if there are no children
			if (!this.model.structure.get('children').length && this.model.id) {
				this.$('> .content .collapse-link').hide()
				return
			}

			// Render structure if it's been removed (i.e. language changed)
			if (!this.$('> ul > li').length) {
				this.renderStructure()
			}

			this.$('> ul').toggleClass('open')
			this.$('> .content .collapse-link').toggleClass('icon-caret-right icon-caret-down')
			this.$('> .content .icon-folder-open, > .content .icon-folder-close').toggleClass('icon-folder-close icon-folder-open')
		} else {
			this.model.structure.once('sync', function() {
				this.model.structure.fetched = true
				this.renderStructure()
				this.collapse()
				this.$('.spinner:first').removeClass('icon-spin').hide()
			}, this)
			this.model.structure.fetch()
			this.$('.spinner:first').addClass('icon-spin').show()
		}

		if (e) {
			e.stopPropagation()
		}
	}
})

PageListItem.LinkedPagesItem = PageListItem.extend({
	className: 'linked-pages-item',

	initialize: function(options) {
		this.filter = ''
		this.listViews = []
		this.pageList = options.pageList

		this.model = new Backbone.Model({
			class: 'TabbedPageCollection',
			title: {
				content: {
					en: $.t('editor.pageList.allPages')
				}
			}
		})

		this.model.collapsible = true
		this.pageTitle = this.model.get('title')
	},

	afterRender: function() {
		this.renderTitle()
		this.$('ul').addClass('linked-pages')
	},

	renderStructure: function() {
		this.$('> ul').empty()

		_.each(this.listViews, function(listView) {
			listView.destroy()
		})

		this.listViews = []

		var pages = this.pageList.filter(function(page) {
			var title = page.get('title')

			if (title) {
				return App.l(title).toLowerCase().match(this.filter)
			}

			return false
		}, this)

		_.each(pages, function(page) {
			var view = new PageListItem({
				model: page,
				pageList: this.pageList
			})

			this.listViews.push(view)
			this.$('.linked-pages').append(view.render().el)
		}, this)
	},

	// Override click handler. Don't do anything.
	click: function(e) {
		e.stopPropagation()
	},

	setFilter: function(filter) {
		this.filter = filter.toLowerCase()
		this.renderStructure()
	}
})

module.exports = PageListItem
