<div class="row">
	<div class="preview-outer col-md-7">
		{{#if title}}
			<div class="preview-container">
				<div class="segmented-control device-style">
					<button class="active" value="ios">iOS</button>
					<button value="android">Android</button>
				</div>
				<div class="preview editable preview-mode">
					<div class="preview-header">
						<div class="status-bar"></div>
						<div class="title inline-editable" data-attribute="title" data-placeholder="{{t "editor.inspector.properties.title.title"}}">{{getLocaleText title}}</div>
					</div>
					<div class="preview-content {{class}}"></div>
				</div>
			</div>
			<button class="button round big add-object-button hidden">
				<i class="icon icon-plus"></i>
			</button>
		{{/if}}
	</div>
	<div class="col-md-5 inspector-container"></div>
</div>
