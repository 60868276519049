<form class="row">
	<div class="col-xs-12">
		<div class="row">
			<div class="col-sm-6">
				<legend data-i18n="arpro.codeGroup.editItem"></legend>
			</div>
			<div class="col-sm-6 actions-col">
				<a href="javascript:history.back()"><button class="button red wide" type="button">{{t "common.cancel"}}</button></a>
				<button class="button green wide save" type="submit">{{t "common.save"}}</button>
			</div>
		</div>
	</div>
	<div class="container">
	<!-- Burning Rate -->
	<!-- <div class="form-group row">
		<label class="control-label col-xs-3" for="burningRate-input">{{t "arpro.search.burningRate"}}</label>
		<div class="col-xs-4">
			<input type="number" class="form-control" id="burningRate-input" data-code="en" value="{{burningRate}}">
		</div>
	</div> -->

	<!-- Compression Strength 25 -->
	<div class="form-group row">
		<label class="control-label col-xs-3" for="compressionStrength25-input">{{t "arpro.search.compressionStrength25"}}</label>
		<div class="col-xs-4">
			<input type="number" class="form-control" id="compressionStrength25-input" data-code="en" value="{{compressionStrength25}}">
		</div>
	</div>

	<!-- Compression Strength 50 -->
	<div class="form-group row">
		<label class="control-label col-xs-3" for="compressionStrength50-input">{{t "arpro.search.compressionStrength50"}}</label>
		<div class="col-xs-4">
			<input type="number" class="form-control" id="compressionStrength50-input" data-code="en" value="{{compressionStrength50}}">
		</div>
	</div>

	<!-- Compression Strength 75 -->
	<div class="form-group row">
		<label class="control-label col-xs-3" for="compressionStrength75-input">{{t "arpro.search.compressionStrength75"}}</label>
		<div class="col-xs-4">
			<input type="number" class="form-control" id="compressionStrength75-input" data-code="en" value="{{compressionStrength75}}">
		</div>
	</div>

	<!-- Equivalent Youngs Modulus -->
	<div class="form-group row">
		<label class="control-label col-xs-3" for="equivalentYoungsModulus-input">{{t "arpro.search.equivalentYoungsModulus"}}</label>
		<div class="col-xs-4">
			<input type="number" class="form-control" id="equivalentYoungsModulus-input" data-code="en" value="{{equivalentYoungsModulus}}">
		</div>
	</div>

	<!-- Moulded Density -->
	<div class="form-group row">
		<label class="control-label col-xs-3" for="mouldedDensity-input">{{t "arpro.search.mouldedDensity"}}</label>
		<div class="col-xs-4">
			<input type="number" class="form-control" id="mouldedDensity-input" data-code="en" value="{{mouldedDensity}}">
		</div>
	</div>

	<!-- Resilience 75 Dynamic Compression -->
	<div class="form-group row">
		<label class="control-label col-xs-3" for="resilience75DynamicCompression-input">{{t "arpro.search.resilience75DynamicCompression"}}</label>
		<div class="col-xs-4">
			<input type="number" class="form-control" id="resilience75DynamicCompression-input" data-code="en" value="{{resilience75DynamicCompression}}">
		</div>
	</div>

	<!-- Tensile Elongation -->
	<!-- <div class="form-group row">
		<label class="control-label col-xs-3" for="tensileElongation-input">{{t "arpro.search.tensileElongation"}}</label>
		<div class="col-xs-4">
			<input type="number" class="form-control" id="tensileElongation-input" data-code="en" value="{{tensileElongation}}">
		</div>
	</div> -->

	<!-- Tensile Strength -->
	<!-- <div class="form-group row">
		<label class="control-label col-xs-3" for="tensileStrength-input">{{t "arpro.search.tensileStrength"}}</label>
		<div class="col-xs-4">
			<input type="number" class="form-control" id="tensileStrength-input" data-code="en" value="{{tensileStrength}}">
		</div>
	</div> -->
</div>

</form>
