var Permissions = require('./permissions-structure')

module.exports = {
	parse: function(permissions) {
		if (Object.keys(permissions).length === 0) {
			return {}
		}

		var selectedPermissions = {}

		$.each(Permissions, function(group, tabs) {
			$.each(tabs, function(tab, actions) {
				$.each(actions, function(action, systems) {
					var selectedGroup = selectedPermissions[group] = selectedPermissions[group] || {}
					var outcome = true

					$.each(systems, function(system, controllers) {
						var systemPermissions = permissions[system]

						$.each(controllers, function(controller, methods) {
							var modelController = (systemPermissions[controller]) ? systemPermissions[controller] : {}

							$.each(methods, function(method) {
								var modelValue = modelController[method] || false

								if (App.developerMode && outcome && !modelValue) {
									console.warn('No ' + group + ' ' + tab + ' ' + action + ' permission. Missing ' + controller + '/' + method)
								}

								outcome = outcome && modelValue
							})
						})
					})

					if (outcome) {
						selectedGroup[tab] = action
					} else {
						selectedGroup[tab] = selectedGroup[tab] || ''
					}
				})
			})
		})

		return selectedPermissions
	}
}
