module.exports = Backbone.View.extend({
	template: require('./login-view-template'),

	el: '#login',

	events: {
		'click .login-button': 'login',
		'click .forgot-submit-button': 'resetPassword',
		'click .forgot-button': 'forgotPassword',
		'click .forgot-return-button': 'forgotCancel',
		'keypress .username, .password': 'loginKeyPress',
		'keypress .email': 'forgotKeyPress',
		'change .client-language-select': 'languageChange'
	},

	initialize: function() {
		this.session = App.session
		if (localStorage.getItem('lang')) {
			this.currentLang = localStorage.getItem('lang')
			this.setLanguage()
			this.update_il8next(this.currentLang)
		}
		this.render()

		// Show login whenever the session expires.
		this.listenTo(this.session, 'change:isAuthenticated', function() {
			if (!this.session.get('isAuthenticated')) {
				App.showLoginOverlay()
			}
		}, this)
	},

	afterRender: function() {
		// Set current language value
		this.$('.client-language-select').val(App.clientLang)
	},

	show: function() {
		this.render()
		this.$el.show()

		var username = localStorage.getItem('username') || ''

		if (username) {
			this.$('.username').val(username)
			this.$('.password').focus()
		} else {
			this.$('.username').focus()
		}
	},

	hide: function() {
		this.$el.hide()
	},

	login: function() {
		var username = this.$('.username').val()
		var password = this.$('.password').val()

		if (username && password) {
			this.$('.login-button').attr('disabled', true)

			this.session.authenticate(username, password).then(function() {
				if (App.apiRoot.includes('auth.cubeapis')) {
					// We're on the auth page, don't set auth, only display message.
					this.$('.login-button').removeAttr('disabled')
					this.$('.login-inner').addClass('login success')
				} else {
					localStorage.setItem('auth', JSON.stringify(this.session))
					localStorage.setItem('username', username)

					this.$('.login-inner').removeClass('error')
					this.$('.login-button').removeAttr('disabled')

					this.$el.hide()

					// Remove blur from rest of content
					$('#container, #navigation').removeClass('blur')

					// If this isn't a re-authentication, reload the app.
					if (!Storm.view) {
						document.location = App.initialRoute || '/'
					}
				}
			}.bind(this), function() {
				this.$('.login-inner').addClass('error')
				this.$('.login-button').removeAttr('disabled')

				// For 401/429 errors, show countdown on the login button.
				var nextAttempt = this.session.get('loginThrottle'),
					remaining = nextAttempt - parseInt(Date.now() / 1000, 10)

				if (remaining) {
					this.setLoginButtonTimeout(remaining)
				}
			}.bind(this))
		}

		return false
	},

	resetPassword: function() {
		var email = this.$('.email').val()
		if (!email) {
			return false
		}

		this.$('.forgot-submit-button').attr('disabled', true)

		$.ajax({
			url: App.authRoot + 'passwords',
			type: 'POST',
			dataType: 'json',
			contentType: 'application/json; charset=utf-8',
			data: JSON.stringify({
				email: email
			}),
			global: false,
			success: function(data) {
				if (data.error) {
					swal($.t('error.oops'), data.error, 'error')
				} else {
					this.$('.login-inner').addClass('success')
					this.$('.forgot-submit-button').removeAttr('disabled')
				}
			}.bind(this),
			error: function() {
				swal($.t('error.oops'), $.t('login.error.emailNotFound'), 'error')
				this.$('.forgot-submit-button').removeAttr('disabled')
			}.bind(this)
		})

		return false
	},

	loginKeyPress: function(e) {
		// Submit when return pressed
		if (e.keyCode === 13) {
			this.$('.login-button').click()
		}
	},

	forgotKeyPress: function(e) {
		// Submit when return pressed
		if (e.keyCode === 13) {
			this.$('.forgot-button').click()
		}
	},

	forgotPassword: function() {
		this.$('.login-inner').removeClass('error').addClass('forgot')
		this.$('.email').focus()
	},

	forgotCancel: function() {
		this.$('.login-inner').removeClass('forgot')
		this.$('.username').focus()
	},

	languageChange: function() {
		App.clientLang = this.$('.client-language-select').val()
		this.currentLang = App.clientLang
		this.update_il8next(this.currentLang)
		this.setLanguage()
		// Set language to localStorage
		localStorage.setItem('lang', App.clientLang)
		this.render()
	},

	update_il8next: function(lang) {
		// Force langage change with il8next
		App.il8next.changeLanguage(lang, function(err, t) {
			if (err) {
				console.error('Could not load language ', lang, t)
				return
			}
		})
	},

	setLoginButtonTimeout: function(remaining) {
		if (remaining <= 0) {
			// Re-enable button and remove timer.
			this.$('.login-button').removeAttr('disabled')
			this.$('.login-timeout').text('')
		} else {
			var minutes = parseInt(remaining / 60, 10)
			var seconds = remaining % 60
			var countdownText = ((minutes) ? minutes + ':' : '') + ((seconds < 10) ? '0' : '') + seconds

			// Disable button and update countdown
			this.$('.login-button').prop('disabled', true)
			this.$('.login-timeout')
				.text(' (' + countdownText + ')')

			setTimeout(function() {
				this.setLoginButtonTimeout(remaining - 1)
			}.bind(this), 1000)
		}
	},

	setLanguage: function() {
		if (this.currentLang) {
			App.clientLang = this.currentLang
		}
	}
})
