{
	"error": {
		"noPermission": "您没有权限执行该项操作。",
		"noAccess": "您没有访问此系统的权限。请联系您的管理员。",
		"noAccessJoin": "您目前没有访问该系统的权限。你想加入吗？",
		"saveFailed": "保存失败。请刷新页面。",
		"connect": "无法连接到API服务器上。",
		"noApps": "您不必访问任何应用程序",
		"rootPage": "找不到根页。",
		"generic": "错误已经发生。",
		"exitWarning": "应用程序数据仍然被保存。如果您离开该页面现在可能会丢失数据。",
		"allFields": "请填写所有字段。",
		"cantPaste": "这个对象类型不能粘贴在这里。",
		"lockedBy": "这个对象目前锁定：",
		"oops": "哎呀！"
	},
	"common": {
		"save": "节省",
		"submit": "递交",
		"cancel": "取消",
		"next": "下",
		"finish": "完",
		"close": "关闭",
		"back": "回",
		"preview": "预览",
		"publish": "发布",
		"upload": "上传",
		"done": "完成",
		"tag": "标签",
		"page": "页码",
		"view": "视图",
		"email": "电子邮件地址",
		"username": "用户名",
		"add": "添加",
		"edit": "编辑",
		"tools": "工具",
		"description": "描述",
		"position": "位置",
		"warning": "警报",
		"settings": "设置",
		"clickToBrowseMap": "点击浏览地图",
		"closeThisMessage": "关闭此消息",
		"pleaseWait": "请稍候",
		"pageTags": {
			"learn": "学习",
			"prepare": "准备",
			"emergency": "紧急",
			"tests": "测试",
			"info": "信息",
			"other": "其它"
		},
		"linkTypes": {
			"external": "外部",
			"internal": "内部",
			"native": "本地人",
			"uri": "统一资源标识符",
			"timer": "定时器",
			"call": "呼叫",
			"sms": "短讯服务",
			"share": "共享",
			"emergency": "紧急",
			"appLink": "跨应用程序",
			"email": "电子邮件"
		},
		"linkDescriptions": {
			"external": "链接到外部网页。将应用程序中打开。",
			"internal": "在应用程序内的链接到不同的页面。",
			"native": "在应用程序内的链接，一块原生功能。",
			"uri": "链接到外部网页或应用程序。会导致用户离开应用程序。",
			"timer": "这将启动设备上的倒计时器的链接。",
			"call": "链接到从用户的设备拨打电话号码。",
			"sms": "链接发送从用户的设备SMS消息。",
			"share": "链接通过设备上的本地“共享”对话框中，分享的应用程序。",
			"emergency": "链接到拨号用户的设置中指定的紧急电话号码。",
			"appLink": "在另一个应用程序链接到一个页面。",
			"email": "发送电子邮件到指定的地址的链接。"
		},
		"filter": "滤器",
		"reject": "拒绝",
		"approve": "批准",
		"language": "语言",
		"approved": "批准",
		"sections": "第",
		"longLoad": "检索大量数据可能需要一分钟。"
	},
	"login": {
		"inputs": {
			"password": "密码"
		},
		"error": {
			"title": "登录",
			"body": "您所输入的用户名或密码不正确。",
			"emailNotFound": "电子邮件地址未找到。",
			"linkExpired": "此密码重置链接已经失效。"
		},
		"forgot": {
			"title": "我们有时都忘了！",
			"body": "输入电子邮件地址，链接到您的帐户。"
		},
		"success": {
			"title": "成功了！",
			"body": "你会在短期内收到一封电子邮件，说明重置您的密码。",
			"loginBody": "您已成功登录，但无处可去！您在auth.3sidedcube.com。请转到您所在组织的Storm CMS。"
		},
		"links": {
			"forgot": "我忘了......",
			"return": "返回登陆"
		},
		"reset": {
			"newPassword": "新密码",
			"confirm": "确认",
			"submit": "提交",
			"success": "您的密码已设置，您现在可以关闭此窗口。"
		},
		"title": "登录"
	},
	"nav": {
		"navigate": "导航",
		"logout": "退出",
		"items": {
			"appSetup": {
				"title": "应用程序设置",
				"description": "配置应用程序的名称、图标、当地语言和应用程序的存储信息。"
			},
			"content": {
				"title": "内容",
				"description": "编辑应用程序的内容"
			},
			"publish": {
				"title": "发布",
				"description": "更改应用程序以便使用"
			},
			"stats": {
				"title": "统计和分析",
				"description": "观察此应用程序如何运作"
			},
			"userAgreement": {
				"title": "用户协议",
				"description": "回顾并签署全球应用程序项目协议"
			},
			"users": {
				"title": "用户",
				"description": "管理所有用户"
			},
			"stories": {
				"title": "报道",
				"description": "管理故事"
			},
			"levels": {
				"title": "级别",
				"description": "管理应用水平的进步"
			},
			"badges": {
				"title": "徽章",
				"description": "管理徽章授予在应用程序中完成的活动“"
			},
			"push": {
				"title": "推送通知",
				"description": "管理和发送推送通知用户"
			},
			"sprint": {
				"title": "状态板",
				"description": "查看当前的发展现状冲刺"
			},
			"bugs": {
				"title": "错误报告",
				"description": "用编辑器或应用程序构建报告问题"
			},
			"gdpcConsole": {
				"title": "国家红会的控制台",
				"description": "查看国家社会与开发泳池状态"
			},
			"hazards": {
				"title": "灾害和警报",
				"description": "优先级，灾害管理，警报与节制"
			},
			"decisionTree": {
				"title": "决策树",
				"description": "查看和编辑决策树的应用"
			},
			"admin": {
				"title": "管理员",
				"description": "查看统计数据，编辑用户和组织细节"
			}
		}
	},
	"localisations": {
		"title": "本地化",
		"string": "弦",
		"key": "按键",
		"addNew": "添加新的本地化",
		"addMultiple": "添加多个本地化",
		"overwriteExisting": "覆盖现有的本地化",
		"uploadCsv": "上传CSV",
		"copyFromAnotherApp": "从另一个应用程序复制",
		"addMultipleInstructions": "添加或更新批量本地化。使用CSV格式",
		"all": "<b>所有</b>本地化",
		"overwriteWarning": "所有现有的翻译将被覆盖。",
		"errors": {
			"invalidKey": "无效的定位键。钥匙必须以下划线，只包含大写字母和下划线。钥匙必须至少包含两个部分（比特用下划线分隔）。",
			"csvTooManyColumns": "太多的列标题。",
			"csvInvalidHeader": "标题无效。",
			"csvInvalidKeyOnLine": "在线无效的定位键",
			"csvInvalidColumnCountOnLine": "对行的列数无效",
			"expected": "预期",
			"got": "有"
		},
		"confirmCopy": "你确定你想从所选择的应用程序复制本地化？"
	},
	"appSetup": {
		"title": "应用程序设置",
		"appName": "应用程序名称",
		"startPage": "开始页",
		"languages": "应用程序语言",
		"localisation": "应用本地化",
		"publishable": "可发布到：",
		"dev": "显影",
		"live": "带电",
		"key": "答案",
		"value": "值",
		"properties": "属性",
		"publishableLanguages": "发布的语言",
		"addMoreLanguages": "只分配给该组织的语言可以在这里找到。分配更多的管理部分。",
		"translationError": "无法下载翻译文件。请检查应用程序和组织都至少有一个语言启用并发布的。",
		"expandAll": "全部展开",
		"addCsv": "添加CSV",
		"bundleIdentifiers": "SDK包标识符",
		"type": "类型",
		"translation": "翻译"
	},
	"metadata": {
		"title": "元数据",
		"noBuildPlatforms": "没有构建平台，这个应用程序存在",
		"appStore": "App Store的详细信息",
		"name": "应用程序名称",
		"description": "描述",
		"keywords": "关键词",
		"primaryCategory": "主分类",
		"secondaryCategory": "二级类别",
		"copyright": "版权",
		"website": "营销网址",
		"supportUrl": "支持URL",
		"privacyPolicyUrl": "隐私政策网址",
		"phoneNumber": "电话号码",
		"launchDate": "发射日期",
		"appIcon": "应用程序图标",
		"iconSize": "原来的图标必须1024x1024的大小。",
		"addScreenshot": "添加截图",
		"appIconHint": "应该是1024×1024，没有透明度。必须是JPG或PNG格式，具有至少72的DPI的一个最小分辨率，并且在RGB颜色范围。它不能包含图层或圆角",
		"appPreviewVideo": "应用程序预览视频",
		"appPreviewVideoHint": "应用视频预览必须在M4V，MP4或MOV格式，并且不能超过500 MB",
		"appStoreTitle": "应用商店的标题",
		"delete": "删除",
		"emailHint": "提供一个电子邮件地址，如果App Store或用户需要取得联系",
		"featureGraphic": "功能图形",
		"featureGraphicHint": "在谷歌Play商店的应用程序需要有一个功能，图形或“旗帜” - 尺寸应1024x500。 这可以是一个独立的图像，或个电视节目预告的视频的背景图像（如果提供）",
		"languages": "语言",
		"nameOnDevice": "在设备上应用程序的名称",
		"phoneNumberHint": "必须包括国家区号",
		"platformSpecific": "平台特定的元数据",
		"price": "价格",
		"rating": "等级",
		"screenshotsHint": "包括每个屏幕大小的截图。截图必须在JPG或PNG格式，并且在RGB颜色范围",
		"shared": "跨平台的元数据",
		"shortAppDescription": "简短的应用介绍",
		"supportUrlHint": "一个URL与您的应用程序的支持信息。此URL将在App Store可见",
		"websiteHint": "一个URL与有关您的应用程序的营销信息。此URL将在App Store可见",
		"features": "特征"
	},
	"plugins": {
		"title": "插件",
		"availablePlugins": "可用的插件"
	},
	"editor": {
		"title": "编辑",
		"pageName": "页面名称",
		"pageType": "页面类型",
		"pageStyle": "页面风格",
		"pageTag": "页面标签",
		"addPage": "添加页",
		"saving": "正在保存...",
		"lastSaved": "上次保存：",
		"never": "从来没有",
		"newFromTemplate": "新的模板",
		"pageList": {
			"title": "页码",
			"filter": "过滤器的网页",
			"allPages": "所有页面"
		},
		"addObject": "在这里添加对象",
		"addQuiz": "添加测验",
		"addApp": "添加应用程序",
		"addLink": "添加链接",
		"confirmRelock": "这个对象已经锁定了你。你想再次锁定了吗？您以前的锁将被撤销。",
		"confirmRequestNewLock": "您锁定了此页面已被吊销。你想申请一个新的锁？",
		"savesQueued": "节省排队",
		"inspector": {
			"title": "编辑属性",
			"addAnother": "添加另一个",
			"addBulletItem": "添加项目符号项",
			"areYouSure": "你确定吗？",
			"rootPageDelete": "这是应用程序的根页。它不能被删除。",
			"confirmDelete": "你确定要删除这个部分吗？",
			"confirmPageDelete": "确认删除页面",
			"confirmPageDeleteWarning": "警告：你正删除页面。所有页面内容将被删除。你确定要继续吗？",
			"moveUp": "提前",
			"moveDown": "下移",
			"copy": "复制",
			"paste": "贴",
			"copySuccess": "对象复制到剪贴板",
			"changeType": "更改类型",
			"newType": "新型",
			"properties": {
				"annotation": {
					"title": "注解",
					"description": "步数"
				},
				"title": {
					"title": "标题",
					"description": "列表项标题"
				},
				"name": {
					"title": "名称",
					"description": "内部系统名称"
				},
				"hint": {
					"title": "示意",
					"description": "用户说明"
				},
				"image": {
					"title": "图像",
					"chooseButton": "选择图片",
					"removeButton": "删除图像"
				},
				"video": {
					"title": "视频",
					"chooseButton": "选择视频",
					"attributes": "属性",
					"loop": "循环",
					"addLanguage": "新增语言"
				},
				"link": {
					"title": "链接",
					"type": "类型",
					"destination": "目的地",
					"body": "正文文本",
					"duration": "定时时间（分钟）",
					"recipients": "收件人（每行一个）",
					"testLink": "测试环节",
					"linkDestination": "链接目标",
					"linkTitle": "链接标题"
				},
				"images": {
					"add": "添加图片"
				},
				"animation": {
					"chooseButton": "选择动画速度",
					"delay": "帧延迟时间（秒）"
				},
				"embeddedLinks": {
					"title": "嵌入式链接",
					"add": "添加链接"
				},
				"embeddedMedia": {
					"title": "嵌入式媒体"
				},
				"header": {
					"title": "标题",
					"description": "出现上述项目组"
				},
				"footer": {
					"title": "页脚",
					"description": "下方会出现一组项目"
				},
				"badge": {
					"title": "徽章",
					"icon": "图标",
					"completion": {
						"title": "完成文本",
						"description": "向用户显示测试完成"
					},
					"how": {
						"title": "如何赚取",
						"description": "文字描述如何实现这个徽章"
					}
				},
				"units": {
					"title": "单位",
					"description": "秒/米等。"
				},
				"range": {
					"title": "范围",
					"start": "开始",
					"length": "长度",
					"increment": "增量"
				},
				"completionText": {
					"title": "完成文本",
					"description": "向用户显示答案正确"
				},
				"failureText": {
					"title": "故障文本",
					"description": "向用户显示答案不正确"
				},
				"winMessage": {
					"title": "获得信息",
					"description": "向用户显示通过测试"
				},
				"loseMessage": {
					"title": "失去信息",
					"description": "向用户显示测试失败"
				},
				"shareMessage": {
					"title": "分享信息",
					"description": "在社交网络上文本与徽章共享"
				},
				"volatile": "挥发份（不保持状态）",
				"textContent": "文本内容",
				"quizzes": "测验",
				"answer": "答案",
				"selectionLimit": "选择限制",
				"correctAnswer": "正确的答案",
				"initialPosition": "初始位置",
				"relatedLinks": "相关链接",
				"winRelatedLinks": "赢得相关链接",
				"loseRelatedLinks": "失去相关链接",
				"caption": "标题",
				"delay": "延迟时间（秒）",
				"placeholderImage": "图像占位符",
				"button": "钮",
				"standard": "标准",
				"columns": "柱",
				"quiz": "测验",
				"overlay": "叠加文字",
				"progressMessage": "进展消息",
				"gridType": "网格类型",
				"confirmGridTypeChange": "你确定你想改变网格类型吗？这将改变所有的网格项目。",
				"winThreshold": {
					"title": "赢门槛（％）",
					"description": "通过测验所需的正确答案百分比"
				}
			},
			"swapItem": "交换项目",
			"copiedItem": "复制的项目",
			"confirmApproval": "你确定要批准吗？"
		},
		"objectPreviews": {
			"DescriptionListItem": {
				"title": "此项目有一个标题和描述",
				"description": "当你有很多要说的时候可以使用此项"
			},
			"StandardListItem": {
				"title": "大部分时间使用此项",
				"description": "它有一个标题，描述和图标。您可以留一些空白 - 比如说图标"
			},
			"ToggleableListItem": {
				"title": "当你有东西要隐藏，请使用此",
				"description": "这个文本可以由用户显示和隐藏。适合很多细节使用 - 比如常见问题"
			},
			"OrderedListItem": {
				"title": "有序列表",
				"description": "你想要顺序的时候可以使用"
			},
			"UnorderedListItem": {
				"title": "无序列表",
				"description": "用于清单使用，但只是要点"
			},
			"HeaderListItem": {
				"title": "使用这个在页面的顶部",
				"description": "它可以有一个标题和一个较长的描述"
			},
			"LogoListItem": "这个用于类似图标，你想要图像，标题和链接的时候",
			"SpotlightListItem": {
				"first": "旋转",
				"second": "聚光灯"
			},
			"TitleListItem": "使用此项目文本单行",
			"TextListItem": "该项目用于导入文本，通常在一个页面顶部",
			"CheckableListItem": "用于清单使用",
			"ButtonListItem": "使用它如果你想有一个按钮",
			"ChunkyListItemView": {
				"title": "这就像标准的列表项 - 有更多的填充",
				"description": "它有一个标题，描述和图标。您可以留一些空白 - 比如说图标"
			},
			"ListItemView": "该项目只有有一个标题"
		}
	},
	"mediaLibrary": {
		"title": "媒体库",
		"refine": "缩小结果",
		"filterByTags": "通过标签筛选",
		"type": "媒体类型",
		"image": "图像",
		"video": "视频",
		"icon": "图标",
		"animation": "动画",
		"section": "部分",
		"society": "社会",
		"general": "普通",
		"additionalTags": "额外的标签",
		"commas": "用逗号分隔",
		"clearFilters": "清除过滤器",
		"editMedia": "编辑媒体",
		"delete": "删除",
		"dragToReorder": "拖动重新排序",
		"confirmTagRemoval": "您确定要删除这个标签？",
		"upload": {
			"title": "上传媒体",
			"tags": "标签",
			"newTag": "新标签",
			"agreementText": "全部内容上传到这个系统...",
			"filesizeError": "上传失败。该文件太大。允许的最大文件为...",
			"formatError": "上传失败。无效的文件格式。允许的图像格式为：JPEG，PNG，GIF。允许的图像格式为：MP4，M4V。",
			"genericError": "文件上传失败。错误代码：",
			"selectType": "请选择图像上载的类型",
			"imageDesc": "推荐用于边缘<b>640×320</b>像素的图像边缘图像，幻灯片图像，问答图像和视频图像。测验的图像应该是<b>640×640</b>像素。",
			"invalidTag": "无效的标签。标签可能不包含通配符（*）符号。",
			"instructions": "点击这里或拖动文件开始",
			"errors": {
				"tooManyFiles": "请选择一个文件"
			},
			"square": "方形",
			"noCrop": "没有截图",
			"cropType": "截图类型",
			"squareDesc": "该截图用于图标和测验图像。测验图像应该是<b>640（w）的</b> <b>640（H）</b>像素。",
			"headerDesc": "使用该作物中出现在页面顶部的大标题图片。图像应是<b>640（w）的</b>在尺寸<b>568（H）</b>像素。",
			"spotlightDesc": "使用这种作物的射灯，边到边，幻灯片和视频图像。图像应是<b>640（w）的</b>通过在<b>尺寸</b>为<b>320（H）</b>像素。",
			"requiresTransparency": "需要透明背景",
			"gdpcHeaderDesc": "使用这种作物在危险的应用程序大标题图片。图像应是<b>640（w）的</b>在尺寸<b>568（H）</b>像素。",
			"gdpcSpotlightDesc": "使用这种作物的标题中急救的应用程序，射灯，边到边，幻灯片和视频图像。图像应是<b>640（w）的</b>通过在<b>尺寸</b>为<b>320（H）</b>像素。"
		},
		"chooseExisting": "选择现有的",
		"loadMore": "装载更多"
	},
	"viewPicker": {
		"title": "对象",
		"filter": "过滤对象",
		"basicObjects": "基本对象",
		"multiLineObjects": "多线对象",
		"mediaObjects": "媒体对象",
		"interactiveObjects": "互动对象",
		"text": "文本",
		"subText": "子文",
		"leadInText": "引入文本",
		"hiddenText": "隐藏文本",
		"spotlight": "聚光灯",
		"TextListItem": "群体之间的边到边文本",
		"TitleListItem": "单行文字",
		"StandardListItem": "一个标题，链接和图标",
		"DescriptionListItem": "标题和多行文字说明",
		"ToggleableListItem": "显示较长文本内容的点击标题",
		"OrderedListItem": "标题和多行文字内容，批注或步数",
		"UnorderedListItem": "带有标题和描述的列表",
		"CheckableListItem": "一个标题和一个复选框。状态在不同用户之间使用",
		"ButtonListItem": "标题和按钮",
		"ImageListItem": "一个图形，边到边",
		"HeaderListItem": "大图像叠加的标题和描述",
		"VideoListItem": "支持多个来源的视频对象",
		"AnimatedImageListItem": "幻灯片播放的系列图像，实现动画",
		"SpotlightImageListItem": "一个或多个焦点的图像，包含一个图像，一个标题和一个链接",
		"LogoListItem": "用来显示公司品牌",
		"SliderQuizItem": "图片滑块选择问题",
		"TextQuizItem": "文本选择问题",
		"ImageQuizItem": "图像选择问题",
		"AreaQuizItem": "区域选择问题",
		"CollectionListItem": "一个“收集所有”视图的应用程序，徽章和链接",
		"dragAndDrop": "拖放对象，将它们添加到页面"
	},
	"publish": {
		"title": "发布",
		"auditTrail": "逐位跟踪",
		"noChanges": "没有变化",
		"lastModified": "上次修改",
		"publishToDev": "发布到开发",
		"publishToLive": "发布",
		"objectType": "对象类型",
		"detail": "细节",
		"details": "详细信息",
		"time": "时间",
		"startTime": "开始时间",
		"endTime": "时间结束",
		"user": "用户",
		"selectAll": "全选",
		"error": "请选择一个或多个页面发布。",
		"warning1": "您选择了下面的页面，通过增量更新发布到的iOS和Android应用。",
		"warning2": "请彻底检查所有更改，然后再继续在页面底部并确认您的选择。",
		"comment": "评论",
		"publishConfirm": "本人确认已审阅所有选定的变化和权限，把他们推住",
		"toApps": "发布应用程序",
		"pleaseConfirm": "请检查复选框以确认您已审阅所有更改。",
		"publishing": "出版...",
		"progress1": "你选择的变化现在正在出版。",
		"progress2": "这可能需要一段时间。不要离开此页面导航。",
		"success": "发布成功。",
		"fail": "发布失败。",
		"history": "历史",
		"publishType": "发布类型",
		"test": "实验",
		"live": "活的",
		"publishLandmark": "地标发布？",
		"progress3": "看起来这需要一段时间。你可以稍后回来。"
	},
	"publishHistory": {
		"title": "发布历史",
		"previousPublishes": "上一页出版"
	},
	"agreement": {
		"title": "全球应用程序项目协议",
		"hazardsTitle": "危害通用应用程序协议",
		"details": "协议细节",
		"status": "状态",
		"sign": "签署",
		"signed": "已签署",
		"notSigned": "未签署",
		"daysRemaining": "__count__ 剩余天数",
		"daysRemaining_plural": "__count__ 剩余天数",
		"signedBy": "签名",
		"date": "日期",
		"nationalSociety": "国家红会",
		"viewThe": "查看...",
		"signAgreement": "签署协议",
		"fullName": "全名",
		"email": "电子邮件地址",
		"iAgree": "我同意条款中给出...",
		"checkToAgree": "请检查选框，以确认您已阅读并同意这些条款。"
	},
	"users": {
		"title": "用户",
		"list": "用户列表",
		"name": "名",
		"firstName": "名字",
		"lastName": "姓",
		"organisation": "机构",
		"role": "角色",
		"roles": "角色",
		"apps": "应用程序",
		"lastActive": "最新动态时间 ",
		"success": "成功了！新用户将收到一封电子邮件，说明激活他们的帐户。",
		"complete": "用户成功创建",
		"error": "无法创建用户。检查用户名和电子邮件尚未注册。",
		"createUser": "创建用户",
		"usernameHelp": "用户名只能包含字母，数字，下划线，破折号和点。",
		"removeUserConfirm": "你确定要删除此用户？",
		"failedToRemove": "无法删除用户"
	},
	"roles": {
		"title": "用户角色",
		"list": "角色一览",
		"permissions": "权限"
	},
	"permissions": {
		"title": "角色权限",
		"edit": "编辑权限",
		"none": "无",
		"read": "阅读",
		"write": "写",
		"delete": "写入/删除"
	},
	"apps": {
		"title": "分配应用程序",
		"subtitle": "将应用程序分配给用户",
		"app": "应用程序",
		"assignNew": "分配新的应用程序",
		"assign": "分配"
	},
	"stories": {
		"title": "报道",
		"list": "案例列表",
		"created": "创建日期",
		"subtitle": "字幕",
		"content": "内容",
		"editStory": "编辑故事"
	},
	"badges": {
		"title": "徽章",
		"list": "徽章列表",
		"editBadge": "编辑徽章"
	},
	"levels": {
		"title": "级别",
		"list": "各级清单",
		"editLevel": "编辑等级",
		"details": "水平细节",
		"children": "平儿"
	},
	"push": {
		"showAllApps": "显示所有的应用程序",
		"existing": "现有的推送通知",
		"category": "类别",
		"notes": "笔记",
		"type": "类型",
		"numberSent": "总",
		"numberCompleted": "完成",
		"createdBy": "创建者",
		"priority": "优先",
		"editPush": "编辑推送通知",
		"pushDetails": "按通知的详细信息",
		"assignments": "作业",
		"noPageSpecified": "没有指定的页面",
		"approve": "批准",
		"reject": "拒绝",
		"message": "信息",
		"payload": "有效载荷",
		"saveAndSend": "保存和发送",
		"noNotifications": "无通知",
		"sending": "发出",
		"sent": "推送通知发送",
		"categories": {
			"standard": "标准",
			"future": "未来",
			"region": "区域",
			"custom": "习俗"
		},
		"types": {
			"default": "默认"
		},
		"priorities": {
			"low": "低",
			"high": "杲"
		},
		"custom": {
			"area": "目标区域",
			"areaHelp": "点击地图可以给此通知定义一个目标区域"
		},
		"error": {
			"length": "推送通知消息过长",
			"messageEmpty": "请输入信息",
			"missingRegion": "请选择一个区域"
		},
		"messageDescription": "推送通知信息",
		"what": "你想送什么？",
		"when": "你想什么时候发呢？",
		"addToQueue": "添加到队列"
	},
	"bugs": {
		"title": "错误",
		"report": "报告",
		"listOpen": "公开报告清单",
		"listPending": "未决报告一览",
		"listClosed": "封闭的报告清单",
		"version": "版本",
		"build": "调整",
		"device": "设备/操作系统版本",
		"screenshots": "截图",
		"reportBug": "报告错误",
		"platformDetails": "平台详细信息",
		"issueDetails": "发行详情",
		"steps": "重现步骤",
		"expected": "预期的行为",
		"actually": "实际行为",
		"noReports": "无报告",
		"bugReport": "错误报告",
		"comments": "评论",
		"noComments": "没有评论",
		"addComment": "添加评论",
		"loadingDetails": "加载中错误的详细信息...",
		"closeIssue": "关闭问题",
		"reopenIssue": "重新打开的问题",
		"loadingFiles": "加载文件...",
		"noFiles": "没有文件",
		"status": {
			"title": "状态",
			"deleted": "删除",
			"open": "公开",
			"fixed": "定",
			"wontFix": "不会修复",
			"postponed": "推迟",
			"duplicate": "副本",
			"notReproducible": "不可复制",
			"byDesign": "通过设计",
			"closed": "关闭"
		}
	},
	"gdpcConsole": {
		"nationalSocieties": {
			"title": "概观",
			"countries": "国家",
			"stage": "阶段",
			"averageRating": "平均评级",
			"downloads": "下载",
			"totalDownloads": "总下载",
			"contact": "联络",
			"pool": "团",
			"goLive": "去住",
			"locale": "语言环境",
			"details": "社会详细",
			"edit": "编辑协会"
		},
		"devPools": {
			"title": "发展现状池",
			"completed": "已完成",
			"noApps": "在这个开发组没有应用程序",
			"editPool": "编辑开发泳池",
			"stages": "阶段",
			"language": "语",
			"expected": "预期",
			"requiredBy": "按要求",
			"responsibility": "责任",
			"estimatedDate": "当前估计的应用程序的日期"
		}
	},
	"hazards": {
		"disasters": {
			"title": "灾害",
			"rootPage": "根页",
			"alertSettings": "警报设置",
			"configure": "配置",
			"enable": "启用",
			"disable": "关闭",
			"saveSuccess": "应用程序配置保存。",
			"saveError": "无法保存应用程序配置。",
			"pleaseSpecifyPages": "为每个选定的灾害类型，请指定页面。",
			"whatNow": "“现在怎么办”页",
			"categories": {
				"tsunami": "海啸",
				"hurricane": "飓风",
				"tornado": "龙卷风",
				"flood": "洪水",
				"wildfire": "磷火",
				"wind": "风",
				"storm": "风暴",
				"earthquake": "地震",
				"heatwave": "热浪",
				"winter": "冬天",
				"volcano": "火山",
				"nuclear": "核",
				"coastal": "沿海"
			},
			"tutorial": {
				"heading": "有什么不同的状态是什么意思？",
				"body": "- “已启用”意味着页面会出现在内容编辑器让你开始工作就可以了。<br>- “开发”是指在网页中会出现开发版本的这个程序。<br>- “实时”意味着页面会出现在应用商店构建这个应用程序。"
			}
		},
		"alerts": {
			"title": "警报",
			"alert": "警报",
			"expired": "期满",
			"alertActive": "警报活动",
			"pin": "绷",
			"pinned": "取消固定",
			"effective": "有效",
			"issuedBy": "发出",
			"noAlerts": "无警示",
			"show": "节目",
			"inTheLast": "在最后",
			"allAlerts": "所有警报",
			"createNewAlert": "创建新警报",
			"testAlert": "创建测试警报",
			"testAlertDesc": "通过此警报测试将被发送到监测南极的位置的所有用户。",
			"specifySinglePolygon": "不支持用于警报多个多边形。请画一个多边形。",
			"times": {
				"allTime": "所有时间",
				"hour": "小时",
				"day": "日",
				"week": "周",
				"month": "月"
			},
			"custom": {
				"types": {
					"announcement": "公告",
					"pin": "寄托页",
					"announcementDesc": "公告用于创建警报不会从其他警报提供者提供的事件。",
					"pinDesc": "寄托页用于功能重要页面，这是时间和地点敏感。"
				},
				"errors": {
					"pleaseSelectRegion": "请选择一个地区进行此警报。",
					"pleaseProvideMessage": "请提供对本公布的消息。",
					"pleaseSelectPage": "请选择一个页面被寄予厚望。",
					"pleaseProvideAreaDescription": "请提供本公告的区域描述。",
					"pleaseProvideEvent": "请为此公告提供活动。",
					"pleaseProvideHeadline": "请提供此公告的标题。",
					"effectiveWrong": "生效日期不能在到期日之后"
				},
				"announcementPage": "该页面显示“现在是什么”一节中。",
				"pinPage": "该页面同时展出。",
				"message": "与此警报发送的消息。",
				"area": "警戒区",
				"areaHelp": "警报将被发送到所选择的区域内监测位置的所有用户。",
				"expiry": "到期日",
				"expiryHelp": "日此警报将被删除。",
				"severity": "严重性",
				"severityHelp": "如何重要的是此警报？",
				"alertQueued": "警报排队等待发送。",
				"drawRegion": "绘制一个围绕区域此警报应发送",
				"notify": "通知",
				"sendNotification": "发送推送通知到这些用户。",
				"pushes": "推",
				"languageHelp": "写入警报的语言。",
				"cap_create": "帽",
				"effective": "生效日期",
				"updateMap": "更新地图",
				"showPolyData": "显示多边形数据",
				"onset": "发生日期",
				"cap_send": "生成CAP警报",
				"onsetHelp": "危险发生的日期。",
				"polygon": "在此添加自定义多边形数据",
				"effectiveHelp": "此警报生效的日期。"
			}
		},
		"title": "危害",
		"generalNotifications": {
			"title": "一般通知",
			"createNotification": "创建通知",
			"customMapData": {
				"moreCoords": "请提供三个以上的坐标",
				"firstAndLast": "第一个和最后一个坐标应该相同",
				"fisrtFoundNotLast": "在坐标内找到的第一个坐标，只有第一个和最后一个坐标应该相同",
				"placeholder": "单击上面的地图或在此处添加至少三个坐标以创建多边形。即[经度，纬度]，[经度，纬度]，[经度，纬度]",
				"notFound": "未找到多边形数据，请至少添加三个坐标。即[经度，纬度]，[经度，纬度]，[经度，纬度]"
			},
			"dropdown": {
				"cancel": "取消",
				"unknown": "未知",
				"update": "更新",
				"alert": "警报",
				"future": "未来",
				"fire": "火",
				"system": "系统",
				"transport": "运输",
				"observed": "观察到的",
				"rescue": "拯救",
				"minor": "次要",
				"extreme": "极端",
				"restricted": "受限",
				"likely": "容易",
				"test": "测试",
				"expected": "预期",
				"moderate": "中等",
				"severe": "严重",
				"actual": "实际",
				"error": "错误",
				"draft": "草案",
				"security": "安全",
				"public": "上市",
				"past": "过去",
				"health": "健康",
				"safety": "安全",
				"other": "其他",
				"private": "私人的",
				"unlikely": "不会",
				"immediate": "即时",
				"possible": "可能"
			},
			"area_description": "区域描述",
			"category": "类别",
			"event": "事件",
			"certainty": "肯定",
			"status": "状态",
			"type": "类型",
			"scope": "范围",
			"headline": "标题",
			"severity": "严重",
			"urgency": "急"
		},
		"alertZones": {
			"title": "警报区",
			"type": "类型",
			"addAlertZone": "添加警戒范围",
			"events": "活动",
			"areaSelectModalTitle": "周围绘制此区域适用于区域",
			"area": "区",
			"editAlertZone": "编辑警报区",
			"blacklist": "黑名单",
			"whitelist": "白名单",
			"name": "名称"
		},
		"feeds": {
			"tutorial": {
				"heading": "使用Feeds",
				"steps": "<ol><li>确保为此应用启用所选的灾难类型和类别。这将确定下面可以启用哪些Feed和事件。 </li><li>选择您希望为此应用提供警报的Feed和事件类型。 </li></ol>",
				"body": "某些警报类型可能来自多个警报源。此部分允许您从此应用的各个Feed中启用或禁用特定Feed和事件类型。"
			},
			"delete": {
				"confirm": "你确定？",
				"yes": "是的，删除它",
				"events": "此Feed将禁用所有已启用的事件。"
			},
			"events": "活动",
			"code": "码",
			"disable": "禁用",
			"type": "类型",
			"title": "饲料",
			"enable": "启用",
			"configure": "配置",
			"scope": "范围",
			"languages": "可用语言",
			"feed": "饲料"
		}
	},
	"appCreate": {
		"title": "创建新的应用程序",
		"details": "应用细节",
		"copyFrom": "从复制内容",
		"emptyApp": "空的应用程序",
		"createNewOrganization": "创建新组织"
	},
	"organization": {
		"title": "组织",
		"details": "组织细节",
		"noLanguages": "分配无语言",
		"confirmLanguageAssignment": "你确定你要分配这个语言呢？语言分配不能被删除。"
	},
	"mailingList": {
		"title": "邮件列表",
		"list": "可用列表",
		"export": "出口",
		"dateFrom": "从",
		"dateTo": "要"
	},
	"moderation": {
		"title": "适度",
		"image": {
			"title": "图片审核",
			"location": "地点"
		}
	},
	"manageApps": {
		"title": "管理应用程序",
		"yourNextApp": "你的下一个应用程序",
		"easilySetUpYourNextApp": "轻松设置你的下一个应用程序"
	},
	"dashboard": {
		"title": "仪表板"
	},
	"manage": {
		"title": "经营"
	},
	"userApps": {
		"title": "用户应用程序"
	},
	"posts": {
		"title": "Posts"
	},
	"achievements": {
		"badge": "徽章",
		"edit": "编辑成就",
		"completion": "完成",
		"title": "成就",
		"image": "图像",
		"name": "名称"
	},
	"triggers": {
		"subclasses": {
			"RaisedTrigger": {
				"amount": "量",
				"singular": "募集金额"
			}
		},
		"plural": "触发器"
	},
	"mind-moderation": {
		"emoodji": {
			"singular": "Emoodji"
		},
		"title": "Emoodji审核",
		"empty": "没有Emoodjis中度"
	},
	"crossBorders": {
		"contact": {
			"editItem": "编辑联系人",
			"number": "电话号码",
			"title": "标题"
		},
		"phrase": {
			"editItem": "编辑短语",
			"translation": "翻译",
			"phrase": "短语"
		},
		"country": {
			"code": "国家代码",
			"description": "目的地国家",
			"name": "国家的名字"
		},
		"contacts": "往来",
		"editItem": "编辑目的地国家",
		"translations": "翻译语",
		"phrases": "短语",
		"title": "跨越国界",
		"list": "目的地名单",
		"link": {
			"title": "标题",
			"placeholder": "目的地标题",
			"destination": "目的地"
		},
		"links": "链接"
	},
	"whatnow": {
		"attribution": {
			"attribution": "归因",
			"url": "归因网址",
			"image": "归因图片",
			"message": "归因信息",
			"socName": "归因社会名称",
			"imageHelp": "图像/图标上传。",
			"messageHelp": "与归属相关的消息。",
			"urlHelp": "链接到有关Attributor的更多信息的URL。",
			"socNameHelp": "该社团的名称将归因于什么。"
		},
		"edit": {
			"moreinfoHelp": {
				"placeholder": "Lorem ipsum dolor sit amet，consectetur adipiscing elit，sed do eiusmod tempor incididunt ut labore et dolore magna aliqua。 Ut enim ad minim veniam，quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat。"
			},
			"warning": "警告",
			"languages": "语言",
			"recover": "恢复",
			"before": "之前",
			"immediateHelp": "响应",
			"after": "后",
			"forecast": "预测",
			"watch": "看",
			"during": "中",
			"recoverHelp": "后",
			"immediate": "即时",
			"translations": "翻译",
			"event": "冒险",
			"title": "标题",
			"description": "描述",
			"moreinfo": "信息URL",
			"missing": "缺少内容",
			"editWhatnow": "编辑现在的内容",
			"selectLang": "选择一种语言",
			"warningHelp": "准备回应",
			"new": "添加一种新语言",
			"watchHelp": "准备回应",
			"midterm": "中期预测",
			"descriptionHelp": "什么现在的描述。",
			"titleHelp": "什么现在的标题。",
			"addALang": "添加语言即可开始使用",
			"eventhelp": "什么现在的事件的类型。",
			"empty": "请添加一种语言来创建一个现在",
			"beforeHelp": "事件发生前如何准备。",
			"duringHelp": "在活动发生时该怎么做。",
			"forecastHelp": "评估和计划（短期行动）",
			"midtermHelp": "降低风险（长期行动）",
			"moreinfohelp": "有关“现在的内容”的更多信息的URL。",
			"afterHelp": "事件发生后该怎么办。"
		},
		"whatnow": "现在怎么办？",
		"before": "之前",
		"description": "描述",
		"title": "标题",
		"after": "后",
		"during": "中",
		"selectSoc": "选择一个社会",
		"content-not-set": "内容未设置",
		"eventType": "冒险",
		"empty": "现在没有什么条目，点击添加创建一个"
	},
	"arpro": {
		"codeGroup": {
			"description": "描述",
			"codes": "代码",
			"name": "名称",
			"codeGroup": "代码组",
			"editItem": "编辑代码组",
			"addCodes": "在这里输入代码"
		},
		"colours": {
			"name": "名称",
			"colours": "颜色",
			"description": "描述"
		},
		"search": {
			"tensileStrength": "抗拉强度",
			"search": "产品搜索",
			"burningRate": "燃烧率",
			"compressionStrength75": "压缩强度75",
			"compressionStrength25": "压缩强度25",
			"compressionStrength50": "压缩强度50",
			"mouldedDensity": "模压密度",
			"resilience75DynamicCompression": "弹性75动态压缩",
			"equivalentYoungsModulus": "等效杨氏模量",
			"tensileElongation": "拉伸伸长率"
		},
		"product": {
			"properties": {
				"bulkLabel": "块",
				"packaging": "打包",
				"bag": "袋",
				"food": "食品批准",
				"weight": "重量（mg）",
				"size": "尺寸（mm）",
				"moulded": "模塑密度（g / l）",
				"bulk": "体积密度（g / l）"
			},
			"notes": "笔记",
			"colour": "颜色",
			"grade": "年级",
			"name": "名称",
			"specifications": "产品规格",
			"access": "访问代码",
			"physicalProps": "物理特性",
			"editItem": "编辑产品",
			"linked": "有访问代码",
			"rawData": "原始JSON数据",
			"noneLinked": "没有访问代码",
			"product": "制品",
			"moulded": "模塑密度范围（g / l）",
			"rawDataError": "原始JSON数据 - 无效的JSON数据"
		},
		"arpro": "ARPRO",
		"noitems": "没有要显示的项目，请单击上面的添加。"
	},
	"r4b": {
		"level": {
			"title": "水平",
			"alpha": "Α",
			"blue": "蓝色",
			"colour": "颜色",
			"red": "红",
			"green": "绿色",
			"levels": "水平",
			"selectBadge": "请选择徽章"
		},
		"modules": {
			"blockContent": {
				"logic": {
					"title": "部分可见性逻辑"
				},
				"slider": {
					"interval": "间隔",
					"startLabel": "开始标签",
					"endLabel": "结束标签"
				},
				"question": {
					"title": "问题和答案",
					"textHelp": "输入一个问题",
					"addResponse": "添加回复",
					"responseHelp": "输入回复",
					"answerHelp": "输入答案",
					"addAnotherResponse": "添加另一个回复",
					"selectionHelp": "选择要继续的部分"
				},
				"quote": {
					"title": "引用",
					"citation": "在这里输入引文"
				},
				"checkbox": {
					"title": "复选框"
				},
				"textInput": {
					"title": "文字输入",
					"multi": "多行文字？",
					"help": "在此输入输入标签"
				},
				"link": {
					"title": "链接"
				},
				"help": {
					"title": "帮助对象"
				},
				"task": {
					"title": "任务对象",
					"help": "输入任务目标"
				},
				"text": {
					"title": "文本对象"
				},
				"radio": {
					"title": "单选按钮"
				},
				"section": "部分",
				"break": "分页符",
				"enterText": "在此输入文字",
				"clickToEdit": "点击编辑",
				"identifier": "识别码",
				"enterTitle": "输入标题",
				"addIdentifier": "添加标识符",
				"inputLabel": "文字输入标签",
				"videoLink": "链接到外部视频",
				"addSection": "在此处拖动项目以创建新部分"
			},
			"section": {
				"title": "部分"
			},
			"blockTypes": {
				"radio": "无线电",
				"image": "图片",
				"quote": "引用",
				"text": "文本",
				"link": "链接",
				"task": "任务",
				"help": "帮帮我",
				"slider": "滑块",
				"qna": "Q＆A",
				"video": "视频",
				"check": "CHECK",
				"media": "媒体块",
				"textInput": "文字输入",
				"userInput": "用户输入块"
			},
			"level": "水平",
			"visibility": "能见度",
			"title": "模块",
			"person": "人",
			"mins": "分钟",
			"people": "人",
			"levels": "水平",
			"module": "模",
			"saved": "保存所有更改",
			"unsaved": "有未保存的更改",
			"version": "版"
		},
		"assessments": {
			"assessment": "评定",
			"pre": "预评估"
		},
		"people": "人",
		"minutes": "分钟",
		"languages": "语言",
		"person": "人",
		"sections": "SECTIONS",
		"title": "ATLAS",
		"questions": "问题",
		"languageVars": "语言变化",
		"contentBlocks": "内容块",
		"admin": "管理员仪表板",
		"mediaBlocks": "媒体块",
		"inputBlocks": "用户输入块",
		"empty": "这里没有模块或评估，添加一个到这个级别开始。"
	},
	"rspb": {
		"factFile": {
			"description": "描述",
			"show": "显示",
			"features": "特征",
			"name": "名称",
			"tags": "标签",
			"funFacts": "有趣的事实",
			"start": "开始",
			"scientificName": "科学名称",
			"seen": "看",
			"addImages": "选择图像",
			"commonName": "通用名称",
			"factFile": "事实档案",
			"editItem": "编辑事实档案",
			"addFact": "在这里输入一个事实",
			"addFeatures": "在此输入功能",
			"addTags": "在此处输入标签",
			"addActivities": "在此输入活动",
			"images": "图片",
			"activities": "活动"
		},
		"planItem": {
			"difficulty": {
				"medium": "中",
				"hard": "硬",
				"difficulty": "选择难度",
				"easy": "简单"
			},
			"garden": {
				"public": "上市",
				"large": "大",
				"small": "小",
				"balconyroof": "阳台/屋顶",
				"selectType": "选择花园的类型",
				"medium": "中"
			},
			"title": "标题",
			"type": "类型",
			"tags": "标签",
			"image": "图片",
			"to": "结束日期",
			"months": "月",
			"region": "地区",
			"mostImportant": "最重要的",
			"dates": "日期",
			"activityCode": "活动代码",
			"planItems": "计划项目",
			"from": "可从",
			"visible": "可见",
			"weighting": "计划项目权重",
			"duration": "持续时间（秒）",
			"solo": "为了这个家庭？",
			"regionHelp": "单击以选择此计划项目的区域",
			"leastImportant": "最不重要",
			"description": "描述",
			"source": "资源",
			"identifier": "识别码",
			"indefinite": "不定？",
			"weightingHelp": "项目在应用中的显示位置的优先级",
			"pageHelp": "这是您的计划项目将链接到的页面"
		},
		"rspb": "RSPB",
		"noitems": "没有要显示的项目，请单击上面的添加。"
	}
}