var Maps = require('lib/maps')

module.exports = Backbone.View.extend({
	template: require('./box-draw-view-template'),
	className: 'region-draw storm-modal modal fade',

	events: {
		'click .cancel-button': 'close',
		'click .save-button': 'save'
	},

	initialize: function() {
		this.box = {}
	},

	afterRender: function() {
		// Initialise Google Maps API.
		Maps.init(this.initGoogleMap.bind(this))
	},

	initGoogleMap: function() {
		var overlayOptions = {
			strokeColor: '#F93A2F',
			fillColor: '#F93A2F',
			fillOpacity: 0.28,
			strokeWeight: 2,
			clickable: false,
			editable: true
		}

		// Initialize Google map
		this.map = new google.maps.Map(this.$('.region-map')[0], Maps.getOptions())

		this.drawingManager = new google.maps.drawing.DrawingManager({
			drawingMode: google.maps.drawing.OverlayType.RECTANGLE,
			drawingControlOptions: {
				position: google.maps.ControlPosition.TOP_CENTER,
				drawingModes: [
					google.maps.drawing.OverlayType.RECTANGLE
				]
			},
			polygonOptions: overlayOptions,
			map: this.map
		})

		google.maps.event.addListener(this.drawingManager, 'overlaycomplete', this.overlayComplete.bind(this))
	},

	show: function() {
		this.$el.modal({backdrop: 'static'})

		setTimeout(function() {
			// Container has resized – update map and re-center.
			google.maps.event.trigger(this.map, 'resize')
			this.map.setCenter(Maps.getOptions().center)
		}.bind(this), 500)
	},

	close: function() {
		this.$el.modal('hide')
	},

	overlayComplete: function(e) {
		// Allow only one drawing
		if (!_.isEmpty(this.box)) {
			this.box.setMap(null)
		}

		this.box = e.overlay
	},

	save: function() {
		var paths = []

		if (_.isEmpty(this.box)) {
			this.close()
			return
		}

		var bounds = this.box.getBounds(),
			NE     = bounds.getNorthEast(),
			SW     = bounds.getSouthWest(),
			NW     = new google.maps.LatLng(NE.lat(), SW.lng()),
			SE     = new google.maps.LatLng(SW.lat(), NE.lng())

		paths.push([
			[NE.lng(), NE.lat()],
			[SE.lng(), SE.lat()],
			[SW.lng(), SW.lat()],
			[NW.lng(), NW.lat()],
			[NE.lng(), NE.lat()]
		])

		var region = {
			type: 'Polygon',
			coordinates: paths
		}

		this.model.set('region', region)
		this.close()
	}
})
