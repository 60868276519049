var animationPreviewViewTemplate = require('./animation-preview-view-template'),
	spotlightPreviewViewTemplate = require('./spotlight-preview-view-template'),
	animationCompatPreviewViewTemplate = require('./animation-compat-preview-view-template'),
	spotlightCompatPreviewViewTemplate = require('./spotlight-compat-preview-view-template')

var ANIMATION_CLASS = 'AnimationListItem',
	SPOTLIGHT_CLASS = 'SpotlightListItem',
	ANIMATION_OBJ_CLASS = 'Animation',
	SPOTLIGHT_COMPAT_CLASS = 'SpotlightImageListItemView',
	ANIMATION_COMPAT_CLASS = 'AnimatedImageListItemView'

module.exports = Backbone.View.extend({
	initialize: function() {
		this.currentFrame = -1

		var className = this.model.get('class')

		if (className === ANIMATION_CLASS || className === ANIMATION_OBJ_CLASS) {
			this.template = animationPreviewViewTemplate
		} else if (className === SPOTLIGHT_CLASS) {
			this.template = spotlightPreviewViewTemplate
		} else if (className === ANIMATION_COMPAT_CLASS) {
			this.template = animationCompatPreviewViewTemplate
		} else if (className === SPOTLIGHT_COMPAT_CLASS) {
			this.template = spotlightCompatPreviewViewTemplate
		}
	},

	getRenderData: function() {
		return {
			frames: this.getFrames().toJSON()
		}
	},

	getFrames: function() {
		var className = this.model.get('class')

		if (className === ANIMATION_CLASS) {
			return this.model.get('animation').get('frames')
		}

		if (className === SPOTLIGHT_CLASS) {
			return this.model.get('spotlights')
		}

		if (className === ANIMATION_OBJ_CLASS) {
			return this.model.get('frames')
		}

		if (className === SPOTLIGHT_COMPAT_CLASS || className === ANIMATION_COMPAT_CLASS) {
			return this.model.get('images')
		}

		throw new Error('Invalid list item class for animation preview')
	},

	getDelayForFrame: function(frame) {
		var className = this.model.get('class'),
			frames = this.getFrames()

		if (frames.length <= frame) {
			return 0
		}

		if (className === ANIMATION_CLASS) {
			return frames.at(frame).get('delay')
		}

		if (className === SPOTLIGHT_CLASS) {
			return frames.at(frame).get('delay')
		}

		if (className === ANIMATION_OBJ_CLASS) {
			return frames.at(frame).get('delay')
		}

		if (className === SPOTLIGHT_COMPAT_CLASS || className === ANIMATION_COMPAT_CLASS) {
			return frames.at(frame).get('delay')
		}

		throw new Error('Invalid list item class for animation preview')
	},

	afterRender: function() {
		clearTimeout(this.timeout)
		this.nextFrame()
	},

	nextFrame: function() {
		var frames = this.getFrames()

		if (frames.length === 0) {
			return
		}

		this.$('[data-frame]').addClass('hidden')

		this.currentFrame++

		if (this.currentFrame === frames.length) {
			this.currentFrame = 0
		}

		this.$('[data-frame=' + this.currentFrame + ']').removeClass('hidden')

		// Cap at 30fps.
		var delay = Math.max(this.getDelayForFrame(this.currentFrame), 33)
		this.timeout = setTimeout(this.nextFrame.bind(this), delay)
	},

	/** @override */
	beforeDestroy: function() {
		clearTimeout(this.timeout)
	}
})
