var ListView = require('list-view/list-view'),
	EditorSectionView = require('editor/editor-section-view')

var CrossBorderItemListView = ListView.extend({
	template: require('./cross-borders-list-view-template'),
	list: require('models/stormql'),
	listItem: require('./cross-border-list-item-view')
})

module.exports = EditorSectionView.extend({
	className: 'crossBorders',
	template: require('./cross-borders-view-template'),
	// TODO this may change once plugin navigation is done.
	activeTabIndex: 4,

	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)
		App.startLoad()

		this.app = App.appList.get(options.appId)

		this.views = {
			list: new CrossBorderItemListView({
				fetchData: {data: {class: 'CrossBorderItem'}},
				app: this.app
			})
		}
	},

	getPageTitle: function() {
		return $.t('crossBorders.editCrossBorderItem')
	},

	getRenderData: function() {
		return {
			appId: this.app.id
		}
	},

	afterRender: function() {
		if (!App.acl.hasWritePermission('CrossBorder')) {
			this.$('.add').hide()
		}
	}
})
