module.exports = [
	{code: 'AE', name: 'United Arab Emirates'},
	{code: 'AG', name: 'Antigua and Barbuda'},
	{code: 'AI', name: 'Anguilla'},
	{code: 'AL', name: 'Albania'},
	{code: 'AM', name: 'Armenia'},
	{code: 'AO', name: 'Angola'},
	{code: 'AR', name: 'Argentina'},
	{code: 'AT', name: 'Austria'},
	{code: 'AU', name: 'Australia'},
	{code: 'AZ', name: 'Azerbaijan'},
	{code: 'BB', name: 'Barbados'},
	{code: 'BE', name: 'Belgium'},
	{code: 'BF', name: 'Burkina Faso'},
	{code: 'BG', name: 'Bulgaria'},
	{code: 'BH', name: 'Bahrain'},
	{code: 'BJ', name: 'Benin'},
	{code: 'BM', name: 'Bermuda'},
	{code: 'BN', name: 'Brunei'},
	{code: 'BO', name: 'Bolivia'},
	{code: 'BR', name: 'Brazil'},
	{code: 'BS', name: 'Bahamas'},
	{code: 'BT', name: 'Bhutan'},
	{code: 'BW', name: 'Botswana'},
	{code: 'BY', name: 'Belarus'},
	{code: 'BZ', name: 'Belize'},
	{code: 'CA', name: 'Canada'},
	{code: 'CG', name: 'Republic Of Congo'},
	{code: 'CH', name: 'Switzerland'},
	{code: 'CL', name: 'Chile'},
	{code: 'CN', name: 'China'},
	{code: 'CO', name: 'Colombia'},
	{code: 'CR', name: 'Costa Rica'},
	{code: 'CV', name: 'Cape Verde'},
	{code: 'CY', name: 'Cyprus'},
	{code: 'CZ', name: 'Czech Republic'},
	{code: 'DE', name: 'Germany'},
	{code: 'DK', name: 'Denmark'},
	{code: 'DM', name: 'Dominica'},
	{code: 'DO', name: 'Dominican Republic'},
	{code: 'DZ', name: 'Algeria'},
	{code: 'EC', name: 'Ecuador'},
	{code: 'EE', name: 'Estonia'},
	{code: 'EG', name: 'Egypt'},
	{code: 'ES', name: 'Spain'},
	{code: 'FI', name: 'Finland'},
	{code: 'FJ', name: 'Fiji'},
	{code: 'FM', name: 'Federated States Of Micronesia'},
	{code: 'FR', name: 'France'},
	{code: 'GB', name: 'United Kingdom'},
	{code: 'GD', name: 'Grenada'},
	{code: 'GH', name: 'Ghana'},
	{code: 'GM', name: 'Gambia'},
	{code: 'GR', name: 'Greece'},
	{code: 'GT', name: 'Guatemala'},
	{code: 'GW', name: 'Guinea-Bissau'},
	{code: 'GY', name: 'Guyana'},
	{code: 'HK', name: 'Hong Kong'},
	{code: 'HN', name: 'Honduras'},
	{code: 'HR', name: 'Croatia'},
	{code: 'HU', name: 'Hungary'},
	{code: 'ID', name: 'Indonesia'},
	{code: 'IRN', name: 'Iran'},
	{code: 'IRQ', name: 'Iraq'},
	{code: 'IE', name: 'Ireland'},
	{code: 'IL', name: 'Israel'},
	{code: 'IN', name: 'India'},
	{code: 'IS', name: 'Iceland'},
	{code: 'IT', name: 'Italy'},
	{code: 'JM', name: 'Jamaica'},
	{code: 'JO', name: 'Jordan'},
	{code: 'JP', name: 'Japan'},
	{code: 'KE', name: 'Kenya'},
	{code: 'KG', name: 'Kyrgyzstan'},
	{code: 'KH', name: 'Cambodia'},
	{code: 'KN', name: 'St. Kitts and Nevis'},
	{code: 'KR', name: 'Republic Of Korea'},
	{code: 'KW', name: 'Kuwait'},
	{code: 'KY', name: 'Cayman Islands'},
	{code: 'KZ', name: 'Kazakstan'},
	{code: 'LA', name: 'Lao People’s Democratic Republic'},
	{code: 'LB', name: 'Lebanon'},
	{code: 'LC', name: 'St. Lucia'},
	{code: 'LK', name: 'Sri Lanka'},
	{code: 'LR', name: 'Liberia'},
	{code: 'LT', name: 'Lithuania'},
	{code: 'LU', name: 'Luxembourg'},
	{code: 'LV', name: 'Latvia'},
	{code: 'MD', name: 'Republic Of Moldova'},
	{code: 'MG', name: 'Madagascar'},
	{code: 'MK', name: 'Macedonia'},
	{code: 'ML', name: 'Mali'},
	{code: 'MN', name: 'Mongolia'},
	{code: 'MO', name: 'Macau'},
	{code: 'MR', name: 'Mauritania'},
	{code: 'MS', name: 'Montserrat'},
	{code: 'MT', name: 'Malta'},
	{code: 'MU', name: 'Mauritius'},
	{code: 'MW', name: 'Malawi'},
	{code: 'MX', name: 'Mexico'},
	{code: 'MY', name: 'Malaysia'},
	{code: 'MZ', name: 'Mozambique'},
	{code: 'NA', name: 'Namibia'},
	{code: 'NE', name: 'Niger'},
	{code: 'NG', name: 'Nigeria'},
	{code: 'NI', name: 'Nicaragua'},
	{code: 'NL', name: 'Netherlands'},
	{code: 'NO', name: 'Norway'},
	{code: 'NP', name: 'Nepal'},
	{code: 'NZ', name: 'New Zealand'},
	{code: 'OM', name: 'Oman'},
	{code: 'PA', name: 'Panama'},
	{code: 'PE', name: 'Peru'},
	{code: 'PG', name: 'Papua New Guinea'},
	{code: 'PH', name: 'Philippines'},
	{code: 'PK', name: 'Pakistan'},
	{code: 'PL', name: 'Poland'},
	{code: 'PT', name: 'Portugal'},
	{code: 'PW', name: 'Palau'},
	{code: 'PY', name: 'Paraguay'},
	{code: 'QA', name: 'Qatar'},
	{code: 'RO', name: 'Romania'},
	{code: 'RU', name: 'Russia'},
	{code: 'SA', name: 'Saudi Arabia'},
	{code: 'SB', name: 'Solomon Islands'},
	{code: 'SC', name: 'Seychelles'},
	{code: 'SE', name: 'Sweden'},
	{code: 'SG', name: 'Singapore'},
	{code: 'SI', name: 'Slovenia'},
	{code: 'SK', name: 'Slovakia'},
	{code: 'SL', name: 'Sierra Leone'},
	{code: 'SN', name: 'Senegal'},
	{code: 'SR', name: 'Suriname'},
	{code: 'ST', name: 'Sao Tome and Principe'},
	{code: 'SV', name: 'El Salvador'},
	{code: 'SZ', name: 'Swaziland'},
	{code: 'TC', name: 'Turks and Caicos'},
	{code: 'TD', name: 'Chad'},
	{code: 'TH', name: 'Thailand'},
	{code: 'TJ', name: 'Tajikistan'},
	{code: 'TM', name: 'Turkmenistan'},
	{code: 'TN', name: 'Tunisia'},
	{code: 'TR', name: 'Turkey'},
	{code: 'TT', name: 'Trinidad and Tobago'},
	{code: 'TW', name: 'Taiwan'},
	{code: 'TZ', name: 'Tanzania'},
	{code: 'UA', name: 'Ukraine'},
	{code: 'UG', name: 'Uganda'},
	{code: 'US', name: 'United States'},
	{code: 'UY', name: 'Uruguay'},
	{code: 'UZ', name: 'Uzbekistan'},
	{code: 'VC', name: 'St. Vincent and The Grenadines'},
	{code: 'VE', name: 'Venezuela'},
	{code: 'VG', name: 'British Virgin Islands'},
	{code: 'VN', name: 'Vietnam'},
	{code: 'YE', name: 'Yemen'},
	{code: 'ZA', name: 'South Africa'},
	{code: 'ZW', name: 'Zimbabwe'}
]
