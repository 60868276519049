var Tip = require('./tip')

/**
 * Exports {@link TipAnswer}.
 * @module
 */
module.exports = Backbone.Model.extend(/** @lends TipAnswer.prototype */{
	/**
	 * @constructs TipAnswer
	 * @extends Backbone.Model
	 * @override
	 */
	initialize: function(attributes, options) {
		this.tipId = options.tipId
	},

	/** @override */
	urlRoot: function() {
		return Tip.prototype.urlRoot() + '/' + this.tipId + '/answers'
	}
})
