<b><code><i class="icon-lightbulb"></i> {{model.id}} - {{model.class}}</code></b><br>
<i class="icon-cog toggle-tools tool-icon blue-settings"></i>
<i data-id={{model.id}} class="icon-trash remove-section tool-icon red-delete"></i>
<div class="section-header">
	<h5 class="section-text"> <i class="icon-circle-arrow-right"></i> {{t 'r4b.modules.section.title'}} {{sectionNumber}}</h5>
	{{#ifBorC model.class 'R4b_ModuleSection' 'R4b_TaskModuleSection'}}
	<label id="task-label" for="taskcheck-{{selectorId}}">Task?</label>
	<div class="onoffswitch">
	    <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox task-check" id="taskcheck-{{selectorId}}" {{#ifEqual model.class 'R4b_TaskModuleSection'}}checked{{/ifEqual}}>
	    <label class="onoffswitch-label" for="taskcheck-{{selectorId}}"></label>
	</div>
	{{/ifBorC}}
	<span class="task-content enter-text" data-selection={{selectorId}} data-placeholder="{{t 'r4b.modules.blockContent.task.help'}}" {{#if taskModuleSection}} style="display:none;" {{/if}} contenteditable="true">{{getLocaleText model.intro.data.content}}</span>
</div>

<div class="section-tools" data-selection={{selectorId}} style="display:none;">
	{{#ifBorC model.class 'R4b_ModuleSection' 'R4b_TaskModuleSection'}}
	<div class="logic-view" data-selection={{selectorId}}></div>
	{{/ifBorC}}
</div>
<div class="section-blocks" data-selection={{selectorId}} data-id="{{model.id}}"></div>
