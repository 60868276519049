/* eslint-disable */
module.exports = Backbone.View.extend({
	template: require('./navigation-view-template'),

	el: '#navigation',

	events: {
		'click .nav-button' : 'menuToggle',
		'click .nav-group a': 'menuToggle',
		'change .app-select': 'changeApp'
	},

	initialize: function() {
		this.listenTo(App.router, 'route', this.closeMenu, this)
	},

	getRenderData: function() {
		return {
			appId: this.appId
		}
	},

	afterRender: function() {
		// Set current app if not already done so.
		var app = App.getCurrentApp()

		if (!this.appId && App.appList.length) {
			this.appId = app.id
			this.render()
			return
		}

		// Bind scroll handler for menu hiding.
		$(window).off('scroll', '', this.scrollHandler)
		$(window).scroll(this.scrollHandler)

		// Bind extra event handler for subnav link clicks.
		$('body')
			.off('click', '#top-nav a')
			.on('click', '#top-nav a', this.closeMenu.bind(this))

		// Only show user agreement on GDPC.
		if (App.system.id !== 3) {
			this.$('.user-agreement-link').remove()
		}

		// Only show Bugs for users with a valid role.
		if (App.userRoles.findWhere({systemId: 6}) === undefined) {
			this.$('.bugs-link').remove()
		}

		// Only show Levels nav on ARC SWIM
		if (App.system.id !== 9 || this.appId !== 3) {
			this.$('.levels-link').hide()
		}

		// Only show sprint tracker on ARC
		if (App.system.id !== 9) {
			this.$('.sprint-link').hide()
		}

		// Hide nav items disallowed in permission
		var p = App.permissionsStructure

		if (!(p.Admin.Organization || p.Users['App assignment'] || p.Users['Role management'] || p.Users['Users list'] || p.Stats[''])) this.$('.admin-link').hide()
		if (!p['App Setup']['App setup']) this.$('.setup-link').hide()
		if (!p.Content['']) this.$('.content-link').hide()
		if (!p.Publish['']) this.$('.publish-link').hide()
		if (!p['User Agreement']['']) this.$('.user-agreement-link').hide()
		if (!p.Stories['']) this.$('.stories-link').hide()
		if (!p.Levels['']) this.$('.levels-link').hide()
		if (!p.Push['']) this.$('.push-link').hide()
		if (!(p['GDPC Console']['National Societies'] || p['GDPC Console']['Dev Pools'])) this.$('.gdpc-console-link').hide()
		if (!(p.Hazards.Disasters || p.Hazards.Alerts)) this.$('.hazards-link').hide()

		// Hide the Hazards nav item for non-Hazards app types.
		var appInstance = App.appList.get(this.appId)

		if (appInstance && !appInstance.isHazardsApp()) {
			this.$('.hazards-link').hide()
		}

		// Allow users to get to the Hazards 'Alerts' menu without 'Disasters' permissions.
		if (p.Hazards.Alerts && !p.Hazards.Disasters) {
			this.$('.hazards-link a').attr('href', '/apps/' + this.appId + '/alerts')
		}

		// Only allow access to moderation frontend on ARC Wildfire.
		if (App.system.id === 9 && this.appId === 6) {
			this.$('.hazards-link a').attr('href', '/apps/' + this.appId + '/moderation')
		}

		// Hide Decision Tree tab on non-ASPCA.
		if (App.system.id !== 8) {
			this.$('.decision-tree-link').hide()
		}

		// Hide Stories tab on GDPC. GDPC Stories controller isn't the CMS Stories feature.
		if (App.system.id === 3) {
			this.$('.stories-link').hide()
		}

		// Hide nav links to things which require an assigned app.
		if (!App.appList.length) {
			// Allow access to the Users section when no apps are assigned.
			this.$('.admin-link a').attr('href', '/users')

			// Content section should redirect to the 'No apps' message.
			this.$('.content-link a').attr('href', '/')

			var links = [
				'setup',
				'publish',
				'push',
				'gdpc-console',
				'hazards',
				'decision-tree',
				'levels',
				'user-agreement-link'
			]

			var linksString = '.' + links.join('-link, .')
			this.$(linksString).hide()
		}

		// Hide most things on legacy (Umbraco)-type apps.
		if (appInstance && appInstance.isLegacyApp()) {
			this.$('.content-link').hide()
			this.$('.publish-link').hide()
			this.$('.push-link').hide()
			this.$('.stats-link').hide()
		}

		// Populate app selector. Hide if only one app.
		var options = App.generateAppSelect(),
			canWrite = p.Apps[''] === 'Write'

		this.$('.app-select').html(options)

		// Add 'Create new app' option if we have permission.
		if (canWrite) {
			this.$('.app-select').append('<option value="new">' + $.t('common.add') + '</option>')
		}

		this.$('.app-select').val(this.appId)

		if (App.appList.length < 2 && !(App.appList.length === 1 && canWrite)) {
			this.$('.app-select').css('display', 'none')
		}
	},

	menuToggle: function(toggle) {
		$('#top-nav').toggleClass('above', toggle)
		$('.title-bar').toggleClass('under', toggle)
		this.$('.nav-button').toggleClass('above', toggle)
		this.$('.nav-overlay').toggleClass('show-me', toggle)
		this.$('.toggle').toggleClass('close', toggle)
	},

	closeMenu: function() {
		this.menuToggle(false)
	},

	changeApp: function() {
		this.appId = this.$('.app-select').val()

		if (this.appId === 'new') {
			App.router.navigate('/apps/new', {trigger: true})
			return
		}

		// Update all navigation links.
		this.render()

		// Cache last app ID
		localStorage.setItem('lastApp', this.appId)

		// Redirect to App Setup if we're on a legacy-type app.
		var app = App.appList.get(this.appId)

		if (app.isLegacyApp()) {
			App.router.navigate('/apps/' + this.appId + '/setup', {trigger: true})
			return
		}

		// If the current route contains an app ID manually trigger the route change.
		var routeMatch = document.location.pathname.match(/^\/apps\/(\w+)\/?(.*)$/)

		if (routeMatch !== null) {
			var lastApp = routeMatch[1],
				path = routeMatch[2]

			// If switching app in the content editor, don't keep the whole path (page ID).
			if (path.substr(0, 5) === 'pages' && lastApp !== this.appId) {
				path = 'pages'
			}

			App.router.navigate('/apps/' + this.appId + '/' + path, {trigger: true})

			if (App.view) {
				App.view.trigger('change:app', this.appId)
			}
		} else {
			// Reload whatever route we were on
			Backbone.history.loadUrl()
		}
	},

	setApp: function(app) {
		// Check this app exists before setting it.
		var appObj = App.appList.get(app)

		if (appObj) {
			var prevApp = this.appId

			this.appId = app
			this.$('.app-select').val(app)

			if (prevApp !== this.appId) {
				this.$('.app-select').trigger('change')
			}
		}
	},

	scrollHandler: _.throttle(function() {
		var scrollTop = $(this).scrollTop(),
			$toToggle = $('#top-nav, .nav-button, .app-select'),
			$top = $('.title-bar'),
			hide = scrollTop > 53

		$top.toggleClass('stuck', hide)
		$toToggle.toggleClass('hide-me', hide)
			.toggleClass('show-me', !hide)
	}, 50)
})
