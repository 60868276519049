{{partial "admin/admin-nav"}}

<div class="title-bar">
	<div class="header">
		<a href="/roles"><span class="back-button"></span></a>
		<span>{{t "permissions.title"}}</span>
		<button class="button positive save">{{t "common.save"}}</button>
	</div>
</div>

<div class="container pad-top">
	<div class="row">
		<div class="span12 title-header">{{t "permissions.edit"}}</div>
	</div>
	<div class="row">
		<div class="span12">

			<form class="form-horizontal">
				<fieldset>
					{{#key_value permissions "group" "tabs"}}
						<legend>{{group}}</legend>

						<!-- Multiple Radios (inline) -->
						<div class="control-group">
							{{#key_value tabs "tab" "methods"}}
								<label class="control-label" for="{{makeClassName ../group}}-{{makeClassName tab}}">{{tab}}</label>
								<div class="controls">

									<label class="radio inline" for="{{makeClassName ../group}}-{{makeClassName tab}}-0">
										<input type="radio" name="{{makeClassName ../group}}-{{makeClassName tab}}" id="{{makeClassName ../group}}-{{makeClassName tab}}-0" value="" data-group="{{../group}}" data-tab="{{tab}}">
										{{t "permissions.none"}}
									</label>

									<label class="radio inline" for="{{makeClassName ../group}}-{{makeClassName tab}}-1">
										<input type="radio" name="{{makeClassName ../group}}-{{makeClassName tab}}" id="{{makeClassName ../group}}-{{makeClassName tab}}-1" value="Read" data-group="{{../group}}" data-tab="{{tab}}">
										{{t "permissions.read"}}
									</label>

									<label class="radio inline" for="{{makeClassName ../group}}-{{makeClassName tab}}-2">
										<input type="radio" name="{{makeClassName ../group}}-{{makeClassName tab}}" id="{{makeClassName ../group}}-{{makeClassName tab}}-2" value="Write" data-group="{{../group}}" data-tab="{{tab}}">
										{{t "permissions.write"}}
									</label>

									{{#ifExists methods.Delete}}
										<label class="radio inline" for="{{makeClassName ../../group}}-{{makeClassName ../tab}}-3">
											<input type="radio" name="{{makeClassName ../../group}}-{{makeClassName ../tab}}" id="{{makeClassName ../../group}}-{{makeClassName ../tab}}-3" value="Delete" data-group="{{../../group}}" data-tab="{{../tab}}">
											{{t "permissions.delete"}}
										</label>
									{{/ifExists}}

								</div>
							{{/key_value}}
						</div>
					{{/key_value}}

				</fieldset>
			</form>

		</div>
	</div>

</div>
