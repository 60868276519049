/**
 * Defines a simple class for getting/setting global state for the application.
 * @constructor
 */
function StormGlobals() {
	/** @private Object.<string, *> */
	this.objects_ = {}
}

/**
 * Clears all set values from the state.
 */
StormGlobals.prototype.clear = function() {
	this.objects_ = {}
}

/**
 * Sets multiple values on the state from a key/value map.
 * @param {Object.<string, *>} objects Key/value map of objects to set.
 */
StormGlobals.prototype.set = function(objects) {
	Object.keys(objects).forEach(function(key) {
		this.objects_[key] = objects[key]
	}, this)
}

/**
 * Retrieves the object for the specified key from the globals map.
 * @param {string} key The key of the object to retrieve.
 * @returns {*} The value set for the specified key.
 * @throws {Error} If an object for key has not been defined.
 */
StormGlobals.prototype.get = function(key) {
	var object = this.objects_[key]

	if (object === undefined) {
		throw new Error(key + ' global not defined.')
	}

	return object
}

/**
 * @returns {Session} The session for the app.
 */
StormGlobals.prototype.getSession = function() {
	return this.get('session')
}

/**
 * @param {Session} session The session to set.
 */
StormGlobals.prototype.setSession = function(session) {
	this.objects_.session = session
}

/**
 * @returns {AppList} The app list for the app.
 */
StormGlobals.prototype.getAppList = function() {
	return this.get('appList')
}

/**
 * @param {AppList} appList The app list to set.
 */
StormGlobals.prototype.setAppList = function(appList) {
	this.objects_.appList = appList
}

/**
 * @returns {Classes} The classes object for the app.
 */
StormGlobals.prototype.getClasses = function() {
	return this.get('classes')
}

/**
 * @param {Classes} classes The classes object to set.
 */
StormGlobals.prototype.setClasses = function(classes) {
	this.objects_.classes = classes
}

/**
 * @returns {ACL} The ACL for the app.
 */
StormGlobals.prototype.getAcl = function() {
	return this.get('acl')
}

/**
 * @param {ACL} acl The ACL object to set.
 */
StormGlobals.prototype.setAcl = function(acl) {
	this.objects_.acl = acl
}

/**
 * @returns {SocietiesList} The societies list for the app.
 */
StormGlobals.prototype.getSocietiesList = function() {
	return this.get('societiesList')
}

/**
 * @param {SocietiesList} societiesList The societies list to set.
 */
StormGlobals.prototype.setSocietiesList = function(societiesList) {
	this.objects_.societiesList = societiesList
}

/**
 * @returns {UserRoleList} The user role list for the app.
 */
StormGlobals.prototype.getUserRoles = function() {
	return this.get('userRoles')
}

/**
 * @param {UserRoleList} userRoles The user role list to set.
 */
StormGlobals.prototype.setUserRoles = function(userRoles) {
	this.objects_.userRoles = userRoles
}

/**
 * @returns {Object} The system details object for the app.
 */
StormGlobals.prototype.getSystem = function() {
	return this.get('system')
}

/**
 * @param {Object} system The system details object to set.
 */
StormGlobals.prototype.setSystem = function(system) {
	this.objects_.system = system
}

/**
 * Exports a singleton {@link StormGlobals} instance for use for getting/setting
 * other singletons throughout the app.
 * @type {StormGlobals}
 * @module
 */
module.exports = new StormGlobals()
