var Bug = require('./bug')

module.exports = Backbone.View.extend({
	template: require('./bug-create-view-template'),

	events: {
		'change #bug-app': 'appChange',
		'click .submit-button': 'save'
	},

	initialize: function() {
		this.model = new Bug()
	},

	afterRender: function() {
		// Populate app selector
		App.appList.each(function(app) {
			var societyName = App.societiesList.get(app.get('societyId')).get('name')
			this.$('#bug-app').append('<option>' + societyName + ': ' + App.l({content: app.get('name')}) + '</option>')
		}, this)

		this.appChange()

		App.stopLoad()
	},

	appChange: function() {
		var app = this.$('#bug-app').val()

		if (app === 'Frontend') {
			this.$('.app-details').hide()
		} else {
			this.$('.app-details').show()
		}
	},

	save: function() {
		var app = this.$('#bug-app').val()

		if (app === 'Frontend') {
			this.model.unset('version')
			this.model.unset('build')
		} else {
			this.model.set({
				version: this.$('#bug-app-version').val(),
				build: this.$('#bug-app-build').val()
			})
		}

		this.model.set({
			app: app,
			model: this.$('#bug-os-device').val(),
			description: this.$('#bug-description').val(),
			steps: this.$('#bug-steps').val(),
			expected: this.$('#bug-expected').val(),
			actually: this.$('#bug-actually').val()
		})

		if (this.model.isValid()) {
			App.startLoad()

			this.model.once('sync', function() {
				App.router.navigate('/bugs/' + this.model.id, {trigger: true})
			}, this)

			this.model.save()
		} else {
			App.showToast($.t('error.allFields'))
		}
	}
})
