var CommentView = Backbone.View.extend({
	template: require('./comment-view-template'),
	className: 'bug-comment',

	events: {
		'click .comment-reply-button': 'replyToComment',
		'click .comment-delete-button': 'deleteComment'
	},

	initialize: function() {
		this.listViews = []

		this.listenTo(this.model.usersList, 'sync', this.updateUser, this)
		this.listenTo(this.model, 'change', this.render, this)
		this.listenTo(this.model.get('replies'), 'add', this.render, this)
		this.updateUser()
	},

	updateUser: function() {
		var userId = this.model.get('userId')
		var user = this.model.usersList.get(userId)

		if (!user) {
			return
		}

		this.model.set('user', user.toJSON())
	},

	afterRender: function() {
		// Periodically update timestamps
		this.updateTimer = setTimeout(this.render.bind(this), 60000)

		// Display child comments
		var replies = this.model.get('replies')

		if (replies) {
			this.listViews.forEach(function(view) {
				view.destroy()
			})

			this.listViews = []

			replies.forEach(function(comment) {
				comment.usersList = this.model.usersList

				var view = this.addView(new CommentView({model: comment}))
				this.$('> blockquote > .child-comments').append(view.render().el)
			}, this)
		}

		// Hide developer-only controls
		if (!App.developerMode) {
			this.$('.comment-delete-button').remove()
		}
	},

	beforeDestroy: function() {
		clearTimeout(this.updateTimer)
	},

	replyToComment: function(e) {
		if (Number($(e.currentTarget).data('id')) !== this.model.id) {
			return
		}

		setTimeout(function() {
			this.$el.addClass('comment-highlight')
		}.bind(this))
	},

	deleteComment: function() {
		if (confirm($.t('editor.inspector.confirmDelete'))) {
			App.startLoad()

			this.model.once('sync', function() {
				this.$('> blockquote > p').text('Comment Deleted.')
				App.stopLoad()
			}, this)

			this.model.destroy()
		}
	}
})

module.exports = CommentView
