var MediaUploadView = require('./media-upload-view')

/**
 * Exports {@link MediaUploadModalView}.
 * @module
 */
module.exports = Backbone.View.extend(/** @lends MediaUploadModalView.prototype */{
	/** @override */
	className: 'media-upload-modal storm-modal stretch modal fade',

	/** @override */
	template: require('./media-upload-modal-view-template'),

	/**
	 * @constructs MediaUploadModalView
	 * @extends Backbone.View
	 * @override
	 * @classDesc Wraps {@link MediaUploadView} in a Storm modal, for use from
	 *     the {@link MediaSelectorView}.
	 */
	initialize: function(options) {
		if (!options.app) {
			throw new Error('No app specified')
		}

		/** @private {MediaUploadView} */
		this.mediaUploadView_ = new MediaUploadView(options)

		this.listenTo(this.mediaUploadView_, 'select', this.selectHandler_)
		this.listenTo(this.mediaUploadView_, 'close', this.closeHandler_)
		this.listenTo(this.mediaUploadView_, 'upload', this.uploadHandler_)
	},

	/** @override */
	afterRender: function() {
		this.$('.modal-body').append(this.mediaUploadView_.el)
		this.mediaUploadView_.render()
	},

	addFiles: function(files) {
		this.mediaUploadView_.addFiles(files)
	},

	/**
	 * Shows the view for the first time, animating in as a modal.
	 */
	show: function() {
		this.$el.modal({keyboard: false, backdrop: 'static'})
	},

	/**
	 * Dismisses the view's modal and destroys the view.
	 */
	close: function() {
		this.$el.modal('hide')

		// Destroy view after modal animated out.
		setTimeout(this.destroy.bind(this), 500)
	},

	/**
	 * Proxies {@link MediaUploadView#chooseButtonClick()}.
	 */
	chooseButtonClick: function() {
		this.mediaUploadView_.chooseButtonClick()
	},

	/**
	 * Proxies 'select' events from the child {@link MediaUploadView}.
	 * @private
	 */
	selectHandler_: function() {
		this.trigger('select')
	},

	/**
	 * Proxies 'close' events from the child {@link MediaUploadView}, and
	 * closes the modal.
	 * @private
	 */
	closeHandler_: function() {
		this.trigger('close')
		this.close()
	},

	/**
	 * Proxies 'upload' events from the child {@link MediaUploadView}.
	 * @param {Upload} data Upload file data.
	 * @private
	 */
	uploadHandler_: function(data) {
		this.trigger('upload', data)
	}
})
