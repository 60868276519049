module.exports = Backbone.View.extend({
	template: require('./feed-configure-view-template'),
	className: 'storm-modal modal fade',

	events: {
		'click .save-button': 'save',
		'click .cancel-button': 'close',
		'change .enabled-checkbox': 'enabledChange'
	},

	initialize: function(options) {
		this.feed = options.feed
		this.enabledEvents = options.enabledEvents
		this.feedEvents = options.feedEvents
		this.model = options.model
	},

	getRenderData: function() {
		var feed = this.feed.toJSON()
		var events = this.model.get('events')

		this.feedEvents.forEach(function(event) {
			if (this.enabledEvents.indexOf(Number(event.id)) > -1) {
				event.isEnabled = true
			}

			if (events.indexOf(Number(event.id)) > -1) {
				event.isAssigned = true
			}
		}, this)

		return {
			feedEvents: this.feedEvents,
			feed: feed
		}
	},

	save: function(e) {
		var model = this.model

		if (this.needsSaving) {
			model.set('events', [])
			this.$('.enabled-checkbox').each(function() {
				var parent = $(this).parent().parent(),
					eventId = Number(parent.data('id'))

				if (this.checked) {
					model.get('events').push(eventId)
				}
			})

			this.model.needsSaving = true
		}

		this.close()

		// Don't bubble up to parent view.
		e.stopPropagation()
	},

	close: function() {
		this.$el.modal('hide')
	},

	enabledChange: function() {
		this.needsSaving = true
	}
})
