var WhatnowList = require('./whatnow-list'),
	WhatnowItemView = require('./whatnow-item-view'),
	StormView = require('lib/storm-view'),
	WhatNow = require('./whatnow'),
	countryCodes = require('./organisations')

module.exports = StormView.extend({
	template: require('./whatnow-view-template'),

	events: {
		'click .approve': 'approve',
		"change #soc-selector": "socSelected"
	},

	initialize: function(options) {
		App.startLoad()
		this.countryCodes = countryCodes
		// Preference towards user having an app assigned.
		if (options.app) {
			this.app = options.app
			this.appId = options.appId
			this.noApp = false
			// Get society for the currently selected app.
			var societyId = this.app.get('societyId')
			// Get society for the currently selected app.
			this.society = App.societiesList.get(societyId)
			this.countryCode = this.society.get('locale')
		} else { // If no app assigned user is showen a selection and the cms will try and load the last country code from localStorage
			this.noApp = true
			var storedCode = localStorage.getItem('whatnow-country-code')
			if (storedCode) {
				this.countryCode = storedCode
			} else {
				this.countryCode = "-"
			}
		}
		this.getWhatnowData()
		this.views = []
	},

	getWhatnowData: function() {
		this.clearViews()
		if (this.countryCode !== "-") {
			$('#addButton').show()
			this.collection = new WhatnowList({countryCode: this.countryCode})
			this.collection.on('add', this.addListItem, this)
			this.collection.once('sync', this.ready, this)
			this.collection.fetch()
		} else {
			$('#addButton').hide()
			$('#noWhatnows').hide()
			$('.whatnow-table').hide()
			App.stopLoad()
		}
	},

	getRenderData: function() {
		return {
			appId: this.appId,
			noApp: this.noApp,
			countryCodes: this.countryCodes,
			currentLang: this.countryCode
		}
	},

	afterRender: function() {
		if (this.countryCode !== "-") {
			$('#addButton').show()
		} else {
			$('#addButton').hide()
		}
	},

	addListItem: function(item) {
		$.each(item.get('data'), function(index, value) {
			var view = new WhatnowItemView({
				data: value,
				appId: this.appId,
				countryCode: this.countryCode
			})
			if (view) {
				this.views.push(view)
				$('.list-items').append(view.render().el)
			}
		}.bind(this))
		if (this.views.length === 0) {
			// Empty, show message
			$('#noWhatnows').show()
			$('.whatnow-table').hide()
		} else {
			$('#noWhatnows').hide()
			$('.whatnow-table').show()
		}
	},

	clearViews: function() {
		$.each(this.views, function(index, value) {
			value.remove()
		})
		this.views = []
	},

	ready: function() {
		// this.showEmptyMessageOrTable()
		this.trigger('ready')
		App.stopLoad()
	},

	approve: function(event) {
		// Get whatnow model for id
		var id = $(event.currentTarget).data('id')
		this.whatnow = new WhatNow()
		this.whatnow.set('id', id)
		this.whatnow.once('sync', this.updateApprove, this)
		App.startLoad()
		this.whatnow.fetch()
	},

	updateApprove: function() {
		// Update
		if (!this.whatnow.get('approvedBy')) {
			this.whatnow.set('approvedBy', Storm.user_.id)
			// Save
			this.whatnow.save(null, {
				success: function() {
					var id = this.whatnow.get('id')
					$('#approve-' + id).prop('disabled', true)
					$('#approve-' + id).text("Approved")
				}.bind(this),
				error: function() {
					console.log("Error saving model")
				}
			})
		}
		// Render
		App.stopLoad()
	},

	socSelected: function() {
		this.countryCode = $('#soc-selector').val()
		localStorage.setItem('whatnow-country-code', this.countryCode)
		// Set urls for add and attribution buttons
		$('#addButton').attr('href', '/apps/' + this.countryCode + '/whatnow/new')
		$('#attributionButton').attr('href', '/apps/' + this.countryCode + '/whatnow-attribution')

		this.getWhatnowData()
	}
})
