var StandaloneStormObject = require('editor/standalone-storm-object'),
	EditorSectionView = require('editor/editor-section-view'),
	LinkListItemView = require('./link/link-list-item-view'),
	ListView = require('list-view/list-view'),
	countryCodes = require('./country-codes')

var ContactItemListView = ListView.extend({
	template: require('./contact/contact-list-view-template'),
	list: require('models/stormql'),
	listItem: require('./contact/contact-list-item-view'),

	initialize: function(options, data) {
		options = options || {}
		data = data || null

		if (!options.app) {
			throw new Error('No app specified')
		}

		this.app = options.app
		this.fetchData = options.fetchData || {}
		/** @private {Object} */
		this.listItemOptions_ = options.listItemOptions || {}

		this.listViews = []

		var ListConstructor = this.list

		this.collection = new ListConstructor(data, options)
		this.collection.on('add', this.addListItem, this)
		this.collection.once('sync', this.ready, this)

		this.collection.forEach(this.addListItem, this)

		if (this.afterInitialize) {
			this.afterInitialize(options)
		}
	},

	getRenderData: function() {
		var data = {}
		data.appId = this.app.id
		data.parentId = this.fetchData.data.parentId
		return data
	}
})

var PhraseItemListView = ListView.extend({
	template: require('./phrase/phrase-list-view-template'),
	list: require('models/stormql'),
	listItem: require('./phrase/phrase-list-item-view'),

	initialize: function(options, data) {
		options = options || {}
		data = data || null

		if (!options.app) {
			throw new Error('No app specified')
		}

		this.app = options.app
		this.fetchData = options.fetchData || {}
		/** @private {Object} */
		this.listItemOptions_ = options.listItemOptions || {}

		this.listViews = []

		var ListConstructor = this.list

		this.collection = new ListConstructor(data, options)
		this.collection.on('add', this.addListItem, this)
		this.collection.once('sync', this.ready, this)

		this.collection.forEach(this.addListItem, this)

		if (this.afterInitialize) {
			this.afterInitialize(options)
		}
	},

	getRenderData: function() {
		var data = {}
		data.appId = this.app.id
		data.parentId = this.fetchData.data.parentId
		return data
	}
})

/**
 * Exports {@link CrossBordersEditView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends CrossBordersEditView.prototype */{
	/** @override */
	className: 'crossBorders',
	/** @override */
	template: require('./cross-borders-edit-view-template'),
	// TODO need to update this to match badges view, if it changes.
	activeTabIndex: 4,

	/** @override */
	events: {
		'click .save': 'save',
		'click .add-row': 'addLinkRow',
		'click .remove-row': 'removeLinkRow'
	},

	/**
	 * @constructs CrossBordersEditView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)

		this.countryCodes = countryCodes

		/** @private {App} */
		this.app_ = options.app

		// Fetch enabled languages for this app.
		var languagePromise = this.app_.languageList.fetchOnce()

		this.model = new StandaloneStormObject()
		var modelPromise = Promise.resolve()

		if (options.id !== 'new') {
			this.model.set('id', options.id)
			this.model.once('sync', this.ready, this)

			modelPromise = this.model.fetch().then(function() {
				// Add in any missing text objects to the model.
				['country'].forEach(function(key) {
					if (!this.model.has(key)) {
						var text = App.getClassStructure('Text', 0)
						this.model.set(key, text)
					}
				}, this)
				this.model.requestLock()
			}.bind(this))
		} else {
			this.model = StandaloneStormObject.fromClassName('CrossBorderItem')
		}

		Promise.all([modelPromise, languagePromise])
			.then(App.stopLoad)
			.then(this.ready.bind(this))
	},

	ready: function() {
		if (!this.model.isNew()) {
			this.views = {
				contactList: new ContactItemListView({
					fetchData: {data: {class: 'CrossBorderContactItem', parentId: this.model.get('id')}},
					app: this.app_,
					listItemOptions: {parentId: this.model.get('id')}
				}, this.model.get('contacts').toJSON()),
				phraseList: new PhraseItemListView({
					fetchData: {data: {class: 'CrossBorderPhrase', parentId: this.model.get('id')}},
					app: this.app_,
					listItemOptions: {parentId: this.model.get('id')}
				}, this.model.get('phrases').toJSON())
			}

			this.links = []
			if (!_.isEmpty(this.model.get('links'))) {
				this.links = this.model.get('links').toJSON()
			}

			this.linkListView = new LinkListItemView({
				fetchData: {data: {class: 'DestinationLink', parentId: this.model.get('id')}},
				parentId: this.model.get('id'),
				app: this.app_,
				links: this.links
			}, this.model.toJSON())
		}
		this.render()
	},
	/** @override */
	afterRender: function() {
		this.delegateEvents()

		// Render table rows
		if (!this.model.isNew()) {
			_.each(this.views, function(view) {
				view.collection.forEach(view.addListItem, view)
			})
			if (this.linkListView) {
				this.$('.child-views').append(this.linkListView.render().el)
			}

			// Show current input values
			_.each(this.model.get('country..content'), function(val, key) {
				this.$('.name-input[data-code=' + key + ']').val(val)
			})
		}

		this.$('#country-code').val(this.model.get('code'))
	},

	/** @override */
	getRenderData: function() {
		var data = this.model.toJSON()
		data.appId = this.app_.id
		data.languages = this.app_.languageList.toJSON()
		data.countryCodes = this.countryCodes
		return data
	},

	/**
	 * [addLinkRow adds a link to the model and the view.]
	 */
	addLinkRow: function() {
		var	destination = this.$('.new-link-destination').val()
		var titleContent = {}

		$(".titles-col input").each(function() {
			var code = $(this).data("code")
			var text = $(this).val()
			if (code && text) {
				titleContent[code] = text
			} else {
				return
			}
		})

		if (destination && titleContent) {
			var linkModel  = StandaloneStormObject.fromClassName('InternalLink')
			var titleModel = StandaloneStormObject.fromClassName('Text')

			linkModel.set('destination', destination)

			titleModel.set('content', titleContent)
			linkModel.set('title', titleModel)

			if (_.isEmpty(this.model.get('links'))) {
				var arr = []
				arr.push(linkModel)
				this.model.set('links', arr)
			} else {
				this.model.get('links').push(linkModel)
			}
			this.links = this.model.get('links').toJSON()
			this.linkListView.updateView(this.links)
		}
	},

	/**
	 * [removeLinkRow removes a link from the model and the view.]
	 * @param  {[Event]} e [Event for the data-id on the delete link]
	 */
	removeLinkRow: function(e) {
		swal({
			title: $.t('editor.inspector.areYouSure'),
			text: $.t('editor.inspector.confirmDelete'),
			showCancelButton: true
		}, function(didConfirm) {
			if (didConfirm) {
				var linkId  = $(e.currentTarget).data('id')

				App.startLoad()
				var linkToDelete = this.model.get('links').get(linkId)
				// RequestLock then delete
				linkToDelete.requestLock(function() {
					// Delete
					linkToDelete.destroy().then(function() {
						// Update
						this.links = this.model.get('links').toJSON()
						this.linkListView.updateView(this.links)
						App.stopLoad()
					}.bind(this))
				}.bind(this))
			}
		}.bind(this))
	},

	save: function() {
		App.startLoad()

		var self = this

		this.model.once('sync', function() {
			this.model.requestUnlock()
			App.router.navigate('/apps/' + self.app_.id + '/cross-borders', {trigger: true})
		}.bind(this))

		// Get all new input values
		var countries = {},
			code = this.$('#country-code').val()

		if (code.length === 0) {
			return Promise.reject($.t('crossBorder.error.codeEmpty'))
		}

		this.$('.name-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				countries[code] = value
			}
		})

		this.model.set('code', code)
		this.model.set('country..content', countries)
		this.model.set('links', this.model.get('links'))
		this.model.save(null, {appId: this.app_.id})
		return false
	}
})
