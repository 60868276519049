var LanguageList = require('editor/language-list'),
	PluginList = require('editor/plugin-list'),
	PageList = require('editor/page-list/page-list'),
	EditorSectionView = require('editor/editor-section-view'),
	Translations = require('./translations')

	/** @type {StormGlobals} */
	// globals = require('globals')

module.exports = EditorSectionView.extend({
	className: 'localisationTable',
	template: require('./localisation-table-view-template'),

	events: {
		"change #type-selector": "typeSelected",
		"change #page-selector": "pageSelected",
		'click .download-json-button': 'downloadJSON',
		'click .download-csv-button': 'downloadCSV',
		'click .save': 'saveChanges',
		'change .check-lang-option': 'langFilterChange'
	},

	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)
		App.startLoad()
		this.type = 'plugins'
		this.appId = options.appId
		// Fetch translations
		this.model = new Translations({appId: this.appId})
		var tableFetch = this.model.fetch()

		// Fetch list of languages enabled for this app
		this.appLanguages = new LanguageList(null, {
			appId: this.appId
		})
		var langFetch = this.appLanguages.fetch()

		this.availablePlugins = new PluginList()
		var availableFetch = this.availablePlugins.fetch()

		this.pageList = new PageList(null, {appId: this.appId})
		var pageFetch = this.pageList.fetch()

		Promise.all([langFetch, availableFetch, pageFetch, tableFetch]).then(this.ready.bind(this))
	},

	getPageTitle: function() {
		return $.t('appSetup.translation')
	},

	ready: function() {
		this.render()
		// Dirty way to make a copy.
		this.cachedTable = JSON.parse(JSON.stringify(this.model.toJSON()))
		// Store changes to the table here.
		this.changes = []
		// this.typeSelected()
		App.stopLoad()
	},

	getRenderData: function() {
		return {
			appId: this.appId,
			languages: this.appLanguages.toJSON(),
			plugins: this.availablePlugins.toJSON(),
			pages: this.getPageList(),
			classes: App.classes.toJSON(),
			appSelect: App.generateAppSelect(),
			type: this.type
		}
	},

	// After render filter table data based on filter type and then setup table.
	afterRender: function() {
		// Get Model data.
		this.filterData()

		if (this.translations) {
			this.setupTable()
		}
	},

	// Filter model data into a translation array
	filterData: function(pageId) {
		this.translations = []
		var plugins = this.model.get('plugins')
		var native = this.model.get('native')
		var pages = this.model.get('pages')
		switch (this.type) {
			case 'all':
				this.translations.push(plugins, native, pages)
				break
			case 'plugins':
				this.translations.push(plugins)
				break
			case 'native':
				this.translations.push(native)
				break
			case 'pages':
				var filteredByPage = []
				for (var i = 0; i < pages.length; i++) {
					if (pageId && pages[i].id === pageId) {
						filteredByPage.push(pages[i])
					}

					if (!pageId) {
						filteredByPage = pages
					}
				}
				this.translations.push(filteredByPage)
				break
		}
	},

	// Create Handsontable and supply it with column data and table data
	setupTable: function() {
		this.columns = this.appLanguages.toJSON().map(function(appLanguage) {
			return appLanguage.name
		})

		this.columnWidths = this.appLanguages.toJSON().map(function(appLanguage) {
			if (this.localeActive(appLanguage.code)) {
				return 300
			}
			return 0.1
		}.bind(this))
		// this.columns = []
		// this.numOfLangs = this.appLanguages.toJSON().length
		// console.log('num of langs', this.numOfLangs)
		// this.appLanguages.toJSON().forEach(function (language) {
		// 	if (this.localeActive(language.code)){
		// 		this.columns.push(language.name)
		// 	}
		// }.bind(this))

		// Add first colume as String / Code
		this.columnWidths.unshift(300)
		this.columns.unshift('Code')

		var container = document.getElementById('handsontable')
		this.tableData = this.getTableData()

		// Empty value, turn background red if empty
		function emptyValueRenderer(instance, td, row, col, prop, value) {
			Handsontable.renderers.TextRenderer.apply(this, arguments)
			// If empty
			if (!value || value === '') {
				td.style.background = '#ff0000'
			}
		}

		Handsontable.renderers.registerRenderer('emptyValueRenderer', emptyValueRenderer)
		this.table = new Handsontable(container, {
			data: this.tableData,
			rowHeaders: true,
			contextMenu: true,
			colWidths: this.columnWidths,
			startRows: 5,
			startCols: this.columns.length,
			colHeaders: this.columns,
			columnSorting: true,
			manualColumnResize: true,
			manualRowResize: true,

			afterSelection: function(row, col, row2, col2) {
				var meta = this.getCellMeta(row2, col2)

				if (meta.readOnly) {
					this.updateSettings({
						fillHandle: false
					})
				} else {
					this.updateSettings({
						fillHandle: true
					})
				}
			},
			cells: function(row, col) {
				var cellProperties = {}
				if (col === 0) {
					cellProperties.readOnly = true
				}
				cellProperties.renderer = "emptyValueRenderer" // uses lookup map
				return cellProperties
			},

			// After change of a cell
			afterChange: function(tableChanges) {
				if (tableChanges) {
					// Get code
					tableChanges.forEach(function(change) {
						var contentCode = this.table.getDataAtRow(change[0])[0]
						var langCode = this.appLanguages.toJSON()[change[1] - 1].code
						var update = change[3]
						var codeExists = false
						for (var i = 0; i < this.changes.length; i++) {
							if (this.changes[i].code === contentCode) {
								// Loop through and find language code if it exists otherwise just push it.
								var langExists = false
								for (var j = 0; j < this.changes[i].languages.length; j++) {
									if (this.changes[i].languages[j].locale === langCode) {
										this.changes[i].languages[j].text = update
										langExists = true
									}
								}

								if (!langExists) {
									var newUpdate = {
										locale: langCode,
										text: update
									}
									this.changes[i].languages.push(newUpdate)
								}
								codeExists = true
							}
						}
						// If code did not get found.. create it and push inside the update. then push to changes.
						if (!codeExists) {
							var newCode = {
								code: contentCode,
								languages: [{
									locale: langCode,
									text: update
								}]
							}
							this.changes.push(newCode)
						}
					}.bind(this))
				}
			}.bind(this)
		})

		// Remove dev content
		if (!App.developerMode) {
			this.$('.developer-mode').remove()
		}
	},

	// Map translation data into a 2D array
	getTableData: function() {
		var dataStrings = []
		var self = this
		this.translations.forEach(function(type) {
			if (type) {
				type.forEach(function(item) {
					item.content.forEach(function(translation) {
						var toPush = []
						for (var i = 0; i < self.columns.length; i++) {
							if (i === 0) {
								toPush.push(translation.code)
							} else {
								toPush.push(translation.languages[i - 1].text)
							}
						}
						dataStrings.push(toPush)
					})
				})
			}
		})

		return dataStrings
	},

	localeActive: function(locale) {
		return $('input.check-lang-option[value="' + locale + '"]').is(':checked')
	},

	// On type change update table accordingly
	updateTable: function(pageId) {
		App.startLoad()
		this.filterData(pageId)
		this.table.destroy()
		this.setupTable()
		App.stopLoad()
	},

	pageSelected: function() {
		var pageId = $('#page-selector').val()
		this.updateTable(pageId)
	},

	// Hiding bits till the filter endpoint is updated
	typeSelected: function() {
		this.type = $('#type-selector').val()
		switch (this.type) {
			case 'plugins':
				$('#plugins').show()
				$('#pages').hide()
				$('#table-spacer').hide()
				break
			case 'pages':
				$('#plugins').hide()
				$('#pages').show()
				$('#table-spacer').hide()
				break
			default:
				$('#plugins').hide()
				$('#pages').hide()
				$('#table-spacer').show()
				break
		}

		this.updateTable()
	},

	langFilterChange: function() {
		this.updateTable()
	},

	downloadJSON: function() {
		this.downloadTranslation('json')
	},

	downloadCSV: function() {
		this.downloadTranslation('csv')
	},

	downloadTranslation: function(format) {
		App.startLoad()

		var type = (format === 'json') ? 'application/json' : 'text/csv'

		Backbone.sync('fetch', new Backbone.Model(), {
			url: this.app.url() + '/translation?type=' + format,

			dataType: 'text',

			success: function(data) {
				window.URL = window.URL || window.webkitURL

				var file = new Blob([data], {type: type}),
					url  = window.URL.createObjectURL(file),
					link = document.createElement('a')

				link.href = url
				link.download = 'translation'
				link.target = '_blank'

				link.click()

				App.stopLoad()
			},

			error: function() {
				App.stopLoad()
				swal($.t('error.oops'), $.t('appSetup.translationError'), 'error')
			}
		})
	},

	saveChanges: function() {
		App.startLoad()
		$.ajax({
			dataType: 'json',
			url: App.apiRoot + 'apps/' + this.appId + '/translations',
			type: 'POST',
			data: JSON.stringify(this.changes),
			headers: {
				"Authorization": App.session.get('token'),
				"Content-Type": 'application/json'
			},
			success: function() {
				App.stopLoad()
				history.back()
			}
		})
	},

	getPageList: function() {
		// Generate page list dropdown.
		var pageListDropdownOptions = '<option value="">-</option>'

		// Get array of all unique tag names
		var tags = _.filter(this.pageList.pluck('tag'), function(elem, pos, self) {
			return self.indexOf(elem) === pos
		}).sort()

		// Output an option group for each tag
		_.each(tags, function(tag) {
			var taggedPages = this.pageList.where({tag: tag})
			var options = ''

			// Output an option for each page with this tag
			_.each(taggedPages, function(page) {
				options += '<option value="' + page.id + '">' + App.l(page.get('title')) + '</option>'
			})

			pageListDropdownOptions += '<optgroup label="' + tag + '">' + options + '</optgroup>'
		}, this)

		return pageListDropdownOptions
	}
})
