var StormObject = require('editor/storm-object')

module.exports = Backbone.View.extend({
	template: require('./disaster-configure-view-template'),
	className: 'storm-modal modal fade',

	events: {
		'click .save-button': 'save',
		'click .cancel-button': 'close',
		'change .enabled-checkbox': 'enabledChange',
		'change .what-now-page': 'pageChange'
	},

	initialize: function(options) {
		this.disaster = options.disaster
		this.selectOptions = options.selectOptions
	},

	getRenderData: function() {
		var alerts = this.disaster.toJSON().events

		alerts.forEach(function(alert) {
			var assignment = this.model.get('events').findWhere({eventId: alert.id})

			if (assignment) {
				alert.assignment = assignment.toJSON()
			} else {
				delete alert.assignment
			}
		}, this)

		return {
			name: this.disaster.get('name'),
			alerts: alerts,
			selectOptions: this.selectOptions
		}
	},

	afterRender: function() {
		this.model.get('events').forEach(function(e) {
			this.$('tr[data-id=' + e.get('eventId') + '] .what-now-page').val(e.get('src..destination'))
		}, this)

		// Don't allow editing based on permissions.
		if (!App.acl.hasWritePermission('Hazards', 'Disasters')) {
			this.$('input, select').attr('disabled', true)
		}
	},

	save: function(e) {
		var model = this.model

		if (this.needsSaving) {
			this.$('.enabled-checkbox').each(function() {
				var parent = $(this).parent().parent(),
					eventId = parent.data('id'),
					e = model.get('events').findWhere({eventId: eventId})

				if (this.checked) {
					if (!e) {
						e = StormObject.fromClassName('StormQLEvent')
						e.set('eventId', eventId)
						model.get('events').push(e)
					}

					e.set('src..destination', parent.find('.what-now-page').val())
				} else if (e) {
					// Checkbox has been unchecked but event object exists - flag for deletion.
					e.deleted = true
				}
			})

			this.model.needsSaving = true
		}

		this.close()

		// Don't bubble up to parent view.
		e.stopPropagation()
	},

	close: function() {
		this.$el.modal('hide')
	},

	enabledChange: function(e) {
		var select = $(e.currentTarget).parent().parent().find('.what-now-page')

		if (e.currentTarget.checked) {
			select.removeAttr('disabled')
		} else {
			select.attr('disabled', true)
		}

		this.needsSaving = true
	},

	pageChange: function() {
		this.needsSaving = true
	}
})
