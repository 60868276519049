var AppSetupSectionView = require('app-setup/app-setup-section-view'),
	StormObject         = require('editor/storm-object'),
	StormQL             = require('models/stormql'),
	MediaLibrary        = require('media-library/media-library-view'),
	MediaSelectorView   = require('media-library/media-selector-view'),
	screenshotsTemplate = require('./metadata-view-screenshots-template'),
	utils               = require('lib/utils')

/**
 * Exports {@link MetadataView}.
 * @module
 */
module.exports = AppSetupSectionView.extend(/** @lends MetadataView.prototype */{
	/** @override */
	template: require('./metadata-view-template'),

	/** @override */
	events: {
		'click .filter-bar a': 'platformChange',
		'click .choose-icon-button': 'chooseIcon',
		'click .choose-video-button': 'chooseVideo',
		'click .choose-screenshot-button': 'chooseScreenshot',
		'click .add-screenshot-button': 'addScreenshot',
		'click .remove-screenshot-button': 'removeScreenshot',
		'click .choose-featured-graphic-button': 'chooseFeaturedGraphic',

		'click .save-button': 'save'
	},

	/**
	 * @constructs MetadataView
	 * @extends AppSetupSectionView
	 * @override
	 */
	initialize: function(options) {
		AppSetupSectionView.prototype.initialize.apply(this, arguments)

		this._readyCount = 0
		this.isLockingModels = false
		this.views = {}
		this.app = App.appList.get(options.appId)
		this.collection = new StormQL(null, {
			app: this.app
		})
		this.platforms = ['iOS', 'Android', 'Windows']
		this.currentPlatform = null

		this.app.languageList.fetchOnce().then(this.ready.bind(this))

		var collection = this.collection
		var platforms = this.platforms
		collection
			.once('sync', function() {
				// Make sure models exist for each platform
				platforms.forEach(function(platform) {
					if (!collection.find({idiom: platform})) {
						var model = StormObject.fromClassName('Meta')
						model.set('idiom', platform)

						collection.add(model)
					}

					// Convert media object to StormObject
					var meta = collection.find({idiom: platform})
					meta.set('media', StormObject.fromProperties(meta.get('media')))
				})
				this.ready()
			}, this)
			.fetch({
				data: {
					class: 'Meta'
				}
			})

		this.listenTo(this.collection, 'change:icon', this.updateIcon, this)
		this.listenTo(this.collection, 'change:video', this.updateVideo, this)
		this.listenTo(this.collection, 'change:feature', this.updateFeaturedGraphic, this)
		this.listenTo(this.collection, 'change:screenshot', this.refreshScreenshotsPreview, this)
	},

	ready: function() {
		// Wait until Meta and Language object have loaded
		if (++this._readyCount < 2) {
			return
		}

		this.render()
		App.stopLoad()
	},

	/** @override */
	getPageTitle: function() {
		return $.t('metadata.title')
	},

	/** @override */
	getRenderData: function() {
		var data = {
			appId: this.appId,
			categories: require('./categories'),
			platforms: [],
			sharedMeta: null
		}

		var collection = this.collection
		var platforms = this.platforms
		if (collection.length) {
			data.platforms = platforms.map(function(platform) {
				var meta = collection.find({idiom: platform}).toJSON()

				// Format to 2dp
				meta.price = meta.price.toFixed(2)

				return {
					platformName: platform,
					platformMeta: meta
				}
			})

			data.sharedMeta = collection.at(0).toJSON()

			// Default date to today (reset seconds to 0)
			var now = new Date()
			now.setSeconds(0)
			data.sharedMeta.date = data.sharedMeta.date || now.toString()

			// Set whether a country is selected or not
			data.countries = require('./countries')
			data.countries.forEach(function(country) {
				country.selected = data.sharedMeta.countries.indexOf(country.code) !== -1
			})

			// Set whether a language is selected or not
			data.languages = this.app.languageList.toJSON()
			data.languages.forEach(function(language) {
				language.selected = data.sharedMeta.languages.indexOf(language.code) !== -1
			})
		}

		return data
	},

	/** @override */
	afterRender: function() {
		if (document.getElementById('field-date')) {
			this.datepicker = new Pikaday({
				field: document.getElementById('field-date'),
				bound: true,
				showTime: true
			})
		}

		this.$('a[data-platform]').first().trigger('click')

		this.$('select[multiple]').chosen()

		this.refreshScreenshotsPreview()

		// Hide save/media buttons if no write permission.
		if (!App.acl.hasWritePermission('App Setup', 'Metadata')) {
			this.$('.save-button').prop('disabled', true)
			this.$([
				'.choose-icon-button',
				'.add-screenshot-button',
				'.choose-video-button'
			].join(',')).remove()
		}
	},

	platformChange: function(e) {
		var platform = $(e.currentTarget).data('platform')

		this.currentPlatform = platform

		// Hide previous platform details view, show new.
		this.$('.platform-details').hide()
		this.$('.platform-details[data-platform=' + platform + ']').show()

		// Set active class on new link.
		this.$('.filter-bar a.active').removeClass('active')
		$(e.currentTarget).addClass('active')
	},

	chooseIcon: function() {
		var icon = this.collection.find({idiom: this.currentPlatform}).get('media').get('icon')

		this.views.mediaLibrary = new MediaSelectorView({
			app: this.app,
			model: icon,
			mediaType: MediaLibrary.types.ICON
		})

		this.views.mediaLibrary.on('change', function() {
			this.collection.trigger('change:icon')
		}, this)

		$('body').append(this.views.mediaLibrary.el)
		this.views.mediaLibrary.render().show()
	},

	updateIcon: function() {
		var icon = this.collection.find({idiom: this.currentPlatform}).get('media').get('icon')
		var img = document.getElementById(this.currentPlatform + '_field-media.icon-preview')
		img.src = utils.getImagePreviewUrl(icon)
	},

	chooseFeaturedGraphic: function() {
		var feature = this.collection.find({idiom: this.currentPlatform}).get('media').get('feature')

		this.views.mediaLibrary = new MediaSelectorView({
			app: this.app,
			model: feature,
			mediaType: MediaLibrary.types.IMAGE
		})

		this.views.mediaLibrary.on('change', function() {
			this.collection.trigger('change:feature')
		}, this)

		$('body').append(this.views.mediaLibrary.el)
		this.views.mediaLibrary.render().show()
	},

	updateFeaturedGraphic: function() {
		var feature = this.collection.find({idiom: this.currentPlatform}).get('media').get('feature')
		var img = document.getElementById(this.currentPlatform + '_field-media.feature-preview')
		img.src = utils.getImagePreviewUrl(feature)
	},

	chooseVideo: function() {
		var video = this.collection.find({idiom: this.currentPlatform}).get('media').get('preview')

		this.views.mediaLibrary = new MediaSelectorView({
			app: this.app,
			model: video.src,
			mediaType: MediaLibrary.types.VIDEO
		})

		this.views.mediaLibrary.on('change', function() {
			this.collection.trigger('change:video')
		}, this)

		$('body').append(this.views.mediaLibrary.el)
		this.views.mediaLibrary.render().show()
	},

	updateVideo: function() {
		var video = this.collection.find({idiom: this.currentPlatform}).get('media').get('preview')
		var videoElement = document.getElementById(this.currentPlatform + '_field-media.preview-preview')
		videoElement.src = utils.getImagePreviewUrl(utils.getRemoteUrl(video.src.destination))
	},

	addScreenshot: function() {
		var meta = this.collection.find({idiom: this.currentPlatform})
		var screenshots = meta.get('media').get('screenshots')
		var screenshot = StormObject.fromClassName('Image')

		screenshots.add(screenshot)

		this.refreshScreenshotsPreview()
	},

	removeScreenshot: function(e) {
		var screenshotIndex = $(e.target).parents('[data-screenshot-index]').data('screenshot-index')

		var meta = this.collection.find({idiom: this.currentPlatform})

		var screenshots = meta.get('media').get('screenshots')

		screenshots.at(screenshotIndex).destroy()

		this.refreshScreenshotsPreview()
	},

	chooseScreenshot: function(e) {
		var screenshotIndex = $(e.target).parents('[data-screenshot-index]').data('screenshot-index')

		var meta = this.collection.find({idiom: this.currentPlatform})
		var screenshot = meta.get('media').get('screenshots').models[screenshotIndex]

		this.views.mediaLibrary = new MediaSelectorView({
			app: this.app,
			model: screenshot,
			mediaType: MediaLibrary.types.IMAGE
		})

		this.views.mediaLibrary.on('change', function() {
			this.collection.trigger('change:screenshot')
		}, this)

		$('body').append(this.views.mediaLibrary.el)
		this.views.mediaLibrary.render().show()
	},

	refreshScreenshotsPreview: function() {
		if (!this.collection.length) {
			return
		}

		var collection = this.collection

		this.platforms.forEach(function(platform) {
			var screenshots = collection.find({idiom: platform}).get('media').get('screenshots').models
			var previewsContainer = document.getElementById(platform + '_field-media.screenshots-previews')

			previewsContainer.innerHTML = screenshotsTemplate({
				images: screenshots
			})
		})
	},

	save: function() {
		var collection = this.collection

		// Set common fields
		;[
			'date',
			'categories.primary',
			'categories.secondary',
			'rating',
			'phone'
		].forEach(function(fieldName) {
			var key = fieldName.split('.').join('..')
			var val = $(':input[name="' + fieldName + '"]').val()

			collection.forEach(function(model) {
				model.set(key, val)
			})
		})

		// Set common language fields
		;[
			'title',
			'name',
			'descriptions.full',
			'keywords',
			'copyright',
			'email',
			'urls.support',
			'urls.marketing'
		].forEach(function(fieldName) {
			var key = fieldName.split('.').join('..')
			var val = {}
			$(':input[name="' + fieldName + '"]').each(function(i, e) {
				val[e.dataset.lang] = e.value
			})

			collection.forEach(function(model) {
				model.set(key + '..content', val)
			})
		})

		// Set countries
		var fieldName = 'countries'
		var key = fieldName.split('.').join('..')
		var val = $('#field-' + fieldName).val() || []
		collection.forEach(function(model) {
			model.set(key, val)
		})

		// Set languages
		fieldName = 'languages'
		key = fieldName.split('.').join('..')
		val = $('#field-' + fieldName).val() || []
		collection.forEach(function(model) {
			model.set(key, val)
		})

		// Set platform-specific fields
		var platforms = this.platforms

		;[
			'price'
		].forEach(function(fieldName) {
			platforms.forEach(function(platform) {
				var $field = $(':input[name="' + fieldName + '"][data-platform="' + platform + '"]')
				if (!$field.length) {
					return
				}

				var model = collection.find({idiom: platform})
				var key = fieldName.split('.').join('..')
				var val = $field.val()

				model.set(key + '..content', val)
			})
		})

		// Set platform-specific language fields
		;[
			'descriptions.short'
		].forEach(function(fieldName) {
			platforms.forEach(function(platform) {
				var $field = $(':input[name="' + fieldName + '"][data-platform="' + platform + '"]')
				if (!$field.length) {
					return
				}

				var model = collection.find({idiom: platform})
				var key = fieldName.split('.').join('..')

				var val = {}
				$field.each(function(i, e) {
					val[e.dataset.lang] = e.value
				})

				model.set(key + '..content', val)
			})
		})

		// Save objects
		this.isLockingModels = true
		App.startLoad()
		this.lockModels()
	},

	lockModels: function() {
		var collectionLength = this.collection.length
		if (collectionLength) {
			// Start at 0
			this.lockModelAtIndex(0)
		}
	},

	lockModelAtIndex: function(index) {
		if (index < this.collection.length) {
			var model = this.collection.at(index)
			index += 1
			if (!model.isNew()) {
				model.requestLock(function() {
					// Lock the next one...
					this.lockModelAtIndex(index)
				}.bind(this))
			} else {
				this.lockModelAtIndex(index)
			}
		} else {
			this.doSave()
		}
	},

	doSave: function() {
		var app = this.app
		var currentPos = 0
		var self = this
		this.collection.forEach(function(model) {
			if (model.isNew()) {
				model.once('sync', function() {
					currentPos += 1
					if (currentPos === self.collection.length) {
						App.stopLoad()
					}
				})
				// New objects must be posted with app ID
				model.save(null, {
					data: JSON.stringify({
						data: model.toJSON(),
						appId: app.id
					})
				})
			} else {
				model.once('sync', function() {
					currentPos += 1
					model.requestUnlock().then(function() {
						if (currentPos === self.collection.length) {
							App.stopLoad()
						}
					})
				})
				model.save()
			}
		})
	}
})
