<div class="container">
	<div class="row">
		<div class="header-with-buttons">
			<p class="text-left">Active Events</p>
			<a href="/apps/{{appId}}/hazards/events"><button class="button outlined"><i class="icon-cogs"></i> Configure Events</button></a>
		</div>

		<div class="col-xs-12 disasters-events-list disaster-box no-select">
			<!-- Events list here -->
		</div>
	</div>

	<div class="row">
		<div class="header-with-buttons">
			<p class="text-left">Categories</p>
			<button class="button outlined add-category"><i class="icon-plus"></i> Add category</button>
			<button class="button save-categories-button"> Save </button>
		</div>
		<div class="disasters-categories-list">
			<!-- Categories list here -->
		</div>
	</div>
</div>
