var MediaLibrary = require('./media-library-view'),
	EditorSectionView = require('editor/editor-section-view')

module.exports = EditorSectionView.extend({
	className: 'media-library-wrapper',

	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)

		this.app_ = App.appList.get(options.appId)
		this.views = {
			mediaLibrary: new MediaLibrary({app: this.app_})
		}
	},

	getPageTitle: function() {
		return $.t('mediaLibrary.title')
	},

	getRenderData: function() {
		return {appId: this.app_.id}
	},

	afterRender: function() {
		this.$('.hide, .fade').removeClass('hide fade')
		this.$('.close-button').remove()
	}
})
