module.exports = Backbone.Collection.extend({
	initialize: function(options) {
		this.id = options.id
	},

	url: function() {
		return App.apiRoot + 'uploads/' + this.id + '/variants'
	},

	comparator: function(a, b) {
		var aArea = a.get('dimensions..width') * a.get('dimensions..height')
		var bArea = b.get('dimensions..width') * b.get('dimensions..height')

		return aArea > bArea ? 1 : aArea < bArea ? -1 : 0
	}
})
