<form class="row">
	<div class="col-xs-12">
		<div class="row">
			<div class="col-sm-6">
				<h3 data-i18n="arpro.codeGroup.editItem"></h3>
			</div>
			<div class="col-sm-6 actions-col">
				<a href="javascript:history.back()"><button class="button red wide" type="button">{{t "common.cancel"}}</button></a>
				<button class="button green wide save" type="submit">{{t "common.save"}}</button>
			</div>
		</div>
	</div>

	<!-- Name -->
	<div class="col-sm-6">
		<div class="form-group">
			<label class="control-label">{{t "arpro.codeGroup.name"}}</label>
		</div>
		{{#each languages}}
			<div class="form-group">
				<div class="input-group">
					<span class="input-group-addon">{{name}}</span>
					<input type="text" class="name-input form-control" data-code="{{code}}">
				</div>
			</div>
		{{/each}}
	</div>

	<!-- Description -->
	<div class="col-sm-6">
		<div class="form-group">
			<label class="control-label">{{t "arpro.codeGroup.description"}}</label>
		</div>
		{{#each languages}}
			<div class="form-group">
				<div class="input-group">
					<span class="input-group-addon">{{name}}</span>
					<input type="text" class="description-input form-control" data-code="{{code}}">
				</div>
			</div>
		{{/each}}
	</div>

	<!-- Codes -->
	<div class="col-sm-6">
		<div class="form-group">
			<label class="control-label" for="code-input">{{t "arpro.codeGroup.codes"}}</label>
		</div>
		<div class="form-group">
			<div class="col-sm-12 input-group">
				<input type="text" id="code-input" class="form-control" required="" value="" placeholder="{{t "arpro.codeGroup.addCodes"}}">
				<span class="input-group-btn">
					<button class="btn btn-default" type="button" id="addCode">{{t "common.add"}}</button>
				</span>
			</div>
		</div>

		<ul id="code-list">
			{{#each codes}}
				<li>{{this}} <a href="" class="code-remove-button" data-id="{{@key}}">×</a></li>
			{{/each}}
		</ul>
	</div>
</form>
