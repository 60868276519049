<form class="row">
	<div class="col-xs-12">
		<div class="row">
			<div class="col-sm-6">
				<h3 data-i18n="badges.editBadge"></h3>
			</div>
			<div class="col-sm-6 actions-col">
				<a href="javascript:history.back()"><button class="button red wide" type="button">{{t "common.cancel"}}</button></a>
				<button class="button green wide save" type="submit">{{t "common.save"}}</button>
			</div>
		</div>
	</div>

	<div class="col-xs-12">
		<div class="form-group">
			<label class="control-label">{{t "editor.inspector.properties.badge.icon"}}</label>
		</div>
		<div class="form-group">
			<img class="badge-icon-preview" src="{{getPreviewUrl icon}}">
		</div>
		<div class="form-group">
			<button type="button" class="button choose-image-button" style="margin-bottom: 10px">{{t "editor.inspector.properties.image.chooseButton"}}</button>
		</div>
	</div>

	<div class="col-sm-6">
		<div class="form-group">
			<label class="control-label">{{t "editor.inspector.properties.title.title"}}</label>
		</div>
		{{#each languages}}
			<div class="form-group">
				<div class="input-group">
					<span class="input-group-addon">{{name}}</span>
					<input type="text" class="title-input form-control" data-code="{{code}}">
				</div>
			</div>
		{{/each}}
	</div>

	<div class="col-sm-6">
		<div class="form-group">
			<label class="control-label">{{t "editor.inspector.properties.badge.completion.title"}}</label>
		</div>
		{{#each languages}}
			<div class="form-group">
				<div class="input-group">
					<span class="input-group-addon">{{name}}</span>
					<input type="text" class="completion-input form-control" data-code="{{code}}">
				</div>
			</div>
		{{/each}}
	</div>

	<div class="col-sm-6">
		<div class="form-group">
			<label class="control-label">{{t "editor.inspector.properties.shareMessage.title"}}</label>
		</div>
		{{#each languages}}
			<div class="form-group">
				<div class="input-group">
					<span class="input-group-addon">{{name}}</span>
					<input type="text" class="share-input form-control" data-code="{{code}}">
				</div>
			</div>
		{{/each}}
	</div>

	<div class="col-sm-6">
		<div class="form-group">
			<label class="control-label">{{t "editor.inspector.properties.badge.how.title"}}</label>
		</div>
		{{#each languages}}
			<div class="form-group">
				<div class="input-group">
					<span class="input-group-addon">{{name}}</span>
					<input type="text" class="how-input form-control" data-code="{{code}}">
				</div>
			</div>
		{{/each}}
	</div>
</form>
