<div class="col-sm-6 col-lg-4 col-lg-offset-2">
	<form class="form-horizontal">
		<fieldset>
			<legend data-i18n="appSetup.publishableLanguages"></legend>

			{{#each appLanguages}}
				<div class="form-group">
					<label class="control-label col-xs-3">{{name}}</label>
					<div class="controls col-xs-9">
						<div class="publish-options" data-id="{{id}}">
							<label class="checkbox-inline">
								<input type="checkbox" class="publish-option" value="{{id}}"> {{t "appSetup.dev"}}
							</label>
							<label class="checkbox-inline">
								<input type="checkbox" class="publish-option" value="{{id}}"> {{t "appSetup.live"}}
							</label>
						</div>
					</div>
				</div>
			{{/each}}

			{{#if languages}}
                <div class="form-group">
                    <div class="col-xs-offset-1">
                        <select class="form-control new-language" style="display: inline; width: auto;">
                            {{#each languages}}
                                <option value="{{id}}">{{name}} ({{code}})</option>
                            {{/each}}
                        </select>
                        <button type="button" class="button add-language">{{t "common.add"}}</button>
                    </div>
                </div>
			{{/if}}

			<div class="row">
				<div class="col-xs-offset-1">
					<button class="button green save-languages" type="submit" data-i18n="common.save"></button>
				</div>
			</div>

			<p class="help-block">{{t "appSetup.addMoreLanguages"}}</p>
		</fieldset>
	</form>
</div>
