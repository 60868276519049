var StormView = require('lib/storm-view'),
	LanguageList = require('editor/language-list'),
	Whatnow = require('./whatnow'),
	DisasterList = require('../hazards/disaster-list'),
	WhatnowDisasterList = require('./whatnow-disaster-list'),
	WhatnowList = require('./whatnow-list'),
	countryCodes = require('./organisations')

module.exports = StormView.extend({
	template: require('./whatnow-item-edit-view-template'),

	events: {
		'click .save': 'saveWhatnow',
		'click .delete-language': 'removeLanguage',
		'click .list-group-item ul': 'stopClose',
		'click .list-content li': 'expandListItem',
		'click .add-row': 'addNewRow',
		'click .delete-row': 'removeRow',
		'click .add-language-button': 'addLanguage',
		'click .more-info': 'toggleMoreInfo'
	},

	initialize: function(options) {
		this.countryCode = options.countryCode
		this.countryCodes = countryCodes
		// Get whatnow, else default to new model
		var modelPromise
		this.model = new Whatnow()
		this.model.clear()
		if (options.id !== 'new') {
			this.model.set('id', options.id)
			this.model.once('sync', this.ready, this)
			modelPromise = this.model.fetch()
		}

		// Get all the languages.
		this.languageList = new LanguageList()
		var langFetch = this.languageList.fetch()
		this.disasterList = new DisasterList()
		var disasterFetch = this.disasterList.fetch()
		this.whatnowList = new WhatnowList({countryCode: this.countryCode})
		var whatnowListFetch = this.whatnowList.fetch()
		this.language = App.il8next.language
		this.translationViews = []

		$.when(langFetch, disasterFetch, modelPromise, whatnowListFetch).then(this.ready.bind(this))
	},

	getRenderData: function() {
		if (!this.model.get('translations')) {
			this.model.set('translations', [])
		}
		return {
			languages: this.languageList.toJSON(),
			disasterList: this.disasterList.toJSON(),
			whatnowDisasters: WhatnowDisasterList,
			currentLang: this.language,
			currentEvents: this.currentEvents,
			countryCode: this.countryCode,
			countryCodes: this.countryCodes,
			data: this.model.toJSON()
		}
	},

	ready: function() {
		// Get all the current events currently owned with a whatnow
		this.currentEvents = []
		this.whatnowList.each(function(item) {
			var parent = this
			$.each(item.get('data'), function(index, value) {
				if (parent.model.get('eventType') !== value.eventType) {
					parent.currentEvents.push(value.eventType)
				}
			})
		}, this)

		App.stopLoad()
		var data = this.model.get('data')
		this.model.set(data)
		this.render()
	},

	afterRender: function() {
		this.translations = this.model.get('translations') || []
	},

	addLanguage: function(event) {
		event.preventDefault()
		App.startLoad()
		// Get language
		var langCode = $('#whatnow-langselect').val()
		// Add to translations
		var translation = {
			lang: langCode
		}

		this.addNewTranslation(translation)
		this.setModel()
		App.stopLoad()
		this.render()
	},

	addNewTranslation: function(translation) {
		var translations = []
		// Check to see if translations exists
		if (this.model.get('translations')) {
			translations = this.model.get('translations')
			if (this.translationExists(translation.lang)) {
				swal({
					title: 'Error',
					text: 'Translation for ' + translation.langCode + ' already exists',
					type: 'error'
				})
				App.stopLoad()
				return
			}
		}
		translations.push(translation)
		this.model.set('translations', translations)
		this.translations = this.model.get('translations')
	},

	removeLanguage: function(event) {
		var index = event.currentTarget.getAttribute("data-id")
		App.startLoad()
		swal({
			title: $.t('editor.inspector.areYouSure'),
			text: $.t('editor.inspector.confirmDelete'),
			showCancelButton: true
		}, function(didConfirm) {
			if (didConfirm) {
				// Remove from array.
				if (index > -1) {
					this.model.get('translations').splice(index, 1)
					// this.loadTranslations()
					this.render()
				}
			}
		}.bind(this))
		App.stopLoad()
	},

	translationExists: function(lang) {
		if (this.model.get('translations')) {
			var translations = this.model.get('translations')
			for (var i = 0; i < translations.length; i++) {
				if (translations[i].lang === lang) {
					return true
				}
			}
		}
		return false
	},

	setModel: function() {
		// Country code
		var countryCode = this.countryCode
		// Event type
		var eventType = $('#whatnow-event').val()
		// Web URL
		var webUrl = $('#whatnow-moreinfo').val()
		// Created by
		var createdBy = Storm.user_.id

		// Translations for whatnows,
		for (var i = 0; i < this.translations.length; i++) {
			var lang = this.translations[i].lang
			// Title
			this.translations[i].title = $('#whatnow-new-title-' + lang).val()
			// Description
			this.translations[i].description = $('#whatnow-new-description-' + lang).val()
			// Mid-term
			this.translations[i].midTerm = this.getTranslationRows('#midterm-' + lang)
			// Forecast
			this.translations[i].forecast = this.getTranslationRows('#forecast-' + lang)
			// Warning
			this.translations[i].warning = this.getTranslationRows('#warning-' + lang)
			// Watch
			this.translations[i].watch = this.getTranslationRows('#watch-' + lang)
			// Immediate
			this.translations[i].immediate = this.getTranslationRows('#immediate-' + lang)
			// Recover
			this.translations[i].recover = this.getTranslationRows('#recover-' + lang)
		}

		this.model.set({
			countryCode: countryCode,
			eventType: eventType,
			webUrl: webUrl,
			createdBy: createdBy,
			translations: this.translations
		})
	},

	saveWhatnow: function(event) {
		event.preventDefault()
		App.startLoad()
		this.setModel()
		this.model.save(null, {
			success: function() {
				App.stopLoad()
				this.model = new Whatnow()
				history.back()
			}.bind(this)
		})
		App.stopLoad()
	},

	getTranslationRows: function(tableId) {
		var rowValues = []
		$(tableId + ' > tbody  > tr > td > input').each(function() {
			if (this.value) {
				rowValues.push(this.value)
			}
		})
		return rowValues
	},

	expandListItem: function(event) {
		$(event.currentTarget).find('ul').toggleClass('open')
	},

	stopClose: function(event) {
		event.stopPropagation()
	},

	addNewRow: function(event) {
		event.preventDefault()
		$(event.currentTarget).parents("tbody").append('<tr>' +
														'<td>' +
															'<input type="text" class="form-control">' +
														'</td>' +
														'<td class="tools">' +
															'<i class="icon-trash delete-row"></i>' +
														'</td>' +
													'</tr>')
	},

	removeRow: function(event) {
		event.preventDefault()
		$(event.currentTarget).parents("tr").remove()
	},

	toggleMoreInfo: function(event) {
		event.preventDefault()
		event.stopPropagation()
		var info = $(event.currentTarget).data('info')
		$('#more-info-' + info).toggle()
	}
})
