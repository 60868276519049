{
	"error": {
		"noAccess": "У вас нет доступа к этой системе. Обратитесь к своему системному администратору.",
		"noAccessJoin": "В данный момент у вас нет доступа к этой системе. Хотите присоединиться?",
		"oops": "К сожалению!",
		"allFields": "Пожалуйста, заполните все поля.",
		"noApps": "У вас нет доступа к приложениям",
		"saveFailed": "Ошибка сохранения. Обновите страницу.",
		"cantPaste": "Этот тип объекта нельзя вставлять здесь.",
		"generic": "Произошла ошибка.",
		"lockedBy": "Этот объект в настоящее время заблокирован",
		"exitWarning": "Данные приложения все еще сохраняются. Если вы покинете страницу, данные могут быть потеряны.",
		"noPermission": "У вас нет разрешения на выполнение этого действия.",
		"connect": "Не удалось подключиться к серверу API.",
		"rootPage": "Не удалось найти корневую страницу."
	},
	"common": {
		"filter": "Фильтр",
		"linkTypes": {
			"email": "Электронная почта",
			"share": "Поделиться",
			"sms": "смс",
			"external": "внешний",
			"emergency": "Крайняя необходимость",
			"timer": "таймер",
			"call": "Вызов",
			"internal": "внутренний",
			"native": "Родной",
			"appLink": "Inter-приложение",
			"uri": "URI"
		},
		"linkDescriptions": {
			"email": "Ссылка на отправление электронной почты по указанному адресу.",
			"uri": "Ссылка на внешнюю веб-страницу или приложение. Поможет пользователю выйти из приложения.",
			"appLink": "Ссылка на страницу в другом приложении.",
			"emergency": "Ссылка для набора номера экстренного телефона, указанного в настройках пользователя.",
			"external": "Ссылка на внешнюю веб-страницу. Откроется в приложении.",
			"internal": "Ссылка на другую страницу в приложении.",
			"timer": "Ссылка, которая запускает таймер обратного отсчета на устройстве.",
			"native": "Ссылка на часть встроенных функций в приложении.",
			"sms": "Ссылка для отправки SMS-сообщения с устройства пользователя.",
			"call": "Ссылка для набора номера телефона с устройства пользователя.",
			"share": "Ссылка для совместного использования приложения через собственный «общий» диалог на устройстве."
		},
		"pageTags": {
			"tests": "тесты",
			"other": "Другие",
			"info": "Информация",
			"emergency": "Крайняя необходимость",
			"learn": "Учить",
			"prepare": "Подготовить"
		},
		"done": "Готово",
		"tag": "Тег",
		"submit": "Отправить",
		"next": "следующий",
		"close": "Закрыть",
		"email": "Адрес электронной почты",
		"finish": "Конец",
		"add": "Добавить",
		"position": "Должность",
		"username": "имя пользователя",
		"view": "Посмотреть",
		"cancel": "Отмена",
		"back": "назад",
		"pleaseWait": "пожалуйста, подождите",
		"preview": "предварительный просмотр",
		"language": "язык",
		"approve": "Одобрить",
		"reject": "отклонять",
		"approved": "утвержденный",
		"settings": "настройки",
		"sections": "Разделы",
		"longLoad": "Получая большой объем данных, это может занять до минуты.",
		"closeThisMessage": "Закрыть это сообщение",
		"page": "страница",
		"edit": "редактировать",
		"publish": "Публиковать",
		"upload": "Загрузить",
		"save": "Сохранить",
		"tools": "инструменты",
		"description": "Описание",
		"clickToBrowseMap": "Нажмите, чтобы просмотреть карту",
		"warning": "ПРЕДУПРЕЖДЕНИЕ"
	},
	"login": {
		"title": "Войти",
		"inputs": {
			"password": "пароль"
		},
		"error": {
			"title": "Oopsie!",
			"emailNotFound": "Адрес электронной почты не найден.",
			"linkExpired": "Эта ссылка на сброс пароля истёк.",
			"body": "Вы ввели неправильное имя пользователя или пароль."
		},
		"forgot": {
			"title": "Мы все иногда забываем!",
			"body": "Введите адрес электронной почты, связанный с вашей учетной записью."
		},
		"success": {
			"title": "Успех!",
			"body": "Вы должны вскоре получить электронное письмо с инструкциями по сбросу пароля.",
			"loginBody": "Вы вошли в систему успешно, но некуда идти! Вы находитесь на auth.3sidedcube.com. Перейдите в CMS вашей организации."
		},
		"links": {
			"forgot": "Я забыл ...",
			"return": "Вернуться к логину"
		},
		"reset": {
			"confirm": "подтвердить",
			"newPassword": "Новый пароль",
			"submit": "Отправить",
			"success": "Ваш пароль установлен, вы можете закрыть это окно."
		}
	},
	"localisations": {
		"title": "Места",
		"string": "Строчка",
		"key": "Ключ",
		"addNew": "Добавить новую локализацию",
		"addMultiple": "Добавить нескольколокализаций",
		"addMultipleInstructions": "Добавить или редактировать несколько локализаций сразу. Использовать в форматеCSV",
		"uploadCsv": "Загрузить CSV",
		"copyFromAnotherApp": "Скопироватьиздругого приложения",
		"confirmCopy": "Вы уверены, что хотите копировать локализации из избранных приложений?",
		"overwriteExisting": "Переписать существующие локализации",
		"overwriteWarning": "Все существующие переводы, которые вы сделали, будут переписаны.",
		"all": "<b>Все</b>локализации",
		"errors": {
			"invalidKey": "Неверный ключ локализации. Ключи должны начинаться с нижнего подчеркивания и содержать только заглавные буквы и нижние подчеркивания. Ключи должны содержать не менее двух разделов (биты разделены подчеркиваниями).",
			"csvTooManyColumns": "Слишком много столбцов в заголовке",
			"csvInvalidHeader": "Неверный заголовок.",
			"csvInvalidKeyOnLine": "Неверный ключ локализации в строке",
			"csvInvalidColumnCountOnLine": "Неверное количество столбцов с троке",
			"expected": "Ожидается",
			"got": "получено"
		}
	},
	"appSetup": {
		"expandAll": "Расширить все",
		"addCsv": "ДобавитьCSV",
		"dev": "Dev",
		"key": "ключ",
		"value": "Стоимость",
		"appName": "Имя приложения",
		"live": "Жить",
		"startPage": "Начальная страница",
		"type": "Тип",
		"properties": "свойства",
		"publishable": "Возможность публикации:",
		"bundleIdentifiers": "Идентификаторы пакетов SDK",
		"title": "Настройка приложения",
		"languages": "Языки приложений",
		"addMoreLanguages": "Здесь доступны только языки, назначенные этой организации. Назначьте больше в разделе администратора.",
		"translationError": "Не удалось загрузить файл перевода. Пожалуйста, проверьте, что приложение и организация имеют хотя бы один язык, который включен и доступен для публикации.",
		"localisation": "Локализация приложений",
		"publishableLanguages": "Публичные языки",
		"translation": "Переводы"
	},
	"metadata": {
		"addScreenshot": "Добавить снимок экрана",
		"appIconHint": "Должно быть 1024x1024 без прозрачности. Должен быть форматJPGилиPNG, с минимальным разрешением по крайней мере 72DPI, в цветовом пространствеRGB. Без слоев и скругленных углов",
		"appPreviewVideo": "Добавить видео превью",
		"appPreviewVideoHint": "Видео-превью приложения должно быть в форматеM4V,MP4 илиMOVи не может превышать 500 мб",
		"appStoreTitle": "Название хранилища приложения",
		"delete": "Удалить",
		"emailHint": "Добавьте эл.адрес для связи сAppstoreили другими пользователями",
		"featureGraphic": "Отличительный символ",
		"featureGraphicHint": "МагазинGooglePlayтребует от приложений наличия отличительного символа или «баннера», ширина:124 х высота: 500. Это может быть отдельное изображение или фоновое изображение для промо-видео размерами (при наличии)",
		"languages": "Языки",
		"nameOnDevice": "Название приложения на устройстве",
		"phoneNumberHint": "Должен включать телефонный код страны",
		"platformSpecific": "Метаданные для платформы",
		"price": "Цена",
		"rating": "Оценка",
		"screenshotsHint": "Добавьте скриншоты для каждого размера экрана. Скриншоты должны быть в форматахJPGилиPNG, в цветовом пространствеRGB",
		"shared": "Кроссплатформенные метаданные",
		"shortAppDescription": "Короткое описание приложения",
		"supportUrlHint": "Адрес-URLс поясняющей информацией для вашего приложения. Адрес-URLбудет виден вAppStore",
		"websiteHint": "Адрес-URLс рекламной информацией о вашем приложении. Этот адрес будет виден в магазинеAppStore",
		"copyright": "Авторские права",
		"keywords": "Ключевые слова",
		"phoneNumber": "Номер телефона",
		"website": "URL маркетинга",
		"primaryCategory": "Первичная категория",
		"title": "Метаданные",
		"appIcon": "Значок приложения",
		"name": "Имя приложения",
		"launchDate": "Дата запуска",
		"features": "Особенности",
		"secondaryCategory": "Вторичная категория",
		"supportUrl": "URL поддержки",
		"privacyPolicyUrl": "URL политики конфиденциальности",
		"noBuildPlatforms": "Для этого приложения не существует платформ сборки",
		"iconSize": "Значок оригинала должен иметь размер 1024x1024.",
		"appStore": "Сведения о магазине приложений",
		"description": "Описание"
	},
	"plugins": {
		"title": "Плагины",
		"availablePlugins": "Доступные плагины"
	},
	"editor": {
		"inspector": {
			"swapItem": "Сменить объект",
			"properties": {
				"progressMessage": "Сообщение о прогрессе",
				"annotation": {
					"title": "аннотирование",
					"description": "Номер шага"
				},
				"title": {
					"title": "заглавие",
					"description": "Название списка"
				},
				"name": {
					"title": "имя",
					"description": "Имя внутренней системы"
				},
				"hint": {
					"title": "намек",
					"description": "Инструкции для пользователя"
				},
				"image": {
					"title": "Образ",
					"chooseButton": "Выберите изображение",
					"removeButton": "Удалить изображение"
				},
				"video": {
					"addLanguage": "Добавить язык",
					"title": "видео",
					"loop": "петля",
					"chooseButton": "Выберите видео",
					"attributes": "Атрибуты"
				},
				"link": {
					"title": "Ссылка",
					"destination": "Место назначения",
					"type": "Тип",
					"body": "Основной текст",
					"linkTitle": "Название ссылки",
					"testLink": "Тестовая ссылка",
					"linkDestination": "Назначение ссылки",
					"duration": "Продолжительность таймера (минут)",
					"recipients": "Получатели (по одной в строке)"
				},
				"images": {
					"add": "Добавить изображение"
				},
				"animation": {
					"chooseButton": "Выберите анимацию",
					"delay": "Задержка кадра (в секундах)"
				},
				"embeddedLinks": {
					"add": "Добавить ссылку",
					"title": "Встроенные ссылки"
				},
				"embeddedMedia": {
					"title": "Встроенные носители"
				},
				"header": {
					"title": "заголовок",
					"description": "Появляется над группой предметов"
				},
				"footer": {
					"title": "нижний колонтитул",
					"description": "Появляется ниже группы элементов"
				},
				"badge": {
					"completion": {
						"title": "Текст завершения",
						"description": "Отображается пользователю при завершении викторины"
					},
					"how": {
						"title": "Как заработать",
						"description": "Текст, описывающий, как достичь этого значка"
					},
					"icon": "Значок",
					"title": "знак"
				},
				"units": {
					"title": "Единицы",
					"description": "секунд / метров и т. д."
				},
				"range": {
					"start": "Начало",
					"length": "длина",
					"increment": "инкремент",
					"title": "Ассортимент"
				},
				"completionText": {
					"title": "Текст завершения",
					"description": "Отображается пользователю при правильном ответе"
				},
				"failureText": {
					"title": "Текст отказа",
					"description": "Отображается пользователю при неправильном ответе"
				},
				"winThreshold": {
					"title": "Порог выигрыша (%)",
					"description": "Процент правильных ответов, необходимых для прохождения теста"
				},
				"winMessage": {
					"description": "Отображается пользователю при прохождении теста",
					"title": "Выиграть сообщение"
				},
				"loseMessage": {
					"title": "Потерять сообщение",
					"description": "Отображается пользователю при провале теста"
				},
				"shareMessage": {
					"title": "Поделиться сообщением",
					"description": "Текст для совместного использования с значком в социальных сетях"
				},
				"quiz": "викторина",
				"standard": "стандарт",
				"columns": "Колонны",
				"answer": "Ответ",
				"correctAnswer": "Правильный ответ",
				"button": "кнопка",
				"relatedLinks": "Ссылки по теме",
				"caption": "титр",
				"quizzes": "Викторины",
				"placeholderImage": "Изображение заполнителя",
				"initialPosition": "Исходное положение",
				"selectionLimit": "Предел выбора",
				"textContent": "Текстовое содержимое",
				"winRelatedLinks": "Ссылки по теме",
				"loseRelatedLinks": "Потерять ссылки",
				"gridType": "Тип сетки",
				"overlay": "Текст наложения",
				"delay": "Задержка (и)",
				"volatile": "Летучие (не сохраняются)",
				"confirmGridTypeChange": "Вы действительно хотите изменить тип сетки? Это изменит все элементы сетки."
			},
			"areYouSure": "Ты уверен?",
			"copy": "копия",
			"newType": "Новый тип",
			"paste": "Вставить",
			"changeType": "Изменить тип",
			"moveDown": "Переместить вниз",
			"moveUp": "Переместить вверх",
			"addAnother": "Добавить еще один",
			"title": "Изменить свойства",
			"copiedItem": "Скопированный элемент",
			"addBulletItem": "Добавить маркер",
			"confirmPageDelete": "Подтверждение удаления страницы",
			"copySuccess": "Объект, скопированный в буфер обмена",
			"confirmDelete": "Вы действительно хотите удалить этот элемент?",
			"confirmApproval": "Вы уверены, что хотите это одобрить?",
			"rootPageDelete": "Это корневая страница приложения. Его нельзя удалить.",
			"confirmPageDeleteWarning": "ПРЕДУПРЕЖДЕНИЕ: вы удаляете объект страницы. Все содержимое страницы будет удалено. Вы уверены что хотите продолжить?"
		},
		"objectPreviews": {
			"TextListItem": "Используйте этот объект для вводного текста, обычно в верхней части страницы",
			"TitleListItem": "Используйте этот объект для однострочного текста",
			"DescriptionListItem": {
				"title": "Этот объект имеет название и описание",
				"description": "Используйте этот объект, когда у вас много текста"
			},
			"StandardListItem": {
				"title": "Используйте этот объект большую часть времени",
				"description": "Этот объект имеет название, описание и изображение. Вы можете оставить биты пустыми, как ярлык"
			},
			"ToggleableListItem": {
				"title": "Используйте это, когда вам нужно что-то скрыть",
				"description": "Этот текст может быть показан или скрыт пользователем. Хорошо, если у вас много подробностей — может быть, «Часто задаваемые вопросы»"
			},
			"OrderedListItem": {
				"title": "Упорядоченный список",
				"description": "Используйте это, когда вам нужен нумерованный список"
			},
			"UnorderedListItem": {
				"title": "Маркированный список",
				"description": "Используйте это, когда вы хотите создать список с жирными точками"
			},
			"CheckableListItem": "Используйте это для контрольного списка",
			"ButtonListItem": "Используйте это, если вам нужна кнопка",
			"HeaderListItem": {
				"title": "Используйте это в верхней части страницы",
				"description": "Может иметь название и более длинное описание"
			},
			"LogoListItem": "Используйте это для логотипов и подобных объектов, когда вы хотите получить изображение, захват и ссылку",
			"SpotlightListItem": {
				"first": "Вращение",
				"second": "Полезные сведения"
			},
			"ChunkyListItemView": {
				"title": "Это как объект основного списка — с большей отбивкой",
				"description": "Здесь имеется название, описание и ярлык. Вы можете оставить биты пустыми — как ярлык"
			},
			"ListItemView": "Этот объект имеет только название"
		},
		"pageList": {
			"title": "страницы",
			"filter": "Фильтровать страницы",
			"allPages": "Все страницы"
		},
		"title": "редактор",
		"addQuiz": "Добавить тест",
		"addPage": "Добавить страницу",
		"saving": "Сохранение ...",
		"addLink": "Добавить ссылку",
		"addApp": "Добавить приложение",
		"pageType": "Тип страницы",
		"savesQueued": "сохраняет в очереди",
		"pageTag": "Тег страницы",
		"pageStyle": "Стиль страницы",
		"pageName": "Название страницы",
		"lastSaved": "Последний сохраненный:",
		"addObject": "Добавить объект здесь",
		"newFromTemplate": "Новое в шаблоне",
		"confirmRelock": "Этот объект уже заблокирован вами. Вы хотите снова заблокировать его? Ваш предыдущий замок будет отменен.",
		"confirmRequestNewLock": "Ваш замок для этой страницы отменен. Вы хотите запросить новый замок?",
		"never": "никогда"
	},
	"mediaLibrary": {
		"chooseExisting": "Выберите из существующих",
		"upload": {
			"instructions": "Нажмите здесь или перетащите файл, чтобы начать",
			"cropType": "Обрезка",
			"noCrop": "Без обрезки",
			"square": "Квадрат",
			"squareDesc": "Используйтеэтуобрезкудляярлыковикартинокопросника.Картинки опросника должны бытьfor<b>640(ш)</b>by<b>640(в)</b> пикселей.",
			"errors": {
				"tooManyFiles": "Пожалуйста, выберите один файл"
			},
			"requiresTransparency": "Требует прозрачного фона",
			"tags": "Теги",
			"newTag": "Новый тег",
			"filesizeError": "Загрузка не удалась. Файл слишком велик. Максимально допустимый размер файла",
			"title": "Загрузить носитель",
			"gdpcHeaderDesc": "Используйте эту обрезку для больших изображений заголовков в приложениях «Опасности». Изображения должны быть <b>640 (w)</b> на <b>568 (h)</b> пикселей в размере.",
			"spotlightDesc": "Используйте этот обрез для прожекторов, от края до края, слайд-шоу и видеоизображений. Изображения должны быть размером <b>640 (w)</b> на <b>320 (h)</b> пикселей.",
			"invalidTag": "Недопустимые теги. Теги не могут содержать символ подстановки (*).",
			"agreementText": "Все содержимое, загруженное в эту систему, зависит от условий",
			"genericError": "Ошибка загрузки файла. Код ошибки:",
			"selectType": "Выберите тип загружаемого изображения.",
			"gdpcSpotlightDesc": "Используйте этот обрез для заголовков в приложениях первой помощи, прожекторах, от края до края, слайд-шоу и видеоизображения. Изображения должны быть размером <b>640 (w)</b> на <b>320 (h)</b> пикселей.",
			"headerDesc": "Используйте этот обрезку для больших изображений заголовков, которые отображаются в верхней части страницы. Изображения должны быть <b>640 (w)</b> на <b>568 (h)</b> пикселей в размере.",
			"imageDesc": "Изображения <b>640 (w)</b> на <b>320 (h)</b> пикселей рекомендуются для изображений с края до края, изображений слайд-шоу и видеоизображений. Визуальные изображения должны быть <b>640 (w)</b> на <b>640 (h)</b> пикселей.",
			"formatError": "Загрузка не удалась. Недопустимый формат файла. Разрешенные форматы изображений: .jpeg, .png, .gif Разрешенные видеоформаты: .mp4, .m4v"
		},
		"loadMore": "Загрузи больше",
		"society": "общество",
		"editMedia": "Редактировать медиа",
		"additionalTags": "Дополнительные теги",
		"section": "Раздел",
		"dragToReorder": "Перетащите, чтобы изменить порядок",
		"commas": "Разделять запятыми",
		"delete": "Удалить",
		"clearFilters": "Очистить фильтры",
		"image": "Образ",
		"icon": "Значок",
		"video": "видео",
		"title": "Медиа библиотека",
		"animation": "Анимация",
		"refine": "Уточнить библиотеку",
		"filterByTags": "Фильтровать по тегам",
		"type": "Тип носителя",
		"confirmTagRemoval": "Вы действительно хотите удалить этот тег?",
		"general": "Генеральная"
	},
	"viewPicker": {
		"TextListItem": "Состыкованный межгрупповой текст",
		"TitleListItem": "Одна строка текста",
		"StandardListItem": "Название,ссылка и ярлык",
		"DescriptionListItem": "Название и многострочное текстовое описание",
		"ToggleableListItem": "Интерактивное название, переходящее при щелчке в более длинное текстовое содержание",
		"OrderedListItem": "Название и многострочное текстовое содержание с аннотацией или нумерацией шагов",
		"UnorderedListItem": "Список, маркированный жирными точками, с названием и описанием",
		"CheckableListItem": "Название и контрольный список. Состояние может сохраняться при смене пользователей приложения",
		"ButtonListItem": "Название и кнопка",
		"ImageListItem": "Единичное изображение, состыкованное",
		"HeaderListItem": "Большое изображение с наложенным названием и описанием",
		"VideoListItem": "Видеообъект, поддерживающий несколько источников",
		"AnimatedImageListItem": "Серия изображений, проигрываемых в слайдшоу, дающих мультипликационный фильм",
		"SpotlightImageListItem": "Одно или более «прожекторное» (spotlight) изображение, содержащее изображение, захват и ссылку.",
		"LogoListItem": "Используется для отображения рекламы компании",
		"SliderQuizItem": "Вопрос для выбора слайдера изображений",
		"TextQuizItem": "Вопрос для выбора текста",
		"ImageQuizItem": "Вопрос для выбора изображения",
		"AreaQuizItem": "Вопрос для выбора области",
		"CollectionListItem": "Функция «Выбрать все» для приложений, символов и ссылок",
		"multiLineObjects": "Многострочные объекты",
		"title": "Объекты",
		"spotlight": "Прожектор",
		"mediaObjects": "Объекты мультимедиа",
		"subText": "Подтекст",
		"filter": "Фильтровать объекты",
		"basicObjects": "Основные объекты",
		"text": "Текст",
		"interactiveObjects": "Интерактивные объекты",
		"leadInText": "Вводный текст",
		"hiddenText": "Скрытый текст",
		"dragAndDrop": "Перетащите объекты, чтобы добавить их на страницу"
	},
	"users": {
		"removeUserConfirm": "Вы уверены, что хотите удалить этого пользователя?",
		"failedToRemove": "Не удалось удалить пользователя",
		"firstName": "Имя",
		"title": "пользователей",
		"roles": "Роли",
		"organisation": "организация",
		"name": "имя",
		"list": "Список пользователей",
		"error": "Не удалось создать пользователя. Проверьте, что имя пользователя и адрес электронной почты еще не зарегистрированы.",
		"apps": "Программы",
		"lastActive": "Последнее посещение",
		"createUser": "Создать пользователя",
		"role": "Роль",
		"complete": "Пользователь успешно создан",
		"success": "Успех! Новый пользователь получит электронное письмо с инструкциями по активации своей учетной записи.",
		"lastName": "Фамилия",
		"usernameHelp": "Имена пользователей могут содержать только буквы, цифры, символы подчеркивания, тире и точки."
	},
	"userApps": {
		"title": "Приложения пользователя"
	},
	"push": {
		"messageDescription": "Извещающие уведомления",
		"categories": {
			"region": "Регион",
			"standard": "стандарт",
			"future": "Будущее",
			"custom": "изготовленный на заказ"
		},
		"custom": {
			"area": "Целевая область",
			"areaHelp": "Щелкните по карте, чтобы определить целевую область для этого уведомления"
		},
		"error": {
			"length": "Извещающее уведомление слишком длинное",
			"messageEmpty": "Ведите сообщение",
			"missingRegion": "Пожалуйста, введите регион"
		},
		"what": "Что вы желаете послать?",
		"when": "Когда вы желаете послать?",
		"addToQueue": "Добавить в очередь",
		"types": {
			"default": "По умолчанию"
		},
		"priorities": {
			"low": "Низкий",
			"high": "Высокая"
		},
		"notes": "Заметки",
		"approve": "Одобрить",
		"numberSent": "Всего",
		"numberCompleted": "Завершенный",
		"message": "Сообщение",
		"category": "категория",
		"sending": "Отправка",
		"noNotifications": "Нет уведомлений",
		"priority": "приоритет",
		"noPageSpecified": "Нет указанной страницы.",
		"editPush": "Изменить push-уведомление",
		"pushDetails": "Сведения об уведомлении",
		"sent": "Push sent",
		"existing": "Существующие push-уведомления",
		"createdBy": "Сделано",
		"reject": "отклонять",
		"assignments": "Назначения",
		"payload": "полезная нагрузка",
		"showAllApps": "Показать все приложения",
		"saveAndSend": "Сохранить и отправить",
		"type": "Тип"
	},
	"hazards": {
		"title": "Опасности",
		"disasters": {
			"categories": {
				"hurricane": "Ураган",
				"tornado": "Торнадо",
				"storm": "Буря",
				"earthquake": "землетрясение",
				"tsunami": "Цунами",
				"wind": "ветер",
				"winter": "зима",
				"flood": "потоп",
				"volcano": "Вулкан",
				"coastal": "береговой",
				"heatwave": "Heatwave",
				"wildfire": "Wildfire",
				"nuclear": "ядерной"
			},
			"tutorial": {
				"heading": "Что означают разные состояния?",
				"body": "- Включено означает, что страница появится в редакторе содержимого, чтобы вы начали работать над ней. <br> - Dev означает, что страница будет отображаться в сборках разработки для этого приложения. <br> - Live означает, что страница будет отображаться в сборках магазинов приложений для этого приложения."
			},
			"disable": "запрещать",
			"enable": "включить",
			"alertSettings": "Настройки уведомлений",
			"configure": "конфигурировать",
			"title": "Бедствия",
			"rootPage": "Корневая страница",
			"saveSuccess": "Конфигурация приложения сохранена.",
			"whatNow": "«Что сейчас»",
			"saveError": "Не удалось сохранить конфигурацию приложения.",
			"pleaseSpecifyPages": "Укажите страницу для каждого выбранного типа бедствия."
		},
		"alerts": {
			"times": {
				"day": "День",
				"allTime": "Все время",
				"week": "Неделю",
				"hour": "Час",
				"month": "Месяц"
			},
			"custom": {
				"types": {
					"announcement": "Объявление",
					"pin": "Прикрепленная страница",
					"pinDesc": "Прикрепленные страницы используются для отображения важных страниц, чувствительных к времени и местоположению.",
					"announcementDesc": "Объявления используются для создания предупреждений о событиях, которые не предоставляются другими поставщиками предупреждений."
				},
				"errors": {
					"pleaseProvideEvent": "Пожалуйста, предоставьте событие для этого объявления.",
					"pleaseProvideAreaDescription": "Пожалуйста, предоставьте описание области для этого объявления.",
					"pleaseProvideMessage": "Просьба представить сообщение для этого объявления.",
					"pleaseSelectRegion": "Выберите регион для этого предупреждения.",
					"pleaseProvideHeadline": "Укажите заголовок этого объявления.",
					"effectiveWrong": "Дата вступления в силу не может быть по истечении срока действия",
					"pleaseSelectPage": "Пожалуйста, выберите страницу для закрепления."
				},
				"effective": "Дата вступления в силу",
				"expiry": "Срок годности",
				"area": "Область предупреждения",
				"onset": "Дата начала",
				"pinPage": "Страница, которая будет показана.",
				"message": "Сообщение, отправленное с этим предупреждением.",
				"expiryHelp": "Дата, когда это предупреждение будет удалено.",
				"effectiveHelp": "Дата, с которой это предупреждение будет действовать.",
				"announcementPage": "Страница отображается в разделе «что сейчас».",
				"areaHelp": "Предупреждение будет отправлено всем пользователям, отслеживающим местоположение в пределах выбранной области.",
				"notify": "Поставить в известность",
				"severity": "Строгость",
				"cap_create": "КЕПКА",
				"pushes": "Толчки",
				"updateMap": "Обновить карту",
				"showPolyData": "Показать данные полигона",
				"cap_send": "Создать оповещение CAP",
				"severityHelp": "Насколько важно это предупреждение?",
				"languageHelp": "Язык оповещения написан.",
				"onsetHelp": "Дата возникновения опасности.",
				"alertQueued": "Оповещение отправлено в очередь для отправки.",
				"drawRegion": "Нарисуйте области, на которые должно быть отправлено это предупреждение",
				"polygon": "Добавьте сюда данные пользовательского полигона.",
				"sendNotification": "Отправьте push-уведомление этим пользователям."
			},
			"alert": "бдительный",
			"inTheLast": "Напоследок",
			"issuedBy": "выдан",
			"expired": "Истекший",
			"show": "Показать",
			"pinned": "Закрепленные",
			"pin": "Штырь",
			"effective": "эффективный",
			"allAlerts": "Все оповещения",
			"noAlerts": "Нет предупреждений",
			"testAlert": "Создать тестовое оповещение",
			"createNewAlert": "Создать новое оповещение",
			"alertActive": "Предупреждение активно",
			"specifySinglePolygon": "Предупреждения не поддерживаются несколькими полигонами. Нарисуйте один полигон.",
			"testAlertDesc": "Это тестовое оповещение будет отправлено всем пользователям, отслеживающим местоположение в Антарктиде.",
			"title": "Оповещения"
		},
		"generalNotifications": {
			"customMapData": {
				"moreCoords": "Пожалуйста, предоставьте более трех координат",
				"firstAndLast": "Первая и последняя координаты должны быть одинаковыми",
				"fisrtFoundNotLast": "Первая координата, найденная в координатах, только первая и последняя координаты должны быть одинаковыми",
				"placeholder": "Нажмите на карту выше или добавьте минимум три координаты здесь, чтобы создать многоугольник. т.е. [долгота, широта], [долгота, широта], [долгота, широта]",
				"notFound": "Нет данных Polygon, пожалуйста, добавьте минимум три координаты. т.е. [долгота, широта], [долгота, широта], [долгота, широта]"
			},
			"dropdown": {
				"alert": "бдительный",
				"system": "система",
				"test": "Контрольная работа",
				"security": "Безопасность",
				"fire": "Огонь",
				"likely": "Вероятно",
				"unknown": "неизвестный",
				"update": "Обновить",
				"private": "Частный",
				"error": "ошибка",
				"draft": "Проект",
				"cancel": "Отмена",
				"safety": "безопасности",
				"transport": "Транспорт",
				"minor": "Незначительный",
				"immediate": "немедленный",
				"health": "Здоровье",
				"actual": "фактический",
				"past": "прошлое",
				"rescue": "спасание",
				"restricted": "ограниченный",
				"extreme": "крайность",
				"public": "общественного",
				"unlikely": "Вряд ли",
				"expected": "ожидаемый",
				"observed": "Наблюдаемый",
				"other": "Другие",
				"future": "Будущее",
				"possible": "Возможное",
				"severe": "Серьезный",
				"moderate": "умеренный"
			},
			"type": "Тип",
			"event": "Мероприятие",
			"category": "категория",
			"severity": "Строгость",
			"status": "Положение дел",
			"urgency": "Острая необходимость",
			"scope": "Объем",
			"headline": "Заголовок",
			"certainty": "уверенность",
			"createNotification": "Создать уведомление",
			"area_description": "Описание района",
			"title": "Общие уведомления"
		},
		"alertZones": {
			"type": "Тип",
			"area": "Площадь",
			"name": "имя",
			"events": "Мероприятия",
			"blacklist": "Черный список",
			"whitelist": "Whitelist",
			"addAlertZone": "Добавить зону оповещения",
			"editAlertZone": "Изменить зону предупреждения",
			"areaSelectModalTitle": "Нарисуйте области, которые эта зона применяется для",
			"title": "Зоны предупреждения"
		},
		"feeds": {
			"tutorial": {
				"heading": "Использование каналов",
				"steps": "<ol><li> Убедитесь, что выбранные типы и категории бедствий включены для этого приложения. Это определит, какие каналы и события могут быть включены ниже. </li><li> Выберите каналы и типы событий, которые вы хотите внести в оповещения для этого приложения. </li></ol>",
				"body": "Некоторые типы предупреждений могут поступать из нескольких источников предупреждений. В этом разделе вы можете включить или отключить определенные каналы и типы событий из отдельных каналов для этого приложения."
			},
			"delete": {
				"yes": "Да, удалите его",
				"confirm": "Ты уверен?",
				"events": "Все включенные события будут отключены для этого фида."
			},
			"code": "Код",
			"enable": "включить",
			"feed": "Корм",
			"scope": "Объем",
			"disable": "запрещать",
			"events": "Мероприятия",
			"title": "Ленты",
			"languages": "Доступные языки",
			"type": "Тип",
			"configure": "конфигурировать"
		}
	},
	"appCreate": {
		"createNewOrganization": "Создать новую организацию",
		"details": "Сведения о приложении",
		"title": "Создать новое приложение",
		"copyFrom": "Скопировать содержимое из",
		"emptyApp": "Пустое приложение"
	},
	"manageApps": {
		"title": "Управлять приложениями",
		"yourNextApp": "Ваше новое приложение",
		"easilySetUpYourNextApp": "Легко настроить ваше следующее приложение"
	},
	"dashboard": {
		"title": "Рабочий кабинет"
	},
	"manage": {
		"title": "Управление"
	},
	"nav": {
		"items": {
			"appSetup": {
				"title": "Настройка приложения",
				"description": "Настройте имя приложения, значок, локализацию текста и информацию о магазине приложений."
			},
			"content": {
				"description": "Изменить контент в приложении",
				"title": "содержание"
			},
			"publish": {
				"description": "Публикация изменений в dev или live build",
				"title": "Публиковать"
			},
			"stats": {
				"description": "Посмотрите, как приложение работает",
				"title": "Статистика"
			},
			"userAgreement": {
				"title": "Пользовательское Соглашение",
				"description": "Просмотр и подписание Соглашения об универсальной программе приложений"
			},
			"users": {
				"title": "пользователей",
				"description": "Управление всеми пользователями"
			},
			"stories": {
				"title": "Истории",
				"description": "Управление историями"
			},
			"levels": {
				"title": "Уровни",
				"description": "Управление уровнями прогресса приложений"
			},
			"badges": {
				"title": "Значки",
				"description": "Управляйте значками, которые вы получили за выполнение действий в приложении."
			},
			"push": {
				"description": "Управление и отправка push-уведомлений пользователям",
				"title": "Всплывающее уведомление"
			},
			"sprint": {
				"title": "Статусная доска",
				"description": "Просмотр текущего состояния спринта развития"
			},
			"bugs": {
				"title": "Отчеты об ошибках",
				"description": "Сообщить о проблемах с редактором или сборкой приложения"
			},
			"gdpcConsole": {
				"description": "Просмотр статусов Национального общества и Dev Pool",
				"title": "Консоль NS"
			},
			"hazards": {
				"title": "Катастрофы и предупреждения",
				"description": "Приоритеты, борьба со стихийными бедствиями, предупреждения и умеренность"
			},
			"decisionTree": {
				"title": "Древо решений",
				"description": "Просмотр и редактирование дерева решений для приложения"
			},
			"admin": {
				"title": "Администратор",
				"description": "Просмотр статистики, редактирование пользователей и сведений об организации"
			}
		},
		"logout": "Выйти",
		"navigate": "проводить"
	},
	"publish": {
		"time": "Время",
		"publishLandmark": "Ознакомительная публикация?",
		"auditTrail": "Аудиторская тропа",
		"publishToLive": "Опубликовать жить",
		"test": "Контрольная работа",
		"fail": "Опубликовать не удалось.",
		"publishType": "Тип публикации",
		"lastModified": "Последнее изменение",
		"noChanges": "Без изменений",
		"details": "Детали",
		"detail": "подробность",
		"publishing": "Издательский...",
		"selectAll": "Выбрать все",
		"title": "Публиковать",
		"user": "пользователь",
		"comment": "Комментарий",
		"startTime": "Время начала",
		"objectType": "Тип объекта",
		"success": "Опубликовать успешно.",
		"endTime": "Время окончания",
		"publishToDev": "Опубликовать в dev",
		"toApps": "Опубликовать в приложениях",
		"error": "Выберите одну или несколько страниц для публикации.",
		"progress1": "Выбранные вами изменения теперь публикуются.",
		"pleaseConfirm": "Установите флажок, чтобы подтвердить, что вы просмотрели все изменения.",
		"publishConfirm": "Я подтверждаю, что просмотрел все выбранные изменения и получил разрешение на то, чтобы их",
		"progress3": "Похоже, это заняло некоторое время. Вы можете вернуться позже.",
		"progress2": "Это может занять некоторое время. Не удаляйтесь от этой страницы.",
		"warning2": "Пожалуйста, тщательно просмотрите все изменения, прежде чем продолжить и подтвердите свой выбор в нижней части страницы.",
		"live": "Жить",
		"history": "история",
		"warning1": "Вы выбрали следующие страницы, которые будут опубликованы в приложениях iOS и Android через дельта-обновление."
	},
	"publishHistory": {
		"title": "Опубликовать историю",
		"previousPublishes": "Предыдущие публикации"
	},
	"agreement": {
		"details": "Детали соглашения",
		"date": "Дата",
		"status": "Положение дел",
		"daysRemaining": "__count__ день, оставшийся",
		"signAgreement": "Подписать соглашение",
		"notSigned": "Не подписано",
		"nationalSociety": "Национальное общество",
		"viewThe": "Просмотр",
		"title": "Соглашение об универсальном приложении",
		"iAgree": "Я согласен с условиями, изложенными в",
		"hazardsTitle": "Соглашение о применении универсальной опасности",
		"signedBy": "Подписано",
		"signed": "подписанный",
		"sign": "Знак",
		"checkToAgree": "Установите флажок, чтобы подтвердить, что вы прочитали и согласны с условиями.",
		"daysRemaining_plural": "Осталось __count__ дней",
		"email": "Адрес электронной почты",
		"fullName": "Полное имя"
	},
	"roles": {
		"permissions": "права доступа",
		"title": "Роли пользователей",
		"list": "Список ролей"
	},
	"permissions": {
		"none": "Никто",
		"read": "Читать",
		"delete": "Написать / Удалить",
		"title": "Разрешения ролей",
		"write": "Написать",
		"edit": "Изменить разрешения"
	},
	"apps": {
		"title": "Назначить приложения",
		"app": "Приложение",
		"assignNew": "Назначить новое приложение",
		"assign": "приписывать",
		"subtitle": "Назначение приложений пользователю"
	},
	"stories": {
		"created": "созданный",
		"content": "содержание",
		"subtitle": "подзаголовок",
		"title": "Истории",
		"editStory": "Редактировать историю",
		"list": "Список историй"
	},
	"badges": {
		"editBadge": "Изменить значок",
		"list": "Список бейджей",
		"title": "Значки"
	},
	"crossBorders": {
		"link": {
			"destination": "Место назначения",
			"title": "заглавие",
			"placeholder": "Название пункта назначения"
		},
		"contact": {
			"editItem": "Изменить контакт",
			"title": "заглавие",
			"number": "Номер телефона"
		},
		"phrase": {
			"phrase": "Фраза",
			"translation": "Перевод",
			"editItem": "Изменить фразу"
		},
		"country": {
			"name": "Имя страны",
			"code": "Код страны",
			"description": "Страна назначения"
		},
		"links": "связи",
		"contacts": "контакты",
		"title": "Перекрестные границы",
		"list": "Список направлений",
		"editItem": "Изменить страну назначения",
		"translations": "Фразовые переводы",
		"phrases": "фразы"
	},
	"levels": {
		"title": "Уровни",
		"children": "Уровень детей",
		"editLevel": "Уровень редактирования",
		"list": "Список уровней",
		"details": "Детали уровня"
	},
	"bugs": {
		"status": {
			"fixed": "Исправлена",
			"title": "Положение дел",
			"byDesign": "По дизайну",
			"closed": "Закрыто",
			"open": "открыто",
			"postponed": "Отложено",
			"duplicate": "дублировать",
			"notReproducible": "Не воспроизводится",
			"deleted": "удаленный",
			"wontFix": "Не исправить"
		},
		"bugReport": "Отчет об ошибке",
		"noFiles": "Файлов нет",
		"reportBug": "Сообщить об ошибке",
		"addComment": "Добавить комментарий",
		"steps": "Действия по воспроизведению",
		"report": "отчет",
		"noComments": "Без комментариев",
		"comments": "Комментарии",
		"closeIssue": "Закрыть вопрос",
		"platformDetails": "Информация о платформе",
		"reopenIssue": "Повторно открыть вопрос",
		"listPending": "Список ожидающих отчетов об ошибках",
		"version": "Версия",
		"screenshots": "Скриншоты",
		"build": "строить",
		"title": "ошибки",
		"actually": "Фактическое поведение",
		"noReports": "Нет отчетов",
		"issueDetails": "Сведения о выпуске",
		"expected": "Ожидаемое поведение",
		"loadingFiles": "Загрузка файлов ...",
		"device": "Версия устройства / ОС",
		"loadingDetails": "Загрузка информации об ошибке ...",
		"listOpen": "Список открытых отчетов об ошибках",
		"listClosed": "Список закрытых отчетов об ошибках"
	},
	"gdpcConsole": {
		"nationalSocieties": {
			"countries": "страны",
			"averageRating": "Средний рейтинг",
			"contact": "контакт",
			"pool": "Бассейн",
			"locale": "место действия",
			"title": "обзор",
			"stage": "стадия",
			"edit": "Изменить общество",
			"goLive": "Перейти в Live",
			"details": "Сведения об обществе",
			"totalDownloads": "Всего загрузок",
			"downloads": "Загрузки"
		},
		"devPools": {
			"language": "язык",
			"responsibility": "Обязанность",
			"completed": "Завершенный",
			"stages": "Этапы",
			"requiredBy": "Требуется",
			"editPool": "Изменить dev pool",
			"title": "Состояние Dev Pool",
			"estimatedDate": "Текущая расчетная дата приложения",
			"noApps": "В этом пуле разработчиков нет приложений",
			"expected": "ожидаемый"
		}
	},
	"organization": {
		"details": "Сведения об организации",
		"noLanguages": "Языки не назначены",
		"title": "организация",
		"confirmLanguageAssignment": "Вы действительно хотите присвоить этот язык? Невозможно удалить языковые задания."
	},
	"mailingList": {
		"dateTo": "к",
		"export": "экспорт",
		"dateFrom": "Из",
		"list": "Доступные списки",
		"title": "Списки рассылки"
	},
	"moderation": {
		"image": {
			"title": "Модерация изображений",
			"location": "Место нахождения"
		},
		"title": "На модерации"
	},
	"posts": {
		"title": "Сообщений"
	},
	"achievements": {
		"completion": "завершение",
		"badge": "знак",
		"name": "имя",
		"image": "Образ",
		"title": "достижения",
		"edit": "Редактировать достижения"
	},
	"triggers": {
		"subclasses": {
			"RaisedTrigger": {
				"amount": "Количество",
				"singular": "Сумма поднята"
			}
		},
		"plural": "Триггеры"
	},
	"mind-moderation": {
		"emoodji": {
			"singular": "Emoodji"
		},
		"title": "Модерация Эмуджи",
		"empty": "Нет Эмуджиса умеренно"
	},
	"whatnow": {
		"attribution": {
			"attribution": "приписывание",
			"socName": "Название общества атрибуции",
			"image": "Изображение атрибуции",
			"message": "Сообщение об атрибуции",
			"url": "URL-адрес атрибуции",
			"imageHelp": "Загрузка изображения / значка.",
			"messageHelp": "Сообщение, которое сопровождается атрибуцией.",
			"urlHelp": "URL, который ссылается на дополнительную информацию об Attributor.",
			"socNameHelp": "Название Общества, которое должно быть приписано завещанию."
		},
		"edit": {
			"moreinfoHelp": {
				"placeholder": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod time incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud, осуществляющий работу, выполняемый в соответствии с потребностями."
			},
			"forecast": "Прогноз",
			"event": "опасность",
			"moreinfo": "Информационный URL",
			"editWhatnow": "Редактировать, что сейчас",
			"warningHelp": "Подготовьтесь ответить",
			"warning": "Предупреждение",
			"before": "До",
			"immediate": "немедленный",
			"immediateHelp": "Отвечать",
			"title": "заглавие",
			"description": "Описание",
			"languages": "Языки",
			"watchHelp": "Подготовьтесь ответить",
			"after": "После",
			"translations": "Переводы",
			"recoverHelp": "После",
			"watch": "Смотреть",
			"during": "В течение",
			"descriptionHelp": "Описание того, что сейчас.",
			"eventhelp": "Тип события для What Now.",
			"new": "Добавить новый язык",
			"midterm": "Среднесрочный прогноз",
			"selectLang": "Выберите язык",
			"titleHelp": "Название для того, что сейчас.",
			"addALang": "Добавить язык для начала",
			"recover": "оправляться",
			"forecastHelp": "Оценка и планирование (краткосрочные действия)",
			"beforeHelp": "Как подготовиться до события.",
			"midtermHelp": "Сокращение рисков (более длительные действия)",
			"duringHelp": "Что делать, пока событие происходит.",
			"moreinfohelp": "URL для получения дополнительной информации о том, что сейчас.",
			"empty": "Добавьте язык для создания What Now",
			"missing": "Отсутствует контент",
			"afterHelp": "Что делать после события."
		},
		"title": "заглавие",
		"whatnow": "Что теперь?",
		"description": "Описание",
		"after": "После",
		"during": "В течение",
		"before": "До",
		"eventType": "опасность",
		"content-not-set": "Содержимое не установлено",
		"selectSoc": "Выберите общество",
		"empty": "Нет никаких текущих записей, нажмите «Добавить», чтобы создать"
	},
	"arpro": {
		"codeGroup": {
			"description": "Описание",
			"name": "имя",
			"codeGroup": "Группы кодов",
			"addCodes": "Введите код здесь",
			"editItem": "Изменить группу кода",
			"codes": "коды"
		},
		"colours": {
			"description": "Описание",
			"name": "имя",
			"colours": "Цвета"
		},
		"search": {
			"burningRate": "Скорость горения",
			"compressionStrength50": "Прочность при сжатии 50",
			"compressionStrength25": "Сила сжатия 25",
			"tensileElongation": "Удлинение при растяжении",
			"equivalentYoungsModulus": "Эквивалентный модуль Юнга",
			"search": "Поиск продукта",
			"mouldedDensity": "Формованная плотность",
			"compressionStrength75": "Прочность при сжатии 75",
			"resilience75DynamicCompression": "Устойчивость 75 Динамическое сжатие",
			"tensileStrength": "Предел прочности"
		},
		"product": {
			"properties": {
				"packaging": "упаковка",
				"food": "Утверждено питание",
				"size": "Размер (мм)",
				"weight": "Вес (мг)",
				"bulk": "Массовая плотность (г / л)",
				"moulded": "Формованная плотность (г / л)",
				"bulkLabel": "насыпной",
				"bag": "Мешок"
			},
			"name": "имя",
			"grade": "класс",
			"access": "Коды доступа",
			"colour": "Цвет",
			"product": "Продукты",
			"notes": "Заметки",
			"specifications": "Характеристики",
			"physicalProps": "Физические свойства",
			"noneLinked": "Нет кодов доступа",
			"linked": "Имеет коды доступа",
			"rawData": "Данные Raw JSON",
			"rawDataError": "Исходные данные JSON - Недействительные данные JSON",
			"moulded": "Формованный диапазон плотности (г / л)",
			"editItem": "Изменить продукт"
		},
		"arpro": "ARPRO",
		"noitems": "Нет элементов для отображения, нажмите добавить выше."
	},
	"r4b": {
		"level": {
			"blue": "синий",
			"alpha": "Альфа",
			"colour": "Цвет",
			"title": "уровень",
			"red": "красный",
			"levels": "Уровни",
			"green": "зеленый",
			"selectBadge": "Пожалуйста, выберите значок"
		},
		"modules": {
			"blockContent": {
				"logic": {
					"title": "Логика видимости раздела"
				},
				"slider": {
					"interval": "интервал",
					"endLabel": "Конец этикетки",
					"startLabel": "Начать этикетку"
				},
				"question": {
					"textHelp": "Введите вопрос",
					"title": "Вопрос и ответ",
					"addResponse": "Добавить ответ",
					"answerHelp": "Введите ответ",
					"selectionHelp": "Выберите раздел, чтобы перейти к",
					"responseHelp": "Введите ответ",
					"addAnotherResponse": "Добавить другой ответ"
				},
				"quote": {
					"title": "котировка",
					"citation": "Введите цитату здесь"
				},
				"checkbox": {
					"title": "Флажки"
				},
				"textInput": {
					"help": "Введите метку ввода здесь",
					"multi": "Многострочный текст?",
					"title": "Ввод текста"
				},
				"link": {
					"title": "связи"
				},
				"help": {
					"title": "Объект справки"
				},
				"task": {
					"title": "Объект задачи",
					"help": "Введите задачу"
				},
				"text": {
					"title": "Текстовый объект"
				},
				"radio": {
					"title": "Радио-кнопки"
				},
				"break": "Разрыв страницы",
				"section": "Раздел",
				"clickToEdit": "Нажмите, чтобы изменить",
				"identifier": "Идентификатор",
				"enterText": "Введите текст здесь",
				"enterTitle": "Введите заголовок",
				"inputLabel": "Метка ввода текста",
				"addIdentifier": "Добавить идентификатор",
				"videoLink": "Ссылка на внешнее видео",
				"addSection": "Перетащите элемент здесь, чтобы создать новый раздел"
			},
			"section": {
				"title": "РАЗДЕЛ"
			},
			"blockTypes": {
				"radio": "РАДИО",
				"slider": "SLIDER",
				"task": "ЗАДАЧА",
				"video": "ВИДЕО",
				"image": "ОБРАЗ",
				"link": "ССЫЛКА",
				"text": "ТЕКСТ",
				"help": "ПОМОГИТЕ",
				"check": "ПРОВЕРИТЬ",
				"quote": "QUOTE",
				"qna": "Вопрос &",
				"media": "МЕДИА-БЛОКИ",
				"textInput": "ВХОД ТЕКСТА",
				"userInput": "ВХОДНЫЕ БЛОКИ ПОЛЬЗОВАТЕЛЯ"
			},
			"people": "люди",
			"version": "Версия",
			"levels": "Уровни",
			"visibility": "видимость",
			"mins": "мин",
			"person": "человек",
			"level": "уровень",
			"saved": "Все изменения сохранены",
			"unsaved": "Есть несохраненные изменения",
			"title": "Модули",
			"module": "модуль"
		},
		"assessments": {
			"assessment": "оценка",
			"pre": "Предварительная оценка"
		},
		"title": "АТЛАС",
		"people": "ЛЮДИ",
		"contentBlocks": "Блоки контента",
		"admin": "Панель управления администратора",
		"languages": "Языки",
		"questions": "Вопросов",
		"inputBlocks": "ВХОДНЫЕ БЛОКИ ПОЛЬЗОВАТЕЛЯ",
		"mediaBlocks": "МЕДИА-БЛОКИ",
		"person": "ЧЕЛОВЕК",
		"minutes": "МИН",
		"sections": "РАЗДЕЛЫ",
		"languageVars": "Языковые вариации",
		"empty": "Здесь нет модулей или оценок, добавьте их на этот уровень, чтобы начать."
	},
	"rspb": {
		"factFile": {
			"tags": "Теги",
			"funFacts": "Забавные факты",
			"description": "Описание",
			"scientificName": "Научное название",
			"start": "Начало",
			"name": "имя",
			"images": "Изображений",
			"show": "Показать",
			"commonName": "Распространенное имя",
			"seen": "Видели",
			"addImages": "Выберите изображение",
			"factFile": "Файлы фактов",
			"addFeatures": "Введите здесь функцию",
			"addActivities": "Введите здесь активность",
			"addFact": "Введите здесь",
			"editItem": "Редактировать файл фактов",
			"addTags": "Введите тег здесь",
			"features": "Особенности",
			"activities": "мероприятия"
		},
		"planItem": {
			"difficulty": {
				"medium": "средний",
				"hard": "Жесткий",
				"easy": "Легко",
				"difficulty": "Выберите трудность"
			},
			"garden": {
				"medium": "средний",
				"small": "Маленький",
				"large": "большой",
				"balconyroof": "Балкон / крыша",
				"public": "общественного",
				"selectType": "Выберите тип / сад"
			},
			"type": "Тип",
			"description": "Описание",
			"from": "Доступна с",
			"mostImportant": "Самое важное",
			"image": "Образ",
			"months": "Месяцы",
			"tags": "Теги",
			"leastImportant": "Наименее важный",
			"solo": "Для семьи?",
			"source": "Источник",
			"to": "Дата окончания",
			"title": "заглавие",
			"region": "Область",
			"dates": "Даты",
			"indefinite": "Неопределенный?",
			"planItems": "Плановые объекты",
			"activityCode": "Код операции",
			"visible": "Видимый из",
			"weighting": "Уточнение элемента планирования",
			"identifier": "Идентификатор",
			"regionHelp": "Нажмите, чтобы выбрать регион для этого элемента плана",
			"pageHelp": "Это страница, на которую будет ссылаться ваш элемент плана.",
			"weightingHelp": "Приоритет того, где элемент появится в приложении",
			"duration": "Продолжительность (в секундах)"
		},
		"noitems": "Нет элементов для отображения, нажмите добавить выше.",
		"rspb": "RSPB"
	}
}