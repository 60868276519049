<div class="row">
	<div class="col-xs-12">
		<h3>{{t "appSetup.properties"}}</h3>
	</div>
</div>
	<div class="row">
		<div class="col-xs-12">
			<form>
				<table style="margin-bottom: 30px">
					<thead>
						<tr>
							<th>{{t "appSetup.type"}}</th>
							<th>{{t "appSetup.key"}}</th>
							<th>{{t "appSetup.value"}}</th>
							<th>{{t "common.tools"}}</th>
						</tr>
					</thead>
					<tbody>
						{{#each properties}}
							<tr>
								<td>{{idiom}}</td>
								<td>{{key}}</td>
								<td>{{value}}</td>
								<td class="tools">
									<i class="icon-trash delete-property-button edit-mode"
									   title="mediaLibrary.delete"
									   data-idiom="{{idiom}}"
									   data-key="{{key}}"></i>
								</td>
							</tr>
						{{/each}}
						<tr class="edit-mode">
							<td>
								<select id="idiom-selector">
									{{#each idioms}}
										<option value="{{this}}">
											{{this}}
										</option>
									{{/each}}
								</select>
							</td>
							<td>
								<input type="text"
									   class="form-control key-input"
									   placeholder="{{t "appSetup.key"}}">
							</td>
							<td>
								<input type="text"
									   class="form-control value-input"
									   placeholder="{{t "appSetup.value"}}">
							</td>
							<td>
								<button type="submit"
										class="button positive add-property-button"
										>{{t "common.add"}}</button>
							</td>
						</tr>
					</tbody>
				</table>
			</form>
		</div>
	</div>
