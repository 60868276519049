var AppProperties = require('./app-properties'),
	AppSetupSectionView = require('./app-setup-section-view')

module.exports = AppSetupSectionView.extend({
	className: 'app-properties',
	template: require('./properties-view-template'),

	events: {
		'click .add-property-button': 'addProperty',
		'click .delete-property-button': 'deleteProperty'
	},

	initialize: function(options) {
		this.appId = options.appId

		this.collection = new AppProperties(null, {appId: this.appId})
		this.collection.once('sync', this.ready, this)
		this.collection.fetch()
		this.collection.comparator = 'idiom'

		this.listenTo(this.collection, 'add remove', this.render, this)
	},

	getPageTitle: function() {
		return $.t('appSetup.properties')
	},

	getRenderData: function() {
		this.collection.sort()
		var idioms = [
			'ios',
			'android',
			'windows',
			'web',
			'general'
		]

		var collection = this.collection
		var data = {
			appId: this.appId,
			properties: collection.toJSON(),
			idioms: idioms
		}

		// data.idioms = idioms.map(function(idiom) {
		// 	var props = collection
		// 		.where({idiom: idiom})
		// 		.map(function(item) {
		// 			return item.toJSON()
		// 		})
		//
		// 	return {
		// 		idiom: idiom,
		// 		properties: props
		// 	}
		// })

		return data
	},

	afterRender: function() {
		if (!App.acl.hasWritePermission('App Setup', 'App properties')) {
			this.$('.edit-mode').hide()
		}
	},

	ready: function() {
		this.render()
		App.stopLoad()
	},

	addProperty: function() {
		var idiom = this.$('#idiom-selector').val(),
			key = this.$('.key-input').val(),
			value = this.$('.value-input').val()

		if (!key) {
			return false
		}

		var obj = {idiom: idiom, key: key, value: value},
			data = {properties: [obj]}

		this.collection.save(null, {
			data: JSON.stringify(data)
		})

		this.collection.push(obj)
		return false
	},

	deleteProperty: function(e) {
		var idiom = $(e.currentTarget).data('idiom'),
			key = $(e.currentTarget).data('key')

		var obj = this.collection.findWhere({idiom: idiom, key: key})

		this.collection.remove(obj)

		var data = {properties: [obj.toJSON()]}

		this.collection.save(null, {
			data: JSON.stringify(data),
			type: 'DELETE'
		})

		return false
	}
})
