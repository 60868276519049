var StandaloneStormObject = require('editor/standalone-storm-object'),
	EditorSectionView = require('editor/editor-section-view')
/**
 * Exports {@link ProductSearchEditView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends ProductSearchEditView.prototype */{
	/** @override */
	className: 'ProductSearchEditView',
	/** @override */
	template: require('./product-search-edit-view-template'),
	activeTabIndex: 4,

	/** @override */
	events: {
		'click .save': 'save',
		'click #addCode': 'addCode',
		'click .code-remove-button': 'removeCode'
	},

	/**
	 * @constructs ProductSearchEditView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)

		/** @private {App} */
		this.app_ = options.app

		this.model = new StandaloneStormObject()

		var modelPromise = Promise.resolve()

		if (options.id !== 'new') {
			this.model.set('id', options.id)
			this.model.once('sync', this.ready, this)

			modelPromise = this.model.fetch()
		} else {
			this.model = StandaloneStormObject.fromClassName('StormQLSearch')
		}

		Promise.all([modelPromise])
			.then(this.modelLocked.bind(this))
	},

	modelLocked: function() {
		App.stopLoad()
		if (this.model.get('pageId') > 0) {
			this.model.requestLock(function() {
				this.render()
			}.bind(this))
		} else {
			this.render()
		}
	},

	/** @override */
	getRenderData: function() {
		var data = this.model.toJSON()
		data.appId = this.app_.id
		return data
	},

	save: function() {
		App.startLoad()
		var self = this
		this.model.once('sync', function() {
			if (this.model.get('pageId') > 0) {
				this.model.requestUnlock()
			}
			App.router.navigate('/apps/' + self.app_.id + '/product-search', {trigger: true})
		}.bind(this))

		// this.model.set('burningRate', $('#burningRate-input').val())
		this.model.set('compressionStrength25', $('#compressionStrength25-input').val())
		this.model.set('compressionStrength50', $('#compressionStrength50-input').val())
		this.model.set('compressionStrength75', $('#compressionStrength75-input').val())
		this.model.set('equivalentYoungsModulus', $('#equivalentYoungsModulus-input').val())
		this.model.set('mouldedDensity', $('#mouldedDensity-input').val())
		this.model.set('resilience75DynamicCompression', $('#resilience75DynamicCompression-input').val())
		// this.model.set('tensileElongation', $('#tensileElongation-input').val())
		// this.model.set('tensileStrength', $('#tensileStrength-input').val())

		this.model.save(null, {appId: this.app_.id})
		return false
	}

	// Class structure:
	// "burningRate": "Burning Rate",- Hidden
	// "compressionStrength25": "Compression Strength 25",
	// "compressionStrength50": "Compression Strength 50",
	// "compressionStrength75": "Compression Strength 75",
	// "equivalentYoungsModulus": "Equivalent Youngs Modulus",
	// "mouldedDensity": "Moulded Density",
	// "resilience75DynamicCompression": "Resilience 75 Dynamic Compression", - Hidden
	// "tensileElongation": "Tensile Elongation", - Hidden
	// "tensileStrength": "Tensile Strength"
})
