var EditorSectionView = require('editor/editor-section-view'),
	ColourList = require('arpro/products/colour-list'),
	StandaloneStormObject = require('editor/standalone-storm-object'),
	isHexRegex = /^#(?:[A-Fa-f0-9]{3}){1,2}$/,
	isRgbaRegex = /^^rgba[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*,){3}\s*0*(?:\.\d+|1(?:\.0*)?)\s*[)]$/,
	isRgbRegex = /^rgb[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*(?:,(?![)])|(?=[)]))){3}[)]$/

/**
 * Exports {@link ArproColourView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends ArproColourView.prototype */ {
	/** @override */
	className: 'ArproColourView',
	/** @override */
	template: require('./product-colour-view-template'),
	events: {
		'change :input#colour-input': 'updateNewColourFill',
		'change :input#border-colour-input': 'updateNewBorderColour',
		'change :input.js-existing-fill-input': 'updateExistingColourFill',
		'change :input.js-existing-border-input': 'updateExistingBorderColour',
		'click .tab__cta': 'toggleVisibility',
		'click .save-btn': 'saveChanges',
		'click .js-add-btn': 'addNewColour'
	},

	/**
	 * @constructs ArproColourView
	 * @extends EditorSectionView
	 * @override
	 */

	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)

		/** @private {App} */
		this.app_ = options.app

		this.model = new StandaloneStormObject()

		this.model = StandaloneStormObject.fromClassName('StormQLProductColour')

		this.colourList = new ColourList({
			appId: this.app_.id
		})

		var colourListFetch = this.colourList.fetch()

		Promise.all([colourListFetch])
			.then(App.stopLoad)
			.then(this.render.bind(this))
			.then(function(t) {
				var el = document.getElementById('items')
				var sortable = Sortable.create(el, {
					draggable: ".tab",
					handle: ".tab__handle",
					ghostClass: "ghost",
					onUpdate: function(evt) {
						var promises = []
						var oldPosition = evt.oldIndex,
							newPosition = evt.newIndex

						if (oldPosition !== newPosition) {
							// var movedItemId = evt.item.dataset.colour
							var items = t.colourList.toJSON()
							App.startLoad()
							sortable.option("disabled", true)

							items.forEach(function(item) {
								var updateOrderModel = StandaloneStormObject.fromClassName('StormQLProductColour')
								updateOrderModel.set('id', item.id)

								var newOrderId = $('.tab-list li[data-colour=' + item.id + ']').index()
								promises.push(new Promise(function(resolve) {
									updateOrderModel.fetch().then(function() {
										updateOrderModel.set('order', newOrderId)
										updateOrderModel.requestLock(function() {
											updateOrderModel.save().then(function() {
												updateOrderModel.requestUnlock().then(resolve())
											})
										})
									})
								}))
							})
							Promise.all(promises).then(function() {
								App.stopLoad()
								sortable.option("disabled", false)
							})
						}
					}
				})
			})
	},

	/** @override */
	getRenderData: function() {
		var data = {}
		var orderedData = this.colourList.toJSON()
		data.colourList = _.sortBy(orderedData, 'order')
		data.appId = this.app_.id
		return data
	},

	updateNewColourFill: function(e) {
		$('.js-live-colour-preview').css("background-color", e.target.value)
	},

	updateNewBorderColour: function(e) {
		$('.js-live-colour-preview').css('border', '2px solid ' + e.target.value)
	},

	updateExistingColourFill: function(e) {
		$(e.currentTarget).next('.colour-preview').css("background-color", e.target.value)
	},

	updateExistingBorderColour: function(e) {
		$(e.currentTarget).next('.colour-preview').css('border', '2px solid ' + e.target.value)
	},

	toggleVisibility: function(e) {
		e.preventDefault()
		e.currentTarget.classList.toggle('is-disabled')
		var isVisible = null

		if (e.currentTarget.classList.contains('is-disabled')) {
			isVisible = false
		} else {
			isVisible = true
		}

		App.startLoad()
		var id = e.currentTarget.dataset.colour

		var visibleModel = StandaloneStormObject.fromClassName('StormQLProductColour')
		visibleModel.set('id', id)
		visibleModel.fetch().then(function() {
			visibleModel.set('visible', isVisible)
			visibleModel.requestLock(function() {
				visibleModel.save().then(function() {
					this.updateColourList()
					visibleModel.requestUnlock().then(App.stopLoad())
				}.bind(this))
			}.bind(this))
		}.bind(this))
	},

	addNewColour: function() {
		App.startLoad()
		this.model.once('sync', function() {
			App.stopLoad()
			this.updateColourList()
		}.bind(this))

		var colourFillValue = $('#colour-input').val()
		var borderColourValue = $('#border-colour-input').val()

		var fillColours = this.checkColourType(colourFillValue)
		var borderColours = this.checkColourType(borderColourValue)
		var orderNumber = this.colourList.length + 1

		this.model.set('name..content..en', $('#name-input').val())
		this.model.set('colour..r', fillColours.r)
		this.model.set('colour..g', fillColours.g)
		this.model.set('colour..b', fillColours.b)
		this.model.set('colour..a', fillColours.a)
		this.model.set('order', orderNumber)
		this.model.set('visible', true)

		if (borderColours) {
			this.model.set('border..r', borderColours.r)
			this.model.set('border..g', borderColours.g)
			this.model.set('border..b', borderColours.b)
			this.model.set('border..a', borderColours.a)
		}

		this.model.save(null, {
			appId: this.app_.id
		})
		return false
	},

	updateColourList: function() {
		this.colourList.fetch().then(this.render.bind(this))
	},

	saveChanges: function(e) {
		App.startLoad()
		var id = e.currentTarget.dataset.colour

		var colourModel = StandaloneStormObject.fromClassName('StormQLProductColour')
		colourModel.set('id', id)
		colourModel.fetch().then(function() {
			var nameInput = $('#' + e.currentTarget.dataset.colour + '-name-input').val()
			var updatedColourFill = $('#' + e.currentTarget.dataset.colour + '-colour-input').val()
			var updatedBorderColour = $('#' + e.currentTarget.dataset.colour + '-border-colour-input').val()
			var fillColours = this.checkColourType(updatedColourFill)
			var borderColours = this.checkColourType(updatedBorderColour)

			colourModel.set('colour..r', fillColours.r)
			colourModel.set('colour..g', fillColours.g)
			colourModel.set('colour..b', fillColours.b)
			colourModel.set('colour..a', fillColours.a)

			if (borderColours) {
				colourModel.set('border..r', borderColours.r)
				colourModel.set('border..g', borderColours.g)
				colourModel.set('border..b', borderColours.b)
				colourModel.set('border..a', borderColours.a)
			}
			colourModel.set('name..content..en', nameInput)
			colourModel.requestLock(function() {
				colourModel.save().then(function() {
					this.updateColourList()
					colourModel.requestUnlock().then(App.stopLoad())
				}.bind(this))
			}.bind(this))
		}.bind(this))
	},

	checkColourType: function(colourFillValue) {
		var isHex = isHexRegex.test(colourFillValue)
		var isRgba = isRgbaRegex.test(colourFillValue)
		var isRgb = isRgbRegex.test(colourFillValue)
		var colours = {
			r: null,
			g: null,
			b: null,
			a: null
		}

		if (isHex) {
			var hexColourString = colourFillValue.replace('#', ''),
				r = parseInt(hexColourString.substring(0, 2), 16),
				g = parseInt(hexColourString.substring(2, 4), 16),
				b = parseInt(hexColourString.substring(4, 6), 16),
				a = '1'

			colours = colours = {
				r: r,
				g: g,
				b: b,
				a: a
			}
		} else if (isRgba) {
			var rgbaColorString = colourFillValue,
				colorsOnly = rgbaColorString.substring(rgbaColorString.indexOf('(') + 1, rgbaColorString.lastIndexOf(')')).split(/,\s*/),
				red = colorsOnly[0],
				green = colorsOnly[1],
				blue = colorsOnly[2],
				opacity = colorsOnly[3]

			colours = colours = {
				r: red,
				g: green,
				b: blue,
				a: opacity
			}
		} else if (isRgb) {
			var rgbColorString = colourFillValue,
				numbersOnly = rgbColorString.substring(rgbColorString.indexOf('(') + 1, rgbColorString.lastIndexOf(')')).split(/,\s*/),
				rd = numbersOnly[0],
				grn = numbersOnly[1],
				bl = numbersOnly[2],
				alpha = '1'

			colours = colours = {
				r: rd,
				g: grn,
				b: bl,
				a: alpha
			}
		}
		return colours
	}
})
