var LinkImageItem = require('./linkable-image-item-view'),
	StormObject = require('editor/storm-object')

module.exports = Backbone.View.extend({
	template: require('./linkable-image-container-view-template'),

	events: {
		'click .js-add-button': 'addButtonClick'
	},

	initialize: function(options) {
		this.collection = options.collection
		this.pageId = options.pageId
		this.currentLang = options.language || Storm.view.language
		this.parentLock = options.parentLock

		this.listenTo(this.collection, 'add', this.addLink, this)
		this.listenTo(this.collection, 'reset', this.renderLinks, this)
	},

	afterRender: function() {
		this.renderLinks()
	},

	addLink: function(link) {
		var linkListItem = new LinkImageItem({model: link, language: this.currentLang, parentLock: this.parentLock})
		this.$('.js-image-list').append(linkListItem.render().el)
	},

	renderLinks: function() {
		// Re-render all links
		this.$('.js-image-list').empty()

		this.collection.each(function(link) {
			var linkListItem = new LinkImageItem({model: link, language: this.currentLang, parentLock: this.parentLock})
			this.$('.js-image-list').append(linkListItem.render().el)
		}, this)
	},

	addButtonClick: function() {
		// Create new link object
		var link = StormObject.fromClassName('LinkableImageItem', this.pageId)
		this.collection.add(link)
	}
})
