<div class="link-detail">
	{{#ifExists link.title}}
		<div class="form-group link-title-select">
			<label for="{{id}}-title" data-i18n="editor.inspector.properties.title.title"></label>
			<input type="text" class="form-control title" id="{{id}}-title" value="{{getLocaleText link.title}}">
		</div>
	{{/ifExists}}

	<div class="form-group">
		<label for="{{id}}-type" data-i18n="editor.inspector.properties.link.type"></label>
		<select id="{{id}}-type" class="link-type-selector form-control">
			{{#ifExistsInArray validLinks "InternalLink"}}<option value="InternalLink" data-i18n="common.linkTypes.internal"></option>{{/ifExistsInArray}}
			{{#ifExistsInArray validLinks "ExternalLink"}}<option value="ExternalLink" data-i18n="common.linkTypes.external"></option>{{/ifExistsInArray}}
			{{#ifExistsInArray validLinks "UriLink"}}<option value="UriLink" data-i18n="common.linkTypes.uri"></option>{{/ifExistsInArray}}
			{{#ifExistsInArray validLinks "NativeLink"}}<option value="NativeLink" data-i18n="common.linkTypes.native"></option>{{/ifExistsInArray}}
			{{#ifExistsInArray validLinks "AppLink"}}<option value="AppLink" data-i18n="common.linkTypes.appLink"></option>{{/ifExistsInArray}}
			{{#ifExistsInArray validLinks "EmergencyLink"}}<option value="EmergencyLink" data-i18n="common.linkTypes.emergency"></option>{{/ifExistsInArray}}
			{{#ifExistsInArray validLinks "UriLink"}}<option value="CallLink" data-i18n="common.linkTypes.call"></option>{{/ifExistsInArray}}
			{{#ifExistsInArray validLinks "SmsLink"}}<option value="SmsLink" data-i18n="common.linkTypes.sms"></option>{{/ifExistsInArray}}
			{{#ifExistsInArray validLinks "EmailLink"}}<option value="EmailLink" data-i18n="common.linkTypes.email"></option>{{/ifExistsInArray}}
			{{#ifExistsInArray validLinks "ShareLink"}}<option value="ShareLink" data-i18n="common.linkTypes.share"></option>{{/ifExistsInArray}}
			{{#ifExistsInArray validLinks "TimerLink"}}<option value="TimerLink" data-i18n="common.linkTypes.timer"></option>{{/ifExistsInArray}}
		</select>
	</div>

	{{#ifExists link.identifier}}
		<div class="form-group">
			<label for="{{id}}-ident" class="app-label" data-i18n="apps.app"></label>
			<select id="{{id}}-ident" class="link-app-selector form-control">
				<option>-</option>
			</select>
		</div>
	{{/ifExists}}

	{{#ifExists link.destination}}
		<div class="form-group">
			<label class="destination-label" data-i18n="editor.inspector.properties.link.destination"></label>
			<input type="url" class="form-control link-destination-input link-destination" value="{{link.destination}}"
                   placeholder="e.g. {{oneOf link.overridePlaceholder 'http://example.com'}}">
			<select class="link-destination-internal-selector link-destination form-control">
				<option>-</option>
			</select>
			<select class="link-destination-native-selector link-destination form-control">
				<option>-</option>
			</select>
		</div>
	{{/ifExists}}

	{{#ifExists link.body}}
		<div class="form-group">
			<label for="{{id}}-body" data-i18n="editor.inspector.properties.link.body"></label>
			<textarea id="{{id}}-body" class="form-control body">{{getLocaleText link.body}}</textarea>
		</div>
	{{/ifExists}}

	{{#ifExists link.duration}}
		<div class="form-group">
			<label for="{{id}}-duration" data-i18n="editor.inspector.properties.link.duration"></label>
			<input id="{{id}}-duration" type="number" class="form-control duration" value="{{secondsToMinutes link.duration}}">
		</div>
	{{/ifExists}}

	{{#ifExists link.recipients}}
		<div class="form-group">
			<label for="{{id}}-recipients" data-i18n="editor.inspector.properties.link.recipients"></label>
			<textarea id="{{id}}-recipients" class="form-control recipients">{{#each link.recipients}}{{this}}
{{/each}}</textarea>
		</div>
	{{/ifExists}}

	<div class="type-descriptions">
		<span data-type="InternalLink">{{t "common.linkDescriptions.internal"}}</span>
		<span data-type="ExternalLink">{{t "common.linkDescriptions.external"}}</span>
		<span data-type="UriLink">{{t "common.linkDescriptions.uri"}}</span>
		<span data-type="NativeLink">{{t "common.linkDescriptions.native"}}</span>
		<span data-type="AppLink">{{t "common.linkDescriptions.appLink"}}</span>
		<span data-type="EmergencyLink">{{t "common.linkDescriptions.emergency"}}</span>
		<span data-type="CallLink">{{t "common.linkDescriptions.call"}}</span>
		<span data-type="SmsLink">{{t "common.linkDescriptions.sms"}}</span>
		<span data-type="ShareLink">{{t "common.linkDescriptions.share"}}</span>
		<span data-type="TimerLink">{{t "common.linkDescriptions.timer"}}</span>
		<span data-type="EmailLink">{{t "common.linkDescriptions.email"}}</span>
	</div>

	{{#ifExists link.destination}}
		<button class="button link-test-button link-destination">{{t "editor.inspector.properties.link.testLink"}}</button>
	{{/ifExists}}
</div>
