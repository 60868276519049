<div class="grid-item-content editable">
	{{#ifExists image}}
		<div class="image">
			<img src="{{getPreviewUrl image}}">
		</div>
	{{/ifExists}}

	{{#ifExists title}}
		<div class="title inline-editable"
				 data-attribute="title"
				 data-placeholder="{{t "editor.inspector.properties.title.title"}}">{{getLocaleText title}}</div>
	{{/ifExists}}

	{{#ifExists description}}
		<div class="description inline-editable"
				 data-attribute="description"
				 data-placeholder="{{t "common.description"}}">{{getLocaleText description}}</div>
	{{/ifExists}}
</div>
