{{#key_value country.languages "key" "val"}}
	<div class="row">
		<div class="col-xs-12 title-header">
			<span>{{../name}}</span>
			<div class="dev-pool-tools">
				{{t "gdpcConsole.devPools.estimatedDate"}}<span class="no-bold"> - {{formatDate val.expected}}</span>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-xs-12">
			<table style="margin-bottom:40px">
				<thead>
					<tr>
						<th>{{t "gdpcConsole.nationalSocieties.stage"}}</th>
						<th>{{t "gdpcConsole.devPools.completed"}}</th>
						<th>{{t "gdpcConsole.devPools.requiredBy"}}</th>
						<th>{{t "gdpcConsole.devPools.responsibility"}}</th>
						<th>{{t "push.notes"}}</th>
					</tr>
				</thead>
				<tbody>
					{{#each val.stages}}
						<tr>
							<td class="center"><strong>{{stageName}}</strong></td>
							<td class="center">
								{{#if stageCompleted}}
									<div class="status-gem-container">
										<div class="status-gem positive">
											<div class="status-gem-inner"></div>
											<div class="status-gem-popover">
												<p><strong>{{t "gdpcConsole.devPools.completed"}}</strong></p>
												<p>{{formatDate stageCompleted}}</p>
											</div>
										</div>
									</div>
								{{else}}
									{{#ifDateIsInPast stageDue}}
										<div class="status-gem-container">
											<div class="status-gem negative"></div>
										</div>
									{{/ifDateIsInPast}}
								{{/if}}
								<td class="center">{{formatDate stageDue}}</td>
								<td class="center">{{responsibility}}</td>
								<td>{{notes}}</td>
							</td>
						</tr>
					{{/each}}
				</tbody>
			</table>
		</div>
	</div>
{{/key_value}}
