<form class="row">
    <div class="col-xs-12">
        <div class="row">
            <div class="col-sm-6">
                <h3>{{t 'achievements.edit'}}</h3>
            </div>
            <div class="col-sm-6 actions-col">
                <a href="javascript:history.back()"><button class="button red wide" type="button">{{t "common.cancel"}}</button></a>
                <button class="button green wide save" type="submit">{{t "common.save"}}</button>
            </div>
        </div>
    </div>

    <div class="col-sm-12">
        <div class="form-group">
            <label class="control-label">{{t "achievements.badge"}}</label>
        </div>
        <div class="form-group">
            <img src="{{getPreviewUrl badge.src.x2}}">
        </div>
        <div class="form-group">
            <button type="button" class="button choose-image-button" style="margin-bottom: 10px">{{t "editor.inspector.properties.image.chooseButton"}}</button>
        </div>
    </div>

    <div class="col-sm-6">
        <div class="form-group">
            <label class="control-label">{{t "editor.inspector.properties.title.title"}}</label>
        </div>
        <div class="form-group">
            {{#each languages}}
                <div class="input-group">
                    <span class="input-group-addon">{{code}}</span>
                    <input type="text" class="title-input form-control" data-code="{{code}}">
                </div>
            {{/each}}
        </div>
    </div>

    <div class="col-sm-6">
        <div class="form-group">
            <label class="control-label">{{t "common.description"}}</label>
        </div>
        <div class="form-group">
            {{#each languages}}
                <div class="input-group">
                    <span class="input-group-addon">{{code}}</span>
                    <input type="text" class="description-input form-control" data-code="{{code}}">
                </div>
            {{/each}}
        </div>
    </div>

    <div class="col-sm-6">
        <div class="form-group">
            <label class="control-label">{{t "achievements.completion"}}</label>
        </div>
        <div class="form-group">
            {{#each languages}}
                <div class="input-group">
                    <span class="input-group-addon">{{code}}</span>
                    <input type="text" class="completion-input form-control" data-code="{{code}}">
                </div>
            {{/each}}
        </div>
    </div>

    <div class="col-sm-12">
        <div class="form-group">
            <label class="control-label">{{t "triggers.plural"}}</label>
        </div>
        <div id="triggers-view" class="form-group"></div>
    </div>

    <div class="col-sm-6">
        <div class="form-group">
            <label class="control-label">{{t "editor.inspector.properties.shareMessage.title"}}</label>
        </div>
        <div class="form-group">
            {{#each languages}}
                <div class="input-group">
                    <span class="input-group-addon">{{code}}</span>
                    <input type="text" class="share-text-input form-control" data-code="{{code}}">
                </div>
            {{/each}}
        </div>
    </div>

    <div class="col-sm-6">
        <div class="form-group">
            <label class="control-label">{{t "editor.inspector.properties.link.linkTitle"}}</label>
        </div>
        <div class="form-group">
            {{#each languages}}
                <div class="input-group">
                    <span class="input-group-addon">{{code}}</span>
                    <input type="text" class="share-link-title-input form-control" data-code="{{code}}">
                </div>
            {{/each}}
        </div>
    </div>

    <div class="col-sm-6">
        <div class="form-group">
            <label class="control-label">{{t "editor.inspector.properties.link.linkDestination"}}</label>
        </div>
        <div class="form-group">
            <input type="text" class="share-link-destination-input form-control" value="{{share.link.destination}}" />
        </div>
    </div>
</form>
