<h5 data-i18n="editor.inspector.properties.gridType"></h5>
<select class="input-block-level grid-type-select">
	<option value="StandardGridView" data-i18n="editor.inspector.properties.standard"></option>
	<option value="ImageGridView" data-i18n="mediaLibrary.image"></option>
	<option value="QuizGridView" data-i18n="editor.inspector.properties.quiz"></option>
</select>
<hr>

<h5 data-i18n="editor.inspector.properties.columns"></h5>
<input type="number" class="input-block-level grid-columns" value="{{columns}}" min="0">
<hr>
