var StormObject  = require('editor/storm-object'),
	ListItemView = require('./list-item-view'),
	ListView     = require('./list-view'),
	APICompat    = require('lib/api-compat')

var IMAGE_PREVIEW = [
	{
		dimensions: {},
		src: {
			class: 'ExternalLink',
			destination: '/images/storm.png'
		}
	}
]

var ICON_PREVIEW = [
	{
		dimensions: {},
		src: {
			class: 'ExternalLink',
			destination: '/images/3sc.png'
		}
	}
]

var PREVIEWS = {
	TextListItem: {
		description: getTextObjectWithLocalisationKey('editor.objectPreviews.TextListItem')
	},

	TitleListItem: {
		title: getTextObjectWithLocalisationKey('editor.objectPreviews.TitleListItem')
	},

	DescriptionListItem: {
		title: getTextObjectWithLocalisationKey('editor.objectPreviews.DescriptionListItem.title'),
		description: getTextObjectWithLocalisationKey('editor.objectPreviews.DescriptionListItem.description')
	},

	StandardListItem: {
		title: getTextObjectWithLocalisationKey('editor.objectPreviews.StandardListItem.title'),
		description: getTextObjectWithLocalisationKey('editor.objectPreviews.StandardListItem.description'),
		image: ICON_PREVIEW
	},

	ToggleableListItem: {
		title: getTextObjectWithLocalisationKey('editor.objectPreviews.ToggleableListItem.title'),
		description: getTextObjectWithLocalisationKey('editor.objectPreviews.ToggleableListItem.description')
	},

	OrderedListItem: {
		annotation: '1',
		title: getTextObjectWithLocalisationKey('editor.objectPreviews.OrderedListItem.title'),
		description: getTextObjectWithLocalisationKey('editor.objectPreviews.OrderedListItem.description')
	},

	UnorderedListItem: {
		title: getTextObjectWithLocalisationKey('editor.objectPreviews.UnorderedListItem.title'),
		description: getTextObjectWithLocalisationKey('editor.objectPreviews.UnorderedListItem.description')
	},

	CheckableListItem: {
		title: getTextObjectWithLocalisationKey('editor.objectPreviews.CheckableListItem')
	},

	ButtonListItem: {
		title: getTextObjectWithLocalisationKey('editor.objectPreviews.ButtonListItem'),
		button: {
			title: getTextObjectWithLocalisationKey('editor.inspector.properties.button')
		}
	},

	ImageListItem: {
		image: IMAGE_PREVIEW
	},

	VideoListItem: {
		image: IMAGE_PREVIEW
	},

	HeaderListItem: {
		title: getTextObjectWithLocalisationKey('editor.objectPreviews.HeaderListItem.title'),
		description: getTextObjectWithLocalisationKey('editor.objectPreviews.HeaderListItem.description'),
		image: IMAGE_PREVIEW
	},

	LogoListItem: {
		image: ICON_PREVIEW,
		link: {
			title: getTextObjectWithLocalisationKey('editor.objectPreviews.LogoListItem')
		}
	},

	SpotlightListItem: {
		spotlights: [
			{
				image: IMAGE_PREVIEW,
				text: getTextObjectWithLocalisationKey('editor.objectPreviews.SpotlightListItem.first'),
				delay: 1000
			},
			{
				image: IMAGE_PREVIEW,
				text: getTextObjectWithLocalisationKey('editor.objectPreviews.SpotlightListItem.second'),
				delay: 1000
			}
		]
	},

	LinkableImageItemContainer: {
		children: [
			{
				image: ICON_PREVIEW,
				link: {
					title: getTextObjectWithLocalisationKey('editor.objectPreviews.LinkableImageItemContainer.first')
				}
			},
			{
				image: ICON_PREVIEW,
				link: {
					title: getTextObjectWithLocalisationKey('editor.objectPreviews.LinkableImageItemContainer.second')
				}
			},
			{
				image: ICON_PREVIEW,
				link: {
					title: getTextObjectWithLocalisationKey('editor.objectPreviews.LinkableImageItemContainer.third')
				}
			}
		]
	},

	/** @deprecated */
	ListItem: {
		title: getTextObjectWithLocalisationKey('editor.objectPreviews.ListItemView')
	},

	/** @deprecated */
	ChunkyListItem: {
		title: getTextObjectWithLocalisationKey('editor.objectPreviews.ChunkyListItemView.title'),
		description: getTextObjectWithLocalisationKey('editor.objectPreviews.ChunkyListItemView.description'),
		image: ICON_PREVIEW
	}
}

/**
 * @param {string} key i18n localisation key to use.
 * @returns {Object} A Storm Text-compatible object with its content set
 *     to the value of the specified localisation key in the frontend's
 *     current language.
 */
function getTextObjectWithLocalisationKey(key) {
	return {
		content: {
			en: $.t(key)
		}
	}
}

var IGNORE_KEYS = [
	'id',
	'class'
]

var ListItemPreviewView = Backbone.View.extend(/** @lends ListItemPreviewChildView.prototype */{
	/** @override */
	tagName: 'ul',
	/** @override */
	className: 'picker-item',

	/**
	 * @constructs ListItemPreviewChildView
	 * @extends Backbone.View
	 * @override
	 */
	initialize: function(options) {
		var view = new ListItemView(options)

		this.listViews = [view]

		// Remove all bound events from the view to stop click events being
		// handled.
		view.undelegateEvents()
	},

	/** @override */
	afterRender: function() {
		var className = this.model.get('class'),
			$title = $('<h6>').text(className)

		this.$el.attr('title', className)
		this.$el.attr('data-class', className)
		this.$el.append(this.listViews[0].render().el)
		this.$el.append($title)
	}
})

var QuizItemPreviewView = Backbone.View.extend(/** @lends ListItemPreviewChildQuizView.prototype */{
	/** @override */
	className: 'picker-item',

	/**
	 * @constructs ListItemPreviewChildQuizView
	 * @extends Backbone.View
	 * @override
	 */
	initialize: function() {
	},

	/** @override */
	afterRender: function() {
		var className = this.model.get('class'),
			$title = $('<h6>').text(className)

		this.$el.attr('data-class', className)
		this.$el.append($title)
	}
})

var exportedModule = Backbone.View.extend(/** @lends ListItemPreviewView.prototype */{
	/** @override */
	className: 'list-item-preview new-objects',

	/**
	 * @constructs ListItemPreviewView
	 * @extends Backbone.View
	 * @override
	 */
	initialize: function(options) {
		this.subclasses = options.subclasses.slice(0)
		this.listViews = []
		this.superClass = ''

		// Remove the generic super class, if present.
		var listItemIndex = this.subclasses.indexOf('ListItem')

		if (listItemIndex > -1) {
			this.subclasses.splice(listItemIndex, 1)
			this.superClass = 'ListItem'
		} else {
			this.superClass = 'QuizItem'
		}

		/** @private {boolean} */
		this.draggable_ = options.draggable || false
	},

	/** @override */
	afterRender: function() {
		// Clear any previews list views.
		this.listViews.forEach(function(view) {
			view.destroy()
		})

		var model = this.model

		if (this.superClass === 'ListItem') {
			var previewObjs = this.subclasses.map(function(subclass) {
				var obj             = StormObject.fromClassName(subclass, 0),
					normalisedClass = APICompat.normaliseClassName(subclass),
					previewData     = PREVIEWS[normalisedClass]

				// Populate with default preview data.
				if (previewData) {
					obj.set(previewData)
				}

				// If we've been given an existing model to preview, use that.
				if (model) {
					Object.keys(obj.attributes).forEach(function(key) {
						var data = model.get(key)

						// Copy everything but the blacklisted keys.
						if (data && IGNORE_KEYS.indexOf(key) === -1) {
							obj.set(key, data)
						}
					})
				}

				return obj
			})

			previewObjs.forEach(function(model) {
				var view = this.addView(new ListItemPreviewView({
					model: model
				}))

				this.$el.append(view.render().el)
			}, this)
		} else if (this.superClass === 'QuizItem') {
			this.subclasses.forEach(function(subclass) {
				var normalisedClass = APICompat.normaliseClassName(subclass)

				var model = new Backbone.Model({
					name: $.t('viewPicker.' + normalisedClass),
					class: subclass
				})

				var view = this.addView(new QuizItemPreviewView({model: model}))

				this.$el.append(view.render().el)
			}, this)
		}

		this.$el.toggleClass('drag', this.draggable_)
	}
})

exportedModule.ListItemPreviewView = ListItemPreviewView
exportedModule.QuizItemPreviewView = QuizItemPreviewView

exportedModule.ListPreviewView = ListItemPreviewView.extend({
	/**
	 * @constructs ListItemPreviewChildListView
	 * @extends ListItemPreviewChildView
	 * @override
	 */
	initialize: function(options) {
		var view = new ListView(options)

		this.listViews = [view]

		// Remove all bound events from the view to stop click events being
		// handled.
		view.undelegateEvents()
	}
})

/**
 * Exports {@link ListItemPreviewView}.
 * @module
 */
module.exports = exportedModule
