<div class="container-fluid">
	<div class="row">
		<div class="col-sm-6"> <legend>{{t 'appSetup.translation'}}</legend></div>
		<!-- <div class="form-group translation-download col-sm-4">

		</div> -->
		<div class="col-sm-6 actions-col">
				<a href="javascript:history.back()"><button class="button red wide" type="button">{{t "common.cancel"}}</button></a>
				<button class="button green wide save">{{t "common.save"}}</button>
				<button type="button" class="download-json-button button"><i class="icon-download-alt"></i> JSON</button>
				<button type="button" class="download-csv-button button"><i class="icon-download-alt"></i> CSV</button>
		</div>
	</div>
	<div class="row">
		<!-- Type Plugin / Native String -->
		<div class="col-sm-6 form-group">
			<label>{{t "common.filter"}}</label>
			<select class="form-control" id="type-selector">
				<option value="plugins" {{#ifEqual type 'plugins'}} selected {{/ifEqual}}>Plugins</option>
				<option value="native" {{#ifEqual type 'native'}} selected {{/ifEqual}}>Native</option>
				<option value="pages" {{#ifEqual type 'pages'}} selected {{/ifEqual}}>Pages</option>
				<option value="all" {{#ifEqual type 'all'}} selected {{/ifEqual}}>All</option>
			</select>
		</div>

		<!-- <span id='table-spacer'></span> -->

		<!-- Plugins -->
		<div class="col-sm-6 form-group" id="plugins" style="display:none;">
			<label>{{t "plugins.title"}}</label>
			<select class="form-control">
				{{#each plugins}}<option value="{{id}}">{{name}}</option>{{/each}}
			</select>
		</div>

		<!-- Classes -->
		<div class="col-sm-3 form-group" id="classes" style="display:none;">
			<label>{{t "plugins.title"}}</label>
			<select class="form-control">
				{{#each classes}}<option value="{{class}}">{{class}}</option>{{/each}}
			</select>
		</div>

		<!-- Pages -->
		<div class="col-sm-6 form-group" id="pages" style="display:none;">
			<label>{{t "editor.pageList.title"}}</label>
			<select class="form-control" id="page-selector">
				{{{pages}}}
			</select>

		</div>

	</div>
	<div class="filter-langs">
		<label>Filter languages</label>
		{{#each languages}}<input type="checkbox" class="check-lang-option" value="{{code}}" checked>{{name}}</input>
		{{/each}}
	</div>
	<div class="row">
		<div id="handsontable"></div>
	</div>
</div>
