<td class="center">
	{{#with factFile.images.[0]}}
	<span style="background-image: url('{{getPreviewUrl src}}')" class="fact-file-image-table"></span>
	{{/with}}
</td>
<td class="center">
	{{#key_value factFile.commonName.content "lang" "text"}}
		<dt>{{lang}}</dt>
		<dd>{{text}}</dd>
	{{/key_value}}
</td>
<td class="center">
	{{factFile.scientificName}}
</td>
<td class="center">
	{{#key_value factFile.seen.content "lang" "text"}}
		<dt>{{lang}}</dt>
		<dd>{{text}}</dd>
	{{/key_value}}
</td>
<td class="tools">
	<a href="/apps/{{appId}}/fact-files/{{factFile.id}}" title="{{t "common.edit"}}"><i class="icon-pencil"></i></a>
	<i class="icon-trash delete" title="{{t "mediaLibrary.delete"}}"></i>
</td>
