var MediaLibrary = require('media-library/media-library-view'),
	MediaSelectorView = require('media-library/media-selector-view'),
	utils = require('lib/utils')

module.exports = Backbone.View.extend({
	template: require('./story-edit-view-template'),
	className: 'storm-modal hide fade create-modal',

	events: {
		'click .save-button': 'save',
		'click .choose-image-button': 'chooseImage'
	},

	initialize: function() {
		this.views = {}
		this.mediaLibraryModel = {imageUrl: this.model.get('imageUrl')}
	},

	afterRender: function() {
		// Initialise datepicker
		this.datepicker = new Pikaday({
			field: this.$('#story-timestamp')[0],
			bound: false
		})

		var date
		var timestamp = this.model.get('timestamp')
		if (timestamp === undefined) {
			date = new Date()
		} else {
			date = new Date(timestamp * 1000)
		}

		this.datepicker.setDate(date)
	},

	chooseImage: function() {
		var app = App.getCurrentApp()

		// Show media library
		this.views.mediaLibrary = new MediaSelectorView({
			app: app,
			model: this.mediaLibraryModel,
			mediaType: MediaLibrary.types.IMAGE
		})

		this.views.mediaLibrary.on('change', function() {
			this.mediaLibraryModel.imageUrl = 'https:' + utils.getRemoteUrl(this.mediaLibraryModel.imageUrl)
			this.$('img').attr('src', this.mediaLibraryModel.imageUrl)
		}, this)

		$('body').append(this.views.mediaLibrary.el)
		this.views.mediaLibrary.render().show()
	},

	save: function() {
		App.startLoad()

		this.model.once('sync', this.saveDone, this)
		this.model.save({
			imageUrl: this.mediaLibraryModel.imageUrl,
			timestamp: this.datepicker.getDate().getTime() / 1000,
			title: $('#story-title').val(),
			subtitle: $('#story-sub-title').val(),
			content: $('#story-content').val(),
			link: $('#story-link').val()
		})
	},

	saveDone: function() {
		App.stopLoad()

		if (this.model.collection === undefined) {
			this.model.trigger('create', this.model)
		}

		$('.create-modal').modal('hide')
	},

	beforeDestroy: function() {
		// Remove datepicker
		this.datepicker.destroy()
	}
})
