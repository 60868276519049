module.exports = Backbone.Collection.extend({
	comparator: 'name',

	url: function() {
		if (this.appId) {
			return App.apiRoot + 'apps/' + this.appId + '/languages'
		}

		if (this.societyId) {
			return App.apiRoot + 'societies/' + this.societyId + '/languages'
		}

		return App.apiRoot + 'languages'
	},

	initialize: function(models, options) {
		options = options || {}
		this.appId = options.appId
		this.societyId = options.societyId
	}
})
