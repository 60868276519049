var MailerList = require('./mailer-list')

module.exports = Backbone.View.extend({
	template: require('./mailing-list-view-template'),

	events: {
		'click .export-button': 'doExport'
	},

	initialize: function(options) {
		this.appId = options.appId

		this.collection = new MailerList()
		this.collection.once('sync', this.ready, this)
		this.collection.fetch()
	},

	getRenderData: function() {
		return {
			collection: this.collection.toJSON(),
			appId: this.appId
		}
	},

	afterRender: function() {
		this.fromDate = new Pikaday({
			field: this.$('#export-from-date')[0]
		})

		this.toDate = new Pikaday({
			field: this.$('#export-to-date')[0]
		})

		var initialDate = new Date()
		initialDate.setMonth(initialDate.getMonth() - 1)

		this.fromDate.setDate(initialDate)
		this.toDate.setDate(new Date())
	},

	ready: function() {
		this.render()
		App.stopLoad()
	},

	doExport: function(e) {
		App.startLoad()

		// Fetch CSV data for this export.
		var id = Number($(e.currentTarget).data('id')),
			model = this.collection.get(id),
			from = parseInt(this.fromDate.getDate().getTime() / 1000, 10),
			to = parseInt(this.toDate.getDate().getTime() / 1000, 10)

		var p = model.doExport(from, to)
		p.then(this.saveExport)
	},

	saveExport: function(csvData) {
		var blob = new Blob([csvData], {type: 'text/csv;charset=utf-8'})
		saveAs(blob, 'export.csv')

		App.stopLoad()
	}
})
