var StandaloneStormObject = require('editor/standalone-storm-object'),
	EditorSectionView = require('editor/editor-section-view')
/**
 * Exports {@link CodeGroupEditView}.
 * @module
 */
module.exports = EditorSectionView.extend(/** @lends CodeGroupEditView.prototype */{
	/** @override */
	className: 'CodeGroupEditView',
	/** @override */
	template: require('./code-group-edit-view-template'),
	activeTabIndex: 4,

	/** @override */
	events: {
		'click .save': 'save',
		'click #addCode': 'addCode',
		'click .code-remove-button': 'removeCode'
	},

	/**
	 * @constructs CrossBordersEditView
	 * @extends EditorSectionView
	 * @override
	 */
	initialize: function(options) {
		EditorSectionView.prototype.initialize.apply(this, arguments)

		/** @private {App} */
		this.app_ = options.app

		this.model = new StandaloneStormObject()

		var promises = []

		// Fetch enabled languages for this app.
		promises.push(this.app_.languageList.fetchOnce())

		var modelPromise = Promise.resolve()

		if (options.id !== 'new') {
			this.model.set('id', options.id)
			this.model.once('sync', this.ready, this)
			modelPromise = this.model.fetch()
		} else {
			this.model = StandaloneStormObject.fromClassName('StormQLCodeGroup')
		}

		promises.push(modelPromise)

		Promise.all(promises)
			.then(this.modelLocked.bind(this))
	},

	modelLocked: function() {
		App.stopLoad()
		if (this.model.get('pageId') > 0) {
			this.model.requestLock(function() {
				this.render()
			}.bind(this))
		} else {
			this.render()
		}
	},
	/** @override */
	afterRender: function() {
		this.delegateEvents()

		// Show current input values
		_.each(this.model.get('name..content'), function(val, key) {
			this.$('.name-input[data-code=' + key + ']').val(val)
		})

		_.each(this.model.get('description..content'), function(val, key) {
			this.$('.description-input[data-code=' + key + ']').val(val)
		})

		this.codes = this.model.get('codes')
	},

	/** @override */
	getRenderData: function() {
		var data = this.model.toJSON()
		data.appId = this.app_.id
		data.languages = this.app_.languageList.toJSON()
		data.countryCodes = this.countryCodes
		return data
	},

	addCode: function() {
		var input = $('#code-input').val().trim()
		if (input) {
			if (this.codes.indexOf(input) > -1) {
				return
			}
			this.codes.push(input)
			this.populateList()
		}
	},

	removeCode: function(e) {
		var id = $(e.target).data("id")
		this.codes.splice(id, 1)
		this.populateList()
	},

	populateList: function() {
		$('#code-list > li').remove()
		_.each(this.codes, function(code, key) {
			$('#code-list').append('<li>' + code + ' <a href="" class="code-remove-button" data-id="' + key + '">×</a></li>')
		})
	},

	save: function() {
		App.startLoad()

		var self = this

		// Get all new input values
		var names = {},
			descriptions = {}

		this.$('.name-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				names[code] = value
			}
		})

		this.$('.description-input').each(function() {
			var code = $(this).data('code')
			var value = this.value

			if (value !== '') {
				descriptions[code] = value
			}
		})

		this.model.once('sync', function() {
			if (this.model.get('pageId') > 0) {
				this.model.requestUnlock()
			}
			App.router.navigate('/apps/' + self.app_.id + '/code-groups', {trigger: true})
		}.bind(this))

		this.model.set('name..content', names)
		this.model.set('description..content', descriptions)
		this.model.set('codes', this.codes)

		this.model.save(null, {appId: this.app_.id})
		return false
	}
})
