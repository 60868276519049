var MediaLibraryView = require('./media-library-view'),
	types            = MediaLibraryView.types,
	isImage          = /^image\/(jpeg|png)/,
	isVideo          = /^video\/mp4/

/**
 * Defines a set of static methods for manipulating and uploading media.
 */
var MediaUploader = {
	/**
	 * @param {FileList} files List of files to validate.
	 * @param {MediaLibraryView.types} type The media type to validate against.
	 * @returns {boolean|string} {@code true} if all files in the specified
	 *     {@link FileList} are valid for the type, or an error message.
	 */
	mediaIsValidForType: function(files, type) {
		switch (type) {
			case types.IMAGE:
			case types.ICON:
				return MediaUploader.mediaIsValidForTypeImage_(files)

			case types.ANIMATION:
				return MediaUploader.mediaIsValidForTypeAnimation_(files)

			case types.VIDEO:
				return MediaUploader.mediaIsValidForTypeVideo_(files)
		}

		throw new Error('Unsupported media type')
	},

	/**
	 * @param {FileList} files List of files to validate.
	 * @returns {boolean|string} {@code true} if all files in the specified
	 *     {@link FileList} are valid for type image, or an error message.
	 * @private
	 */
	mediaIsValidForTypeImage_: function(files) {
		if (files.length > 1) {
			return $.t('mediaLibrary.upload.errors.tooManyFiles')
		}

		var file = files[0]

		if (!isImage.test(file.type)) {
			return $.t('mediaLibrary.upload.formatError')
		}

		return true
	},

	/**
	 * @param {FileList} files List of files to validate.
	 * @returns {boolean|string} {@code true} if all files in the specified
	 *     {@link FileList} are valid for type animation, or an error message.
	 * @private
	 */
	mediaIsValidForTypeAnimation_: function(files) {
		for (var i = 0; i < files.length; i++) {
			var isValid = MediaUploader.mediaIsValidForTypeImage_(files[i])

			if (isValid !== true) {
				return isValid
			}
		}

		return true
	},

	/**
	 * @param {FileList} files List of files to validate.
	 * @returns {boolean|string} {@code true} if all files in the specified
	 *     {@link FileList} are valid for type video, or an error message.
	 * @private
	 */
	mediaIsValidForTypeVideo_: function(files) {
		if (files.length > 1) {
			return $.t('mediaLibrary.upload.errors.tooManyFiles')
		}

		var file = files[0]

		if (!isVideo.test(file.type)) {
			return $.t('mediaLibrary.upload.formatError')
		}

		return true
	}
}

module.exports = MediaUploader
