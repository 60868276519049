{
	"error": {
		"noAccess": "ليست لديك صلاحية للوصول إلى هذا النظام. يرجى التحدث مع مسؤولك.",
		"noAccessJoin": "ليست لديك صلاحية للوصول إلى هذا النظام حاليًا. هل تود الانضمام؟",
		"cantPaste": "لا يمكن لصق هذا النوع من الكائنات هنا.",
		"noPermission": "ليس لديك إذن لتنفيذ هذا الإجراء.",
		"allFields": "يرجى تعبئة جميع الحقول.",
		"rootPage": "لا يمكن العثور على صفحة الجذر.",
		"lockedBy": "هذا الكائن مقفل حاليًا بواسطة",
		"saveFailed": "فشل عملية الحفظ. يرجى تحديث الصفحة.",
		"oops": "وجه الفتاة!",
		"generic": "حدث خطأ.",
		"exitWarning": "لا تزال بيانات التطبيق محفوظة. إذا غادرت الصفحة الآن ، فستفقد البيانات.",
		"connect": "تعذر الاتصال بخادم واجهة برمجة التطبيقات.",
		"noApps": "لا يمكنك الوصول إلى أي تطبيقات"
	},
	"common": {
		"filter": "تصفية",
		"page": "صفحة",
		"language": "لغة",
		"linkTypes": {
			"email": "البريد الإلكتروني",
			"uri": "URI",
			"native": "محلي",
			"appLink": "بين التطبيق",
			"timer": "مؤقت",
			"call": "مكالمة",
			"emergency": "حالة طوارئ",
			"share": "شارك",
			"sms": "رسالة قصيرة",
			"external": "خارجي",
			"internal": "داخلي"
		},
		"linkDescriptions": {
			"email": "رابط لإرسال بريد إلكتروني إلى العنوان المحدد.",
			"share": "رابط لمشاركة التطبيق عبر مربع حوار \"المشاركة\" الأصلي على الجهاز.",
			"internal": "رابط إلى صفحة مختلفة داخل التطبيق.",
			"emergency": "رابط لطلب رقم هاتف الطوارئ المحدد في إعدادات المستخدم.",
			"call": "رابط لطلب رقم هاتف من جهاز المستخدم.",
			"uri": "رابط إلى صفحة ويب أو تطبيق خارجي. سيؤدي إلى مغادرة المستخدم للتطبيق.",
			"appLink": "رابط إلى صفحة في تطبيق آخر.",
			"external": "رابط إلى صفحة ويب خارجية. ستفتح داخل التطبيق.",
			"sms": "رابط لإرسال رسالة SMS من جهاز المستخدم.",
			"native": "رابط لجزء من الوظائف الأصلية داخل التطبيق.",
			"timer": "رابط يبدأ جهاز ضبط وقت العد التنازلي على الجهاز."
		},
		"pageTags": {
			"learn": "تعلم",
			"info": "معلومات",
			"other": "آخر",
			"tests": "اختبارات",
			"emergency": "حالة طوارئ",
			"prepare": "إعداد"
		},
		"back": "الى الخلف",
		"upload": "تحميل",
		"description": "وصف",
		"settings": "إعدادات",
		"submit": "خضع",
		"preview": "معاينة",
		"approve": "يوافق",
		"closeThisMessage": "أغلق هذه الرسالة",
		"save": "حفظ",
		"view": "رأي",
		"done": "فعله",
		"email": "عنوان البريد الإلكتروني",
		"add": "إضافة",
		"edit": "تصحيح",
		"username": "اسم المستخدم",
		"warning": "تحذير",
		"finish": "إنهاء",
		"position": "موضع",
		"tag": "بطاقة",
		"clickToBrowseMap": "انقر لتصفح الخريطة",
		"cancel": "إلغاء",
		"sections": "الأقسام",
		"pleaseWait": "أرجو الإنتظار",
		"next": "التالى",
		"publish": "نشر",
		"tools": "أدوات",
		"close": "قريب",
		"approved": "وافق",
		"reject": "رفض",
		"longLoad": "استرجاع كمية كبيرة من البيانات ، قد يستغرق هذا الأمر ما يصل إلى دقيقة."
	},
	"login": {
		"title": "تسجيل الدخول",
		"inputs": {
			"password": "كلمه السر"
		},
		"error": {
			"title": "Oopsie!",
			"emailNotFound": "عنوان البريد الإلكتروني غير موجود.",
			"linkExpired": "انتهت صلاحية رابط إعادة تعيين كلمة المرور هذا.",
			"body": "لقد أدخلت اسم المستخدم أو كلمة المرور غير الصحيحة."
		},
		"forgot": {
			"title": "كلنا ننسى في بعض الأحيان!",
			"body": "أدخل عنوان البريد الإلكتروني المرتبط بحسابك."
		},
		"success": {
			"loginBody": "لقد قمت بتسجيل الدخول بنجاح ، ولكن لا يوجد مكان تذهب إليه! أنت على auth.3sidedcube.com. يرجى الذهاب إلى Storm CMS في مؤسستك.",
			"body": "يجب أن تتلقى رسالة إلكترونية تحتوي على تعليمات لإعادة تعيين كلمة المرور الخاصة بك.",
			"title": "نجاح!"
		},
		"links": {
			"return": "العودة لتسجيل الدخول",
			"forgot": "انا نسيت..."
		},
		"reset": {
			"newPassword": "كلمة السر الجديدة",
			"confirm": "تؤكد",
			"submit": "خضع",
			"success": "تم تعيين كلمة المرور الخاصة بك ، يمكنك الآن إغلاق هذه النافذة."
		}
	},
	"localisations": {
		"title": "التطويعات المحلية",
		"string": "سلسلة",
		"key": "مفتاح",
		"addNew": "إضافة تطويعات محلية جديدة",
		"addMultiple": "إضافة تطويعات محلية متعددة",
		"addMultipleInstructions": "إضافة أو تحديث تطويعات محلية بكميات كبيرة. استخدم CSV في التنسيق",
		"uploadCsv": "تحميل CSV",
		"copyFromAnotherApp": "نسخ من تطبيق آخر",
		"confirmCopy": "هل تريد بالفعل نسخ التطويعات المحلية من التطبيق المحدد؟",
		"overwriteExisting": "استبدال التطويعات المحلية الحالية",
		"overwriteWarning": "سيتم استبدال الترجمة التي ترجمتها.",
		"all": "<b>كل</b> التطويعات المحلية",
		"errors": {
			"invalidKey": "مفتاح تطويع محلي غير صالح. يجب أن تبدأ المفاتيح بحرف ذي تسطير سفلي وأن تحتوي فقط على أحرف كبيرة وذات تسطيرات سفلية. يجب أن تحتوي المفاتيح على قسمين على الأقل (الخانات مقيدة حسب الأحرف ذات التسطيرات السفلية).",
			"csvTooManyColumns": "يوجد الكثير من الأعمدة في العنوان.",
			"csvInvalidHeader": "عنوان غير صالح.",
			"csvInvalidKeyOnLine": "مفتاح تطويع محلي غير صالح على الإنترنت",
			"csvInvalidColumnCountOnLine": "عدد غير صالح من الأعمدة على الإنترنت",
			"expected": "متوقع",
			"got": "تحقق"
		}
	},
	"appSetup": {
		"expandAll": "توسيع الكل",
		"addCsv": "إضافة CSV",
		"properties": "الخصائص",
		"value": "القيمة",
		"translation": "ترجمة",
		"dev": "ديف",
		"live": "حي",
		"key": "مفتاح",
		"startPage": "أبدأ الصفحة",
		"appName": "اسم التطبيق",
		"languages": "لغات التطبيق",
		"title": "إعداد التطبيق",
		"localisation": "توطين التطبيق",
		"publishable": "Publishable إلى:",
		"bundleIdentifiers": "معرفات حزمة SDK",
		"addMoreLanguages": "فقط اللغات المعينة لهذه المنظمة متوفرة هنا. تعيين المزيد في قسم المسؤول.",
		"translationError": "فشل تحميل ملف الترجمة. يرجى التحقق من أن التطبيق والتنظيم بهما لغة واحدة على الأقل ممكّنة وقابلة للنشر.",
		"type": "اكتب",
		"publishableLanguages": "لغات قابلة للنشر"
	},
	"metadata": {
		"addScreenshot": "إضافة لقطة شاشة",
		"appIconHint": "ينبغي أن تكون الصورة بجودة 1024 x 1024 مع عدم وجود شفافية. يجب أن تكون بتنسيق JPG أو PNG، مع دقة بحد أدنى لا تقل على 72 نقطة لكل بوصة وفي مساحة ألوان RGB. ويجب أن تحتوي على طبقات أو زوايا مستديرة.",
		"appPreviewVideo": "فيديو نظرة عامة على التطبيق",
		"appPreviewVideoHint": "يجب أن تكون مقاطع فيديو الخاصة بالنظرة العامة على التطبيق بتنسيق M4V أو MP4 أو MOV ولا يمكن أن يتجاوز حجم المقطع 500 ميجا بايت.",
		"appStoreTitle": "العنوان بمتجر التطبيقات",
		"delete": "حذف",
		"emailHint": "أدخل عنوان بريد إلكتروني في حالة ما إذا كان يرغب متجر التطبيقات أو المستخدمين بأن يكونوا على اتصال",
		"featureGraphic": "الرسم المميز",
		"featureGraphicHint": "يتطلب متجر جوجل بلاي أن يكون للتطبيقات رسم مميز أو \"لافتة دعائية\" – يجب أن تكون أبعادها بعرض 1024 × ارتفاع 500. يمكن أن تكون هذه اللافتة صورة مستقلة أو صورة خلفية لفيديو ترويجي (في حالة تقديمه)",
		"languages": "اللغات",
		"nameOnDevice": "اسم التطبيق على الجهاز",
		"phoneNumberHint": "يجب أن يشتمل على رمز الاتصال بالدولة",
		"platformSpecific": "بيانات تعريف خاصة بالمنصة",
		"price": "السعر",
		"rating": "التصنيف",
		"screenshotsHint": "يشتمل على لقطات من الشاشة لكل حجم شاشة. يجب أن تكون لقطات الشاشة بتنسيق JPG أو PNG، وفي مساحة ألوان RGB",
		"shared": "بيانات التعريف عبر منصات متعددة",
		"shortAppDescription": "وصف موجز للتطبيق",
		"supportUrlHint": "رابط على الإنترنت بمعلومات داعمة لتطبيقك. يجب أن يكون هذا الرابط ظاهرًا على متجر التطبيقات.",
		"websiteHint": "رابط على الإنترنت بمعلومات تسويقية لتطبيقك. يجب أن يكون هذا الرابط ظاهرًا على متجر التطبيقات.",
		"website": "عنوان التسويق",
		"phoneNumber": "رقم الهاتف",
		"privacyPolicyUrl": "URL سياسة الخصوصية",
		"copyright": "حقوق النشر",
		"title": "البيانات الوصفية",
		"keywords": "الكلمات الدالة",
		"description": "وصف",
		"features": "الميزات",
		"primaryCategory": "الفئة الأساسية",
		"supportUrl": "عنوان URL الدعم",
		"secondaryCategory": "الفئة الثانوية",
		"appIcon": "رمز التطبيق",
		"appStore": "تفاصيل متجر التطبيقات",
		"noBuildPlatforms": "لا توجد منصات إنشاء لهذا التطبيق",
		"launchDate": "موعد غداء",
		"iconSize": "يجب أن يكون حجم الرمز الأصلي 1024x1024.",
		"name": "اسم التطبيق"
	},
	"plugins": {
		"title": "البرامج الإضافية",
		"availablePlugins": "البرامج الإضافية المتاحة"
	},
	"editor": {
		"inspector": {
			"swapItem": "عنصر التبادل",
			"properties": {
				"progressMessage": "رسالة التقدم",
				"annotation": {
					"description": "رقم الخطوة",
					"title": "حاشية"
				},
				"title": {
					"title": "عنوان",
					"description": "عنوان عنصر القائمة"
				},
				"name": {
					"title": "اسم",
					"description": "اسم النظام الداخلي"
				},
				"hint": {
					"title": "ملحوظة",
					"description": "تعليمات للمستخدم"
				},
				"image": {
					"removeButton": "إزالة الصورة",
					"chooseButton": "اختر صورة",
					"title": "صورة"
				},
				"video": {
					"title": "فيديو",
					"chooseButton": "اختر الفيديو",
					"addLanguage": "إضافة لغة",
					"loop": "عقدة",
					"attributes": "سمات"
				},
				"link": {
					"duration": "مدة الموقت (بالدقائق)",
					"linkDestination": "وصلة الوجهة",
					"title": "حلقة الوصل",
					"linkTitle": "عنوان الرابط",
					"body": "نص أساسي",
					"testLink": "اختبار الارتباط",
					"destination": "المكان المقصود",
					"recipients": "المستلمون (واحد لكل سطر)",
					"type": "اكتب"
				},
				"images": {
					"add": "إضافة صورة"
				},
				"animation": {
					"chooseButton": "اختر الرسوم المتحركة",
					"delay": "تأخير الإطار (بالثواني)"
				},
				"embeddedLinks": {
					"add": "إضافة رابط",
					"title": "روابط مضمنة"
				},
				"embeddedMedia": {
					"title": "الوسائط المدمجة"
				},
				"header": {
					"description": "يظهر فوق مجموعة من العناصر",
					"title": "رأس"
				},
				"footer": {
					"title": "تذييل",
					"description": "يظهر أدناه مجموعة من العناصر"
				},
				"badge": {
					"completion": {
						"title": "نص الانتهاء",
						"description": "معروض للمستخدم على اكتمال الاختبار"
					},
					"how": {
						"title": "كيف تحصل على",
						"description": "نص يصف كيفية تحقيق هذه الشارة"
					},
					"icon": "أيقونة",
					"title": "شارة"
				},
				"units": {
					"description": "ثواني / متر إلخ.",
					"title": "وحدات"
				},
				"range": {
					"increment": "زيادة راتب",
					"start": "بداية",
					"title": "نطاق",
					"length": "الطول"
				},
				"completionText": {
					"title": "نص الانتهاء",
					"description": "عرض للمستخدم على الإجابة بشكل صحيح"
				},
				"failureText": {
					"description": "عرض للمستخدم على الإجابة بشكل غير صحيح",
					"title": "فشل النص"
				},
				"winThreshold": {
					"description": "النسبة المئوية للأجوبة الصحيحة المطلوبة لاجتياز الاختبار",
					"title": "عتبة الفوز (٪)"
				},
				"winMessage": {
					"description": "عرض للمستخدم على اجتياز الاختبار",
					"title": "اربح رسالة"
				},
				"loseMessage": {
					"title": "افقد الرسالة",
					"description": "عرض للمستخدم عند الإخفاق في الاختبار"
				},
				"shareMessage": {
					"description": "النص الذي ستتم مشاركته مع الشارة على الشبكات الاجتماعية",
					"title": "مشاركة الرسالة"
				},
				"caption": "شرح",
				"button": "زر",
				"delay": "التأخير)",
				"quiz": "لغز",
				"quizzes": "مسابقات",
				"overlay": "تراكب النص",
				"standard": "اساسي",
				"columns": "أعمدة",
				"answer": "إجابة",
				"correctAnswer": "اجابة صحيحة",
				"initialPosition": "الوضعية الأولية",
				"textContent": "محتوى النص",
				"gridType": "نوع الشبكة",
				"placeholderImage": "صورة مكان النائب",
				"winRelatedLinks": "اربح الروابط ذات الصلة",
				"selectionLimit": "حد الاختيار",
				"volatile": "متقلب (لا تستمر الدولة)",
				"loseRelatedLinks": "تفقد الروابط ذات الصلة",
				"relatedLinks": "روابط ذات علاقة",
				"confirmGridTypeChange": "هل أنت متأكد من أنك تريد تغيير نوع الشبكة؟ سيؤدي هذا إلى تغيير كافة عناصر الشبكة."
			},
			"paste": "معجون",
			"moveDown": "تحرك لأسفل",
			"newType": "نوع جديد",
			"confirmApproval": "هل أنت متأكد من أنك تريد الموافقة على هذا؟",
			"addAnother": "أضف آخر",
			"rootPageDelete": "هذه هي الصفحة الجذرية للتطبيق. لا يمكن حذفها.",
			"changeType": "تغيير النوع",
			"addBulletItem": "إضافة بند رصاصة",
			"copy": "نسخ",
			"moveUp": "تحرك",
			"confirmDelete": "هل أنت متأكد من أنك تريد حذف هذا العنصر؟",
			"copiedItem": "العنصر المنسوخ",
			"areYouSure": "هل أنت واثق؟",
			"confirmPageDelete": "تأكيد حذف الصفحة",
			"confirmPageDeleteWarning": "تحذير: أنت تقوم بحذف كائن صفحة. ستتم إزالة كل محتوى الصفحة. هل أنت متأكد من أنك تريد المتابعة؟",
			"title": "تحرير الخصائص",
			"copySuccess": "تم نسخ الكائن إلى الحافظة"
		},
		"objectPreviews": {
			"TextListItem": "استخدم هذا البند لنص افتتاحي، عادة ما يكون في أعلى الصفحة",
			"TitleListItem": "استخدم هذا البند لسطر واحد في النص",
			"DescriptionListItem": {
				"title": "يحتوي هذا البند على عنوان ووصف",
				"description": "استخدم هذا البند عندما يكون لديك الكثير لتقوله"
			},
			"StandardListItem": {
				"title": "استخدم هذا البند أكثر من مرة",
				"description": "يشتمل هذا البند على عنوان ووصف وأيقونة يمكنك ترك خانات فارغة – مثل الأيقونة"
			},
			"ToggleableListItem": {
				"title": "استخدم ذلك عندما تريد إخفاء شيء ما",
				"description": "يمكن عرض وإخفاء هذا النص بواسطة المستخدم. هذا البند جيدًا في حالة ما إذا كان يتوفر لديك الكثير من التفاصيل - من الممكن الأسئلة الأكثر شيوعًا"
			},
			"OrderedListItem": {
				"title": "قائمة مرتبة",
				"description": "استخدم هذه الميزة عندما تريد ترتيب القائمة ترتيبًا رقميًا"
			},
			"UnorderedListItem": {
				"title": "قائمة غير مرتبة",
				"description": "استخدم هذه الميزة عندما تريد وضع الأشياء في قائمة بنقاط فقط"
			},
			"CheckableListItem": "استخدم هذه الميزة لقوائم الفحص",
			"ButtonListItem": "استخدم هذه الميزة إن كنت تريد زرًا",
			"HeaderListItem": {
				"title": "استخدم هذه الميزة في أعلى الصفحة",
				"description": "يمكن أن تشتمل على عنوان ووصف مطول"
			},
			"LogoListItem": "استخدم هذه الميزة لشيء ما مثل شعار أو عندما تريد صورة وتسمية توضيحية ورابط",
			"SpotlightListItem": {
				"first": "التدوير",
				"second": "ارتباطات بارزة"
			},
			"ChunkyListItemView": {
				"title": "هذا مثل عنصر القائمة القياسية - مع حشو إضافي.",
				"description": "يشتمل هذا البند على عنوان ووصف وأيقونة. يمكنك ترك خانات فارغة – مثل الأيقونة"
			},
			"ListItemView": "يشتمل هذا البند على عنوان فقط"
		},
		"pageList": {
			"title": "صفحات",
			"allPages": "كل الصفحات",
			"filter": "تصفية الصفحات"
		},
		"title": "محرر",
		"addLink": "إضافة رابط",
		"pageName": "اسم الصفحة",
		"pageStyle": "نمط الصفحة",
		"confirmRelock": "هذا الكائن مقفل من قبلك. هل ترغب في قفله مرة أخرى؟ سيتم إلغاء قفلك السابق.",
		"newFromTemplate": "جديد من قالب",
		"saving": "إنقاذ...",
		"addQuiz": "إضافة مسابقة",
		"addApp": "إضافة التطبيق",
		"addObject": "إضافة كائن هنا",
		"confirmRequestNewLock": "لقد تم إبطال قفل هذه الصفحة. هل ترغب في طلب قفل جديد؟",
		"addPage": "إضافة صفحة",
		"never": "أبدا",
		"pageTag": "علامة الصفحة",
		"pageType": "نوع الصفحة",
		"lastSaved": "آخر حفظ:",
		"savesQueued": "يحفظ في قائمة الانتظار"
	},
	"mediaLibrary": {
		"chooseExisting": "اختيار الحالي",
		"upload": {
			"instructions": "انقر هنا أو اسحب ملفًا للبدء",
			"cropType": "نوع التقطيع",
			"noCrop": "بدون تقطيع",
			"square": "مربع",
			"squareDesc": "استخدم هذه الميزة لتقطيع الأيقونات وصور المسابقات. ينبغي أن تكون صور المسابقات بعرض <b>640</b> وبارتفاع <b>640</b> بكسل.",
			"errors": {
				"tooManyFiles": "يرجى اختيار ملف واحد"
			},
			"requiresTransparency": "يتطلب خلفية شفافة",
			"newTag": "علامة جديدة",
			"agreementText": "يخضع كل المحتوى الذي تم تحميله لهذا النظام لشروط",
			"filesizeError": "التحميل فشل. الملف كبير جدًا. الحد الأقصى المسموح به هو",
			"invalidTag": "علامات غير صالحة. لا يجوز أن تحتوي العلامات على رمز حرف البدل (*).",
			"headerDesc": "استخدم هذا الاقتصاص لصور العنوان الكبيرة التي تظهر في أعلى الصفحة. يجب أن تكون الصور بحجم <b>640 (w)</b> بمقدار <b>568 (h)</b> بكسل.",
			"gdpcSpotlightDesc": "استخدم هذا المحصول للحصول على رؤوس الرسائل في تطبيقات First Aid ، وأضواء كاشفة ، وحافة إلى حافة ، وشرائح ، وصور فيديو. يجب أن تكون الصور <b>640 (w)</b> بمقدار <b>320 (h)</b> بكسل في الحجم.",
			"spotlightDesc": "استخدم هذا الاقتصاص في الأضواء ، والحافة إلى الحافة ، وعرض الشرائح ، وصور الفيديو. يجب أن تكون الصور <b>640 (w)</b> بمقدار <b>320 (h)</b> بكسل في الحجم.",
			"title": "تحميل الوسائط",
			"genericError": "فشل تحميل الملف. خطا بالكود:",
			"gdpcHeaderDesc": "استخدم هذا الاقتصاص لصور الرأس الكبيرة في تطبيقات Hazards. يجب أن تكون الصور بحجم <b>640 (w)</b> بمقدار <b>568 (h)</b> بكسل.",
			"selectType": "يرجى تحديد نوع الصورة التي تقوم بتحميلها",
			"formatError": "التحميل فشل. تنسيق ملف غير صالح. تنسيقات الصور المسموح بها: .jpeg ، .png ، .gif تنسيقات الفيديو المسموح بها: .mp4 ، .m4v",
			"imageDesc": "يوصى باستخدام صور من <b>640 (w)</b> بمقدار <b>320 (h)</b> بكسل للصور من الحافة إلى الحافة ، وصور عرض الشرائح وصور الفيديو. يجب أن تكون صور الاختبار <b>640 (w)</b> بواسطة <b>640 (h)</b> بكسل.",
			"tags": "الكلمات"
		},
		"video": "فيديو",
		"society": "المجتمع",
		"type": "نوع من وسائل الإعلام",
		"icon": "أيقونة",
		"animation": "الرسوم المتحركة",
		"refine": "صقل المكتبة",
		"title": "مكتبة الوسائط",
		"filterByTags": "تصفية حسب العلامات",
		"commas": "افصل بفواصل",
		"general": "جنرال لواء",
		"section": "الجزء",
		"image": "صورة",
		"additionalTags": "علامات إضافية",
		"clearFilters": "مسح الفلاتر",
		"delete": "حذف",
		"loadMore": "تحميل المزيد",
		"dragToReorder": "اسحب لإعادة ترتيب",
		"editMedia": "تحرير الوسائط",
		"confirmTagRemoval": "هل أنت متأكد من أنك تريد إزالة هذه العلامة؟"
	},
	"viewPicker": {
		"TextListItem": "نص من طرف إلى طرف بين المجموعات",
		"TitleListItem": "سطر نصي واحد",
		"StandardListItem": "عنوان ورابط وأيقونة",
		"DescriptionListItem": "عنوان ووصف بنص متعدد السطور",
		"ToggleableListItem": "عنوان يمكن النقر عليه والذي يظهر محتوى نصي أطول",
		"OrderedListItem": "عنوان ومحتوى نصي متعدد السطور يحتوي على تعليق توضيحي ورقم خطوة",
		"UnorderedListItem": "بند قائمة ذات نقاط تشتمل على عنوان ووصف",
		"CheckableListItem": "عنوان وخانة اختيار. يمكن أن تستمر الحالة بين مستخدمي التطبيق",
		"ButtonListItem": "عنوان وزر.",
		"ImageListItem": "صورة واحدة، من طرف إلى آخر",
		"HeaderListItem": "صورة كبيرة بعنوان ووصف متراكب عليها",
		"VideoListItem": "عنصر فيديو يدعم مصادر متعددة",
		"AnimatedImageListItem": "سلسلة من الصور التي يتم تشغيلها على شكل عرض شرائح توضح معلومات",
		"SpotlightImageListItem": "صورة أو أكثر ذات ارتباط مميز تحتوي على صورة وتسمية توضيحية ورابط",
		"LogoListItem": "تستخدم لعرض العلامة التجارية للشركة",
		"SliderQuizItem": "سؤال اختيار مُمر الصور",
		"TextQuizItem": "سؤال اختيار النص",
		"ImageQuizItem": "سؤال اختيار الصور",
		"AreaQuizItem": "سؤال اختيار المنطقة",
		"CollectionListItem": "عرض \"جمع الكل معًا\" للتطبيقات والشارات والروابط",
		"subText": "النص الفرعي",
		"spotlight": "ضوء كشاف",
		"text": "نص",
		"interactiveObjects": "كائنات تفاعلية",
		"basicObjects": "كائنات أساسية",
		"mediaObjects": "كائنات الوسائط",
		"multiLineObjects": "كائنات متعددة الخطوط",
		"leadInText": "نص الرصاص",
		"title": "شاء",
		"filter": "تصفية الكائنات",
		"hiddenText": "النص المخفي",
		"dragAndDrop": "سحب وإفلات الكائنات لإضافتها إلى الصفحة"
	},
	"users": {
		"removeUserConfirm": "هل تريد بالفعل إزالة بيانات هذا المستخدم؟",
		"failedToRemove": "فشل إزالة المستخدم",
		"createUser": "انشاء المستخدم",
		"lastName": "الكنية",
		"success": "نجاح! سيتلقى المستخدم الجديد رسالة إلكترونية تحتوي على تعليمات لتنشيط حسابه.",
		"title": "المستخدمين",
		"roles": "الأدوار",
		"complete": "تم إنشاء المستخدم بنجاح",
		"list": "قائمة المستخدمين",
		"lastActive": "الماضي نشط",
		"usernameHelp": "لا يجوز أن تحتوي أسماء المستخدمين إلا على أحرف وأرقام وشرطات سفلية وشرطات ونقط.",
		"apps": "التطبيقات",
		"firstName": "الاسم الاول",
		"name": "اسم",
		"organisation": "منظمة",
		"role": "وظيفة",
		"error": "لا يمكن إنشاء المستخدم. تحقق من عدم تسجيل اسم المستخدم والبريد الإلكتروني."
	},
	"userApps": {
		"title": "تطبيقات المستخدم"
	},
	"push": {
		"messageDescription": "رسالة إشعارات لحظية",
		"message": "الرسالة",
		"categories": {
			"region": "الإقليم",
			"standard": "اساسي",
			"custom": "العادة",
			"future": "مستقبل"
		},
		"custom": {
			"area": "المنطقة المستهدفة",
			"areaHelp": "انقر على الخريطة لتحديد المنطقة المستهدفة لهذا الإشعار"
		},
		"error": {
			"length": "رسالة الإشعار اللحظي طويلة للغاية",
			"messageEmpty": "يرجى إدخال رسالة",
			"missingRegion": "يرجى اختيار إقليم"
		},
		"what": "ما الذي تريده إرساله؟",
		"when": "متى تريد إرسالها؟",
		"addToQueue": "إضافة إلى قائمة الانتظار",
		"types": {
			"default": "افتراضي"
		},
		"priorities": {
			"low": "منخفض",
			"high": "متوسط"
		},
		"showAllApps": "عرض جميع التطبيقات",
		"category": "الفئة",
		"createdBy": "صنع من قبل",
		"approve": "يوافق",
		"priority": "أفضلية",
		"payload": "الحمولة",
		"sent": "دفع ارسل",
		"existing": "إخطارات الدفع الحالية",
		"reject": "رفض",
		"notes": "ملاحظات",
		"numberSent": "مجموع",
		"sending": "إرسال",
		"type": "اكتب",
		"assignments": "تعيينات",
		"noNotifications": "لا إشعارات",
		"numberCompleted": "منجز",
		"saveAndSend": "حفظ وإرسال",
		"pushDetails": "تفاصيل الإخطارات",
		"editPush": "تعديل إشعار الدفع",
		"noPageSpecified": "لا توجد صفحة محددة"
	},
	"hazards": {
		"title": "المخاطر",
		"alerts": {
			"custom": {
				"types": {
					"announcement": "إعلان",
					"pin": "الصفحة معلقة",
					"announcementDesc": "تستخدم الإعلانات ل إنشاء تنبيهات للأحداث التي لم يتم من مقدمي تنبيه الآخرين .",
					"pinDesc": "ستخدم صفحات معلقة على ميزة الصفحات المهمة التي هي الوقت والمكان الحساس."
				},
				"errors": {
					"pleaseSelectRegion": "يرجى تحديد المنطقة ل هذا التنبيه .",
					"pleaseProvideMessage": "يرجى تقديم رسالة ل هذا الإعلان .",
					"pleaseSelectPage": "يرجى تحديد الصفحة التي سيتم معلقة .",
					"pleaseProvideEvent": "يرجى تقديم حدث ل هذا الإعلان .",
					"pleaseProvideHeadline": "يرجى تقديم عنوان ل هذا الإعلان .",
					"pleaseProvideAreaDescription": "يرجى تقديم وصف المنطقة ل هذا الإعلان .",
					"effectiveWrong": "التاريخ الفعلي لا يمكن أن يكون بعد تاريخ انتهاء الصلاحية"
				},
				"announcementPage": "يتم عرض الصفحة في قسم ما الآن .",
				"pinPage": "الصفحة ل تكون واردة .",
				"message": "الرسالة المرسلة مع هذا التنبيه .",
				"area": "منطقة التنبيه",
				"areaHelp": "سيتم إرسال تنبيه لجميع المستخدمين رصد موقع داخل المنطقة المحددة .",
				"expiry": "تاريخ الانتهاء",
				"expiryHelp": "تاريخ سيتم إزالة هذا التنبيه .",
				"effective": "تاريخ مؤثر",
				"effectiveHelp": "تاريخ هذا التنبيه لن تكون فعالة من .",
				"onset": "تاريخ بداية",
				"onsetHelp": "التاريخ سوف الخطر يحدث.",
				"severity": "خطورة",
				"severityHelp": "ما مدى أهمية هذا التنبيه ؟",
				"alertQueued": "في قائمة الانتظار في حالة تأهب ليتم إرسالها .",
				"drawRegion": "رسم حول المناطق يجب أن ترسل هذا التنبيه",
				"notify": "أبلغ",
				"sendNotification": "إرسال إشعار دفع ل هؤلاء المستخدمين .",
				"pushes": "الدفعات",
				"cap_create": "قبعة",
				"cap_send": "قبعة",
				"languageHelp": "لغة مكتوبة في حالة تأهب في .",
				"polygon": "إضافة بيانات مضلع مخصص هنا .",
				"showPolyData": "وتظهر بيانات المضلع",
				"updateMap": "خريطة التحديث"
			},
			"times": {
				"month": "شهر",
				"day": "يوم",
				"allTime": "كل الوقت",
				"week": "أسبوع",
				"hour": "ساعة"
			},
			"expired": "منتهية الصلاحية",
			"issuedBy": "أصدرت من قبل",
			"effective": "فعال",
			"title": "التنبيهات",
			"testAlert": "إنشاء تنبيه اختبار",
			"createNewAlert": "إنشاء تنبيه جديد",
			"show": "تبين",
			"pin": "دبوس",
			"inTheLast": "في الاخير",
			"alert": "محزر",
			"pinned": "دبس",
			"allAlerts": "كل التنبيهات",
			"specifySinglePolygon": "المضلعات المتعددة غير مدعومة للتنبيهات. يرجى رسم مضلع واحد.",
			"alertActive": "تنبيه نشط",
			"noAlerts": "لا تنبيهات",
			"testAlertDesc": "سيتم إرسال تنبيه الاختبار هذا إلى جميع المستخدمين الذين يراقبون موقعًا في أنتاركتيكا."
		},
		"generalNotifications": {
			"title": "الإخطارات عامة",
			"createNotification": "إنشاء إعلام",
			"event": "حدث",
			"headline": "عنوان رئيسي",
			"area_description": "وصف منطقة",
			"type": "اكتب",
			"status": "الحالة",
			"scope": "نطاق",
			"category": "فئة",
			"urgency": "إلحاح",
			"severity": "خطورة",
			"certainty": "يقين",
			"customMapData": {
				"placeholder": "انقر على الخريطة أعلاه أو إضافة ما لا يقل عن ثلاثة إحداثيات هنا ل إنشاء مضلع . i.e. \n[longitude,latitude],\n[longitude,latitude],\n[longitude,latitude]",
				"moreCoords": "يرجى توفير أكثر من ثلاثة إحداثيات",
				"firstAndLast": "يجب أن تكون الإحداثيات الأولى والأخيرة هي نفسها",
				"notFound": "لا توجد بيانات مضلع وجدت ، يرجى إضافة ما لا يقل عن ثلاثة الإحداثيات. i.e. [longitude,latitude],[longitude,latitude],[longitude,latitude]",
				"fisrtFoundNotLast": "الإحداثي الأول وجدت داخل الإحداثيات ، فقط لأول مرة ، وينبغي أن الإحداثيات مشاركة تكون هي نفسها"
			},
			"dropdown": {
				"alert": "إنذار",
				"update": "تحديث",
				"cancel": "إلغاء",
				"error": "خطأ",
				"actual": "فعلي",
				"system": "نظام",
				"test": "اختبار",
				"draft": "مسودة",
				"public": "عامة",
				"restricted": "مقيد",
				"private": "خاص",
				"safety": "سلامة",
				"security": "أمن",
				"rescue": "إنقاذ",
				"fire": "نار",
				"health": "الصحة",
				"transport": "المواصلات",
				"other": "آخر",
				"immediate": "فوري",
				"expected": "متوقع",
				"future": "مستقبل",
				"past": "الماضي",
				"unknown": "غير معروف",
				"extreme": "أقصى",
				"severe": "شديدة",
				"moderate": "معتدل",
				"minor": "قاصر",
				"observed": "ملاحظ",
				"likely": "على الأرجح",
				"possible": "ممكن",
				"unlikely": "من غير المرجح"
			}
		},
		"disasters": {
			"categories": {
				"wildfire": "حريق هائل",
				"coastal": "ساحلي",
				"tornado": "إعصار",
				"flood": "فيضان",
				"winter": "شتاء",
				"tsunami": "تسونامي",
				"heatwave": "موجة الحر",
				"storm": "عاصفة",
				"hurricane": "اعصار",
				"wind": "ينفخ",
				"earthquake": "زلزال",
				"volcano": "بركان",
				"nuclear": "نووي"
			},
			"tutorial": {
				"heading": "ماذا تعني الدول المختلفة؟",
				"body": "- تمكين تعني أن الصفحة ستظهر في محرر المحتوى لكي تبدأ في العمل عليها. <br> - يعني Dev أن الصفحة ستظهر في إصدارات التطوير لهذا التطبيق. <br> - مباشر يعني أن الصفحة ستظهر في إصدارات متجر التطبيقات لهذا التطبيق."
			},
			"disable": "تعطيل",
			"title": "الكوارث",
			"whatNow": "صفحة \"ماذا الآن\"",
			"configure": "تهيئة",
			"enable": "مكن",
			"alertSettings": "إعدادات التنبيه",
			"saveSuccess": "تم حفظ تكوين التطبيق.",
			"rootPage": "صفحة الجذر",
			"saveError": "أخفق حفظ تهيئة التطبيق.",
			"pleaseSpecifyPages": "يرجى تحديد صفحة لكل نوع من أنواع الكوارث المحددة."
		},
		"alertZones": {
			"type": "اكتب",
			"editAlertZone": "تحرير منطقة التنبيه",
			"blacklist": "القائمة السوداء",
			"area": "منطقة",
			"events": "أحداث",
			"name": "اسم",
			"whitelist": "القائمة البيضاء",
			"areaSelectModalTitle": "ارسم حول المناطق التي تنطبق عليها هذه المنطقة",
			"addAlertZone": "إضافة منطقة التنبيه",
			"title": "مناطق التنبيه"
		},
		"feeds": {
			"tutorial": {
				"heading": "استخدام الخلاصات",
				"body": "قد تأتي بعض أنواع التنبيه من مصادر تنبيه متعددة. يتيح لك هذا القسم تمكين أو تعطيل خلاصات وأنواع أحداث معينة من خلاصات فردية لهذا التطبيق.",
				"steps": "<ol style=\";text-align:right;direction:rtl\"><li style=\";text-align:right;direction:rtl\"> تأكد من تمكين أنواع الكوارث والفئات المختارة لهذا التطبيق. سيحدد ذلك أي الخلاصات والأحداث قد يتم تمكينها أدناه. </li><li style=\";text-align:right;direction:rtl\"> حدد الخلاصات وأنواع الأحداث التي ترغب في المساهمة بها في التنبيهات الخاصة بهذا التطبيق. </li></ol>"
			},
			"delete": {
				"yes": "نعم ، احذفها",
				"confirm": "هل أنت واثق؟",
				"events": "سيتم تعطيل جميع الأحداث الممكّنة لهذه الخلاصة."
			},
			"title": "يغذي",
			"scope": "نطاق",
			"type": "اكتب",
			"disable": "تعطيل",
			"code": "الشفرة",
			"languages": "اللغات المتوفرة",
			"enable": "مكن",
			"configure": "تهيئة",
			"feed": "تغذية",
			"events": "أحداث"
		}
	},
	"appCreate": {
		"createNewOrganization": "إنشاء مؤسسة جديدة",
		"details": "تفاصيل التطبيق",
		"emptyApp": "التطبيق فارغ",
		"title": "إنشاء تطبيق جديد",
		"copyFrom": "نسخ المحتوى من"
	},
	"manageApps": {
		"title": "إدارة التطبيقات",
		"yourNextApp": "تطبيقك الجديد",
		"easilySetUpYourNextApp": "إعداد تطبيقك الجديد بسهولة"
	},
	"dashboard": {
		"title": "لوحة المعلومات"
	},
	"manage": {
		"title": "إدارة"
	},
	"crossBorders": {
		"title": "عبر حدود",
		"list": "قائمة الوجهات",
		"links": "الروابط",
		"link": {
			"title": "عنوان",
			"destination": "المكان المقصود",
			"placeholder": "عنوان الوجهة"
		},
		"editItem": "تحرير الوجهة البلد",
		"contacts": "جهات الاتصال",
		"translations": "ترجمات العبارة",
		"contact": {
			"editItem": "حرر معلومات الاتصال",
			"title": "عنوان",
			"number": "رقم الهاتف"
		},
		"phrases": "العبارات",
		"phrase": {
			"editItem": "تحرير العبارة",
			"phrase": "العبارة",
			"translation": "ترجمة"
		},
		"country": {
			"code": "رقم البلد",
			"name": "اسم الدولة",
			"description": "بلد المقصد"
		}
	},
	"whatnow": {
		"whatnow": "ماذا الان؟",
		"eventType": "نوع الحدث",
		"title": "عنوان",
		"description": "وصف",
		"before": "قبل",
		"during": "أثناء",
		"after": "بعد",
		"content-not-set": "المحتوى لم يتم تعيين",
		"empty": "لا يوجد ما نوس، انقر فوق إضافة إلى إنشاء واحدة",
		"selectSoc": "حدد المجتمع",
		"edit": {
			"editWhatnow": "تعديل ماذا الآن",
			"event": "نوع الحدث",
			"eventhelp": "نوع الحدث ل ماذا الآن .",
			"moreinfo": "URL المعلومات",
			"moreinfohelp": "URL ل مزيد من المعلومات حول ما الآن",
			"translations": "ترجمة",
			"new": "إضافة لغة جديدة",
			"selectLang": "اختر اللغة",
			"title": "عنوان",
			"titleHelp": "عنوان ل ماذا الآن .",
			"description": "وصف",
			"descriptionHelp": "وصف ل ماذا الآن .",
			"midterm": "منتصف المدة المتوقعة",
			"midtermHelp": "الحد من المخاطر (إجراءات على المدى الطويل )",
			"forecast": "توقعات",
			"forecastHelp": "تقييم و خطة (إجراءات المدى القصير )",
			"warning": "تحذير",
			"warningHelp": "الاستعداد ل لرد",
			"watch": "راقب",
			"watchHelp": "الاستعداد ل لرد",
			"immediate": "فوري",
			"immediateHelp": "رد",
			"recover": "استعادة",
			"recoverHelp": "بعد",
			"before": "قبل",
			"beforeHelp": "كيفية تحضير قبل وقوع الحدث.",
			"during": "أثناء",
			"duringHelp": "ما يجب القيام به في حين أن الحدث يحدث",
			"after": "بعد",
			"afterHelp": "ما يجب القيام به بعد أن وقع الحدث",
			"languages": "اللغات",
			"missing": "محتوى مفقود",
			"empty": "الرجاء إضافة لغة إلى إنشاء ماذا الآن",
			"addALang": "إضافة لغة للبدء",
			"moreinfoHelp": {
				"placeholder": "Lorem ipsum dolor sit amet، consectetur adipiscing elit، sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam، quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
			}
		},
		"attribution": {
			"attribution": "عزو",
			"url": "عنوان الإحالة",
			"image": "صورة الإحالة",
			"socName": "اسم جمعية الإسناد",
			"message": "رسالة الإحالة",
			"imageHelp": "صورة / أيقونة تحميل.",
			"messageHelp": "الرسالة التي تترافق مع الإحالة.",
			"urlHelp": "عنوان URL الذي يربطه بمزيد من المعلومات حول المُساهم.",
			"socNameHelp": "اسم الجمعية التي يجب أن تنسب مع ما."
		}
	},
	"nav": {
		"items": {
			"appSetup": {
				"description": "قم بتكوين اسم التطبيق والرمز وتوطين النص ومعلومات متجر التطبيقات",
				"title": "إعداد التطبيق"
			},
			"content": {
				"description": "تحرير المحتوى داخل التطبيق",
				"title": "يحتوى"
			},
			"publish": {
				"title": "نشر",
				"description": "نشر التغييرات على التطوير أو البث المباشر"
			},
			"stats": {
				"title": "احصائيات",
				"description": "شاهد كيف يقوم التطبيق"
			},
			"userAgreement": {
				"title": "اتفاقية المستخدم",
				"description": "عرض وتوقيع اتفاقية برنامج التطبيقات العامة"
			},
			"users": {
				"title": "المستخدمين",
				"description": "إدارة جميع المستخدمين"
			},
			"stories": {
				"title": "قصص",
				"description": "إدارة القصص"
			},
			"levels": {
				"title": "مستويات",
				"description": "إدارة مستويات التقدم في التطبيق"
			},
			"badges": {
				"title": "شارات",
				"description": "إدارة الشارات الممنوحة لاستكمال الأنشطة في التطبيق"
			},
			"push": {
				"title": "دفع الإخطارات",
				"description": "إدارة وإرسال إشعارات الدفع للمستخدمين"
			},
			"sprint": {
				"title": "لوحة الحالة",
				"description": "عرض الوضع الحالي تطور العدو"
			},
			"bugs": {
				"description": "أبلغ عن المشكلات المتعلقة بالمحرر أو التطبيق",
				"title": "الإبلاغ عن الأخطاء"
			},
			"gdpcConsole": {
				"title": "NS وحدة التحكم",
				"description": "عرض حالات المجتمع وجمعية ديف التجمع"
			},
			"hazards": {
				"title": "الكوارث والتنبيهات",
				"description": "الأولويات وإدارة الكوارث والتنبيهات والاعتدال"
			},
			"decisionTree": {
				"title": "شجرة القرار",
				"description": "عرض وتعديل شجرة القرار للتطبيق"
			},
			"admin": {
				"title": "مشرف",
				"description": "عرض الإحصاءات وتحرير المستخدمين وتفاصيل المؤسسة"
			}
		},
		"navigate": "التنقل",
		"logout": "الخروج"
	},
	"publish": {
		"details": "تفاصيل",
		"noChanges": "لا تغيرات",
		"startTime": "وقت البدء",
		"test": "اختبار",
		"publishType": "نوع النشر",
		"publishToDev": "نشر إلى dev",
		"toApps": "النشر على التطبيقات",
		"endTime": "وقت النهاية",
		"detail": "التفاصيل",
		"history": "التاريخ",
		"selectAll": "اختر الكل",
		"objectType": "نوع الكائن",
		"time": "زمن",
		"title": "نشر",
		"fail": "فشل النشر.",
		"success": "نشر ناجح.",
		"progress1": "يتم الآن نشر التغييرات التي اخترتها.",
		"live": "حي",
		"user": "المستعمل",
		"auditTrail": "درب المراجعة",
		"publishToLive": "نشر للعيش",
		"progress2": "هذا قد يستغرق بعض الوقت. لا تتنقل بعيدًا عن هذه الصفحة.",
		"error": "يرجى تحديد صفحة واحدة أو أكثر لنشرها.",
		"progress3": "يبدو أن هذا يستغرق بعض الوقت. يمكنك العودة لاحقا.",
		"warning2": "يرجى مراجعة جميع التغييرات بشكل كامل قبل المتابعة والتأكد من اختيارك في أسفل الصفحة.",
		"publishConfirm": "أؤكد أنني قد راجعت جميع التغييرات المحددة وأن لدي إذنًا بدفعها مباشرةً",
		"warning1": "لقد حددت الصفحات التالية التي سيتم نشرها لتطبيقات iOS و Android من خلال تحديث دلتا.",
		"publishing": "نشر...",
		"comment": "تعليق",
		"lastModified": "آخر تعديل",
		"publishLandmark": "لاندمارك تنشر؟",
		"pleaseConfirm": "يرجى تحديد مربع الاختيار لتأكيد أنك قمت بمراجعة جميع التغييرات."
	},
	"publishHistory": {
		"title": "نشر السجل",
		"previousPublishes": "النشرات السابقة"
	},
	"agreement": {
		"email": "عنوان البريد الإلكتروني",
		"iAgree": "أوافق على الشروط الواردة في",
		"date": "تاريخ",
		"sign": "إشارة",
		"status": "الحالة",
		"fullName": "الاسم الكامل",
		"notSigned": "غير موقعة",
		"checkToAgree": "يرجى تحديد المربع لتأكيد قراءة البنود والموافقة عليها.",
		"signed": "وقعت",
		"signedBy": "موقعة من قبل",
		"signAgreement": "توقيع اتفاق",
		"details": "تفاصيل الاتفاقية",
		"daysRemaining": "__count__ يوم متبقي",
		"hazardsTitle": "اتفاقية برنامج تطبيق المخاطر العالمية",
		"nationalSociety": "المجتمع الوطني",
		"daysRemaining_plural": "__count__ أيام متبقية",
		"viewThe": "عرض",
		"title": "اتفاقية برنامج التطبيق العالمي"
	},
	"roles": {
		"title": "أدوار المستخدمين",
		"permissions": "أذونات",
		"list": "قائمة الأدوار"
	},
	"permissions": {
		"none": "لا شيء",
		"write": "كتابة",
		"edit": "تحرير الأذونات",
		"title": "أذونات الأدوار",
		"read": "اقرأ",
		"delete": "إرسال / حذف"
	},
	"apps": {
		"app": "التطبيق",
		"assign": "تعيين",
		"title": "تعيين التطبيقات",
		"assignNew": "تعيين تطبيق جديد",
		"subtitle": "تعيين التطبيقات للمستخدم"
	},
	"stories": {
		"editStory": "تحرير القصة",
		"title": "قصص",
		"subtitle": "عنوان فرعي",
		"created": "خلقت",
		"list": "قائمة القصص",
		"content": "يحتوى"
	},
	"badges": {
		"editBadge": "تعديل الشارة",
		"title": "شارات",
		"list": "قائمة الشارات"
	},
	"levels": {
		"children": "مستوى الأطفال",
		"title": "مستويات",
		"details": "تفاصيل المستوى",
		"editLevel": "تحرير المستوى",
		"list": "قائمة المستويات"
	},
	"bugs": {
		"status": {
			"byDesign": "من تصمبم",
			"closed": "مغلق",
			"notReproducible": "لا استنساخه",
			"fixed": "ثابت",
			"wontFix": "لن إصلاح",
			"deleted": "تم الحذف",
			"postponed": "مؤجل",
			"duplicate": "مكرر",
			"title": "الحالة",
			"open": "افتح"
		},
		"reportBug": "الإبلاغ عن خطأ",
		"addComment": "اضف تعليق",
		"build": "بناء",
		"issueDetails": "تفاصيل القضية",
		"platformDetails": "تفاصيل المنصة",
		"listOpen": "قائمة تقارير الأخطاء المفتوحة",
		"listPending": "قائمة تقارير الأخطاء المعلقة",
		"expected": "سلوك متوقع",
		"report": "أبلغ عن",
		"version": "الإصدار",
		"title": "البق",
		"comments": "تعليقات",
		"screenshots": "لقطات",
		"loadingFiles": "تحميل ملفات...",
		"noFiles": "لا ملفات",
		"noComments": "بدون تعليقات",
		"bugReport": "تقرير الشوائب",
		"closeIssue": "إغلاق القضية",
		"actually": "السلوك الفعلي",
		"noReports": "لا تقارير",
		"device": "إصدار الجهاز / نظام التشغيل",
		"reopenIssue": "إعادة فتح القضية",
		"loadingDetails": "جارٍ تحميل تفاصيل الخطأ ...",
		"steps": "خطوات للتكاثر",
		"listClosed": "قائمة تقارير الأخطاء المغلقة"
	},
	"gdpcConsole": {
		"nationalSocieties": {
			"downloads": "التنزيلات",
			"details": "تفاصيل المجتمع",
			"edit": "تحرير المجتمع",
			"contact": "اتصل",
			"averageRating": "متوسط ​​تقييم",
			"pool": "حوض السباحة",
			"stage": "المسرح",
			"totalDownloads": "إجمالي التنزيلات",
			"countries": "بلدان",
			"locale": "مكان",
			"title": "نظرة عامة",
			"goLive": "بث مباشر"
		},
		"devPools": {
			"editPool": "تحرير تجمع ديف",
			"language": "لغة",
			"expected": "متوقع",
			"requiredBy": "مطلوب من قبل",
			"responsibility": "المسئولية",
			"stages": "مراحل",
			"completed": "منجز",
			"title": "حالة تجمع Dev",
			"estimatedDate": "تاريخ التطبيق المقدّر الحالي",
			"noApps": "لا توجد تطبيقات في هذا التجمع ديف"
		}
	},
	"organization": {
		"title": "منظمة",
		"details": "تفاصيل المنظمة",
		"noLanguages": "لم يتم تعيين لغات",
		"confirmLanguageAssignment": "هل أنت متأكد من أنك تريد تعيين هذه اللغة؟ لا يمكن إزالة التعيينات اللغوية."
	},
	"mailingList": {
		"dateTo": "إلى",
		"dateFrom": "من عند",
		"title": "القوائم البريدية",
		"export": "تصدير",
		"list": "القوائم المتاحة"
	},
	"moderation": {
		"image": {
			"location": "موقعك",
			"title": "الإشراف على الصورة"
		},
		"title": "الاعتدال"
	},
	"posts": {
		"title": "المشاركات"
	},
	"achievements": {
		"completion": "إكمال",
		"image": "صورة",
		"badge": "شارة",
		"name": "اسم",
		"title": "الإنجازات",
		"edit": "تحرير الإنجاز"
	},
	"triggers": {
		"subclasses": {
			"RaisedTrigger": {
				"amount": "كمية",
				"singular": "المبلغ المطروح"
			}
		},
		"plural": "محفزات"
	},
	"mind-moderation": {
		"emoodji": {
			"singular": "Emoodji"
		},
		"title": "Emoodji الاعتدال",
		"empty": "لا Emoodjis على الاعتدال"
	},
	"arpro": {
		"codeGroup": {
			"name": "اسم",
			"description": "وصف",
			"codes": "رموز",
			"codeGroup": "مجموعات الرموز",
			"editItem": "تحرير مجموعة التعليمات البرمجية",
			"addCodes": "أدخل رمز هنا"
		},
		"colours": {
			"description": "وصف",
			"name": "اسم",
			"colours": "الألوان"
		},
		"search": {
			"burningRate": "معدل حرق",
			"mouldedDensity": "كثافة مقولبة",
			"compressionStrength75": "قوة ضغط 75",
			"tensileElongation": "استطالة الشد",
			"equivalentYoungsModulus": "معادلة يونغز معامل",
			"search": "بحث منتوج",
			"tensileStrength": "قوة الشد",
			"compressionStrength50": "قوة ضغط 50",
			"compressionStrength25": "قوة ضغط 25",
			"resilience75DynamicCompression": "القدرة الديناميكية 75 ضغط ديناميكي"
		},
		"product": {
			"properties": {
				"packaging": "التعبئة والتغليف",
				"bag": "حقيبة",
				"bulkLabel": "حجم",
				"food": "وافق الغذاء",
				"weight": "الوزن (ملغ)",
				"size": "الحجم (مم)",
				"bulk": "الكثافة الظاهرية (جم / لتر)",
				"moulded": "كثافة مقولبة (جم / لتر)"
			},
			"specifications": "مواصفات",
			"grade": "درجة",
			"name": "اسم",
			"product": "منتجات",
			"notes": "ملاحظات",
			"colour": "اللون",
			"access": "رموز الوصول",
			"editItem": "تحرير المنتج",
			"physicalProps": "الخصائص الفيزيائية",
			"noneLinked": "لا رموز الوصول",
			"linked": "لديه رموز الوصول",
			"rawData": "بيانات JSON الخام",
			"moulded": "مدى الكثافة المقولبة (g / l)",
			"rawDataError": "بيانات JSON الخام - بيانات JSON غير صالحة"
		},
		"noitems": "لا توجد عناصر لعرضها ، انقر فوق إضافة أعلاه.",
		"arpro": "arpro"
	},
	"r4b": {
		"level": {
			"title": "مستوى",
			"red": "أحمر",
			"blue": "أزرق",
			"green": "أخضر",
			"colour": "اللون",
			"alpha": "ألفا",
			"levels": "مستويات",
			"selectBadge": "يرجى اختيار شارة"
		},
		"modules": {
			"blockContent": {
				"logic": {
					"title": "قسم رؤية المنطق"
				},
				"slider": {
					"interval": "فترة",
					"endLabel": "نهاية التسمية",
					"startLabel": "بدء التسمية"
				},
				"question": {
					"title": "سؤال وجواب",
					"textHelp": "أدخل السؤال",
					"addResponse": "أضف استجابة",
					"addAnotherResponse": "أضف استجابة أخرى",
					"responseHelp": "أدخل الرد",
					"answerHelp": "أدخل إجابة",
					"selectionHelp": "حدد قسمًا للمتابعة"
				},
				"quote": {
					"title": "اقتبس",
					"citation": "أدخل الاقتباس هنا"
				},
				"checkbox": {
					"title": "مربعات"
				},
				"textInput": {
					"title": "إدخال النص",
					"multi": "نص متعدد الخطوط؟",
					"help": "أدخل تسمية الإدخال هنا"
				},
				"link": {
					"title": "الروابط"
				},
				"help": {
					"title": "كائن المساعدة"
				},
				"task": {
					"title": "كائن المهمة",
					"help": "أدخل هدف المهمة"
				},
				"text": {
					"title": "كائن نص"
				},
				"radio": {
					"title": "أزرار الراديو"
				},
				"clickToEdit": "إضغط للتعديل",
				"section": "الجزء",
				"enterText": "أدخل النص هنا",
				"identifier": "معرف",
				"break": "فاصل صفحة",
				"enterTitle": "أدخل العنوان",
				"inputLabel": "نص إدخال التسمية",
				"videoLink": "رابط الفيديو الخارجي",
				"addIdentifier": "إضافة معرف",
				"addSection": "اسحب عنصرًا هنا لإنشاء قسم جديد"
			},
			"section": {
				"title": "الجزء"
			},
			"blockTypes": {
				"quote": "اقتبس",
				"task": "مهمة",
				"check": "التحقق من",
				"help": "مساعدة",
				"text": "نص",
				"radio": "راديو",
				"qna": "Q & A",
				"media": "كتل الوسائط",
				"userInput": "USER INPUT BLOCKS",
				"slider": "المنزلق",
				"link": "حلقة الوصل",
				"video": "فيديو",
				"textInput": "مدخل النص",
				"image": "صورة"
			},
			"title": "وحدات",
			"levels": "مستويات",
			"people": "اشخاص",
			"person": "شخص",
			"mins": "دقيقة",
			"visibility": "رؤية",
			"module": "وحدة",
			"version": "الإصدار",
			"level": "مستوى",
			"saved": "يتم حفظ جميع التغييرات",
			"unsaved": "هناك تغييرات غير محفوظة"
		},
		"assessments": {
			"assessment": "تقدير",
			"pre": "قبل التقييم"
		},
		"languages": "اللغات",
		"mediaBlocks": "كتل الوسائط",
		"inputBlocks": "USER INPUT BLOCKS",
		"title": "أطلس",
		"questions": "الأسئلة",
		"person": "شخص",
		"sections": "الأقسام",
		"minutes": "MINS",
		"languageVars": "تنويعات اللغة",
		"contentBlocks": "كتل المحتوى",
		"admin": "لوحة تحكم المشرف",
		"empty": "لا توجد وحدات أو تقييمات هنا ، أضف واحدة إلى هذا المستوى للبدء.",
		"people": "اشخاص"
	},
	"rspb": {
		"factFile": {
			"description": "وصف",
			"factFile": "ملفات حقيقية",
			"start": "بداية",
			"show": "تبين",
			"seen": "رأيت",
			"name": "اسم",
			"funFacts": "حقائق ممتعة",
			"scientificName": "الاسم العلمي",
			"images": "صور",
			"addImages": "اختر صورة",
			"activities": "أنشطة",
			"tags": "الكلمات",
			"editItem": "تحرير ملف الحقائق",
			"addFact": "أدخل حقيقة هنا",
			"addActivities": "أدخل نشاطًا هنا",
			"addFeatures": "أدخل ميزة هنا",
			"features": "الميزات",
			"commonName": "اسم شائع",
			"addTags": "أدخل علامة هنا"
		},
		"planItem": {
			"difficulty": {
				"hard": "الصعب",
				"easy": "سهل",
				"medium": "متوسط",
				"difficulty": "حدد الصعوبة"
			},
			"garden": {
				"large": "كبير",
				"medium": "متوسط",
				"small": "صغير",
				"public": "عامة",
				"balconyroof": "شرفة / سقف",
				"selectType": "اختر نوع / ق الحديقة"
			},
			"source": "مصدر",
			"weighting": "ترجيح البند الخطة",
			"weightingHelp": "أولوية المكان الذي سيظهر فيه العنصر في التطبيق",
			"solo": "لأجل العائلة؟",
			"image": "صورة",
			"indefinite": "غير محدد؟",
			"region": "منطقة",
			"mostImportant": "الأهم",
			"description": "وصف",
			"to": "تاريخ الانتهاء",
			"type": "اكتب",
			"months": "الشهور",
			"identifier": "معرف",
			"title": "عنوان",
			"from": "متاح من",
			"tags": "الكلمات",
			"visible": "مرئي من",
			"leastImportant": "أقل أهمية",
			"planItems": "عناصر الخطة",
			"activityCode": "رمز النشاط",
			"duration": "المدة (ثانية)",
			"regionHelp": "انقر لتحديد المنطقة لهذا البند من الخطة",
			"pageHelp": "هذه هي الصفحة التي سيرتبط بها عنصر خطتك",
			"dates": "تواريخ"
		},
		"noitems": "لا توجد عناصر لعرضها ، انقر فوق إضافة أعلاه.",
		"rspb": "RSPB"
	}
}