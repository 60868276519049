{{partial "editor/editor-nav"}}

<div class="title-bar">
	<div class="header">
		<span>Blood Journey</span>
		<button class="button add">Add step</button>
		<button class="button upload-journey">Upload journey CSV</button>
	</div>
</div>

<div class="overlay"></div>

<div class="container pad-top">
	<div class="row">
		<div class="span12 title-header">List of steps</div>
		<div class="pagination"></div>
	</div>
	<div class="row">
		<div class="span12">

			<table class="header-float">
				<thead class="title-case">
					<tr>
						<th>{{t "common.tools"}}</th>
					</tr>
				</thead>
				<tbody class="list-items"></tbody>
			</table>
			<div class="pagination"></div>

		</div>
	</div>
</div>

<input type="file" accept="text/csv" class="journey-upload-input" style="display:none">
